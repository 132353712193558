import { Component, Input, OnInit } from '@angular/core'
import { BotRun } from 'generated/graphql'
import * as moment from 'moment'

/**
 * Component to display a bot run's key details
 *
 * @export
 * @class BotRunKeyDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-bot-run-key-details',
  templateUrl: './bot-run-key-details.component.html',
  styleUrls: ['./bot-run-key-details.component.scss'],
  host: {
    '[class.d-flex]': 'true',
    '[class.flex-column]': 'true',
    '[class.flex-grow-1]': 'true',
  },
})
export class BotRunKeyDetailsComponent implements OnInit {
  @Input() botRun: BotRun
  lateQueuedRun: boolean

  constructor() {}

  ngOnInit(): void {
    this.lateQueuedRun =
      this.botRun?.status === 'QUEUED' && moment(this.botRun?.queuedAt).isBefore(moment().subtract(1, 'hours'))
  }
}
