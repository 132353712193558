import { Component, HostBinding } from '@angular/core'

/**
 * Janus App Home Page
 *
 * @export
 * @class HomePage
 */
@Component({
  selector: 'app-home-page',
  template:
    '<img src="/assets/img/Janus_Health_horizontal_logo_colored.png" alt="Janus Health Logo" id="janus-homepage-logo" />',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  @HostBinding('class') classes = 'full-height no-crumbs'

  constructor() {}
}
