import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { OnlyProviderClaimIds } from 'app/claims/claims-list/claims-ids-only.gql'
import { ClaimsForClaimList } from 'app/claims/claims-list/claims-list.gql'
import { ClaimsService } from 'app/claims/claims.service'
import { LoadSearchComponent } from 'app/shared/components/faceted-search/load-search/load-search.component'
import { SaveSearchComponent } from 'app/shared/components/faceted-search/save-search/save-search.component'
import { ProgressModalComponent } from 'app/shared/components/progress-modal/progress-modal.component'
import { NgbdSortableHeader, SortColumn, SortDirection, SortEvent } from 'app/shared/directives/sortable.directive'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { PROPTYPE, PropertyDef, getPropDetails } from 'app/shared/types/claim'
import { Facet } from 'app/shared/types/facet'
import { FACETS, SEARCHFIELD } from 'app/shared/types/facets'
import { debug } from 'app/shared/utils/debug'
import { facetsToClaimQueryParams } from 'app/shared/utils/facets-to-params'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { jitter } from 'app/shared/utils/retry-jitter'
import type BN from 'bottleneck'
import { saveAs } from 'file-saver'
import { Claim, ListResponseMetaData, QueryClaimsArgs, SavedSearch } from 'generated/graphql'
import { chunk, find, flatten, isEqual, pull } from 'lodash'
import { Subscription } from 'rxjs'
import { ClaimListColumnsComponent } from '../components/claim-list-columns/claim-list-columns.component'
import { ClaimsExportListComponent } from '../components/claims-export-list/claims-export-list.component'
import { FavoriteClaimsService } from '../favorite-claims.service'

declare const Bottleneck: typeof BN

type ColumnGroup = {
  heading: string
  span: number
}

/**
 * Page to display a list of claims and allow searching
 *
 * @export
 * @class ClaimsListPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-claims-list-page',
  templateUrl: './claims-list.page.html',
  styleUrls: ['./claims-list.page.scss'],
})
export class ClaimsListPage implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') classes = 'd-flex flex-column h-100'
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>

  PROPTYPE = PROPTYPE

  columns: PropertyDef[] = []
  columnGroups: ColumnGroup[] = []
  hasColumnGroups: boolean = false

  claims: Claim[]
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 25

  claimSearch: Facet[] = []
  search: SavedSearch = null

  selected: string[] = []
  selectedAll: boolean = false
  maxSelectAll = 1000000
  limiter = null

  saveSearchDisabled = true
  loading: boolean = false

  favorited: string[] = []
  favoriteClaims$: Subscription
  isFavoritesEnabled: boolean = false
  isWorkflowsEnabled: boolean = false

  claimSearch$: Subscription
  routerSub$: Subscription

  constructor(
    private claimsService: ClaimsService,
    private toast: ToastService,
    private facetService: FacetedSearchService,
    private router: Router,
    private modal: NgbModal,
    public authenticationService: AuthenticationService,
    private favs: FavoriteClaimsService,
    private featureFlagService: FeatureFlagService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.stateFromSessionStorage()
    this.isFavoritesEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.favorites)
    if (this.isFavoritesEnabled) {
      this.favoriteClaims$ = this.favs.getFavoriteClaims().subscribe((event) => {
        if (event.error) {
          this.toast.error(parseGraphQLError(event, 'Could not load favorite claims'), JSON.stringify(event.error))
        }
        if (event.data) {
          this.favorited = event.data.favoriteClaims.entities.map((f) => f.id)
        }
      })
    }

    this.isWorkflowsEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.workflows)

    this.claimSearch$ = this.facetService.facets$.subscribe(async (facets) => {
      facets = facets.filter((f) => f.selected.length > 0)
      this.saveSearchDisabled = true
      let newSelected = flatten(facets.map((f) => f.selected.map((s) => `${f.id}-${s.display}`))).sort()
      let oldSelected = flatten(this.claimSearch.map((f) => f.selected.map((s) => `${f.id}-${s.display}`))).sort()

      if (!isEqual(newSelected, oldSelected)) {
        if (facets.length > 0) {
          this.saveSearchDisabled = false
          await this.searchClaims(facets)
        } else {
          await this.getClaims()
        }
      }
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.resetSort(this.meta?.sort))
  }

  ngOnDestroy(): void {
    this.claimSearch$?.unsubscribe()
    this.routerSub$?.unsubscribe()
    this.favoriteClaims$?.unsubscribe()
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.claimsService.saveFacetsToSessionStorage()
  }

  /**
   * Load state from session storage
   *  - including search, pagination, and columns to display
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async stateFromSessionStorage(): Promise<void> {
    let sessionSearch = window.sessionStorage.getItem('claimsSearch')
    let sessionMeta = window.sessionStorage.getItem('claimsMeta')
    let sessionColumns = window.localStorage?.getItem('claimsColumns')
    let columns = JSON.parse(sessionColumns)
    if (columns?.length) {
      this.columns = columns.map((col) => getPropDetails(col))
    } else {
      this.columns = [
        getPropDetails('providerClaimId'),
        getPropDetails('icn'),
        getPropDetails('serviceDate'),
        getPropDetails('patientName'),
        getPropDetails('patientDob'),
        getPropDetails('subscriberId'),
        getPropDetails('claimAmount'),
        getPropDetails('outstandingAmount'),
        getPropDetails('payer.name'),
      ]
    }
    this.setColumnGroups()
    if (sessionMeta?.length) {
      this.meta = JSON.parse(sessionMeta) as ListResponseMetaData
      this.page = (this.meta.offset + this.meta.limit) / this.meta.limit
    } else {
      this.meta = this.claimsService.getMeta()
      this.page = 1
    }
    let parsed = JSON.parse(sessionSearch)
    if (parsed?.length) {
      let realFacets: Facet[] = parsed.map((p) => {
        let facet = p.id === 'wildcard' ? SEARCHFIELD : FACETS.find((f) => f.id === p.id)
        facet.selected = p.selected
        facet.skipFocus = true
        return facet
      })
      if (!realFacets.find((f) => f.id === 'wildcard')) {
        realFacets.unshift(SEARCHFIELD)
      }
      this.facetService.setFacets(realFacets)
    } else {
      await this.getClaims()
    }
  }

  /**
   * Split columns into their respective groups
   *
   * @private
   * @memberof ClaimsListPage
   */
  private setColumnGroups(): void {
    let cg: ColumnGroup[] = []

    this.hasColumnGroups = this.columns.some((c) => c?.parentHeading?.length)

    this.columns
      .sort((a, b) => a.order - b.order)
      .map((c) => c?.parentHeading || '')
      .forEach((str) => {
        if (!str.length) {
          cg.push({ span: 1, heading: '' })
        } else {
          let existing = cg.findIndex((cg) => cg.heading === str)
          if (existing >= 0) {
            cg[existing].span += 1
          } else {
            cg.push({ span: 1, heading: str })
          }
        }
      })

    this.columnGroups = cg
    window.localStorage?.setItem('claimsColumns', JSON.stringify(this.columns.map((col) => col.property)))
  }

  /**
   * Helper to determine if object is an array
   *
   * @param {unknown} thing
   * @return {*}  {boolean}
   * @memberof ClaimsListPage
   */
  isArray(thing: unknown): boolean {
    return Array.isArray(thing)
  }

  /**
   * Launch modal to allow changing displayed columns
   *
   * @memberof ClaimsListPage
   */
  changeColumns(): void {
    let modalRef = this.modal.open(ClaimListColumnsComponent, {
      size: 'xl',
      centered: true,
    })
    modalRef.componentInstance.selected = this.columns

    modalRef.result.then(
      (columns) => {
        this.loading = true
        this.columns = columns
        this.setColumnGroups()
        setTimeout(() => (this.loading = false), 250)
      },
      () => {},
    )
  }

  /**
   * Load claims based on query variables
   *
   * @private
   * @param {QueryClaimsArgs} vars
   * @return {*}  {Promise<boolean>}
   * @memberof ClaimsListPage
   */
  private async queryClaims(vars: QueryClaimsArgs): Promise<boolean> {
    this.loading = true
    try {
      this.claims = await this.claimsService.getClaims(ClaimsForClaimList, vars)
      this.meta = this.claimsService.getMeta()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load claims'), JSON.stringify(e))
      return false
    }
    this.loading = false
    return true
  }

  /**
   * Load list of claims using default variables
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async getClaims(): Promise<void> {
    this.claimSearch = []
    this.selected = []
    this.page = 1
    let vars: QueryClaimsArgs = {
      sort: this.meta.sort,
      offset: 0,
      limit: this.pageSize,
    }
    if (await this.queryClaims(vars)) {
      this.resetSort(this.meta.sort)
    }
  }

  /**
   * Load list of claims based on search facets
   *
   * @param {Facet[]} search
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async searchClaims(search: Facet[]): Promise<void> {
    this.selected = []
    this.selectedAll = false
    this.claimSearch = search
    this.page = 1
    if (search.length === 0) {
      await this.getClaims()
    } else {
      let additionalParams = facetsToClaimQueryParams(search)
      debug('claims', 'additional params from facets', additionalParams)
      let vars: QueryClaimsArgs = {
        sort: this.meta.sort,
        offset: 0,
        limit: this.pageSize,
        ...additionalParams,
      }
      this.queryClaims(vars)
    }
  }

  /**
   * Load list of claims based on user sort select
   *
   * @param {SortEvent} { column, direction }
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async onSort({ column, direction }: SortEvent): Promise<void> {
    // let sort = this.meta?.sort.split(',').filter((str) => str.length)
    // let existing = sort.findIndex((s) => s.includes(column))
    // if (existing >= 0) {
    //   sort.splice(existing, 1)
    // }
    // if (direction.length) {
    //   sort.push(`${column}:${direction}`)
    // }
    // sort = sort.join(',')
    // Leaving the above in place JUST IN CASE we ever want to support multiple column sort
    let sort = direction.length ? `${column}:${direction}` : ''
    this.resetSort(sort)
    let vars: QueryClaimsArgs = {
      sort: sort,
      offset: 0,
      limit: this.pageSize,
      ...facetsToClaimQueryParams(this.claimSearch),
    }
    if (await this.queryClaims(vars)) {
      this.page = 1
      if (this.selectedAll) {
        this.selected = this.claims.map((c) => c.providerClaimId)
      } else {
        this.selected = []
      }
    }
  }

  /**
   * Reset column sort display
   *
   * @param {string} sort
   * @memberof ClaimsListPage
   */
  resetSort(sort: string): void {
    let column: SortColumn = sort?.substring(0, sort.indexOf(':'))
    let direction: SortDirection = sort?.substring(sort.indexOf(':') + 1) as SortDirection
    this.headers?.forEach((header) => {
      if (header.sortable === column) {
        header.direction = direction
      } else {
        header.direction = ''
      }
    })
  }

  /**
   * Load list of claims when table page is changed
   *
   * @param {number} page
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async onPageChange(page: number): Promise<void> {
    //this.page is already updated!
    let offset = (page - 1) * this.meta.limit
    let vars: QueryClaimsArgs = {
      sort: this.meta.sort,
      offset: offset,
      limit: this.pageSize,
      ...facetsToClaimQueryParams(this.claimSearch),
    }
    if (await this.queryClaims(vars)) {
      if (this.selectedAll) {
        this.selected = this.claims.map((c) => c.providerClaimId)
      } else {
        this.selected = []
      }
    }
  }

  /**
   * Launch modal to allow saving the current search
   *
   * @memberof ClaimsListPage
   */
  saveSearch(): void {
    let ref = this.modal.open(SaveSearchComponent, { size: 'lg', centered: true })
    ref.componentInstance.savedSearch = this.search

    ref.result.then(
      (close) => {
        this.search = close
        return
      },
      (dismiss) => {},
    )
  }

  /**
   * Launch modal to allow loading a previously saved search
   *
   * @memberof ClaimsListPage
   */
  loadSearch(): void {
    let ref = this.modal.open(LoadSearchComponent, { size: 'xl', centered: true })

    ref.result.then(
      (close) => {
        this.search = close
        return
      },
      (dismiss) => {},
    )
  }

  /**
   * Add claim to list of selected claims
   *
   * @param {string} providerClaimID
   * @memberof ClaimsListPage
   */
  selectClaim(providerClaimID: string): void {
    if (this.selected.includes(providerClaimID)) {
      pull(this.selected, providerClaimID)
      this.selectedAll = false
    } else {
      this.selected.push(providerClaimID)
    }
  }

  /**
   * Select all visible claims
   *
   * @param {Event} $event
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async selectAllClaims($event: Event): Promise<void> {
    $event.preventDefault()
    let target = $event.target as HTMLInputElement

    if (target.checked || target.indeterminate) {
      this.selected = this.claims.map((c) => c.providerClaimId)
    } else {
      this.selected = []
      this.selectedAll = false
    }
  }

  /**
   * Select all claims in result set
   *
   * @memberof ClaimsListPage
   */
  selectAllAllClaims(): void {
    this.selectedAll = true
    this.selected = this.claims.map((c) => c.providerClaimId)
  }

  /**
   * Get list of select claim ids
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async getSelected(): Promise<void> {
    let limiter = new Bottleneck({
      maxConcurrent: 6,
      minTime: 333,
      trackDoneStatus: true, // this might be problematic!
    })

    let jitters = {}

    limiter.on('failed', (error, info) => {
      if (info.retryCount < 10) {
        let previous = info.retryCount === 0 ? 0 : jitters[info.options.id]
        let jit = jitter(previous, info.retryCount + 1)
        jitters[info.options.id] = jit
        return jit
      }
    })

    let call = (str, offset, limit, search, scripts) => {
      return this.claimsService.getClaims(OnlyProviderClaimIds, { sort: '', offset, limit, search, scripts }, false)
    }
    let wrapped = limiter.wrap(call)

    let offset = 0
    let offsets = []
    let limit = 100
    while (offset < this.meta.total && offset < this.maxSelectAll) {
      offsets.push(offset)
      offset += limit
    }
    let modalRef = this.modal.open(ProgressModalComponent, {
      size: 'lg',
      centered: true,
    })
    modalRef.componentInstance.title = 'Selecting all claims...'
    modalRef.componentInstance.total = offsets.length
    modalRef.componentInstance.current = 0

    let interval = setInterval(() => {
      let count = limiter.counts()
      modalRef.componentInstance.current = count.DONE
      if (count.DONE === offsets.length) {
        modalRef.dismiss()
      }
    }, 1000)

    modalRef.result.then(
      () => {},
      () => {
        clearInterval(interval)
        limiter.stop({ dropWaitingJobs: true })
      },
    )

    try {
      let { search, scripts } = facetsToClaimQueryParams(this.claimSearch)
      let calls = await Promise.all(offsets.map((offset) => wrapped('', offset, limit, search, scripts)))
      modalRef.componentInstance.current = offsets.length
      modalRef.dismiss()
      this.selected = flatten(calls).map((claim: Claim) => claim.providerClaimId)
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not select all claims'), JSON.stringify(e))
      return
    }
  }

  /**
   * Set / unset a claim as a favorite
   *
   * @param {Event} $event
   * @param {string} claimId
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async toggleFav($event: Event, claimId: string): Promise<void> {
    $event.stopPropagation()
    if (!this.favorited.includes(claimId)) {
      try {
        const claim: Claim = find(this.claims, (c) => c.id === claimId)
        await this.favs.addFavoriteClaim(claim)
        this.favorited.push(claimId)
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not add claim to favorites'), JSON.stringify(e))
      }
    } else {
      try {
        await this.favs.removeFavoriteClaim(claimId)
        pull(this.favorited, claimId)
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not remove claim from favorites'), JSON.stringify(e))
      }
    }
  }

  /**
   * Navigate to new pages while passing list of selected claims
   * as navigation state
   *
   * @private
   * @param {any[]} navigateCommands
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  private async navigateWithClaims(navigateCommands: any[]): Promise<void> {
    if (this.selectedAll) {
      await this.getSelected()
    }
    this.router.navigate(navigateCommands, {
      state: { data: { providerClaimIDs: this.selected } },
    })
  }

  assignTasksToClaims(): void {
    this.navigateWithClaims(['admin/task-types/assign'])
  }

  deleteTasksOnClaims(): void {
    this.navigateWithClaims(['admin/tasks/delete'])
  }

  assignWorkflowsToClaims(): void {
    this.navigateWithClaims(['admin/workflows/assign'])
  }

  /**
   * Helper to allow a user to enter a list of claim IDs
   * to export if no claims are selected in the list
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  async prepareClaimExport(): Promise<void> {
    if (this.selectedAll || this.selected.length > 0) {
      let total: number
      if (this.selectedAll) {
        total = this.meta?.total > this.maxSelectAll ? this.maxSelectAll : this.meta?.total
      } else {
        total = this.selected.length
      }
      let call = (i) => {
        return this.claimsService.getClaimsForExport(this.claimSearch, i * 100, 100)
      }
      await this.exportClaims(call, total)
    } else {
      let modalRef = this.modal.open(ClaimsExportListComponent, {
        size: 'lg',
        centered: true,
      })

      modalRef.result.then(
        async (listOfClaims: string[]) => {
          let total = listOfClaims.length

          let selectedChunks = chunk(
            listOfClaims.map((id) => ({ value: id, display: id })),
            100,
          )
          let chunkedCall = (i) => {
            let facet: Facet = Object.assign(
              {},
              FACETS.find((f) => f.id === 'providerClaimId'),
            )
            facet.selected = selectedChunks[i]
            return this.claimsService.getClaimsForExport([facet], 0, 100)
          }
          await this.exportClaims(chunkedCall, total)
        },
        (cancel) => {},
      )
    }
  }

  /**
   * Export claims matching a search as csv for download
   *
   * @private
   * @param {(i: number) => any} call
   * @param {number} total
   * @return {*}  {Promise<void>}
   * @memberof ClaimsListPage
   */
  private async exportClaims(call: (i: number) => any, total: number): Promise<void> {
    try {
      let limiter = new Bottleneck({
        maxConcurrent: 6,
        minTime: 333,
        trackDoneStatus: true, // this might be problematic!
      })

      let jitters = {}

      limiter.on('failed', (error, info) => {
        if (info.retryCount < 10) {
          let previous = info.retryCount === 0 ? 0 : jitters[info.options.id]
          let jit = jitter(previous, info.retryCount + 1)
          jitters[info.options.id] = jit
          return jit
        }
      })

      let wrapped = limiter.wrap(call)

      let totalCalls = total > 100 ? [...Array(Math.round(total / 100)).keys()] : [0]

      let modalRef = this.modal.open(ProgressModalComponent, {
        size: 'lg',
        centered: true,
      })
      modalRef.componentInstance.title = 'Exporting claims...'
      modalRef.componentInstance.total = totalCalls.length
      modalRef.componentInstance.current = 0

      let interval = setInterval(() => {
        let count = limiter.counts()
        modalRef.componentInstance.current = count.DONE
      }, 1000)

      modalRef.result.then(
        () => {},
        () => {
          clearInterval(interval)
          limiter.stop({ dropWaitingJobs: true })
        },
      )
      try {
        let claimsToExport
        if (this.selectedAll || !this.selected.length) {
          claimsToExport = await Promise.all(totalCalls.map((num) => wrapped(num)))
          modalRef.componentInstance.current = totalCalls.length
        } else {
          claimsToExport = this.claims.filter((c) => this.selected.includes(c.providerClaimId))
        }
        modalRef.dismiss()

        let flat: Claim[] = flatten(claimsToExport)
        if (flat.length) {
          let csvArray = this.claimsService.claimsToCsv(flat, this.columns)
          let blob = new Blob([csvArray], { type: 'text/csv' })
          saveAs(blob, 'claims-export.csv')
        }
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not export claims'), JSON.stringify(e))
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not export claims'), JSON.stringify(e))
    }
  }
}
