<div class="card detail-card">
  <div class="card-header">Employee Productivity Details</div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <h5 class="my-4">Metric - Tasks</h5>
        <form [formGroup]="tasksMetricForm">
          <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="model">
            <label class="btn-outline-alt-steel-blue" ngbButtonLabel>
              <input type="radio" value="created" ngbButton /> Created
            </label>
            <label class="btn-outline-alt-steel-blue" ngbButtonLabel>
              <input type="radio" value="completed" ngbButton /> Completed
            </label>
            <label class="btn-outline-alt-steel-blue" ngbButtonLabel>
              <input type="radio" value="rejected" ngbButton /> Rejected
            </label>
          </div>
        </form>
      </div>
      <div class="col-lg-4">
        <h5 class="my-4">Employees</h5>
        <app-user-search (onSelect)="addUser($event)" [disabledUserIds]="selectedUserIds.getValue()"></app-user-search>
        <ul class="list-unstyled my-4 height-restrict">
          <li *ngFor="let user of selectedUsers" class="d-flex justify-content-between text-dark">
            <span>
              <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeUser(user)"></i
              >{{ user?.name || user.email }}
            </span>
          </li>
        </ul>
      </div>
      <div class="col-lg-4">
        <h5 class="my-4">Time Range</h5>
        <app-datepicker-range (rangeSelected)="rangeSelected($event)"></app-datepicker-range>
      </div>
    </div>
    <hr class="w-75" />
    <div class="row">
      <div class="col">
        <canvas
          baseChart
          [datasets]="data"
          [options]="chartOptions"
          [legend]="chartLegend"
        >
        </canvas>
      </div>
    </div>
  </div>
</div>
