<label for="facet-search" class="sr-only">Add a search filter</label>
<input
  type="text"
  id="facet-search"
  class="form-control mb-2"
  placeholder="Add a search filter"
  data-t="facetSearchBar"
  container="body"
  [ngbTypeahead]="search"
  [resultFormatter]="resultFormatter"
  [inputFormatter]="inputFormatter"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  (selectItem)="onSelectItem($event, element)"
  #input="ngbTypeahead"
  #element
/>
<div
  class="mt-3"
  *ngFor="let facet of facetService.facets$ | async; trackBy: trackById"
  [attr.data-t]="facet?.id + 'Facet'"
>
  <ng-template appDynamicComponent [component]="facet?.component" [data]="facet"></ng-template>
</div>
