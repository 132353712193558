<div class="row full-height">
  <div class="col-lg-12 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1 overflow-hidden">
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body flex-basis-lg-0">
              <h3 class="text-center">Claims</h3>
              <hr class="w-75 mx-auto" />
              <textarea [formControl]="claimIds" class="form-control flex-shrink-0" cols="20" rows="6"></textarea>
              <hr class="w-75 mx-auto" />
              <h4 class="text-center">Parsed {{claims?.length | number}} Claims</h4>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body flex-basis-lg-0">
              <h3 class="text-center">Workflows</h3>
              <hr class="w-75 mx-auto" />
              <table class="table table-janus table-striped" data-t="workflows-table">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">
                      <label for="selectAll" class="sr-only">Select All Workflows</label>
                      <div class="form-check">
                        <input
                          id="selectAll"
                          type="checkbox"
                          [checked]="workflowTypes?.length && selectedWorkflowTypes?.length === workflowTypes?.length"
                          (change)="selectAllWorkflowTypes($event)"
                        />
                      </div>
                    </th>
                    <th scope="col" class="text-left">Workflows on Claims</th>
                  </tr>
                </thead>
                <tbody *ngIf="!isFetchingWorkflow" data-t="workflow-list">
                  <tr *ngFor="let workflowType of workflowTypes" [attr.data-t]="workflowType?.name">
                    <td scope="row" class="text-center">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          [checked]="selectedWorkflowTypes?.includes(workflowType)"
                          (change)="selectWorkflowType(workflowType, $event)"
                          data-t="checkbox"
                        />
                      </div>
                    </td>
                    <td>{{workflowType?.name}}</td>
                  </tr>
                </tbody>
              </table>
              <app-loading
                *ngIf="isFetchingWorkflow"
                innerClass="fa-4x"
                outerClass="align-self-center text-brand-light-blue"
                class="d-flex flex-column flex-grow-1 justify-content-center"
              ></app-loading>
              <div
                *ngIf="!isFetchingWorkflow && fetchedClaims?.length && !workflowTypes?.length"
                class="d-flex justify-content-center alert alert-danger w-100"
                role="alert"
                scope="rows"
              >
                No workflows found on claims.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="delete-workflows-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger px-5"
            data-t="delete-workflows-button"
            [disabled]="claims?.length < 1 || selectedWorkflowTypes?.length < 1"
            (click)="confirmWorkflowDelete()"
          >
            Delete
            <app-loading *ngIf="isDeleting" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
