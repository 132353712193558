import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import {
  MultiselectTypeaheadFilter,
  MultiselectTypeaheadSearchFunc,
} from 'app/shared/components/multiselect-typeahead/multiselect-typeahead.component'
import { VxPageTypeService } from 'app/vision-x/services/vx-page-type.service'
import { VxPageType } from 'generated/graphql'
import { sortBy } from 'lodash'
import { Subject } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * Records that the template will use to create controls (checkboxes) for VxPageTypes returned by the API from the search.
 * We subscribe to the controls so that we can emit to the @DocumentFilterComponent which page types to filter on.
 */
export type VxPageTypeFilter = MultiselectTypeaheadFilter<VxPageType>

export type VxPageTypeMap = { [pageTypeId: string]: VxPageType }
export const uncategorizedPageType = { id: 'uncategorized', name: 'Uncategorized' } as VxPageType

@Component({
  selector: 'app-vx-page-type-multiselect-typeahead',
  templateUrl: './vx-page-type-multiselect-typeahead.component.html',
  styleUrls: ['./vx-page-type-multiselect-typeahead.component.scss'],
})
export class VxPageTypeMultiSelectTypeaheadComponent implements OnDestroy {
  // Value is used in `ngOnChanges` to populate `selectedVxPageTypeIds$`.
  @Input() selectedVxPageTypeIds: string[] = []

  /**
   * Emits changes when a `VxPageType` is selected or deselected as a document filter.
   */
  @Output() onSelect = new EventEmitter<VxPageTypeFilter[]>()

  /**
   * Emitted when the component is destroyed.
   */
  destroy$ = new Subject<void>()

  /**
   * Return an observable that fetches page types for a given search string and
   * sorts them by case-insensitive name.
   *
   * Always includes the uncategorized page type at the top.
   *
   * @param search Search string
   */
  fetchOnSearch: MultiselectTypeaheadSearchFunc<VxPageType> = (search: string) =>
    this.vxPageTypeService.fetchVxPageTypes(search, undefined).pipe(
      map((result) => {
        const records: VxPageType[] = sortBy(result?.data?.vxPageTypes?.entities ?? [], ({ name }) =>
          name?.toLowerCase(),
        )

        return [uncategorizedPageType, ...records]
      }),
    )

  constructor(public vxPageTypeService: VxPageTypeService) {}

  get placeholderText(): string {
    const selectedCount = this.selectedVxPageTypeIds.length

    if (selectedCount === 0) {
      return 'Page Types'
    }

    return `Page Types (${selectedCount.toLocaleString()})`
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
