<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar">
    <div class="card my-1 p-0 border-0 bg-transparent">
      <div class="card-header border-0 bg-primary text-white">
        {{botJob?.id ? 'Edit Bot Job Details' : 'Add Bot Job'}}
      </div>
      <div class="card-body mt-2 px-5 bg-white shadow">
        <form [formGroup]="form" class="d-flex flex-column px-4">
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="name"
                  id="name"
                  [appFcBsValidation]="form.get('name')"
                />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="command">Command</label>
                <input type="text" class="form-control" formControlName="command" id="command" [appFcBsValidation]="form.get('command')" />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="portal">Portal</label>
                <input type="text" class="form-control" formControlName="portal" id="portal" [appFcBsValidation]="form.get('portal')" />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <label>Task Type</label>
              <app-task-type-typeahead
                [selectedTaskType]="selectedTaskType?.name"
                (onSelect)="pickedTaskType($event)"
              ></app-task-type-typeahead>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="credentialType">Credential Type</label>
                <input type="text" class="form-control" formControlName="credentialType" id="credentialType" />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="credentialTypeModifier">Credential Type Modifier</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="credentialTypeModifier"
                  id="credentialTypeModifier"
                />
              </div>
            </div>
          </div>
          <div *ngIf="!isLoading && !isLegacyPayer" class="row my-3" data-testid="non-legacy-payer-div">
            <div
              class="col-12 col-md-6 col-xl-4"
              [ngClass]="{'col-md-12': selectedPayerName, 'col-xl-8': selectedPayerName }"
            >
              <div class="row">
                <div class="col-12" [ngClass]="{'col-sm-6': selectedPayerName }">
                  <label>Payer <span class="text-muted">(optional)</span></label>
                  <div>
                    <app-payerplan-payer-typeahead
                      (onSelect)="pickedPayer($event)"
                      [disabledPayerNames]="[selectedPayerName]"
                    ></app-payerplan-payer-typeahead>
                  </div>
                </div>
                <div class="col-12 col-sm-6 pl-sm-0 mt-2 mt-sm-0" *ngIf="selectedPayerName">
                  <label [ngClass]="{'text-muted' : !selectedPayerName}">Plan</label>
                  <div>
                    <app-payerplan-plan-typeahead
                      #ctrlPlan
                      (onAdd)="addPlan($event)"
                      [disabledPlanIds]="selectedPayerPlanIds"
                      [payerName]="selectedPayerName"
                      [disabled]="!selectedPayerName"
                    ></app-payerplan-plan-typeahead>
                  </div>
                </div>
              </div>
              <ul class="list-unstyled my-4 height-restrict">
                <li *ngFor="let payerPlan of selectedPayerPlans; trackBy: trackById">
                  <i class="fa fa-times-circle-o text-danger mr-4" (click)="removePayerPlan(payerPlan)"></i>{{payerPlan
                  | payerPlan}}
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="!isLoading && isLegacyPayer" class="row my-3">
            <div class="col-md-6 col-xl-4">
              <label>Payers</label>
              <app-payer-typeahead
                (onSelect)="pickedLegacyPayer($event)"
                [disabledPayerIds]="selectedPayersIds"
                [orgId]="botJob?.orgId"
              ></app-payer-typeahead>
              <ul class="list-unstyled my-4 height-restrict">
                <li *ngFor="let payer of selectedPayers; trackBy: trackById" data-testid="selected-payer-list-item">
                  <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeLegacyPayer(payer)"></i>{{ payer?.name
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <div class="form-group">
                <label for="org">Organization </label>
                <app-organization-typeahead [autoFocus]="false" *ngIf="!orgCtrl.value.length" [showError]="orgCtrl.touched && !!orgCtrl.invalid" (onSelect)="pickedOrg($event)" id="org"></app-organization-typeahead>
              </div>
              <ul class="list-unstyled my-4 height-restrict">
                <li *ngFor="let org of orgCtrl.value; trackBy: trackById" data-testid="selected-org-list-item">
                  <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeOrg(org)"></i>{{ org.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-xl-4">
              <label for="contactEmails"> Contact Emails </label>
              <div class="input-group form-group">
                <input
                  #emailEl
                  type="email"
                  class="form-control"
                  id="contactEmails"
                  [formControl]="contactEmail"
                  [appFcBsValidation]="contactEmail"
                />
                <div class="input-group-append">
                  <span class="in-input-icon d-flex align-items-center" aria-label="Add Email">
                    <button
                      type="button"
                      (click)="addContactEmail()"
                      class="btn bg-transparent"
                      ngbTooltip="Add Email"
                      container="body"
                    >
                      <i class="fa fa-plus fa-fw text-brand-dark-gray"></i>
                    </button>
                  </span>
                </div>
              </div>
              <ul class="list-unstyled my-4 height-restrict">
                <li
                  *ngFor="let email of contactEmails; let i = index"
                  class="d-flex align-items-center"
                  data-testid="contact-email-list-item"
                >
                  <button
                    class="btn"
                    (click)="removeContactEmail(i)"
                    aria-label="Remove email"
                    ngbTooltip="Remove Email"
                    container="body"
                  >
                    <i class="fa fa-times-circle-o text-danger mr-4"></i>
                  </button>
                  {{ email }}
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <div class="custom-control custom-switch text-nowrap pl-5">
                <input class="custom-control-input" type="checkbox" id="enabled" formControlName="enabled" />
                <label class="custom-control-label ml-4" for="enabled">Enabled</label>
              </div>
              <div class="custom-control custom-switch text-nowrap pl-5">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  id="teleportEnabled"
                  formControlName="teleportEnabled"
                />
                <label class="custom-control-label ml-4" for="teleportEnabled">Teleport</label>
              </div>
              <div class="custom-control custom-switch text-nowrap pl-5">
                <input class="custom-control-input" type="checkbox" id="beta" formControlName="beta" />
                <label class="custom-control-label ml-4" for="beta">Beta</label>
              </div>
              <div
                class="custom-control custom-switch text-nowrap pl-5"
                ngbTooltip="NOTE: Enabling this setting does not guarantee that ZKeep will be used. The user must also have the extension installed & enabled."
                placement="top-left"
              >
                <input class="custom-control-input" type="checkbox" id="zyncAllowed" formControlName="zyncAllowed" />
                <label class="custom-control-label ml-4" for="zyncAllowed">Zync Allowed</label>
              </div>
            </div>
          </div>
          <div class="row mt-5 mb-3">
            <div class="col-md-6 col-xl-4 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary mr-1"
                data-t="delete-bot-job-button"
                (click)="confirmDelete()"
              >
                <i class="fa fa-trash mr-3"></i>
                Delete
              </button>
              <a class="btn btn-cancel mx-1" routerLink="/admin/bot-jobs"> Cancel </a>
              <button type="button" (click)="saveBotJob()" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-bot-job-button">
                <i class="fa fa-floppy-o mr-3"></i>
                Save
                <app-loading *ngIf="saving" class="pl-2"></app-loading>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
