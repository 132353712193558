import { Component, Input, OnInit } from '@angular/core'
import { getPropDetails } from 'app/shared/types/claim'
import { EhrPayment, PayerPayment } from 'generated/graphql'

/**
 * Component to display a claim's payments
 *
 * @export
 * @class ClaimPaymentsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-claim-payments',
  templateUrl: './claim-payments.component.html',
  styleUrls: ['./claim-payments.component.scss'],
})
export class ClaimPaymentsComponent implements OnInit {
  @Input() payments: { ehr: EhrPayment[]; payer: PayerPayment[] } = null

  getPropDetails = getPropDetails

  constructor() {}

  ngOnInit(): void {}
}
