<app-auth-layout-component>
  <app-org-switcher
    *ngIf="shouldWaitForUserToSelectOrg$ | async; else isLoading"
  ></app-org-switcher>
</app-auth-layout-component>

<ng-template #isLoading>
  <app-loading
    innerClass="fa-3x"
    class="d-flex justify-content-center text-brand-light-blue"
  ></app-loading>
</ng-template>
