<div class="row">
  <div class="col-lg-3 col-sm-9">
    <div class="input-group form-group">
      <input
        [formControl]="reportSearch"
        (keyup.enter)="searchNavigate()"
        type="text"
        class="form-control"
        placeholder="Search Reports"
      />
      <div class="input-group-append">
        <span class="in-input-icon d-flex align-items-center">
          <button type="button" (click)="searchNavigate()" class="btn bg-transparent">
            <i class="fa fa-search fa-fw"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div class="col" *ngIf="authenticationService?.isSuper()">
    <a routerLink="/reports/upload" class="btn btn-primary mb-3 mx-2"> <i class="fa fa-upload mr-2"></i> Upload </a>
  </div>
</div>
<div class="d-flex flex-column flex-grow-1">
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="report-table">
      <thead>
        <tr>
          <th scope="col" class="width-1">Link</th>
          <th scope="col" class="width-1">Download</th>
          <th scope="col" class="text-left">Name</th>
          <th scope="col" class="text-left">Created At</th>
          <th scope="col" class="text-left">Category</th>
          <th scope="col" *appPermissions="canDeleteReportConfig" class="width-1">
            <span class="sr-only"> Delete Report </span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="!isLoading && reports?.length">
        <tr *ngFor="let report of reports" class="clickable" data-t="report-card">
          <td class="text-right">
            <button
              type="button"
              class="btn btn-link copy-btn py-0"
              [attr.data-clipboard-text]="currentRoute + report?.id"
            >
              <i class="fa fa-fw fa-lg fa-link"></i>
            </button>
          </td>
          <td class="text-center">
            <button
              *ngIf="report?.link && report?.id != downloading"
              type="button"
              class="btn btn-link p-0"
              data-t="download-report-button"
              ngbTooltip="Download Report"
              container="body"
              (click)="downloadReport(report?.id)"
            >
              <i class="fa fa-fw fa-lg fa-file-text-o"></i>
            </button>
            <app-loading *ngIf="report?.id === downloading" class="pl-2 text-primary"></app-loading>
          </td>
          <td>{{report?.name}}</td>
          <td>{{report?.createdAt ? (report.createdAt | date: 'MM/dd/yyyy hh:mm a') : 'Unknown'}}</td>
          <td>{{report?.category}}</td>
          <td class="text-right" *appPermissions="canDeleteReportConfig">
            <button
              type="button"
              class="btn btn-link p-0"
              data-t="delete-report-button"
              (click)="confirmDelete(report?.id)"
              ngbTooltip="Delete report"
              container="body"
            >
              <i class="fa fa-fw fa-lg fa-trash text-dark"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="isLoading"
      innerClass="fa-4x"
      outerClass="align-self-center text-brand-light-blue"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div *ngIf="!reports?.length && !isLoading" class="d-flex justify-content-center alert alert-danger" role="alert">
      Your search returned no results.
    </div>
  </div>

  <div class="d-flex align-items-baseline justify-content-end mb-4" *ngIf="!isLoading && reports?.length">
    <small class="mr-3">
      {{page * pageSize - pageSize + 1 | number}} - {{reports?.length < pageSize ? meta?.total : page * pageSize |
      number}} of {{meta?.total | number}}
    </small>
    <ngb-pagination
      [collectionSize]="meta?.total"
      (pageChange)="searchReports($event)"
      [pageSize]="pageSize"
      [(page)]="page"
      [maxSize]="5"
      *ngIf="meta?.total >= pageSize"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
</div>
