import { Component, EventEmitter, Output } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { Organization } from 'generated/graphql'

@Component({
  selector: 'app-create-org',
  templateUrl: './create-org.component.html',
  styleUrls: ['./create-org.component.scss'],
  host: {
    class: 'h-100',
  },
})
export class CreateOrgComponent {
  @Output() onSave = new EventEmitter<Organization>()
  @Output() onCancel = new EventEmitter()

  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    orgSetId: new UntypedFormControl(null),
    testOrg: new UntypedFormControl(false),
  })

  constructor(private toast: ToastService) {}

  save(): void {
    this.form.markAllAsTouched()
    if (this.form.invalid) {
      this.toast.error('Please fill in all required fields', JSON.stringify(readableFormGroupErrors(this.form)))
      return
    }
    this.onSave.emit(this.form.value)
  }
}
