import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TaskCardActionsService } from 'app/admin/task-types/task-card-actions.service'
import { TaskType } from 'generated/graphql'
import { Subscription } from 'rxjs'

/**
 * Page to display and search a list of task types to edit
 *
 * @export
 * @class TaskTypeEditPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-type-edit-page',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss'],
})
export class TaskTypeEditPage implements OnInit, OnDestroy {
  actionSub$: Subscription

  constructor(private router: Router, private actionService: TaskCardActionsService) {
    this.actionSub$ = actionService.pencilClicked$.subscribe((taskType: TaskType) => {
      this.router.navigate([`admin/task-types/edit/${taskType.id}`])
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.actionSub$.unsubscribe()
  }
}
