import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { OrgService } from 'app/admin/org/org.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import bsCustomFileInput from 'bs-custom-file-input'
import { Subscription } from 'rxjs'

/**
 * Page to allow uploading a new logo for the current organization
 *
 * @export
 * @class OrgLogoPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-org-logo-page',
  templateUrl: './logo.page.html',
  styleUrls: ['./logo.page.scss'],
})
export class OrgLogoPage implements OnInit, OnDestroy {
  logo = new UntypedFormControl(null)
  imgSrc = null
  logo$: Subscription

  constructor(private org: OrgService, private toast: ToastService) {}

  async ngOnInit(): Promise<void> {
    bsCustomFileInput.init()
    let reader = new FileReader()
    reader.onload = (e) => {
      this.imgSrc = e?.target?.result
    }
    this.logo$ = this.logo.valueChanges.subscribe((value) => {
      reader.readAsDataURL(value)
    })
    try {
      let currentLogo = await this.org.getOrgLogo()
      reader.readAsDataURL(currentLogo)
    } catch (e) {
      debug('logo', 'error loading current logo', JSON.stringify(e))
    }
  }

  ngOnDestroy(): void {
    bsCustomFileInput?.destroy()
  }

  /**
   * Save the organization's logo
   *
   * @return {Promise<void>}
   * @memberof OrgLogoPage
   */
  async submit(): Promise<void> {
    try {
      await this.org.setOrgLogo(this.logo.value as File)
      this.toast.success('New logo saved')
      setTimeout(() => {
        document.location.reload()
      }, 1000) // to speed up application, lower this number
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save logo'), JSON.stringify(e))
    }
  }
}
