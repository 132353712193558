import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'app/shared/services/toast.service'
import { BehaviorSubject, combineLatest, concat, Observable, of, Subject } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators'
import { LoadingComponent } from '../loading/loading.component'

/**
 * Type of data that is returned by the search function.
 *
 * Here, `id` is required because that is used to identify form controls.
 */
export interface MultiselectTypeaheadData {
  id: string
}

/**
 * Each filter will have a control associated with a data record.
 */
export interface MultiselectTypeaheadFilter<T extends MultiselectTypeaheadData> {
  control: FormControl
  data: T
}

/**
 * Search function that is triggered when the search text input changes.
 */
export type MultiselectTypeaheadSearchFunc<T extends MultiselectTypeaheadData> = (s: string) => Observable<T[]>

@Component({
  selector: 'app-multiselect-typeahead',
  templateUrl: './multiselect-typeahead.component.html',
  styleUrls: ['./multiselect-typeahead.component.scss'],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoadingComponent, NgbModule],
  standalone: true
})
export class MultiSelectTypeaheadComponent<T extends MultiselectTypeaheadData> implements OnChanges, OnDestroy, OnInit {
  @Input() iconButton: boolean = false
  @Input() fetchOnSearch: MultiselectTypeaheadSearchFunc<T>
  @Input() filterLabel: TemplateRef<any>
  @Input() placeholderText: string = ''
  @Input() selectedIds: string[] = []
  @Input() reset$: BehaviorSubject<unknown>

  /**
   * Emits a list of selected filters that will include the data and the form
   * control so callees know it's "active" status.
   */
  @Output() onSelect = new EventEmitter<MultiselectTypeaheadFilter<T>[]>()

  @ViewChild('searchInput') searchInput: ElementRef

  /**
   * Form group that contains all the form controls for the items. Each control
   * key will use the `id` property for the associated item record.
   */
  formGroup = this.fb.group({})

  /**
   * Prefix added to all inputs of this component so they don't interfere with
   * other instances of the component.
   */
  inputPrefix = 'msta-' + Math.random().toString(16).slice(2)

  /**
   * Emitted when the component is destroyed.
   */
  destroy$ = new Subject<void>()

  /**
   * Emitted when the text in the search input changes.
   */
  search$ = new BehaviorSubject<string>('')

  /**
   * Emitted when the list of selected IDs passed as input changes.
   */
  selectedIds$ = new BehaviorSubject<string[]>([])

  /**
   * Debounced version of `searchText$` that debounces for all values *except*
   * the first value.
   */
  debouncedSearch$ = concat(this.search$.pipe(take(1)), this.search$.pipe(debounceTime(375))).pipe(
    distinctUntilChanged(),
  )

  /**
   * When true, it means we are currently loading search data.
   *
   * True to start because we will always be immediately searching.
   */
  searching$ = new BehaviorSubject<boolean>(true)

  /**
   * Internal subject used to trigger data re-evaluation in response to the external `reset$` input.
   * When `resetData$` emits, it causes `data$` to re-fetch and update its data based on the latest search input.
   */
  resetData$ = new BehaviorSubject<null>(null)

  /**
   * Emits search data. Will emit after every change to the search input.
   */
  data$ = combineLatest([this.debouncedSearch$, this.resetData$]).pipe(
    tap(() => this.searching$.next(true)),
    switchMap(([search]) =>
      combineLatest([
        this.fetchOnSearch(search).pipe(
          catchError((error) => {
            this.toast.error(error?.message ?? error)
            return of([] as T[])
          }),
        ),
        this.selectedIds$,
      ]),
    ),
    tap(() => this.searching$.next(false)),
    takeUntil(this.destroy$),
  )

  /**
   * Emits mapped search data to filter objects containing a form control for
   * every record.
   */
  filters$ = this.data$.pipe(
    tap(([items]) => this.removeStaleControls(items)),
    map(([items, selectedIds]) =>
      items.map((data) => {
        const selected = selectedIds.includes(data.id)
        let control = this.formGroup.get(data.id) as FormControl<boolean>
        if (!control) {
          control = new FormControl<boolean>(selected)
          this.formGroup.setControl(data.id, control)

          control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
            this.onSelect.emit([{ control, data }])
          })
        } else {
          control.setValue(selected, { emitEvent: false })
        }

        return { control, data, selected }
      }),
    ),
    shareReplay(),
  )

  /**
   * Emits search data marked as selected.
   */
  selectedFilters$ = this.filters$.pipe(map((items) => items.filter(({ selected }) => selected)))

  /**
   * Emits search data marked as unselected.
   */
  unselectedFilters$ = this.filters$.pipe(map((items) => items.filter(({ selected }) => !selected)))

  /**
   * Form control for the search input.
   */
  searchFormControl: FormControl<string> = new FormControl<string>('')

  /**
   * All filters, regardless of selection status. Used in "Select all" and
   * "Select none" buttons.
   */
  allFilters: MultiselectTypeaheadFilter<T>[] = []

  constructor(public fb: FormBuilder, public toast: ToastService) {}

  ngOnInit(): void {
    this.filters$.subscribe((filters) => {
      this.allFilters = [...filters]
    })

    if (this.reset$) {
      this.reset$.pipe(
        tap(() => {
          if (!this.search$.value) {
            this.resetData$.next(null)
          } else {
            this.searchFormControl.patchValue('')
            this.search$.next('')
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { selectedIds } = changes

    if (selectedIds !== undefined) {
      this.selectedIds$.next(selectedIds.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Remove controls with keys no longer found in the set of item records.
   */
  removeStaleControls(items: T[]): void {
    const itemIds = items.map(({ id }) => id)

    Object.keys(this.formGroup.controls).forEach((key) => {
      if (!itemIds.includes(key)) {
        this.formGroup.removeControl(key)
      }
    })
  }

  /**
   * Set the vlaue for all controls to `value`, then emit `onSelect()` with all
   * filters.
   */
  toggleAll(value: boolean): void {
    const filtersToUpdate = this.allFilters.filter((filter) => filter.control.value !== value)

    filtersToUpdate.forEach((filter) => {
      filter.control.setValue(value, { emitEvent: false })
    })

    this.onSelect.emit(filtersToUpdate)
  }

  /**
   * @deprecated Use the @see reset$ input instead.
   */
  resetSearch(): void {
    this.searchFormControl.patchValue('')
    this.search$.next('')
  }
}
