<div class="d-flex">
  <div class="flex-grow-1">
    <app-search-field [label]="'Search Task Groups'" [search]="taskGroupSearch" [searching]="searching">
    </app-search-field>
  </div>
  <div class="flex-shrink-1">
    <button type="button" class="btn btn-brand-green font-weight-bold ml-2" (click)="createTaskGroup()">
      <i class="fa fa-fw fa-plus-circle" data-t="create-task-button"></i>
    </button>
  </div>
</div>

<small class="d-block text-center" *ngIf="taskGroups?.length">
  Showing {{ taskGroups.length - actions.hidden.length }} of about {{ totalTaskGroups }} Task Groups
</small>

<ng-template ngFor let-tg [ngForOf]="taskGroups" let-i="index">
  <app-task-group-card
    [class.d-none]="actions.hidden.includes(tg.id)"
    [taskGroup]="tg"
    [isCollapsed]="isCollapsed[i]"
    [showPencil]="showPencil"
    [showRight]="showRight"
    [attr.data-t]="'group-' + tg.name"
  >
  </app-task-group-card>
</ng-template>
