<div class="row full-height">
  <div class="col-lg-3 scrolling-sidebar left-column">
    <app-task-group-search
      showRight="Assign"
      (onTaskGroupCreate)="assignCreatedTaskGroup($event)"
    ></app-task-group-search>
  </div>

  <div class="col-lg-9 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1 overflow-hidden">
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body flex-basis-lg-0">
              <h3 class="text-center">Task Group</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <ng-template ngFor let-tg [ngForOf]="taskGroups" let-i="index">
                  <app-task-group-card
                    [taskGroup]="tg"
                    [isCollapsed]="taskGroupCollapsed[i]"
                    showRemove="Remove"
                    showPencil="Edit"
                  ></app-task-group-card>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column flex-grow-1 mt-5 mt-lg-0">
          <div class="card flex-grow-1">
            <div class="card-body scrolling-main flex-basis-lg-0 d-flex flex-column">
              <h3 class="text-center">Claims</h3>
              <hr class="w-75 mx-auto" />
              <textarea [formControl]="claimIds" class="form-control flex-shrink-0" cols="20" rows="6"></textarea>
              <small class="form-text text-muted"> Enter Claim IDs separated by commas, tabs, or one per line </small>
              <hr class="w-75 mx-auto" />
              <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto">
                <h5>Select which tasks to add to the Task Group</h5>
                <table
                  class="table table-janus table-striped"
                  data-t="task-types-table"
                  *ngIf="!fetchingTasks && taskTypes?.length"
                >
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">
                        <div class="form-check">
                          <label for="selectAll" class="sr-only">Select All Task Types</label>
                          <input
                            id="selectAll"
                            type="checkbox"
                            [checked]="selectedTaskTypes.length === taskTypes.length"
                            (change)="selectAllTaskTypes($event)"
                          />
                        </div>
                      </th>
                      <th scope="col" class="text-left">Task Types on Claims</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let taskType of taskTypes">
                      <td scope="row" class="text-center">
                        <div class="form-check">
                          <input
                            type="checkbox"
                            [checked]="selectedTaskTypes.includes(taskType)"
                            (change)="selectTaskType(taskType, $event)"
                          />
                        </div>
                      </td>
                      <td>{{ taskType.name }}</td>
                    </tr>
                  </tbody>
                </table>
                <app-loading
                  *ngIf="fetchingTasks"
                  innerClass="fa-4x"
                  outerClass="align-self-center text-brand-light-blue"
                  class="d-flex flex-column flex-grow-1 justify-content-center"
                ></app-loading>
                <div
                  *ngIf="!fetchingTasks && fetchedClaims.length && !tasks.length"
                  class="d-flex alert alert-danger justify-content-center"
                  role="alert"
                  data-t="no-task-warning"
                >
                  There are no tasks on the entered claims
                </div>
              </div>
              <small class="form-text text-muted">
                Note: If a claim in the list does not have the given task it will not be added to the selected Task
                Group
              </small>
              <hr class="w-75 mx-auto" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="assign-tasks-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-green px-5"
            data-t="assign-tasks-button"
            [disabled]="taskGroups.length < 1 || selectedTaskTypes.length < 1"
            (click)="save()"
          >
            Assign
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
