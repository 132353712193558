<div class="d-flex justify-content-between">
  <h5 class="align-self-end text-dark">{{ total }} Runs Found</h5>
  <button type="button" class="btn btn-outline-alt-steel-blue ml-2" (click)="open()" data-t="filter-options-button">
    <i class="fa fa-sliders"></i>
    <span *ngIf="botRunsService.filterBadge()" class="badge badge-light ml-2">
      {{ botRunsService.filterBadge() }}
    </span>
  </button>
</div>
<app-loading
  *ngIf="loading"
  innerClass="fa-4x"
  outerClass="align-self-center text-brand-light-blue"
  class="d-flex flex-column flex-grow-1 justify-content-center"
></app-loading>
<div *ngIf="!loading">
  <button
    type="button"
    class="btn btn-block btn-alt-steel-blue rounded py-2 my-2"
    *ngIf="offset !== 0"
    (click)="load('prev')"
  >
    Load Previous Runs
  </button>
  <app-bot-run-card
    *ngFor="let botRun of botRuns"
    [botRun]="botRun"
    [selectedBotRunId]="selectedBotRunId"
    [botId]="botJobId"
  ></app-bot-run-card>
  <button
    type="button"
    class="btn btn-block btn-alt-steel-blue py-2 my-2"
    *ngIf="runningTotal < total"
    (click)="load('next')"
  >
    Load Next Runs
  </button>
</div>
