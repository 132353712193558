import { Component } from '@angular/core'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import {
  Router,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RoutesRecognized,
} from '@angular/router'
import { filter } from 'rxjs/operators'

/**
 * Component to display heirarchical breadcrumbs
 * See: breadcrumbs.service.ts
 *
 * @export
 * @class BreadcrumbsComponent
 */
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent{
  constructor(private router: Router, public breadcrumbService: BreadcrumbsService) {
    router.events
      .pipe(
        filter((event) => 
          event instanceof RoutesRecognized ||
          event instanceof NavigationStart ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ),
      )
      .subscribe((event) => {
        this.breadcrumbService.routerEvent$.next(event)
      })
  }
}
