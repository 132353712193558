import { Component, OnInit } from '@angular/core'
import { StatusService } from 'app/status/status.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'

@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {
  connectivity: 'checking' | 'good' | 'bad' = 'checking'
  health: 'checking' | 'good' | 'bad' | 'slow' = 'checking'

  constructor(private status: StatusService, protected zendesk: ZendeskService) {}

  ngOnInit(): void {
    this.status
      .pingApi()
      .then((response) => {
        this.connectivity = response.pong ? 'good' : 'bad'
      })
      .catch(() => {
        this.connectivity = 'bad'
      })
    this.status
      .checkApiHealth()
      .then((response) => {
        if (response.healthy) {
          if (response.time) {
            this.health = response.time < 100 ? 'good' : 'slow'
          } else {
            this.health = 'good'
          }
        } else {
          this.health = 'bad'
        }
      })
      .catch(() => {
        this.health = 'bad'
      })
  }
}
