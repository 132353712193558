import { Component } from '@angular/core'

/**
 * Component to layout a pane
 *
 * @export
 * @class PaneComponent
 */
@Component({
  selector: 'app-pane',
  templateUrl: './pane.component.html',
  styleUrls: ['./pane.component.scss'],
  host: {
    class: 'd-flex p-4 rounded-lgr shadow-lgr',
  },
})
export class PaneComponent {}
