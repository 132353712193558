<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <fieldset [formGroup]="form">
      <div class="form-group">
        <div class="form-check" formArrayName="checks" *ngFor="let check of checksFromArray.controls; let i = index">
          <input type="checkbox" class="form-check-input" [formControlName]="i" #input [attr.id]="data.id + i" />
          <label class="form-check-label" [attr.for]="data.id + i">{{ data?.options[i]?.display }}</label>
        </div>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="negate" [attr.id]="data.id + 'negate'" />
        <label
          class="form-check-label"
          [attr.for]="data.id + 'negate'"
          ngbTooltip="Search for all terms NOT matching this one"
          container="body"
        >
          NOT
        </label>
      </div>
    </fieldset>
  </div>
</div>
