import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TaskGroupCardActionsService } from 'app/admin/task-groups/task-group-card-actions.service'
import { TaskGroup } from 'generated/graphql'
import { Subscription } from 'rxjs'

/**
 * Page to display list of task groups and allow editing them
 *
 * @export
 * @class TaskGroupEditPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-group-edit-page',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss'],
})
export class TaskGroupEditPage implements OnInit, OnDestroy {
  actionSub$: Subscription

  constructor(private router: Router, private actionService: TaskGroupCardActionsService) {
    this.actionSub$ = actionService.pencilClicked$.subscribe((taskGroup: TaskGroup) => {
      this.router.navigate([`admin/task-groups/edit/${taskGroup.id}`])
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.actionSub$?.unsubscribe()
  }
}
