import { GraphOptions } from '@antv/g6-core'

export const GRAPH_OPTIONS: Partial<GraphOptions> = {
  fitView: true,
  fitCenter: false,
  maxZoom: 2,
  layout: {
    type: 'dagre',
    nodesep: 15,
    ranksep: 20,
    controlPoints: true,
    rankdir: 'TB',
    align: 'UL',
  },
}
