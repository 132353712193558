import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Payer } from 'generated/graphql'

export type RESOLVED = {
  payer: Payer
  childrenPayers: Payer[]
}
export const PayerResolverService: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const payersService = inject(PayersService)
  const router = inject(Router)
  const toast = inject(ToastService)

  let resolved: RESOLVED = {
    payer: null,
    childrenPayers: null,
  }

  let payerId = route.paramMap.get('payerId')
  if (payerId === 'new') {
    return {}
  }
  try {
    resolved.payer = await payersService.getPayer(payerId)
    resolved.childrenPayers = await payersService.searchPayers({ parentId: payerId, includeChildren: true })
    return resolved
  } catch (e) {
    toast.error(parseGraphQLError(e, 'Could not load payer'), JSON.stringify(e))
    router.navigate(['admin', 'payers'])
    return {}
  }
}
