<div class="d-flex justify-content-between">
  <div class="col-lg-3 col-sm-9">
    <div class="input-group form-group">
      <input
        [formControl]="clientCredentialsSearch"
        (keyup.enter)="page.setValue(1)"
        type="text"
        class="form-control"
        placeholder="Search Client Credentials"
      />
      <div class="input-group-append">
        <span class="in-input-icon d-flex align-items-center">
          <button type="button" (click)="page.setValue(1)" class="btn bg-transparent">
            <i class="fa fa-search fa-fw"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
  <div>
    <a routerLink="/admin/client-credentials/new" class="btn btn-primary mb-3">Add Credential</a>
  </div>
</div>

<div class="flex-grow-1 flex-shrink-0 overflow-auto mt-4">
  <div class="position-relative">
    <div class="position-absolute w-100">
      <div class="table-responsive-md">
        <table class="table table-janus table-striped" data-t="client-credentials-table">
          <thead>
            <tr>
              <th scope="col" class="width-1">Enabled</th>
              <th scope="col" class="text-left">Type</th>
              <th scope="col" class="text-left">Username</th>
              <th scope="col" class="text-left">Email</th>
              <th scope="col" class="text-left">MFA Code</th>
              <th scope="col" class="text-right">Num. Locked</th>
              <th scope="col" class="text-right">Max. Concurrent</th>
              <th scope="col" class="text-center">Status</th>
              <th scope="col" class="text-center">Checked Out By</th>
            </tr>
          </thead>
          <tbody *ngIf="!isLoading && clientCredentials?.length">
            <tr
              *ngFor="let clientCred of clientCredentials; trackBy: trackByCredentialId"
              data-t="client-cred-row"
            >
              <td class="text-center">
                <span class="sr-only">
                  {{ !clientCred?.credential?.disabled ? 'Enabled' : 'Disabled' }}
                </span>
                <i
                  [ngClass]="
                    !clientCred?.credential?.disabled
                      ? 'fa fa-circle text-success'
                      : 'fa fa-circle-o text-secondary'
                  "
                  [ngbTooltip]="!clientCred?.credential?.disabled ? 'Enabled' : 'Disabled'"
                  container="body"
                ></i>
              </td>
              <td>
                <a
                  routerLink="/admin/client-credentials/{{ clientCred?.credential?.id }}"
                  class="text-nowrap"
                >
                  {{ clientCred?.credential?.type }}
                </a>
              </td>
              <td>{{ clientCred?.credential?.username }}</td>
              <td>{{ clientCred?.credential?.email }}</td>
              <td>{{ clientCred?.credential?.mfa }}</td>
              <td class="text-right">{{ clientCred?.credential?.locked | number }}</td>
              <td class="text-right">
                {{ getConcurrencyDisplay(clientCred) }}
              </td>
              <td class="text-center">
                <button
                  type="button"
                  aria-label="Check In Credentials"
                  *ngIf="clientCred?.credential?.checkedOutByUserId"
                  class="btn btn-alt-dark-gray"
                  (click)="checkInCredentials(clientCred?.credential?.id)"
                >
                  Checked Out
                </button>
                <button
                  type="button"
                  aria-label="Check Out Credentials"
                  *ngIf="!clientCred?.credential?.checkedOutByUserId"
                  class="btn btn-alt-green"
                  (click)="checkOutCredentials(clientCred?.credential?.id)"
                >
                  Checked In
                </button>
              </td>
              <td class="text-center">
                {{ getCheckedOutByDisplay(clientCred) }}
              </td>
            </tr>
          </tbody>
        </table>
        <app-loading
          *ngIf="isLoading"
          innerClass="fa-4x"
          outerClass="align-self-center text-brand-light-blue"
          class="d-flex flex-column flex-grow-1 justify-content-center"
        ></app-loading>
        <div
          *ngIf="!clientCredentials?.length && !isLoading"
          class="d-flex justify-content-center alert alert-danger"
          role="alert"
        >
          Your search returned no results.
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="d-flex align-items-baseline justify-content-end mt-4"
  *ngIf="!isLoading && clientCredentials?.length"
>
  <small class="mr-3">
    {{
      (page.value - 1) * pageSize + 1 | number
    }}
    -
    {{
      Math.min(page.value * pageSize, meta?.total) | number
    }}
    of
    {{ meta?.total | number }}
  </small>
  <ngb-pagination
    [collectionSize]="meta?.total"
    [pageSize]="pageSize"
    [page]="page.value"
    (pageChange)="page.setValue($event)"
    [maxSize]="5"
    *ngIf="meta?.total >= pageSize"
    [boundaryLinks]="false"
  >
  </ngb-pagination>
</div>