import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { SortEvent } from 'app/shared/directives/sortable.directive'
import { ToastService } from 'app/shared/services/toast.service'
import { ListResponseMetaData, ObserveActivityLog, ObserveActivityLogModels,  } from 'generated/graphql'
import { Subject } from 'rxjs'
import { debounceTime, first, take, takeUntil } from 'rxjs/operators'
import { ObserveActivityLogService } from '../services/observe-activity-log.service'
import { saveAs } from 'file-saver'
import { OrgService } from 'app/admin/org/org.service'
import { format } from 'date-fns'


@Component({
  selector: 'app-observe-activity-log',
  templateUrl: './observe-activity-log.component.html'
})

export class ObserveActivityLogComponent implements OnInit, OnDestroy  {
  @Input()
  orgId: string
  @Input()
  modelType: ObserveActivityLogModels

  destroy$ = new Subject<void>()
  activityLogSearchText: FormControl<string> = new FormControl('')
  creatingExport = false
  isLoading = false
  activitylistMetaData: ListResponseMetaData = {
    offset: 0,
    limit: 50,
    total: 0,
    sort: 'createdAt:desc'
  }
  observeActivityLogs: ObserveActivityLog[] = []
  saveAs = saveAs

  constructor(private observeActivityLogService: ObserveActivityLogService, private toastService: ToastService, 
    private orgService: OrgService) {}

  async ngOnInit(): Promise<void> {
    this.activityLogSearchText.valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(val => {
      this.getObserveActivityLogs()
    })
   this.getObserveActivityLogs()
  }

  async getObserveActivityLogs(): Promise<void> {
    this.isLoading = true
    try {
    const searchText = this.activityLogSearchText.value.trim() || null
    const params = {
      orgId: this.orgId,
      searchText,
      limit: this.activitylistMetaData.limit,
      offset: this.activitylistMetaData.offset,
      sort: this.activitylistMetaData.sort
    }
    const response = (await this.observeActivityLogService.getObserveActivityLogs(params.orgId, this.modelType,
      params.searchText, params.limit, params.offset, params.sort).pipe(take(1)).toPromise()).data
      this.observeActivityLogs = response.activityLogs.entities
      this.activitylistMetaData.total = response.activityLogs.meta.total || 0
    } catch (error) {
      this.toastService.error(`A error occured fetching activity logs ${error}`)
      this.observeActivityLogs = []
      this.activitylistMetaData.total = 0
    }
    this.isLoading = false
  }

  async exportData(): Promise<void> {
    this.creatingExport = true
    try {
      const data = await this.observeActivityLogService.exportObserveActivityLogs(this.orgId, ObserveActivityLogModels.ObserveUser).pipe(first()).toPromise()
      if(data.type === 'text/csv') {
          const blob = new Blob([data], {type: 'text/csv'})
          const date = format(new Date(), 'yyyy-MM-dd')
          const orgName = (await this.orgService.getOrg(this.orgId).pipe(take(1)).toPromise()).data.organization.name
          const formattedOrgName = orgName.toLowerCase()
          formattedOrgName.replace(/ /g, '-').replace(/'/g, '')
          const fileName = `Org-${formattedOrgName}-observe-activity-logs-${date}.csv`
          this.saveAs(blob, fileName)
        } else {
          this.toastService.error('A error occured generating file')
        }
        this.creatingExport = false
    } catch (err) {
      this.toastService.error('A error occured generating file')
      this.creatingExport = false
    }
  }

  fetchPage($event: PaginationUpdateEvent): void {
    const page = $event.newPageNumber 
    this.activitylistMetaData.offset = this.activitylistMetaData.limit * (page - 1)

    this.getObserveActivityLogs()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onSort($event: SortEvent): void {
    const sortDirection = $event.direction || 'desc'
   this.activitylistMetaData.sort = `createdAt:${sortDirection}`
   this.getObserveActivityLogs()
  }
}

