<div class="card detail-card my-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <i class="fa fa-lg fa-cogs mr-2" *ngIf="taskType?.isBotTask" aria-hidden="true"></i>
    <span class="flex-grow-1">{{ taskType.name }}</span>
    <span class="flex-shrink-0 pr-3">
      <button
        *ngIf="showPencil"
        class="btn btn-sm btn-link"
        (click)="clickPencil(taskType, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showPencil"
      >
        <i class="fa fa-lg fa-pencil text-light"></i>
      </button>
      <button
        *ngIf="showRight"
        class="btn btn-sm btn-link"
        (click)="clickRight(taskType, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRight"
        data-t="task-card-assign"
      >
        <i class="fa fa-2x fa-caret-right text-light pt-1"></i>
      </button>
      <button
        *ngIf="showRemove"
        class="btn btn-sm btn-link"
        (click)="clickRemove(taskType, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRemove"
      >
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
      <button
        *ngIf="showTwoRight"
        class="btn btn-sm btn-link"
        (click)="clickTwoRight(taskType, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showTwoRight"
        data-t="merge-forward-button"
      >
        <i class="fa fa-lg fa-forward text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <span [innerHTML]="taskType?.description"></span>
  </div>
</div>
