import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TeleportService } from 'app/teleport/teleport.service'
import { CreateTeleportFeedbackInput, Payer } from 'generated/graphql'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { uniqBy } from 'lodash'

@Component({
  selector: 'app-teleport-feedback',
  templateUrl: './teleport-feedback.page.html',
  styleUrls: ['./teleport-feedback.page.scss'],
})
export class TeleportFeedbackPage implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private teleportService: TeleportService,
    private toast: ToastService,
    private router: Router,
    protected zendesk: ZendeskService,
  ) {}

  rating: number = 0
  ratingDescription = new UntypedFormControl(null)
  additionalPayers: Payer[] = []

  shouldShowRequiredFieldWarning: boolean = false
  isSubmitted: boolean = false

  ngOnInit(): void {}

  /**
   * Add selected payer to payers list
   *
   * @param {Payer} payer
   */
  pickedPayer(payer: Payer): void {
    this.additionalPayers = uniqBy([...(this.additionalPayers || []), payer], 'id')
  }

  /**
   * Remove payer from additional payers list
   *
   * @param {Payer} payer
   * @memberof EditBotJobPage
   */
  removePayer(payer: Payer): void {
    let i = this.additionalPayers.findIndex((u) => u.id === payer.id)
    if (i >= 0) {
      this.additionalPayers.splice(i, 1)
    }
  }

  /**
   * Adding a new payer name to the additional payers list
   *
   * @memberof TeleportFeedbackPage
   */
  addNewPayer(payerName: string): void {
    this.additionalPayers.push({ id: null, name: payerName })
  }

  /**
   * Helper for ngFor performance
   *
   * @param {number} _index
   * @param {*} item
   * @return {*}  {string}
   * @memberof EditBotJobPage
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackById(_index: number, item: any): string {
    return item?.id
  }

  /**
   * Submit feedback
   */
  async submit(): Promise<void> {
    if (!this.rating) {
      this.shouldShowRequiredFieldWarning = true
    } else {
      this.shouldShowRequiredFieldWarning = false
      const params = this.route?.snapshot?.queryParams
      const feedback: CreateTeleportFeedbackInput = {
        payer: params?.payer,
        portal: params?.portal,
        rating: this.rating,
        review: this.ratingDescription?.value,
        newSupportedPayerIds: this.additionalPayers.filter((payer) => payer.id).map((payer) => payer.id),
        newPayerNames: this.additionalPayers.filter((payer) => !payer.id).map((payer) => payer.name),
      }

      // submit feedback
      try {
        const feedbackResult = await this.teleportService.createTeleportFeedback(feedback)
        if (feedbackResult) {
          this.isSubmitted = true
        }
      } catch (error) {
        this.toast.error(parseGraphQLError(error, 'Could not submit teleport feedback'), JSON.stringify(error))
      }
    }
  }

  /**
   * Cancel feedback submission and navigate to app home page
   */
  cancel(): void {
    this.router.navigate([''])
  }
}
