<jui-dialog title="Confirm Delete">
  <p>
    Are you sure you want to delete the <strong>{{ data.org.name }}</strong> organization?
  </p>

  <div class="button-grid">
    @if (deleteIsDisabled()) {
    <jui-button [disabled]="true">Delete ({{ disableTimeout() }}s)</jui-button>
    } @else {
    <jui-button (click)="onDelete()">Delete</jui-button>
    }

    <jui-button (click)="onCancel()" variant="secondary">Cancel</jui-button>
  </div>
</jui-dialog>
