import { Component, Input } from '@angular/core'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import { FACETS } from 'app/shared/types/facets'

/**
 * Component to display and allow copying a value
 *
 * @export
 * @class CopyValueComponent
 */
@Component({
  selector: 'app-copy-value',
  templateUrl: './copy-value.component.html',
  styleUrls: ['./copy-value.component.scss'],
})
export class CopyValueComponent {
  @Input() value: string
  @Input() facet: string

  constructor(private facetService: FacetedSearchService) {}

  /**
   * Possibly set the property's associated facet with the value
   *
   * @param {*} $event
   * @return {*}  {boolean}
   * @memberof CopyValueComponent
   */
  setFacet($event: unknown): boolean {
    let facet: Facet = FACETS.find((f) => f.id === this.facet)
    if (facet.id) {
      this.facetService.removeAllFacets()
      this.facetService.addFacet(facet)
      this.facetService.addToFacet(facet.id, { value: this.value, display: this.value })
    }
    return true
  }
}
