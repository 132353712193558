import { Component } from '@angular/core'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'

@Component({
  selector: 'app-usage-insights-dashboard',
  templateUrl: './usage-insights-dashboard.page.html',
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class UsageInsightsDashboardPage {
  readonly dashboardType: QuicksightDashboardType = QuicksightDashboardType.UsageInsights
}
