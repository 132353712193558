import { HttpClient } from '@angular/common/http'
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { debug } from 'app/shared/utils/debug'
import bsCustomFileInput from 'bs-custom-file-input'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-request',
  templateUrl: './request.page.html',
})
export class RequestPage implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    request: new UntypedFormControl(null),
    upload: new UntypedFormControl(null),
  })
  requestStatus: boolean = null
  isSending: boolean = false
  upload$: Subscription

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    bsCustomFileInput.init()
    let reader = new FileReader()
    this.upload$ = this.form.get('upload').valueChanges.subscribe((value) => {
      reader.readAsDataURL(value)
    })
  }

  /**
   * Sends request built in textarea to the API
   *
   * @return {*}  {Promise<void>}
   * @memberof RequestPage
   */
  async save(): Promise<void> {
    let response
    this.isSending = true
    this.requestStatus = null
    try {
      let request = JSON.parse(this.form.get('request')?.value?.toString())
      let method: 'POST' | 'GET' = request?.method
      let url = this.sanitizer.sanitize(SecurityContext.URL, request?.url)
      if (this.form.get('upload').value) {
        let upload = new FormData()
        let formUpload = this.form.get('upload').value as File
        upload.append('file', formUpload, formUpload?.name)
        response = await this.http
          .post(url, request.body, {
            headers: {
              Authorization: `bearer ${request.sessionToken}`,
              'x-botRunId': request.botRunId,
            },
          })
          .toPromise()
      } else if (method) {
        let options = {
          headers: {
            Authorization: `bearer ${request.sessionToken}`,
            'Content-Type': 'application/json; charset=utf-8',
            'x-botRunId': request.botRunId,
          },
        }
        if (method === 'POST') {
          response = await this.http.post(url, request.body, options).toPromise()
        } else if (method === 'GET') {
          response = await this.http.get(url, options).toPromise()
        }
        if (response?.error || response?.errors) {
          throw new Error(request?.error?.errors?.[0] || response?.error)
        }
        this.requestStatus = true
      }
    } catch (e) {
      debug('request', e)
      this.requestStatus = false
    } finally {
      this.isSending = false
    }
  }

  ngOnDestroy(): void {
    bsCustomFileInput?.destroy()
    this.upload$?.unsubscribe()
  }
}
