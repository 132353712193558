import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router'
import { Subject, combineLatest } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { BotRunsService } from '../bot-runs.service'

/**
 * Page to display details of a specific bot
 *
 * @export
 * @class BotDetailPage
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-bot-detail',
  templateUrl: './bot-detail.page.html',
  styleUrls: ['./bot-detail.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class BotDetailPage implements OnDestroy, OnInit {
  isLoading: boolean = true
  destroy$: Subject<void>= new Subject<void>()
  botJobId: string
  selectedBotRunId: string
  params: Params
  triggerRoute$ = new Subject<boolean>()

  constructor(private route: ActivatedRoute, router: Router, private botRunsService: BotRunsService) {
    route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.params = params
      if (params?.status) {
        this.botRunsService.statuses = Array.isArray(params.status) ? params.status : [params.status]
      } else {
        this.botRunsService.statuses = ['SUCCEEDED', 'FAILED', 'UNSTABLE', 'RUNNING', 'SCATTERED', 'ABORTED']
      }
      this.botRunsService.startDate = params?.startDate
      this.botRunsService.startTime = params?.startTime
      this.botRunsService.endDate = params?.endDate
      this.botRunsService.endTime = params?.endTime
    })
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.botJobId = this.route.snapshot.paramMap.get('botJobId')
        this.selectedBotRunId = this.route?.snapshot?.firstChild?.paramMap?.get('botRunId')
        this.isLoading = false
      })
  }
  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
