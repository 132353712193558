<div class="container align-items-start p-5 bg-alt-white">
  <h4><b class="text-primary">JANUS HEALTH TECHNOLOGIES, INC. </b></h4>
  <h4><b class="text-primary">PRODUCT PRIVACY POLICY</b></h4>
  <p><br /></p>
  <p><b>Effective Date: June 1, 2021</b></p>
  <p><br /></p>
  <p>
    Janus Health Technologies, Inc. (“Janus”, “we”, “us”, and “our”) is committed to respecting the privacy and
    confidentiality of the Personal Information we process. Please read this Privacy Policy (“Product Privacy Policy”)
    carefully to understand how we collect, use, and process Personal Information.
  </p>
  <p><br /></p>
  <p>
    By visiting, accessing, or using our Services you acknowledge and agree that you have received and read this Product
    Privacy Policy. We may update this Product Privacy Policy from time to time as described in the
    <u class="text-primary clickable" (click)="scroll(changesProductPrivacyPolicy)"
      >Changes to this Product Privacy Policy</u
    >
    section below. If you have any questions, comments, or concerns please contact us at
    <a href="mailto:privacy@janus-ai.com"><u>privacy&commat;janus-ai.com</u></a
    >.
  </p>
  <p><br /></p>
  <p><b class="text-primary">Table of Contents:</b></p>
  <ol class="text-primary">
    <li>
      <p (click)="scroll(scope)" class="clickable">
        <u><b>Scope</b></u>
      </p>
    </li>
    <li>
      <p (click)="scroll(whatPersonalInfo)" class="clickable">
        <u><b>What Personal Information We Collect And How</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(howPersonalInfo)" class="clickable">
        <u><b>How We Use Personal Information</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(automatedProcessing)" class="clickable">
        <u><b>Automated Processing</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(sharePersonalInfo)" class="clickable">
        <u><b>How We Share Personal Information</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(cookiesAndTracking)" class="clickable">
        <u><b>Cookies and Tracking Technologies</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(protectPersonalInfo)" class="clickable">
        <u><b>How We Protect Personal Information</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(childrensPrivacy)" class="clickable">
        <u><b>Children’s Privacy</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(thirdPartyLinks)" class="clickable">
        <u><b>Third-Party Links</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(retainPersonalInfo)" class="clickable">
        <u><b>How Long We Retain Your Personal Information</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(doNotTrackPreferences)" class="clickable">
        <u><b>Do Not Track Preferences</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(CAPrivacyRights)" class="clickable">
        <u><b>Your California Privacy Rights</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(changesProductPrivacyPolicy)" class="clickable">
        <u><b>Changes to this Product Privacy Policy</b></u>
      </p>
    </li>

    <li>
      <p (click)="scroll(contactUs)" class="clickable">
        <u><b>Contact Us</b></u>
      </p>
    </li>
  </ol>

  <p><br /></p>
  <ol start="1" class="h5">
    <li>
      <h4><b #scope class="text-primary">Scope</b></h4>
    </li>
  </ol>
  <p>This Product Privacy Policy applies to Personal Information that Janus collects, uses, and shares when you:</p>
  <ul>
    <li><p>Access or use Janus’s online hosted Services;</p></li>

    <li>
      <p>
        Access or use any tools or documentation, such as application programming interfaces (“APIs”), we make available
        through the Services or otherwise;
      </p>
    </li>

    <li>
      <p>Communicate with us through any written, electronic, or oral communications; or</p>
    </li>

    <li>
      <p>
        Access or use any other technology, platform, dashboard, software, feature, or content owned or operated by
        Janus.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <p>
    For the purposes of this Product Privacy Policy, we refer to our online hosted services, and any other service we
    provide that is covered by this Product Privacy Policy as the “Services.” For users of our website please refer to
    the “Website Privacy Policy.”
  </p>

  <p><br /></p>
  <ol start="2" class="h5">
    <li>
      <h4><b #whatPersonalInfo class="text-primary">What Personal Information Do We Collect and How</b></h4>
    </li>
  </ol>
  <p>
    As used in this Product Privacy Policy, Personal Information means any information that identifies or could be used
    to identify an individual person, which may include name, email address, and phone number. It also includes other
    non-public information that we associate with such identifying information. Personal Information does not include
    aggregated or anonymized information. We collect information, including Personal Information, directly from you and
    indirectly, as described below.
  </p>
  <p><br /></p>
  <ol start="1" type="a">
    <li>
      <p>
        <i><b class="text-primary">Information You Provide to Us</b></i>
      </p>
    </li>

    <p>
      We collect information that you provide directly to us when you access or use our Services or when you interact
      with us. We collect information that you provide to us in the following ways:
    </p>
    <p><br /></p>
    <ul>
      <li>
        <p>
          <i><b class="text-primary">Account and profile information: </b></i>When you or your company sign up for or
          establish an account to access or use the Services, we collect information to set up your account including
          your name, company name, contact information including your business email address and phone number, and
          professional details, such as your company name and role, manager, and any other account details you provide.
          We may also assign you a unique identifier as a user of the Services.
        </p>
      </li>
    </ul>

    <ul>
      <li>
        <p>
          <i><b class="text-primary">Technical support requests, questions, and feedback:</b></i>
          If you contact us, such as by sending us an email or by submitting a technical support request or feedback, we
          may collect information to process and respond to your submission, such as your name, business email address,
          company name, business phone number, the reason for contacting us, and any other information you choose to
          include in your message, request, or feedback. We may also collect information you provide if you complete
          surveys or questionnaires that we make available.
        </p>
      </li>
    </ul>

    <ul>
      <li>
        <p>
          <i><b class="text-primary">Content: </b></i>When you access or use the Services, we collect the patient claim
          and payor information that you upload into or otherwise make available to the Services and/or that we collect
          on your behalf to provide the Services, which may include information such as patient name, claim information,
          payor details, claim status, and revenue cycle information. Depending on the Services you use, we may also
          collect workflow information. If you choose to connect the Services with payor accounts, we collect
          information when you connect to each account, such as dashboard information, claim submission status, and
          payment amounts.
        </p>
      </li>

      <li>
        <p>
          <i><b class="text-primary">Demos: </b></i>If you request a demo of our Services, we will collect your name,
          company name, contact information including your business email and phone number, and additional details
          regarding your request, including the types of Services you’d like to demo.
        </p>
      </li>
    </ul>

    <p><br /></p>
    <p>
      You may choose not to provide information directly to us or to not use the Janus Services. However, some Personal
      Information is necessary so that we can provide you with the Services you have requested. Failure to provide this
      information may prevent us from providing you with access to our Services.
    </p>
    <p><br /></p>
  </ol>
  <ol start="2" type="a">
    <li>
      <p>
        <i><b class="text-primary">Information We Collect Automatically</b></i>
      </p>
    </li>
    <p>
      When you access or use our Services, we or our third-party service providers automatically collect information
      about the device you use to access the Services and your activity when you use the Services. Depending on your
      activity, the information may include:
    </p>
    <p><br /></p>
    <ul>
      <li>
        <p>
          <i><b class="text-primary">Device and Usage Information: </b></i>We collect certain information about your
          device and how you interact with the Services each time you access or use them.
        </p>
        <ul>
          <li>
            <p>
              Device information may include your IP address, browser type, unique device identifiers, information about
              your approximate location (as determined through your IP address), mobile, computer or device hardware
              information (e.g., operating system version, hardware model, IMEI number and other unique device
              identifiers, MAC address, and device settings), device event information, log information, and crash data.
            </p>
          </li>

          <li>
            <p>
              Usage information may include features that you use, clickstream data, access dates and times, and how you
              interact with others through the Services, such as the teams you work with and your interactions with
              colleagues or managers who are also using the Services. Depending on the Services you use, we may also
              collect screen captures of your computer or device when you undertake certain activities.
            </p>
          </li>
        </ul>
      </li>

      <li>
        <p>
          <i><b class="text-primary">Cookies and Other Tracking Technologies:</b></i> We and our third-party service
          providers or agents may collect information using one or more Cookies or similar technologies such as pixels
          or locally stored objects (collectively “Cookies”). Cookies may collect information such as your Internet
          Protocol (“IP”) address, unique device identifiers, your browser settings, and information about how you use
          the Services (e.g., the pages you view, the links you click). Please refer to our
          <u><a routerLink="/policies/cookie-policy">Product Cookie Policy</a></u>
          for more information about our use of Cookies and your choices related to Cookies.
        </p>
      </li>
    </ul>

    <p><br /></p>
  </ol>
  <ol start="3" type="a">
    <li>
      <p>
        <i><b class="text-primary">Information Collected from Third Parties</b></i>
      </p>
    </li>
    <p>
      In some instances, and depending on the Services you use, we process Personal Information we receive from third
      parties, which consists of:
    </p>
    <p><br /></p>
    <ul>
      <li>
        <p>
          <i><b class="text-primary">Your Service Providers and Payors:</b></i>
          If you choose to connect a third-party account/service with your account, such as a payor account, we will
          receive information from that account/service according to your settings with that account/service. For
          example, if you connect a payor account to your Janus account, we may receive details of your payouts and
          status of claims.
        </p>
      </li>
    </ul>

    <ul>
      <li>
        <p>
          <i><b class="text-primary">Janus’s Service Providers:</b></i
          >We receive information from our service providers or agents who perform business services for Janus and
          transactional data from our third-party infrastructure service providers.
        </p>
      </li>
    </ul>

    <ul>
      <li>
        <p>
          <i><b class="text-primary">Analytics and Research Providers:</b></i>
          We may receive information from entities who perform business functions for Janus, such as research
          organizations and analytics service providers.
        </p>
      </li>
    </ul>
  </ol>

  <p><br /></p>
  <ol start="3" class="h5">
    <li>
      <h4><b #howPersonalInfo class="text-primary">How We Use Personal Information</b></h4>
    </li>
  </ol>
  <p>We use the information we collect in the following ways:</p>
  <ul>
    <li><p>To provide and operate the Janus Services;</p></li>

    <li>
      <p>To update, improve, and/or enhance the Services and develop new features, functionality, and products;</p>
    </li>

    <li>
      <p>
        To provide technical support for, secure, and protect the Services, including to detect and prevent fraud,
        abuse, or security risks, and to track outages and troubleshoot;
      </p>
    </li>

    <li><p>To conduct analytics related to the Services;</p></li>

    <li><p>To provide demos of the Services;</p></li>
    <li><p>To facilitate payments, fees, and subscriptions;</p></li>

    <li><p>To manage and maintain customer accounts;</p></li>

    <li><p>To market our Services to you;</p></li>

    <li>
      <p>
        To respond to your inquiries and requests, or otherwise communicate with you to provide you with assistance
        related to the Services;
      </p>
    </li>

    <li><p>To provide updates about our Services and send you newsletters;</p></li>

    <li>
      <p>
        To enforce our Terms and Conditions, resolve disputes or otherwise to carry out our obligations and enforce our
        rights, and to protect our business interest and rights of third parties;
      </p>
    </li>

    <li><p>To comply with legal and contractual obligations and requirements;</p></li>

    <li>
      <p>
        To keep you updated about changes to policies relating to our Services (including this Product Privacy Policy);
        and
      </p>
    </li>

    <li><p>For any other purpose with your consent.</p></li>
  </ul>

  <p><br /></p>
  <ol start="4" class="h5">
    <li>
      <h4><b #automatedProcessing class="text-primary">Automated Processing</b></h4>
    </li>
  </ol>
  <p>
    To provide you with tailored advice, recommendations and insights, we process Personal Information using automated
    methods. Our automated methods include artificial intelligence (AI) and a range of technologies that help our
    Services learn and reason to provide you with Services tailored to your workflows and needs and to improve our
    ability to provide you with tailored Services.
  </p>

  <p><br /></p>
  <ol start="5" class="h5">
    <li>
      <h4><b #sharePersonalInfo class="text-primary">How We Share Personal Information</b></h4>
    </li>
  </ol>
  <p>
    We transmit, share, grant access, make available and provide Personal Information to or with the following types of
    third parties:
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <i
          ><b class="text-primary"
            >Your Account’s Administrator, Your Employer, and Other Users in Your Organization:</b
          ></i
        >
        If you are using the Services as part of a company account, we share account information and information about
        your use of the Services with your manager or supervisor and/or your organization’s account administrator, such
        as to provide a report of account access and activity. Depending on your use of the Services and the type of
        Services, you may be able to share information across other users within your organization.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <ul>
    <li>
      <p>
        <i><b class="text-primary">Service Providers: </b></i>We share Personal Information with third-party vendors,
        consultants, agents, and service providers that help us provide our Services or with any of the purposes
        described in this Product Privacy Policy.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <ul>
    <li>
      <p>
        <i><b class="text-primary">Business Transfers:</b></i>
        We may share Personal Information with third parties we choose to acquire or with buyers, successors, or others
        in connection with a merger, divestiture, restructuring, reorganization, financing due diligence, dissolution or
        other sale or transfer of some or all of our assets or transition of service to another provider, whether as a
        going concern or as part of bankruptcy, receivership, liquidation or similar proceeding, as permitted by law
        and/or contract.
      </p>
    </li>
  </ul>

  <ul>
    <li>
      <p>
        <i><b class="text-primary">Compliance and Regulatory Purposes: </b></i>We may share Personal Information if we
        believe that it is necessary to:
      </p>
      <ul>
        <li>
          <p>comply with a law, regulation, legal process, or legitimate governmental request;</p>
        </li>

        <li>
          <p>protect the safety or security of the Services, users of the Services, or any person;</p>
        </li>

        <li>
          <p>investigate, prevent, or take action regarding illegal or suspected illegal activities;</p>
        </li>

        <li>
          <p>prevent spam, abuse, fraud, or other malicious activity of actors using or accessing the Services; or</p>
        </li>

        <li><p>protect our rights or property of those who use the Services.</p></li>
      </ul>
    </li>
  </ul>

  <p>
    Non-public information about our users will not be released to law enforcement except in response to an appropriate
    legal process such as a subpoena, court order, or other valid legal process that has been reviewed by Janus.
    However, if we receive information that provides us with a good faith belief that there is an exigent emergency, we
    may provide information to law enforcement trying to prevent or mitigate the danger (if we have it), to be
    determined on a case-by-case basis.
  </p>
  <ul>
    <li>
      <p>
        <i><b class="text-primary">With Your Consent:</b></i>
        There may be situations where you are asked to consent to share Personal Information with third parties for
        additional reasons not included in this Product Privacy Policy.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <ol start="6" class="h5">
    <li>
      <h4><b #cookiesAndTracking class="text-primary">Cookies and Tracking Technologies</b></h4>
    </li>
  </ol>
  <p>
    For information about how we use Cookies and similar tracking technologies and to learn how to manage Cookies,
    please see our <u><a routerLink="/policies/cookie-policy">Product Cookie Policy</a></u
    >.
  </p>

  <p><br /></p>
  <ol start="7" class="h5">
    <li>
      <h4><b #protectPersonalInfo class="text-primary">How We Protect Personal Information</b></h4>
    </li>
  </ol>
  <p>
    At Janus, we take our responsibility to protect the security and privacy of Personal Information seriously. We have
    implemented what we believe to be reasonable and appropriate security measures designed to prevent Personal
    Information from being lost, used, accessed, altered, or disclosed in an unauthorized or unlawful way.
  </p>
  <p><br /></p>
  <p>
    However, no method of transmission over the Internet, or method of electronic storage, is 100% secure, and we cannot
    and do not guarantee that Personal Information is completely secure and safe from such risks. By using the Services,
    you understand and assume the risks associated with your activities on the Internet.
  </p>
  <p><br /></p>
  <p>
    If you have reason to believe that your information is no longer secure, please let us know by contacting us at
    <a href="mailto:privacy@janus-ai.com"><u>privacy&commat;janus-ai.com</u></a
    >.
  </p>

  <p><br /></p>
  <ol start="8" class="h5">
    <li>
      <h4><b #childrensPrivacy class="text-primary">Children’s Privacy</b></h4>
    </li>
  </ol>
  <p>
    Our Services are not intended for use by minors under the age of 13, and we do not knowingly collect Personal
    Information directly from minors under the age of 13. If you learn that a child under the age of 13 has provided us
    with Personal Information, please <u>contact us</u>.
  </p>

  <p><br /></p>
  <ol start="9" class="h5">
    <li>
      <h4><b #thirdPartyLinks class="text-primary">Third-Party Links</b></h4>
    </li>
  </ol>
  <p>
    Our Services may provide, for informational purposes only, links to other websites or resources with which we do not
    have a contractual relationship and over which we do not have control (“External Websites”). Such links are not paid
    advertisements, nor do they constitute an endorsement by Janus of those External Websites and are provided to you
    only as a convenience. By clicking on links to External Websites, the operators of the External Websites may collect
    your Personal Information. We are not responsible for the content or data collection practices of those External
    Websites, and your use of External Websites is subject to their respective terms of use and privacy policies.
  </p>

  <p><br /></p>
  <ol start="10" class="h5">
    <li>
      <h4><b #retainPersonalInfo class="text-primary">How Long We Retain Your Personal Information</b></h4>
    </li>
  </ol>
  <p>
    We retain Personal Information for the purposes stated in this Product Privacy Policy and as required under
    applicable law. To determine how long we keep Personal Information, we consider the amount, nature, and sensitivity
    of Personal Information, the reasons for which we collect and process the information, and applicable legal
    requirements.
  </p>
  <p>
    If the Services are made available to you through an organization (e.g., your employer), we retain your Personal
    Information as long as required by the administrator of your account.&nbsp;
  </p>

  <p><br /></p>
  <ol start="11" class="h5">
    <li>
      <h4><b #doNotTrackPreferences class="text-primary">Do Not Track Preferences</b></h4>
    </li>
  </ol>
  <p>
    The Services do not monitor for or behave differently if your browser or device transmits a “do-not-track” or
    similar message.
  </p>

  <p><br /></p>
  <ol start="12" class="h5">
    <li>
      <h4><b #CAPrivacyRights class="text-primary">Your California Privacy Rights</b></h4>
    </li>
  </ol>
  <p>
    If you are a California resident, you may ask us to refrain from sharing your Personal Information with our
    affiliates and third parties for their direct marketing purposes. We do not share Personal Information with third
    parties for their own marketing purposes.
  </p>

  <p><br /></p>
  <ol start="13" class="h5">
    <li>
      <h4><b #changesProductPrivacyPolicy class="text-primary">Changes to This Product Privacy Policy</b></h4>
    </li>
  </ol>
  <p>
    We may update this Product Privacy Policy from time to time. When we update this Product Privacy Policy, we will
    revise the “Effective Date” at the top of this Product Privacy Policy and post a link to the updated Product Privacy
    Policy on our Services. Changes to this Product Privacy Policy are effective when they are posted on this page. If
    we make any material changes, we will provide you with notice as required under applicable law. You are advised to
    review this Product Privacy Policy periodically to ensure you are aware of any such changes.
  </p>

  <p><br /></p>
  <ol start="14" class="h5">
    <li>
      <h4><b #contactUs class="text-primary">Contact Us</b></h4>
    </li>
  </ol>
  <p>
    If you have any questions about this Product Privacy Policy or our privacy practices, please contact us by email at:

    <a href="mailto:privacy@janus-ai.com"><u>privacy&commat;janus-ai.com</u></a
    >.
  </p>
  <p><br /></p>
  <p>Or by postal mail at:</p>
  <p>Janus Health Technologies, Inc.</p>
  <p>1761 N Wilmot Ave</p>
  <p>Chicago, IL 60647</p>
</div>
