import { Component, Input, OnInit } from '@angular/core'
import { getPropDetails } from 'app/shared/types/claim'
import { Claim } from 'generated/graphql'

/**
 * Component to display specific claim details
 *
 * @export
 * @class ClaimDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-claim-details',
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.scss'],
})
export class ClaimDetailsComponent implements OnInit {
  @Input() claim: Claim

  getPropDetails = getPropDetails

  constructor() {}

  ngOnInit(): void {}
}
