<a [routerLink]="['/claims']" *ngIf="facet && value; else noLink" (click)="setFacet($event)">
  {{ value || 'N/A' }}
</a>
<ng-template #noLink>
  {{ value || 'N/A' }}
</ng-template>
<i
  *ngIf="value?.length"
  class="fa fw fa-clone copy-btn clickable px-2"
  role="button"
  [attr.data-clipboard-text]="value"
></i>
