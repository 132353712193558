import { Component, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ZendeskService } from 'app/shared/services/zendesk.service'

export type Status = 'PASSWORD' | 'EXPIRED' | 'LOADING' | 'INVALID' | 'SUCCESS' | 'EXPIRED_SENT'

/**
 * Login page component
 *
 * @export
 * @class LoginPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-confirm-page',
  templateUrl: './confirm.page.html'
})
export class ConfirmPage implements OnDestroy {
  destroy$ = new Subject<void>()
  status: Status = 'LOADING'
  error: string = null

  form = new UntypedFormGroup({
    pass: new UntypedFormControl(null),
  })
  token: string
  submitting = false

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    protected zendesk: ZendeskService
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.token = params.token
      this.configure();
    })
  }

  ngOnDestroy(): void {
    this.destroy$?.next()
    this.destroy$?.complete()
  }

  /**
   * Check the current status of the user's confirmation status.
   */
  async configure() {   
    try {
      this.status = (await this.authService.isConfirmationTokenValid(this.token))?.status ? 'PASSWORD' : 'EXPIRED'
    } catch(ex) {
      this.status = 'INVALID'
    } 
  }

  /**
   * Confirm user account and submit the password update for the user
   */
  async submit(password: string): Promise<void> {
    if (!password) {
      this.error = 'Please fill in all fields before attempting to create your password.'
      return
    }    
    
    this.form.get('pass').setValue(password)    
    this.submitting = true
    this.error = null
    try {
      this.status = (await this.authService.submitConfirmationTokenUpdate(this.form.value.pass, this.token))?.status ? 'SUCCESS' : 'EXPIRED'
    } catch(ex) {
      this.status = 'INVALID'
    }
    this.submitting = false
  }

  /**
   * Request a new confirmation welcome email if the current token is expired
   */
  async requestConfirmation() {
    this.status = 'LOADING'
    try {
      this.status = (await this.authService.submitConfirmationRequest(this.token))?.status ? 'EXPIRED_SENT' : 'EXPIRED'
    } catch(ex) {
      this.status = 'INVALID'
    }
  }
}
