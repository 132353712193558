<div class="modal-header d-flex p-4 border-0">
  <h5 class="modal-title flex-grow-1 d-flex justify-content-center">{{ title }}</h5>
  <button type="button" class="btn btn-sm" aria-label="Close" (click)="clickOnCancel()">
    <i class="fa fa-close fa-fw"></i>
  </button>
</div>
<div class="modal-body d-flex flex-column text-start mx-5 p-4">
  {{ body }}
  <ng-content></ng-content>
</div>
<div #footer>
  <ng-content select="[slot=footer]"> </ng-content>
</div>
<div *ngIf="!footer.children.length" class="modal-footer d-flex justify-content-start border-0 mx-5 p-4">
  <button
   *ngIf="confirmText"
    type="button"
    class="btn rounded-lg btn-alt-light-blue px-4 py-1 d-flex align-items-center justify-content-center"
    (click)="clickOnConfirm()"
    [disabled]="showConfirmSpinner"
    data-t="confirm-modal-yes-button"
  >
    <app-loading
      [ngClass]="{ invisible: !showConfirmSpinner }"
      class="position-absolute"
      data-t="confirm-modal-yes-button-spinner"
    ></app-loading>
    <div [ngClass]="{ invisible: showConfirmSpinner }">
      {{ confirmText }}
    </div>
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary px-4 py-1"
    (click)="clickOnCancel()"
    data-t="confirm-modal-cancel-button"
  >
    {{ cancelText }}
  </button>
</div>
