import { DragDropModule } from '@angular/cdk/drag-drop'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { JuiButtonComponent, JuiDialogService } from '@janushealthinc/janus-ui-kit'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AdminPage } from 'app/admin/admin.page'
import { ClaimSearchEditPage } from 'app/admin/claim-searches/claim-searches.page'
import { SavedSearchSearchComponent } from 'app/admin/claim-searches/components/saved-search-search/saved-search-search.component'
import { ClaimSearchDetailPage } from 'app/admin/claim-searches/edit/edit.page'
import { ClientCredentialsListPage } from 'app/admin/client-credentials/client-credentials-list/client-credentials-list.page'
import { ViewEmailsComponent } from 'app/admin/client-credentials/components/view-emails/view-emails.component'
import { ClientCredentialEditPage } from 'app/admin/client-credentials/edit/client-credential-edit.page'
import { FeatureFlagEditPage } from 'app/admin/feature-flags/edit/feature-flag-edit.page'
import { FeatureFlagPage } from 'app/admin/feature-flags/feature-flag/feature-flag.page'
import { ObserveAccessControlAuditTrailPage } from 'app/admin/observe-access-controls/audit-trail/observe-access-control-audit-trail.page'
import { CreateOrgComponent } from 'app/admin/org/components/create-org/create-org.component'
import { SelectOrgSetComponent } from 'app/admin/org/components/select-org-set/select-org-set.component'
import { OrgLogoPage } from 'app/admin/org/logo/logo.page'
import { OrgListPage } from 'app/admin/org/org-list/org-list.page'
import { SamlConfigurationPage } from 'app/admin/org/saml/saml-configuration.page'
import { SamlOrgListPage } from 'app/admin/org/saml/saml-org-list.page'
import { CombinePayersPage } from 'app/admin/payers/combine/combine-payers.page'
import { PayerCardComponent } from 'app/admin/payers/components/payer-card/payer-card.component'
import { PayerSearchComponent } from 'app/admin/payers/components/payer-search/payer-search.component'
import { PayerEditPage } from 'app/admin/payers/edit/payer-edit.page'
import { PayersListPage } from 'app/admin/payers/payers-list/payers-list.page'
import { QediAdminLinksComponent } from 'app/admin/qedi-admin-links/qedi-admin-links.component'
import { TaskGroupAssignPage } from 'app/admin/task-groups/assign/assign.page'
import { TaskGroupCardComponent } from 'app/admin/task-groups/components/task-group-card/task-group-card.component'
import { TaskGroupSearchComponent } from 'app/admin/task-groups/components/task-group-search/task-group-search.component'
import { TaskGroupEditPage } from 'app/admin/task-groups/edit/edit.page'
import { TaskGroupDetailsPage } from 'app/admin/task-groups/edit/task-group-details/task-group-details.page'
import { TaskTypeAssignPage } from 'app/admin/task-types/assign/assign.page'
import { TaskTypeCardComponent } from 'app/admin/task-types/components/task-type-card/task-type-card.component'
import { TaskTypeSearchComponent } from 'app/admin/task-types/components/task-type-search/task-type-search.component'
import { TaskTypeEditPage } from 'app/admin/task-types/edit/edit.page'
import { TaskTypeDetailsPage } from 'app/admin/task-types/edit/right/task-type-details/task-type-details.page'
import { TaskTypeMergePage } from 'app/admin/task-types/merge/merge.page'
import { TasksDeletePage } from 'app/admin/tasks/delete/delete.page'
import { CreateTeamComponent } from 'app/admin/teams/components/create-team/create-team.component'
import { TeamCardComponent } from 'app/admin/teams/components/team-card/team-card.component'
import { TeamEditPage } from 'app/admin/teams/edit/team-edit.page'
import { TeamsPage } from 'app/admin/teams/teams.page'
import { EditUserPage } from 'app/admin/users/edit/edit-user.page'
import { WorkflowAssignPage } from 'app/admin/workflows/assign/assign.page'
import { TaskGroupTypeaheadComponent } from 'app/admin/workflows/components/task-group-typeahead/task-group-typeahead.component'
import { WorkflowCardComponent } from 'app/admin/workflows/components/workflow-card/workflow-card.component'
import { WorkflowSearchComponent } from 'app/admin/workflows/components/workflow-search/workflow-search.component'
import { DeleteWorkflowsPage } from 'app/admin/workflows/delete/delete-workflows.page'
import { OutcomeSelectorComponent } from 'app/admin/workflows/outcome-selector/outcome-selector.component'
import { WorkflowListPage } from 'app/admin/workflows/workflow-list/workflow-list.page'
import { WorkflowPage } from 'app/admin/workflows/workflow/workflow.page'
import { AnnotationsPage } from 'app/annotations/annotations.page'
import { AppRoutingModule } from 'app/app-routing.module'
import { AppComponent } from 'app/app.component'
import { AuthLayoutComponent } from 'app/auth/auth-layout/auth-layout.component'
import { HeaderMenuComponent } from 'app/auth/header-menu/header-menu.component'
import { InactivityHttpInterceptor } from 'app/auth/inactivity/inactivity-http.interceptor'
import { InactivityWarningComponent } from 'app/auth/inactivity/inactivity-warning.component'
import { LoggedInPage } from 'app/auth/logged-in/logged-in.page'
import { ActivatePage } from 'app/auth/login/activate/activate.page'
import { AuthErrorCardComponent } from 'app/auth/login/components/auth-error-card/auth-error-card.component'
import { MfaComponent } from 'app/auth/login/components/mfa/mfa.component'
import { PasswordComponent } from 'app/auth/login/components/password/password.component'
import { UsernameComponent } from 'app/auth/login/components/username/username.component'
import { LoginPage } from 'app/auth/login/login.page'
import { NewPasswordPage } from 'app/auth/login/reset/new-password/new-password.page'
import { ResetPage } from 'app/auth/login/reset/reset.page'
import { SelectOrgPage } from 'app/auth/login/select-org/select-org.page'
import { ClaimDetailPage } from 'app/claims/claim-detail/claim-detail.page'
import { ClaimsListPage } from 'app/claims/claims-list/claims-list.page'
import { ClaimDetailsComponent } from 'app/claims/components/claim-details/claim-details.component'
import { ClaimGenerateNoteComponent } from 'app/claims/components/claim-generate-note/claim-generate-note.component'
import { ClaimKeyDetailsComponent } from 'app/claims/components/claim-key-details/claim-key-details.component'
import { ClaimListColumnsComponent } from 'app/claims/components/claim-list-columns/claim-list-columns.component'
import { ClaimPaymentsComponent } from 'app/claims/components/claim-payments/claim-payments.component'
import { ClaimResponsibilityComponent } from 'app/claims/components/claim-responsibility/claim-responsibility.component'
import { ServicelinesComponent } from 'app/claims/components/claim-servicelines/claim-servicelines.component'
import { ClaimStatusComponent } from 'app/claims/components/claim-status/claim-status.component'
import { ClaimsExportListComponent } from 'app/claims/components/claims-export-list/claims-export-list.component'
import { CustomRouteReuseStrategy } from 'app/custom-route-reuse-strategy'
import { DashboardPage } from 'app/dashboard/dashboard.page'
import { PriorAuthDashboardPage } from 'app/demo/prior-auth/prior-auth.page'
import { FilePage } from 'app/file/file.page'
import { GraphQLModule } from 'app/graphql.module'
import { HomePage } from 'app/home/home.page'
import { IlluminateGraphPanelToolbarComponent } from 'app/illuminate-v2/components/illuminate-graph-panel-toolbar/illuminate-graph-panel-toolbar.component'
import { IlluminateGraphPanelComponent } from 'app/illuminate-v2/components/illuminate-graph-panel/illuminate-graph-panel.component'
import { IlluminateWorkflowGroupPage } from 'app/illuminate-v2/illuminate-workflow-group/illuminate-workflow-group.page'
import { CumulativeOpportunityStatComponent } from 'app/illuminate/components/cumulative-opportunity-stat/cumulative-opportunity-stat.component'
import { DeploymentStatusComponent } from 'app/illuminate/components/deployment-status/deployment-status.component'
import { LevelBadgeComponent } from 'app/illuminate/components/level-badge/level-badge.component'
import { ProfitImprovementComponent } from 'app/illuminate/components/profit-improvement/profit-improvement.component'
import { TableItemComponent } from 'app/illuminate/components/table-item/table-item.component'
import { WorkflowPhaseDetailsComponent } from 'app/illuminate/components/workflow-phase-details/workflow-phase-details.component'
import { IlluminatePage } from 'app/illuminate/illuminate.page'
import { OpportunitiesPage } from 'app/illuminate/opportunities/opportunities.page'
import { OpportunityDetailsPage } from 'app/illuminate/opportunity-details/opportunity-details.page'
import { TaskGroupAssignAvatarComponent } from 'app/pathfinder/task-groups/components/task-group-assign-avatar/task-group-assign-avatar.component'
import { CreateTaskGroupComponent } from 'app/pathfinder/task-groups/components/task-group-create/task-group-create.component'
import { TaskGroupFilterComponent } from 'app/pathfinder/task-groups/components/task-group-filter/task-group-filter.component'
import { TaskGroupOverviewCardComponent } from 'app/pathfinder/task-groups/components/task-group-overview-card/task-group-overview-card.component'
import { TaskGroupOverviewComponent } from 'app/pathfinder/task-groups/components/task-group-overview/task-group-overview.component'
import { TaskGroupScoreComponent } from 'app/pathfinder/task-groups/components/task-group-score/task-group-score.component'
import { TaskGroupsPage } from 'app/pathfinder/task-groups/task-groups.page'
import { TaskActionsComponent } from 'app/pathfinder/tasks/components/task-actions/task-actions.component'
import { TaskCardComponent } from 'app/pathfinder/tasks/components/task-card/task-card.component'
import { TaskClaimCardComponent } from 'app/pathfinder/tasks/components/task-claim-card/task-claim-card.component'
import { TaskCommentsComponent } from 'app/pathfinder/tasks/components/task-comments/task-comments.component'
import { CreateTaskComponent } from 'app/pathfinder/tasks/components/task-create/task-create.component'
import { TaskFilterComponent } from 'app/pathfinder/tasks/components/task-filter/task-filter.component'
import { TaskPanelComponent } from 'app/pathfinder/tasks/components/task-panel/task-panel.component'
import { TaskPickedByComponent } from 'app/pathfinder/tasks/components/task-picked-by/task-picked-by.component'
import { TaskStatusIconComponent } from 'app/pathfinder/tasks/components/task-status-icon/task-status-icon.component'
import { WorkflowTasksComponent } from 'app/pathfinder/tasks/components/workflow-tasks/workflow-tasks.component'
import { TasksPage } from 'app/pathfinder/tasks/tasks.page'
import { CookiePolicyPage } from 'app/policies/cookie-policy/cookie-policy.page'
import { PrivacyPolicyPage } from 'app/policies/privacy-policy/privacy-policy.page'
import { SupportAndMaintenancePage } from 'app/policies/support-and-maintenance.page'
import { TermsOfUsePage } from 'app/policies/terms-of-use/terms-of-use.page'
import { EmployeeProductivityComponent } from 'app/reporting/components/employee-productivity/employee-productivity.component'
import { TeamQuickStatsComponent } from 'app/reporting/components/team-quick-stats/team-quick-stats.component'
import { ReportingPage } from 'app/reporting/reporting.page'
import { ReportListPage } from 'app/reports/report-list/report-list.page'
import { ReportUploadPage } from 'app/reports/report-upload/report-upload.page'
import { RequestPage } from 'app/request/request.page'
import { BotDetailPage } from 'app/revbot/bot-detail/bot-detail.page'
import { BotOverviewPage } from 'app/revbot/bot-detail/bot-overview/bot-overview.page'
import { BotRunOverviewPage } from 'app/revbot/bot-detail/bot-run-overview/bot-run-overview.page'
import { BotKeyDetailsComponent } from 'app/revbot/bot-detail/components/bot-key-details/bot-key-details.component'
import { BotRunCardComponent } from 'app/revbot/bot-detail/components/bot-run-card/bot-run-card.component'
import { BotRunKeyDetailsComponent } from 'app/revbot/bot-detail/components/bot-run-key-details/bot-run-key-details.component'
import { BotRunsFilterComponent } from 'app/revbot/bot-detail/components/bot-runs-filter/bot-runs-filter.component'
import { BotRunsGraphComponent } from 'app/revbot/bot-detail/components/bot-runs-graph/bot-runs-graph.component'
import { BotRunsSearchComponent } from 'app/revbot/bot-detail/components/bot-runs-search/bot-runs-search.component'
import { BotsListPage } from 'app/revbot/bots-list/bots-list.page'
import { SecurityAndCompliancePage } from 'app/security-and-compliance/security-and-compliance.page'
import { BreadcrumbsComponent } from 'app/shared/components/breadcrumbs/breadcrumbs.component'
import { CopyValueComponent } from 'app/shared/components/copy-value/copy-value.component'
import { CopyrightComponent } from 'app/shared/components/copyright/copyright.component'
import { DagComponent } from 'app/shared/components/dag/dag.component'
import { CheckboxFacetComponent } from 'app/shared/components/faceted-search/checkbox/checkbox.component'
import { ComparisonFacetComponent } from 'app/shared/components/faceted-search/comparison/comparison.component'
import { DaterangeFacetComponent } from 'app/shared/components/faceted-search/daterange/daterange.component'
import { FacetedSearchComponent } from 'app/shared/components/faceted-search/faceted-search.component'
import { LoadSearchComponent } from 'app/shared/components/faceted-search/load-search/load-search.component'
import { NumberRangeFacetComponent } from 'app/shared/components/faceted-search/number-range/number-range.component'
import { PreDefinedFacetComponent } from 'app/shared/components/faceted-search/pre-defined/pre-defined.component'
import { RadioFacetComponent } from 'app/shared/components/faceted-search/radio/radio.component'
import { RangeSliderFacetComponent } from 'app/shared/components/faceted-search/range-slider/range-slider.component'
import { SaveSearchComponent } from 'app/shared/components/faceted-search/save-search/save-search.component'
import { SelectedFacetsComponent } from 'app/shared/components/faceted-search/selected-facets/selected-facets.component'
import { TextFacetComponent } from 'app/shared/components/faceted-search/text/text.component'
import { TypeaheadFacetComponent } from 'app/shared/components/faceted-search/typeahead/typeahead.component'
import { ImageAnnotateComponent } from 'app/shared/components/image-annotate/image-annotate.component'
import { ImageModalComponent } from 'app/shared/components/image-modal/image-modal.component'
import { ImageViewerComponent } from 'app/shared/components/image-viewer/image-viewer.component'
import { NotificationsComponent } from 'app/shared/components/notifications/notifications.component'
import { PaneComponent } from 'app/shared/components/pane/pane.component'
import { PasswordInputComponent } from 'app/shared/components/password-input/password-input.component'
import { PasswordValidatorComponent } from 'app/shared/components/password-validator/password-validator.component'
import { PdfViewerComponent } from 'app/shared/components/pdf-viewer/pdf-viewer.component'
import { ProgressModalComponent } from 'app/shared/components/progress-modal/progress-modal.component'
import { TabComponent } from 'app/shared/components/tab/tab.component'
import { TableauDashboardComponent } from 'app/shared/components/tableau-dashboard/tableau-dashboard.component'
import { TeleportButtonComponent } from 'app/shared/components/teleport-button/teleport-button.component'
import { UserSearchComponent } from 'app/shared/components/user-search/user-search.component'
import { VideoModalComponent } from 'app/shared/components/video-modal/video-modal.component'
import { WellComponent } from 'app/shared/components/well/well.component'
import { DynamicComponentDirective } from 'app/shared/directives/dynamic-component.directive'
import { FeatureFlagDirective } from 'app/shared/directives/feature-flag.directive'
import { FileUploadDirective } from 'app/shared/directives/file-upload.directive'
import { NgbdSortableHeader } from 'app/shared/directives/sortable.directive'
import { TrimmedInputDirective } from 'app/shared/directives/trimmed-input.directive'
import { AsPipe } from 'app/shared/pipes/as.pipe'
import { DurationPipe } from 'app/shared/pipes/duration.pipe'
import { GetPipe } from 'app/shared/pipes/get.pipe'
import { PrettyPrintPipe } from 'app/shared/pipes/pretty-print.pipe'
import { SharedModule } from 'app/shared/shared.module'
import { StatusPage } from 'app/status/status.page'
import { TeleportFeedbackPage } from 'app/teleport-feedback/teleport-feedback.page'
import { DocumentUploadComponent } from 'app/teleport/components/document-upload/document-upload.component'
import { FeedbackSubmittedComponent } from 'app/teleport/components/feedback-submitted/feedback-submitted.component'
import { PayerPickerComponent } from 'app/teleport/components/payer-picker/payer-picker.component'
import { ProcedureSetDetailsComponent } from 'app/teleport/components/procedure-set-details/procedure-set-details.component'
import { PriorAuthDocsUploadPage } from 'app/teleport/docs-upload/docs-upload.page'
import { HarListPage } from 'app/teleport/har-list/har-list.page'
import { PriorAuthPage } from 'app/teleport/prior-auth/prior-auth.page'
import { TeleportErrorPage } from 'app/teleport/teleport-error/teleport-error.page'
import { TeleportInvoicePage } from 'app/teleport/teleport-invoice/teleport-invoice.page'
import { TeleportPage } from 'app/teleport/teleport.page'
import { NgChartsModule } from 'ng2-charts'
import { NgxSliderModule } from 'ngx-slider-v2'
import { BotJobsAdminModule } from './admin/bot-jobs/bot-jobs.module'
import { DashboardOrgListComponent } from './admin/dashboard-management/dashboard-org-list/dashboard-org-list.component'
import { EditOrgDashboardPage } from './admin/dashboard-management/edit/edit-org-dashboard.page.component'
import { OrganizationManagementComponent } from './admin/dashboard-management/organization-management/organization-management.component'
import { EdiReader } from './admin/edi-reader/edi-reader.page'
import { ObserveAcessControlEditComponent } from './admin/observe-access-controls/observe-access-control-list/observe-access-control-edit/observe-access-control-edit.component'
import { ObserveAccessControlListComponent } from './admin/observe-access-controls/observe-access-control-list/observe-access-control-list/observe-access-control-list.component'
import { ObserveAccessControlPage } from './admin/observe-access-controls/observe-access-control-list/observe-access-control.page'
import { ObserveUsageSummaryComponent } from './admin/observe-access-controls/observe-access-control-list/observe-usage-summary/observe-usage-summary.component'
import { ObserveBuildConfigPageComponent } from './admin/observe-builds/observe-build-config-page/observe-build-config-page.component'
import { TagSearchComponent } from './admin/observe-builds/tag-search/tag-search.component'
import { AddConfigurationsComponent } from './admin/observe-configurations/add-configurations/add-configurations/add-configurations.component'
import { AnnotationBuilderComponent } from './admin/observe-configurations/annotation-builder/annotation-builder/annotation-builder.component'
import { ConfigurationPipelineComponent } from './admin/observe-configurations/configuration-pipeline/configuration-pipeline/configuration-pipeline.component'
import { ObserveConfigPage } from './admin/observe-configurations/observe-config-page/observe-config.page'
import { UrlGroupsComponent } from './admin/observe-configurations/url-groups/url-groups/url-groups.component'
import { ObserveTestingPage } from './admin/observe-testing/observe-testing.page'
import { ObserveUserEditModalComponent } from './admin/observe-users/observe-user-edit-modal/observe-user-edit-modal.component'
import { ObserveUsersActivityLogComponent } from './admin/observe-users/observe-users-activity-log/observe-users-activity-log.component'
import { ObserveUsersListComponent } from './admin/observe-users/observe-users-list/observe-users-list.component'
import { ObserveActivityLogComponent } from './admin/observe/observe-activity-log/observe-activity-log.component'
import { ObserveAdminLinksComponent } from './admin/observe/observe-admin-links/observe-admin-links.component'
import { ObserveOrgListComponent } from './admin/observe/org-settings/observe-org-list/observe-org-list.component'
import { ObserveOrgSettingsComponent } from './admin/observe/org-settings/observe-org-settings/observe-org-settings.component'
import { ConfirmDeleteOrgDialogComponent } from './admin/org/org-list/confirm-delete-org-modal/confirm-delete-org-modal.component'
import { PayerPlanGroupEditPage } from './admin/payer-plan-groups/edit/payer-plan-group-edit.page'
import { PayerPlanGroupPage } from './admin/payer-plan-groups/payer-plan-groups/payer-plan-groups.page'
import { AutomationsQueryCenterPage } from './admin/query-center/automations-query-center/automations-query-center.page'
import { QueryCenterComponent } from './admin/query-center/query-center/query-center.component'
import { OrgRolesComponent } from './admin/roles/org-roles/org-roles.component'
import { UserOrgAssignmentsComponent } from './admin/roles/user-org-assignments/user-org-assignments.component'
import { AnalyticsModule } from './analytics/analytics.module'
import { AnnotatedRecordSlideComponent } from './annotations/annotated-record-slide/annotated-record-slide.component'
import { ConfirmPage } from './auth/login/confirm/confirm.page'
import { PermissionsDirective } from './auth/permissions/permissions.directive'
import { BotModal } from './claims/components/bot-modal/bot-modal.component'
import { DenialsDashboardPage } from './illuminate/denials-dashboard/denials-dashboard.page'
import { IlluminateDashboardPage } from './illuminate/illuminate-dashboard/illuminate-dashboard.page'
import { UsageInsightsDashboardPage } from './illuminate/usage-insights-dashboard/usage-insights-dashboard.page'
import { ObserveDashboardPage } from './observe-dashboard/observe-dashboard.page'
import { ThroughputReportDashboardPage } from './revbot/throughput-report-dashboard/throughput-report-dashboard.page'
import { CompletedTeleportLinkTableRowComponent } from './teleport/components/completed-teleport-link-table-row/completed-teleport-link-table-row.component'
import { ContactPayerLinkTableRowComponent } from './teleport/components/contact-payer-link-table-row/contact-payer-link-table-row.component'
import { ContactPayerModalDropdownComponent } from './teleport/components/contact-payer-modal-dropdown/contact-payer-modal-dropdown.component'
import { ContactPayerModalComponent } from './teleport/components/contact-payer-modal/contact-payer-modal.component'
import { TeleportLinkTableRowComponent } from './teleport/components/teleport-link-table-row/teleport-link-table-row.component'
import { TeleportLinkTableComponent } from './teleport/components/teleport-link-table/teleport-link-table.component'

@NgModule({
  declarations: [
    ObserveUserEditModalComponent,
    AnnotationsPage,
    AppComponent,
    HeaderMenuComponent,
    ClaimDetailsComponent,
    ServicelinesComponent,
    ClaimsListPage,
    ClaimDetailPage,
    NgbdSortableHeader,
    CreateTaskComponent,
    ClaimKeyDetailsComponent,
    BreadcrumbsComponent,
    HomePage,
    NotificationsComponent,
    LoginPage,
    ActivatePage,
    ConfirmPage,
    LoggedInPage,
    TaskCommentsComponent,
    TaskActionsComponent,
    AdminPage,
    TaskTypeDetailsPage,
    TaskTypeEditPage,
    TaskTypeSearchComponent,
    TaskTypeMergePage,
    TaskTypeCardComponent,
    TaskTypeAssignPage,
    TaskGroupEditPage,
    TaskGroupAssignPage,
    TasksDeletePage,
    ReportingPage,
    ResetPage,
    SelectOrgPage,
    AuthLayoutComponent,
    NewPasswordPage,
    BotsListPage,
    TeamQuickStatsComponent,
    EmployeeProductivityComponent,
    UserSearchComponent,
    ClaimPaymentsComponent,
    FacetedSearchComponent,
    TextFacetComponent,
    TypeaheadFacetComponent,
    CheckboxFacetComponent,
    RadioFacetComponent,
    DaterangeFacetComponent,
    SelectedFacetsComponent,
    DynamicComponentDirective,
    NumberRangeFacetComponent,
    RangeSliderFacetComponent,
    SaveSearchComponent,
    LoadSearchComponent,
    TaskGroupSearchComponent,
    TaskGroupCardComponent,
    CreateTaskGroupComponent,
    TaskGroupDetailsPage,
    TaskGroupScoreComponent,
    ClaimSearchEditPage,
    ClaimSearchDetailPage,
    SavedSearchSearchComponent,
    BotOverviewPage,
    BotKeyDetailsComponent,
    BotRunsFilterComponent,
    BotRunsSearchComponent,
    BotRunCardComponent,
    TaskGroupFilterComponent,
    BotDetailPage,
    BotRunOverviewPage,
    BotRunKeyDetailsComponent,
    BotRunsGraphComponent,
    ProgressModalComponent,
    PreDefinedFacetComponent,
    PrettyPrintPipe,
    TaskGroupsPage,
    TasksPage,
    TaskGroupOverviewComponent,
    TaskGroupOverviewCardComponent,
    TaskCardComponent,
    TaskClaimCardComponent,
    TaskStatusIconComponent,
    CopyValueComponent,
    CopyrightComponent,
    TaskFilterComponent,
    TeamsPage,
    TeamCardComponent,
    CreateTeamComponent,
    EditUserPage,
    PasswordValidatorComponent,
    ClaimListColumnsComponent,
    GetPipe,
    ClaimGenerateNoteComponent,
    TeleportPage,
    ClaimStatusComponent,
    PayersListPage,
    PayerEditPage,
    OrgLogoPage,
    FileUploadDirective,
    FeatureFlagDirective,
    ClientCredentialsListPage,
    ClientCredentialEditPage,
    WorkflowListPage,
    WorkflowPage,
    PaneComponent,
    DagComponent,
    WellComponent,
    WorkflowTasksComponent,
    OutcomeSelectorComponent,
    PasswordInputComponent,
    WorkflowAssignPage,
    WorkflowSearchComponent,
    WorkflowCardComponent,
    TaskGroupTypeaheadComponent,
    DashboardPage,
    TaskGroupAssignAvatarComponent,
    ViewEmailsComponent,
    DeleteWorkflowsPage,
    CombinePayersPage,
    PayerCardComponent,
    PayerSearchComponent,
    ClaimResponsibilityComponent,
    TeleportButtonComponent,
    ComparisonFacetComponent,
    SupportAndMaintenancePage,
    ReportListPage,
    ReportUploadPage,
    TeamEditPage,
    IlluminatePage,
    OpportunitiesPage,
    LevelBadgeComponent,
    OpportunityDetailsPage,
    WorkflowPhaseDetailsComponent,
    TableItemComponent,
    ProfitImprovementComponent,
    FeatureFlagEditPage,
    ProfitImprovementComponent,
    VideoModalComponent,
    TaskPanelComponent,
    TaskPickedByComponent,
    TeleportFeedbackPage,
    RequestPage,
    ImageAnnotateComponent,
    DeploymentStatusComponent,
    CumulativeOpportunityStatComponent,
    ClaimsExportListComponent,
    ImageModalComponent,
    FeatureFlagPage,
    PrivacyPolicyPage,
    CookiePolicyPage,
    TermsOfUsePage,
    AsPipe,
    DurationPipe,
    OrgListPage,
    CreateOrgComponent,
    SelectOrgSetComponent,
    TeleportErrorPage,
    FeedbackSubmittedComponent,
    PayerPickerComponent,
    ProcedureSetDetailsComponent,
    HarListPage,
    UsernameComponent,
    PasswordComponent,
    AuthErrorCardComponent,
    MfaComponent,
    IlluminateWorkflowGroupPage,
    TabComponent,
    TableauDashboardComponent,
    IlluminateGraphPanelComponent,
    IlluminateGraphPanelToolbarComponent,
    PdfViewerComponent,
    ImageViewerComponent,
    FilePage,
    TeleportInvoicePage,
    PriorAuthPage,
    PriorAuthDocsUploadPage,
    DocumentUploadComponent,
    StatusPage,
    SecurityAndCompliancePage,
    TrimmedInputDirective,
    ObserveAccessControlAuditTrailPage,
    PriorAuthDashboardPage,
    AnnotatedRecordSlideComponent,
    QueryCenterComponent,
    QediAdminLinksComponent,
    AutomationsQueryCenterPage,
    TeleportLinkTableComponent,
    TeleportLinkTableRowComponent,
    ContactPayerLinkTableRowComponent,
    CompletedTeleportLinkTableRowComponent,
    ContactPayerModalComponent,
    IlluminateDashboardPage,
    UsageInsightsDashboardPage,
    ObserveDashboardPage,
    InactivityWarningComponent,
    ObserveConfigPage,
    ConfigurationPipelineComponent,
    AnnotationBuilderComponent,
    UrlGroupsComponent,
    AddConfigurationsComponent,
    BotModal,
    OrganizationManagementComponent,
    DashboardOrgListComponent,
    PermissionsDirective,
    ObserveAccessControlListComponent,
    ObserveUsageSummaryComponent,
    ObserveAccessControlPage,
    ObserveAcessControlEditComponent,
    ObserveTestingPage,
    EditOrgDashboardPage,
    ThroughputReportDashboardPage,
    DenialsDashboardPage,
    ContactPayerModalDropdownComponent,
    ObserveOrgListComponent,
    ObserveOrgSettingsComponent,
    ObserveBuildConfigPageComponent,
    TagSearchComponent,
    PayerPlanGroupEditPage,
    PayerPlanGroupPage,
    ObserveUsersListComponent,
    ObserveAdminLinksComponent,
    ObserveUsersActivityLogComponent,
    ObserveActivityLogComponent,
    SamlConfigurationPage,
    SamlOrgListPage,
    EdiReader,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    AnalyticsModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    NgChartsModule,
    NgxSliderModule,
    DragDropModule,
    SharedModule,
    BotJobsAdminModule,

    OrgRolesComponent,
    UserOrgAssignmentsComponent,
    ConfirmDeleteOrgDialogComponent,

    JuiButtonComponent,
  ],
  providers: [
    { provide: Window, useValue: window },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InactivityHttpInterceptor,
      multi: true,
    },
    JuiDialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
