import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'app-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss'],
})
export class TypeaheadSelectComponent implements OnInit {
  /**
   * A selector specifying the element the typeahead popup will be appended to.
   *
   * Currently only supports "body".
   *
   * @see {@link https://ng-bootstrap.github.io/#/components/typeahead/api#NgbTypeahead}
   */
  @Input() container?: 'body'

  @Input() placeholder: string = 'Search'
  @Input() searchByTerm: (term) => Observable<any[]>
  @Input() resultFormatter: (x: any) => string
  @Input() selectedItem: any

  @Output() selectValue = new EventEmitter<any>()

  @ViewChild('instance', { static: true }) instance: NgbTypeahead

  click$ = new Subject<string>()
  focus$ = new Subject<string>()

  constructor() {}

  ngOnInit(): void {}

  /**
   * Format typeahead input and results list
   *
   * @param {string} x
   * @return {*} {string}
   * @memberof TypeaheadFacetComponent
   */
  defaultFormatter = (x: string): string => x

  search = (text$: Observable<string>): Observable<string[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500))
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      distinctUntilChanged(),
      tap((term) => {
        if (!term) this.selectValue.emit(null)
      }),
      switchMap((term) =>
        this.searchByTerm(term).pipe(
          catchError(() => {
            return of([])
          }),
        ),
      ),
    )
  }
}
