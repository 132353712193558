<div class="p-2 h-100 w-100">
  <div class="card rounded-lg h-100 border-0">
    <a
      class="card-header rounded-top-lg border-0 text-dark h5 p-4"
      [ngClass]="teamsService.getTeamColor(teamPod?.team?.id)"
      [routerLink]="['/admin/teams/', teamPod?.team?.id]"
    >
      {{ teamPod?.team?.name }}
    </a>
    <div class="card-body rounded-bottom-lg">
      <div class="row m-0">
        <div class="col p-0">
          <div class="text-muted">Supervisor:</div>
          <h5>{{ teamPod?.supervisor?.name || teamPod?.supervisor?.email || 'N/A' }}</h5>
          <div class="mt-4 text-muted">
            <span [ngbTooltip]="teamPod?.team?.description" container="body">{{
              teamPod?.team?.description?.length > 40
                ? (teamPod?.team?.description | slice: 0:40) + '...'
                : teamPod?.team?.description
            }}</span>
          </div>
          <div class="mt-4">
            <h5>{{ teamPod?.team?.memberUserIds?.length }}</h5>
            <div [ngPlural]="teamPod?.team?.memberUserIds?.length" class="text-muted">
              <ng-template ngPluralCase="=1">Member</ng-template>
              <ng-template ngPluralCase="other">Members</ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
