import { Component } from '@angular/core'


@Component({
  selector: 'app-dashboard-org-list',
  templateUrl: './dashboard-org-list.component.html'
})
export class DashboardOrgListComponent {

  public urlToRoute = '/admin/dashboard-publishing/'
 
  constructor() {}
}
