<div class="d-flex flex-row">
  <app-typeahead-select
    #vxDocumentTypeTypeahead
    data-t="vx-page-type-document-type-typeahead"
    placeholder="Select Document Type"
    container="body"
    [searchByTerm]="buildSearchVxDocumentTypes"
    [resultFormatter]="formatVxDocumentTypeName"
    (selectValue)="onVxDocumentTypeSelected.emit($event)"
    [selectedItem]="selectedVxDocumentType"
  />
  <span class="ml-1">
    <button
      type="button"
      class="btn btn-light border-alt-light-gray text-alt-medium-gray"
      (click)="onVxDocumentTypeSelected.emit(null)"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
  </span>
</div>
