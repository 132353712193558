/**
 * All permission strings used by this repo. Note: New permission strings must be populated in db via migrations.
 */
export const Permissions = {
  automation: {
    revbot: {
      all: 'automation.revbot.all',
    },
  },
  illuminate: {
    all: {
      all: 'illuminate.all.all',
    },
  },
  observe: {
    config: {
      all: 'observe.config.all',
    },
  },
  pathfinder: {
    all: {
      all: 'pathfinder.all.all',
    },
  },
  priorAuth: {
    dashboard: {
      view: 'prior_auth.dashboard.view',
    },
  },
  qedi: {
    all: {
      all: 'qedi.all.all',
    },
  },
  queryCenter: {
    automations: {
      all: 'query_center.automations.all',
    },
  },
  system: {
    admin: {
      all: 'system.admin.all',
    },
    featureFlag: {
      all: 'system.featureFlag.all',
    },
    org: {
      all: 'system.org.all',
    },
    payers: {
      all: 'system.payers.all',
    },
    reporting: {
      all: 'system.reporting.all',
    },
    report: {
      all: 'system.reports.all',
    },
  },
  wmf: {
    dashboard: {
      view: 'wfm.dashboard.view',
    },
  },
  visionx: {
    all: {
      all: 'visionx.all.all',
    },
    dashboard: {
      view: 'visionx.dashboard.view',
    },
    document: {
      all: 'visionx.document.all',
      create: 'visionx.document.create',
      view: 'visionx.document.view',
    },
    documentManager: {
      view: 'visionx.document_manager.view',
    },
    documentTypes: {
      view: 'visionx.document_types.view',
    },
    documentTypeMappings: {
      view: 'visionx.document_type_mappings.view',
    },
    shipmentManager: {
      view: 'visionx.shipment_manager.view',
    },
    shipment: {
      view: 'visionx.shipment.view',
    },
    file: {
      all: 'visionx.file.all',
    },
    identifierReview: {
      view: 'visionx.identifier_review.view',
    },
    pageTypes: {
      view: 'visionx.page_types.view',
    },
    quickReview: {
      view: 'visionx.quick_review.view',
    },
    ruleEditor: {
      all: 'visionx.ruleEditor.all',
      view: 'visionx.rule_editor.view', // yes I know this one is cased differently but this is how these permissions are cased in the DB
    },
  },
} as const

type PermissionStrings<T> = T extends string
  ? T
  : {
      [K in keyof T]: PermissionStrings<T[K]>
    }[keyof T]

export type Permission = PermissionStrings<typeof Permissions>

export type PermissionConditionType = 'OR' | 'AND'
