import { Component, Input, OnInit } from '@angular/core'
import { Opportunity } from 'app/illuminate/opportunities'

/**
 * Component to display a single phase of an opportunity
 *
 * @export
 * @class WorkflowPhaseDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-workflow-phase-details',
  templateUrl: './workflow-phase-details.component.html',
  styleUrls: ['./workflow-phase-details.component.scss'],
})
export class WorkflowPhaseDetailsComponent implements OnInit {
  @Input() phase: number
  @Input() opportunity: Opportunity

  phaseDescriptions: string[] = [
    'BASELINE',
    'PATHFINDER DECISION SUPPORT',
    'PARTIAL AUTOMATION',
    'FULL END-TO-END AUTOMATION',
  ]
  phaseKeys: string[] = [null, 'phaseOne', 'phaseTwo', 'fullAutomation']
  phaseColors: string[] = ['alt-medium-gray', 'alt-light-blue', 'alt-green', 'alt-purple']
  phaseDescription: string
  phaseColor: string
  phaseKey: string

  constructor() {}

  ngOnInit(): void {
    this.phaseDescription = this.phaseDescriptions[this.phase]
    this.phaseColor = this.phaseColors[this.phase]
    this.phaseKey = this.phaseKeys[this.phase]
  }
}
