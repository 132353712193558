import { Component, OnInit, Input, ViewChildren, QueryList, Output, EventEmitter, OnDestroy } from '@angular/core'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { NgbdSortableHeader, SortDirection, SortEvent } from 'app/shared/directives/sortable.directive'
import { ListResponseMetaData, ObserveMissConditions, ObserveSummary, ObserveUrlGroup } from 'generated/graphql'
import { first, takeUntil } from 'rxjs/operators'
import { ObserveConfigService, reasonsWithLabel } from '../../service/observe-config.service'
import { UntypedFormControl } from '@angular/forms'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-url-groups',
  templateUrl: './url-groups.component.html',
  styleUrls: ['./url-groups.component.scss'],
})
export class UrlGroupsComponent implements OnInit, OnDestroy {
  @Input()
  selectedObserveSummary: ObserveSummary
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>

  @Output()
  selectedUrlGroup: EventEmitter<ObserveUrlGroup> = new EventEmitter<ObserveUrlGroup>()

  @Output()
  selectedMissReason: EventEmitter<ObserveMissConditions> = new EventEmitter<ObserveMissConditions>()

  missReason = new UntypedFormControl(null)

  listMetaData: ListResponseMetaData = {
    offset: 0,
    limit: 50,
    total: 0,
  }
  readonly COLUMN_TYPES = {
    rawUrl: 'Raw Url',
    volume: 'Volume',
    screenLabel: 'Screen Label',
    actionName: 'Action',
    targetName: 'Target Name',
    rawTargetName: 'Raw Target Name',
  }
  urlGroupList: ObserveUrlGroup[] = []
  displayedRows: ObserveUrlGroup[] = []
  loading = false
  readonly PAGE_SIZE = 50
  currentPage = 1
  missReasons: reasonsWithLabel[] = [
    {
      label: 'Missing Screen Label',
      condition: ObserveMissConditions.UrlMissingScreenName,
    },
    {
      label: 'Missing Action',
      condition: ObserveMissConditions.ClickEventMissingActionName,
    },
  ]
  private destroyed$ = new Subject<void>()

  constructor(private observeConfigService: ObserveConfigService) {}

  async ngOnInit(): Promise<void> {
    this.missReason.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(async (selection: reasonsWithLabel) => {
      this.clearTable()
      if (selection) {
        this.selectedMissReason.emit(selection.condition)
        await this.getUrlGroups()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  public async getUrlGroups(): Promise<void> {
    this.loading = true
    if (this.selectedObserveSummary) {
      const data = (
        await this.observeConfigService
          .getUrlGroups(
            this.selectedObserveSummary.orgId,
            this.selectedObserveSummary.applicationURL,
            this.missReason.value.condition,
          )
          .pipe(first())
          .toPromise()
      ).data
      this.urlGroupList = [...data.urlGroupsList]
      this.listMetaData.total = this.urlGroupList.length || 0
      this.getDisplayRows()
    }
    this.loading = false
  }

  public onPageUpdate($event: PaginationUpdateEvent): void {
    this.currentPage = $event.newPageNumber
    this.getDisplayRows()
  }

  public getDisplayRows(): void {
    const start = this.currentPage == 1 ? 0 : this.PAGE_SIZE * (this.currentPage - 1)
    const end = start + this.PAGE_SIZE
    this.displayedRows = this.urlGroupList?.slice(start, end) ?? []

    this.listMetaData = {
      ...this.listMetaData,
      offset: start,
    }
  }

  public showAddConfigurations(row: ObserveUrlGroup): void {
    this.selectedUrlGroup.emit(row)
  }

  public onSort($event: SortEvent): void {
    const sortColumn = $event.column
    const sortDirection: SortDirection = $event.direction
    if (sortColumn == this.COLUMN_TYPES.rawUrl) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.groupRawUrl.localeCompare(a.groupRawUrl)
          : a.groupRawUrl.localeCompare(b.groupRawUrl)
      })
    } else if (sortColumn == this.COLUMN_TYPES.volume) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc' ? a.volume - b.volume : b.volume - a.volume
      })
    } else if (sortColumn == this.COLUMN_TYPES.screenLabel) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.screenLabel.localeCompare(a.screenLabel)
          : a.screenLabel.localeCompare(b.screenLabel)
      })
    } else if (sortColumn == this.COLUMN_TYPES.actionName) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.actionName.localeCompare(a.actionName)
          : a.actionName.localeCompare(b.actionName)
      })
    } else if (sortColumn == this.COLUMN_TYPES.targetName) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.targetName.localeCompare(a.targetName)
          : a.targetName.localeCompare(b.targetName)
      })
    } else if (sortColumn == this.COLUMN_TYPES.rawTargetName) {
      this.urlGroupList.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.rawTargetName.localeCompare(a.rawTargetName)
          : a.rawTargetName.localeCompare(b.rawTargetName)
      })
    }

    this.headers?.forEach((header) => {
      if (header.sortable === sortColumn) {
        header.direction = sortDirection
      } else {
        header.direction = ''
      }
    })

    // reset to page 1 onSort
    this.onPageUpdate({ newPageNumber: 1 })
    this.listMetaData = { ...this.listMetaData }
    this.getDisplayRows()
  }

  public clearTable(): void {
    this.listMetaData.total = 0
    this.displayedRows = []
  }

  public clearMissReason(): void {
    this.missReason.patchValue(null)
    this.clearTable()
  }

  public onReasonSelection(reason: reasonsWithLabel): void {
    this.missReason.patchValue(reason)
  }
}

