<div class="card detail-card h-100">
  <app-loading
    *ngIf="loading"
    innerClass="fa-3x"
    class="d-flex flex-column w-100 h-100 flex-grow-1 justify-content-center bg-white position-absolute"
    outerClass="align-self-center text-brand-light-blue"
  ></app-loading>
  <div class="card-body flex-basis-lg-0 h-100">
    <div class="row h-100">
      <div class="col-xl-6 d-flex flex-column flex-grow-1 flex-basis-0 scrolling-sidebar order-2">
        <app-claim-key-details [claim]="claim"></app-claim-key-details>
        <app-claim-status [claim]="claim"></app-claim-status>
        <app-claim-details [claim]="claim"></app-claim-details>
        <app-claim-responsibility [claim]="claim"></app-claim-responsibility>
        <app-claim-servicelines
          *ngIf="claim?.servicelines?.length"
          [servicelines]="claim?.servicelines"
        ></app-claim-servicelines>
        <app-claim-payments
          *ngIf="claim?.ehrPayments?.length || claim?.payerPayments?.length"
          [payments]="{ehr: claim?.ehrPayments, payer: claim?.payerPayments}"
        ></app-claim-payments>
      </div>
      <div class="col-xl-6 d-flex flex-column flex-grow-1 flex-basis-0 scrolling-sidebar order-1 order-xl-3">
        <div class="row mb-3">
          <div class="col">
            <div class="d-flex justify-content-between mb-3">
              <h5>Tasks</h5>
              <button
                type="button"
                class="btn btn-brand-green rounded-pill font-weight-bold px-3"
                (click)="createTask()"
                data-t="create-new-task"
              >
                <i class="fa fa-fw fa-plus-circle pr-3" data-t="create-task-button"></i>
                Create Task
              </button>
            </div>
            <app-task-panel [tasks]="tasks"></app-task-panel>
            <app-claim-generate-note [claim]="claim"></app-claim-generate-note>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
