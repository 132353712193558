import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PayerCardActionsService } from 'app/admin/payers/payer-card-actions.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Payer } from 'generated/graphql'
import { Subscription } from 'rxjs'

/**
 * Page to allow combining existing payers
 *
 * @export
 * @class CombinePayersPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-combine-payers',
  templateUrl: './combine-payers.page.html',
})
export class CombinePayersPage implements OnInit, OnDestroy {
  rightSub$: Subscription
  twoRightSub$: Subscription
  removeSub$: Subscription

  keep: Payer = null
  combine: Payer[] = []

  isSaving: boolean = false

  constructor(
    private router: Router,
    private actionService: PayerCardActionsService,
    private toast: ToastService,
    private modal: NgbModal,
    private payerService: PayersService,
  ) {
    this.rightSub$ = actionService.rightClicked$.subscribe((payer: Payer) => {
      if (this.keep) {
        this.actionService.hideCard(this.keep, false)
      }
      this.keep = payer
      let exists = this.combine.findIndex((p) => p.id === payer.id)
      if (exists > -1) {
        this.combine.splice(exists, 1)
      }
      this.actionService.hideCard(payer, true)
    })
    this.twoRightSub$ = actionService.twoRightClicked.subscribe((payer: Payer) => {
      let exists = this.combine.findIndex((p) => p.id === payer.id)
      if (exists === -1) {
        this.combine.push(payer)
      }
      if (this.keep?.id === payer.id) {
        this.keep = null
      }
      this.actionService.hideCard(payer, true)
    })
    this.removeSub$ = actionService.removeClicked$.subscribe((payer: Payer) => {
      if (this.keep?.id === payer.id) {
        this.keep = null
      } else {
        let exists = this.combine.findIndex((p) => p.id === payer.id)
        this.combine.splice(exists, 1)
      }
      this.actionService.hideCard(payer, false)
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.rightSub$.unsubscribe()
    this.twoRightSub$.unsubscribe()
    this.removeSub$.unsubscribe()
  }

  /**
   * Cancel current combination and reset list of payers
   *
   * @memberof CombinePayersPage
   */
  cancel(): void {
    this.keep = null
    this.combine = []
    this.actionService.hidden = []
  }

  /**
   * Launch modal to confirm payer combination
   *
   * @memberof CombinePayersPage
   */
  confirmSave(): void {
    if (!this.combine?.length || !this.keep) {
      this.toast.error(
        'Please pick one payer to keep and at least one payer to combine.',
        'no payers picked to combine or no target payer picked',
      )
    } else {
      const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
      modalRef.componentInstance.title = 'Combine payers?'
      modalRef.componentInstance.body = `Combine ${this.combine?.length + 1} payers under ${this.keep?.name}`
      modalRef.componentInstance.yes = 'Combine payers'
      modalRef.componentInstance.yesClass = 'btn-brand-green'

      modalRef.result.then(
        (closed) => {
          this.save()
        },
        (dismissed) => {},
      )
    }
  }

  /**
   * Merge list of 'combine' payers to one 'keep' payer
   *
   * @return {*}  {Promise<void>}
   * @memberof CombinePayersPage
   */
  async save(): Promise<void> {
    try {
      let childIds = this.combine.map((payer) => payer.id)
      await this.payerService.mergePayers(this.keep.id, childIds)
      this.toast.success(`Combined payers under "${this.keep.name}"`)
      this.cancel()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not merge payers'), JSON.stringify(e))
    }
  }
}
