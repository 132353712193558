<div
  *ngIf="allowCollapse"
  role="button"
  (click)="collapse.toggle()"
  [attr.aria-label]="isCollapsed ? 'Show details' : 'Hide details'"
  [ngClass]="isCollapsed ? 'toggle toggle-collapsed' : 'toggle'"
></div>

<div class="wrapper">
  <div class="d-flex pr-5">
    <div (click)="collapse.toggle()" class="clickable flex-grow-1">
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <div class="flex-shrink-1">
      <ng-content select="[slot=actions]"></ng-content>
    </div>
  </div>

  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" (ngbCollapseChange)="onToggled.emit(isCollapsed)">
    <ng-content select="[slot=content]"></ng-content>
  </div>
</div>
