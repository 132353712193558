import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import {
  CreateTaskType,
  DeleteTaskType,
  MergeTaskTypes,
  TaskTypeGet,
  TaskTypeSearch,
  UpdateTaskType,
} from 'app/pathfinder/tasks/task-types.gql'
import { debug } from 'app/shared/utils/debug'
import {
  ListResponseMetaData,
  MutationCreateTaskTypeArgs,
  MutationDeleteTaskTypeArgs,
  MutationMergeTaskTypesArgs,
  MutationUpdateTaskTypeArgs,
  QueryTaskTypeArgs,
  QueryTaskTypesArgs,
  TaskType,
  TaskTypeList,
} from 'generated/graphql'

/**
 * Handle task type operations
 *
 * @export
 * @class TaskTypesService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskTypesService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*} {ListResponseMetaData}
   * @memberof TaskTypesService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve individual task type
   *
   * @param {string} id
   * @return {*}  {Promise<TaskType>}
   * @memberof TaskTypesService
   */
  async getTaskType(id: string): Promise<TaskType> {
    debug('task-type-service', 'get task type', id)
    let result = await this.apollo
      .query<{ taskType: TaskType }, QueryTaskTypeArgs>({
        query: TaskTypeGet,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.taskType
  }

  /**
   * Retrieve a list of task types based on term
   *
   * @param {string} term
   * @return {*}  {Promise<TaskType[]>}
   * @memberof TaskTypesService
   */
  async searchTaskTypes(term: string): Promise<TaskType[]> {
    debug('task-type-service', 'search for task type', term)
    let result = await this.apollo
      .query<{ taskTypes: TaskTypeList }, QueryTaskTypesArgs>({
        query: TaskTypeSearch,
        variables: {
          search: term,
          limit: 10,
        },
        fetchPolicy: 'network-only',
      })
      .toPromise()

    this.meta = result.data.taskTypes.meta

    return result.data.taskTypes.entities
  }

  /**
   * Create a new task type
   *
   * @param {{ name: string; description: string; averageMinutes?: number }} taskType
   * @return {*}  {Promise<TaskType>}
   * @memberof TaskTypesService
   */
  async createTaskType(taskType: { name: string; description: string; averageMinutes?: number }): Promise<TaskType> {
    debug('task-type-service', 'create task type', taskType)
    let result = await this.apollo
      .mutate<{ createTaskType: TaskType }, MutationCreateTaskTypeArgs>({
        mutation: CreateTaskType,
        variables: {
          data: taskType,
        },
      })
      .toPromise()

    return result.data.createTaskType
  }

  /**
   * Update an existing task type
   *
   * @param {string} id
   * @param {{ name: string; description: string; averageMinutes: number }} taskType
   * @return {*}  {Promise<TaskType>}
   * @memberof TaskTypesService
   */
  async updateTaskType(
    id: string,
    taskType: { name: string; description: string; averageMinutes: number },
  ): Promise<TaskType> {
    debug('task-type-service', 'update task type', taskType)
    let result = await this.apollo
      .mutate<{ updateTaskType: TaskType }, MutationUpdateTaskTypeArgs>({
        mutation: UpdateTaskType,
        variables: {
          data: taskType,
          id: id,
        },
      })
      .toPromise()

    return result.data.updateTaskType
  }

  /**
   * Delete a task type
   *
   * @param {string} id
   * @return {*}  {Promise<number>}
   * @memberof TaskTypesService
   */
  async deleteTaskType(id: string): Promise<number> {
    debug('task-type-service', 'delete task type', id)
    let result = await this.apollo
      .mutate<{ deleteTaskType: number }, MutationDeleteTaskTypeArgs>({
        mutation: DeleteTaskType,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.deleteTaskType
  }

  /**
   * Merge a list of task types, updating all entities that are linked
   * to any of the sourceIds task types to point to the targetId task type
   *
   * @param {string[]} sourceIds
   * @param {string} targetId
   * @return {*}  {Promise<number>}
   * @memberof TaskTypesService
   */
  async mergeTaskTypes(sourceIds: string[], targetId: string): Promise<number> {
    debug('task-type-service', 'merge task types', sourceIds, targetId)
    let result = await this.apollo
      .mutate<{ mergeTaskTypes: number }, MutationMergeTaskTypesArgs>({
        mutation: MergeTaskTypes,
        variables: {
          sourceIds: sourceIds,
          targetId: targetId,
        },
      })
      .toPromise()

    return result.data.mergeTaskTypes
  }
}
