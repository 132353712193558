import {
  Connection,
  messageIsForClient,
  messageIsForServer,
  tryToGetMessageObject,
} from '@hanseltime/janus-simple-command'

import { CommandMessage, FailStatusMessage, SuccessStatusMessage } from '@hanseltime/janus-simple-command'
import { debug } from 'app/shared/utils/debug'

export interface RevbotArguments {
  disableSavePhaseDbWrite?: boolean
  botType?: any
  beta?: boolean
  orgId?: string
  sourceId?: string
  claimId?: string
  portal?: any
  payer?: any
  historyId?: string
  headless?: boolean
  credentialType?: any
  botJobId?: string
  org?: string
  remitDate?: string
  availityPayerId?: string
  memberId?: string
  procedureCode?: string
  procedureDate?: string
  procedureSetId?: string
  ediPort?: number
  ediHost?: string
  cptCodeStart?: number
  cptCodeEnd?: number
  // If the bot was started by a revbot version job or legacy botjob entity
  asRevBot?: boolean
  taskGroupName?: string
  refinedTaskGroupName?: string
}

export type Commands = 'startBot'
export type CommandMap = {
  startBot: CommandMessage<'startBot', RevbotArguments>
}
export type StatusMap = {
  startBot:
    | SuccessStatusMessage<{
        msg?: string
        claimDiff: any
        claimDb: Record<string, any>
        claimScraped: Record<string, any>
      }>
    | FailStatusMessage
}

export class WebSocketConnection implements Connection {
  private messageHandler: ((msg: string) => Promise<void>) | undefined
  private errorHandler: ((error: Error) => Promise<void>) | undefined
  private closeHandler: (() => Promise<void>) | undefined

  private connect: Promise<void>
  private cancelConnect: ((error: Error) => void) | undefined
  private pending = new Set<Promise<any>>()

  constructor(private ws: WebSocket, private type: 'server' | 'client') {
    this.connect = new Promise<void>((res, reject) => {
      if (ws.readyState === WebSocket.CONNECTING) {
        this.cancelConnect = reject
        ws.addEventListener('open', () => {
          this.cancelConnect = undefined
          res()
        })
        return
      }
      if (ws.readyState === ws.CLOSED || ws.readyState === ws.CLOSING) {
        throw new Error('Attempting to make a connection from a closing Websocket')
      }
      res()
    })
    ws.addEventListener('message', (event) => {
      if (!this.messageHandler) {
        debug('bot-qa', 'returning void onmessage')
        return
      }
      const msg = event.data
      const msgObj = tryToGetMessageObject(msg)
      const appliesToConnection =
        (this.type === 'client' && messageIsForClient(msgObj as any)) ||
        (this.type === 'server' && messageIsForServer(msgObj as any))
      if (!appliesToConnection) return

      const prom = this.messageHandler(msg).finally(() => {
        this.pending.delete(prom)
      })
      this.pending.add(prom)
    })
    ws.addEventListener('error', (event) => {
      if (!this.errorHandler) return
      const prom = this.errorHandler(new Error('ws errored')).finally(() => {
        this.pending.delete(prom)
      })
      this.pending.add(prom)
    })
    ws.addEventListener('close', () => {
      if (!this.closeHandler) return
      const prom = this.closeHandler().finally(() => {
        this.pending.delete(prom)
      })
    })
  }

  async open(): Promise<void> {
    await this.connect
  }

  async sendMessage(msg: string): Promise<void> {
    this.ws.send(msg)
  }
  onMessage(messageHandler: (msg: string) => Promise<void>): void {
    this.messageHandler = messageHandler
  }
  onError(errorHandler: (error: Error) => Promise<void>): void {
    this.errorHandler = errorHandler
  }
  onClose(closeHandler: () => Promise<void>): void {
    this.closeHandler = closeHandler
  }

  async close(): Promise<void> {
    if (this.cancelConnect) {
      this.cancelConnect(new Error('Close called before connection'))
    }
    await Promise.allSettled(this.pending)
    this.ws.close()
  }
}
