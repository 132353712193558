import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-cumulative-opportunity-stat',
  templateUrl: './cumulative-opportunity-stat.component.html',
  styleUrls: ['./cumulative-opportunity-stat.component.scss'],
})
export class CumulativeOpportunityStatComponent implements OnInit {
  @Input() title: string
  @Input() amount: number
  @Input() icon?: string
  @Input() janusFontIconName?: string
  @Input() iconColor: string = 'text-brand-dark-gray'
  @Input() dollars: boolean = false

  formattedAmount: {
    amount?: number
    thousand?: boolean
    million?: boolean
  } = {}

  constructor() {}

  ngOnInit(): void {
    if (this.amount) {
      if (this.amount / 1000000 >= 1) {
        this.formattedAmount.million = true
        this.formattedAmount.amount = this.amount / 1000000
      } else if (this.amount / 10000 >= 1) {
        // only show value in thousands if there are at least ten thousand
        this.formattedAmount.thousand = true
        this.formattedAmount.amount = this.amount / 1000
      } else {
        this.formattedAmount.amount = this.amount
      }
    }
  }
}
