import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { OrgService } from 'app/admin/org/org.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotJob, ListResponseMetaData, Organization } from 'generated/graphql'
import { first } from 'rxjs/operators'

/**
 * Page to display a list of bot jobs
 *
 * @export
 * @class BotJobsListPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-bot-jobs-list',
  templateUrl: './bot-jobs-list.page.html',
  styleUrls: ['./bot-jobs-list.page.scss'],
  host: { class: 'h-100' },
})
export class BotJobsListPage implements OnInit {
  botJobs: { botJob: BotJob; orgs: string[] }[] = []
  loading: boolean = false
  botJobsSearch = new UntypedFormControl('')
  orgFilter: string
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 50
  fullOrgs: Organization[]

  constructor(private botJobsService: BotJobsService, private toast: ToastService, private orgsService: OrgService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true

    try {
      let result = await this.orgsService.getOrgs().pipe(first()).toPromise()
      this.fullOrgs = result?.data?.organizations?.entities
      await this.searchBotJobs()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not initially load bot jobs'), JSON.parse(e))
    }
  }

  /**
   * Load a page of bot jobs
   *
   * @param {number} [page]
   * @return {*}  {Promise<void>}
   * @memberof BotJobsListPage
   */
  async searchBotJobs(page?: number, orgId?: string): Promise<void> {
    this.loading = true
    try {
      let offset
      if (page) {
        offset = (page - 1) * (this.meta?.limit ?? 0)
      }
      let botJobs = await this.botJobsService
        .getBotJobs(offset, undefined, undefined, this.botJobsSearch?.value, false, orgId)
        .pipe(first())
        .toPromise()

      const botJobList = botJobs?.data?.botJobsV2?.entities || []
      this.botJobs = botJobList.map((botJob) => {
        let orgs = this.fullOrgs?.filter((org) => botJob.orgIds?.includes(org.id)).map((org) => org?.name)
        return { botJob, orgs }
      })

      this.meta = this.botJobsService.getMeta()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load bot jobs'), JSON.stringify(e))
    }
    this.loading = false
  }

  pickedOrg(event: Organization): void {
    this.orgFilter = event?.name
    this.searchBotJobs(1, event?.id)
  }
}
