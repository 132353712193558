<div class="pane-wrapper position-absolute d-flex m-n2">
  <app-pane data-t="workflow-editor-details-pane" class="flex-basis-0 flex-grow-1 flex-column m-2">
    <form [formGroup]="form" (submit)="save()" class="h-100 d-flex flex-column">
      <div class="pane-header flex-grow-0 flex-shrink-0">
        <h1 class="pane-title">Create Workflow</h1>
      </div>

      <div class="pane-content flex-grow-1">
        <!-- name & description -->
        <app-collapsing-card>
          <div slot="title">
            <div class="d-flex justify-content-between">
              <h2 class="card-heading">{{ form.get('name').value || 'New Workflow' }}</h2>
              <i *ngIf="getErrorCode()" class="text-danger align-bottom mr-2 fa fa-2x fa-exclamation-circle"></i>
            </div>
          </div>
          <div slot="content">
            <app-well>
              <div *ngIf="getErrorCode()" class="d-flex justify-content-center alert alert-danger" role="alert">
                {{getErrorCode()}}
              </div>
              <div class="row">
                <div class="col-7">
                  <label class="form-label required" for="workflowName">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="workflowName"
                    formControlName="name"
                    [appFcBsValidation]="form.get('name')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-7">
                  <label class="form-label required" for="workflowDescription">Description</label>
                  <textarea
                    class="form-control"
                    id="workflowDescription"
                    formControlName="description"
                    [appFcBsValidation]="form.get('description')"
                  ></textarea>
                </div>
              </div>
            </app-well>
          </div>
        </app-collapsing-card>

        <!-- tasks -->
        <ng-container formArrayName="tasks">
          <app-collapsing-card
            #taskCard
            *ngFor="let task of tasks.controls; index as taskIndex"
            [isCollapsed]="taskIndex !== selectedTaskIndex"
            (onToggled)="toggledTask(taskIndex, $event)"
            [formGroupName]="taskIndex"
          >
            <div slot="title">
              <div class="d-flex justify-content-between">
                <h2 class="card-heading">{{ task.get('taskTypeName').value || 'Task' }}</h2>
                <div>
                  <i
                    *ngIf="getErrorCode(taskIndex, '*')"
                    class="text-danger align-bottom mr-2 fa fa-2x fa-exclamation-circle"
                  ></i>
                  <button type="button" class="btn btn-circle btn-secondary" (click)="removeTaskFromForm(taskIndex)">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div slot="content">
              <app-well>
                <div
                  *ngIf="getErrorCode(taskIndex)"
                  class="d-flex justify-content-center alert alert-danger"
                  role="alert"
                >
                  {{getErrorCode(taskIndex)}}
                </div>
                <div class="row">
                  <div class="col-7">
                    <label class="form-label required">Name</label>
                    <!-- TODO: add for attr -->
                    <app-task-type-typeahead
                      [selectedTaskType]="task.get('taskTypeName').value"
                      (onEdit)="changedTaskName(taskIndex, $event)"
                      (onSelect)="selectedTaskType(taskIndex, $event)"
                      [required]="true"
                    ></app-task-type-typeahead>
                  </div>
                </div>
                <div class="row">
                  <div class="col-7">
                    <label class="form-label required" [attr.for]="'task'+taskIndex+'description'">Description</label>
                    <textarea
                      class="form-control"
                      [attr.id]="'task'+taskIndex+'description'"
                      formControlName="taskTypeDescription"
                      [attr.readonly]="tasksWithLockedDescriptions.includes(taskIndex) ? true : undefined"
                      [appFcBsValidation]="task.get('taskTypeDescription')"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-7">
                    <div class="d-flex justify-content-end" *ngIf="tasksWithLockedDescriptions.includes(taskIndex)">
                      <div>
                        <button
                          type="button"
                          class="btn btn-alt-light-blue flex-grow-1 flex-basis-0 m-2"
                          (click)="unlockDescription(taskIndex)"
                        >
                          <i class="fa fa-pencil mr-1"></i>
                          Edit Task
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          class="btn btn-alt-green flex-grow-1 flex-basis-0 my-2"
                          (click)="copyTask(taskIndex, task)"
                        >
                          <i class="fa fa-copy mr-1"></i>
                          Copy Task
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </app-well>
              <h2 class="card-heading">Outcome</h2>
              <ng-container formArrayName="outcomes">
                <app-well
                  *ngFor="let outcome of $any(task.get('outcomes')).controls; index as outcomeIndex"
                  [formGroupName]="outcomeIndex"
                >
                  <div
                    *ngIf="getErrorCode(taskIndex, outcomeIndex)"
                    class="d-flex justify-content-center alert alert-danger"
                    role="alert"
                  >
                    {{getErrorCode(taskIndex, outcomeIndex)}}
                  </div>
                  <div class="row">
                    <div class="col-7">
                      <label class="form-label required" [attr.for]="'task'+taskIndex+'outcome'+outcomeIndex+'name'">
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        [attr.id]="'task'+taskIndex+'outcome'+outcomeIndex+'name'"
                        formControlName="name"
                        [appFcBsValidation]="task.get('outcomes.'+ outcomeIndex + '.name')"
                      />
                    </div>
                    <div class="col-5">
                      <label
                        class="form-label required"
                        [attr.for]="'task'+taskIndex+'outcome'+outcomeIndex+'description'"
                      >
                        Result
                      </label>
                      <app-outcome-selector
                        [options]="outcomeOptionsMap[taskIndex]"
                        [selection]="getOutcomeSelection(taskIndex, outcomeIndex)"
                        [required]="true"
                        (onSelection)="selectedOutcome(taskIndex, outcomeIndex, $event)"
                      >
                      </app-outcome-selector>
                    </div>
                  </div>
                  <div class="pt-3">
                    <button
                      type="button"
                      class="btn btn-circle btn-info mr-2"
                      *ngIf="outcomeIndex === $any(task.get('outcomes')).length - 1"
                      (click)="addOutcomeToForm(taskIndex)"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-circle btn-secondary"
                      (click)="removeOutcomeFromForm(taskIndex, outcomeIndex)"
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                </app-well>
                <app-well *ngIf="$any(task.get('outcomes')).length === 0">
                  None; completing task completes workflow
                  <div class="pt-3">
                    <button type="button" class="btn btn-circle btn-info mr-2" (click)="addOutcomeToForm(taskIndex)">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </app-well>
              </ng-container>
            </div>
          </app-collapsing-card>
        </ng-container>
      </div>

      <div class="pane-footer flex-grow-0 flex-shrink-0 d-flex mt-2">
        <button
          type="button"
          class="btn btn-alt-red flex-grow-1 flex-basis-0 mr-2"
          (click)="confirmDelete()"
          [disabled]="!workflowType.id || isDeleting"
        >
          <i class="fa fa-trash mr-1"></i>
          Delete
          <app-loading *ngIf="isDeleting" class="pl-2"></app-loading>
        </button>
        <button
          type="button"
          class="btn btn-alt-white flex-grow-1 flex-basis-0 mr-2 text-alt-medium-gray border-alt-medium-gray"
          (click)="clear()"
        >
          <i class="fa fa-refresh mr-1"></i>
          Clear
        </button>
        <button
          type="button"
          class="btn btn-alt-green flex-grow-1 flex-basis-0 mr-2"
          (click)="addTaskToForm(null, true)"
        >
          <i class="fa fa-plus mr-1"></i>
          Add Task
        </button>
        <button
          *ngIf="!isPublishable"
          type="submit"
          class="btn btn-alt-light-blue flex-grow-1 flex-basis-0"
          [disabled]="isSaving"
        >
          <i class="fa fa-save mr-1"></i>
          Save
          <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
        </button>
        <button
          *ngIf="isPublishable"
          type="submit"
          class="btn btn-alt-light-blue flex-grow-1 flex-basis-0"
          [disabled]="isSaving"
        >
          <span class="fa-stack fa-lg mr-1">
            <i class="fa fa-desktop fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x"></i>
          </span>
          Publish
          <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
        </button>
      </div>
    </form>
  </app-pane>
  <app-pane data-t="workflow-editor-preview-pane" class="flex-basis-0 flex-grow-1 flex-column m-2">
    <ng-container *ngIf="canPreviewWorkflow; else newWorkflow">
      <h1 class="pane-title">Preview: <span class="text-info">{{ form.get('name').value }}</span></h1>
      <app-dag
        [graphData]="graphData"
        [graphOptions]="graphOptions"
        [selectedNodeId]="selectedTaskIndex?.toString()"
        (onSelection)="selectedGraphNode($event)"
        class="flex-grow-1"
      >
      </app-dag>
    </ng-container>

    <ng-template #newWorkflow>
      <h1 class="pane-title">Preview Workflow</h1>
      <div class="bg-info rounded-lgr p-4">
        <app-well> Enter required details in the left panel to see the workflow preview. </app-well>
      </div>
    </ng-template>
  </app-pane>
</div>
