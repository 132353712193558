import { Component, OnInit } from '@angular/core'

/**
 * Page that displays support and maintenance policies
 *
 * @export
 * @class SupportAndMaintenancePage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-support-and-maintenance',
  templateUrl: './support-and-maintenance.page.html',
  styleUrls: ['./support-and-maintenance.page.scss'],
})
export class SupportAndMaintenancePage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
