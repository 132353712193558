import { Component } from '@angular/core'

/**
 * Page that displays the privacy policy
 *
 * @export
 * @class PrivacyPolicyPage
 */
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.page.html',
  styleUrls: ['./privacy-policy.page.scss'],
})
export class PrivacyPolicyPage {
  constructor() {}

  /**
   * Scrolls page to the passed in target
   *
   * @param {HTMLElement} element
   * @memberof PrivacyPolicyPage
   */
  scroll(element: HTMLElement): void {
    element.scrollIntoView()
  }
}
