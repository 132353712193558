<div class="d-flex flex-column h-100">
  <div class="d-flex">
    <form class="flex-grow-1 flex-md-grow-0 mr-3" (submit)="getWorkflowTypes(query.value, page = 1)">
      <div class="input-group form-group">
        <input
          [formControl]="query"
          type="text"
          class="form-control"
          placeholder="Search Workflows"
          data-t="workflow-search-bar"
        />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <button type="submit" class="btn bg-transparent" data-t="workflow-search-button">
              <i class="fa fa-search fa-fw"></i>
            </button>
          </span>
        </div>
      </div>
    </form>
    <a routerLink="/admin/workflows/new" class="btn btn-primary text-light flex-grow-0 mb-3">Add Workflow</a>
  </div>
  <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="workflows-table">
      <!-- header row -->
      <thead>
        <tr>
          <th scope="col" class="width-1">Published</th>
          <th scope="col" class="pl-4 text-left">Name</th>
          <th></th>
        </tr>
      </thead>

      <!-- body rows -->
      <tbody>
        <tr *ngFor="let workflowType of workflowTypes" data-t="workflowType-row">
          <td class="text-center">
            <i [ngClass]="workflowType.published ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"></i>
          </td>
          <td>
            <a routerLink="/admin/workflows/{{ workflowType.id }}"> {{ workflowType.name }} </a>
          </td>
          <td class="text-right">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              [ngbPopover]="copyWorkflowPopover"
              [autoClose]="'outside'"
              placement="left"
              (shown)="openedCopyWorkflow(workflowType)"
            >
              <i class="fa fw fa-clone mr-1"></i> Copy
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- popover -->
    <ng-template #copyWorkflowPopover>
      <form (submit)="copyWorkflow()">
        <label for="workflowCopyName" class="form-label">New Workflow Name:</label>
        <input type="text" [formControl]="workflowCopyName" id="workflowCopyName" class="form-control" size="50" />
        <div class="d-flex mt-2">
          <button type="submit" class="btn btn-primary flex-grow-1" [disabled]="isSavingWorkflowCopy">
            <i class="fa fa-save mr-1"></i>
            Save Copy
            <app-loading *ngIf="isSavingWorkflowCopy" class="pl-2"></app-loading>
          </button>
        </div>
      </form>
    </ng-template>

    <!-- loading indicator -->
    <app-loading
      *ngIf="isLoading"
      innerClass="fa-4x"
      outerClass="align-self-center text-brand-light-blue"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>

    <!-- no results -->
    <ng-container *ngIf="!workflowTypes?.length && !isLoading">
      <div *ngIf="lastSearch" class="text-center alert alert-danger" role="alert" data-t="workflow-no-results">
        Your search returned no results.
      </div>
      <div *ngIf="!lastSearch" class="text-center">
        No workflows have been created. <a routerLink="/admin/workflows/new">Add a workflow</a>.
      </div>
    </ng-container>

    <!-- pagination controls -->
    <div class="d-flex align-items-baseline justify-content-end mb-4" *ngIf="workflowTypes?.length && !isLoading">
      <small class="mr-3">
        {{page * pageSize - pageSize + 1 | number}} - {{workflowTypes?.length < pageSize ? meta?.total : page * pageSize
        | number}} of {{meta?.total | number}}
      </small>
      <ngb-pagination
        [collectionSize]="meta?.total"
        (pageChange)="getWorkflowTypes(query.value, $event)"
        [pageSize]="pageSize"
        [(page)]="page"
        [maxSize]="5"
        *ngIf="meta?.total >= pageSize"
        [boundaryLinks]="false"
        data-t="workflow-pagination"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
