/*
 * Usage: debug('feature', 'message to console log', Obj1, Array1, Obj2)
 * URL: http://dev.janus-ai.com/#/home?debug=feature,foo,bar
 *
 * The string 'all' will show all console logs
 * URL: http://dev.janus-ai.com/#/home?debug=all
 */

/**
 * Helper to parse debug query params from url
 * and save to / load from session storage
 *
 * @return {*}  {string[]}
 */
const extractDebug = (): string[] => {
  try {
    const hash = window?.location?.hash
    const questionMarkIndex = hash.indexOf('?')
    const search = new URLSearchParams(hash.substr(questionMarkIndex))
    let debug = search.get('debug')
    if (debug) {
      sessionStorage.setItem('debug', debug)
    } else {
      debug = sessionStorage.getItem('debug')
    }
    return debug.split(',')
  } catch (e) {
    return []
  }
}

const css = 'font-weight: bolder; color: #177da8'

/**
 * Helper to console log messages based on debug query params
 *
 * @param {string} key
 * @param {string} message
 * @param {...any} args
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debug = (key: string, message: string, ...args: any): void => {
  const keys = extractDebug()
  if (keys.includes('all') || keys.includes(key)) {
    // eslint-disable-next-line no-console
    console.log(`%c${key} > ${message}`, css, ...args)
  }
}
