import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskGroup, User } from 'generated/graphql'

/**
 * Modal to create a task group
 *
 * @export
 * @class CreateTaskGroupComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-group-create',
  templateUrl: './task-group-create.component.html',
  styleUrls: ['./task-group-create.component.scss'],
  host: {
    'data-t': 'create-task-group-modal',
  },
})
export class CreateTaskGroupComponent implements OnInit {
  taskGroupForm = new UntypedFormGroup({
    taskGroup: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(''),
    score: new UntypedFormControl(''),
  })

  saving: boolean = false
  selectedUsers: User[] = []

  /**
   * Helper to get selected user IDs
   *
   * @readonly
   * @type {string[]}
   * @memberof CreateTaskGroupComponent
   */
  get selectedUserIds(): string[] {
    return this.selectedUsers.map((u) => u.id)
  }

  constructor(
    public activeModal: NgbActiveModal,
    private taskGroupsService: TaskGroupsService,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {}

  /**
   * Create new task group
   *
   * @return {*}  {Promise<void>}
   * @memberof CreateTaskGroupComponent
   */
  async saveNewTaskGroup(): Promise<void> {
    this.taskGroupForm.markAllAsTouched()
    if (this.taskGroupForm.invalid) {
      this.toast.error(
        'Please fill in all required fields',
        JSON.stringify(readableFormGroupErrors(this.taskGroupForm)),
      )
      return
    }
    this.saving = true
    let taskGroup = {
      name: this.taskGroupForm.get('taskGroup').value,
      description: this.taskGroupForm.get('description').value,
      score: parseInt(this.taskGroupForm.get('score').value),
      assignedToUserIds: this.selectedUsers.map((u) => u.id),
    }
    debug('tasks', 'saving new task group', taskGroup)

    try {
      let createdTaskGroup: TaskGroup = await this.taskGroupsService.saveTaskGroup(taskGroup)
      this.toast.success('Task Group created')
      this.activeModal.close(createdTaskGroup)
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not create Task Group'), JSON.stringify(e))
    }
    this.saving = false
  }

  /**
   * Add user to be assigned to new task group
   *
   * @param {User} user
   * @memberof CreateTaskGroupComponent
   */
  addUser(user: User): void {
    if (user?.id) {
      this.selectedUsers.push(user)
    }
  }

  /**
   * Remove user from being assigned to new task group
   *
   * @param {User} user
   * @memberof CreateTaskGroupComponent
   */
  removeUser(user: User): void {
    let i = this.selectedUsers.findIndex((u) => u.id === user.id)
    if (i >= 0) {
      this.selectedUsers.splice(i, 1)
    }
  }
}
