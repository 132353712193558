import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
})
export class LoadingBarComponent implements OnInit {
  @Input() totalSteps: number = 1
  @Input() completedSteps: number = 0

  constructor() {}

  ngOnInit(): void {}
}
