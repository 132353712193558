import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import { escapeElasticQuery } from 'app/shared/utils/escapeElasticQuery'
import { merge, Subscription } from 'rxjs'

/**
 * Component to display a text facet for claim properties
 *
 * @export
 * @class TextFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet
  @ViewChild('input') input: ElementRef

  isCollapsed = false

  search = new UntypedFormControl('')
  exact = new UntypedFormControl(false)
  negate = new UntypedFormControl(false)

  modifierSub$: Subscription

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.modifierSub$ = merge(this.exact.valueChanges, this.negate.valueChanges).subscribe(() => {
      this.addTerm()
    })
  }

  ngOnDestroy(): void {
    this.modifierSub$?.unsubscribe()
  }

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.input.nativeElement.focus()
    }
  }

  /**
   * Add user input to facet
   *
   * @memberof TextFacetComponent
   */
  addTerm(): void {
    if (this.search.value?.length > 0) {
      let value = this.exact.value ? this.search.value.trim() : `*${escapeElasticQuery(this.search.value.trim())}*`
      let display = this.exact.value ? this.search.value.trim() : `*${this.search.value.trim()}*`
      if (this.negate.value) {
        value = `!(${value})`
        display = `NOT ${display}`
      }
      this.facetService.addToFacet(this.data.id, { value: value, display: display })
      this.search.setValue('')
    }
  }

  /**
   * Remove this facet
   *
   * @memberof TextFacetComponent
   */
  removeFacet = (): void => {
    if (this.data.id !== 'wildcard') {
      this.facetService.removeFacet(this.data)
    }
  }
}
