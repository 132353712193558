import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FileService } from 'app/file/file.service'
import { filter, map, switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-file',
  templateUrl: './file.page.html',
  styleUrls: ['./file.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class FilePage {
  result$ = this.route.params.pipe(switchMap((params) => this.fileService.fetchFile(params.fileId)))
  file$ = this.result$.pipe(map((result) => result.data?.file || null))
  fileType$ = this.file$.pipe(
    filter((file) => file !== null),
    map((file) => {
      if (file.contentType === 'application/pdf') {
        return 'pdf'
      } else if (file.contentType.indexOf('image/') === 0) {
        return 'image'
      } else {
        return 'other'
      }
    }),
  )
  fileUrl$ = this.file$.pipe(
    filter((file) => file !== null),
    map((file) => `/api/file/${file.id}`),
  )
  fileName$ = this.file$.pipe(
    filter((file) => file !== null),
    map((file) => file.name.slice(file.name.lastIndexOf('/') + 1)),
  )

  constructor(private fileService: FileService, private route: ActivatedRoute) {}
}
