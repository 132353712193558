import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client'
import { NgbCarousel, NgbSlideEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { AnnotationsStateService } from 'app/annotations/annotations-state.service'
import { AnnotationsService } from 'app/annotations/annotations.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { IlTask, IlWorkflowGrouping, ObserveRecordAnnotated, ObserveRecordAnnotatedInput } from 'generated/graphql'
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs'
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators'

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.page.html',
})
export class AnnotationsPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()

  isLoading = false

  /**
   * Array of annotated observe records for the active Workflow Grouping.
   */
  annotatedObserveRecords: ObserveRecordAnnotated[] = []

  /**
   * Form control for the active annotated observe record slider.
   */
  activeRecordFormControl = new UntypedFormControl(1)

  /**
   * Workflow form for the active annotated observe record.
   */
  workflowForm = new UntypedFormGroup({
    isStart: new UntypedFormControl(false),
    isEnd: new UntypedFormControl(false),
    taskId: new UntypedFormControl(),
  })

  /**
   * Emits true if the form for the active annotated observe record has been
   * modified.
   */
  workflowFormModified$ = new BehaviorSubject<boolean>(false)

  @ViewChild('workflowGroupingTypeahead', { static: true }) workflowGroupingTypeahead: NgbTypeahead
  workflowGroupingFormControl = new UntypedFormControl('')
  workflowGroupingFocus$ = new Subject<string>()
  workflowGroupingClick$ = new Subject<string>()

  /**
   * Search function used by the Workflow Grouping typeahead.
   *
   * @param text$ Observable that emits values from the typeahead
   */
  workflowGroupingSearch = (text$: Observable<string>): Observable<IlWorkflowGrouping[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter((term) => term?.length > 3),
    )
    const clicksWithClosedPopup$ = this.workflowGroupingClick$.pipe(
      filter(() => !this.workflowGroupingTypeahead?.isPopupOpen()),
    )

    return merge(debouncedText$, this.workflowGroupingFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) =>
        this.annotationsService.getWorkflowGroups(term).pipe(
          first(),
          map((list) => list?.data?.ilWorkflowGroups?.entities),
          catchError((e) => {
            this.toast.error(parseGraphQLError(e, 'Could not load workflow groups'), JSON.stringify(e))
            return of([])
          }),
        ),
      ),
    )
  }

  /**
   * Formatter used to display items in the Workflow Grouping typeahead.
   *
   * @param workflowGrouping Workflow Grouping
   */
  workflowGroupsFormatter = (workflowGrouping: IlWorkflowGrouping): string => workflowGrouping?.name

  @ViewChild('recordCarousel') recordCarousel: NgbCarousel

  @ViewChild('taskTypeahead', { static: true }) taskTypeahead: NgbTypeahead
  taskFocus$ = new Subject<string>()
  taskClick$ = new Subject<string>()

  /**
   * Search function used by the Task typeahead.
   *
   * @param text$ Observable that emits values from the typeahead
   */
  taskSearch = (text$: Observable<string>): Observable<IlTask[]> => {
    const debouncedText$ = text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      filter((term) => term?.length > 3),
    )
    const clicksWithClosedPopup$ = this.taskClick$.pipe(filter(() => !this.taskTypeahead?.isPopupOpen()))

    return merge(debouncedText$, this.taskFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) =>
        this.annotationsService.getTasks(term).pipe(
          first(),
          map((list) => list?.data?.ilTasks?.entities),
          catchError((e) => {
            this.toast.error(parseGraphQLError(e, 'Could not load tasks'), JSON.stringify(e))
            return of([])
          }),
        ),
      ),
    )
  }

  /**
   * Formatter used to display items in the Task typeahead.
   *
   * @param task Task
   */
  taskFormatter = (task: IlTask): string => task?.name

  constructor(
    public annotationsService: AnnotationsService,
    public annotationsStateService: AnnotationsStateService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    // When the active record slider value changes, select the appropriate
    // carousel slide.
    this.activeRecordFormControl.valueChanges
      .pipe(debounceTime(150), takeUntil(this.destroy$))
      .subscribe((oneIndex: number | string) => {
        const index = Number(oneIndex) - 1
        this.recordCarousel.select(this.annotatedObserveRecords[index]?.id)
      })

    // When the active annotated observe record is changed, update the form
    // controls.
    this.annotationsStateService.currentObserveRecordAnnotated$
      .pipe(
        filter((observeRecordAnnotated) => !!observeRecordAnnotated),
        takeUntil(this.destroy$),
      )
      .subscribe((observeRecordAnnotated) => {
        this.workflowFormModified$.next(false)

        this.workflowForm.patchValue(
          {
            isStart: observeRecordAnnotated.isStart,
            isEnd: observeRecordAnnotated.isEnd,
            taskId: observeRecordAnnotated.taskId,
          },
          { emitEvent: false },
        )
      })

    // Subscribe to events emitted when the currently selected workflow group
    // is changed, and load annotated observe records for that group.
    this.annotationsStateService.currentWorkflowGroup$
      .pipe(
        filter((workflowGrouping) => !!workflowGrouping),
        tap(() => (this.isLoading = true)),
        switchMap((workflowGrouping) => this.annotationsService.pickRecords(workflowGrouping.id)),
        catchError(
          (err): Observable<ApolloQueryResult<{ pickObserveRecordGroup: ObserveRecordAnnotated[] }>> => {
            return of({ data: null, error: err, loading: false, networkStatus: null })
          },
        ),
        tap(() => (this.isLoading = false)),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        const { data, error } = result

        if (error) {
          this.toast.error(parseGraphQLError(error, 'Could not pick workflow grouping'), JSON.stringify(error))
        } else {
          this.annotatedObserveRecords = data?.pickObserveRecordGroup
          if (this.annotatedObserveRecords.length) {
            this.annotationsStateService.currentObserveRecordAnnotated$.next(this.annotatedObserveRecords[0])
          }
        }
      })

    this.route.queryParams
      .pipe(
        tap((queryParams) => {
          const { workflowGroupingId } = queryParams

          if (!workflowGroupingId) {
            this.annotatedObserveRecords = []
            this.annotationsStateService.currentWorkflowGroup$.next(null)
            this.workflowGroupingFormControl.setValue(null)
          }
        }),
        filter((queryParams) => queryParams.workflowGroupingId),
        switchMap((queryParams) => this.annotationsService.getWorkflowGroup(queryParams.workflowGroupingId)),
        catchError(
          (err): Observable<ApolloQueryResult<{ ilWorkflowGrouping: IlWorkflowGrouping }>> => {
            return of({ data: null, error: err, loading: false, networkStatus: null })
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (result.error) {
          this.toast.error(
            parseGraphQLError(result.error, 'Could not fetch workflow grouping'),
            JSON.stringify(result.error),
          )
        } else {
          this.workflowGroupingFormControl.setValue(result.data.ilWorkflowGrouping)
          this.annotationsStateService.currentWorkflowGroup$.next(result.data.ilWorkflowGrouping)
        }
      })

    this.workflowForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.workflowFormModified$.next(true)
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * When the observe record carousel slides to a new item, set the active
   * annotated observe record.
   *
   * @param $event NgbCarousel slide event
   */
  changeRecord($event: NgbSlideEvent): void {
    const record = this.annotatedObserveRecords.find((record) => record.id === $event.current)
    if (record) {
      this.annotationsStateService.currentObserveRecordAnnotated$.next(record)
    }
  }

  /**
   * When a Workflow Grouping is selected, set the Workflow Grouping ID in the
   * queryParams.
   *
   * Passing nothing into this method will essentially clear the selected
   * workflow grouping.
   *
   * @param event Event with Workflow Grouping item
   */
  selectWorkflowGrouping(event?: { item: IlWorkflowGrouping }): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        workflowGroupingId: event?.item?.id || undefined,
      },
      queryParamsHandling: 'merge',
    })
  }

  /**
   * Update the active annotated observe record.
   */
  async updateObserveRecordAnnotated(): Promise<void> {
    const originalRecord = this.annotationsStateService.currentObserveRecordAnnotated$.getValue()
    if (!originalRecord) {
      this.toast.error('An annotated record is not active!')
      return
    }

    const updateRecord: ObserveRecordAnnotatedInput = {
      id: originalRecord?.id,
      ...(this.workflowForm.value as Partial<ObserveRecordAnnotated>),
    }

    try {
      await this.annotationsService.updateObserveRecordAnnotated(originalRecord, updateRecord)
      this.workflowFormModified$.next(false)
    } catch (error) {
      this.toast.error(error?.message || 'Could not update annotated record', JSON.stringify(error))
    }
  }
}
