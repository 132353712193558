<div class="d-flex flex-column flex-basis-0 flex-grow-1">
  <div class="row mx-0 my-3 flex-grow-1">
    <div class="col d-flex flex-column flex-basis-0 flex-grow-1">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-grow-1 bg-alt-light-gray-high py-2 px-4 text-uppercase">
          {{opportunity.processName}}
        </div>
        <div class="flex-shrink-1" ngbDropdown>
          <!-- <button class="btn btn-primary mx-2" ngbDropdownToggle>Workflow Phase</button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem>Baseline</button>
            <button ngbDropdownItem>Phase I</button>
            <button ngbDropdownItem>Phase II</button>
            <button ngbDropdownItem>Phase III</button>
          </div> -->
          <button
            class="btn mx-2"
            [ngClass]="animationInterval ? 'btn-danger' : 'btn-success'"
            (click)="toggleAnimation()"
          >
            <i class="fa fa-fw" [ngClass]="animationInterval ? 'fa-stop' : 'fa-play'"></i>
          </button>
        </div>
      </div>
      <div class="row h-100 mx-0 pt-2 border border-top-0 border-alt-light-gray">
        <div class="col-md-9 d-flex flex-column flex-basis-0 flex-grow-1 flex-shrink-1" #leftGraphEl>
          <app-dag
            (graphChange)="leftGraph=$event"
            [setMode]="graphMode"
            [(graphData)]="leftGraphData"
            [graphOptions]="leftGraphOptions"
            class="flex-grow-1"
          >
          </app-dag>
        </div>
        <div class="col-md-3 pr-0">
          <div class="card">
            <div class="card-header bg-brand-gray">Workflow Statistics</div>
            <div class="card-body bg-alt-white">
              <table class="table rounded">
                <tr>
                  <td class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase"></td>
                  <td class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase">baseline</td>
                  <td class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase">{{path}}</td>
                </tr>
                <tr>
                  <th class="border-top-0">Count <small>(monthly)</small></th>
                  <td class="text-primary text-right font-weight-bold border-top-0">{{opportunity.processVolume}}</td>
                  <td class="text-primary text-right font-weight-bold border-top-0">
                    {{data?.count ? data?.count : 'N/A'}}
                  </td>
                </tr>
                <tr>
                  <th>Volume</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.totalOutstanding / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">
                    {{ data?.volume ? (data?.volume | currency) : 'N/A'}}
                  </td>
                </tr>
                <tr>
                  <th>Avg Outstanding</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.averageOutstanding / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.outstanding | currency }}</td>
                </tr>
                <tr>
                  <th>Avg Payment</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.averagePayment / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.payment | currency }}</td>
                </tr>
                <tr>
                  <th>Payment Frequency</th>
                  <td class="text-primary text-right font-weight-bold">{{ opportunity.paymentFrequency }}%</td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.payment_frequency }}%</td>
                </tr>
                <tr>
                  <th>Avg Outcome</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.averageOutcome / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.outcome | currency }}</td>
                </tr>
                <tr>
                  <th>Avg Completion Time</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.averageCompletionTime * 1000 | date: 'm:ss'}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.time * 1000 | date: 'm:ss' }}</td>
                </tr>
                <tr>
                  <th>Mean Collection Cost</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.meanCollectionCost / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.mean_cost | currency }}</td>
                </tr>
                <tr>
                  <th>Net Collection Profitability</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{ opportunity.netCollectionProfit / 100 | currency}}
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{ data?.net_cost | currency }}</td>
                </tr>
                <tr>
                  <th>Avg Payment Lag</th>
                  <td class="text-primary text-right font-weight-bold">
                    {{opportunity.averagePaymentLag.currentPhase}} days
                  </td>
                  <td class="text-primary text-right font-weight-bold">{{data?.lag}} days</td>
                </tr>
                <tr>
                  <th>Workflow Variance</th>
                  <td class="text-primary">
                    <div class="d-flex justify-content-end">
                      <app-level-badge level="medium" text="{{opportunity.workflowVariance}}"></app-level-badge>
                    </div>
                  </td>
                  <td class="text-primary">
                    <div class="d-flex justify-content-end">
                      <app-level-badge level="medium" text="/"></app-level-badge>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mx-0">
    <div class="col flex-shrink-1">
      <app-collapsing-card
        [isCollapsed]="isCollapsed"
        (onToggled)="toggleDetails($event)"
        class="bg-brand-gray"
        id="workflow-details"
      >
        <div slot="title" class="bg-brand-gray">WORKFLOW DETAILS</div>
        <div slot="content" class="baby-blue-light">
          <div>
            <div class="row">
              <div class="col text-center pl-24-per">Total Monthly Workflow Yield</div>
              <div class="col text-center">Average Cost to Collect</div>
              <div class="col text-center">Total Monthly Cost to Collect</div>
              <div class="col text-center">Average Payment Days</div>
              <div class="col text-center">Average Workflow Net Profitability of Collection</div>
              <div class="col text-center">Total Monthly Net Profitability of Collection</div>
            </div>
            <app-workflow-phase-details [opportunity]="opportunity" [phase]="0"></app-workflow-phase-details>
            <app-workflow-phase-details [opportunity]="opportunity" [phase]="1"></app-workflow-phase-details>
            <app-workflow-phase-details [opportunity]="opportunity" [phase]="2"></app-workflow-phase-details>
            <app-workflow-phase-details [opportunity]="opportunity" [phase]="3"></app-workflow-phase-details>
          </div>
        </div>
      </app-collapsing-card>
    </div>
  </div>
</div>
