<div class="pl-5">
  <h5>Select Organization</h5>
</div>
<form (ngSubmit)="changeOrg()" novalidate>
  <div class="p-5">
    <div class="form-group">
      <small class="form-text text-muted">
        Currently in <strong class="text-brand-dark-blue">{{ currentOrg?.name }}</strong>
      </small>
      <label for="org" class="sr-only">Select Organization</label>
      <app-organization-typeahead
        (onSelect)="select($event)"
        [clearInput]="false"
        [placeholder]="currentOrg ? currentOrg.name : 'Loading'"
        [autoFocus]="false"
        id="org"
      ></app-organization-typeahead>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <ng-container *ngIf="isLoading; else submitButton">
      <button type="submit" class="btn btn-brand-green mr-5 col-3 disabled" data-t="change-org-button">
        <i class="fa fa-spin fa-spinner fa-1x"></i>
      </button>
    </ng-container>
    <ng-template #submitButton>
      <button type="submit" class="btn btn-brand-green col-3 mr-5" data-t="change-org-button">Confirm</button>
    </ng-template>
  </div>
</form>
