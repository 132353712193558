import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { NgbdSortableHeader, SortEvent } from 'app/shared/directives/sortable.directive'
import { ObserveUsageSummary, QueryObserveUsageSummaryArgs } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { ObserveUsageService } from 'app/admin/observe-access-controls/observe-usage.service'

@Component({
  selector: 'app-observe-usage-summary',
  templateUrl: './observe-usage-summary.component.html',
  styleUrls: ['./observe-usage-summary.component.scss'],
})
export class ObserveUsageSummaryComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>
  acl_Types = aclTypes
  currentType = aclTypes.ALL
  isLoading = false
  observeUsageSummaries: ObserveUsageSummary[] = []
  displayedSummaries: ObserveUsageSummary[] = []

  @Input()
  set timeFilter(value: number) {
    if (!value) {
      this._timeFilter = ''
    } else {
      let date = new Date()
      let newDate = date.getDate() - value
      date.setDate(newDate)
      this._timeFilter = date.toString()
    }

    this.getObserveSummaries()
  }
  private _timeFilter: string = ''

  @Output()
  createRuleForUsageSummary = new EventEmitter<ObserveUsageSummary>()

  constructor(private observeUsageService: ObserveUsageService) {}

  async ngOnInit(): Promise<void> {
    await this.getObserveSummaries()
  }

  typeChangeEvent(event: NgbNavChangeEvent): void {
    this.currentType = event.nextId
    this.filterSummariesByType()
  }

  async getObserveSummaries(): Promise<void> {
    this.isLoading = true
    const params: QueryObserveUsageSummaryArgs = {
      startDate: this._timeFilter,
    }

    const result = await this.observeUsageService
      .getObserveSummaryList(params)
      .pipe(first())
      .toPromise()
      .finally(() => {
        this.isLoading = false
      })

    this.observeUsageSummaries = [...result.data.observeUsageSummary.entities]
    this.filterSummariesByType()
  }

  filterSummariesByType(): void {
    if (this.currentType != aclTypes.ALL) {
      this.displayedSummaries = this.observeUsageSummaries.filter((summary) => {
        return summary.type == this.currentType
      })
    } else {
      this.displayedSummaries = this.observeUsageSummaries
    }
  }

  onSort({ column, direction }: SortEvent): void {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = ''
      }
    })
    this.displayedSummaries = this.observeUsageService.sortSummaries(this.displayedSummaries, column, direction)
  }

  createRule(summary: ObserveUsageSummary): void {
    this.createRuleForUsageSummary.emit(summary)
  }
}

export enum aclTypes {
  ALL = 'ALL',
  APP = 'APP',
  URL = 'URL',
}
