<div class="container d-flex flex-column min-vh-100">
  <div class="row justify-content-around my-5">
    <div class="col-lg-4 col-sm-8">
      <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" class="img-fluid" />
    </div>
  </div>
  <div class="row justify-content-around">
    <div
      *ngIf="!isSubmitted; else feedbackSubmitted"
      [ngClass]="{'bg-brand-gray rounded p-5 col-lg-7 col-9': !isSubmitted}"
    >
      <div class="h5 align-middle">
        <span class="px-1 align-middle" aria-hidden="true">
          <img src="/assets/img/teleport-app-icon.svg" height="25px" />
        </span>
        <span class="align-middle text-brand-dark-gray"> TELL US ABOUT YOUR TELEPORT EXPERIENCE</span>
      </div>

      <div class="my-5">
        <div>
          <label class="required text-alt-dark-gray">Did you enjoy using Teleport?</label>
        </div>
        <div><ngb-rating [(rate)]="rating" [max]="5" class="text-alt-green h2"></ngb-rating></div>
        <div
          *ngIf="shouldShowRequiredFieldWarning"
          class="alert alert-danger d-flex justify-content-between w-50"
          role="alert"
        >
          <div>This field is required</div>
          <div><i class="fa fa-exclamation"></i></div>
        </div>
      </div>

      <div class="my-5 row no-gutters">
        <div class="col-6">
          <label class="text-alt-dark-gray" for="ratingDescription">Please explain your rating</label>
          <textarea
            id="ratingDescription"
            rows="5"
            type="textarea"
            class="form-control rounded"
            [formControl]="ratingDescription"
          ></textarea>
        </div>
      </div>

      <app-payer-picker (newPayer)="addNewPayer($event)" (pickedPayer)="pickedPayer($event)"></app-payer-picker>
      <div class="row no-gutters">
        <div class="col-6 px-1">
          <ul class="list-unstyled my-4 height-restrict">
            <li *ngFor="let payer of additionalPayers; trackBy: trackById">
              <i class="fa fa-times-circle-o text-danger mr-4 clickable" (click)="removePayer(payer)"></i>{{ payer?.name
              }}
            </li>
          </ul>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-5">
        <button class="btn btn-cancel bg-alt-white m-2 rounded px-5 py-2" (click)="cancel()">Cancel</button>
        <button class="btn btn-alt-light-blue m-2 rounded px-5 py-2" (click)="submit()">
          <i class="fa fa-check"></i> Submit
        </button>
      </div>
    </div>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
<ng-template #feedbackSubmitted>
  <app-feedback-submitted></app-feedback-submitted>
</ng-template>
