import { CreateTaskInput, TaskType } from 'generated/graphql'
import { chunk } from 'lodash'

/**
 * Helper to chunk API calls for creating many tasks on claims
 *
 * @param {number} size
 * @param {TaskType[]} tasks
 * @param {string[]} claims
 * @return {*}  {CreateTaskInput[][]}
 */
export const chunkTasksAndClaims = (size: number, tasks: TaskType[], claims: string[]): CreateTaskInput[][] => {
  let array = []

  // create one large array
  claims.forEach((claim) => {
    tasks.forEach((task) => {
      array.push({
        providerClaimId: claim,
        taskTypeId: task.id,
      })
    })
  })

  // chunk into 'size'
  return chunk(array, size)
}
