export function parseGraphQLError(error: unknown, defaultMessage?: string): string {
  if (Object.hasOwnProperty.call(error as any, 'graphQLErrors')) {
    return (error as any)?.message
  }
  if ((error as any)?.error?.message?.length) {
    return (error as any)?.error?.message
  }
  if (defaultMessage?.length) {
    return defaultMessage
  }
  return 'An unknown error has occurred'
}
