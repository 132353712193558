import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { TeleportService } from '../../teleport/teleport.service'
import { AuthenticationService } from '../../auth/authentication.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { firstValueFrom } from 'rxjs'

export const TeleportGuard: CanActivateFn = async (
  _next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => {
  // don't check for extension when navigating to warning page
  if (state.url.includes('/teleport/warning')) {
    return true
  }
  const teleportService = inject(TeleportService)
  const authenticationService = inject(AuthenticationService)
  const router = inject(Router)
  const http = inject(HttpClient)

  const success = await teleportService.checkForExtension()

  if (!success) {
    console.log('unsuccessful in connecting to teleport extension')
    try {
      await authenticationService.setUser()
    } catch (e) {
      return false
    }
    const user = authenticationService.getUser()
    await createFailedTeleportHistoryRecord(http, {
      userId: user.id,
      orgId: user.orgId,
      browserType: teleportService.getBrowserType(),
      networkDetails: teleportService.getNetworkDetails(),
    })
    router.navigate(['teleport', 'warning'], {
      queryParams: { errorType: 'TeleportExtensionUnresponsive' },
    })
    return false
  } else {
    console.log("teleport guard's extension check succeeded")
  }

  return true
}

const createFailedTeleportHistoryRecord = async (http: HttpClient, { userId, orgId, browserType, networkDetails }) => {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
  })
  const baseFields = {
    portal: 'none',
    payer: 'none',
    status: 'FAILED',
    errorType: 'EXTENSION_UNAVAILABLE',
    userId,
    browserType,
    networkDetails,
  }
  const body = {
    ...baseFields,
    succeeded: false,
    metrics: {
      ...baseFields,
      orgId,
      env: 'browser',
      errorMsg: 'Teleport guard blocked Teleport, could not establish connection with Teleport extension',
      lastPhase: 'LAUNCH',
    },
  }
  try {
    // use firstValueFrom to convert the Observable to a Promise
    await firstValueFrom(http.post('/api/teleport/history', body, { headers }))
    console.log('successfully created teleport history entry')
  } catch (error) {
    console.log(`failed to create teleport history entry: ${error.status} - ${error.statusText}`)
  }
}
