import { Injectable } from '@angular/core'
import { EdgeConfig } from '@antv/g6'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateIlSelectedPath,
  DeleteIlSelectedPath,
  GetIlPath,
  GetIlSelectedPaths,
  GetIlTaskImpact,
  GetIlTaskImpacts,
  GetIlTaskStats,
  GetIlWorkflow,
  GetIlWorkflowStats,
  GetWorkflowGroup,
} from 'app/illuminate-v2/illuminate.gql'
import { debug } from 'app/shared/utils/debug'
import {
  IlPathRankedResponse,
  IlPathType,
  IlSelectedPath,
  IlSelectedPathInput,
  IlTaskImpact,
  IlTaskStats,
  IlUserTask,
  IlWorkflow,
  IlWorkflowGrouping,
  IlWorkflowStats,
  MutationCreateIlSelectedPathArgs,
  MutationDeleteIlSelectedPathArgs,
  QueryIlPathArgs,
  QueryIlSelectedPathsArgs,
  QueryIlTaskImpactArgs,
  QueryIlTaskImpactsArgs,
  QueryIlTaskStatsArgs,
  QueryIlWorkflowArgs,
  QueryIlWorkflowGroupingArgs,
  QueryIlWorkflowStatsArgs,
  User,
} from 'generated/graphql'
import { flatten, uniq } from 'lodash'
import { BehaviorSubject, Observable } from 'rxjs'

export interface IlluminatePathFilter {
  id: string
  name: string
}

export interface IlluminateUserFilter {
  possible: User[]
  selected: User[]
}

export interface IlluminateDateFilter {
  possible: { startAt: string; endAt: string }
  selected: { startAt: string; endAt: string }
}

@Injectable({
  providedIn: 'root',
})
export class IlluminateService {
  constructor(private apollo: Apollo) {}

  /**
   * Get a single workflow grouping
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ ilWorkflowGrouping: IlWorkflowGrouping }>>}
   * @memberof IlluminateService
   */
  getWorkflowGroup(id: string): Observable<ApolloQueryResult<{ ilWorkflowGrouping: IlWorkflowGrouping }>> {
    return this.apollo.watchQuery<{ ilWorkflowGrouping: IlWorkflowGrouping }, QueryIlWorkflowGroupingArgs>({
      query: GetWorkflowGroup,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Get a single workflows stats
   *
   * @param {string} ilWorkflowId
   * @param {string[]} userIds
   * @param {string} startDate
   * @param {string} endDate
   * @return {*}  {Observable<ApolloQueryResult<{ ilWorkflowStats: IlWorkflowStats }>>}
   * @memberof IlluminateService
   */
  getWorkflowStats(
    ilWorkflowGroupingId: string,
    pathId: string,
    userIds: string[],
    startDate: string,
    endDate: string,
  ): Observable<ApolloQueryResult<{ ilWorkflowStats: IlWorkflowStats }>> {
    return this.apollo.watchQuery<{ ilWorkflowStats: IlWorkflowStats }, QueryIlWorkflowStatsArgs>({
      query: GetIlWorkflowStats,
      variables: {
        ilWorkflowGroupingId: ilWorkflowGroupingId,
        pathId: pathId,
        userIds: userIds,
        startDate: startDate,
        endDate: endDate,
      },
    }).valueChanges
  }

  /**
   * Get a single task's stats
   *
   * @param {string} ilTaskId
   * @param {string[]} userIds
   * @param {string} startDate
   * @param {string} endDate
   * @return {*}  {Observable<ApolloQueryResult<{ ilTaskStats: IlTaskStats }>>}
   * @memberof IlluminateService
   */
  getTaskStats(
    ilTaskId: string,
    userIds: string[],
    startDate: string,
    endDate: string,
  ): Observable<ApolloQueryResult<{ ilTaskStats: IlTaskStats }>> {
    return this.apollo.watchQuery<{ ilTaskStats: IlTaskStats }, QueryIlTaskStatsArgs>({
      query: GetIlTaskStats,
      variables: {
        ilTaskId: ilTaskId,
        userIds: userIds,
        startDate: startDate,
        endDate: endDate,
      },
    }).valueChanges
  }

  /**
   * Get a single workflow
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ ilWorkflow: IlWorkflow }>>}
   * @memberof IlluminateService
   */
  getWorkflow(id: string): Observable<ApolloQueryResult<{ ilWorkflow: IlWorkflow }>> {
    return this.apollo.watchQuery<{ ilWorkflow: IlWorkflow }, QueryIlWorkflowArgs>({
      query: GetIlWorkflow,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Get a single task impact record
   *
   * @param {string} previousTaskId
   * @param {string} taskId
   * @param {string} workflowGroupingId
   * @return {*}  {Observable<ApolloQueryResult<{ ilTaskImpact: IlTaskImpact }>>}
   * @memberof IlluminateService
   */
  getTaskImpact(
    previousTaskId: string,
    taskId: string,
    workflowGroupingId: string,
  ): Observable<ApolloQueryResult<{ ilTaskImpact: IlTaskImpact }>> {
    return this.apollo.watchQuery<{ ilTaskImpact: IlTaskImpact }, QueryIlTaskImpactArgs>({
      query: GetIlTaskImpact,
      variables: {
        previousTaskId,
        taskId,
        workflowGroupingId,
      },
    }).valueChanges
  }

  /**
   * Get all task impacts for a workflow grouping
   *
   * @param {string} workflowGroupingId
   * @return {*}  {Observable<ApolloQueryResult<{ ilTaskImpacts: IlTaskImpact[] }>>}
   * @memberof IlluminateService
   */
  getTaskImpacts(workflowGroupingId: string): Observable<ApolloQueryResult<{ ilTaskImpacts: IlTaskImpact[] }>> {
    return this.apollo.watchQuery<{ ilTaskImpacts: IlTaskImpact[] }, QueryIlTaskImpactsArgs>({
      query: GetIlTaskImpacts,
      variables: {
        workflowGroupingId,
      },
    }).valueChanges
  }

  /**
   * Get a ranking of specified paths by specified ranking type
   *
   * @param {IlPathType} pathType
   * @param {string[]} pathIds
   * @return {*}  {Observable<ApolloQueryResult<{ ilPath: IlPathRankedResponse }>>}
   * @memberof IlluminateService
   */
  getRankedPaths(
    pathType: IlPathType,
    pathIds: string[],
  ): Observable<ApolloQueryResult<{ ilPath: IlPathRankedResponse[] }>> {
    return this.apollo.watchQuery<{ ilPath: IlPathRankedResponse[] }, QueryIlPathArgs>({
      query: GetIlPath,
      variables: {
        pathType,
        pathIds,
      },
    }).valueChanges
  }

  /**
   * Fetch all user created / selected paths for a workflow grouping
   *
   * @param {string} workflowGroupingId
   * @return {*}  {Observable<ApolloQueryResult<{ ilSelectedPaths: IlSelectedPath[] }>>}
   * @memberof IlluminateService
   */
  getSelectedPaths(workflowGroupingId: string): Observable<ApolloQueryResult<{ ilSelectedPaths: IlSelectedPath[] }>> {
    return this.apollo.watchQuery<{ ilSelectedPaths: IlSelectedPath[] }, QueryIlSelectedPathsArgs>({
      query: GetIlSelectedPaths,
      variables: {
        workflowGroupingId,
      },
    }).valueChanges
  }

  /**
   * Create a new Il Selected path for a workflow grouping
   *
   * @param {IlSelectedPathInput} data
   * @return {*}  {Promise<IlSelectedPath>}
   * @memberof IlluminateService
   */
  async createSelectedPath(data: IlSelectedPathInput): Promise<IlSelectedPath> {
    let result = await this.apollo
      .mutate<{ createIlSelectedPath: IlSelectedPath }, MutationCreateIlSelectedPathArgs>({
        mutation: CreateIlSelectedPath,
        variables: {
          data,
        },
        update: (store, { data: { createIlSelectedPath } }) => {
          const query: { ilSelectedPaths: IlSelectedPath[] } = store.readQuery({
            query: GetIlSelectedPaths,
            variables: {
              workflowGroupingId: createIlSelectedPath?.workflowGroupingId,
            },
          })
          store.writeQuery({
            query: GetIlSelectedPaths,
            variables: {
              workflowGroupingId: createIlSelectedPath?.workflowGroupingId,
            },
            data: { ilSelectedPaths: [createIlSelectedPath, ...query?.ilSelectedPaths] },
          })
        },
      })
      .toPromise()

    return result?.data?.createIlSelectedPath
  }

  /**
   * Delete an existing Il Selected Path in a workflow grouping
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof IlluminateService
   */
  async deleteSelectedPath(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ deleteIlSelectedPath: boolean }, MutationDeleteIlSelectedPathArgs>({
        mutation: DeleteIlSelectedPath,
        variables: {
          id,
        },
        update: () => {
          let cache = this.apollo.client.cache
          cache.evict({ id: cache.identify({ id: id, __typename: 'IlSelectedPath' }) })
          cache.gc()
        },
      })
      .toPromise()

    return result?.data?.deleteIlSelectedPath
  }

  /* --------------- State Management --------------- */
  currentWorkflowGroup = new BehaviorSubject<IlWorkflowGrouping>(null)
  currentTaskImpacts = new BehaviorSubject<IlTaskImpact[]>([])

  allPaths: {
    pathId: string
    taskIds: string[]
  }[] = null

  filters = new BehaviorSubject<{
    path: IlluminatePathFilter
    users: IlluminateUserFilter
    dates: IlluminateDateFilter
  }>(null)

  /* --------------- Helpers --------------- */

  /**
   * Fetch task impact for unique combination of
   * source / target nodes by their edge
   *
   * @param {EdgeConfig} edge
   * @return {*}  {Promise<number>}
   * @memberof IlluminateService
   */
  taskImpact(edge: EdgeConfig): number {
    let taskId = edge.target
    let previousTaskId = edge.source
    try {
      if (!taskId || !previousTaskId) {
        // usually this is missing taskId aka it's a terminal node
        throw new Error('bad edge')
      }

      let impact = this.currentTaskImpacts.value.find(
        (impact) => impact.previousTaskId === previousTaskId && impact.taskId === taskId,
      )

      return impact?.impact || 0
    } catch (e) {
      debug('illuminate', 'failed to load task impact', edge, e)
      return -1
    }
  }

  /**
   * Create matrix of unique tasks
   *
   * @param {string} [pathId]
   * @return {*}  {IlUserTask[]}
   * @memberof IlluminateService
   */
  extractUserTasks(pathId?: string): IlUserTask[] {
    let tasks: IlUserTask[][] = []

    this.currentWorkflowGroup?.value?.ilWorkflows.forEach((workflow) => {
      if (!pathId || workflow.pathId === pathId) {
        tasks.push(workflow.ilUserTasks)
      }
    })

    return uniq(flatten(tasks))
  }

  isTaskSequenceInPath(tasks: string[]): boolean {
    const isNextInAPath = this.allPaths.find((path) => {
      let tmpTasks = tasks.join(',')
      let tmpPathTasks = path.taskIds.join(',')
      return tmpPathTasks.indexOf(tmpTasks) === 0
    })

    return isNextInAPath ? true : false
  }
}
