<div class="row">
  <div class="col-lg-3 full-height no-crumbs overflow-scroll">
    <app-bot-runs-search
      [selectedBotRunId]="selectedBotRunId"
      [botJobId]="botJobId"
      [params]="params"
    ></app-bot-runs-search>
  </div>

  <div class="col-lg-9 full-height no-crumbs overflow-scroll">
    <router-outlet></router-outlet>
  </div>
</div>
