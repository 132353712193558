import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloQueryResult, SingleExecutionResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateOrgSamlInput,
  QuerySamlArgs,
  UpdateOrgSamlInput,
  OrgSaml,
  QuerySamlByOrgIdArgs,
  DeleteSamlDocument,
  BulkUpdateOrgSamlInput,
  CreateOrUpdateSamlBulkDocument,
} from 'generated/graphql'
import { CreateSaml, GetSaml, UpdateSaml, GetAllSamlByOrgId } from './saml.gql'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class SamlService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  /**
   * Create a Saml record.
   * @param {string} orgId
   * @param {string} data
   * @returns {OrgSaml}
   */
  create(orgId: string, data: CreateOrgSamlInput) {
    return this.apollo.mutate<{ saml: OrgSaml }>({
      mutation: CreateSaml,
      variables: { orgId, data },
    })
  }

  delete(orgSamlId: string) {
    return this.apollo.mutate<{ deleteSaml: boolean }>({ mutation: DeleteSamlDocument, variables: { orgSamlId } })
  }

  /**
   * Fetch a Saml record.
   * @param {string} orgSamlId
   * @returns {OrgSaml}
   */
  get(orgSamlId: string): Observable<ApolloQueryResult<{ saml: OrgSaml }>> {
    return this.apollo.watchQuery<{ saml: OrgSaml }, QuerySamlArgs>({
      query: GetSaml,
      variables: { orgSamlId },
    }).valueChanges
  }

  /**
   * Fetch all saml records for an org.
   * @param {string} orgId
   * @returns {OrgSaml[]}
   */
  getAllByOrgId(orgId: string): Observable<OrgSaml[]> {
    return this.apollo
      .watchQuery<{ samlByOrgId: { entities: OrgSaml[] } }, QuerySamlByOrgIdArgs>({
        query: GetAllSamlByOrgId,
        variables: { orgId },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(map((result) => result.data.samlByOrgId.entities))
  }

  /**
   * Update an Saml record.
   * @param {string} orgSamlId
   * @param {UpdateSamlInput} data
   * @returns {Saml}
   */
  update(orgSamlId: string, data: UpdateOrgSamlInput) {
    return this.apollo.mutate<{ saml: OrgSaml }>({
      mutation: UpdateSaml,
      variables: { orgSamlId, data },
    })
  }

  createOrUpdateBulk(
    data: BulkUpdateOrgSamlInput[],
  ): Observable<SingleExecutionResult<{ createOrUpdateSamlBulk: { entities: OrgSaml[] } }>> {
    return this.apollo.mutate<{ createOrUpdateSamlBulk: { entities: OrgSaml[] } }>({
      mutation: CreateOrUpdateSamlBulkDocument,
      variables: { data },
    })
  }

  /**
   * Fetch a saml metadata string.
   * @param {string} orgSamlId
   * @returns {string}
   */
  async fetchSamlMetadata(orgSamlId: string): Promise<string> {
    return await this.http.get(`/api/saml/${orgSamlId}/metadata`, { responseType: 'text' }).toPromise()
  }
}
