import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { ToastService } from 'app/shared/services/toast.service'
import { Selected } from 'app/shared/types/facet'
import { facetsToClaimQueryParams } from 'app/shared/utils/facets-to-params'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { searchToFacet } from 'app/shared/utils/search-to-facet'
import { SavedSearch, SaveSearchInput } from 'generated/graphql'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

/**
 * Page to allow editing a saved search
 *
 * @export
 * @class ClaimSearchDetailPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-claim-search-edit-page',
  templateUrl: './edit.page.html',
  styleUrls: ['./edit.page.scss'],
})
export class ClaimSearchDetailPage implements OnInit, OnDestroy {
  search: SavedSearch = null

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    saveToTaskGroup: new UntypedFormControl(false),
  })

  loading: boolean = false
  saving: boolean = false

  routerSub$: Subscription
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private facetService: FacetedSearchService,
    private toast: ToastService,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger], index) => event instanceof NavigationEnd || trigger))
      .subscribe(() => {
        this.onRouteChange()
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$?.unsubscribe()
  }

  /**
   * Load a specific saved search
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimSearchDetailPage
   */
  async onRouteChange(): Promise<void> {
    let id = this.route.snapshot.paramMap.get('searchId') // id or 'new'

    if (id && id !== 'new') {
      this.loading = true
      try {
        let search = await this.facetService.getSavedSearch(id)
        this.search = search

        // let lastCrumb = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 1]
        // lastCrumb.label = search.name

        this.crumbs.setPageTitle('Admin - Claim Searches - ' + search?.name)

        this.facetService.removeAllFacets()
        this.facetService.setFacets(search.facets.map(searchToFacet))

        this.form.patchValue({
          name: search.name,
          description: search.description,
          saveToTaskGroup: search.assignToTaskGroupId ? true : false,
        })
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not find saved search'), JSON.stringify(e))
        this.search = null
      }
      this.loading = false
    } else {
      this.search = null
    }
  }

  /**
   * Save current saved search
   *
   * @param {Event} $event
   * @return {*}  {Promise<void>}
   * @memberof ClaimSearchDetailPage
   */
  async saveSearch($event: Event): Promise<void> {
    this.saving = true

    let facets = this.facetService.facets$.value.filter((f) => f?.selected?.length > 0)
    let facetsInput = facets.map((facet) => {
      let selected = facet.selected.map((s: Selected) => ({
        value: `${s.value}`,
        display: s.display,
        displayPill: s.displayPill,
      }))
      return { facet: facet.id, title: facet.title, selected: selected }
    })

    let { search, scripts } = facetsToClaimQueryParams(facets)

    let obj: SaveSearchInput = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      saveToTaskGroup: this.form.get('saveToTaskGroup').value,
      facets: facetsInput,
      query: search,
      scripts,
    }

    try {
      await this.facetService.saveSearch(obj, this.search?.id)
      this.toast.success('Saved search')
      this.router.navigate(['admin/searches/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save search'), JSON.stringify(e))
    }

    this.saving = false
  }

  cancel(): void {
    this.router.navigate(['admin/searches/edit'])
  }

  /**
   * Launch modal to confirm deleting the current saved search
   *
   * @memberof ClaimSearchDetailPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Saved Search?'
    if (this.form.get('saveToTaskGroup').value) {
      modalRef.componentInstance.body =
        'Deleting this saved search will cause tasks to no longer be automatically assigned to new claims.'
    } else {
      modalRef.componentInstance.body = 'Are you sure you want to delete this saved search?'
    }
    modalRef.componentInstance.yes = 'Delete'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteSavedSearch()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete the current saved search
   *
   * @return {*}  {Promise<void>}
   * @memberof ClaimSearchDetailPage
   */
  async deleteSavedSearch(): Promise<void> {
    try {
      await this.facetService.deleteSavedSearch(this.search.id)
      this.toast.success('Saved search successfully deleted')
      this.router.navigate(['admin/searches/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete saved search'), JSON.stringify(e))
    }
  }
}
