import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { uniq } from 'lodash'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-claims-export-list',
  templateUrl: './claims-export-list.component.html',
  styleUrls: ['./claims-export-list.component.scss'],
})
export class ClaimsExportListComponent implements OnInit, OnDestroy {
  claimIds = new UntypedFormControl('')
  parsedClaimIds: string[] = []

  destroy$ = new Subject<void>()

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.claimIds.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((ids: string) => {
      if (ids?.length > 0) {
        let claims = uniq(ids.split(/\t|\n|,/))
        this.parsedClaimIds = claims.filter((c) => c?.length)
      } else {
        this.parsedClaimIds = []
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
