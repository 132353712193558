import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { BotRun } from 'generated/graphql'
import * as moment from 'moment'

/**
 * Component to display a bot run in a card
 *
 * @export
 * @class BotRunCardComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-bot-run-card',
  templateUrl: './bot-run-card.component.html',
  styleUrls: ['./bot-run-card.component.scss'],
})
export class BotRunCardComponent implements OnInit, AfterViewInit {
  @Input() botRun: BotRun
  @Input() selectedBotRunId: string
  @Input() botId: string
  lateQueuedRun: boolean

  @ViewChild('botRunCard') botRunCard: ElementRef

  constructor() {}

  ngOnInit(): void {
    this.lateQueuedRun =
      this.botRun?.status === 'QUEUED' && moment(this.botRun?.queuedAt).isBefore(moment().subtract(1, 'hours'))
  }

  ngAfterViewInit() {
    if (this.botRun?.id === this.selectedBotRunId)
      setTimeout(() => {
        if (this.botRunCard) this.botRunCard.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      })
  }
}
