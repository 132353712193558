import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router'
import { ClaimsListPage } from 'app/claims/claims-list/claims-list.page'
import { ClaimsService } from 'app/claims/claims.service'

/**
 * Angular route guard that saves claims search and pagination to session storage
 *
 * @export
 * @constant ClaimsListGuard
 */

export const ClaimsListGuard: CanDeactivateFn<ClaimsListPage> = (
  component: ClaimsListPage,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState?: RouterStateSnapshot,
): boolean => {
  inject(ClaimsService).saveFacetsToSessionStorage()
  return true
}
