import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { ClaimsService } from 'app/claims/claims.service'
import { DecryptService } from 'app/shared/services/decrypt.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim } from 'generated/graphql'
import { combineLatest, Subject, Subscription, throwError } from 'rxjs'
import { map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { TeleportService } from '../teleport.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { BotJobsService } from '../../admin/bot-jobs/bot-jobs.service'

@Component({
  selector: 'app-teleport-invoice',
  templateUrl: './teleport-invoice.page.html',
  styleUrls: ['./teleport-invoice.page.scss'],
})
export class TeleportInvoicePage implements OnInit, OnDestroy {
  routerSubscription: Subscription
  isLoading: boolean = true
  isTeleportSupported: boolean = false
  claim: Claim
  destroy$ = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private toast: ToastService,
    private teleportService: TeleportService,
    private botjobsService: BotJobsService,
    private window: Window,
    private decryptService: DecryptService,
    protected zendesk: ZendeskService,
  ) {}

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe()
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnInit(): void {
    this.routerSubscription = this.route.paramMap
      .pipe(
        tap((_) => {
          this.claim = null
          this.isLoading = true
        }),
        map((paramMap: ParamMap) => paramMap.get('providerClaimId')),
        switchMap((maybeEncrypted) => {
          return this.decryptService.getEpicDecrypted(maybeEncrypted)
        }),
        switchMap((providerClaimId: string) => {
          if (!providerClaimId) {
            return throwError(new Error('Cannot fetch claim without provider claim id'))
          }

          return this.claimsService.getClaim(providerClaimId, { billingModule: 'pb' }).pipe(
            map((result) => {
              if (result?.error) {
                this.toast.error(parseGraphQLError(result.error, 'Could not fet claim information'))
              }
              if (!result?.data?.claim) {
                return null
              }
              return result.data.claim
            }),
          )
        }),
        switchMap((claim) => {
          if (!claim) {
            return throwError(new Error('Claim not found'))
          }
          return combineLatest([
            this.botjobsService.botJobFromClaim({ claim }),
            this.teleportService.getTeleportUrlForEntity('claim', claim.id),
          ]).pipe(map(([botjob, url]) => ({ claim, botjob, url })))
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        ({ claim, botjob, url }) => {
          this.claim = claim
          this.isTeleportSupported = url?.length > 0
          this.isLoading = false

          if (!this.isTeleportSupported) {
            /*
             * Teleport feature isn't supported
             */
            this.toast.error('Teleport is not supported for this claim', 'empty teleport url')
            return
          }

          this.teleportService.launchTeleport({
            url,
            refId: claim.id,
            refType: 'claim',
            portal: botjob.portal,
            payer: botjob.payer?.toLowerCase(),
            botType: 'getClaimStatus',
            reuseTab: botjob.reuseTab,
          })
        },
        (err) => {
          this.isLoading = false
          this.toast.error(parseGraphQLError(err, 'The specified claim could not be found'), JSON.stringify(err))
        },
      )
  }
}
