import { Component, Input, OnInit } from '@angular/core'
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-task-picked-by',
  templateUrl: './task-picked-by.component.html',
  styleUrls: ['./task-picked-by.component.scss'],
})
export class TaskPickedByComponent implements OnInit {
  @Input() userIds: string[] = []

  constructor(private userService: UsersService, private toast: ToastService) {}

  ngOnInit(): void {}

  /**
   * Retrieve the user names of users who have picked a task
   * for display in a tooltip
   *
   * @param {*} tooltip
   * @param {boolean} open
   * @param {string[]} [userIds]
   * @return {*}  {Promise<void>}
   * @memberof TaskPickedByComponent
   */
  async toggleTooltip(tooltip: NgbTooltip, open: boolean, userIds?: string[]): Promise<void> {
    if (open && tooltip.isOpen()) {
      tooltip.close()
    } else if (userIds?.length > 0) {
      try {
        let users = await Promise.all(
          userIds.map(async (id) => {
            let user = await this.userService
              .getUser(id)
              .pipe(first())
              .toPromise()
              .catch((e) => null)
            return user.name
          }),
        )
        tooltip.open({ names: users })
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not load users'), JSON.stringify(e))
      }
    }
  }
}
