import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Organization } from 'generated/graphql'

@Component({
  selector: 'app-confirm-org-removal-modal',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">Remove Organization</h5>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Removing an organization from a user also removes the associated roles assigned to the user, denying them access
        to the app.
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="emitConfirmRemoval()">Remove</button>
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
    </div>
  `,
  standalone: true,
})
export class UnassignOrgModalComponent {
  @Input() organization: Organization
  @Output() confirmRemoval = new EventEmitter<Organization>()

  constructor(private modalService: NgbModal) {}

  emitConfirmRemoval(): void {
    this.confirmRemoval.emit(this.organization)
    this.dismiss()
  }

  dismiss(): void {
    this.modalService.dismissAll()
  }
}
