import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, AsyncValidatorFn, Validators } from '@angular/forms'
import { AuthenticationService, PasswordValidationError } from 'app/auth/authentication.service'
import { map } from 'rxjs/operators'

export const MIN_PASSWORD_LENGTH = 12

/**
 * Component to validate a password
 */
@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
})
export class PasswordValidatorComponent implements OnInit {
  @Input() password: AbstractControl
  @Input() email?: AbstractControl
  @Input() token?: string
  passwordFeedback: PasswordValidationError | null = null
  passwordPolicyFeatureFlag: boolean = false
  loading: boolean = true

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    // Attach validators to the password field
    this.password.addValidators([Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)])
    this.password.addAsyncValidators([this.passesBackend()])

    // When the password field becomes invalid, check if it's because of the password policy
    // or because it's too short. If it's too short, show a custom error message in the form of
    // a PasswordValidationError. This simplifies the logic in the template.
    this.password.statusChanges
      .pipe(
        map((status) =>
          status === 'INVALID' && this.password?.errors?.['minlength'] != null
            ? {
                score: 0,
                warning: 'Short passwords are easy to guess!',
                suggestions: [`Your password must contain at least ${MIN_PASSWORD_LENGTH} characters`],
              }
            : this.password?.errors?.['passwordPolicy'] ?? null,
        ),
      )
      .subscribe((val) => {
        this.passwordFeedback = val
      })

    // When the password field is pending, show a loading spinner
    this.password.statusChanges.subscribe((status) => {
      status === 'PENDING' ? (this.loading = true) : (this.loading = false)
    })
    // This is just for initial loading control
    this.loading = false
  }

  /**
   * Validates the password will pass the server-side password policy
   */
  passesBackend(): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<{ passwordPolicy: PasswordValidationError | null }> => {
      if (control.value == null || control.value === '') return null
      const result = await this.authenticationService.validatePassword({
        password: control.value,
        token: this.token,
        email: this.email?.value,
      })
      return result.isValid ? null : { passwordPolicy: result.feedback }
    }
  }

  /**
   * Helper to determine if a field is invalid
   *
   * @param errorKey The error key to check for (if blank, returns true if any error is present)
   */
  isInvalid(errorKey?: string): boolean {
    // Empty input shouldn't trigger validation errors
    if (this.password == null) return false

    // If the field status is anything other than invalid, it's treated as valid
    if (this.password?.status !== 'INVALID') return false

    const { errors } = this.password
    if (Object.keys(errors).length <= 0) return false // This doesn't make sense. The field is invalid, but has no errors?

    if (errorKey == null) {
      // Not checking for a specific error.
      // At this point, we know the field is invalid. For completeness, we'll check if it's dirty.
      return this.password?.dirty
    }

    // Checking for a specific error
    const hasErrorWithKey = errorKey != null && this.password?.errors?.[errorKey] != null
    return this.password?.dirty && hasErrorWithKey
  }
}
