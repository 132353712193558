import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { JuiButtonComponent, JuiThemeComponent } from '@janushealthinc/janus-ui-kit'
import { LoadingComponent } from 'app/shared/components/loading/loading.component'
import { Organization, Role } from 'generated/graphql'

@Component({
  selector: 'app-org-roles',
  templateUrl: './org-roles.component.html',
  styleUrls: ['./org-roles.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, JuiButtonComponent, JuiThemeComponent, LoadingComponent],
})
export class OrgRolesComponent implements OnChanges {
  @Input() organization: Organization
  @Input() roles: Role[]
  @Input() selectedRoles: Role[]
  @Input() isOrganizationRemovable: boolean
  @Input() loading: boolean
  @Input() primary: boolean
  @Output() rolesChange = new EventEmitter<Role[]>()
  @Output() removeOrganization = new EventEmitter<Organization>()
  rolesForm: FormGroup
  editing = false

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roles || changes.selectedRoles) {
      this.initializeForm()
    }
  }

  private initializeForm() {
    const formControls = this.roles.reduce((controls, role) => {
      controls[role.name] = new FormControl(this.isSelected(role))
      return controls
    }, {})

    this.rolesForm = this.fb.group(formControls)
    this.rolesForm.valueChanges.subscribe((val) => {
      const selectedRoles = this.roles.filter((role) => val[role.name])
      this.rolesChange.emit(selectedRoles)
    })
  }

  private isSelected(role: Role): boolean {
    return this.selectedRoles.some((selectedRole) => selectedRole.id === role.id)
  }

  toggleEditing(): void {
    this.editing = !this.editing
  }

  removeRole(role: Role): void {
    this.selectedRoles = this.selectedRoles.filter((selectedRole) => selectedRole.id !== role.id)
    this.rolesChange.emit(this.selectedRoles)
    this.initializeForm()
  }

  removeOrg(): void {
    if (this.isOrganizationRemovable) {
      this.removeOrganization.emit(this.organization)
    }
  }

  selectAll(): void {
    this.roles.forEach((role) => {
      this.rolesForm.controls[role.name].setValue(true)
    })
    this.emitSelectedRoles()
  }

  clearAll(): void {
    this.roles.forEach((role) => {
      this.rolesForm.controls[role.name].setValue(false)
    })
    this.emitSelectedRoles()
  }

  canEdit(): boolean {
    return !this.editing && (!this.primary || (this.primary && this.roles.length > 0))
  }

  private emitSelectedRoles(): void {
    const selectedRoles = this.roles.filter((role) => this.rolesForm.controls[role.name].value)
    this.rolesChange.emit(selectedRoles)
  }
}
