import { formatCurrency, formatDate } from '@angular/common'
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import G6, { Graph, GraphData, GraphOptions, IEdge, INode, NodeConfig } from '@antv/g6'
import { OpportunitiesService } from 'app/illuminate/opportunities.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { defer, sum } from 'lodash'
import { Subject, combineLatest } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { Opportunity } from '../opportunities'

/* color combos ( normal - highlight )
  default : alt-barely-blue - alt-baby-blue
  baseline: alt-light-gray - brand-gray
  good: alt-green - alt-green-light
  warning: alt-orange-lighter - alt-orange-light
  bad: alt-red-lighter - alt-red-light
  full-auto: alt-purple-light - alt-barely-purple
*/

/**
 * Page to display a single opportunity and possible paths
 *
 * @export
 * @class OpportunityDetailsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-opportunity-details',
  templateUrl: './opportunity-details.page.html',
  styleUrls: ['./opportunity-details.page.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class OpportunityDetailsPage implements OnInit, OnDestroy {
  @ViewChild('leftGraphEl') leftGraphEl: ElementRef

  opportunity: Opportunity
  isCollapsed = true

  data: any
  graphHeight: number

  graphOptions: Partial<GraphOptions> = {
    plugins: [
      new G6.Tooltip({
        itemTypes: ['node', 'edge'],
        getContent: (e) => {
          let model = e?.item?.getModel()
          const outDiv = document.createElement('div')
          outDiv.style.width = '276px'
          if (e.item.getType() === 'node') {
            outDiv.innerHTML = `
            <div class="popover show" role="tooltip">
              <h3 class="popover-header pre-wrap">${model?.label}</h3>
              <div class="popover-body bg-alt-white">
                <table class="table">
                    <tr class="border-top-0">
                      <td class="border-top-0">Avg time to reach node</td>
                      <td class="border-top-0 text-left text-primary font-weight-bold">${
                        model?.totalTime ? formatDate(parseInt(model?.totalTime) * 1000, 'm:ss', 'en') : ''
                      }</td>
                    </tr>
                    <tr>
                      <td>Percent reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${
                        model?.totalPercent ? model?.totalPercent + '%' : ''
                      }</td>
                    </tr>
                    <tr>
                      <td>Total reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${model?.totalCount || ''}</td>
                    </tr>
                    <tr class="${!model?.impactOnDenial ? 'd-none' : ''}">
                      <td>Impact on Denial Rate</td>
                      <td class="${
                        model?.impactOnDenial > 0 ? 'text-danger' : 'text-alt-green'
                      } text-left font-weight-bold">
                        <i class="fa ${model?.impactOnDenial < 0 ? 'fa-minus-circle' : 'fa-plus-circle'}"></i>
                        ${model?.impactOnDenial ? Math.abs(model?.impactOnDenial) + '%' : ''}
                      </td>
                    </tr>
                    <tr class="${!model?.relativeImpactOnCollection ? 'd-none' : ''}">
                      <td>Relative Impact <br>on Collection</td>
                      <td class="${
                        model?.relativeImpactOnCollection > 0 ? 'text-alt-green' : 'text-danger'
                      } text-left font-weight-bold">
                        <i class="fa ${
                          model?.relativeImpactOnCollection > 0 ? 'fa-plus-circle' : 'fa-minus-circle'
                        }"></i>
                        ${model?.relativeImpactOnCollection ? Math.abs(model?.relativeImpactOnCollection) + '%' : ''}
                      </td>
                    </tr>
                    <tr class="${!model?.absoluteImpactOnCollection ? 'd-none' : ''}">
                      <td>Absolute Impact <br>on Collection</td>
                      <td class="${
                        model?.absoluteImpactOnCollection > 0 ? 'text-alt-green' : 'text-danger'
                      } text-left font-weight-bold">
                        <i class="fa ${
                          model?.absoluteImpactOnCollection > 0 ? 'fa-plus-circle' : 'fa-minus-circle'
                        }"></i>
                        ${
                          model?.absoluteImpactOnCollection
                            ? formatCurrency(Math.abs(model?.absoluteImpactOnCollection), 'en', '$')
                            : ''
                        }
                      </td>
                    </tr>
                </table>
              </div>
            </div>
            `
          } else {
            outDiv.innerHTML = `
            <div class="popover show" role="tooltip">
              <div class="popover-body bg-alt-white">
                <table class="table">
                    <tr class="border-top-0">
                      <td class="border-top-0">Avg time</td>
                      <td class="border-top-0 text-left text-primary font-weight-bold">${
                        model?.time ? formatDate(parseInt(model?.time) * 1000, 'm:ss', 'en') : ''
                      }</td>
                    </tr>
                    <tr>
                      <td>Percent reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${
                        model?.percent ? model?.percent + '%' : ''
                      }</td>
                    </tr>
                    <tr>
                      <td>Total reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${model?.time || ''}</td>
                    </tr>
                </table>
              </div>
            </div>
            `
          }

          return outDiv
        },
      }),
    ],
    fitView: true,
    fitCenter: false,
    maxZoom: 2,
    layout: {
      type: 'dagre',
      nodesep: 15,
      ranksep: 20,
      controlPoints: true,
      rankdir: 'TB',
      align: 'UL',
    },
  }
  leftGraph: Graph
  leftGraphData: GraphData
  leftGraphOptions = Object.assign({}, this.graphOptions)

  rightGraph: Graph
  rightGraphData: GraphData
  rightGraphOptions = Object.assign({}, this.graphOptions)

  graphMode = ''
  path: 'ideal' | 'decision support' | 'partial Automation' | 'full Automation' | 'longest' | 'lowest'

  highlight: UntypedFormControl = new UntypedFormControl(false)

  animationInterval: number = null

  /**
   * Start / stop main workflow graph animation
   *
   * @memberof OpportunityDetailsPage
   */
  toggleAnimation(): void {
    if (this.animationInterval) {
      window.clearInterval(this.animationInterval)
      this.animationInterval = null
      let { nodes, edges } = this.opportunitiesService.getMainProcessData(this.opportunity.id)

      edges.forEach((edge, index) => {
        edges[index].style.lineWidth = 2 + (edge.count / this.opportunity.processVolume) * 12
      })

      this.leftGraphData = { nodes: nodes, edges: edges }
    } else {
      let rootNode = this.leftGraph.getNodes().find((node) => node.getID() === '1')
      this.animationInterval = window.setInterval(() => this.animateDot(rootNode), 200) // TODO: switch to rxjs interval
    }
  }

  /**
   * Animate dots in the left / main graph
   *
   * @param {INode} startingNode
   * @memberof OpportunityDetailsPage
   */
  animateDot(startingNode: INode): void {
    let possibleEdges = this.leftGraph.getEdges().filter((edge) => edge.getSource().getID() === startingNode.getID())
    if (possibleEdges.length) {
      let probabilities = possibleEdges.map((edge) => {
        let edgeData = this.leftGraphData.edges.find((data) => data.id === edge.getID())
        let percent = (edgeData as any).percent || 0
        return parseInt(percent) / 100
      })
      let totalProbability = sum(probabilities)
      if (totalProbability !== 1) {
        // console.warn('Probabilities of edges leading away from node do not total 100%', startingNode)
      }
      let chosenIndex = this.probabilisticChoice(probabilities)
      let chosenEdge = possibleEdges[chosenIndex]
      if (!chosenEdge) {
        // console.error('Error choosing edge', { possibleEdges, probabilities, chosenIndex })
        return
      }
      let chosenEdgeShape = chosenEdge.getKeyShape()
      let startingPoint = (chosenEdgeShape as any).getPoint(0)
      const circle = chosenEdge.getContainer().addShape('circle', {
        attrs: {
          x: startingPoint.x,
          y: startingPoint.y,
          fill: '#1890ff',
          r: 6,
        },
      })
      circle.animate(
        (ratio) => {
          const intermediatePoint = (chosenEdgeShape as any).getPoint(ratio)
          return {
            x: intermediatePoint.x,
            y: intermediatePoint.y,
          }
        },
        {
          duration: 2000,
          // easing: 'linearEasing', // TODO: figure this out
          callback: () => {
            chosenEdge.getContainer().removeChild(circle)
            this.animateDot(chosenEdge.getTarget())
          },
        },
      )
    }
  }

  /**
   * Helper to determine where a dot should be
   *
   * @param {number[]} probabilities
   * @return {*}  {number}
   * @memberof OpportunityDetailsPage
   */
  probabilisticChoice(probabilities: number[]): number {
    let random = Math.random()
    let cumulativeProbability = 0
    for (let i = 0; i < probabilities.length; i++) {
      cumulativeProbability += probabilities[i]
      if (cumulativeProbability > random) {
        return i
      }
    }
    return 0
  }

  /**
   * Open or close the workflow details section
   * Correspondingly shrink / grow the two graphs
   *
   * @param {*} event
   * @memberof OpportunityDetailsPage
   */
  toggleDetails(event: boolean): void {
    this.graphHeight = this.graphHeight || this.leftGraph.getHeight()

    if (!event) {
      this.leftGraph.changeSize(this.leftGraph.getWidth(), this.graphHeight)
      this.leftGraph.read(this.leftGraphData)
      this.rightGraph.changeSize(this.rightGraph.getWidth(), this.graphHeight)
      this.rightGraph.read(this.rightGraphData)
      setTimeout(() => {
        document.getElementById('workflow-details')?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 500)
    } else {
      setTimeout(() => {
        let parentHeight = this.leftGraphEl.nativeElement.offsetHeight - 5
        this.leftGraph.changeSize(this.leftGraph.getWidth(), parentHeight)
        this.leftGraph.read(this.leftGraphData)
        this.rightGraph.changeSize(this.rightGraph.getWidth(), parentHeight)
        this.rightGraph.read(this.rightGraphData)
      }, 250)
    }
  }

  /**
   * Select a path for the right graph
   * Possibly highligh path in left graph
   *
   * @param {('ideal' | 'decision support' | 'partial Automation' | 'full Automation' | 'longest' | 'lowest')} path
   * @memberof OpportunityDetailsPage
   */
  selectPath(
    path: 'ideal' | 'decision support' | 'partial Automation' | 'full Automation' | 'longest' | 'lowest',
  ): void {
    this.path = path
    let { nodes, edges, data } = this.opportunitiesService.getPathData(this.opportunity.id, path)
    this.data = data
    this.rightGraphData = { nodes, edges }
    if (this.highlight.value) {
      defer(() => this.highlightPath(nodes))
    } else if (this.leftGraph) {
      this.resetHighlighting()
    }
  }

  /**
   * Highlight path in left graph based on nodes in right graph
   *
   * @param {NodeConfig[]} nodes
   * @memberof OpportunityDetailsPage
   */
  highlightPath(nodes: NodeConfig[]): void {
    this.leftGraph.getNodes().forEach((node: INode) => {
      if (node.getID()[0] !== 'd') {
        if (nodes.map((n) => n.id).includes(node.getID())) {
          this.leftGraph.setItemState(node, 'selected', true)
          this.leftGraph.setItemState(node, 'inactive', false)
          node.getInEdges().forEach((edge: IEdge) => {
            if (nodes.map((n) => n.id).includes(edge.getSource().getID())) {
              this.leftGraph.setItemState(edge, 'selected', true)
              this.leftGraph.setItemState(edge, 'inactive', false)
            }
          })
        } else {
          this.leftGraph.setItemState(node, 'selected', false)
          this.leftGraph.setItemState(node, 'inactive', true)
          node.getInEdges().forEach((edge: IEdge) => {
            this.leftGraph.setItemState(edge, 'selected', false)
            this.leftGraph.setItemState(edge, 'inactive', true)
          })
        }
      }
    })
  }

  /**
   * Remove highlighting in left graph
   *
   * @memberof OpportunityDetailsPage
   */
  resetHighlighting(): void {
    this.leftGraph.getNodes().forEach((node) => {
      this.leftGraph.setItemState(node, 'selected', false)
      this.leftGraph.setItemState(node, 'inactive', false)
    })
    this.leftGraph.getEdges().forEach((edge: IEdge) => {
      this.leftGraph.setItemState(edge, 'selected', false)
      this.leftGraph.setItemState(edge, 'inactive', false)
    })
  }

  destroy$ = new Subject<void>()
  triggerRoute$ = new Subject<boolean>()

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private opportunitiesService: OpportunitiesService,
    private crumbs: BreadcrumbsService,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        let opportunityId = this.route.snapshot.paramMap.get('opportunityId')
        this.opportunity = this.opportunitiesService.getOpportunity(opportunityId)

        this.crumbs.setPageTitle('Illuminate - ' + this.opportunity.processName)

        let { nodes, edges } = this.opportunitiesService.getMainProcessData(opportunityId)

        edges.forEach((edge, index) => {
          edges[index].style.lineWidth = 2 + (edge.count / this.opportunity.processVolume) * 12
        })

        this.leftGraphData = { nodes: nodes, edges: edges }
        this.selectPath('ideal')

        this.highlight.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
          this.selectPath(this.path)
        })
        defer(() => {
          this.graphMode = 'processWorkflow'
        })
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
