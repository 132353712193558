import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetTaskGroupStats } from 'app/reporting/stats.gql'
import { debug } from 'app/shared/utils/debug'
import { QueryTaskGroupStatsArgs, TaskGroupStats } from 'generated/graphql'

/**
 * Handle retrieving an organization's task group statistics
 *
 * @export
 * @class TaskGroupStatsService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskGroupStatsService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve a task group's statistics
   *
   * @param {string} id
   * @return {*}  {Promise<TaskGroupStats>}
   * @memberof TaskGroupStatsService
   */
  async getTaskGroupStats(id: string): Promise<TaskGroupStats> {
    debug('task-group-stats', 'get task group stats', id)
    let result = await this.apollo
      .query<{ taskGroupStats: TaskGroupStats }, QueryTaskGroupStatsArgs>({
        query: GetTaskGroupStats,
        fetchPolicy: 'network-only',
        variables: {
          taskGroupId: id,
        },
      })
      .toPromise()

    return result.data.taskGroupStats
  }
}
