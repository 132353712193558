<div class="d-flex flex-column min-vh-100 align-items-center justify-content-center">
  <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" width="400px" />
  <div *ngIf="teleportTakeover || teleportFailed" class="alert alert-primary mb-4" role="alert">
    <div *ngIf="teleportTakeover">Teleport should take over momentarily.</div>
    <div *ngIf="teleportFailed">Teleport did not respond. Please try again.</div>
  </div>
  <app-loading
    *ngIf="!teleportFailed"
    innerClass="fa-3x"
    class="mt-2"
    outerClass="align-self-center text-brand-light-blue"
  ></app-loading>
</div>
