<div class="row full-height">
  <div class="col-lg-3 scrolling-sidebar left-column">
    <app-task-type-search showRight="Assign"></app-task-type-search>
  </div>

  <div class="col-lg-9 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1 overflow-hidden">
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body flex-basis-lg-0">
              <h3 class="text-center">Tasks</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <ng-template ngFor let-tt [ngForOf]="tasks" let-i="index">
                  <app-task-type-card
                    [taskType]="tt"
                    [isCollapsed]="taskCollapsed[i]"
                    showRemove="Remove"
                    showPencil="Edit"
                  ></app-task-type-card>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column flex-grow-1 mt-5 mt-lg-0">
          <div class="card flex-grow-1">
            <div class="card-body scrolling-main flex-basis-lg-0 d-flex flex-column">
              <h3 class="text-center">Claims</h3>
              <hr class="w-75 mx-auto" />
              <textarea [formControl]="claimIds" class="form-control flex-grow-1" cols="20" rows="6"></textarea>
              <small class="form-text text-muted"> Enter Claim IDs separated by commas, tabs, or one per line </small>
              <hr class="w-75 mx-auto" />
              <h4 class="text-center">Parsed {{claims?.length | number}} Claims</h4>
              <hr class="w-75 mx-auto" />
              <div class="d-flex justify-content-end">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="allow-dupe-checkbox"
                    [formControl]="duplicates"
                    data-t="allow-dupe-checkbox"
                  />
                  <label class="form-check-label" for="allow-dupe-checkbox">Allow Duplicate Tasks</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="assign-tasks-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-green px-5"
            data-t="assign-tasks-button"
            (click)="save()"
            [disabled]="tasks.length < 1 || claims.length < 1"
          >
            Assign
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title mx-auto">Bulk Assign Review</h5>
  </div>
  <div class="modal-body d-flex flex-column">
    <nav ngbNav #nav="ngbNav" class="nav-pills muted">
      <ng-container ngbNavItem>
        <a ngbNavLink
          ><span> <i class="fa fa-check-circle-o text-brand-green"></i> Assigned: {{assigned?.length}} </span></a
        >
        <ng-template ngbNavContent>
          <div class="d-flex flex-column height-restrict">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Task</th>
                  <th>Claim</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let response of assigned">
                  <td class="text-center">
                    <i class="fa fa-check-circle-o text-brand-green" data-t="assign-review-green-check"></i>
                  </td>
                  <td>{{response?.taskName}}</td>
                  <td>{{response?.providerClaimId}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem>
        <a ngbNavLink>
          <span> <i class="fa fa-times-circle-o text-danger"></i> Failed: {{failed?.length}} </span></a
        >
        <ng-template ngbNavContent>
          <div class="d-flex flex-column height-restrict">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Task</th>
                  <th>Claim</th>
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let response of failed">
                  <td class="text-center">
                    <i class="fa fa-times-circle-o text-danger"></i>
                  </td>
                  <td>{{response?.taskName}}</td>
                  <td>{{response?.providerClaimId}}</td>
                  <td class="small">{{response?.message || ''}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" *ngIf="assigned.length" (click)="assignTasksToTaskGroup()">
      Assign to Task Group
    </button>
    <button type="button" class="btn btn-link" (click)="modal.close()">Done</button>
  </div>
</ng-template>
