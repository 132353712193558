import { Component, ElementRef, EventEmitter, OnChanges, Input, Output, ViewChild, HostListener } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { Subject, Observable, debounceTime, distinctUntilChanged, filter, tap, switchMap, first, of, merge, defer} from 'rxjs';

@Component({
  selector: 'app-tag-search',
  templateUrl: './tag-search.component.html'
})

export class TagSearchComponent implements OnChanges {

  protected focus$ = new Subject<string>()
  protected click$ = new Subject<string>()
  protected searching: boolean = false

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() availableTags: string[] = []
  @Input() selectedTag: string = ''
  @Input() clearInput: boolean = true
  @Input() autoFocus: boolean = true

  @Output() onSelect = new EventEmitter<string>()

  ngOnChanges(): void {
    if (this.selectedTag?.length) {
      this.element.nativeElement.value = this.selectedTag
    }
    if (this.autoFocus) {
      this.element.nativeElement.focus()
    }
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      const $activeItems = document.querySelectorAll('ngb-typeahead-window.show > .dropdown-item.active')
      $activeItems.forEach(($item) => $item.scrollIntoView())
    }
  }

  search = (text$: Observable<string>): Observable<string[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((search) =>
        this.getTagResult(search).pipe(
          first(),
        )
      ),
      tap(() => (this.searching = false)),
    )
  }

  getTagResult(search?: string): Observable<string[]> {
    return of(this.availableTags.filter((tag) => tag.startsWith(search)))
  }
  
  inputFormatter = (x): string => x

  resultFormatter = (x): string => x


  onSelectTag(event: { item: string }): void {
    const tag = event.item
    this.onSelect.emit(tag)
    if (this.clearInput) {
      defer(() => (this.element.nativeElement.value = ''))
    } else{
      this.element.nativeElement.value = tag
    }
  }

  clearTag(event: Event): void {
    event.preventDefault()
    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
