import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Task, TaskGroup, User } from 'generated/graphql'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

/**
 * Page to allow editing an existing task group
 *
 * @export
 * @class TaskGroupDetailsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-group-edit-details-page',
  templateUrl: './task-group-details.page.html',
  styleUrls: ['./task-group-details.page.scss'],
})
export class TaskGroupDetailsPage implements OnInit, OnDestroy {
  taskGroup: TaskGroup
  tasksSub$: Subscription
  tasks: Task[] = []
  taskGroupForm = new UntypedFormGroup({
    taskGroup: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    score: new UntypedFormControl(''),
  })

  saving: boolean = false
  loading: boolean = false
  loadingUsers: boolean = false
  selectedUsers: User[] = []

  get selectedUserIds(): string[] {
    return this.selectedUsers.map((u) => u.id)
  }

  routerSub$: Subscription
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private taskgroupService: TaskGroupsService,
    private taskService: TasksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private userService: UsersService,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger]) => event instanceof NavigationEnd || trigger))
      .subscribe(() => {
        this.onRouteChange()
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
    this.tasksSub$.unsubscribe()
  }

  /**
   * Load a task group and related data
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupDetailsPage
   */
  async onRouteChange(): Promise<void> {
    this.selectedUsers = []
    let taskGroupId = this.route.snapshot.paramMap.get('taskGroupId')

    if (taskGroupId) {
      this.loading = true
      try {
        this.taskGroup = await this.taskgroupService.getTaskGroup(taskGroupId)
        debug('taskGroup', 'got taskGroup', this.taskGroup)

        this.crumbs.setPageTitle('Admin - Task Groups - ' + this.taskGroup?.name)

        this.taskGroupForm.setValue({
          taskGroup: this.taskGroup.name,
          description: this.taskGroup.description,
          score: this.taskGroup.score,
        })
        if (this.taskGroup.assignedToUserIds?.length) {
          this.loadingUsers = true
          this.selectedUsers = await this.userService.getUsers(null, this.taskGroup.assignedToUserIds)
          this.loadingUsers = false
        }
        this.tasksSub$?.unsubscribe()
        this.tasksSub$ = this.taskService
          .getTasks({ taskGroupId: taskGroupId })
          .subscribe((val) => (this.tasks = val?.data?.tasks?.entities))
        // let lastCrumb = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 1]
        // lastCrumb.label = this.taskGroup.name
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not find task group'), JSON.stringify(e))
        this.taskGroup = null
        this.tasks = []
      }
      this.loading = false
    } else {
      this.taskGroup = null
      this.tasks = []
    }
  }

  /**
   * Save edits to the current task group
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupDetailsPage
   */
  async editTaskGroup(): Promise<void> {
    this.taskGroupForm.markAllAsTouched()
    if (this.taskGroupForm.invalid) {
      this.toast.error(
        'Please fill in all required fields',
        JSON.stringify(readableFormGroupErrors(this.taskGroupForm)),
      )
      return
    }
    try {
      await this.taskgroupService.saveTaskGroup(
        {
          name: this.taskGroupForm.get('taskGroup').value,
          description: this.taskGroupForm.get('description').value,
          score: parseInt(this.taskGroupForm.get('score').value),
          assignedToUserIds: this.selectedUsers.map((u) => u.id),
        },
        this.taskGroup.id,
      )
      this.toast.success('Task Group successfully edited')
      this.router.navigate(['admin/task-groups/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save task group'), JSON.stringify(e))
    }
  }

  /**
   * Cancel the current edits and return to the list of task groups
   *
   * @memberof TaskGroupDetailsPage
   */
  cancelEditing(): void {
    this.router.navigate(['admin/task-groups/edit'], { state: { data: { reloadSearch: false } } })
  }

  /**
   * Assign a user to the current task group
   *
   * @param {User} user
   * @memberof TaskGroupDetailsPage
   */
  addUser(user: User): void {
    if (user?.id) {
      let arr = this.selectedUsers.slice()
      arr.push(user)
      this.selectedUsers = arr
    }
  }

  /**
   * Unassign a user from the current task group
   *
   * @param {User} user
   * @memberof TaskGroupDetailsPage
   */
  removeUser(user: User): void {
    let i = this.selectedUsers.findIndex((u) => u.id === user.id)
    if (i >= 0) {
      let arr = this.selectedUsers.slice()
      arr.splice(i, 1)
      this.selectedUsers = arr
    }
  }

  /**
   * Launch modal to confirm deleting the current task group
   *
   * @memberof TaskGroupDetailsPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete task group?'
    modalRef.componentInstance.body = `${this.tasks?.length} task(s) will be without a task group.`
    modalRef.componentInstance.yes = 'Delete task group'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTaskGroup()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete the current task group
   *
   * @memberof TaskGroupDetailsPage
   */
  async deleteTaskGroup(): Promise<void> {
    try {
      await this.taskgroupService.deleteTaskGroup(this.taskGroup.id)
      this.toast.success('Task Group successfully deleted')
      this.router.navigate(['admin/task-groups/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete task group'), JSON.stringify(e))
    }
  }
}
