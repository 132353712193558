import { Component, Input, OnInit } from '@angular/core'
import { TaskGroupCardActionsService } from 'app/admin/task-groups/task-group-card-actions.service'
import { TaskGroup } from 'generated/graphql'

/**
 * Component to display a single task group in a card
 *
 * @export
 * @class TaskGroupCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-group-card',
  templateUrl: './task-group-card.component.html',
  styleUrls: ['./task-group-card.component.scss'],
})
export class TaskGroupCardComponent implements OnInit {
  @Input() taskGroup: TaskGroup
  @Input() isCollapsed: boolean

  @Input() showPencil: string = null
  @Input() showRight: string = null
  @Input() showRemove: string = null

  constructor(private actionService: TaskGroupCardActionsService) {}

  ngOnInit(): void {}

  /**
   * User clicked edit icon
   *
   * @param {TaskGroup} taskGroup
   * @param {*} $event
   * @memberof TaskGroupCardComponent
   */
  clickPencil(taskGroup: TaskGroup, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickPencil(taskGroup)
  }

  /**
   * User clicked arrow icon
   *
   * @param {TaskGroup} taskGroup
   * @param {*} $event
   * @memberof TaskGroupCardComponent
   */
  clickRight(taskGroup: TaskGroup, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRight(taskGroup)
  }

  /**
   * User clicked remove icon
   *
   * @param {TaskGroup} taskGroup
   * @param {*} $event
   * @memberof TaskGroupCardComponent
   */
  clickRemove(taskGroup: TaskGroup, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRemove(taskGroup)
  }
}
