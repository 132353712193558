<div class="form-group">
  <label class="pr-2" [for]="inputBoxId">{{ label }}</label>
  <div
    class="form-control h-100 cursor-text"
    [class.is-invalid]="(inputFormControl.invalid || arrayFormControl.invalid) && (inputFormControl.touched || arrayFormControl.touched)"
  >
    <span
      *ngFor="let entry of arrayFormControl.value; let i = index"
      class="d-inline-block border rounded-lg m-1 px-3 py-1 chip text-white text-wrap"
    >
      {{ entry }}
      <i (click)="onDeleteEntry($event, i)" class="fa fa-times-circle text-light ml-1"></i>
    </span>
    <input
      #inputBox
      autocomplete="off"
      class="bg-transparent mw-100 w-100 multi-input"
      [id]="inputBoxId"
      type="text"
      [formControl]="inputFormControl"
      (keydown.enter)="onEnter($event)"
      (keydown.backspace)="onBackspace($event)"
      [attr.aria-describedby]="inputDescribedBy"
    />
  </div>
  <ng-content></ng-content>
</div>
