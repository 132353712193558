<div class="text-alt-dark-gray rounded col-12" *ngIf="!isLoading; else loadingBlock">
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md col p-0">
    <div *ngIf="authStatusRowConfigs?.length" class="text-center text-alt-light-gray">
      <p>Teleport for Auth Initiation</p>
    </div>
    <table class="table table-janus-new">
      <thead>
        <tr class="fs-13" [ngSwitch]="type">
          <th scope="col"><span class="sr-only">Teleport</span></th>
          <ng-template [ngSwitchCase]="'patientMrn'">
            <th scope="col">Service Date</th>
            <th scope="col" class="text-left">Payer</th>
            <th scope="col">Epic Referral ID</th>
            <th scope="col">Procedure Code</th>
          </ng-template>
          <ng-template [ngSwitchCase]="'harId'">
            <th scope="col">Claim ID</th>
            <th scope="col">Service Date</th>
            <th scope="col" class="text-left">Payer</th>
            <th scope="col">Outstanding Amt.</th>
            <th scope="col">Total Amt.</th>
          </ng-template>
        </tr>
      </thead>
      <tbody>
        <app-teleport-link-table-row
          *ngFor="let rowConfig of rowConfigs"
          [config]="rowConfig"
          (onLoaded)="onLinkLoaded($event)"
          aria-label="test"
        ></app-teleport-link-table-row>
      </tbody>
    </table>
    <div
      *ngIf="!rowConfigs?.length && !isLoading"
      class="d-flex justify-content-center alert alert-danger"
      role="alert"
      id="no-results-alert"
    >
      Your search returned no results.
    </div>
    <div *ngIf="authStatusRowConfigs?.length && !isLoading">
      <div class="text-center text-alt-light-gray">
        <p>Teleport for Auth Status</p>
      </div>
      <table class="table table-janus-new">
        <thead>
          <tr class="fs-13">
            <th scope="col"><span class="sr-only">Teleport</span></th>
            <th scope="col">Service Date</th>
            <th scope="col" class="text-left">Payer</th>
            <th scope="col">Epic Referral ID</th>
            <th scope="col">Procedure Code</th>
          </tr>
        </thead>
        <tbody>
          <app-teleport-link-table-row
            *ngFor="let rowConfig of authStatusRowConfigs"
            [config]="rowConfig"
            (onLoaded)="onLinkLoaded($event)"
          ></app-teleport-link-table-row>
        </tbody>
      </table>
    </div>
    <div [ngSwitch]="type" *ngIf="contactRowConfigs?.length && !isLoading">
      <ng-template [ngSwitchCase]="'patientMrn'">
        <div class="text-center text-alt-light-gray">
          <p>Contact Payer Cases</p>
        </div>
        <table class="table table-janus-new">
          <thead>
            <tr class="fs-13">
              <th scope="col"><span class="sr-only">Teleport</span></th>
              <ng-template [ngSwitchCase]="'patientMrn'">
                <th scope="col">Service Date</th>
                <th scope="col" class="text-left">Payer</th>
                <th scope="col">Epic Referral ID</th>
                <th scope="col">Procedure Code</th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <app-contact-payer-link-table-row
              *ngFor="let rowConfig of contactRowConfigs"
              [config]="rowConfig"
              (statusUpdated)="onStatusUpdated($event)"
            ></app-contact-payer-link-table-row>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div [ngSwitch]="type" *ngIf="completeReferralRowConfigs?.length && !isLoading">
      <ng-template [ngSwitchCase]="'patientMrn'">
        <div class="text-center text-alt-light-gray">
          <p>Completed Cases</p>
        </div>
        <table class="table table-janus-new">
          <thead>
            <tr class="fs-13">
              <th scope="col"><span class="sr-only">Teleport</span></th>
              <ng-template [ngSwitchCase]="'patientMrn'">
                <th scope="col">Service Date</th>
                <th scope="col" class="text-left">Payer</th>
                <th scope="col">Epic Referral ID</th>
                <th scope="col">Procedure Code</th>
              </ng-template>
            </tr>
          </thead>
          <tbody>
            <app-completed-teleport-link-table-row
              *ngFor="let rowConfig of completeReferralRowConfigs"
              [config]="rowConfig"
            ></app-completed-teleport-link-table-row>
          </tbody>
        </table>
      </ng-template>
    </div>
    <div [ngSwitch]="type" *ngIf="testRowConfigs?.length && !isLoading">
      <div class="text-center text-alt-light-gray">
        <p>Test Teleport for Auth Initiation</p>
      </div>
      <table class="table table-janus-new">
        <thead>
          <tr class="fs-13">
            <th scope="col"><span class="sr-only">Teleport</span></th>
            <th scope="col">Service Date</th>
            <th scope="col" class="text-left">Payer</th>
            <th scope="col">Epic Referral ID</th>
            <th scope="col">Procedure Code</th>
          </tr>
        </thead>
        <tbody>
          <app-teleport-link-table-row
            *ngFor="let rowConfig of testRowConfigs"
            [config]="rowConfig"
            (onLoaded)="onLinkLoaded($event)"
          ></app-teleport-link-table-row>
        </tbody>
      </table>
    </div>
    <div *ngIf="testAuthStatusRowConfigs?.length && !isLoading">
      <div class="text-center text-alt-light-gray">
        <p>Test Teleport for Auth Status</p>
      </div>
      <table class="table table-janus-new">
        <thead>
          <tr class="fs-13">
            <th scope="col"><span class="sr-only">Teleport</span></th>
            <th scope="col">Service Date</th>
            <th scope="col" class="text-left">Payer</th>
            <th scope="col">Epic Referral ID</th>
            <th scope="col">Procedure Code</th>
          </tr>
        </thead>
        <tbody>
          <app-teleport-link-table-row
            *ngFor="let rowConfig of testAuthStatusRowConfigs"
            [config]="rowConfig"
            (onLoaded)="onLinkLoaded($event)"
          ></app-teleport-link-table-row>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #loadingBlock class="h-100 d-flex align-items-center justify-content-center">
  <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
</ng-template>
