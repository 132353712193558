<div class="row">
  <div class="col-lg-3 col-sm-9">
    <fieldset>
      <div class="input-group form-group">
        <input
          [formControl]="botsSearch"
          (keyup.enter)="searchBotJobs()"
          type="text"
          class="form-control"
          placeholder="Search Bot Jobs"
        />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <button type="button" (click)="searchBotJobs()" class="btn bg-transparent">
              <i class="fa fa-search fa-fw"></i>
            </button>
          </span>
        </div>
      </div>
    </fieldset>
  </div>
</div>
<div class="d-flex flex-column flex-grow-1">
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="bots-table">
      <thead>
        <tr>
          <th scope="col" class="text-left">Name</th>
          <th scope="col" class="text-left">Description</th>
          <th scope="col" class="text-left">Last Run</th>
          <th scope="col" class="text-left">Success Rate</th>
          <th scope="col" class="text-left">Avg. Duration</th>
        </tr>
      </thead>
      <tbody *ngIf="!loading && botJobs?.length">
        <tr
          *ngFor="let botJob of botJobs; index as i"
          class="clickable"
          data-t="bot-card"
          routerLink="/revbots/{{ botJob.botJob?.id }}"
        >
          <td>{{ botJob.botJob?.name }}</td>
          <td><p [innerHTML]="botJob.botJob?.description"></p></td>
          <td>
            <app-loading *ngIf="!botJob.details?.fetched && !botJob.details?.lastRun"></app-loading>
            <div *ngIf="botJob.details?.fetched || botJob.details?.lastRun">
              {{ botJob.details?.lastRun ? (botJob.details.lastRun | date) : 'Never' }}
            </div>
          </td>
          <td>
            <app-loading *ngIf="!botJob.details?.fetched && !botJob.details?.lastRun"></app-loading>
            <div *ngIf="botJob.details?.fetched || botJob.details?.lastRun">
              {{ botJob.details?.lastRun ? (botJob.details?.successRate || 0 | percent) : 'None recent' }}
            </div>
          </td>
          <td>
            <app-loading *ngIf="!botJob.details?.fetched && !botJob.details?.avgRunTime"></app-loading>
            <div *ngIf="botJob.details?.fetched || botJob.details?.avgRunTime">
              {{ botJob?.details?.avgRunTime ? botJob.details?.avgRunTime + ' sec' : 'None recent' }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="loading"
      innerClass="fa-4x"
      outerClass="align-self-center text-brand-light-blue"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div *ngIf="!botJobs?.length && !loading" class="d-flex justify-content-center alert alert-danger" role="alert">
      Your search returned no results.
    </div>
  </div>

  <div class="d-flex align-items-baseline justify-content-end pt-3 pr-3" *ngIf="!loading && botJobs?.length">
    <small class="mr-3">
      {{page * pageSize - pageSize + 1 | number}} - {{botJobs?.length < pageSize ? meta?.total : page * pageSize |
      number}} of {{meta?.total | number}}
    </small>
    <ngb-pagination
      [collectionSize]="meta?.total"
      (pageChange)="searchBotJobs($event)"
      [pageSize]="pageSize"
      [(page)]="page"
      [maxSize]="5"
      *ngIf="meta?.total >= pageSize"
      [boundaryLinks]="false"
    >
    </ngb-pagination>
  </div>
</div>
