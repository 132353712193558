<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col-3 pt-2">
      <app-organization-typeahead
        (onSelect)="onOrgChange($event)"
        [placeholder]="currentOrg ? currentOrg.name : 'Loading'"
        [autoFocus]="false"
        id="org"
      ></app-organization-typeahead>
    </div>
  </div>

  <div class="flex-grow-1 d-flex flex-row">
    <div class="mt-4 flex-grow-1 d-flex justify-content-start">
      <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab">
        <ng-container *ngIf="activeTab != viewModes.URL_GROUPS" ngbNavItem [ngbNavItem]="viewModes.CONFIG">
          <a ngbNavLink [ngClass]="{ 'bg-alt-lighter-gray': activeTab == viewModes.CONFIG }">Configurations</a>
          <ng-template class="container-fluid" ngbNavContent>
            <app-configuration-pipeline
              [selectedOrganization]="currentOrg"
              (queryDateRange)="setQueryDate($event)"
              (selectedSummary)="openUrlGrouping($event)"
            ></app-configuration-pipeline>
          </ng-template>
        </ng-container>
        <ng-container
          *ngIf="activeTab != viewModes.URL_GROUPS"
          ngbNavItem
          [ngbNavItem]="viewModes.ANNONATION_BUILDER"
          [disabled]="true"
        >
          <a ngbNavLink [ngClass]="{ 'bg-alt-lighter-gray': activeTab == viewModes.ANNONATION_BUILDER }"
            >Annotation Builder</a
          >
          <ng-template ngbNavContent class="flex-grow-1 d-flex">
            <app-annotation-builder></app-annotation-builder>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="activeTab == viewModes.URL_GROUPS" ngbNavItem>
          <button
            class="border-alt-light-gray btn btn-light btn-md text-alt-medium-gray"
            ngbTooltip="Previous"
            type="button"
            (click)="goToBackButton()"
            data-t="previous-button"
          >
            <i class="fa fa-arrow-left px-3"></i>
          </button>
        </ng-container>
        <ng-container *ngIf="activeTab == viewModes.URL_GROUPS" ngbNavItem [ngbNavItem]="viewModes.URL_GROUPS">
          <a ngbNavLink class="bg-alt-lighter-gray">{{ selectedObserveSummary?.applicationURL }}</a>
          <ng-template ngbNavContent class="flex-grow-1 d-flex">
            <app-add-configurations
              *ngIf="showAddConfigurations"
              [selectedObserveSummary]="selectedObserveSummary"
              [selectedUrlGroup]="selectedUrlGroup"
              [missReason]="selectedMissReason"
            ></app-add-configurations>
            <app-url-groups
              *ngIf="!showAddConfigurations"
              [selectedObserveSummary]="selectedObserveSummary"
              (selectedUrlGroup)="openAddConfigurations($event)"
              (selectedMissReason)="missReasonSelected($event)"
            ></app-url-groups>
          </ng-template>
        </ng-container>
      </nav>
    </div>

    <div class="float-right">
      <p *ngIf="activeTab == viewModes.CONFIG || viewModes.URL_GROUPS">{{ dateRangeString }}</p>
    </div>
  </div>

  <div [ngbNavOutlet]="nav"></div>
</div>
