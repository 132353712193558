import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { PermissionsConfig } from 'app/auth/permissions/permissions-config.interface'
import { Permissions } from 'app/auth/types'
import { ReportService } from 'app/reports/report.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ListResponseMetaData, Report, UserRole } from 'generated/graphql'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter, first } from 'rxjs/operators'

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.page.html',
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class ReportListPage implements OnInit, OnDestroy {
  isLoading: boolean = false
  reportSearch = new UntypedFormControl('')
  reports: Report[]
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 25
  routerSub$: Subscription
  reportsSub$: Subscription
  downloading: string
  currentRoute: string
  canDeleteReportConfig: PermissionsConfig = { permissions: [Permissions.system.report.all], roles: [UserRole.Admin] }
  triggerRoute$ = new Subject<boolean>()

  constructor(
    public toast: ToastService,
    public authenticationService: AuthenticationService,
    private reportService: ReportService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger]) => event instanceof NavigationEnd || trigger))
      .subscribe((event) => {
        this.reportSearch.setValue(this.route.snapshot.paramMap.get('search'))
      })
    this.reportsSub$ = this.reportService
      .getReports({
        search: this.reportSearch?.value,
        limit: this.pageSize,
      })
      .subscribe((event) => {
        this.isLoading = event.loading
        if (event.error) {
          this.toast.error(parseGraphQLError(event, 'Could not find reports'), JSON.stringify(event.error))
        }
        if (event.data) {
          this.reports = event.data.reports.entities
          this.meta = event.data.reports.meta
        }
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
    this.currentRoute = window.location.origin + '/reports/'
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }

  searchNavigate(): void {
    this.router.navigate(['reports', this.reportSearch.value])
  }

  async downloadReport(id: string): Promise<void> {
    this.downloading = id
    try {
      let query: ApolloQueryResult<{ report: Report }> = await this.reportService
        .getReport(id)
        .pipe(first())
        .toPromise()
        .catch((e) => null)
      window.open(query?.data?.report?.link, '_blank')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Failed to download report'), JSON.stringify(e))
    }
    this.downloading = null
  }

  async searchReports(page?: number): Promise<void> {
    this.isLoading = true
    let offset
    if (page) {
      offset = (page - 1) * this.meta.limit
    }
    this.reportService
      .getReports({
        search: this.reportSearch?.value,
        offset,
        limit: this.pageSize,
      })
      .pipe(first())
      .subscribe(
        (reports) => {
          this.reports = reports?.data?.reports?.entities
          this.meta = this.reportService.getMeta()
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not find reports'), JSON.stringify(e))
        },
        () => {
          this.isLoading = false
        },
      )
  }

  confirmDelete(reportId: string): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete report?'
    modalRef.componentInstance.body = `Once this report is deleted its data cannot be recovered.`
    modalRef.componentInstance.yes = 'Delete report'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteReport(reportId)
      },
      (dismissed) => {},
    )
  }

  async deleteReport(reportId: string): Promise<void> {
    try {
      await this.reportService.deleteReport(reportId)
      this.toast.success('Report successfully deleted')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete report'), JSON.stringify(e))
    }
  }
}
