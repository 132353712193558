<div class="bg-alt-lighter-gray container-fluid">
  <ng-container>
    <div class="flex-grow-1 d-flex flex-row py-4">
      <div *ngIf="totalScreenCoverage" class="col pt-2 float-left">
        <p>Screen Coverage: {{ totalScreenCoverage | percent }}</p>
      </div>
      <div *ngIf="configData?.length" class="col float-right">
        <app-pagination [metaData]="configsMetaData" (fetchPage)="onPageUpdate($event)"> </app-pagination>
      </div>
    </div>

    <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md col m-1">
      <table class="table table-janus-new table-striped" data-t="observe-configuration-pipeline-table">
        <thead class="bg-alt-lighter-gray">
          <tr>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.applicationURL"
              (sort)="onSort($event)"
            >
              Allowed App/URL
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.count"
              (sort)="onSort($event)"
            >
              Visits
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.totalCoveragePercent"
              (sort)="onSort($event)"
            >
              Current Coverage
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!loading" class="bg-alt-white">
          <tr *ngFor="let config of displayedRows" data-t="observe-configuration-pipeline-table-row">
            <td class="text-left url-col">
              <button type="button" class="btn btn-link" (click)="onSelectedSummary(config.applicationURL)">
                {{ config.applicationURL }}
              </button>
            </td>
            <td class="text-left">{{ config.count | number: '1.0':'en-US' }}</td>
            <td class="text-left">{{ config.totalCoveragePercent | percent }}</td>
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="loading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center py-4 my-4"
      ></app-loading>
      <div
        *ngIf="!configData?.length && !loading"
        class="d-flex justify-content-center alert alert-danger"
        role="alert"
      >
        No results for Organization
      </div>
      <div *ngIf="configData?.length" class="col float-right pb-2">
        <app-pagination [metaData]="configsMetaData" (fetchPage)="onPageUpdate($event)"> </app-pagination>
      </div>
    </div>
  </ng-container>
</div>
