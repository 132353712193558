import { Component, OnInit, Input } from '@angular/core'
import { ObserveRecordAnnotated, ObserveRecord, ObserveFile } from 'generated/graphql'

@Component({
  selector: 'app-annotated-record-slide',
  templateUrl: './annotated-record-slide.component.html',
})
export class AnnotatedRecordSlideComponent implements OnInit {
  @Input() record: ObserveRecordAnnotated

  constructor() {}

  /**
   * Return a string the badge text should display based on record status.
   */
  get badgeText(): string {
    if (this.record?.isConfirmed) {
      return 'Confirmed'
    }
    if (this.record?.hasBeenViewed) {
      return 'Viewed'
    }
    return 'Not Viewed'
  }

  /**
   * Nested observe record.
   */
  get observeRecord(): ObserveRecord {
    return this.record?.observeRecord
  }

  /**
   * Nested observe image file.
   */
  get observeFile(): ObserveFile {
    return this.observeRecord?.imageFile
  }

  ngOnInit(): void {}
}
