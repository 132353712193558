import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/**
 * Generic Artemis Modal
 *
 * @export
 * @class GenericModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  host: {
    class: 'd-flex flex-column mh-90vh',
    'data-t': 'generic-modal',
  },
})
export class GenericModalComponent implements OnInit {
  @Input() title: string
  @Input() confirmText: string
  @Input() cancelText: string = 'Cancel'
  @Input() showConfirmSpinner: boolean
  @Input() body?: string
  @Output() onConfirm: EventEmitter<void> = new EventEmitter()
  @Output() onCancel: EventEmitter<void> = new EventEmitter()

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  clickOnConfirm(): void {
    this.activeModal.close()
    this.onConfirm.emit()
  }

  clickOnCancel(): void {
    this.activeModal.dismiss()
    this.onCancel.emit()
  }
}
