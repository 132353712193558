<div class="mt-5 row no-gutters">
  <div class="col-6 d-flex flex-column justify-content-end px-1">
    <label class="text-alt-dark-gray">What other payers should Teleport support?</label>
    <app-payer-typeahead (onSelect)="onPayerPicked($event)" [allowOther]="true"></app-payer-typeahead>
  </div>
  <div class="col-6 d-flex flex-column justify-content-end px-1" *ngIf="isAddingPayer">
    <label class="text-alt-dark-gray" for="newPayer">Add a payer not listed</label>
    <div class="input-group form-group m-0">
      <input type="text" class="form-control" id="newPayer" [formControl]="newPayerName" />
      <div class="input-group-append">
        <span class="in-input-icon d-flex align-items-center" aria-label="Add Payer">
          <button
            type="button"
            (click)="addNewPayer()"
            class="btn bg-transparent"
            ngbTooltip="Add Payer"
            container="body"
          >
            <i class="fa fa-plus fa-fw text-brand-dark-gray"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
