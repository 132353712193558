import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AnalyticsGqlInterceptor } from './interceptors/analytics-gql.interceptor'
import { AnalyticsApiInterceptor } from './interceptors/analytics-api.interceptor'
import { RouterModule } from '@angular/router'
import { PrivateDirective } from './directives/private.directive'

@NgModule({
  declarations: [PrivateDirective],
  imports: [BrowserModule, HttpClientModule, RouterModule],
  exports: [PrivateDirective],
  providers: [
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: AnalyticsApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AnalyticsGqlInterceptor, multi: true },
  ],
})
export class AnalyticsModule {}
