import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ClaimsAndTasksQuery } from 'app/admin/tasks/delete/claims-by-id.gql'
import { ClaimsService } from 'app/claims/claims.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, Workflow, WorkflowType } from 'generated/graphql'
import { compact, flatten, pull, uniq, uniqBy } from 'lodash'
import { Subscription } from 'rxjs'
import { WorkflowService } from '../workflow.service'

/**
 * Page to allow removing workflows from claims
 *
 * @export
 * @class DeleteWorkflowsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-delete-workflows',
  templateUrl: './delete-workflows.page.html',
})
export class DeleteWorkflowsPage implements OnInit, OnDestroy {
  claimIds = new UntypedFormControl('')
  claimIds$: Subscription
  claims: string[]
  fetchedClaims: Claim[] = []

  workflows: Workflow[] = []
  workflowTypes: WorkflowType[] = []
  selectedWorkflowTypes: WorkflowType[] = []
  isFetchingWorkflow: boolean = false

  isDeleting: boolean = false

  constructor(
    private claimsService: ClaimsService,
    private workflowService: WorkflowService,
    private toast: ToastService,
    private modal: NgbModal,
  ) {
    this.claimIds$ = this.claimIds.valueChanges.subscribe(async (ids: string) => {
      this.isFetchingWorkflow = true
      if (ids?.length > 0) {
        this.claims = uniq(ids.split(/\t|\n|,/)).map((id) => id.trim())
        try {
          this.fetchedClaims = await this.claimsService.getClaims(
            ClaimsAndTasksQuery,
            {
              sort: 'providerClaimId',
              offset: 0,
              limit: 100,
              search: `providerClaimId: (${this.claims.join(' OR ')})`,
            },
            false,
          )
          this.workflows = compact(flatten(this.fetchedClaims.map((claim) => claim?.tasks))).map(
            (task) => task?.workflow,
          )
          this.workflowTypes = uniqBy(this.workflows, 'workflowType.id')
            .filter((workflow) => workflow?.workflowType)
            .map((workflow) => workflow.workflowType)
        } catch (e) {
          this.toast.error(parseGraphQLError(e, 'Error in fetching workflows'), JSON.stringify(e))
        }
      }
      this.isFetchingWorkflow = false
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.claimIds$.unsubscribe()
  }

  /**
   * Add workflow to list of selected workflows
   *
   * @param {WorkflowType} workflowType
   * @param {Event} $event
   * @memberof DeleteWorkflowsPage
   */
  selectWorkflowType(workflowType: WorkflowType, $event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedWorkflowTypes.push(workflowType)
    } else {
      pull(this.selectedWorkflowTypes, workflowType)
    }
  }

  /**
   * Add all visible workflows to list of selected workflows
   *
   * @param {Event} $event
   * @memberof DeleteWorkflowsPage
   */
  selectAllWorkflowTypes($event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedWorkflowTypes = this.workflowTypes
    } else {
      this.selectedWorkflowTypes = []
    }
  }

  /**
   * Launch modal to confirm removing selected workflows from claims
   *
   * @memberof DeleteWorkflowsPage
   */
  confirmWorkflowDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    let workflowsToDelete = uniq(
      compact(
        this.workflows.map((workflow) => {
          if (this.selectedWorkflowTypes.find((type) => type?.id === workflow?.workflowType?.id)) {
            return workflow.id
          }
        }),
      ),
    )
    modalRef.componentInstance.title = 'Delete Workflows?'
    modalRef.componentInstance.body = `This will delete ${workflowsToDelete?.length} workflow(s) from up to ${this.claims?.length} claims`
    modalRef.componentInstance.yes = 'Delete Workflows'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteWorkflows(workflowsToDelete)
      },
      (dismissed) => {},
    )
  }

  /**
   * Remove selected workflows from list of claims
   *
   * @private
   * @param {string[]} workflowsToDelete
   * @return {*}  {Promise<void>}
   * @memberof DeleteWorkflowsPage
   */
  private async deleteWorkflows(workflowsToDelete: string[]): Promise<void> {
    this.isDeleting = true
    try {
      let failedResults = []
      let results = flatten(
        await Promise.all(
          workflowsToDelete.map(async (workflowToDelete) =>
            this.workflowService.deleteWorkflow(workflowToDelete).then((result) => {
              if (!result) {
                failedResults.push(workflowToDelete)
              }
              return result
            }),
          ),
        ),
      )
      let successfulResults: number = results.filter((result) => {
        return result === true
      })?.length
      if (successfulResults === 0) {
        throw new Error('API returned all successes as false')
      } else if (successfulResults > 0 && failedResults.length) {
        this.toast.error(
          `Successfully deleted ${successfulResults} workflow(s), but failed to delete ${failedResults.length} workflow(s)`,
          failedResults.join(', '),
        )
      } else {
        this.toast.success(`Successfully deleted ${successfulResults} workflow(s) on claims`)
        this.cancel()
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete workflows on claims'), JSON.stringify(e))
    }
    this.isDeleting = false
  }

  /**
   * Cancel current removal and clear inputs
   *
   * @memberof DeleteWorkflowsPage
   */
  cancel(): void {
    this.claimIds.reset()
    this.workflows = []
    this.workflowTypes = []
    this.fetchedClaims = []
    this.selectedWorkflowTypes = []
  }
}
