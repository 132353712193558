import { EdgeConfig, GraphData, NodeConfig } from '@antv/g6'
import { WorkflowTypeTask } from 'generated/graphql'
import { uniqBy } from 'lodash'

const SHOW_COMPLETION_NODE = true
const UNIQUE_EDGES_ONLY = true

/**
 * Create G6 nodes and edges from a list of tasks & outcomes
 *
 * @param {WorkflowTypeTask[]} workflowTypeTasks
 * @return {*}  {GraphData}
 */
export function getGraphDataFromWorkflowTypeTasks(workflowTypeTasks: WorkflowTypeTask[]): GraphData {
  let nodes: NodeConfig[] = []
  let edges: EdgeConfig[] = []

  if (SHOW_COMPLETION_NODE) {
    nodes.push({
      id: 'complete',
      label: 'Complete Workflow',
    }) // TODO: style differently?
  }

  workflowTypeTasks?.forEach((task, taskIndex) => {
    nodes.push({
      id: taskIndex.toString(),
      label: task.taskType?.name,
    })
    if (task.outcomes?.length) {
      task.outcomes.forEach((outcome) => {
        let nextTaskIndex = outcome.nextWorkflowTypeTaskIndex?.toString()
        if (nextTaskIndex || outcome.isWorkflowTerminus) {
          edges.push({
            source: taskIndex.toString(),
            target: nextTaskIndex || 'complete',
          })
        }
      })
    } else if (SHOW_COMPLETION_NODE) {
      edges.push({ source: taskIndex.toString(), target: 'complete' })
    }
  })

  if (UNIQUE_EDGES_ONLY) {
    edges = uniqBy(edges, JSON.stringify)
  }

  return { nodes, edges }
}
