<app-loading
  *ngIf="isLoading"
  innerClass="fa-4x"
  outerClass="align-self-center text-brand-light-blue"
  class="d-flex flex-column flex-grow-1 justify-content-center"
></app-loading>
<div *ngIf="!isLoading" class="card detail-card-new bg-light d-flex">
  <div class="card-header d-flex justify-content-between">
    <h3 class="text-dark">{{ botJob?.name }}</h3>
    <div>
      <a
        *ngIf="authenticationService.isSuper()"
        class="btn btn-alt-steel-blue text-light mx-1"
        routerLink="/admin/bot-jobs/{{botJob?.id}}"
      >
        Edit Bot Job
      </a>
    </div>
  </div>
  <div class="card-body">
    <h5 class="text-dark ml-3 mb-4">Bot Job Details</h5>
    <app-bot-key-details [botJob]="botJob" [botRuns]="botRuns" [taskType]="taskType"></app-bot-key-details>
    <div class="d-flex justify-content-between mx-3 mb-4 mt-5">
      <h5 class="text-dark">Bot Run Outcomes</h5>
      <button type="button" class="btn btn-outline-alt-steel-blue ml-2" (click)="open()">
        <i class="fa fa-sliders"></i>
        <span *ngIf="botRunsService.filterBadge()" class="badge badge-light ml-2">
          {{ botRunsService.filterBadge() }}
        </span>
      </button>
    </div>
    <app-bot-runs-graph [botRuns]="botRuns"></app-bot-runs-graph>
  </div>
</div>
