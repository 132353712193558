<tr
  [ngSwitch]="config.rowType"
  class="text-center clickable"
  [ngClass]="{ 'teleport-available': teleportUrl }"
  (click)="launchTeleport()"
  (keydown.enter)="launchTeleport()"
  (keydown.space)="launchTeleport()"
  role="button"
  tabindex="0"
  [attr.aria-label]="'Launch Teleport for payer ' + config?.payer?.name"
  [ngbTooltip]="tooltip"
  container="body"
  placement="bottom"
>
  <ng-container *ngIf="!isTest; else testTemplate">
    <td class="teleport-icon rounded-left-lg">
      <app-loading *ngIf="isLoading"></app-loading>
      <app-janus-icon icon="teleport" *ngIf="!isLoading"></app-janus-icon>
    </td>
  </ng-container>
  <ng-template #testTemplate>
    <td class="test-teleport-icon rounded-left-lg">
      <app-loading *ngIf="isLoading"></app-loading>
      Test
    </td>
  </ng-template>
  <!-- Different row display options -->
  <ng-template [ngSwitchCase]="'procedureSet'">
    <td [ngClass]="{ test: isTest }">
      {{ config.serviceDates.startDate | date : 'MM/dd/yyyy' : '+0000'
      }}<span *ngIf="config.serviceDates.endDate && config.serviceDates.startDate !== config.serviceDates.endDate"
        >&ndash;{{ config.serviceDates.endDate | date : 'MM/dd/yyyy' : '+0000' }}</span
      >
    </td>
    <td [ngClass]="{ test: isTest }" class="text-left" *ngIf="config.rowType === 'procedureSet'">
      {{ config?.payer?.name || config?.typeDef?.payerPlan?.payerName }}
      <i
        *ngIf="config.docUploadRequired"
        class="fa fa-file-text text-danger py-2"
        style="padding-left: 5px"
        placement="top"
        ngbTooltip="Documents Required"
      ></i>
    </td>
    <td [ngClass]="{ test: isTest }">
      {{ config.epicReferralId ? config.epicReferralId : '' }}
    </td>
    <td [ngClass]="{ test: isTest }" class="rounded-right-lg">
      <div *ngFor="let procedure of (config | as : ProcedureSetRowConfig).typeDef.procedures">
        <ng-template [ngIf]="!['NA', 'NRC1', 'NRC2'].includes(procedure?.status)">
          {{ procedure?.code }}
          <ng-template [ngIf]="procedure?.description"> - {{ procedure.description }}</ng-template>
        </ng-template>
      </div>
    </td>
  </ng-template>
  <ng-template [ngSwitchCase]="'claim'">
    <td class="claim-pcid">{{ (config | as : ClaimRowConfig).typeDef.providerClaimId }}</td>
    <td>
      {{ config.serviceDates.startDate | date : 'MM/dd/yyyy'
      }}<span *ngIf="config.serviceDates.endDate && config.serviceDates.endDate !== config.serviceDates.startDate"
        >&ndash;{{ config.serviceDates.endDate | date : 'MM/dd/yyyy' }}</span
      >
    </td>
    <td class="text-left">{{ config?.payer?.name }}</td>
    <td>{{ (config | as : ClaimRowConfig).typeDef.outstandingAmount | currency }}</td>
    <td class="rounded-right-lg">{{ (config | as : ClaimRowConfig).typeDef.claimAmount | currency }}</td>
  </ng-template>
</tr>
