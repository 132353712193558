import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationEnd, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client'
import { TaskGroupCardActionsService } from 'app/admin/task-groups/task-group-card-actions.service'
import { ClaimsService } from 'app/claims/claims.service'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, Task, TaskGroup, TaskType } from 'generated/graphql'
import { chunk, compact, flatten, pull, uniq, uniqBy } from 'lodash'
import { Subscription } from 'rxjs'
import { filter, first, tap } from 'rxjs/operators'

/**
 * Page to allow assigning tasks to a task group(s)
 *
 * @export
 * @class TaskGroupAssignPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-group-assign-page',
  templateUrl: './assign.page.html',
  styleUrls: ['./assign.page.scss'],
})
export class TaskGroupAssignPage implements OnInit, OnDestroy {
  actionSub$: Subscription
  rightSub$: Subscription
  removeSub$: Subscription
  routerSub$: Subscription

  assign: TaskGroup[] = []
  assignCollapsed = []

  claimIds = new UntypedFormControl('')
  claimIds$: Subscription
  fetchedClaims: Claim[] = []

  taskGroups: TaskGroup[] = []
  taskGroupCollapsed = []
  tasks: Task[] = []
  taskTypes: TaskType[] = []
  selectedTaskTypes: TaskType[] = []

  saving: boolean = false
  fetchingTasks: boolean = false

  constructor(
    private router: Router,
    private actionService: TaskGroupCardActionsService,
    private taskGroupsService: TaskGroupsService,
    private claimsService: ClaimsService,
    private toast: ToastService,
  ) {
    this.routerSub$ = router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.onRouteChange()
    })
    this.actionSub$ = actionService.pencilClicked$.subscribe((taskGroup: TaskGroup) => {
      this.router.navigate([`admin/task-groups/edit/${taskGroup.id}`])
    })
    this.rightSub$ = actionService.rightClicked$.subscribe((taskGroup: TaskGroup) => {
      this.clearPreviousTaskGroup()
      let exists = this.taskGroups.findIndex((tg) => tg.id === taskGroup?.id)
      if (exists === -1) {
        this.taskGroups.push(taskGroup)
        this.taskGroupCollapsed.push(true)
      }
      this.actionService.hideCard(taskGroup, true)
    })
    this.removeSub$ = actionService.removeClicked$.subscribe((taskGroup: TaskGroup) => {
      let exists = this.taskGroups.findIndex((tg) => tg.id === taskGroup.id)
      if (exists > -1) {
        this.taskGroups.splice(exists, 1)
        this.taskGroupCollapsed.splice(exists, 1)
        this.actionService.hideCard(taskGroup, false)
      }
    })
    this.claimIds$ = this.claimIds.valueChanges
      .pipe(
        tap(async (ids: string) => {
          this.fetchingTasks = true
          if (ids?.length > 0) {
            let claims = compact(uniq(ids.split(/\t|\n|,/)).map((id) => (id?.trim().length ? id.trim() : null)))
            const fetchedClaims: ApolloQueryResult<{ claim: Claim }>[] = await Promise.all(
              claims.map((claimId) =>
                this.claimsService
                  .getClaim(claimId)
                  .pipe(first())
                  .toPromise()
                  .catch((e) => null),
              ),
            )
            this.fetchedClaims = compact(fetchedClaims.map((fc) => fc?.data?.claim))
            this.tasks = compact(flatten(this.fetchedClaims.map((claim) => claim?.tasks)))
            this.taskTypes = uniqBy(this.tasks, 'taskType.id').map((task) => task?.taskType)
          }
          this.fetchingTasks = false
        }),
      )
      .subscribe()
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.cancel()
    this.actionSub$?.unsubscribe()
    this.rightSub$.unsubscribe()
    this.removeSub$.unsubscribe()
    this.claimIds$.unsubscribe()
    this.routerSub$?.unsubscribe()
  }

  /**
   * Set list of claims from which to get tasks
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupAssignPage
   */
  async onRouteChange(): Promise<void> {
    let providerClaimIDs = this.router.getCurrentNavigation().extras?.state?.data?.providerClaimIDs
    if (providerClaimIDs) {
      this.claimIds.setValue(providerClaimIDs.join())
    }
  }

  /**
   * Cancel current assignment and clear all inputs
   *
   * @memberof TaskGroupAssignPage
   */
  cancel(): void {
    this.taskGroups = []
    this.claimIds.reset()
    this.actionService.hidden = []
    this.tasks = []
    this.taskTypes = []
    this.fetchedClaims = []
    this.selectedTaskTypes = []
  }

  /**
   * Assign selected task(s) to selected task group(s)
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupAssignPage
   */
  async save(): Promise<void> {
    this.saving = true
    try {
      let groupTasksToAssign: string[][] = chunk(
        compact(
          this.tasks.map((task) => {
            if (this.selectedTaskTypes.find((type) => type.id === task.taskType.id)) {
              return task.id
            }
          }),
        ),
        100,
      )
      await Promise.all(
        flatten(
          groupTasksToAssign.map((tasksToAssign) =>
            this.taskGroups.map((taskGroup) => this.taskGroupsService.addTasksToTaskGroup(tasksToAssign, taskGroup.id)),
          ),
        ),
      )
      this.toast.success('Tasks successfully assigned to task group')
      this.cancel()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not assign tasks to task group'), JSON.stringify(e))
    }

    this.saving = false
  }

  /**
   * Add task to list of selected tasks for assignment
   *
   * @param {TaskType} TaskType
   * @param {Event} $event
   * @memberof TaskGroupAssignPage
   */
  selectTaskType(TaskType: TaskType, $event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedTaskTypes.push(TaskType)
    } else {
      pull(this.selectedTaskTypes, TaskType)
    }
  }

  /**
   * Add all visible tasks to list of selected tasks for assignments
   *
   * @param {Event} $event
   * @memberof TaskGroupAssignPage
   */
  selectAllTaskTypes($event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedTaskTypes = this.taskTypes
    } else {
      this.selectedTaskTypes = []
    }
  }

  /**
   * Switch to which task group to assign tasks
   *
   * @memberof TaskGroupAssignPage
   */
  clearPreviousTaskGroup(): void {
    // This will likely be removed later once tasks and task groups can have many to many relationships
    if (this.taskGroups.length) {
      let currentTaskGroup = this.taskGroups[0]
      pull(this.taskGroups, currentTaskGroup)
      pull(this.taskGroupCollapsed, currentTaskGroup)
      this.actionService.hideCard(currentTaskGroup, false)
    }
  }

  /**
   * Select newly created task group as target of assignment
   *
   * @param {*} $event
   * @memberof TaskGroupAssignPage
   */
  assignCreatedTaskGroup($event: TaskGroup): void {
    this.clearPreviousTaskGroup()
    this.taskGroups.push($event)
    this.taskGroupCollapsed.push(true)
    this.actionService.hideCard($event, true)
  }
}
