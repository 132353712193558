import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-annotation-builder',
  templateUrl: './annotation-builder.component.html',
  styleUrls: ['./annotation-builder.component.scss'],
})
export class AnnotationBuilderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
