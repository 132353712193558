import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { ListResponseMetaData } from 'generated/graphql'

export type PaginationUpdateEvent = {
  newPageNumber: number
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements OnChanges {
  @Input() metaData: ListResponseMetaData = null
  @Input() isTotalApproximate: boolean = false
  @Output() fetchPage = new EventEmitter<PaginationUpdateEvent>()

  currentPageNumber: number = 1

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metaData) {
      const newMeta: ListResponseMetaData = changes.metaData.currentValue
      this.currentPageNumber = Math.ceil(newMeta.offset / newMeta.limit) + 1
    }
  }

  get maximumNumberOfPages(): number {
    return Math.ceil(this.metaData.total / this.metaData.limit)
  }

  /**
   * Decrements the current page. Doesn't protect against negative pages.
   */
  previousPage(): void {
    this.currentPageNumber -= 1
    this.fetchPage.emit({ newPageNumber: this.currentPageNumber })
  }

  /**
   * Increments the current page. Doesn't protect against incrementing past the
   * last page.
   */
  nextPage(): void {
    this.currentPageNumber += 1
    this.fetchPage.emit({ newPageNumber: this.currentPageNumber })
  }
}
