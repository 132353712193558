import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Procedure } from 'generated/graphql'

@Component({
  selector: 'app-contact-payer-modal',
  templateUrl: './contact-payer-modal.component.html',
})
export class ContactPayerModalComponent implements OnInit {
  constructor(private modal: NgbActiveModal, private fb: FormBuilder) {}
  procedures: Procedure[] = []
  tempProcedures: Procedure[] = []
  @Input() procedureSetId: string
  form: FormGroup

  onSubmit(): void {
    this.modal.close(this.tempProcedures)
  }

  cancel(): void {
    this.modal.close()
  }

  ngOnInit(): void {
    const rawProcedures = JSON.parse(JSON.stringify(this.procedures)).sort(
      (a, b) => parseInt(a.code) - parseInt(b.code),
    )
    this.tempProcedures = rawProcedures
  }
}
