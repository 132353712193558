import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateBotJobV2,
  DeleteBotJobV2,
  GetBotJobV2,
  GetBotJobsV2,
  UpdateBotJobV2,
} from 'app/admin/bot-jobs/bot-jobs.gql'
import { AuthenticationService } from 'app/auth/authentication.service'
import {
  BotJob,
  BotJobList,
  Claim,
  CreateBotJobInput,
  ListResponseMetaData,
  MutationCreateBotJobV2Args,
  MutationDeleteBotJobV2Args,
  MutationUpdateBotJobV2Args,
  QueryBotJobArgs,
  QueryBotJobsV2Args,
  UpdateBotJobInput,
} from 'generated/graphql'
import { from, Observable } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { PayerPlanService } from '../../shared/services/payer-plan.service'

/**
 * Handle bot job operations
 *
 * @export
 * @class BotJobsService
 */
@Injectable({
  providedIn: 'root',
})
export class BotJobsService {
  private meta: ListResponseMetaData

  constructor(
    private apollo: Apollo,
    private authenticationService: AuthenticationService,
    private payerPlanService: PayerPlanService,
  ) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof BotJobsService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve a single bot job
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ botJob: BotJob }>>}
   * @memberof BotJobsService
   */
  getBotJob(id: string): Observable<ApolloQueryResult<{ botJobV2: BotJob }>> {
    return this.apollo.watchQuery<{ botJobV2: BotJob }, QueryBotJobArgs>({
      fetchPolicy: 'no-cache',
      query: GetBotJobV2,
      variables: {
        id,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of bot jobs based on criteria
   */
  getBotJobs(
    offset?: number,
    payerOrPayerPlanId?: string,
    taskTypeId?: string,
    search?: string,
    onlyActive?: boolean,
    orgId?: string,
    noCache?: boolean,
    limit: number = 50,
  ): Observable<ApolloQueryResult<{ botJobsV2: BotJobList }>> {
    return this.apollo
      .watchQuery<{ botJobsV2: BotJobList }, QueryBotJobsV2Args>({
        query: GetBotJobsV2,
        variables: {
          limit,
          offset,
          payerOrPayerPlanId,
          taskTypeId,
          search,
          onlyActive,
          orgId,
        },
        fetchPolicy: noCache ? 'network-only' : 'cache-and-network',
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.botJobsV2?.meta
        }),
      )
  }

  /**
   * Create a new bot job
   *
   * @param {CreateBotJobInput} data
   * @return {*}  {Promise<BotJob>}
   * @memberof BotJobsService
   */
  async createBotJob(data: CreateBotJobInput, payerPlanIds?: string[]): Promise<BotJob> {
    let result = await this.apollo
      .mutate<{ createBotJobV2: BotJob }, MutationCreateBotJobV2Args>({
        mutation: CreateBotJobV2,
        variables: { data, payerPlanIds },
      })
      .toPromise()

    return result.data?.createBotJobV2
  }

  /**
   * Update an existing bot job
   *
   * @param {string} id
   * @param {UpdateBotJobInput} data
   * @return {*}  {Promise<BotJob>}
   * @memberof BotJobsService
   */
  async updateBotJob(id: string, data: UpdateBotJobInput, payerPlanIds?: string[]): Promise<BotJob> {
    let result = await this.apollo
      .mutate<{ updateBotJobV2: BotJob }, MutationUpdateBotJobV2Args>({
        mutation: UpdateBotJobV2,
        variables: { id, data, payerPlanIds },
      })
      .toPromise()

    return result.data?.updateBotJobV2
  }

  /**
   * Delete an existing bot job
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof BotJobsService
   */
  async deleteBotJob(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ deleteBotJobV2: boolean }, MutationDeleteBotJobV2Args>({
        mutation: DeleteBotJobV2,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data?.deleteBotJobV2
  }

  /**
   * @deprecated Will be using PayerPlan model in the future
   */
  getTeleportJobForPayerId(payerId: string): Observable<BotJob> {
    const user = this.authenticationService.getUser()
    return this.getBotJobs(0, payerId, null, null, true, user.orgId, true).pipe(
      map((resp) => {
        return resp?.data?.botJobsV2?.entities.find(
          // Note - payerId check is redundant but legacy, remove when confirmed
          (bot) => bot.payerIds.includes(payerId) && bot.teleportEnabled === true,
        )
      }),
    )
  }
  getTeleportJobForPayerPlanId(payerPlanId: string): Observable<BotJob> {
    if (!payerPlanId) {
      throw new Error('Missing payerPlanId')
    }

    const user = this.authenticationService.getUser()
    return this.getBotJobs(0, payerPlanId, null, null, true, user.orgId, true).pipe(
      map((resp) => {
        return (resp?.data?.botJobsV2?.entities || []).find((b) => b.teleportEnabled === true)
      }),
    )
  }
  botJobFromClaim({ claim }: { claim: Claim }) {
    return from(this.payerPlanService.getLegacyEnabled()).pipe(
      switchMap((isLegacy) => {
        const payerId = claim.payer?.parentId || claim.payer?.id
        return isLegacy ? this.getTeleportJobForPayerId(payerId) : this.getTeleportJobForPayerPlanId(claim.payerPlanId)
      }),
    )
  }
}
