<div class="container d-flex flex-column min-vh-100" *ngIf="!isLoading; else loadingBlock">
  <div class="row d-flex justify-content-center my-5">
    <div class="col-md-4 col-8 d-flex justify-content-center">
      <div class="px-4" id="janus-logo">
        <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" height="50px" />
      </div>
      <div class="px-4" id="teleport-logo">
        <img src="/assets/img/teleport-logo.svg" height="50px" />
      </div>
    </div>
  </div>
  <div class="row text-alt-light-gray">
    <div class="col-8 offset-2 py-3">
      <p>
        Welcome to Teleport!
        <a
          href="https://www.janus-ai.com/platforms/teleport"
          target="_blank"
          class="text-underline"
          ngbTooltip="www.janus-ai.com/platforms/teleport"
          placement="bottom"
          container="body"
          >Teleport</a
        >
        automates the process of logging in and navigating payer portals. It will take you directly to the Claim Status
        page for a selected claim.
      </p>
      <p>
        Below is a list of active claims for account <span class="text-alt-light-blue">#{{harId}}</span>. Please select
        a claim:
      </p>
    </div>
  </div>
  <div class="row mx-n2">
    <div class="col-6 px-2">
      <div id="name-container" class="bg-alt-green rounded-lg px-4 py-2">
        <div class="text-brand-dark-gray text-uppercase fs-12">Patient Name</div>
        <div class="text-alt-white fs-18">{{patientName || '&nbsp;'}}</div>
      </div>
    </div>
    <div class="col-6 px-2">
      <div id="har-container" class="bg-alt-baby-blue rounded-lg px-4 py-2">
        <div class="text-brand-dark-gray text-uppercase fs-12">Account</div>
        <div class="text-alt-dark-blue fs-18">#{{harId}}</div>
      </div>
    </div>
  </div>
  <div class="row no-gutters justify-content-center my-4">
    <app-teleport-link-table
      [type]="'harId'"
      [listId]="harId"
      [billingModules]="acceptedBillingModules"
    ></app-teleport-link-table>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
<ng-template #loadingBlock>
  <div class="min-vh-100 d-flex align-items-center justify-content-center">
    <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
  </div>
</ng-template>
