import { Location } from '@angular/common'
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { QueryParamsHandling, Router } from '@angular/router'
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ClaimsForClaimList } from 'app/claims/claims-list/claims-list.gql'
import { ClaimsService } from 'app/claims/claims.service'
import { FavoriteClaimsService } from 'app/claims/favorite-claims.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { Facet } from 'app/shared/types/facet'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, UserRole } from 'generated/graphql'
import { defer, isString, trim } from 'lodash'
import { ReplaySubject, Subject } from 'rxjs'
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators'
import { AuthorizationService } from '../authorization.service'
import { Permissions } from '../types'

interface MenuLink {
  type: 'link' | 'menu'
  label: string
  routerLink?: string
  queryParamsHandling?: QueryParamsHandling
  children?: MenuLink[]
  // visible gets priority over feature flag
  visible?: boolean
  featureFlag?: string
}

/**
 * Component to display the top header + navigation
 *
 * @export
 * @class HeaderMenuComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  menu: MenuLink[] = []
  favoriteClaims: Claim[] = []

  isLoadingFavorites: boolean = false

  isFavoritesEnabled: boolean = false
  isIlluminateWorkforceManagementEnabled: boolean = false
  isIlluminateDenialsDashboardEnabled: boolean = false
  isIlluminateUsageInsightsDashboardEnabled: boolean = false
  isThroughputReportEnabled: boolean = false
  isProcessMapsEnabled: boolean = false
  isUsingNewPermissionSystem: boolean = false
  featureFlags = FeatureFlags

  globalSearchQuery = new UntypedFormControl('')
  globalSearchResults$ = new ReplaySubject<{ query: string; results: Claim[] }>(1)
  isSearching: boolean = false

  isCollapsed = true

  destroy$ = new Subject<void>()

  @ViewChild('globalSearch') globalSearch: NgbDropdown
  @ViewChild('claimsSearch') claimsSearch: ElementRef
  focus$ = new Subject<string>()
  foundClaims: Claim[] = []

  /**
   * Get the label for WFM productivity insights. If usage insights dashboard is enabled
   * name it differently than if it is not enabled.
   */
  getProductivityLabel(): string {
    return this.isIlluminateUsageInsightsDashboardEnabled ? 'WFM: Productivity Insights' : 'Workforce Management'
  }

  constructor(
    private location: Location,
    public authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private favs: FavoriteClaimsService,
    private toast: ToastService,
    private featureFlagService: FeatureFlagService,
    private orgService: OrgService,
    private claimsService: ClaimsService,
    private router: Router,
    private facetService: FacetedSearchService,
    public breadcrumbService: BreadcrumbsService,
    protected zendesk: ZendeskService,
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
      return
    }

    if (event.keyCode === 191) {
      this.globalSearch.isOpen() ? this.globalSearch.close() : this.globalSearch.open()
    }
  }

  async ngOnInit(): Promise<void> {
    this.globalSearchQuery.valueChanges
      .pipe(
        debounceTime(250),
        filter((query) => trim(query).length > 0),
        tap((query) => {
          debug('globalSearch', 'pre-fetching search results', query)
          this.isSearching = false
        }),
        switchMap((query) =>
          this.claimsService
            .getClaims(ClaimsForClaimList, {
              search: `(providerClaimId: (*${query}*))`,
            })
            .then((results) => {
              debug('globalSearch', 'got results', results)
              return { query, results }
            })
            .catch((e) => debug('claims', 'Error when searching claims', e)),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((searchResults) => {
        if (searchResults) {
          this.globalSearchResults$.next(searchResults)
        }
      })

    this.isFavoritesEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.favorites)

    if (this.isFavoritesEnabled) {
      this.favs
        .getFavoriteClaims()
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          this.isLoadingFavorites = event.loading
          if (event.error) {
            this.toast.error(parseGraphQLError(event, 'Could not load favorite claims'), JSON.stringify(event.error))
          }
          if (event.data) {
            this.favoriteClaims = event.data.favoriteClaims.entities
          }
        })
    }

    // eslint-disable-next-line
    ;[
      this.isIlluminateWorkforceManagementEnabled, // ui.illuminate.workforceManagement
      this.isIlluminateDenialsDashboardEnabled, // ui.illuminate.denialsDashboard
      this.isIlluminateUsageInsightsDashboardEnabled, // ui.illuminate.usageInsights
      this.isThroughputReportEnabled, // ui.throughputReport
      this.isUsingNewPermissionSystem, // auth.authorization.v2023
      this.isProcessMapsEnabled, // ui.processMaps
    ] = await Promise.all([
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.illuminate.workforceManagement),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.illuminate.denialsDashboard),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.illuminate.usageInsights),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.throughputReport),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.auth.authorization.v2023),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.processMaps),
    ])

    // Each link has own visibiliy logic configurable with it
    this.menu = [
      {
        label: 'Dashboard',
        routerLink: 'dashboard',
        type: 'link',
        featureFlag: FeatureFlags.ui.dashboard,
      },
      {
        label: 'Operational Intelligence',
        type: 'menu',
        visible: this.isOperationalIntelligenceLinkEnabled(),
        children: [
          {
            label: 'Process Intelligence',
            routerLink: 'illuminate/opportunities',
            type: 'link',
            featureFlag: FeatureFlags.ui.illuminate.root,
            visible: this.orgService.isDemo(),
          },
          {
            label: this.getProductivityLabel(),
            routerLink: 'illuminate/workforce-management',
            type: 'link',
            featureFlag: FeatureFlags.ui.illuminate.workforceManagement,
          },
          {
            label: 'WFM: Usage Insights',
            routerLink: 'illuminate/usage-insights',
            type: 'link',
            featureFlag: FeatureFlags.ui.illuminate.usageInsights,
          },
          {
            label: 'Denials Insights',
            routerLink: 'illuminate/denials',
            type: 'link',
            featureFlag: FeatureFlags.ui.illuminate.denialsDashboard,
          },
          {
            label: 'Process Maps',
            routerLink: 'process-maps',
            type: 'link',
            featureFlag: FeatureFlags.ui.processMaps,
          },
        ],
      },
      {
        label: 'VisionX',
        type: 'menu',
        visible: await this.canSeeVisionX(),
        children: [
          {
            label: 'Dashboard',
            routerLink: 'vx',
            queryParamsHandling: 'merge',
            type: 'link',
            visible: await this.canSeeVxDashboard(),
          },
          {
            label: 'Document Manager',
            routerLink: 'vx/manager',
            queryParamsHandling: 'merge',
            type: 'link',
            visible: await this.canSeeVxDocumentManager(),
          },
          {
            label: 'Document Types',
            routerLink: 'vx/document-types',
            type: 'link',
            visible: await this.canSeeVxDocumentTypes(),
          },
          { label: 'Page Types', routerLink: 'vx/page-types', type: 'link', visible: await this.canSeeVxPageTypes() },
          {
            label: 'Rule Editor',
            routerLink: 'visionx-rule-editor',
            type: 'link',
            visible: await this.canSeeVxRuleEditor(),
          },
          {
            label: 'Shipment Manager',
            routerLink: 'vx/shipment-manager',
            type: 'link',
            visible: await this.canSeeVxShipmentManager(),
          },
        ],
      },
      {
        label: 'Reports',
        type: 'menu',
        visible: await this.canSeeReportsHeader(),
        children: [
          {
            label: 'Throughput Report',
            type: 'link',
            visible: await this.canSeeThroughputReport(),
            routerLink: 'revbots/throughput',
          },
          {
            label: 'General Reports',
            type: 'link',
            visible: await this.canSeeGeneralReports(),
            routerLink: 'reports',
          },
        ],
      },
      {
        label: 'Admin',
        visible: await this.canSeeAdmin(),
        type: 'menu',
        children: [
          { label: 'Admin', routerLink: 'admin', type: 'link' },
          { label: 'Claims', routerLink: 'claims', type: 'link' },
          { label: 'Reporting', routerLink: 'reporting', type: 'link', visible: await this.canSeeReporting() },
        ],
      },
    ]
  }

  async canSeeAdmin(): Promise<boolean> {
    const result = await this.authorizationService.hasPermissionsOrRoles({
      permissions: [Permissions.system.admin.all],
      roles: [UserRole.Admin],
    })

    return result
  }

  async canSeeVisionX(): Promise<boolean> {
    return this.authorizationService.hasPermissions(
      [
        Permissions.visionx.dashboard.view,
        Permissions.visionx.documentManager.view,
        Permissions.visionx.documentTypes.view,
        Permissions.visionx.pageTypes.view,
        Permissions.visionx.ruleEditor.view,
      ],
      { conditionType: 'OR' },
    )
  }

  async canSeeVxDashboard(): Promise<boolean> {
    return this.authorizationService.hasPermissions([Permissions.visionx.dashboard.view])
  }

  async canSeeVxDocumentManager(): Promise<boolean> {
    return this.authorizationService.hasPermissions([Permissions.visionx.documentManager.view])
  }

  async canSeeVxShipmentManager(): Promise<boolean> {
    return this.authorizationService.hasPermissions([Permissions.visionx.shipmentManager.view])
  }

  async canSeeVxDocumentTypes(): Promise<boolean> {
    return this.authorizationService.hasPermissions([Permissions.visionx.documentTypes.view])
  }

  async canSeeVxPageTypes(): Promise<boolean> {
    return this.authorizationService.hasPermissions([Permissions.visionx.pageTypes.view])
  }

  async canSeeVxRuleEditor(): Promise<boolean> {
    return await this.authorizationService.hasPermissions([Permissions.visionx.ruleEditor.view])
  }

  async canSeeReportsHeader(): Promise<boolean> {
    const [canSeeThroughput, canSeeGeneral] = await Promise.all([
      this.canSeeThroughputReport(),
      this.canSeeGeneralReports(),
    ])
    return canSeeThroughput || canSeeGeneral
  }

  async canSeeThroughputReport(): Promise<boolean> {
    return (
      this.isThroughputReportEnabled &&
      (await this.authorizationService.hasPermissionsOrRoles({
        permissions: [],
        roles: [UserRole.Admin, UserRole.Executive],
      }))
    )
  }

  async canSeeGeneralReports(): Promise<boolean> {
    return (
      !this.orgService.isDemo() &&
      (await this.authorizationService.hasPermissionsOrRoles({
        permissions: [Permissions.system.report.all],
        roles: [UserRole.Admin, UserRole.Manager],
      }))
    )
  }

  async canSeeReporting(): Promise<boolean> {
    return await this.authorizationService.hasPermissionsOrRoles({
      permissions: [Permissions.system.reporting.all],
      roles: [UserRole.Admin, UserRole.Manager],
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Show the main Operational Intelligence header link if any of the operational intelligence dashboards are enabled
   * and the user is in the allowed roles array
   */
  isOperationalIntelligenceLinkEnabled(): boolean {
    const allowedOpIntelRoles = [UserRole.Admin, UserRole.Executive]

    const hasRole = allowedOpIntelRoles.some((role) => this.authenticationService.checkUserRole(role))

    const isChildLinkEnabled =
      this.isIlluminateDenialsDashboardEnabled ||
      this.isIlluminateUsageInsightsDashboardEnabled ||
      this.isIlluminateWorkforceManagementEnabled ||
      this.isProcessMapsEnabled

    return hasRole && isChildLinkEnabled
  }

  /**
   * Helper to identify currently active link
   *
   * @param {string} link
   * @memberof HeaderMenuComponent
   */
  isLinkActive = (link: string | MenuLink[]): boolean => {
    let isActive = false
    let topLink = this.location.path().split('/')[1]

    if (isString(link)) {
      isActive = topLink === (link as string)
    } else {
      let mapped = (link as MenuLink[]).map((hl) => hl[Object.keys(hl)[0]])
      isActive = mapped.includes(topLink)
    }
    return link.length && isActive
  }

  /**
   * Focus the search field when global search is entered
   *
   * @param {boolean} opened
   * @memberof HeaderMenuComponent
   */
  onGlobalSearchToggled(opened: boolean): void {
    if (opened) {
      defer(() => this.claimsSearch.nativeElement.focus())
    }
  }

  /**
   * Set search string as the providerClaimId facet
   * and navigate to claim list
   *
   * @memberof HeaderMenuComponent
   */
  onSearchClaim(): void {
    this.isSearching = true
    let currentQuery = this.globalSearchQuery.value
    debug('globalSearch', 'user pressed enter', currentQuery)
    this.globalSearchResults$
      .pipe(
        filter((searchResults) => searchResults.query === currentQuery),
        takeUntil(this.globalSearchQuery.valueChanges),
      )
      .subscribe((searchResults) => {
        debug('globalSearch', 'using results', currentQuery, searchResults)
        if (searchResults.results.length === 1) {
          this.router.navigate(['/claims', searchResults.results[0].providerClaimId], {
            queryParams: {
              billing_module: searchResults.results[0].billingModule,
            },
          })
        } else {
          this.facetService.setFacets([
            {
              id: 'providerClaimId',
              selected: [{ value: `*${searchResults.query}*`, display: `*${searchResults.query}*` }],
            },
          ] as Facet[])
          window.sessionStorage.setItem(
            'claimsSearch',
            `[{"id":"providerClaimId","selected":[{"value":"*${searchResults.query}*","display":"*${searchResults.query}*"}]}]`,
          )
          this.router.navigate(['/claims'])
        }
        this.globalSearch.close()
        this.globalSearchQuery.setValue('')
      })
    this.isSearching = false
  }
}
