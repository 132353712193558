<div class="container d-flex flex-column min-vh-100">
  <div class="row justify-content-around my-5">
    <div class="col-lg-4 col-sm-8">
      <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" class="img-fluid" />
    </div>
  </div>

  <div class="row justify-content-around">
    <div class="col-lg-6">
      <div class="card login-wrapper py-5 px-4 d-flex align-items-center shadow">
        <h4 class="text-dark mt-2 mb-4">Password Reset</h4>
        <form *ngIf="!success" [formGroup]="form" (ngSubmit)="submit()" novalidate class="w-75 d-flex flex-column">
          <div class="d-flex justify-content-center">
            <div *ngIf="error" class="d-flex alert alert-danger my-1 flex-column text-center" role="alert">
              Oops, something went wrong. To report an issue, please submit a ticket at
              <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
            </div>
          </div>
          <label for="pass1" class="text-muted text-center my-4" *ngIf="!error"> Please enter a new password. </label>
          <div class="input-group form-group mt-4 mb-5">
            <input
              id="pass1"
              [type]="passType"
              formControlName="pass1"
              class="form-control py-4"
              data-t="pass1"
              [appFcBsValidation]="form.get('pass1')"
            />
            <div class="input-group-append">
              <span class="in-input-icon d-flex align-items-center">
                <button type="button" class="btn btn-link bg-transparent" (click)="togglePassType()">
                  <i
                    class="fa fw fa-lg text-reset"
                    [class.fa-eye]="passType === 'password'"
                    [class.fa-eye-slash]="passType === 'text'"
                  ></i>
                </button>
              </span>
            </div>
          </div>
          <app-password-validator [password]="form.get('pass1')" [token]="token"></app-password-validator>
          <button
            class="btn btn-primary py-4 btn-block border-0 text-light text-uppercase mt-3"
            type="submit"
            [disabled]="!form.valid"
          >
            Submit
            <app-loading *ngIf="saving || form.get('pass1').status === 'PENDING'" class="pl-2"></app-loading>
          </button>
        </form>
        <div *ngIf="success" class="col-lg-10 d-flex flex-column">
          <div class="d-flex alert alert-success my-5" role="alert">Your password has successfully been changed!</div>
          <a class="btn btn-alt-steel-blue text-light btn-block mb-4" routerLink="/login">Sign In</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
