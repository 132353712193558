import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { ToastService } from 'app/shared/services/toast.service'
import { Selected } from 'app/shared/types/facet'
import { facetsToClaimQueryParams } from 'app/shared/utils/facets-to-params'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { SavedSearch, SaveSearchInput } from 'generated/graphql'

/**
 * Modal to save a faceted search
 *
 * @export
 * @class SaveSearchComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-save-search',
  templateUrl: './save-search.component.html',
  styleUrls: ['./save-search.component.scss'],
  host: {
    'data-t': 'save-search-modal',
  },
})
export class SaveSearchComponent implements OnInit {
  @Input() savedSearch: SavedSearch = null

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    saveToTaskGroup: new UntypedFormControl(false),
    newSearch: new UntypedFormControl(true),
  })

  saving: boolean = false

  /**
   * Helper to check if non-admins can overwrite a saved search that
   * assigns creates & assigns tasks to task groups
   *
   * @readonly
   * @type {boolean}
   * @memberof SaveSearchComponent
   */
  get canSaveExistingSearch(): boolean {
    return !this.savedSearch?.assignToTaskGroupId || this.authenticationService.canAccessAdmin()
  }

  constructor(
    private router: Router,
    public modal: NgbActiveModal,
    private toast: ToastService,
    private facetService: FacetedSearchService,
    public authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    if (this.savedSearch) {
      this.form.get('name').setValue(this.savedSearch.name)
      this.form.get('description').setValue(this.savedSearch.description)
      this.form.get('saveToTaskGroup').setValue(this.savedSearch.assignToTaskGroupId ? true : false)

      if (!this.canSaveExistingSearch) {
        this.form.get('newSearch').setValue(true)
        this.form.get('newSearch').disable({ onlySelf: true })
        this.form.get('name').setValue('')
      } else {
        this.form.get('newSearch').setValue(false)
      }
    }
  }

  /**
   * Save the current search
   *
   * @return {*}  {Promise<SavedSearch>}
   * @memberof SaveSearchComponent
   */
  async saveSearch(): Promise<SavedSearch> {
    if (this.form.invalid) {
      return
    }

    this.saving = true

    let facets = this.facetService.facets$.value.filter((f) => f?.selected?.length > 0)
    let facetsInput = facets.map((facet) => {
      let selected = facet.selected.map((s: Selected) => ({
        value: `${s.value}`,
        display: s.display,
        displayPill: s.displayPill,
      }))
      return { facet: facet.id, title: facet.title, selected: selected }
    })

    let { search, scripts } = facetsToClaimQueryParams(facets)

    let obj: SaveSearchInput = {
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      saveToTaskGroup: this.form.get('saveToTaskGroup').value,
      facets: facetsInput,
      query: search,
      scripts,
    }

    try {
      let search = await this.facetService.saveSearch(
        obj,
        this.form.get('newSearch').value ? null : this.savedSearch.id,
      )
      this.modal.close(search)
      this.toast.success('Saved search')
      return search
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save search'), JSON.stringify(e))
    }

    this.saving = false
  }
}
