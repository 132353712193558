import { Component, Input, OnChanges } from '@angular/core'
import { UsersService } from 'app/shared/services/users.service'
import { TaskGroup, User } from 'generated/graphql'

/**
 * Component to display task group overview info
 * used in tasks.page
 *
 * @export
 * @class TaskGroupOverviewComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-task-group-overview',
  templateUrl: './task-group-overview.component.html',
  styleUrls: ['./task-group-overview.component.scss'],
  host: {
    '[class.d-flex]': 'true',
    '[class.flex-column]': 'true',
  },
})
export class TaskGroupOverviewComponent implements OnChanges {
  @Input() taskGroup: TaskGroup = null

  users: User[] = null

  constructor(private userService: UsersService) {}

  async ngOnChanges(): Promise<void> {
    if (this.taskGroup.assignedToUserIds?.length) {
      try {
        this.users = await this.userService.getUsers('', this.taskGroup.assignedToUserIds)
      } catch (e) {
        this.users = []
      }
    }
  }
}
