<div class="d-flex flex-column">
  <div class="d-flex flex-column">
    <span>Raw 278:</span>
    <textarea #raw278 rows="3" placeholder="Paste raw 278 message here..." autofocus></textarea>
    <button class="btn btn-primary mt-4 mb-4 ml-auto" (click)="parseEdi(raw278.value)">Format</button>
  </div>

  <div class="d-flex">
    <div class="border border-dark parsed-info" [innerHTML]="formattedText"></div>
    <div class="border border-dark parsed-info" [innerHTML]="parsedText"></div>
  </div>
</div>