import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import G6, { Combo, Edge, Graph, GraphData, GraphOptions, Node, TreeGraphData } from '@antv/g6'
import { debug } from 'app/shared/utils/debug'
import { defer } from 'lodash'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { debounceTime, first, skip, takeUntil } from 'rxjs/operators'

interface Dimensions {
  width: number
  height: number
}

/**
 * Component to display a G6 DAG graph
 *
 * @export
 * @class DagComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-dag',
  templateUrl: './dag.component.html',
  styleUrls: ['./dag.component.scss'],
  host: {
    class: 'd-block',
  },
})
export class DagComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  destroyed$ = new Subject<void>()
  dimensions$ = new BehaviorSubject<Dimensions>(null)
  data$ = new BehaviorSubject<GraphData>(null)

  draggedNode: Node = null

  graph: Graph
  @Input() setMode: string
  @Input() graphData: GraphData
  @Output() graphDataChange = new EventEmitter<GraphData | TreeGraphData>()
  @Output() graphChange = new EventEmitter<Graph>()

  @Input() graphOptions: Partial<GraphOptions>
  @Input() selectedNodeId: string
  @Input() combinedNodeIds: string[]
  @Input() selectedComboId: string
  @Output() onSelection = new EventEmitter<Node[]>()
  @Output() onBrushSelection = new EventEmitter<{ nodes: Node[]; edges: Edge[] }>()
  @Output() onComboToggle = new EventEmitter<Combo>()
  @Output() onNodeDoubleClick = new EventEmitter<Node>()

  @HostListener('window:resize')
  onResize(): void {
    this.getDimensions()
  }

  @ViewChild('graphContainer', { static: false }) graphContainer: ElementRef

  constructor(public elementRef: ElementRef, private zone: NgZone) {}

  ngOnInit(): void {
    let computedStyle = getComputedStyle(document.body)
    G6.registerNode(
      'workflowTask',
      {
        drawShape(cfg, group) {
          const rect = group.addShape('rect', {
            attrs: {
              x: -100,
              y: -25,
              width: 200,
              height: 50,
              radius: 2,
              stroke: computedStyle.getPropertyValue('--alt-baby-blue'),
              fill: computedStyle.getPropertyValue('--alt-barely-blue'),
              lineWidth: 3,
            },
            name: 'rect-shape',
          })
          if (cfg.name) {
            group.addShape('text', {
              attrs: {
                text: cfg.name,
                x: 0,
                y: 0,
                fill: '#555A5F',
                fontSize: 14,
                textAlign: 'center',
                textBaseline: 'middle',
                fontWeight: 'bold',
              },
              name: 'text-shape',
            })
          }
          return rect
        },
      },
      'single-node',
    )
    G6.registerNode(
      'componentTask',
      {
        drawShape(cfg, group) {
          const rect = group.addShape('rect', {
            attrs: {
              x: -100,
              y: -25,
              width: 200,
              height: 50,
              radius: 2,
              stroke: computedStyle.getPropertyValue('--alt-yellow'),
              fill: computedStyle.getPropertyValue('--alt-barely-yellow'),
              lineWidth: 3,
            },
            name: 'rect-shape',
          })
          if (cfg.name) {
            group.addShape('text', {
              attrs: {
                text: cfg.name,
                x: 0,
                y: 0,
                fill: '#555A5F',
                fontSize: 14,
                textAlign: 'center',
                textBaseline: 'middle',
                fontWeight: 'bold',
              },
              name: 'text-shape',
            })
          }
          return rect
        },
      },
      'single-node',
    )

    G6.registerBehavior('add-node-in-place', {
      getEvents() {
        return {
          'edge:drop': 'onEdgeDrop',
          'node:dragstart': 'setDraggedNode',
        }
      },

      onEdgeDrop: (event) => {
        let edge: Edge = event.item
        let node: Node = this.draggedNode
        if (node && node?.getEdges()?.length === 0 && edge) {
          let oldSource = edge.getSource()
          let oldTarget = edge.getTarget()

          this.graph.removeItem(edge)
          this.graph.addItem('edge', {
            source: oldSource,
            target: node,
          })
          this.graph.addItem('edge', {
            source: node,
            target: oldTarget,
          })
          this.graphDataChange.emit(this.graph.save())
          this.graph.read(this.graphData)
          this.graph.setMode('default')
        }
      },

      setDraggedNode: (event) => {
        this.draggedNode = event.item
      },
    })
  }

  ngAfterViewInit(): void {
    defer(() => this.getDimensions()) // wait for current call stack to complete (finish rendering full view)

    // initialize graph once we have everything we need
    combineLatest([this.dimensions$, this.data$])
      .pipe(first())
      .subscribe(([dimensions, data]) => {
        debug('dag', 'initializing graph with', { dimensions, data })
        this.graph = new Graph({
          container: this.graphContainer.nativeElement,
          ...dimensions,
          ...this.graphOptions,
          modes: {
            default: [
              {
                type: 'click-select',
                multiple: false,
                shouldBegin: (e) => {
                  return e.item.getModel().id !== 'start' && e.item.getModel().id !== 'end'
                },
              },
              { type: 'zoom-canvas' },
              { type: 'drag-canvas' },
            ],
            selectMultiple: [
              {
                type: 'click-select',
                multiple: true,
                shouldBegin: (e) => {
                  return e.item.getModel().id !== 'start' && e.item.getModel().id !== 'end'
                },
              },
              { type: 'zoom-canvas' },
              { type: 'drag-canvas' },
            ],
            newNode: [
              { type: 'drag-node' },
              { type: 'zoom-canvas' },
              { type: 'drag-canvas' },
              { type: 'add-node-in-place' },
            ],
            comboDragCreate: [
              {
                type: 'brush-select',
                trigger: 'drag',
                // @ts-ignore
                brushStyle: {
                  fill: 'yellow',
                  fillOpacity: 0.25,
                },
              },
            ],
            processWorkflow: [
              { type: 'zoom-canvas' },
              { type: 'drag-canvas' },
              // { type: 'activate-relations', trigger: 'click', activeState: 'selected' },
            ],
          },
        })
        if (data) {
          this.graph.read(data)
        }
        this.graphChange.emit(this.graph)
        //this.graph.render()
        this.setSelectedNodeState()
        this.setCombinedNodesState()
        this.graph.on('nodeselectchange', (e: any) => {
          let selected = e.selectedItems as { nodes: Node[]; edges: Edge[] }
          if (this.graph.getCurrentMode() === 'comboDragCreate') {
            this.onBrushSelection.emit({ nodes: selected.nodes, edges: selected.edges })
          } else if (selected?.nodes?.length >= 1) {
            this.onSelection.emit(selected.nodes)
          } else {
            this.onSelection.emit([])
          }
        })

        this.graph.on('combo:dblclick', (e) => {
          this.onComboToggle.emit(e.item as Combo)
        })
        this.graph.on('node:dblclick', (e) => {
          this.onNodeDoubleClick.emit(e.item as Node)
        })

        // once initial render is done, update graph when window is resized...
        this.dimensions$.pipe(skip(1), debounceTime(250), takeUntil(this.destroyed$)).subscribe(({ width, height }) => {
          debug('dag', 'changing graph size to', { width, height })
          this.graph.changeSize(width, height)
          this.graph.changeData(this.data$.value)
          this.setSelectedNodeState()
          this.setCombinedNodesState()
        })

        // ...and when data is changed
        this.data$.pipe(skip(1), takeUntil(this.destroyed$)).subscribe((data) => {
          debug('dag', 'changing graph data to', data)
          this.graph.read(data)
          this.setSelectedNodeState()
          this.setCombinedNodesState()
        })
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.graphData) {
      this.data$.next(changes.graphData.currentValue)
    }
    if (this.graph && changes.selectedNodeId) {
      this.graph.getNodes().forEach((node) => this.graph.setItemState(node.getID(), 'selected', false))
      this.setSelectedNodeState()
      this.getDimensions()
    }
    if (this.graph && changes.combinedNodeIds) {
      this.graph.getNodes().forEach((node) => this.graph.setItemState(node.getID(), 'combined', false))
      this.setCombinedNodesState()
      this.getDimensions()
    }
    if (this.graph && changes.selectedComboId) {
      this.graph.getCombos().forEach((combo) => this.graph.setItemState(combo.getID(), 'selected', false))
    }
    if (this.graph && changes.setMode) {
      this.graph.setMode(changes.setMode.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
    this.graph?.off()
    this.graph.set('plugins', [])
    this.graph?.destroy()
  }

  /**
   * Helper to get parent element size
   *
   * @private
   * @memberof DagComponent
   */
  private getDimensions(): void {
    this.graphContainer.nativeElement.style.display = 'none'
    // same fix as in image-annotate,
    // but in this case only 5 pixels seems to preserve the correct size
    this.dimensions$.next({
      width: this.elementRef.nativeElement.offsetWidth - 5,
      height: this.elementRef.nativeElement.offsetHeight - 5,
    })
    this.graphContainer.nativeElement.style.display = 'block'
  }

  /**
   * Set a specific node as 'selected' state
   *
   * @private
   * @memberof DagComponent
   */
  private setSelectedNodeState(): void {
    if (this.graph && this.selectedNodeId) {
      if (this.graph.findById(this.selectedNodeId)) {
        this.graph.setItemState(this.selectedNodeId, 'selected', true)
      }
    }
    this.graph.fitView(0)
  }
  /**
   * Set a group of nodes as 'combined' state
   *
   * @private
   * @memberof DagComponent
   */
  private setCombinedNodesState(): void {
    if (this.graph && this.combinedNodeIds) {
      this.combinedNodeIds.forEach((id) => {
        if (this.graph.findById(id)) {
          this.graph.setItemState(id, 'combined', true)
        }
      })
    }
    this.graph.fitView(0)
  }
}
