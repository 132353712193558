<div class="card mx-3 px-5 py-3 border-grey bg-light">
  <div class="row my-3">
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Status</div>
        <div class="col-xl-9" [ngClass]="{ 'text-success': botJob?.enabled }">
          {{ botJob?.enabled ? 'Enabled' : 'Disabled' }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Last Run</div>
        <div class="col-xl-9">
          {{ lastRun ? (lastRun | date) : 'Never' }}
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Teleport</div>
        <div class="col-xl-9" [ngClass]="{ 'text-success': botJob?.teleportEnabled }">
          {{ botJob?.teleportEnabled ? 'Enabled' : 'Disabled' }}
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Success Rate</div>
        <div class="col-xl-9">{{ successRate ? (successRate | percent) : 'No succeeded runs in set' }}</div>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Description</div>
        <div class="col-xl-9">
          <p [innerHTML]="taskType?.description"></p>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-xl-3 font-weight-bold">Avg. Duration</div>
        <div class="col-xl-9">{{ avgBotRunTime ? avgBotRunTime + ' sec' : 'No completed runs in set' }}</div>
      </div>
    </div>
  </div>
</div>
