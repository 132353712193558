import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ApolloQueryResult } from '@apollo/client'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import {
  ClientCredential,
  ClientCredentialsList,
  ListResponseMetaData,
  QueryClientCredentialsArgs,
  User,
} from 'generated/graphql'
import { combineLatest, of, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs/operators'
import { ClientCredentialsService } from '../client-credentials.service'

/**
 * Page to display a list of client credentials
 *
 * @export
 * @class ClientCredentialsListPage
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-client-credentials-list',
  templateUrl: './client-credentials-list.page.html',
  host: { class: 'd-flex flex-column h-100' },
})
export class ClientCredentialsListPage implements OnInit, OnDestroy {
  isLoading: boolean = true
  clientCredentialsSearch = new UntypedFormControl('')
  clientCredentials: { credential: ClientCredential; checkedOutBy: User }[] = []
  meta: ListResponseMetaData
  page = new UntypedFormControl(1)
  pageSize: number = 25
  destroy$ = new Subject<void>()

  constructor(private clientCredentialsService: ClientCredentialsService, private toast: ToastService) {}

  ngOnInit(): void {
    combineLatest([
      this.clientCredentialsSearch.valueChanges.pipe(startWith(''), debounceTime(500)),
      this.page.valueChanges.pipe(startWith(1)),
    ])
      .pipe(
        // Prevents re-searching if the user hits enter after finishing typing
        // their query.
        distinctUntilChanged((prev: [string, number], curr: [string, number]) => {
          return prev.join('') === curr.join('')
        }),
        switchMap(([search, page]) => {
          try {
            let offset = (page - 1) * this.pageSize
            let variables: QueryClientCredentialsArgs = {
              sort: this.meta?.sort,
              offset,
              search,
              limit: this.pageSize,
            }
            return this.clientCredentialsService.searchClientCredentials(variables)
          } catch (e) {
            this.toast.error(parseGraphQLError(e, 'Could not load client credentials'), JSON.stringify(e))
            this.isLoading = false
          }
          return of({ data: { clientCredentials: { entities: [] } } })
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        async (
          result: ApolloQueryResult<{
            clientCredentials: ClientCredentialsList
          }>,
        ) => {
          this.meta = result?.data?.clientCredentials?.meta

          let credentials: ClientCredential[] = result?.data?.clientCredentials?.entities ?? []
          this.clientCredentials = await this.clientCredentialsService.saturateCredentialList(credentials)

          this.isLoading = false
        },
      )
  }

  ngOnDestroy(): void {
    this.destroy$?.next()
    this.destroy$?.complete()
  }

  /**
   * Checks out selected client credential to the current user
   *
   * @param {string} id
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialsListPage
   */
  async checkOutCredentials(id: string): Promise<void> {
    try {
      let result = await this.clientCredentialsService.checkOutClientCredentials(id)
      if (result) {
        this.toast.success('Successfully checked out client credential')
        this.isLoading = true
        this.page.setValue(this.page.value)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not check out client credentials'), JSON.stringify(e))
    }
  }

  /**
   * Checks in the selected client credential
   *
   * @param {string} id
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialsListPage
   */
  async checkInCredentials(id: string): Promise<void> {
    try {
      let result = await this.clientCredentialsService.checkInClientCredentials(id)
      if (result) {
        this.toast.success('Successfully checked in client credential')
        this.isLoading = true
        this.page.setValue(this.page.value)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not check in client credentials'), JSON.stringify(e))
    }
  }
}
