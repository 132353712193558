import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'

/**
 * Page where a user requests a password reset ( email )
 *
 * @export
 * @class ResetPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-reset-page',
  templateUrl: './reset.page.html',
  styleUrls: ['./reset.page.scss'],
})
export class ResetPage implements OnInit {
  form = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
  })

  saving: boolean = false
  error: boolean = false
  success: boolean = false

  constructor(private authenticationService: AuthenticationService, protected zendesk: ZendeskService) {}

  ngOnInit(): void {}

  /**
   * Submit user's email for password reset email generation
   *
   * @return {*}  {Promise<void>}
   * @memberof ResetPage
   */
  async submit(): Promise<void> {
    this.error = false
    this.success = false
    if (!this.form.valid) {
      return
    }

    this.saving = true

    try {
      await this.authenticationService.reset(this.form.get('email').value)
      this.success = true
    } catch (e) {
      this.error = true
    }
    this.saving = false
  }
}
