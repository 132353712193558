import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import bsCustomFileInput from 'bs-custom-file-input'
import { Subscription } from 'rxjs'
import { ReportService } from '../report.service'

@Component({
  selector: 'app-report-upload',
  templateUrl: './report-upload.page.html',
  styleUrls: ['./report-upload.page.scss'],
})
export class ReportUploadPage implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    category: new UntypedFormControl(null, Validators.required),
    report: new UntypedFormControl(null, Validators.required),
  })
  report$: Subscription
  isSaving: boolean = false

  constructor(private toast: ToastService, private router: Router, private reportService: ReportService) {}

  ngOnInit(): void {
    bsCustomFileInput.init()
    let reader = new FileReader()
    this.report$ = this.form.get('report').valueChanges.subscribe((value) => {
      reader.readAsDataURL(value)
    })
  }

  async uploadFile(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }
    this.isSaving = true
    let upload = this.form.value
    try {
      await this.reportService.setReport(upload?.report as File, upload?.name, upload?.category)
      this.toast.success('Report successfully uploaded')
      this.router.navigate(['reports'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not upload report'), JSON.stringify(e))
    }
    this.isSaving = false
  }

  ngOnDestroy(): void {
    bsCustomFileInput?.destroy()
  }
}
