<span
  class="badge badge-pill badge-light"
  *ngIf="userIds?.length"
  [ngbTooltip]="tipContent"
  #tt="ngbTooltip"
  triggers="manual"
  container="body"
  placement="left"
  (mouseover)="toggleTooltip(tt, false, userIds)"
  (mouseout)="toggleTooltip(tt, true)"
  data-t="task-picked-badge"
>
  <i class="fa fa-fw fa-eye pr-2"></i>
  {{ userIds.length }}
</span>
<ng-template #tipContent let-names="names">
  <b>Currently viewing:</b>
  <div *ngFor="let name of names">{{ name }}</div>
</ng-template>
