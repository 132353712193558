<form [formGroup]="form" (ngSubmit)="save()" class="h-100 d-flex flex-column">
  <div class="card-body flex-grow-0">
    <div class="form-group">
      <label for="name" class="required">Name</label>
      <input type="text" class="form-control" id="name" formControlName="name" [appFcBsValidation]="form.get('name')" />
    </div>
    <app-select-org-set [orgSetId]="form.get('orgSetId')"></app-select-org-set>
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="testOrg" formControlName="testOrg" />
      <label class="custom-control-label" for="testOrg">Test Org?</label>
    </div>
  </div>
  <div class="card-footer border-top-1 bg-alt-white d-flex justify-content-end">
    <button type="button" class="btn btn-cancel mr-3" (click)="onCancel.emit()">Cancel</button>
    <button class="btn btn-primary" type="submit">
      <i class="fa fa-fw fa-floppy-o mr-2"></i>
      Save
    </button>
  </div>
</form>
