import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AuthenticationService } from 'app/auth/authentication.service'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Task } from 'generated/graphql'

/**
 * Component to Complete / Rejected / Reopen non-workflow tasks
 *
 * @export
 * @class TaskActionsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-actions',
  templateUrl: './task-actions.component.html',
  styleUrls: ['./task-actions.component.scss'],
})
export class TaskActionsComponent implements OnInit {
  @Input() task: Task
  @Output() taskEvent = new EventEmitter<true | false>()

  canReopen: boolean = false

  constructor(private taskService: TasksService, private authenticationService: AuthenticationService, private toast: ToastService) {}

  ngOnInit(): void {
    this.canReopen = this.task?.completedUserId === this.authenticationService.getUser().id || this.authenticationService.canAccessManagerViews()
  }

  async completeTask(): Promise<void> {
    debug('tasks', 'trying to complete task ', this.task.id)
    try {
      await this.taskService.completeTask(this.task.id)
      this.taskEvent.emit(true)
      this.toast.success('Task completed')
      this.canReopen = true
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not complete task'), JSON.stringify(e))
    }
  }

  async rejectTask(): Promise<void> {
    debug('tasks', 'trying to reject task ', this.task.id)
    try {
      await this.taskService.completeTask(this.task.id, true)
      this.taskEvent.emit(false)
      this.toast.success('Task rejected')
      this.canReopen = true
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not reject task'), JSON.stringify(e))
    }
  }

  async uncompleteTask(): Promise<void> {
    debug('tasks', 'trying to uncomplete task ', this.task.id)
    try {
      await this.taskService.unCompleteTask(this.task.id)
      this.toast.success('Task re-opened')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not re-open task'), JSON.stringify(e))
    }
  }
}
