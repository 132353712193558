import { Facet } from 'app/shared/types/facet'
import { FACETS, SEARCHFIELD } from 'app/shared/types/facets'
import { SearchFacet } from 'generated/graphql'

/**
 * Format an elastic search query string into an array of facets
 *
 * @param {SearchFacet} search
 * @return {*} {Facet}
 */
export const searchToFacet = (search: SearchFacet): Facet => {
  let facet: Facet = FACETS.find((f) => f.id === search.facet) || SEARCHFIELD

  facet.selected = search.selected.map((s) => {
    let value

    if (!isNaN(Number(s.value))) {
      value = Number(s.value)
    } else if (s.value === 'true') {
      value = true
    } else if (s.value === 'false') {
      value = false
    } else if (s.value.split(',').length > 1) {
      value = s.value.split(',')
    } else {
      value = s.value
    }

    let selected = {
      value: value,
      display: s.display,
      displayPill: s.displayPill,
    }
    if (s.key) {
      selected['key'] = s.key
    }
    return selected
  })
  facet.skipFocus = true
  return facet
}
