import { Pipe, PipeTransform } from '@angular/core'

/**
 * Simple Utility pipe for performing casts in templates to a concrete type.
 *
 * Example usage:
 *
 * You have a relatively simple component that you want to maintain styling & function for
 * but alter how you display certain information based off of ConfigA and ConfigB.
 *
 * MyComponent {
 *    config: ConfigA | ConfigB
 * }
 *
 * In order to use this pipe, you will need to store type references on your component first:
 *
 * MyComponent {
 *
 *   // Used for template type-casting
 *   ConfigA = {} as ConfigA
 *   ConfigB = {} as ConfigB
 * }
 *
 * Then in your template, pipe the original field to the as operator:
 *
 * (config | as : ConfigB).configBFields
 */
@Pipe({
  name: 'as',
  pure: true,
})
export class AsPipe implements PipeTransform {
  transform<T>(value: unknown, _type: (new (...args: any[]) => T) | T): T {
    return value as T
  }
}
