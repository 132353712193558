import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { togglePassType } from 'app/shared/utils/togglePassType'
import { Subscription } from 'rxjs'

/**
 * Page that a user is sent to via the password reset email
 *
 * @export
 * @class NewPasswordPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-new-password-page',
  templateUrl: './new-password.page.html',
  styleUrls: ['./new-password.page.scss'],
})
export class NewPasswordPage implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    pass1: new UntypedFormControl('', Validators.required),
  })

  passType: 'password' | 'text' = 'password'

  token: string = null
  param$: Subscription

  saving: boolean = false
  error: boolean = false
  success: boolean = false

  constructor(
    private router: ActivatedRoute,
    private authenticationService: AuthenticationService,
    protected zendesk: ZendeskService,
  ) {}

  ngOnInit(): void {
    this.param$ = this.router.paramMap.subscribe((params) => {
      // TODO: This needs null checking
      this.token = params.get('token')
    })
  }

  ngOnDestroy(): void {
    this.param$?.unsubscribe()
  }

  /**
   * Helper to toggle password visibility
   *
   * @memberof NewPasswordPage
   */
  togglePassType(): void {
    this.passType = togglePassType(this.passType)
  }

  /**
   * Submit user's new password
   *
   * @return {*}  {Promise<void>}
   * @memberof NewPasswordPage
   */
  async submit(): Promise<void> {
    this.error = false
    this.success = false
    if (!this.form.valid) {
      return
    }

    this.saving = true

    try {
      await this.authenticationService.setPassword(this.form.get('pass1').value, this.token)
      this.success = true
    } catch (e) {
      this.error = true
    }
    this.saving = false
  }
}
