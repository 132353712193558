<div>
  <form class="d-flex flex-row align-items-center" [formGroup]="dateRangeFormGroup">
    <!-- Dropdown for pre-arranged -->
    <div ngbDropdown class="mr-4">
      <button
        type="button"
        class="btn btn-light border border-alt-light-gray text-alt-medium-gray"
        id="date-presets"
        ngbDropdownToggle
      >
        Date Presets
      </button>
      <div id="presets-dropdown-container" ngbDropdownMenu aria-labelledby="presetsDropdown">
        <button ngbDropdownItem>Date Range</button>
        <button
          ngbDropdownItem
          *ngFor="let preset of presets"
          (click)="onSelectPreset(preset)"
          [id]="preset.label + '-preset'"
        >
          {{ preset.label }}
        </button>
      </div>
    </div>

    <div class="form-group m-0">
      <label class="pr-2 sr-only" for="startDateInput">Start Date</label>
      <input
        id="start-date-input"
        class="form-control border-alt-light-gray text-alt-medium-gray"
        name="startDateInput"
        type="date"
        formControlName="startDate"
        [attr.max]="endDateControl.value"
        size="8"
      />
    </div>
    &nbsp;&#8212;&nbsp;
    <div class="form-group m-0">
      <label class="pr-2 sr-only" for="endDateInput">End Date</label>
      <input
        id="end-date-input"
        class="form-control border-alt-light-gray text-alt-medium-gray"
        name="endDateInput"
        type="date"
        formControlName="endDate"
        [attr.min]="startDateControl.value"
        size="8"
      />
    </div>
  </form>
</div>
