import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotJob, CreateBotJobInput, Organization, Payer, PayerPlan, TaskType } from 'generated/graphql'
import { defer, pickBy, uniqBy } from 'lodash'
import { forkJoin, of, Observable, Subject } from 'rxjs'
import { switchMap, take, takeUntil } from 'rxjs/operators'
import { BotJobsService } from '../bot-jobs.service'
import { PayerPlanPlanTypeaheadComponent } from '../../../shared/components/payerplan-plan-typeahead/payerplan-plan-typeahead.component'

/**
 * Page to create / edit a single bot job
 *
 * @export
 * @class EditBotJobPage
 */
@Component({
  selector: 'app-edit-bot-job',
  templateUrl: './edit-bot-job.page.html',
  styleUrls: ['./edit-bot-job.page.scss'],
  host: { class: 'h-100' },
})
export class EditBotJobPage implements OnInit, OnDestroy {
  @ViewChild('ctrlPlan') ctrlPlan: PayerPlanPlanTypeaheadComponent
  @ViewChild('emailEl') emailEl!: ElementRef

  botJob: BotJob | null = null
  destroy$ = new Subject<void>()
  saving: boolean = false
  isLoading: boolean = true
  selectedPayers: Payer[] = []
  selectedPayersIds: string[] = []

  isLegacyPayer = false
  selectedPayerPlans: PayerPlan[] = []
  selectedPayerName: string = ''
  selectedPayerPlan: PayerPlan | null = null

  contactEmail = new UntypedFormControl(null, Validators.email)
  contactEmails: string[] = []
  selectedTaskType: TaskType | null = null
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    command: new UntypedFormControl(null, Validators.required),
    enabled: new UntypedFormControl(false),
    teleportEnabled: new UntypedFormControl(false),
    beta: new UntypedFormControl(false),
    zyncAllowed: new UntypedFormControl(false),
    credentialType: new UntypedFormControl(null),
    credentialTypeModifier: new UntypedFormControl(null),
    portal: new UntypedFormControl(null, Validators.required),
    payer: new UntypedFormControl(null, Validators.required),
  })
  orgCtrl = new UntypedFormControl([], Validators.required)

  get selectedPayerPlanIds(): string[] {
    return this.selectedPayerPlans
      ? this.selectedPayerPlans.map((payerPlan) => (payerPlan as any).id ?? payerPlan.id)
      : []
  }

  constructor(
    private toast: ToastService,
    private botJobsService: BotJobsService,
    private payersService: PayersService,
    private taskTypesService: TaskTypesService,
    private orgsService: OrgService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
    private payerPlanService: PayerPlanService,
  ) {}

  ngOnInit(): void {
    this.form.addControl('orgIds', this.orgCtrl)
    this.orgCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.configureLegacyPayer.bind(this))

    this.isLoading = true
    const botJobId = this.route.snapshot.paramMap.get('botJobId')

    if (botJobId === 'new') {
      this.isLoading = false
      this.resetForm()
      this.crumbs.setPageTitle('Admin - Bot Jobs - New')
    } else if (!botJobId) {
      this.toast.error('Missing Bot Job ID in URL')
      this.router.navigate(['admin', 'bot-jobs'])
    } else {
      this.botJobsService
        .getBotJob(botJobId)
        .pipe(
          take(1),
          switchMap((botJobResult) => {
            const botJob = botJobResult?.data?.botJobV2
            if (!botJob) {
              throw new Error("Couldn't fetch bot job")
            }
            return of(botJob)
          }),
          switchMap((botJob) => {
            if (!botJob.orgIds) {
              throw new Error('Missing Org IDs for the Bot Job')
            }

            return this.getRelatedBotJobData(botJob)
          }),
          takeUntil(this.destroy$),
        )
        .subscribe(
          ([isLegacyPayer, botJob, jobOrganizations, payers, taskType]) => {
            this.isLegacyPayer = isLegacyPayer
            this.botJob = botJob
            this.orgCtrl.setValue(jobOrganizations)
            this.crumbs.setPageTitle('Admin - Bot Jobs - ' + this.botJob.name)

            this.contactEmails = botJob.contactEmails ?? []
            this.selectedPayerPlans = (botJob.botJobPayerPlanBridges ?? []).map((b) => b.payerPlan)
            this.selectedPayers = payers
            this.selectedPayersIds = payers.map((payer) => payer.id)
            this.selectedTaskType = taskType

            this.setFormAndBreadcrumb()

            this.isLoading = false
          },
          (error) => {
            this.toast.error('Could not fetch bot job', JSON.stringify(error))
            this.router.navigate(['admin', 'bot-jobs'])
          },
        )
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  resetForm(): void {
    this.isLegacyPayer = true
    this.form.reset({
      name: null,
      command: null,
      enabled: false,
      teleportEnabled: false,
      beta: false,
      credentialType: null,
      credentialTypeModifier: null,
      portal: null,
      payer: null,
      orgIds: [],
    })
    this.contactEmails = []
  }

  getRelatedBotJobData(botJob: BotJob): Observable<[boolean, BotJob, Organization[], Payer[], TaskType | null]> {
    const legacyEnabled = botJob.orgIds.length
      ? this.payerPlanService.getLegacyEnabled(botJob.orgIds[0]) // deprecated??
      : of(true)

    const payerResults =
      botJob.payerIds && botJob.payerIds.length
        ? this.payersService.searchPayers({ ids: botJob.payerIds })
        : of<Payer[]>([])

    const taskType: Promise<TaskType> | Observable<null> = botJob.taskTypeId
      ? this.taskTypesService.getTaskType(botJob.taskTypeId)
      : of(null)

    const orgs = botJob.orgIds.length
      ? forkJoin(botJob.orgIds.map((orgId) => this.orgsService.getOrg(orgId).pipe(take(1)))).pipe(
          switchMap((orgResults) => {
            return of(orgResults.map((result) => result.data.organization))
          }),
        )
      : of<Organization[]>([])

    return forkJoin([legacyEnabled, of(botJob), orgs, payerResults, taskType])
  }

  /**
   * Configures which Payer controls to display each time the organizations selected changes.
   * Always uses first org in the list.
   *
   * Defaults to using legacy controls if the first organization in the list does not have
   * `api.payerplan` feature flag enabled.
   */
  private async configureLegacyPayer() {
    this.isLegacyPayer = this.orgCtrl.value.length
      ? await this.payerPlanService.getLegacyEnabled(this.orgCtrl.value[0].id)
      : true
  }

  /**
   * Create / Save current bot job
   *
   * @return {*}  {Promise<void>}
   * @memberof EditBotJobPage
   */
  async saveBotJob(): Promise<void> {
    this.saving = true
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      this.toast.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.form)),
      )
    } else {
      const form = this.removeEmpty(this.form.value)

      form.payerIds = this.selectedPayers.map((payer) => payer?.id)
      form.taskTypeId = this.selectedTaskType?.id
      form.orgIds = this.orgCtrl.value.map((org) => org.id)
      form.orgId = form.orgIds[0]
      form.contactEmails = this.contactEmails
      try {
        const payerPlanIds = this.selectedPayerPlans
          .map((payerPlan) => payerPlan?.id)
          .filter((payerPlanId): payerPlanId is string => !!payerPlanId)
        if (this.botJob?.id) {
          this.botJob = await this.botJobsService.updateBotJob(this.botJob.id, form, payerPlanIds)
          this.setFormAndBreadcrumb()
        } else {
          const result = await this.botJobsService.createBotJob(form, payerPlanIds)
          if (result.id) {
            this.router.navigate([`admin/bot-jobs/${result.id}`])
          }
        }
        this.toast.success('Bot job successfully saved')
        this.router.navigate(['admin', 'bot-jobs'])
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not save bot job'), JSON.stringify(e))
      }
    }
    this.saving = false
  }

  /**
   * Update bot job form and set breadcrumbs
   *
   * @memberof EditBotJobPage
   */
  setFormAndBreadcrumb(): void {
    // let lastCrumb = this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1]
    // lastCrumb.label = this?.botJob?.name || 'New Bot Job'
    if (this.botJob?.id) {
      this.form.setValue({
        name: this.botJob?.name,
        enabled: this.botJob?.enabled,
        command: this.botJob?.command,
        teleportEnabled: this.botJob?.teleportEnabled,
        beta: this.botJob?.beta,
        zyncAllowed: this.botJob?.zyncAllowed,
        credentialType: this.botJob?.credentialType || '',
        credentialTypeModifier: this.botJob?.credentialTypeModifier || '',
        portal: this.botJob?.portal || '',
        payer: this.botJob?.payer || '',
        orgIds: this.orgCtrl.value,
      })
    }
  }

  /**
   * Helper to strip null values from form data
   *
   * @param {Record<string, unknown>} object
   * @return {*}  {CreateBotJobInput}
   * @memberof EditBotJobPage
   */
  removeEmpty(object: Record<string, unknown>): CreateBotJobInput {
    return pickBy(object, (value) => value != null) as CreateBotJobInput
  }

  /**
   * Set picked task type
   *
   * @param {TaskType} taskType
   * @memberof EditBotJobPage
   */
  pickedTaskType(taskType: TaskType): void {
    this.selectedTaskType = taskType
  }

  /**
   * Add selected payer to payers list
   *
   * @param {Payer} payer
   * @memberof EditBotJobPage
   * @deprecated
   */
  pickedLegacyPayer(payer: Payer): void {
    this.selectedPayers = uniqBy([...(this.selectedPayers || []), payer], 'id')
    this.selectedPayersIds = this.selectedPayers.map((payer) => payer.id)
  }

  /**
   * Remove payer from payers list
   *
   * @param {Payer} payer
   * @memberof EditBotJobPage
   * @deprecated
   */
  removeLegacyPayer(payer: Payer): void {
    this.selectedPayers = this.selectedPayers.filter((u) => u.id !== payer.id)
    this.selectedPayersIds = this.selectedPayersIds.filter((uId) => uId !== payer.id)
  }

  /** Select payer to choose a plan from */
  pickedPayer(payerPlan: PayerPlan): void {
    if (!payerPlan?.payerName) {
      this.selectedPayerName = ''
      if (this.ctrlPlan) this.ctrlPlan.clearPlan()
    } else {
      this.selectedPayerName = payerPlan.payerName
    }
  }

  /** Add selected plan to the payerPlan list */
  addPlan(payerPlan: PayerPlan): void {
    this.selectedPayerPlans = [...this.selectedPayerPlans, payerPlan]
  }

  /** Remove payerPlan from payerPlan list */
  removePayerPlan(payerPlan: PayerPlan): void {
    this.selectedPayerPlans = this.selectedPayerPlans.filter(
      (selectedPayerPlan) => selectedPayerPlan.id !== payerPlan.id,
    )
  }

  /**
   * Add organization to org list
   *
   * @param {Organization} event
   * @memberof EditBotJobPage
   */
  pickedOrg(event: Organization): void {
    const orgs = uniqBy([...this.orgCtrl.value, event], 'id')
    this.orgCtrl.setValue(orgs)
    this.orgCtrl.markAsTouched()
  }

  /**
   * Remove org from orgs list
   *
   * @param {Organization} org
   * @memberof EditBotJobPage
   */
  removeOrg(org: Organization): void {
    const orgs = this.orgCtrl.value.filter((u) => u.id !== org.id)
    this.orgCtrl.setValue(orgs)
    this.orgCtrl.markAsTouched()
  }

  /** Helper for ngFor performance */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  trackById(_index: number, item: any): string {
    return item?.id
  }

  /**
   * Launch modal to confirm deleting current bot job
   *
   * @memberof EditBotJobPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete bot job?'
    modalRef.componentInstance.body = `Once this bot job is deleted its data cannot be recovered.`
    modalRef.componentInstance.yes = 'Delete bot job'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteBotJob()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current bot job & navigate back to list
   *
   * @return {*}  {Promise<void>}
   * @memberof EditBotJobPage
   */
  async deleteBotJob(): Promise<void> {
    try {
      await this.botJobsService.deleteBotJob(this.botJob.id)
      this.toast.success('Bot job successfully deleted')
      this.router.navigate(['admin/bot-jobs'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete bot job'), JSON.stringify(e))
    }
  }

  /**
   * Adding input contact email to array of contact emails
   *
   * @memberof EditBotJobPage
   */
  addContactEmail(): void {
    if (this.contactEmail?.value?.length && this.contactEmail.valid) {
      this.contactEmails = [...this.contactEmails, this.contactEmail.value]
      this.contactEmail.reset('')
    } else {
      this.toast.error('Please submit valid email before adding', 'email missing or invalid')
    }
    defer(() => this.emailEl.nativeElement.focus())
  }

  /**
   * Removing selected email from array of contact emails
   *
   * @param {number} index
   * @memberof EditBotJobPage
   */
  removeContactEmail(index: number): void {
    const copy = [...this.contactEmails]
    copy.splice(index, 1)
    this.contactEmails = copy
  }
}
