/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Location } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { WorkflowService } from 'app/admin/workflows/workflow.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { SaturatedTaskComment, TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ChartDataset, ChartOptions, ChartType } from 'chart.js'
import { Claim, CompletionType, Task, Workflow } from 'generated/graphql'
import { delay, flatten, uniq } from 'lodash'
import { BaseChartDirective } from 'ng2-charts'
import { Subscription } from 'rxjs'

/**
 * Component to display a single task or workflow
 *
 * @export
 * @class TaskCardComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() task: Task | Workflow = null
  @Input() claim: Claim = null
  @Input() isSelected: boolean = false

  @Input() isSidebarCollapsed: boolean = true
  @Output() isSidebarCollapsedChange = new EventEmitter<boolean>()
  @Output() taskResolved = new EventEmitter<void>()

  completed: boolean = false
  rejected: boolean = false
  activeUserIds: string[] = []
  outstandingAmount: number = null

  fakeAmount = Math.floor(Math.random() * (750 - 250) + 250)

  comments: SaturatedTaskComment[] = []
  loadingComments: boolean = false
  taskComments$: Subscription

  @ViewChild('baseChart') chart: BaseChartDirective
  workflowProgress: ChartDataset<'doughnut'>[] = [];
  labels: string[] = []

  chartOptions: ChartOptions<'doughnut'> = {
    aspectRatio: 1,
    cutout: '75%',
    circumference: 2 * Math.PI,
    plugins: {
      tooltip: {
        enabled: false,
      },
    }
  };

  chartLegend = true
  chartType: ChartType = 'doughnut'
  chartPlugins = []

  doneColor
  activeColor
  futureColor
  successColor

  constructor(
    private taskService: TasksService,
    private workflowService: WorkflowService,
    public toast: ToastService,
    public authenticationService: AuthenticationService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public org: OrgService,
  ) {
    let style = getComputedStyle(document.body)
    this.doneColor = style.getPropertyValue('--alt-barely-blue')
    this.activeColor = style.getPropertyValue('--alt-baby-blue')
    this.futureColor = style.getPropertyValue('--light')
    this.successColor = style.getPropertyValue('--success')
  }

  ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.task?.__typename === 'Workflow') {
      this.completed = this.task?.completed && this.task?.completionType !== CompletionType.Rejected
      this.rejected = this.task?.completed && this.task?.completionType === CompletionType.Rejected
      this.activeUserIds = uniq(flatten(this.task?.tasks?.map((t) => t.pickedByUserIds)))
      this.outstandingAmount = this.task?.claim?.outstandingAmount

      const prevWorkflowTasks: Task[] = changes.task?.previousValue?.tasks || []
      const currWorkflowTasks: Task[] = changes.task?.currentValue?.tasks || []
      const prevCompletedTasks = prevWorkflowTasks.filter((t) => t.completed).length
      const currCompletedTasks = currWorkflowTasks.filter((t) => t.completed).length
      if (
        this.isSelected &&
        this.task?.tasks?.length >= 0 &&
        (prevWorkflowTasks.length !== currWorkflowTasks.length || prevCompletedTasks !== currCompletedTasks)
      ) {
        this.redrawWorkflowProgress()
      }
    } else {
      this.completed = this.task.completedUserId !== null && this.task.completionType === 'Completed'
      this.rejected = this.task.completedUserId !== null && this.task.completionType === 'Rejected'
      this.activeUserIds = this.task.pickedByUserIds
      this.outstandingAmount = this.task?.outstandingAmount
    }

    if (this.isSelected && this.task.__typename === 'Task' && this.task?.id) {
      this.getTaskComments(this.task.id)
    }
  }

  /**
   * Compute and draw the workflow progress graph
   *
   * @private
   * @memberof TaskCardComponent
   */
  private redrawWorkflowProgress(): void {
    let workflow = this.task as Workflow
    let remaining = workflow?.maxRemainingTasks
    let existing = workflow?.tasks?.length
    let completed = workflow?.completed ? existing : existing - 1
    let total = remaining + existing

    let done = this.doneColor.trim()
    let active = this.activeColor.trim()
    let future = this.futureColor.trim()
    let success = this.successColor.trim()

    let colors = []
    if (completed > 0) {
      colors = new Array(completed).fill(done)
    }
    if (completed < existing) {
      colors.push(active)
    }
    if (remaining) {
      colors = colors.concat(new Array(remaining).fill(future))
    }

    this.labels = new Array(total).fill(false)
    this.chartPlugins = [
      {
        beforeDatasetDraw: function (chart) {
          //Get ctx from string
          let ctx = chart.chart.ctx

          //Get options from the center object in options
          let fontStyle = 'Arial'
          let leftToDo = existing - completed
          let percentage = Math.round(((total - remaining - leftToDo) / total) * 100)

          let txt = `${percentage}%`
          let sidePadding = 20
          let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          let centerX = (chart.chartArea.left + chart.chartArea.right) / 2
          let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2

          // draw circle
          ctx.beginPath()
          ctx.arc(centerX, centerY, (chart.innerRadius * 2 - sidePaddingCalculated) / 2, 0, 2 * Math.PI)
          ctx.fillStyle = success
          ctx.fill()
          //Start with a base font of 30px
          ctx.font = '30px ' + fontStyle

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          let stringWidth = ctx.measureText(txt).width
          let elementWidth = chart.innerRadius * 2 - sidePaddingCalculated - 10

          // Find out how much the font can grow in width.
          let widthRatio = elementWidth / stringWidth
          let newFontSize = Math.floor(30 * widthRatio)
          let elementHeight = chart.innerRadius * 2

          // Pick a new font size so it will not be larger than the height of label.
          let fontSizeToUse = Math.min(newFontSize, elementHeight)

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'

          ctx.font = fontSizeToUse + 'px ' + fontStyle
          ctx.fillStyle = future

          //Draw text in center
          ctx.fillText(txt, centerX, centerY)
        },
      },
    ]
    this.workflowProgress = [{ type: 'doughnut', data: new Array(total).fill(100 / total) as number[], backgroundColor: colors, hoverBackgroundColor: colors }];
    delay(() => {
      this.chart?.render()
    }, 1)
  }

  /**
   * Get the selected task's comments
   *
   * @private
   * @param {string} taskId
   * @memberof TaskCardComponent
   */
  private getTaskComments(taskId: string): void {
    try {
      this.taskComments$ = this.taskService.getTaskComments(taskId).subscribe(async (event) => {
        this.loadingComments = event.loading
        this.comments = await this.taskService.saturateTaskComments(event.data.taskComments)
      })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load task details'), JSON.stringify(e))
    }
  }

  ngOnDestroy(): void {
    this.taskComments$?.unsubscribe()
  }

  /**
   * Launch modal to show images related to a task
   * FOR DEMO ONLY
   *
   * @param {*} modal
   * @memberof TaskCardComponent
   */
  showImages(modal: any): void {
    this.modal.open(modal, { centered: true, size: 'xl' })
  }

  /**
   * If the selected task is a workflow, react to a user completing
   * a task belonging to the workflow
   *
   * @param {{ id: string; outcome: number }} $event
   * @return {*}  {Promise<void>}
   * @memberof TaskCardComponent
   */
  async onWorkflowTaskComplete($event: { id: string; outcome: number }): Promise<void> {
    let { id, outcome } = $event
    try {
      let updatedWorkflow = await this.taskService.completeWorkflowTask(id, outcome)
      this.toast.success('Task completed')
      if (updatedWorkflow?.completed) {
        this.taskResolved.emit()
      } else {
        let nextTask = updatedWorkflow?.tasks?.find((t) => !t.completed)
        if (nextTask) {
          this.taskService.pickTask(nextTask.id)
        }
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not complete task'), JSON.stringify(e))
    }
  }

  /**
   * React to selecting a task inside a workflow
   *
   * @param {*} $event
   * @return {*}  {Promise<void>}
   * @memberof TaskCardComponent
   */
  async onWorkflowNavigate($event): Promise<void> {
    this.getTaskComments($event)
  }

  /**
   * Reject or Reopen a workflow
   *
   * @param {*} $event
   * @return {*}  {Promise<void>}
   * @memberof TaskCardComponent
   */
  async onWorkflowReject($event): Promise<void> {
    let action = $event.reject ? 'rejection' : 're-opening'

    try {
      if ($event.reject) {
        await this.workflowService.rejectWorkflow($event.id)
        this.toast.success('Workflow rejected')
        this.taskResolved.emit()
      } else {
        await this.workflowService.unrejectWorkflow($event.id)
        this.toast.success('Workflow re-opened')
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, `Workflow ${action} failed`), JSON.stringify(e))
    }
  }

  /**
   * Helper to treat a task as a Task
   * ( used for type safety in template )
   *
   * @param {(Task | Workflow)} task
   * @return {*}  {Task}
   * @memberof TaskCardComponent
   */
  asTask(task: Task | Workflow): Task {
    return task as Task
  }

  /**
   * Helper to treat a task as a Workflow
   * ( used for type safety in template )
   *
   * @param {(Task | Workflow)} task
   * @return {*}  {Workflow}
   * @memberof TaskCardComponent
   */
  asWorkflow(task: Task | Workflow): Workflow {
    return task as Workflow
  }
}
