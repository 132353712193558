import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import * as moment from 'moment'
import { Subject, Subscription } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

/**
 * Component to display a daterange facet for claim properties
 *
 * @export
 * @class DaterangeFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-faceted-search-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
})
export class DaterangeFacetComponent implements OnInit, OnDestroy {
  @Input() data: Facet

  isCollapsed = false

  facets$: Subscription
  clearDates$ = new Subject<void>()
  selectedDates = null

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.facets$ = this.facetService.facets$
      .pipe(
        filter((facets) => facets.find((f) => f.id === this.data.id) !== undefined),
        tap((facets: Facet[]) => {
          let selected = facets.find((f) => f.id === this.data.id).selected
          if (selected.length === 0) {
            this.clearDates$.next()
          }
        }),
      )
      .subscribe()

    if (this.data.selected?.length > 0) {
      this.selectedDates = {
        from: moment(this.data.selected[0].value[0], 'MM-DD-YYYY').format('MM/DD/YYYY'),
        to: moment(this.data.selected[0].value[1], 'MM-DD-YYYY').format('MM/DD/YYYY'),
      }
    }
  }

  ngOnDestroy(): void {
    this.facets$.unsubscribe()
  }

  /**
   * Helper to determine if a range is valid
   *
   * @param {{ from: string; to: string }} event
   * @memberof DaterangeFacetComponent
   */
  rangeSelected(event: { from: string; to: string }): void {
    let { from, to } = event
    const fromMoment = moment(from, 'MM/DD/YYYY')
    const toMoment = moment(to, 'MM/DD/YYYY')
    if (fromMoment.isValid() || toMoment.isValid()) {
      this.facetService.addToFacet(this.data.id, {
        value: [
          fromMoment.isValid() ? fromMoment.format('YYYY-MM-DD') : null,
          toMoment.isValid() ? toMoment.format('YYYY-MM-DD') : null,
        ],
        display: this.getDisplayText(fromMoment, toMoment),
      })
    }
  }

  /**
   * Helper to convert a range to human-readable display text
   *
   * @param {moment.Moment} fromMoment
   * @param {moment.Moment} toMoment
   * @return {*}  {string}
   * @memberof DaterangeFacetComponent
   */
  getDisplayText(fromMoment: moment.Moment, toMoment: moment.Moment): string {
    const displayDateFormat = 'MM/DD/YYYY'
    if (fromMoment.isValid() && toMoment.isValid()) {
      return `${fromMoment.format(displayDateFormat)} - ${toMoment.format(displayDateFormat)}`
    } else if (fromMoment.isValid()) {
      return `After ${fromMoment.format(displayDateFormat)}`
    } else if (toMoment.isValid()) {
      return `Before ${toMoment.format(displayDateFormat)}`
    }
  }

  /**
   * Remove this facet
   *
   * @memberof DaterangeFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
