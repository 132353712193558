<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <fieldset [formGroup]="form">
      <div class="form-group">
        <div class="form-check" *ngFor="let radio of data.options; let i = index">
          <input
            type="radio"
            class="form-check-input"
            formControlName="radios"
            [value]="radio?.value"
            [attr.id]="data.id + i"
            #input
          />
          <label class="form-check-label" [attr.for]="data.id + i">{{ radio?.display }}</label>
        </div>
      </div>
    </fieldset>
  </div>
</div>
