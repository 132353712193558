import { formatCurrency } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { WorkflowService } from 'app/admin/workflows/workflow.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ClaimsService } from 'app/claims/claims.service'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { TaskFilterComponent } from 'app/pathfinder/tasks/components/task-filter/task-filter.component'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { TaskGroupStatsService } from 'app/reporting/task-group-stats.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ChartDataset, ChartOptions, ChartType } from 'chart.js'
import { Claim, Task, TaskGroup, TaskGroupObject, TaskGroupStats, Workflow } from 'generated/graphql'
import { flatten, fromPairs } from 'lodash'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter, pairwise, startWith, takeUntil } from 'rxjs/operators'

type TaskGroupObjectType = 'Workflow' | 'Task'

/**
 * Page to display tasks and workflows in a specific task group
 *
 * @export
 * @class TasksPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-tasks-page',
  templateUrl: './tasks.page.html',
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class TasksPage implements OnInit, OnDestroy {
  taskGroup: TaskGroup = null
  taskGroupStats: TaskGroupStats = null
  myTaskGroupIds: string[] = null

  tasks: TaskGroupObject[] = []
  tasksChanges$: Subscription

  selected: Task | Workflow = null
  selectedChanges$: Subscription
  prev: string = null
  prevType: TaskGroupObjectType = null
  next: string = null
  nextType: TaskGroupObjectType = null

  claimSub$: Subscription = null
  claim: Claim = null

  completed = new Subject<string>()
  completedValue = 'all'
  searching = false

  isSidebarCollapsed: boolean = true

  workflowChanges$: Subscription
  routerSub$: Subscription
  triggerRoute$ = new Subject<boolean>()
  search$: Subscription
  destroy$ = new Subject<void>()

  cashCollected: ChartDataset[] = []
  labels: string[] = ['Addressed Amount', 'Oustanding Amount']
  chartOptions: ChartOptions<'doughnut'> = {
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (item): string => {
            return `${this.labels[item.dataIndex]}: ${formatCurrency(
              this.cashCollected[0].data[item.dataIndex] as number,
              'en',
              '$',
            )}`
          },
        },
      },
    }
  }
  chartLegend = true
  chartType: ChartType = 'doughnut'
  chartPlugins = []

  constructor(
    private taskGroupService: TaskGroupsService,
    private taskGroupStatsService: TaskGroupStatsService,
    private taskService: TasksService,
    private workflowService: WorkflowService,
    private claimService: ClaimsService,
    private breadcrumbs: BreadcrumbsService,
    private authenticationService: AuthenticationService,
    private toast: ToastService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        takeUntil(this.destroy$),
      )
      .subscribe(async () => {
        debug('taskGroup', 'navigation end event')
        await this.loadTasks()
        setTimeout(() => this.setBreadcrumbs(), 100)
      })
  }

  /**
   * Helper to scroll a task into view
   *
   * @private
   * @memberof TasksPage
   */
  private scrollTaskIntoPlace(): void {
    setTimeout(
      () => document.getElementById(`${this.selected?.id}`).scrollIntoView({ behavior: 'smooth', block: 'start' }),
      500,
    )
  }

  /**
   * Helper to scroll a workflow into view
   *
   * @private
   * @memberof TasksPage
   */
  private scrollWorkflowIntoPlace(): void {
    setTimeout(
      () =>
        document
          .getElementById(`${this.selected?.id}`)
          .scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }),
      500,
    )
  }

  /**
   * Load tasks and workflows, task group details and stats, and possibly specifc task
   * based on route fragments
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof TasksPage
   */
  private async loadTasks(): Promise<void> {
    let taskGroupId = this.route.parent.snapshot.paramMap.get('taskGroupId')
    let taskId = this.route.snapshot.paramMap.get('taskId')

    const isTask = this.route.snapshot.queryParamMap.get('type') === 'task'

    let completed = null
    if (this.route?.snapshot?.queryParams?.completed === 'true') {
      completed = true
    } else if (this.route?.snapshot?.queryParams?.completed === 'false') {
      completed = false
    }
    debug('taskGroup', 'loadTasks', { taskGroupId, taskId, isTask, completed })

    try {
      await this.taskService.unpickAllTasks()

      const completedTasks = this.route?.snapshot?.queryParams?.completed || 'all'

      if (taskGroupId === 'my') {
        let myGroups = await this.taskGroupService.searchTaskGroups('', this.authenticationService.getUser()?.id)

        let myTasks = await Promise.all(
          myGroups.map((tg: TaskGroup) =>
            this.taskService.getTaskGroupObjects({ taskGroupId: tg.id, completed: completed }).valueChanges.toPromise(),
          ),
        )

        this.taskGroup = null
        this.tasks = flatten(myTasks.map((mt) => mt.data.taskGroupObjects.entities))
        this.breadcrumbs.setPageTitle('Pathfinder')
      } else if (taskGroupId !== this.taskGroup?.id || completedTasks !== this.completedValue) {
        debug('taskGroup', 'task group or filter changed; getting TGOs')
        this.taskGroup = await this.taskGroupService.getTaskGroup(taskGroupId)

        this.breadcrumbs.setPageTitle('Pathfinder - ' + this.taskGroup?.name)

        this.tasksChanges$?.unsubscribe()
        this.tasksChanges$ = this.taskService
          .getTaskGroupObjects({ taskGroupId: taskGroupId, completed: completed })
          .valueChanges.pipe(takeUntil(this.destroy$))
          .subscribe((event) => {
            debug('taskGroup', 'new data from getTaskGroupObjects', event?.data?.taskGroupObjects)
            this.searching = event?.loading
            this.tasks = event?.data?.taskGroupObjects?.entities
            if (this.tasks?.length) {
              this.setTaskgroupStats()
            }
          })
      }

      if (taskId !== 'tasks') {
        this.selectedChanges$?.unsubscribe()
        if (isTask) {
          this.selectedChanges$ = this.taskService
            .getTask(taskId)
            .pipe(startWith({ data: null }), pairwise(), takeUntil(this.destroy$))
            .subscribe(async (event) => {
              if (event[1]?.data?.task?.id && event[0]?.data?.task?.id !== event[1]?.data?.task?.id) {
                this.listCheckAndClaimFetch(event[1].data.task)
                await this.pickScrollAndSetNextPrev(event[1].data.task)
              }
              this.selected = event[1]?.data?.task
            })
        } else {
          this.selectedChanges$ = this.workflowService
            .getWorkflow(taskId)
            .pipe(startWith({ data: null }), pairwise(), takeUntil(this.destroy$))
            .subscribe(async (event) => {
              if (event[1]?.data?.workflow?.id && event[0]?.data?.workflow?.id !== event[1]?.data?.workflow?.id) {
                this.listCheckAndClaimFetch(event[1].data.workflow)
                await this.pickScrollAndSetNextPrev(event[1].data.workflow)
              }
              this.selected = event[1]?.data?.workflow
            })
        }
      } else {
        this.claim = null
        this.selected = null
        this.selectedChanges$?.unsubscribe()
      }
      this.completedValue = completedTasks
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load tasks'), JSON.stringify(e))
    }
  }

  /**
   * Make sure task / workflow is in the visible list
   * and load the associated claim
   *
   * @private
   * @param {(Task | Workflow)} task
   * @memberof TasksPage
   */
  private listCheckAndClaimFetch(task: Task | Workflow): void {
    let existing = this.tasks.findIndex((t) => t.id === task.id)
    if (existing < 0) {
      let tasks = this.tasks.slice()
      tasks.splice(existing, 1, task)
      this.tasks = tasks
    }
    let pcId = task.__typename === 'Task' ? task?.providerClaimId : task?.claim?.providerClaimId

    this.claimSub$?.unsubscribe()
    this.claimSub$ = this.claimService.getClaim(pcId, { markAsOpened: true }).subscribe((event) => {
      if (event.error) {
        this.toast.error(parseGraphQLError(event, 'Could not load Claim'), JSON.stringify(event.error))
      }
      if (event.data) {
        this.claim = event.data.claim
      }
    })
  }

  /**
   * Pick the selected task or workflow,
   * scroll it into view, and set up the
   * next and previous navigations
   *
   * @private
   * @param {(Task | Workflow)} task
   * @return {*}  {Promise<void>}
   * @memberof TasksPage
   */
  private async pickScrollAndSetNextPrev(task: Task | Workflow): Promise<void> {
    if (task.__typename === 'Task') {
      await this.taskService.pickTask(task.id)
      this.scrollTaskIntoPlace()
    } else {
      const taskToPick = task.tasks?.find((t) => !t.completed)
      await this.taskService.pickTask(taskToPick?.id)
      this.scrollWorkflowIntoPlace()
    }
    if (this.tasks?.length) {
      let position = this.tasks.findIndex((t) => t.id === task?.id)
      let prev = this.tasks[position === 0 ? this.tasks.length - 1 : position - 1]
      let next = this.tasks[position === this.tasks.length - 1 ? 0 : position + 1]
      debug('taskGroup', 'new prev/next', { prev, next })
      this.prev = prev ? prev.id : null
      this.prevType = prev ? prev.__typename : null
      this.next = next ? next.id : null
      this.nextType = next ? next.__typename : null
    }
  }

  /**
   * Load the selected task group's stats
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof TasksPage
   */
  private async setTaskgroupStats(): Promise<void> {
    if (this.taskGroup?.id) {
      this.taskGroupStats = await this.taskGroupStatsService.getTaskGroupStats(this.taskGroup.id)
    }

    this.cashCollected = [
      {
        data: [
          this.taskGroupStats?.addressedOutstandingAmount,
          this.taskGroupStats?.totalOutstandingAmount - this.taskGroupStats?.addressedOutstandingAmount,
        ],
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)',
      },
    ]
  }

  /**
   * Set breadcrumbs
   *
   * @deprecated
   * @private
   * @memberof TasksPage
   */
  private setBreadcrumbs(): void {
    if (
      this.breadcrumbs.breadcrumbs.find((b) => b.link.includes('pathfinder')) &&
      this.breadcrumbs.breadcrumbs.length > 1
    ) {
      if (!this.selected?.id) {
        this.breadcrumbs.breadcrumbs.splice(this.breadcrumbs.breadcrumbs.length - 1)
        this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 1].label = this.taskGroup?.name || 'My Tasks'
      } else {
        this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 2].label = this.taskGroup?.name
        this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 1].label =
          (this.selected.__typename === 'Task' ? this.selected?.taskType?.name : this.selected?.workflowType?.name) ||
          'Tasks'
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.triggerRoute$.next(true)
    this.search$ = this.completed.pipe(takeUntil(this.destroy$)).subscribe(async (value) => {
      this.selected = null
      this.searching = true
      this.updateRoute({ completed: value })
      // this.completedValue set by router event sub
      this.searching = false
    })
  }

  ngOnDestroy(): void {
    this.claimSub$?.unsubscribe()
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Launch modal to filter tasks / workflows
   *
   * @memberof TasksPage
   */
  toggleFilters(): void {
    const modalRef = this.modal.open(TaskFilterComponent, {
      centered: true,
      backdrop: true,
      windowClass: 'modal-extra-padding',
    })

    modalRef.componentInstance.completedValue = this.completedValue

    modalRef.result.then(
      (closed) => {
        this.completed.next(closed?.completed)
      },
      (dismissed) => {},
    )
  }

  /**
   * Helper to navigate to the selected task / workflow
   * with status and type as query params
   *
   * @private
   * @param {{ completed?: string; selectedObject?: { type: string; id: string } }} options
   * @memberof TasksPage
   */
  private updateRoute(options: { completed?: string; selectedObject?: { type: string; id: string } }): void {
    let commands = ['pathfinder', this.taskGroup?.id || 'my']
    let queryParamPairs: [string, string][] = []
    if (options.selectedObject) {
      commands.push(options.selectedObject.id)
      queryParamPairs.push(['type', options.selectedObject.type])
    }
    let completed = options.completed || this.completedValue
    if (completed !== 'all') {
      queryParamPairs.push(['completed', `${completed}`])
    }
    let extras = { queryParams: fromPairs(queryParamPairs) }
    debug('taskGroup', 'updateRoute', commands, extras)
    this.router.navigate(commands, extras)
  }

  /**
   * Setup for navigation after a task has been resolved
   *
   * @memberof TasksPage
   */
  onTaskResolved(): void {
    let selectedObject = this.next ? { type: this.nextType?.toLowerCase(), id: this.next } : null
    this.updateRoute({ selectedObject })
  }

  /**
   * Setup for navigation after a user clicks next or previous task
   *
   * @param {('next' | 'prev')} direction
   * @memberof TasksPage
   */
  onTaskNavigation(direction: 'next' | 'prev'): void {
    let selectedObject: { type: string; id: string }
    if (direction === 'next') {
      selectedObject = this.next ? { type: this.nextType?.toLowerCase(), id: this.next } : null
    } else if (direction === 'prev') {
      selectedObject = this.prev ? { type: this.prevType?.toLowerCase(), id: this.prev } : null
    }
    this.updateRoute({ selectedObject })
  }
}
