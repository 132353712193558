<a
  id="teleport-button"
  class="btn btn-sm btn-outline-primary flex-shrink-1 d-flex align-items-center"
  *ngIf="isVisible"
  (click)="onClick()"
>
  <app-janus-icon icon="teleport" class="mr-1 d-flex align-items-center"></app-janus-icon>
  Teleport
</a>
<button
  id="copy-teleport-params-button"
  type="button"
  class="btn btn-sm btn-outline-primary flex-shrink-1 d-flex align-items-center ml-2"
  (click)="onCopyClick()"
  ngbTooltip="Copy Teleport params to clipboard"
  container="body"
  *ngIf="isCopyBtnVisible"
>
  <i id="copyClickIcon" class="fa fa-clone"></i>
</button>

<div ngbDropdown class="flex-shrink-1" *ngIf="isDemoEnabled">
  <button class="btn btn-sm btn-outline-primary flex-shrink-1 d-flex align-items-center" ngbDropdownToggle>
    <app-janus-icon icon="teleport" class="mr-1 d-flex align-items-center"></app-janus-icon>
    Teleport
  </button>
  <div ngbDropdownMenu>
    <a ngbDropdownItem href="assets/teleport/Teleport-Aetna.mp4" target="_blank">Claim Status</a>
    <button ngbDropdownItem>Eligibility</button>
    <button ngbDropdownItem>Authorization</button>
    <button ngbDropdownItem>Submit Documentation</button>
  </div>
</div>
