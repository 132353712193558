<div class="container p-4 m-4 border border-secondary w-25">
    <div class="form-row p-4 ml-3">
      <h4 class="">Organization: {{ organization?.name }}</h4>
    </div>
  
    <form [formGroup]="orgSettingsForm" (ngSubmit)="saveOrgChanges()" class="ml-3">
      <div class="form-row p-4">
        <div class="custom-control custom-switch ml-3">
            <input
              type="checkbox"
              class="custom-control-input"
              id="collectDataToggle"
              formControlName="collectData"
            />
            <label class="custom-control-label" for="collectDataToggle">Collect Data</label>
          </div>
      </div>
  
      <div class="form-row p-4">
        <div class="custom-control custom-switch ml-3">
          <input
            type="checkbox"
            class="custom-control-input"
            id="enqueueRecordsToggle"
            formControlName="enqueueRecords"
          />
          <label class="custom-control-label" for="enqueueRecordsToggle">Enqueue Data</label>
        </div>
      </div>
  
      <div class="form-row p-4">
        <div class="m-2">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </form>
  </div>
  