<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between row">
    <div class="col-sm">
      <div class="input-group form-group">
        <input
          [formControl]="botJobsSearch"
          (keyup.enter)="searchBotJobs()"
          type="text"
          class="form-control"
          placeholder="Search Bot Jobs"
        />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <button type="button" (click)="searchBotJobs()" class="btn bg-transparent">
              <i class="fa fa-search fa-fw"></i>
            </button>
          </span>
        </div>
        <div class="col-sm">
          <app-organization-typeahead
            (onSelect)="pickedOrg($event)"
            [selectedOrg]="orgFilter"
            [clearInput]="false"
            id="org"
          ></app-organization-typeahead>
        </div>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <a routerLink="/admin/bot-jobs/new" class="btn btn-primary mb-3"> Add Bot Job </a>
    </div>
  </div>
  <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="bot-jobs-table">
      <thead>
        <tr>
          <th scope="col" class="width-1">Enabled</th>
          <th scope="col" class="width-1 text-nowrap">Teleport Enabled</th>
          <th scope="col" class="width-1">Beta</th>
          <th scope="col" class="width-1">Zync Allowed</th>
          <th scope="col" class="text-left">Name</th>
          <th scope="col" class="text-left">Portal</th>
          <th scope="col" class="text-left">Organizations</th>
        </tr>
      </thead>
      <tbody *ngIf="!loading && botJobs?.length">
        <tr *ngFor="let botJobCluster of botJobs" data-t="bot-job-row">
          <td class="text-center">
            <i
              [ngClass]="botJobCluster?.botJob?.enabled ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
            ></i>
          </td>
          <td class="text-center">
            <i
              [ngClass]="botJobCluster?.botJob?.teleportEnabled ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
            ></i>
          </td>
          <td class="text-center">
            <i
              [ngClass]="botJobCluster?.botJob?.beta ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
            ></i>
          </td>
          <td class="text-center">
            <i
              [ngClass]="botJobCluster?.botJob?.zyncAllowed ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
            ></i>
          </td>
          <td>
            <a routerLink="/admin/bot-jobs/{{ botJobCluster?.botJob?.id }}" class="text-nowrap">
              {{ botJobCluster?.botJob?.name }}
            </a>
          </td>
          <td>{{botJobCluster?.botJob?.portal}}</td>
          <td>{{botJobCluster?.orgs?.join(', ')}}</td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="loading"
      innerClass="fa-4x"
      outerClass="align-self-center text-primary"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div *ngIf="!botJobs?.length && !loading" class="d-flex justify-content-center alert alert-danger" role="alert">
      Your search returned no results.
    </div>
    <div class="d-flex align-items-baseline justify-content-end mb-4" *ngIf="!loading && botJobs?.length">
      <small class="mr-3">
        {{page * pageSize - pageSize + 1 | number}} - {{botJobs?.length < pageSize ? meta?.total : page * pageSize |
        number}} of {{meta?.total | number}}
      </small>
      <ngb-pagination
        [collectionSize]="meta?.total"
        (pageChange)="searchBotJobs($event)"
        [pageSize]="pageSize"
        [(page)]="page"
        [maxSize]="5"
        *ngIf="meta?.total >= pageSize"
        [boundaryLinks]="false"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
