import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { OrgService } from 'app/admin/org/org.service'
import { AnalyticsService } from 'app/analytics/services/analytics.service'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'
import { GetMe } from 'app/shared/services/users.gql'
import { debug } from 'app/shared/utils/debug'
import { jitter } from 'app/shared/utils/retry-jitter'
import { sleep } from 'app/shared/utils/sleep'
import { Organization, User, UserRole } from 'generated/graphql'
import { isObjectLike as _isObjectLike } from 'lodash'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

// Does the same thing as lodash's isObjectLike, but with a type guard (useful for narrowing)
const isObjectLike = (obj: unknown): obj is Record<string, unknown> => _isObjectLike(obj)

export type PasswordValidationError = {
  score: number
  warning: string | null
  suggestions: string[]
}
export type PasswordValidationResponse = {
  isValid: boolean
  feedback: PasswordValidationError | null
}
export type ConfirmStatusResponse = {
  status: boolean
}

function isPasswordValidationError(obj: unknown): obj is PasswordValidationError {
  // Must be an object
  if (!isObjectLike(obj)) return false
  // Then, it's gotta have a score property of type number, always.
  if (typeof obj.score !== 'number') return false
  // Then, it's gotta have a warning property of type string or null, always.
  if (obj.warning !== null && typeof obj.warning !== 'string') return false
  // Finally, suggestions is an array of strings, always.
  if (!Array.isArray(obj.suggestions) || !obj.suggestions.every((s) => typeof s === 'string')) return false

  // Else
  return true
}

export function isPasswordValidationResponse(obj: unknown): obj is PasswordValidationResponse {
  // I'm using a slightly more verbose method than a long single boolean expression to gain
  // the powers of type narrowing, which provides greater safety and maintainability.

  // First, it's gotta be an object
  if (!isObjectLike(obj)) return false
  // Then, it's gotta have an isValid property of type boolean, always.
  if (typeof obj.isValid !== 'boolean') return false
  // Finally, feedback is either null or a PasswordValidationError
  if (obj.feedback !== null && !isPasswordValidationError(obj.feedback)) return false

  // Else
  return true
}

/**
 * Service to handle user authentication.
 *
 * @export
 * @class AuthenticationService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private API_URL = '/api'
  private LOGIN_URL = `${this.API_URL}/login`
  private LOGIN_PRECHECK_URL = `${this.LOGIN_URL}/pre-check`
  private RESET_URL = `${this.LOGIN_URL}/reset`
  private PASSWORD_VALIDATE_URL = `${this.LOGIN_URL}/validatePassword`
  private ACTIVATE_URL = `${this.LOGIN_URL}/activate`
  private LOGOUT_URL = `${this.API_URL}/logout`
  private GET_TABLEAU_DASHBOARD_EMBED_URL = `${this.API_URL}/dashboard/dashboardUrl`
  private CONFIRMATION_TOKEN_VALIDATE_URL = `${this.LOGIN_URL}/confirmation-token`
  private CONFIRMATION_UPDATE_URL = `${this.LOGIN_URL}/confirmation-update`
  private CONFIRMATION_REQUEST_URL = `${this.LOGIN_URL}/confirmation-request`

  private getQuicksightEmbedUrlPath = (dashboardType: string): string =>
    `${this.API_URL}/dashboard/${dashboardType}/url`
  private currentUser$ = new BehaviorSubject<User | null>(null)

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private orgService: OrgService,
    private analytics: AnalyticsService,
  ) {
    this.analytics.watchUser(this.currentUser$)
  }

  get user$(): Observable<User | null> {
    return this.currentUser$.asObservable()
  }

  async loginPreCheck(email: string, orgId: string = null): Promise<HttpResponse<string>> {
    try {
      return this.http
        .post(
          this.LOGIN_PRECHECK_URL,
          {
            email: email,
            orgId: orgId,
            relayState: this.route.snapshot.queryParamMap.get('relayState') || '',
            janusAsIdp: this.route.snapshot.queryParamMap.get('janusAsIdp') || '',
            SAMLRequest: this.route.snapshot.queryParamMap.get('SAMLRequest') || '',
          },
          { observe: 'response', responseType: 'text' },
        )
        .toPromise()
    } catch (e) {
      debug('authentication', 'login pre-check failed', e)
      return e
    }
  }

  /**
   * Login a user and save their token
   *
   * @param email
   * @param pass
   * @param [mfa]
   * @memberof AuthenticationService
   */
  async login(
    email: string,
    pass: string,
    mfa?: string,
    queryParams?: Params,
    redirect: boolean = true,
  ): Promise<boolean> {
    try {
      const response = await this.http
        .post(
          this.LOGIN_URL,
          {
            email: email,
            password: pass,
            mfaCode: mfa,
          },
          { params: queryParams, responseType: 'text' },
        )
        .toPromise()

      // When Janus acts as an IdP and a user is authenticating via email/password
      // The response type will be 'text'. For regular authentication the response will
      // be 'json'. The `catch` is to process the 'text' case.
      try {
        JSON.parse(response)
        await this.setUser()
      } catch (_) {
        this.processFormHtml(response)
      }

      // If there is a SAMLRequest query parameter and Janus is not acting as the Identity Provider (IdP),
      // we redirect the user to the '/api/saml/auth' endpoint for SAML authentication.
      // In this scenario, Janus is acting as the Service Provider (SP).
      if (queryParams?.SAMLRequest && !queryParams?.janusAsIdp) {
        this.redirectForSamlAuthentication(queryParams)
      } else if (redirect) {
        this.redirectAfterLogin()
      }
      return true
    } catch (e) {
      try {
        e.error = JSON.parse(e.error)
      } catch (_) {
        // If parsing fails, do nothing
      }
      debug('authentication', 'login went sideways', e)
      throw e
    }
  }

  /**
   * Processes the HTML form in the response from the server, appends it to the DOM, and submits it.
   * This is used when Janus acts as an Identity Provider (IdP) and a user is authenticating via email/password.
   *
   * @param response - The HTTP response from the server.
   */
  private processFormHtml(response: string): void {
    const formHtml = response as string

    const tempElement = document.createElement('div')
    tempElement.innerHTML = formHtml

    const formElement = tempElement.querySelector('#janus-idp-response') as HTMLFormElement

    // Submit the form with the SAML Response that will redirect the user to the external SP
    if (formElement) {
      document.body.appendChild(formElement)
      const form = document.getElementById('janus-idp-response') as HTMLFormElement
      form.submit()
    } else {
      debug('authentication', 'Form element not found for Janus as IdP email/password flow')
    }
  }

  /**
   * Redirects the user to the '/api/saml/auth' endpoint for SAML authentication.
   * In this scenario, Janus is acting as the Service Provider (SP).
   *
   * @param queryParams - The HTTP query parameters.
   */
  private redirectForSamlAuthentication(queryParams: Params): void {
    const samlAuthParams = new HttpParams()
      .set('SAMLRequest', queryParams.SAMLRequest)
      .set('RelayState', queryParams.RelayState)

    window.location.assign(`/api/saml/auth?${samlAuthParams}`)
  }

  /**
   *
   * @param redirectUrl i.e. 'home' or 'vx/manager'
   * @param delayTime in milliseconds
   */
  async redirectAfterLogin(redirectUrl: string = null): Promise<void> {
    // In this case it's pointing to an external site (not a route within our Angular app)
    // This happens when Janus acts as an IdP and relayState is set to an external SP.
    if (redirectUrl && redirectUrl.includes('http')) {
      window.location.assign(redirectUrl)
    } else {
      await this.router.navigateByUrl(redirectUrl || DEFAULT_HOME_PATH)
      document.location.reload()
    }
  }

  /**
   * Log a user out and clear data cache
   *
   * @memberof AuthenticationService
   */
  async logout(): Promise<void> {
    try {
      await this.http.post(this.LOGOUT_URL, {}, { withCredentials: true }).toPromise()
      this.apollo.client.resetStore()
      this.currentUser$.next(null)
      window.localStorage.clear()
      document.location.reload()
    } catch (e) {
      debug('authentication', 'logout went sideways', e)
      return e
    }
  }

  /**
   * Send the user a password reset email
   *
   * @param email
   * @memberof AuthenticationService
   */
  async reset(email: string): Promise<boolean> {
    try {
      await this.http
        .post(this.RESET_URL, {
          email: email,
        })
        .toPromise()
      return true
    } catch (e) {
      debug('authentication', 'reset went sideways', e)
      throw new Error('Could not reset')
    }
  }

  /**
   * Check a proposed password against the password policy
   *
   * @param password The proposed password
   */
  async validatePassword(options: {
    password: string
    token?: string
    email?: string
  }): Promise<PasswordValidationResponse> {
    try {
      const response = await this.http.post(this.PASSWORD_VALIDATE_URL, options).toPromise()
      if (!isPasswordValidationResponse(response)) {
        throw new Error('Invalid password policy response')
      }
      return response
    } catch (e) {
      debug('authentication', 'password validation went sideways', e)
      throw new Error('Could not validate password')
    }
  }

  /**
   * Confirmation Token Status Check
   *
   * @param token The token
   */
  async isConfirmationTokenValid(token: string): Promise<ConfirmStatusResponse> {
    try {
      return (await this.http
        .post(this.CONFIRMATION_TOKEN_VALIDATE_URL, { token })
        .toPromise()) as ConfirmStatusResponse
    } catch (e) {
      debug('authentication', 'cannot submit confirmation token', e)
      throw new Error('Invalid Confirmation Token')
    }
  }

  /**
   * Confirm a user and submit their new password.
   *
   * @param password User's new password
   * @param token The token
   */
  async submitConfirmationTokenUpdate(password: string, token: string): Promise<ConfirmStatusResponse> {
    try {
      return (await this.http
        .post(this.CONFIRMATION_UPDATE_URL, { token, password })
        .toPromise()) as ConfirmStatusResponse
    } catch (e) {
      debug('authentication', 'cannot submit confirmation update', e)
      throw new Error('Invalid Confirmation User')
    }
  }

  /**
   * Send the user a new confirmation email
   *
   * @param token The token
   */
  async submitConfirmationRequest(token: string): Promise<ConfirmStatusResponse> {
    try {
      return (await this.http.post(this.CONFIRMATION_REQUEST_URL, { token }).toPromise()) as ConfirmStatusResponse
    } catch (e) {
      debug('authentication', 'cannot submit confirmation update', e)
      throw new Error('Invalid Confirmation Request')
    }
  }

  /**
   * Try to activate a user given a token
   *
   * @param activationToken
   * @memberof AuthenticationService
   */
  async activateByToken(activationToken: string): Promise<void> {
    try {
      await this.http
        .post(this.ACTIVATE_URL, {
          token: activationToken,
        })
        .toPromise()
    } catch (e) {
      debug('authentication', 'failed to activate user', e)
      throw new Error('Could Not Activate User')
    }
  }

  /**
   * Reset a user's password
   *
   * @param pass
   * @param token
   * @memberof AuthenticationService
   */
  async setPassword(pass: string, token: string): Promise<boolean> {
    try {
      await this.http
        .post(this.RESET_URL, {
          password: pass,
          token: token,
        })
        .toPromise()
      return true
    } catch (e) {
      debug('authentication', 'new password reset went sideways', e)
      throw new Error('Could not set new password')
    }
  }

  /**
   * get dashboard embedding info
   * The URL corresponds to the tableau user that corresponds to the organization of the janus user making the request
   *
   * @memberof AuthenticationService
   */
  async getTableauDashboardEmbedInfo(dashboardType: string): Promise<{ env: string; dashboard: string }> {
    try {
      const dashboardUrlResponse = (await this.http
        .get(`${this.GET_TABLEAU_DASHBOARD_EMBED_URL}/${dashboardType}`, {})
        .toPromise()) as {
        env: string
        dashboard: string
      }
      return dashboardUrlResponse
    } catch (e) {
      debug('authentication', 'get dashboard URL went sideways', e)
      throw new Error('Could not get Tableau dashboard URL')
    }
  }

  /**
   * Fetches the URL to pass into the quicksight embed SDK
   *
   * @param dashboardType the type of dashboard we want to fetch
   * @returns
   */
  async getQuicksightDashboardUrl(dashboardType: QuicksightDashboardType): Promise<string> {
    try {
      const dashboardUrlResponse = (await this.http
        .get(this.getQuicksightEmbedUrlPath(dashboardType), {})
        .toPromise()) as { embedUrl: string }
      return dashboardUrlResponse.embedUrl
    } catch (e) {
      debug('authentication', 'get dashboard URL went sideways', e)
      throw new Error('Could not get Quicksight dashboard URL')
    }
  }

  /**
   * Retrieve currently authorized user
   *
   * @memberof AuthenticationService
   */
  async setUser(shouldRetry = true): Promise<void> {
    try {
      let me = await this.apollo
        .query<{ me: User }>({
          query: GetMe,
        })
        .toPromise()
      this.currentUser$.next(me?.data?.me ?? null)
      await this.orgService.getDemoFt()
    } catch (error) {
      debug('authentication', 'error trying to get me', error)
      debug('app', 'could not set user')
      if (shouldRetry) {
        await sleep(jitter(0, 1))
        await this.setUser(false)
      } else {
        throw new Error('User is not set')
      }
    }
  }

  /**
   * Get current user
   *
   * @memberof AuthenticationService
   */
  getUser(): User | null {
    return this.currentUser$.value || null
  }

  /**
   * Get current user's org
   *
   * @memberof AuthenticationService
   */
  getUserOrg(): Promise<Organization> {
    const user = this.getUser()
    if (user) {
      return this.orgService
        .getOrg(user.orgId)
        .pipe(
          take(1),
          map((response) => response?.data?.organization),
        )
        .toPromise()
    } else {
      return null
    }
  }

  /**
   * Helper for checking current user's role
   *
   * @param role
   * @memberof AuthenticationService
   * @deprecated Use permissions instead {@link AuthorizationService}
   */
  checkUserRole(role: UserRole): boolean {
    debug('authentication', 'checking user role', this.currentUser$.value?.role, role)

    return this.currentUser$.value?.role === role
  }

  /**
   * Can current user access application administrative screens
   *
   * @memberof AuthenticationService
   * @deprecated Use permissions instead {@link AuthorizationService}
   */
  canAccessAdmin(): boolean {
    return this.checkUserRole(UserRole.Admin)
  }

  /**
   * Can current user access manager-focused screens
   *
   * @memberof AuthenticationService
   * @deprecated Use permissions instead {@link AuthorizationService}
   */
  canAccessManagerViews(): boolean {
    return this.canAccessAdmin() || this.checkUserRole(UserRole.Manager)
  }

  /**
   * Filter roles by current user's level
   *
   * @memberof AuthenticationService
   * @deprecated Use permissions instead {@link AuthorizationService}
   */
  getAllowedRoles(): UserRole[] {
    let roles = Object.values(UserRole)
    const hiddenRoles = [UserRole.Unknown, UserRole.NoRole]
    roles = roles.filter((role) => !hiddenRoles.includes(role))
    if (this.isSuper() || this.isAdmin()) {
      return roles
    }

    if (this.checkUserRole(UserRole.Executive)) {
      return roles.filter((role) => role !== UserRole.Admin)
    }

    if (this.checkUserRole(UserRole.Manager)) {
      return roles.filter((role) => ![UserRole.Admin, UserRole.Executive].includes(role))
    }

    if (this.checkUserRole(UserRole.Biller)) {
      return roles.filter((role) => ![UserRole.Admin, UserRole.Executive, UserRole.Manager].includes(role))
    }

    if (this.checkUserRole(UserRole.Annotator)) {
      return [UserRole.Annotator]
    }

    return []
  }

  /**
   * Is the current user Janus staff
   *
   * @memberof AuthenticationService
   * @deprecated Use isStaff in {@link AuthorizationService}
   */
  isStaff(): boolean {
    return this.currentUser$.value?.isStaff || false
  }

  /**
   * Is the current user Janus Super user
   *
   * @memberof AuthenticationService
   * @deprecated Use isSuper in {@link AuthorizationService}
   */
  isSuper(): boolean {
    return this.currentUser$.value?.isSuperuser || false
  }

  /**
   * Is the current user an admin
   *
   * @memberof AuthenticationService
   * @deprecated Use permissions instead {@link AuthorizationService}
   */
  isAdmin(): boolean {
    return this.checkUserRole(UserRole.Admin)
  }

  /**
   * Super users do not have more than one 'allowedOrgIds' so we also check for isSuperuser here.
   * @deprecated Use userHasMultipleOrgs in {@link AuthorizationService}
   */
  userHasMultipleOrgs(): boolean {
    return (this.currentUser$.value?.allowedOrgIds?.length > 1 || this.isSuper()) ?? false
  }
}
