import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ProcedureSetsService } from 'app/procedure-sets/procedure-sets.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { ProcedureSet } from 'generated/graphql'
import { ZendeskService } from 'app/shared/services/zendesk.service'

@Component({
  selector: 'app-prior-auth-docs-upload',
  templateUrl: './docs-upload.page.html',
  styleUrls: ['./docs-upload.page.scss'],
})
export class PriorAuthDocsUploadPage implements OnInit {
  isLoading: boolean = true
  procedureSet: ProcedureSet

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private procedureSetsService: ProcedureSetsService,
    protected zendesk: ZendeskService,
  ) {}

  ngOnInit(): void {
    const procedureSetId = this.route.snapshot.queryParamMap.get('procedure_set_id')
    if (!procedureSetId) {
      this.toast.error(`procedure_set_id is undefined`)
      return
    }
    debug('info', 'procedureSetId', procedureSetId)
    this.fetchProcedureSet(procedureSetId)
  }

  fetchProcedureSet(procedureSetId: string): void {
    this.procedureSetsService.getProcedureSet(procedureSetId).subscribe(
      (procedureSet) => {
        if (!procedureSet) {
          this.toast.error(`No procedure set found for id ${procedureSetId}`)
          return
        }

        this.procedureSet = procedureSet
        this.isLoading = false
      },
      (error) => {
        debug('info', error)
        this.toast.error(`Could not fetch procedures set with id ${procedureSetId}`)
      },
    )
  }
}
