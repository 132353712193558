import { formatNumber } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { isNil } from 'lodash'

@Pipe({
  name: 'approximation',
})
export class ApproximationPipe implements PipeTransform {
  constructor() {}

  transform(
    value: number,
    isApproximate: boolean,
    approximationOptions: {
      /**
       * The threshold before which exact numbers will be displayed even if isApproximate is true
       */
      maximumExactValue: number
      /**
       * How much above a value a number must be to be shown as more than that value;
       * for example, with a marginOfError of 50, 2049 would be shown as 1,000+, whereas 2050 would be shown as 2,000+
       */
      marginOfError: number
      /**
       * The precision with which approximate values are shown above the maximumExactValue;
       * for example, with an approximationPrecision of 1000, values would display as 2,000+ until they are high enough to be displayed as 3,000+
       */
      approximationPrecision: number
      locale: string
    } = {
      maximumExactValue: 1000,
      marginOfError: 50,
      approximationPrecision: 1000,
      locale: 'en-US',
    },
  ): string {
    if (isNil(value)) {
      return ''
    }

    let { maximumExactValue, marginOfError, approximationPrecision, locale } = approximationOptions

    if (isApproximate) {
      if (value <= maximumExactValue) {
        return `${formatNumber(value, locale)}`
      } else if (value < maximumExactValue + marginOfError) {
        // we assume that, if we are accurate up through `maximumExactValue`,
        // then `maximumExactValue + marginOfError`, while not necessarily accurate,
        // will at least be above `maximumExactValue`
        return `${formatNumber(maximumExactValue, locale)}+`
      } else {
        // if (value >= maximumExactValue + marginOfError)
        return `${formatNumber(
          Math.floor((value - marginOfError) / approximationPrecision) * approximationPrecision,
          locale,
        )}+`
      }
    } else {
      // if (!isApproximate)
      return `${formatNumber(value, locale)}`
    }
  }
}
