import { Component, Input, OnInit } from '@angular/core'

type status = 'opportunity' | 'implementing' | 'live'

@Component({
  selector: 'app-deployment-status',
  templateUrl: './deployment-status.component.html',
  styleUrls: ['./deployment-status.component.scss'],
})
export class DeploymentStatusComponent implements OnInit {
  @Input() completedSteps: number

  statusColors: { [key in status]: string } = {
    opportunity: 'alt-medium-gray',
    implementing: 'brand-green',
    live: 'alt-purple',
  }

  status: status = 'opportunity'

  constructor() {}

  ngOnInit(): void {
    if (this.completedSteps === 12) {
      this.status = 'live'
    } else if (this.completedSteps > 0) {
      this.status = 'implementing'
    }
  }
}
