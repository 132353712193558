<div class="d-flex my-4">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img
      src="/assets/img/Janus_Health_horizontal_logo_white.svg"
      alt="Janus Health Logo"
      class="img-fluid"
      width="200px"
    />
  </div>
</div>
<div class="d-flex flex-column flex-grow-1 bg-alt-white p-4 row m-0">
  <div class="card my-1 p-0 border-0 bg-transparent col-lg-6 col-sm-9">
    <form [formGroup]="form" (ngSubmit)="save()" novalidate>
      <div class="form-group mb-5">
        <label for="request" class="h3 mb-2">Request</label>
        <div
          *ngIf="requestStatus !== null"
          class="d-flex justify-content-center alert align-items-center h3"
          [ngClass]="requestStatus? 'alert-success' : 'alert-danger'"
          role="alert"
        >
          <app-loading
            *ngIf="isSending"
            innerClass="fa-4x"
            outerClass="align-self-center text-primary"
            class="d-flex flex-column flex-grow-1 justify-content-center"
          ></app-loading>
          <i
            class="fa fa-2x mx-4"
            [ngClass]="requestStatus? 'text-success fa-check-square' : 'text-danger fa-times-circle'"
          ></i>
          {{requestStatus? 'Request successfully sent and saved!' : 'Something went wrong! Request failed.'}}
        </div>
        <textarea id="request" formControlName="request" class="form-control flex-grow-1" rows="1"></textarea>
        <label for="upload" class="h3 mt-5 mb-2">Upload</label>
        <div class="custom-file">
          <input id="upload" type="file" class="custom-file-input" formControlName="upload" />
          <label class="custom-file-label" for="upload">Choose Upload</label>
        </div>
      </div>
      <div class="d-flex flex-nowrap justify-content-end">
        <a class="btn btn-cancel" routerLink="/"> Cancel </a>
        <button type="submit" class="btn btn-alt-light-blue px-5 py-2 rounded-lgr">
          <i class="fa fa-paper-plane mr-2"></i>
          Send
        </button>
      </div>
    </form>
  </div>
</div>
