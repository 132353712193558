import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TeamsService } from 'app/admin/teams/teams.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Team, User } from 'generated/graphql'
import { Subscription } from 'rxjs'
import { first } from 'rxjs/operators'
import { CreateTeamComponent } from './components/create-team/create-team.component'

/**
 * Page to display and search a list of teams
 *
 * @export
 * @class TeamsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-teams',
  templateUrl: './teams.page.html',
  styleUrls: ['./teams.page.scss'],
  host: { class: 'h-100' },
})
export class TeamsPage implements OnInit, OnDestroy {
  teamsSearch = new UntypedFormControl('')
  teamsSub$: Subscription

  isLoading: boolean = false
  class: string

  supervisors: User[]
  teamPods: {
    team: Team
    supervisor: User
  }[] = []

  constructor(
    private router: Router,
    private modal: NgbModal,
    private toast: ToastService,
    public teamsService: TeamsService,
    private usersService: UsersService,
  ) {
    this.teamsSub$ = this.teamsService.searchTeams(this.teamsSearch?.value).subscribe((event) => {
      this.isLoading = event.loading
      if (event.error) {
        this.toast.error(parseGraphQLError(event, 'Could not load teams'), JSON.stringify(event.error))
        this.router.navigate(['admin'])
      }
      if (event.data) {
        let teams = event.data.teams.entities
        this.setTeamPod(teams)
      }
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.teamsSub$.unsubscribe()
  }

  /**
   * Load a list of teams
   *
   * @return {*}  {Promise<void>}
   * @memberof TeamsPage
   */
  async searchTeams(): Promise<void> {
    this.isLoading = true
    try {
      let result = await this.teamsService
        .searchTeams(this.teamsSearch?.value)
        .pipe(first())
        .toPromise()
        .catch((e) => {
          throw new Error(e)
        })
      let teams = result?.data?.teams?.entities
      this.setTeamPod(teams)
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load teams'), JSON.stringify(e))
      this.router.navigate(['admin'])
    }
    this.isLoading = false
  }

  /**
   *
   *
   * @memberof TeamsPage
   */
  createTeam(): void {
    const modalRef = this.modal.open(CreateTeamComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'modal-extra-padding',
    })
    modalRef.result.then(
      async (result) => {
        modalRef.close()
      },
      (dismiss) => {},
    )
  }

  /**
   * Add full user object for team supervisor
   *
   * @param {Team[]} teams
   * @memberof TeamsPage
   */
  setTeamPod(teams: Team[]): void {
    let pods = []
    teams.map(async (team) => {
      let supervisor
      if (team?.supervisorUserId) {
        supervisor = await this.usersService
          .getUser(team.supervisorUserId)
          .pipe(first())
          .toPromise()
          .catch((e) => null)
      }
      pods.push({ team, supervisor })
    })
    this.teamPods = pods
  }
}
