import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class OrgSettingService {
  private readonly ORG_SETTING_URL = '/api/org-setting'

  constructor(private http: HttpClient) {}

  getOrgSettingValue(orgSettingKey: string, options?: { responseType: 'text' }): Observable<any> {
    return this.http.get(`${this.ORG_SETTING_URL}/${orgSettingKey}`, options)
  }
}
