import { EdgeConfig, NodeConfig } from '@antv/g6'
import {
  GREEN_EDGE,
  GREEN_NODE,
  IMA_EDGE,
  IMA_NODE,
  ORANGE_EDGE,
  ORANGE_NODE,
  PURPLE_EDGE,
  PURPLE_NODE,
  RED_EDGE,
  RED_NODE,
} from '../nodes'

export const MainNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  {
    id: '1',
    label: 'Open WQ - Medicare Denials',
    totalCount: '7804',
    totalTime: '8',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '2', label: 'Select Claim on WQ', totalCount: '7804', totalTime: '10', totalPercent: '100', ...GREEN_NODE },
  {
    id: '3',
    label: 'Open Transaction Inquiry',
    totalCount: '7804',
    totalTime: '13',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: "Click 'View Claim Image'",
    totalCount: '2280',
    totalTime: '20',
    totalPercent: '29',
    ...GREEN_NODE,
  },
  {
    id: '4a',
    label: 'Open Transaction Details',
    totalCount: '5524',
    totalTime: '22',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  {
    id: '5',
    label: 'Click into 1st Service Line View',
    totalCount: '5524',
    totalTime: '26',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  {
    id: '6',
    label: 'Click into 2nd Service Line View',
    totalCount: '4906',
    totalTime: '39',
    totalPercent: '62',
    ...ORANGE_NODE,
  },
  { id: '7', label: 'Tab to Cerner', totalCount: '7804', totalTime: '55', totalPercent: '100', ...GREEN_NODE },
  { id: '8', label: 'Open Patient Visit', totalCount: '7804', totalTime: '98', totalPercent: '100', ...GREEN_NODE },
  {
    id: '9',
    label: 'Open Provider Notes - Diagnosis',
    totalCount: '7804',
    totalTime: '109',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '10', label: 'Tab to Epic', totalCount: '7804', totalTime: '169', totalPercent: '100', ...GREEN_NODE },
  {
    id: '11',
    label: 'Open Charge Correction',
    totalCount: '7804',
    totalTime: '183',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '12', label: 'CC - Update Diagnosis', totalCount: '5087', totalTime: '197', totalPercent: '65', ...GREEN_NODE },
  {
    id: '13',
    label: 'CC - Add Modifier 57',
    totalCount: '6712',
    totalTime: '215',
    totalPercent: '86',
    impactOnDenial: -94,
    relativeImpactOnCollection: 44,
    absoluteImpactOnCollection: 77.01,
    ...GREEN_NODE,
  },
  {
    id: '13a',
    label: 'CC - Add Modifier 25',
    totalCount: '1092',
    totalTime: '200',
    totalPercent: '13',
    impactOnDenial: 94,
    relativeImpactOnCollection: -44,
    absoluteImpactOnCollection: -77.01,
    ...RED_NODE,
  },
  {
    id: '14',
    label: 'CC - Complete Charge Correction',
    totalCount: '6712',
    totalTime: '241',
    totalPercent: '86',
    ...GREEN_NODE,
  },
]

export const MainEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  // { id: 'path_start_1', source: 'start', target: '1', time: 8, label:  },
  { id: 'path_1_2', source: '1', target: '2', time: 2, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_2_3', source: '2', target: '3', time: 3, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_3_4', source: '3', target: '4', time: 7, label: '2280', percent: '29', count: 2280, ...GREEN_EDGE },
  { id: 'path_3_4a', source: '3', target: '4a', time: 6, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_4a_5', source: '4a', target: '5', time: 4, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_5_6', source: '5', target: '6', time: 13, label: '4906', percent: '63', count: 4906, ...ORANGE_EDGE },
  { id: 'path_6_7', source: '6', target: '7', time: 22, label: '4906', percent: '63', count: 4906, ...GREEN_EDGE },
  { id: 'path_4_7', source: '4', target: '7', time: 24, label: '2280', percent: '29', count: 2280, ...GREEN_EDGE },
  { id: 'path_5_7', source: '5', target: '7', time: 21, label: '618', percent: '8', count: 618, ...RED_EDGE },
  { id: 'path_7_8', source: '7', target: '8', time: 43, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_8_9', source: '8', target: '9', time: 11, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_9_10', source: '9', target: '10', time: 58, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_10_11', source: '10', target: '11', time: 14, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_11_13', source: '11', target: '13', time: 9, label: '2106', percent: '27', count: 2106, ...RED_EDGE },
  { id: 'path_11_12', source: '11', target: '12', time: 14, label: '5087', percent: '65', count: 5087, ...GREEN_EDGE },
  { id: 'path_12_13', source: '12', target: '13', time: 28, label: '4609', percent: '59', count: 4609, ...GREEN_EDGE },
  { id: 'path_11_13a', source: '11', target: '13a', time: 11, label: '611', percent: '8', count: 611, ...RED_EDGE },
  { id: 'path_12_13a', source: '12', target: '13a', time: 10, label: '481', percent: '6', count: 481, ...RED_EDGE },
  { id: 'path_13_14', source: '13', target: '14', time: 28, label: '6712', percent: '86', count: 6712, ...GREEN_EDGE },
  {
    id: 'path_13a_14',
    source: '13a',
    target: '14',
    time: 27,
    label: '1092',
    percent: '14',
    count: 1092,
    ...GREEN_EDGE,
  },
]

export const IdealNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  {
    id: '1',
    label: 'Open WQ - Medicare Denials',
    totalCount: '7804',
    totalTime: '8',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '2', label: 'Select Claim on WQ', totalCount: '7804', totalTime: '10', totalPercent: '100', ...GREEN_NODE },
  {
    id: '3',
    label: 'Open Transaction Inquiry',
    totalCount: '7804',
    totalTime: '13',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: "Click 'View Claim Image'",
    totalCount: '2280',
    totalTime: '20',
    totalPercent: '29',
    ...GREEN_NODE,
  },
  { id: '7', label: 'Tab to Cerner', totalCount: '7804', totalTime: '55', totalPercent: '100', ...GREEN_NODE },
  { id: '8', label: 'Open Patient Visit', totalCount: '7804', totalTime: '98', totalPercent: '100', ...GREEN_NODE },
  {
    id: '9',
    label: 'Open Provider Notes - Diagnosis',
    totalCount: '7804',
    totalTime: '109',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '10', label: 'Tab to Epic', totalCount: '7804', totalTime: '169', totalPercent: '100', ...GREEN_NODE },
  {
    id: '11',
    label: 'Open Charge Correction',
    totalCount: '7804',
    totalTime: '183',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '12', label: 'CC - Update Diagnosis', totalCount: '5087', totalTime: '197', totalPercent: '65', ...GREEN_NODE },
  {
    id: '13',
    label: 'CC - Add Modifier 57',
    totalCount: '6712',
    totalTime: '215',
    totalPercent: '86',
    impactOnDenial: -94,
    relativeImpactOnCollection: 44,
    absoluteImpactOnCollection: 77.01,
    ...GREEN_NODE,
  },
  {
    id: '14',
    label: 'CC - Complete Charge Correction',
    totalCount: '6712',
    totalTime: '241',
    totalPercent: '86',
    ...GREEN_NODE,
  },
]
export const IdealEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  { id: 'path_1_2', source: '1', target: '2', time: 2, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_2_3', source: '2', target: '3', time: 3, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_3_4', source: '3', target: '4', time: 7, label: '2280', percent: '29', count: 2280, ...GREEN_EDGE },
  { id: 'path_4_7', source: '4', target: '7', time: 24, label: '2280', percent: '29', count: 2280, ...GREEN_EDGE },
  { id: 'path_7_8', source: '7', target: '8', time: 43, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_8_9', source: '8', target: '9', time: 11, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_9_10', source: '9', target: '10', time: 58, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_10_11', source: '10', target: '11', time: 14, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_11_12', source: '11', target: '12', time: 14, label: '5087', percent: '65', count: 5087, ...GREEN_EDGE },
  { id: 'path_12_13', source: '12', target: '13', time: 28, label: '4609', percent: '59', count: 4609, ...GREEN_EDGE },
  { id: 'path_13_14', source: '13', target: '14', time: 28, label: '6712', percent: '86', count: 6712, ...GREEN_EDGE },
]

export const PartialAutomationNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  {
    id: '1',
    label: 'Pull Provider Notes \nDiagnosis',
    totalCount: '7804',
    totalTime: '5',
    totalPercent: '100',
    ...PURPLE_NODE,
  },
  {
    id: '2',
    label: 'Provide Modifier Recommendation',
    totalCount: '7804',
    totalTime: '5',
    totalPercent: '100',
    ...PURPLE_NODE,
  },
  {
    id: '3',
    label: 'User Approve / Decline Modifier',
    totalCount: '7804',
    totalTime: '25',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: 'Update Modifier on Claim',
    totalCount: '7804',
    totalTime: '5',
    totalPercent: '100',
    ...PURPLE_NODE,
  },
  {
    id: '5',
    label: 'Complete Charge Correction',
    totalCount: '7804',
    totalTime: '5',
    totalPercent: '100',
    ...PURPLE_NODE,
  },
]
export const PartialAutomationEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  { id: 'path_1_2', source: '1', target: '2', time: 5, label: '7804', percent: '100', count: 7804, ...PURPLE_EDGE },
  { id: 'path_2_3', source: '2', target: '3', time: 5, label: '7804', percent: '100', count: 7804, ...PURPLE_EDGE },
  { id: 'path_3_4', source: '3', target: '4', time: 25, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_4_5', source: '4', target: '5', time: 5, label: '7804', percent: '100', count: 7804, ...PURPLE_EDGE },
]

export const LongestNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  {
    id: '1',
    label: 'Open WQ - Medicare Denials',
    totalCount: '7804',
    totalTime: '12',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '2', label: 'Select Claim on WQ', totalCount: '7804', totalTime: '22', totalPercent: '100', ...GREEN_NODE },
  {
    id: '3',
    label: 'Open Transaction Inquiry',
    totalCount: '7804',
    totalTime: '27',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '4a',
    label: 'Open Transaction Details',
    totalCount: '5524',
    totalTime: '25',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  {
    id: '5',
    label: 'Click into 1st Service Line View',
    totalCount: '5524',
    totalTime: '35',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  {
    id: '6',
    label: 'Click into 2nd Service Line View',
    totalCount: '4906',
    totalTime: '78',
    totalPercent: '62',
    ...ORANGE_NODE,
  },
  { id: '7', label: 'Tab to Cerner', totalCount: '4906', totalTime: '131', totalPercent: '100', ...GREEN_NODE },
  { id: '8', label: 'Open Patient Visit', totalCount: '4906', totalTime: '189', totalPercent: '100', ...GREEN_NODE },
  {
    id: '9',
    label: 'Open Provider Notes - Diagnosis',
    totalCount: '4906',
    totalTime: '205',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '10', label: 'Tab to Epic', totalCount: '4906', totalTime: '228', totalPercent: '100', ...GREEN_NODE },
  {
    id: '11',
    label: 'Open Charge Correction',
    totalCount: '4906',
    totalTime: '252',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '12', label: 'CC - Update Diagnosis', totalCount: '3890', totalTime: '283', totalPercent: '65', ...GREEN_NODE },
  {
    id: '13',
    label: 'CC - Add Modifier 57',
    totalCount: '3890',
    totalTime: '288',
    totalPercent: '86',
    impactOnDenial: -94,
    relativeImpactOnCollection: 44,
    absoluteImpactOnCollection: 77.01,
    ...GREEN_NODE,
  },
  {
    id: '14',
    label: 'CC - Complete Charge Correction',
    totalCount: '3890',
    totalTime: '325',
    totalPercent: '86',
    ...GREEN_NODE,
  },
]
export const LongestEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  // { id: 'path_start_1', source: 'start', target: '1', time: 8, label:  },
  { id: 'path_1_2', source: '1', target: '2', time: 2, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_2_3', source: '2', target: '3', time: 3, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_3_4a', source: '3', target: '4a', time: 7, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_4a_5', source: '4a', target: '5', time: 4, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_5_6', source: '5', target: '6', time: 13, label: '4906', percent: '63', count: 4906, ...ORANGE_EDGE },
  { id: 'path_6_7', source: '6', target: '7', time: 22, label: '4906', percent: '63', count: 4906, ...GREEN_EDGE },
  { id: 'path_7_8', source: '7', target: '8', time: 43, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_8_9', source: '8', target: '9', time: 11, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_9_10', source: '9', target: '10', time: 58, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_10_11', source: '10', target: '11', time: 14, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_11_12', source: '11', target: '12', time: 14, label: '5087', percent: '65', count: 5087, ...GREEN_EDGE },
  { id: 'path_12_13', source: '12', target: '13', time: 28, label: '4609', percent: '59', count: 4609, ...GREEN_EDGE },
  { id: 'path_13_14', source: '13', target: '14', time: 28, label: '6712', percent: '86', count: 6712, ...GREEN_EDGE },
]

export const LowestNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  {
    id: '1',
    label: 'Open WQ - Medicare Denials',
    totalCount: '7804',
    totalTime: '8',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '2', label: 'Select Claim on WQ', totalCount: '7804', totalTime: '10', totalPercent: '100', ...GREEN_NODE },
  {
    id: '3',
    label: 'Open Transaction Inquiry',
    totalCount: '7804',
    totalTime: '13',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '4a',
    label: 'Open Transaction Details',
    totalCount: '5524',
    totalTime: '22',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  {
    id: '5',
    label: 'Click into 1st Service Line View',
    totalCount: '5524',
    totalTime: '26',
    totalPercent: '70',
    ...ORANGE_NODE,
  },
  { id: '7', label: 'Tab to Cerner', totalCount: '7804', totalTime: '55', totalPercent: '100', ...GREEN_NODE },
  { id: '8', label: 'Open Patient Visit', totalCount: '7804', totalTime: '98', totalPercent: '100', ...GREEN_NODE },
  {
    id: '9',
    label: 'Open Provider Notes - Diagnosis',
    totalCount: '7804',
    totalTime: '109',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  { id: '10', label: 'Tab to Epic', totalCount: '7804', totalTime: '169', totalPercent: '100', ...GREEN_NODE },
  {
    id: '11',
    label: 'Open Charge Correction',
    totalCount: '7804',
    totalTime: '183',
    totalPercent: '100',
    ...GREEN_NODE,
  },
  {
    id: '13a',
    label: 'CC - Add Modifier 25',
    totalCount: '1092',
    totalTime: '200',
    totalPercent: '13',
    impactOnDenial: 94,
    relativeImpactOnCollection: -44,
    absoluteImpactOnCollection: -77.01,
    ...RED_NODE,
  },
  {
    id: '14',
    label: 'CC - Complete Charge Correction',
    totalCount: '6712',
    totalTime: '241',
    totalPercent: '86',
    ...GREEN_NODE,
  },
]

export const LowestEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  { id: 'path_1_2', source: '1', target: '2', time: 2, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_2_3', source: '2', target: '3', time: 3, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_3_4a', source: '3', target: '4a', time: 6, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_4a_5', source: '4a', target: '5', time: 4, label: '5524', percent: '70', count: 5524, ...ORANGE_EDGE },
  { id: 'path_5_7', source: '5', target: '7', time: 21, label: '618', percent: '8', count: 618, ...RED_EDGE },
  { id: 'path_7_8', source: '7', target: '8', time: 43, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_8_9', source: '8', target: '9', time: 11, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_9_10', source: '9', target: '10', time: 58, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_10_11', source: '10', target: '11', time: 14, label: '7804', percent: '100', count: 7804, ...GREEN_EDGE },
  { id: 'path_11_13a', source: '11', target: '13a', time: 11, label: '611', percent: '8', count: 611, ...RED_EDGE },
  {
    id: 'path_13a_14',
    source: '13a',
    target: '14',
    time: 27,
    label: '1092',
    percent: '14',
    count: 1092,
    ...GREEN_EDGE,
  },
]

export const IdealData = {
  count: '1820',
  frequency: '23.30',
  volume: '397124',
  outstanding: '218.20',
  payment: '218.20',
  payment_frequency: '97',
  outcome: '211.78',
  time: 25,
  fte_cost: 40,
  mean_cost: '0.27',
  net_cost: '211.51',
  lag: '27',
  variance: 0,
}
// export const PathfinderData = {
//   count: 'N/A',
//   frequency: 'N/A',
//   volume: '356086.91',
//   outstanding: '156.11',
//   payment: '136.12',
//   payment_frequency: '97.5',
//   outcome: '132.72',
//   time: 696,
//   fte_cost: 40,
//   mean_cost: '7.73',
//   net_cost: '124.98',
//   lag: '28',
//   variance: 0,
// }
export const PartialAutoData = {
  count: null,
  frequency: null,
  volume: '1702832.80',
  outstanding: '218.20',
  payment: '218.20',
  payment_frequency: '97',
  outcome: '211.78',
  time: 25,
  fte_cost: 40,
  mean_cost: '0.27',
  net_cost: '211.51',
  lag: '27',
  variance: 0,
}
// export const FullAutoData = {
//   count: 'N/A',
//   frequency: 'N/A',
//   volume: '356086.91',
//   outstanding: '156.11',
//   payment: '136.12',
//   payment_frequency: '98.4',
//   outcome: '133.94',
//   time: 32,
//   fte_cost: 0,
//   mean_cost: '0',
//   net_cost: '133.94',
//   lag: '23',
//   variance: 0,
// }
export const LongestData = {
  count: '3890',
  frequency: '49.80',
  volume: '848010.73',
  outstanding: '218.20',
  payment: '210.20',
  payment_frequency: '88',
  outcome: '184.98',
  time: 325,
  fte_cost: 40,
  mean_cost: '3.62',
  net_cost: '181.35',
  lag: '34',
  variance: '3.4',
}
export const LowestData = {
  count: '412',
  frequency: '5.28',
  volume: '89898.40',
  outstanding: '218.20',
  payment: '148.20',
  payment_frequency: '46',
  outcome: '68.17',
  time: 161,
  fte_cost: 40,
  mean_cost: '1.79',
  net_cost: '66.38',
  lag: '46',
  variance: '3.6',
}
