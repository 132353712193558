import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import {
  AddTasksToTaskGroup,
  DeleteTaskGroup,
  GetTaskGroup,
  GetTaskGroups,
  SaveTaskGroup,
  SearchTaskGroups,
} from 'app/pathfinder/task-groups/task-groups.gql'
import { debug } from 'app/shared/utils/debug'
import {
  ListResponseMetaData,
  MutationAddTasksToTaskGroupArgs,
  MutationDeleteTaskGroupArgs,
  MutationSaveTaskGroupArgs,
  QueryTaskGroupArgs,
  QueryTaskGroupsArgs,
  TaskGroup,
  TaskGroupInput,
  TaskGroupList,
} from 'generated/graphql'

export const TASK_GROUPS_PAGE_LIMIT = 10

/**
 * Handle task group operations
 *
 * @export
 * @class TaskGroupsService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskGroupsService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*} {ListResponseMetaData}
   * @memberof TaskGroupsService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve default list of task groups
   *
   * @return {*}  {Promise<TaskGroup[]>}
   * @memberof TaskGroupsService
   */
  async getTaskGroups(): Promise<TaskGroup[]> {
    debug('task-service', 'get tasks')
    let result = await this.apollo
      .query<{ taskGroups: TaskGroupList }, QueryTaskGroupsArgs>({
        query: GetTaskGroups,
        variables: {
          limit: TASK_GROUPS_PAGE_LIMIT,
        },
      })
      .toPromise()

    this.meta = result.data.taskGroups?.meta

    return result.data.taskGroups?.entities
  }

  /**
   * Retrieve an individual task group
   *
   * @param {string} id
   * @return {*}  {Promise<TaskGroup>}
   * @memberof TaskGroupsService
   */
  async getTaskGroup(id: string): Promise<TaskGroup> {
    debug('task-service', 'get task group', id)
    let result = await this.apollo
      .query<{ taskGroup: TaskGroup }, QueryTaskGroupArgs>({
        query: GetTaskGroup,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.taskGroup
  }

  /**
   * Update an existing task group
   *
   * @param {TaskGroupInput} data
   * @param {string} [id]
   * @return {*}  {Promise<TaskGroup>}
   * @memberof TaskGroupsService
   */
  async saveTaskGroup(data: TaskGroupInput, id?: string): Promise<TaskGroup> {
    let variables = {
      data: data,
    }
    if (id) {
      variables['id'] = id
    }

    let result = await this.apollo
      .mutate<{ saveTaskGroup: TaskGroup }, MutationSaveTaskGroupArgs>({
        mutation: SaveTaskGroup,
        variables: variables,
      })
      .toPromise()

    return result.data.saveTaskGroup
  }

  /**
   * Retrieve a list of task groups based on variables
   *
   * @param {string} term
   * @param {string} [user]
   * @param {string} [sort]
   * @param {number} [offset=0]
   * @param {boolean} [includeCompleted=false]
   * @return {*}  {Promise<TaskGroup[]>}
   * @memberof TaskGroupsService
   */
  async searchTaskGroups(
    term: string,
    user?: string,
    sort?: string,
    offset: number = 0,
    includeCompleted: boolean = false,
  ): Promise<TaskGroup[]> {
    // if (term === '' && user === null && sort === null) {
    //   return await this.getTaskGroups()
    // }
    let variables = {
      search: term,
      limit: TASK_GROUPS_PAGE_LIMIT,
      sort: sort,
      offset,
      includeCompleted,
    }
    if (user >= '0') {
      variables['userId'] = user
    }
    let result = await this.apollo
      .query<{ taskGroups: TaskGroupList }, QueryTaskGroupsArgs>({
        query: SearchTaskGroups,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .toPromise()

    this.meta = result.data.taskGroups?.meta

    return result.data.taskGroups?.entities
  }

  /**
   * Assign a list of tasks to a task group
   *
   * @param {string[]} taskIds
   * @param {string} taskGroupId
   * @return {*}  {Promise<boolean>}
   * @memberof TaskGroupsService
   */
  async addTasksToTaskGroup(taskIds: string[], taskGroupId: string): Promise<boolean> {
    debug('task-service', 'add tasks to task group', taskIds, taskGroupId)
    let result = await this.apollo
      .mutate<{ addTasksToTaskGroup: boolean }, MutationAddTasksToTaskGroupArgs>({
        mutation: AddTasksToTaskGroup,
        variables: {
          taskIds: taskIds,
          taskGroupId: taskGroupId,
        },
      })
      .toPromise()

    return result.data.addTasksToTaskGroup
  }

  /**
   * Delete a task group
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof TaskGroupsService
   */
  async deleteTaskGroup(id: string): Promise<boolean> {
    debug('task-group-service', 'delete task group', id)
    let result = await this.apollo
      .mutate<{ deleteTaskGroup: boolean }, MutationDeleteTaskGroupArgs>({
        mutation: DeleteTaskGroup,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.deleteTaskGroup
  }
}
