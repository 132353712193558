<div class="card detail-card">
  <div class="card-header">Team Quick Stats</div>
  <div class="card-body p-3">
    <div class="card">
      <div class="card-header bg-light text-dark">Tasks</div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-borderless table-sm">
            <thead>
              <th scope="col"><span class="sr-only">Time Period</span></th>
              <th scope="col" class="text-right">Created</th>
              <th scope="col" class="text-right">Completed</th>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Today</th>
                <td class="text-right text-brand-light-blue">{{ created.day | number }}</td>
                <td class="text-right text-brand-green">{{ completed.day | number }}</td>
              </tr>
              <tr>
                <th scope="row">This Week</th>
                <td class="text-right text-brand-light-blue">{{ created.week | number }}</td>
                <td class="text-right text-brand-green">{{ completed.week | number }}</td>
              </tr>
              <tr>
                <th scope="row">This Month</th>
                <td class="text-right text-brand-light-blue">{{ created.month | number }}</td>
                <td class="text-right text-brand-green">{{ completed.month | number }}</td>
              </tr>
              <tr>
                <th scope="row">This Year</th>
                <td class="text-right text-brand-light-blue">{{ created.year | number }}</td>
                <td class="text-right text-brand-green">{{ completed.year | number }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
