<div class="container d-flex flex-column min-vh-100">
  <div class="row d-flex justify-content-center my-5">
    <div class="col-md-4 col-8 d-flex justify-content-center">
      <div class="px-4" id="janus-logo">
        <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" height="50px" />
      </div>
      <div class="px-4" id="teleport-logo">
        <img src="/assets/img/teleport-logo.svg" height="50px" />
      </div>
    </div>
  </div>
  <div class="row text-alt-light-gray">
    <div class="col-8 offset-2 py-3">
      <p>
        Welcome to Teleport!
        <a
          href="https://www.janus-ai.com/platforms/teleport"
          target="_blank"
          class="text-underline"
          ngbTooltip="www.janus-ai.com/platforms/teleport"
          placement="bottom"
          container="body"
          >Teleport</a
        >
        automates the process of logging in and navigating payer portals. It will take you directly to the Claim Status
        page.
      </p>
    </div>
  </div>
  <div class="row no-gutters justify-content-center my-4">
    <div class="text-alt-dark-gray rounded col-12" *ngIf="!isLoading; else loadingBlock">
      <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md col p-0">
        <div *ngIf="!claim" class="d-flex justify-content-center alert alert-danger" role="alert">
          The specified claim could not be found.
        </div>
        <div
          *ngIf="claim && !isTeleportSupported"
          class="d-flex justify-content-center alert alert-danger"
          role="alert"
        >
          Teleport is not supported for this claim.
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
<ng-template #loadingBlock class="h-100 d-flex align-items-center justify-content-center">
  <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
</ng-template>
