<div class="form-group">
  <label for="orgSetId">Select an Org Set ID</label>
  <div class="d-flex align-items-center">
    <div class="input-group">
      <input id="orgSetId" type="text" class="form-control" [formControl]="orgSetIdFc" [ngbTypeahead]="search" />
      <span class="in-input-icon d-flex align-items-center">
        <button
          *ngIf="orgSetIdFc?.value?.length"
          type="button"
          (click)="orgSetIdFc.setValue('')"
          class="btn bg-transparent"
        >
          <i class="fa fa-times fa-fw"></i>
          <span class="sr-only">Clear Org Set ID</span>
        </button>
      </span>
    </div>
    <button
      class="btn btn-primary ml-3"
      type="button"
      (click)="createNewOrgSetId()"
      ngbTooltip="Generate new Org Set ID"
      container="body"
    >
      <i class="fa fa-fw fa-plus"></i>
      <span class="sr-only">Generate new Org Set ID</span>
    </button>
  </div>
</div>
