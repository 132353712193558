<div class="user-org-assignments">
  <div class="mb-4">
    <app-org-roles
      *ngIf="primaryOrganization"
      [organization]="primaryOrganization"
      [roles]="allRoles[primaryOrganization.id] ?? []"
      [selectedRoles]="userRoles[primaryOrganization.id] ?? []"
      (rolesChange)="handleRolesChange($event, primaryOrganization)"
      [isOrganizationRemovable]="false"
      [loading]="loading"
      [primary]="true"
    ></app-org-roles>
  </div>

  <div class="mb-4" *ngFor="let org of allowedOrganizations">
    <app-org-roles
      [organization]="org"
      [roles]="allRoles[org.id] ?? []"
      [selectedRoles]="userRoles[org.id] ?? []"
      (rolesChange)="handleRolesChange($event, org)"
      (removeOrganization)="openRemovalModal(org)"
      [isOrganizationRemovable]="true"
      [loading]="loading"
    ></app-org-roles>
  </div>

  <button type="button" class="btn btn-secondary" (click)="openOrgAssignModal()">Assign Organization</button>
</div>
