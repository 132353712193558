import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { FeatureFlagType, CreateFeatureFlagInput, FeatureFlag } from 'generated/graphql'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'
import { DashboardSettingLabels } from '../edit/edit-org-dashboard.page.component'
import { OrgSettingsService } from '../services/org-settings.service'
import { validate } from 'uuid'
import { AuthenticationService } from 'app/auth/authentication.service'

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss'],
})
export class OrganizationManagementComponent implements OnInit {
  @Input()
  orgId: string

  @Input()
  settingType: DashboardSettingLabels

  dashboardSettingForm: UntypedFormGroup = new UntypedFormGroup({
    dashboardLink: new UntypedFormControl(''),
    publish: new UntypedFormControl(false),
  })

  existingOrgSettingId: string

  destroy$ = new Subject<void>()

  constructor(
    private featureFlagService: FeatureFlagService,
    private orgSettingService: OrgSettingsService,
    private toast: ToastService,
    private authenticationService: AuthenticationService
  ) {}

  async ngOnInit(): Promise<void> {
    window.scrollTo({
      top: 0,
    })

    const result = await this.orgSettingService
      .getDashboardSetting(this.orgId, this.settingType.lookUpKey, this.settingType.featureFlagName)
      .pipe(first())
      .toPromise()

    this.existingOrgSettingId = result.data.orgDashboardSetting.orgSettingId

    this.dashboardSettingForm.patchValue({
      publish: result.data.orgDashboardSetting.published,
      dashboardLink: result.data.orgDashboardSetting.dashboardLink,
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  async saveOrgChanges(): Promise<void> {
    const dashboardLink = this.dashboardSettingForm.get('dashboardLink').value
    if (!dashboardLink) {
      await this.clearDashBoardLink()
      return
    } else if (dashboardLink && !validate(dashboardLink)) {
      this.toast.error('Invalid dashboard UUID')
      return
    }

    if (!this.dashboardSettingForm.touched) {
      this.toast.info('No Changes Detected')
      return
    }

    let saveResult = this.existingOrgSettingId
      ? await this.updateOrgDashboardLink(dashboardLink)
      : await this.createOrgDashboardLink(dashboardLink)

    if (saveResult) {
      this.toast.success('Successfully updated Organization Dashboard Link')
    } else {
      this.toast.error('Error updating Organization Dashboard Link')
      return
    }

    const published = this.dashboardSettingForm.get('publish')
    if (published.touched) {
      const exisitingFeatureFlag = await this.getFeatureFlag()
      if (exisitingFeatureFlag) {
        await this.updateFeatureFlag(published.value, exisitingFeatureFlag.id)

        // We only want to create a new feature flag if: no existing flag and publish === true
      } else if (published.value) {
        await this.createFeatureFlag(published.value)
      }
      const userCurrentOrgId = this.authenticationService.getUser()?.orgId
      if(this.orgId === userCurrentOrgId) {
        this.windowReload()
      } 
      
    }
  }

  async updateOrgDashboardLink(dashboardLink: string): Promise<any> {
    return await this.orgSettingService.updateDashboardLink(
      this.orgId,
      dashboardLink,
      this.existingOrgSettingId,
      this.settingType.lookUpKey,
      this.settingType.featureFlagName,
    )
  }

  async createOrgDashboardLink(dashboardLink: string): Promise<any> {
    const saveResult = await this.orgSettingService.createDashboardLink(
      this.orgId,
      dashboardLink,
      this.settingType.lookUpKey,
      this.settingType.featureFlagName,
    )
    this.existingOrgSettingId = saveResult.data?.createOrgSetting?.id
    return saveResult
  }

  async updateFeatureFlag(publish: boolean, existingFlagId: string): Promise<void> {
    await this.featureFlagService.updateFeatureFlag({
      name: this.settingType.featureFlagName,
      value: publish,
      type: FeatureFlagType.Organization,
      id: existingFlagId,
      typeId: this.orgId,
    })
    this.orgSettingService.updatePublishedDashboardSettingCache(
      this.orgId,
      this.settingType.lookUpKey,
      this.settingType.featureFlagName,
      publish,
    )
  }

  async createFeatureFlag(publish: boolean): Promise<void> {
    const flagInfo: CreateFeatureFlagInput = {
      name: this.settingType.featureFlagName,
      value: publish,
      type: FeatureFlagType.Organization,
      typeId: this.orgId,
    }
    await this.featureFlagService.createFeatureFlag(flagInfo)
    this.orgSettingService.updatePublishedDashboardSettingCache(
      this.orgId,
      this.settingType.lookUpKey,
      this.settingType.featureFlagName,
      publish,
    )
  }

  async getFeatureFlag(): Promise<FeatureFlag> | null {
    const flagName = this.settingType.featureFlagName

    const featureFlagResult = await this.featureFlagService.getFeatureFlags([flagName]).pipe(first()).toPromise()

    return (
      featureFlagResult.data.featureFlags.find((flag) => {
        return flag.type === 'Organization' && flag.typeId === this.orgId && flag.name === flagName
      }) || null
    )
  }

  async clearDashBoardLink(): Promise<void> {
    await this.updateOrgDashboardLink('')
    this.dashboardSettingForm.patchValue({
      publish: false,
    })
    const exisitingFeatureFlag = await this.getFeatureFlag()
    if (exisitingFeatureFlag) {
      await this.updateFeatureFlag(false, exisitingFeatureFlag.id)
    }

    this.toast.success('Successfully deleted Organization Dashboard Link')
  }

  // To avoid the unit test accidentily calling window.location
  // This function is overwritten with a null spy within the unit test
  windowReload(): void {
    window.location.reload()
  }
}
