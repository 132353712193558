<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <div class="d-flex">
      <div>{{ data.comparisonProps[0] }}</div>
      <div class="flex-shrink-0 mx-4">
        <select class="form-control" [formControl]="selectedOperator">
          <option *ngFor="let option of data.options" [value]="option.value">{{ option.display }}</option>
        </select>
      </div>
      <div>{{ data.comparisonProps[1] }}</div>
    </div>
  </div>
</div>
