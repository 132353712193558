<h5 class="mb-3">Claim Status</h5>
<div class="card bg-light mb-5">
  <div class="card-body">
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">Claim Status Date</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.statusDate ? (claim.statusDate | date: 'MM/dd/yyyy') : ''"></app-copy-value>
      </dd>
    </div>

    <!-- ehrStatusCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.ehrStatusCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelineEHR; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            facet="ehrStatusCode"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelineEHR?.length">N/A</ng-template>
      </dd>
    </div>

    <!-- payerStatusCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.payerStatusCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelinePayer; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            facet="payerStatusCode"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelinePayer?.length">N/A</ng-template>
      </dd>
    </div>

    <!-- claimStatusCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.claimStatusCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelineClaim; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelineClaim?.length">N/A</ng-template>
      </dd>
    </div>

    <!-- categoryCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.categoryCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelineCat; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            facet="categoryCode"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelineCat?.length">N/A</ng-template>
      </dd>
    </div>

    <!-- claimAdjustmentReasonCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.claimAdjustmentReasonCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelineCAR; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelineCAR?.length">N/A</ng-template>
      </dd>
    </div>

    <!-- remitAdviceRemarkCodes -->
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">
        {{ getPropDetails('servicelines.remitAdviceRemarkCodes.code')?.heading }}
      </dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span class="flex-shrink-1" *ngFor="let c of servicelineRAR; last as l">
          <app-copy-value
            [value]="c?.code"
            [ngClass]="{ 'border-right border-dark px-2': !l, 'pl-2': l }"
            [ngbTooltip]="c?.description"
            container="body"
          ></app-copy-value>
        </span>
        <ng-template [ngIf]="!servicelineRAR?.length">N/A</ng-template>
      </dd>
    </div>
  </div>
</div>
