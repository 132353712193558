import { Component } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { OrgSwitcherModalComponent } from 'app/shared/components/org-switcher-modal/org-switcher-modal.component'

@Component({
  selector: 'app-user-avatar-menu',
  templateUrl: './user-avatar-menu.component.html',
  styleUrls: ['./user-avatar-menu.component.scss'],
})
export class UserAvatarMenuComponent {
  constructor(public authenticationService: AuthenticationService, private modal: NgbModal) {}

  openOrgChangeModal(): void {
    this.modal.open(OrgSwitcherModalComponent, { centered: true })
  }
}
