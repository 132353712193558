<div class="container d-flex flex-column min-vh-100">
  <div class="row justify-content-around my-5">
    <div class="col-lg-4 col-sm-8">
      <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" class="img-fluid" />
    </div>
  </div>

  <div class="row justify-content-around">
    <div class="col-lg-6">
      <div class="card login-wrapper py-5 px-4 d-flex align-items-center shadow">
        <h4 class="text-dark mt-2 mb-4">Reset Password</h4>
        <form *ngIf="!success" [formGroup]="form" (ngSubmit)="submit()" class="w-75 d-flex flex-column">
          <div class="d-flex justify-content-center">
            <div *ngIf="error" class="d-flex alert alert-danger my-4 flex-column text-center" role="alert">
              Oops, something went wrong. To report an issue, please submit a ticket at
              <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
            </div>
          </div>
          <label for="id_email" class="text-muted text-center my-4" *ngIf="!error">
            Please enter your email address to receive password reset instructions.
          </label>
          <div class="form-group">
            <input
              id="id_email"
              type="email"
              formControlName="email"
              autofocus=""
              autocapitalize="none"
              autocomplete="email"
              maxlength="254"
              required=""
              class="form-control py-4"
              placeholder="&#xf0e0; EMAIL ADDRESS"
              data-t="email"
              [appFcBsValidation]="form.get('email')"
            />
          </div>
          <button
            class="btn btn-primary py-4 btn-block border-0 text-light text-uppercase mt-3"
            type="submit"
            [disabled]="!form.valid"
          >
            Send Reset Email
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
          <a class="btn btn-link btn-block my-2" routerLink="/login">Sign in to your account</a>
        </form>
        <div *ngIf="success" class="col-lg-10 d-flex flex-column">
          <div class="d-flex alert alert-success my-4" role="alert">
            Submitted! Please check your email and follow the included link.
          </div>
          <h5 class="text-dark text-center my-5">{{form.get('email').value}}</h5>
          <a class="btn btn-alt-steel-blue text-light btn-block mt-2 mb-4" routerLink="/login">Sign In</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
