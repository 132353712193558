import { ActivatedRoute } from '@angular/router'

export const getFinalChildRouteWithTitle = (activatedRoute: ActivatedRoute): ActivatedRoute | null => {
  let route: ActivatedRoute | null = activatedRoute.firstChild
  let child: ActivatedRoute | null = route
  let lastChildWithTitle: ActivatedRoute

  while (child) {
    if (child.snapshot?.data?.title) {
      lastChildWithTitle = child
    }

    if (child.firstChild) {
      child = child.firstChild
      route = child
    } else {
      child = null
    }
  }

  return lastChildWithTitle || route
}
