import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { buildPathForLoginWithRelayState } from 'app/auth/redirect-to-login'
import { ToastService } from '../services/toast.service'

/**
 * Angular route guard that requires the current user to be authenticated
 *
 * @export
 * @constant AuthenticationGuard
 */
export const AuthenticationGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => {
  const authenticationService = inject(AuthenticationService)
  const toastService = inject(ToastService)
  const router = inject(Router)

  const user = authenticationService.getUser()
  const redirectUrl = state.url.split('?')[0]

  if (!user) {
    try {
      // Check if user has an active session.
      await authenticationService.setUser()
      return true
    } catch (e) {
      displayToastForUnauthenticatedUser(next, toastService)
      return router.parseUrl(buildPathForLoginWithRelayState(redirectUrl))
    }
  }
  return true
}

function displayToastForUnauthenticatedUser(next: ActivatedRouteSnapshot, toastService: ToastService): void {
  let currentRoute: ActivatedRouteSnapshot | null = next

  while (currentRoute) {
    if (currentRoute.data?.unauthenticatedMessage) {
      toastService.info(currentRoute.data.unauthenticatedMessage)
      return
    }
    currentRoute = currentRoute.parent
  }
}
