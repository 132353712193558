import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { PayerPlan } from 'generated/graphql'
import { defer } from 'lodash'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'

/** Component to allow searching for and selecting a payer using the PayerPlan model. */
@Component({
  selector: 'app-payerplan-payer-typeahead',
  templateUrl: './payerplan-payer-typeahead.component.html',
  styleUrls: ['./payerplan-payer-typeahead.component.scss'],
})
export class PayerPlanPayerTypeaheadComponent {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() disabledPayerNames: string[] = []
  @Output() onSelect = new EventEmitter<PayerPlan>()

  searching: boolean = false

  constructor(private payerPlanService: PayerPlanService, private toastService: ToastService) {}

  /** Get payers based on search term in typeahead. */
  search = (text$: Observable<string>): Observable<PayerPlan[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((search) => {
        if (search?.length < 2) {
          this.input?.dismissPopup()
        }
      }),
      filter((search) => search?.length >= 2),
      tap(() => (this.searching = true)),
      switchMap((search) =>
        from(this.payerPlanService.searchPayers({ payerName: search })).pipe(
          catchError((err) => {
            this.toastService.error(parseGraphQLError(err, 'Could not query payers.'), JSON.stringify(err))
            return of([] as PayerPlan[])
          }),
          map((payers) => {
            const resultPayers = payers.filter(
              (payer) =>
                this.disabledPayerNames.filter((n) => n.toLowerCase().indexOf(payer.payerName.toLowerCase()) > -1)
                  .length === 0,
            )

            const exactMatch = resultPayers.filter((payer) => payer.payerName.toLowerCase() === search.toLowerCase())
            const otherMatches = resultPayers.filter((payer) => payer.payerName.toLowerCase() !== search.toLowerCase())
            const result = [...exactMatch, ...otherMatches]

            return result
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    )
  }

  /** Format Payer typeahead value in input. */
  inputFormatter = (x: { payerName: string }): string => x.payerName

  /** Format Payer typeahead results list. */
  resultFormatter = (x: { payerName: string }): string => x.payerName

  /**
   * Notify consumers that a payer has been selected
   *
   * @memberof PayerPlanPayerTypeaheadComponent
   */
  onSelectPayer(event: { item: PayerPlan }): void {
    const payer = event.item
    debug('payer plan payers typeahead', 'selected a payer', payer)
    this.onSelect.emit(payer)
    defer(() => (this.element.nativeElement.value = payer.payerName))
  }

  /**
   * Clear typeahead input
   *
   * @memberof PayerPlanPayerTypeaheadComponent
   */
  clearPayer(event: Event): void {
    event.preventDefault()

    this.element.nativeElement.value = ''
    this.element.nativeElement.focus()
    this.onSelect.emit()
  }
}
