<h5 class="mb-3">Claim Details</h5>
<div class="card bg-light mb-5">
  <div class="card-body">
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('claimAmount')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.claimAmount | currency"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('outstandingAmount')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.outstandingAmount | currency"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('predictedRemainingAmount')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.predictedRemainingAmount | currency"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('submissionDate')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value
          [value]="claim?.submissionDate ? (claim.submissionDate | date: 'MM/dd/yyyy') : ''"
        ></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('taxId')?.label }}</dt>
      <dd class="flex-grow-1 text-right"><app-copy-value [value]="claim?.taxId"></app-copy-value></dd>
    </div>
  </div>
</div>
