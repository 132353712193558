import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo, MutationResult } from 'apollo-angular'
import {  OrgSetting, QuickSightDashboardSetting } from 'generated/graphql'
import { Observable } from 'rxjs'
import {
  DashboardSetting,
  UpdateOrgSetting,
  CreateOrgSetting,
} from 'src/app/admin/dashboard-management/services/org-setting.gql'

@Injectable({
  providedIn: 'root',
})
export class OrgSettingsService {
  constructor(private apollo: Apollo) {}

  public getDashboardSetting(
    orgId: string,
    lookupKey: string,
    featureFlagName: string,
  ): Observable<ApolloQueryResult<{ orgDashboardSetting: QuickSightDashboardSetting }>> {
    return this.apollo.watchQuery<{ orgDashboardSetting: QuickSightDashboardSetting }, any>({
      query: DashboardSetting,
      variables: {
        orgId,
        lookupKey,
        featureFlagName,
      },
    }).valueChanges
  }

  public updateDashboardLink(
    orgId: string,
    dashboardLink: string,
    orgSettingId: string,
    lookupKey: string,
    featureFlagName: string,
  ): Promise<
    MutationResult<{
      updateOrgSetting: OrgSetting
    }>
  > {
    const variables = {
      orgId,
      value: dashboardLink,
      orgSettingId,
    }

    return this.apollo
      .mutate<{ updateOrgSetting: OrgSetting }, any>({
        mutation: UpdateOrgSetting,
        variables,
        refetchQueries: [
          {
            query: DashboardSetting,
            variables: {
              orgId,
              lookupKey,
              featureFlagName,
            },
          },
        ],
      })
      .toPromise()
  }

  public async createDashboardLink(
    orgId: string,
    dashboardLink: string,
    orgSettingKey: string,
    featureFlagName: string,
  ): Promise<
    MutationResult<{
      createOrgSetting: OrgSetting
    }>
  > {
    const variables = {
      orgId,
      orgSettingKey,
      value: dashboardLink,
    }

    return await this.apollo
      .mutate<{ createOrgSetting: OrgSetting }, any>({
        mutation: CreateOrgSetting,
        variables,
        refetchQueries: [
          {
            query: DashboardSetting,
            variables: {
              orgId,
              lookupKey: orgSettingKey,
              featureFlagName,
            },
          },
        ],
      })
      .toPromise()
  }

  // Updates a org DashboardSetting, publish value in stored in the local apollo cache
  public updatePublishedDashboardSettingCache(
    orgId: string,
    lookupKey: string,
    featureFlagName: string,
    updatedPublish: boolean,
  ): void {
    const existingDasbboardSetting: { orgDashboardSetting: any } = this.apollo.client.cache.readQuery({
      query: DashboardSetting,
      variables: {
        orgId,
        lookupKey,
        featureFlagName,
      },
    })

    const updatedSetting = { ...existingDasbboardSetting.orgDashboardSetting, published: updatedPublish }
    this.apollo.client.cache.writeQuery({
      query: DashboardSetting,
      variables: {
        orgId,
        lookupKey,
        featureFlagName,
      },
      data: { orgDashboardSetting: updatedSetting },
    })
  }
}
