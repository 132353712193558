import { Component, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ClientCredential, CreateClientCredentialInput } from 'generated/graphql'
import { omit, pickBy } from 'lodash'
import { combineLatest, Subject, Subscription } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { ClientCredentialsService } from '../client-credentials.service'

/**
 * Page to create and edit existing client credential
 *
 * @export
 * @class ClientCredentialEditPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-client-credential-edit',
  templateUrl: './client-credential-edit.page.html',
  host: { class: 'h-100' },
})
export class ClientCredentialEditPage implements OnInit, OnDestroy {
  isLoading: boolean = true
  clientCredential: ClientCredential
  routerSub$: Subscription
  saving: boolean = false
  form = new UntypedFormGroup({
    type: new UntypedFormControl(null, Validators.required),
    username: new UntypedFormControl(null),
    mfa: new UntypedFormControl(null),
    email: new UntypedFormControl(null, Validators.email),
    password: new UntypedFormControl(null, Validators.required),
    disabled: new UntypedFormControl(false),
    concurrency: new UntypedFormControl(null),
    securityQuestions: new UntypedFormArray([]),
  })
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private toast: ToastService,
    private clientCredentialsService: ClientCredentialsService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private modal: NgbModal,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        map(() => this.route.snapshot.paramMap.get('clientCredentialId')),
        tap((clientCredentialId) => {
          if (clientCredentialId === 'new') {
            this.clientCredential = null
            this.form.reset()
            this.isLoading = false
          }
        }),
        filter((clientCredentialId) => clientCredentialId != 'new'),
        switchMap((clientCredentialId) => {
          let credential$ = this.clientCredentialsService.getClientCredential(clientCredentialId)
          let password$ = this.clientCredentialsService.getClientCredentialPassword(clientCredentialId)
          return combineLatest([credential$, password$]).pipe(
            map(([credential, password]) => {
              this.clientCredential = credential?.data?.clientCredential
              this.setFormAndBreadcrumb()
              this.form.get('password').setValue(password?.data?.getClientCredentialPassword)
              this.isLoading = password?.loading || credential?.loading
            }),
          )
        }),
      )
      .subscribe()
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }

  /**
   * Populate form for existing client credential
   *
   * @memberof ClientCredentialEditPage
   */
  setFormAndBreadcrumb(): void {
    // let lastCrumb = this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1]
    // lastCrumb.label = this.clientCredential?.type || 'New Client Credential'

    this.breadcrumbService.setPageTitle('Admin - Client Credentials - ' + this.clientCredential?.type || 'New')

    if (this.clientCredential?.id) {
      this.form.patchValue({
        type: this.clientCredential?.type,
        username: this.clientCredential?.username,
        email: this.clientCredential?.email,
        disabled: this.clientCredential?.disabled,
        locked: this.clientCredential?.locked,
        mfa: this.clientCredential?.mfa,
        concurrency: this.clientCredential?.concurrency,
      })
    }
    if (this.clientCredential?.securityQuestions?.length) {
      let securityQuestions = this.form.get('securityQuestions') as UntypedFormArray
      this.clientCredential?.securityQuestions?.forEach((question) => {
        securityQuestions.push(
          new UntypedFormGroup({
            question: new UntypedFormControl(question.question),
            answer: new UntypedFormControl(question.answer),
          }),
        )
      })
    }
  }

  /**
   * Save changes to, or create new, client credential
   *
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialEditPage
   */
  async saveClientCredential(): Promise<void> {
    this.saving = true
    if (this.form.invalid) {
      this.toast.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.form)),
      )
    } else {
      let password = this.form.get('password').value
      let form = omit(
        pickBy(this.form.value, (value) => value != null),
        'password',
      ) as CreateClientCredentialInput
      try {
        if (this.clientCredential?.id) {
          this.clientCredential = await this.clientCredentialsService.updateClientCredential(
            this.clientCredential.id,
            form,
          )
          this.clientCredentialsService.updateClientCredentialPassword(this.clientCredential.id, password)
          this.setFormAndBreadcrumb()
        } else {
          let result = await this.clientCredentialsService.createClientCredential(form)
          if (result.id) {
            this.clientCredentialsService.updateClientCredentialPassword(result.id, password)
          }
        }
        this.toast.success('Client credential successfully saved')
        this.router.navigate(['admin/client-credentials'])
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not save client credential'), JSON.stringify(e))
      }
    }
    this.saving = false
  }

  /**
   * Launch modal to get confirmation for deleting the current client credential
   *
   * @memberof ClientCredentialEditPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Client Credential?'
    modalRef.componentInstance.body = 'Once this client credential is deleted its data cannot be recovered'
    modalRef.componentInstance.yes = 'Delete client credential'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteClientCredential()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete the current client credential
   *
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialEditPage
   */
  async deleteClientCredential(): Promise<void> {
    try {
      await this.clientCredentialsService.deleteClientCredential(this.clientCredential.id)
      this.toast.success('Client credential successfully deleted')
      this.router.navigate(['admin/client-credentials'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete client credential'), JSON.stringify(e))
    }
  }

  /**
   * Adding a security question object to the currently existing security questions
   *
   * @memberof ClientCredentialEditPage
   */
  addSecurityQuestion(): void {
    let questions = this.form.get('securityQuestions') as UntypedFormArray
    questions.push(new UntypedFormGroup({ question: new UntypedFormControl(''), answer: new UntypedFormControl('') }))
  }

  /**
   * Removing a security question object from the currently existing security questions
   *
   * @param {number} index
   * @memberof ClientCredentialEditPage
   */
  removeSecurityQuestion(index: number): void {
    let questions = this.form.get('securityQuestions') as UntypedFormArray
    questions.removeAt(index)
  }

  /**
   * Getting security questions controls for list
   *
   * @readonly
   * @type {AbstractControl[]}
   * @memberof ClientCredentialEditPage
   */
  get securityQuestions(): AbstractControl[] {
    return (this.form.get('securityQuestions') as UntypedFormArray).controls
  }
}
