<div class="card py-5 px-4 shadow bg-brand-gray text-brand-dark-gray">
  <div class="d-flex justify-content-center">
    <div class="container">
      <div>
        <div class="col-sm">
          <h2 class="pb-4">{{ title }}</h2>
          <div>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
