<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
</div>
<div class="modal-body text-center">
  <ngb-progressbar
    type="success"
    class="progress"
    [max]="total"
    [value]="current"
    [striped]="true"
    height="30px"
  ></ngb-progressbar>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="modal.dismiss()" data-t="progress-modal-cancel-button">
    Cancel
  </button>
</div>
