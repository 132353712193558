import { Component, OnInit, ViewChild } from '@angular/core'
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { PossibleValuesService } from 'app/shared/services/possible-values.service'
import { UsersService } from 'app/shared/services/users.service'
import { Facet, FacetType } from 'app/shared/types/facet'
import { FACETS, SEARCHFIELD } from 'app/shared/types/facets'
import { cloneDeep } from 'lodash'
import { merge, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'

/**
 * Component to display a list of facets to appy to the claims list
 *
 * @export
 * @class FacetedSearchComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-faceted-search',
  templateUrl: './faceted-search.component.html',
  styleUrls: ['./faceted-search.component.scss'],
})
export class FacetedSearchComponent implements OnInit {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead

  constructor(
    public facetService: FacetedSearchService,
    private taskGroupsService: TaskGroupsService,
    private tasktypeService: TaskTypesService,
    private userService: UsersService,
    private possibleValuesService: PossibleValuesService,
  ) {}

  ngOnInit(): void {
    if (!this.facetService.facets$.value?.length) {
      this.facetService.addFacet(SEARCHFIELD)
    }
    // set up typeaheads

    // Task Type
    let taskTypeFn = (term) => this.tasktypeService.searchTaskTypes(term)
    let tasks = FACETS.findIndex((f) => f.id === 'tasks.taskType.name')
    FACETS[tasks].search = taskTypeFn

    // Task Group
    let taskGroupFn = (term) => this.taskGroupsService.searchTaskGroups(term)
    let groups = FACETS.findIndex((f) => f.id === 'tasks.taskGroup.name')
    FACETS[groups].search = taskGroupFn

    // all other typeaheads!
    FACETS.filter((f) => f.type === FacetType.TYPEAHEAD && !f.search).forEach((facet) => {
      facet.search = async (term, allowCustom) => {
        let listOptions = cloneDeep(await this.possibleValuesService.getValuesForField(term, facet.id))
        if (allowCustom && term) {
          const displayedTerm = term.replace(/\\/g, '') // need to not show escape chars in dropdown list
          listOptions = [displayedTerm, ...listOptions]
        }
        return listOptions
      }
    })
  }

  /**
   * Search the list of facets based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @return {*} {Observable<Facet[]>}
   * @memberof FacetedSearchComponent
   */
  search = (text$: Observable<string>): Observable<Facet[]> => {
    const debouncedText$ = text$.pipe(distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => {
        let facets =
          term === ''
            ? FACETS
            : FACETS.filter(
                (facet) =>
                  facet.title.toLowerCase().includes(term.toLowerCase()) &&
                  this.facetService.facets$.value.filter((f) => f.id === facet.id).length === 0,
              )
        return facets.sort((a, b) => a.title.localeCompare(b.title))
      }),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {Facet} x
   * @return {*} {string}
   * @memberof FacetedSearchComponent
   */
  inputFormatter = (x: Facet): string => x.title

  /**
   * Format typeahead results list
   *
   * @param {Facet} x
   * @return {*} {string}
   * @memberof FacetedSearchComponent
   */
  resultFormatter = (x: Facet): string => x.title

  /**
   * Add selected facet
   *
   * @param {*} event
   * @param {*} input
   * @memberof FacetedSearchComponent
   */
  onSelectItem(event: NgbTypeaheadSelectItemEvent, input: { value: string }): void {
    event.preventDefault()
    let facet = event.item as Facet
    this.facetService.addFacet(facet)

    input.value = ''
  }

  /**
   * Helper for performant ngFor loop
   *
   * @param {*} index
   * @param {Facet} item
   * @return {*}
   * @memberof FacetedSearchComponent
   */
  trackById(index: number, item: Facet): string {
    return item?.id as any
  }
}
