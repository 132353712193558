<div class="card detail-card">
  <div class="card-header">{{ search?.name }}</div>
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="saveSearch($event)" novalidate>
      <div class="row my-4">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Name</label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            formControlName="name"
            required
            [appFcBsValidation]="form.get('name')"
            data-t="task-name-edit"
          />
        </div>
      </div>
      <div class="row my-4">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Description</label>
        <div class="col-sm-8">
          <textarea formControlName="description" class="form-control" cols="30" rows="10"></textarea>
        </div>
      </div>
      <div class="row my-4">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Terms</label>
        <div class="col-sm-8">
          <app-selected-facets></app-selected-facets>
        </div>
      </div>
      <div class="row my-4">
        <label
          class="col-sm-4 d-flex align-items-center task-input-label"
          for="saveToTaskGroup"
          ngbTooltip="Select this to assign current and future claims matching this search to a task group"
          container="body"
        >
          Create Task Group
        </label>

        <div class="col-sm-8">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="saveToTaskGroup" formControlName="saveToTaskGroup" />
          </div>
        </div>
      </div>

      <div class="row justify-content-end mt-5">
        <div class="col-sm-2">
          <button type="button" class="btn btn-danger" data-t="delete-claim-search-button" (click)="confirmDelete()">
            Delete
          </button>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="edit-saved-search-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-brand-green px-5"
            data-t="save-saved-search-button"
            [disabled]="form.invalid"
          >
            Save
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
