<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <div class="form-inline" [formGroup]="form">
      <label class="sr-only" for="min">Minimum</label>
      <div class="input-group mb-2">
        <div class="input-group-prepend" *ngIf="data.labelPrepend">
          <div class="input-group-text">{{ data.labelPrepend }}</div>
        </div>
        <input #input type="number" class="form-control" id="min" placeholder="min" formControlName="min" />
      </div>
      <label class="sr-only" for="max">Maximum</label>
      <div class="input-group">
        <div class="input-group-prepend" *ngIf="data.labelPrepend">
          <div class="input-group-text">{{ data.labelPrepend }}</div>
        </div>
        <input type="number" class="form-control" id="max" placeholder="max" formControlName="max" />
      </div>
    </div>
  </div>
</div>
