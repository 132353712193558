<li
  [ngbNavItem]="label"
  [disabled]="disabled"
  [attr.aria-disabled]="disabled"
  class="border-left border-top border-right clipped-corner"
  [ngClass]="{
    'border-brand-dark-gray': selected,
    'border-alt-dark-gray': !selected && disabled,
    'border-alt-orange': !selected && !disabled && highlighted,
    'border-brand-light-blue': !selected && !disabled && !highlighted
  }"
>
  <div
    class="clip-corner"
    [ngClass]="{
      'bg-brand-dark-gray': selected,
      'bg-alt-dark-gray': !selected && disabled,
      'bg-alt-orange': !selected && !disabled && highlighted,
      'bg-brand-light-blue': !selected && !disabled && !highlighted
    }"
  ></div>
  <a
    ngbNavLink
    [routerLink]="href"
    class="text-uppercase"
    [ngClass]="{
      'bg-brand-dark-blue text-alt-white': selected,
      'bg-alt-light-gray text-brand-dark-gray': !selected && disabled,
      'bg-alt-orange-med text-alt-orange': !selected && !disabled && highlighted
    }"
    [attr.aria-disabled]="disabled"
    [attr.aria-label]="label"
  >
    <ng-content></ng-content>
  </a>
</li>
