<div class="container-fluid p-4 m-4 border col-12">
  <div class="form-row p-4 ml-3">
    <h4 class="">{{ settingType?.label }}</h4>
  </div>

  <form [formGroup]="dashboardSettingForm" (ngSubmit)="saveOrgChanges()" class="ml-3">
    <div class="form-row p-4">
      <div class="col-12">
        <label for="dashboardLink">Dashboard ID</label>
        <input type="text" class="form-control" formControlName="dashboardLink" id="dashboardLink" />
      </div>
    </div>

    <div class="form-row p-4">
      <div class="custom-control custom-switch ml-3">
        <input
          type="checkbox"
          class="custom-control-input"
          id="{{ settingType.lookUpKey }}-publishToggle"
          formControlName="publish"
        />
        <label class="custom-control-label" for="{{ settingType.lookUpKey }}-publishToggle">Publish</label>
      </div>
    </div>

    <div class="form-row p-4">
      <div class="m-2">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </div>
  </form>
</div>
