import { Injectable, OnDestroy } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { debug } from 'app/shared/utils/debug'
import { DocumentChanges, DocumentWorkerChanges } from 'app/vision-x/vision-x.gql'
import { SubscriptionNotification } from 'generated/graphql'
import { Observable, Subject } from 'rxjs'
import { delay, map, takeUntil } from 'rxjs/operators'

export enum VxDocumentTopics {
  documentStatusUpdated = 'documentStatusUpdated',
}

@Injectable({
  providedIn: 'root',
})
export class VxDocumentSubscriptionService implements OnDestroy {
  destroy$ = new Subject<void>()

  constructor(private apollo: Apollo) {}

  subscribe(): void {
    this.apollo
      .subscribe<{ documentChanges: SubscriptionNotification }>({
        query: DocumentChanges,
      })
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(
        (event) => {
          const { type, entityId, data } = event?.data?.documentChanges
          if (type === VxDocumentTopics.documentStatusUpdated) {
            if (data) {
              const apolloCache = this.apollo.client.cache
              apolloCache.modify({
                id: apolloCache.identify({ id: entityId, __typename: 'VxDocument' }),
                fields: {
                  status() {
                    return data
                  },
                },
              })
            }
          }
        },
        (error) => {
          debug('subscriptions', 'document subscription error')
        },
      )
  }

  // get api worker task id
  getDocumentWorkerChanges(vxDocumentId: string): Observable<SubscriptionNotification> {
    return this.apollo
      .subscribe<{ documentWorkerChanges: SubscriptionNotification }>({
        query: DocumentWorkerChanges,
        variables: { vxDocumentId },
      })
      .pipe(map((result) => result.data.documentWorkerChanges))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
