import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Params, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BotRunsFilterComponent } from 'app/revbot/bot-detail/components/bot-runs-filter/bot-runs-filter.component'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotRun } from 'generated/graphql'
import { first } from 'rxjs/operators'

/**
 * Component to display and filter a list of bot runs
 *
 * @export
 * @class BotRunsSearchComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-bot-runs-search',
  templateUrl: './bot-runs-search.component.html',
  styleUrls: ['./bot-runs-search.component.scss'],
})
export class BotRunsSearchComponent implements OnChanges {
  @Input() selectedBotRunId: string
  @Input() botJobId: string
  @Input() params: Params
  botRuns: BotRun[]
  loading: boolean = false
  total: number
  runningTotal: number = 0

  offset: number = 0

  constructor(
    private toast: ToastService,
    public botRunsService: BotRunsService,
    private modal: NgbModal,
    private router: Router,
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.selectedBotRunId?.currentValue || changes.botJobId?.currentValue || changes.params?.currentValue) {
      this.searchBotRuns()
    }
  }

  /**
   * Launch modal to allow filtering the bot run list
   *
   * @memberof BotRunsSearchComponent
   */
  open(): void {
    this.modal.open(BotRunsFilterComponent, { size: 'lg', centered: true })
  }

  /**
   * Filter the list of bot runs
   *
   * @param {boolean} [reroute=false]
   * @return {*}  {Promise<void>}
   * @memberof BotRunsSearchComponent
   */
  async searchBotRuns(reroute: boolean = false): Promise<void> {
    try {
      this.loading = true
      let botRunsResult = await this.botRunsService.filterBotRuns(this.botJobId, this.offset).pipe(first()).toPromise()
      this.botRuns = botRunsResult?.data?.botRuns?.entities
      this.total = this.botRunsService.getMeta().total
      if (reroute) {
        let includedBotRun = this.botRuns?.find((botRun) => botRun.id === this.selectedBotRunId)
        if (!includedBotRun) {
          this.router.navigate([`revbots/${this.botJobId}`], { queryParamsHandling: 'preserve' })
        }
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find bot runs'), JSON.stringify(e))
    }
    this.loading = false
  }

  /**
   * Load more or previous bot runs to the list
   *
   * @param {('next' | 'prev')} direction
   * @return {*}  {Promise<void>}
   * @memberof BotRunsSearchComponent
   */
  async load(direction: 'next' | 'prev'): Promise<void> {
    try {
      if (direction === 'next') {
        this.offset += 1000
        await this.searchBotRuns()
        this.runningTotal += this.botRuns.length
      } else {
        this.offset -= 1000
        this.runningTotal -= this.botRuns.length
        await this.searchBotRuns()
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load more bot runs'), JSON.stringify(e))
    }
  }
}
