import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthenticationService } from '../../auth/authentication.service'
import { TeleportService } from '../../teleport/teleport.service'
import { FeatureFlags, FeatureFlagService } from '../services/feature-flag.service'

const teleportStorePage =
  'https://chrome.google.com/webstore/detail/janus-teleport/fogbgogfedkknbbdiepecoacdoilemof/related?hl=en&authuser=1'

export const TeleportGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => {
  const authenticationService = inject(AuthenticationService)
  const teleportService = inject(TeleportService)
  const featureFlagService = inject(FeatureFlagService)
  const http = inject(HttpClient)

  // disableTeleportGuard is temporary and will be removed after customer testing
  const disableGuard = await featureFlagService.getFeatureFlagValue(FeatureFlags.ui.disableTeleportGuard)
  if (disableGuard) {
    console.log('disableTeleportGuard feature flag is enabled, skipping guard!')
    return Promise.resolve(true)
  }
  console.log('disableTeleportGuard feature flag is not enabled, executing guard!')
  const firstChildPath = next.firstChild?.url[0]?.path

  // check for teleport extension on all pages *except* launch page used by standalone electron app
  const shouldCheckForExtension = firstChildPath !== 'launch'

  return new Promise((resolve) => {
    if (!shouldCheckForExtension) {
      return resolve(true)
    }
    setTimeout(async () => {
      if (document.getElementsByTagName('body')[0]?.classList.contains('janus-teleport-enabled')) {
        return resolve(true)
      }

      const teleportUiObservability = await featureFlagService.getFeatureFlagValue(
        FeatureFlags.ui.teleportUiObservability,
      )
      if (teleportUiObservability) {
        try {
          await authenticationService.setUser()
        } catch (e) {
          document.location.replace(teleportStorePage)
          return resolve(false)
        }
        const user = authenticationService.getUser()
        const baseFields = {
          portal: 'none',
          payer: 'none',
          status: 'FAILED',
          errorType: 'CHROME_STORE_REDIRECT',
          userId: user.id,
          browserType: teleportService.getBrowserType(),
          networkDetails: teleportService.getNetworkDetails(),
        }
        await http
          .post(
            '/api/teleport/history',
            {
              ...baseFields,
              succeeded: false,
              metrics: {
                ...baseFields,
                orgId: user.orgId,
                env: 'browser',
                errorMsg: 'teleport guard blocked teleport',
                lastPhase: 'LAUNCH',
              },
            },
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
          .toPromise()
      }

      document.location.replace(teleportStorePage)
      resolve(false)
    }, 2000)
  })
}
