export interface ParsedPattern {
  protocol: 'https' | 'http' | 'empty' | 'unknown'
  protocolLiteral: string
  subdomains: 'exact' | 'any' | 'unknown'
  domainLiteral: string
  path: 'any' | 'empty' | 'unknown'
}

const PROTOCOL_SUBPATTERNS: Array<[string, ParsedPattern['protocol']]> = [
  [`https://`, 'https'],
  [`http://`, 'http'],
  ['', 'empty'],
]

const SUBDOMAIN_SUBPATTERNS: Array<[string, ParsedPattern['subdomains']]> = [
  [`(.*\\.)?`, 'any'],
  [`.*\\.`, 'any'],
]

const PATH_SUBPATTERNS: Array<[string, ParsedPattern['path']]> = [[`(/.*)?`, 'any']]

export const HOST_PATTERN = /^[\w-]+(\.[\w-]+)*(:\d+)?$/
export const FIND_HOST_SUBDOMAINS = /^((?:[\w-]+\.)+)[\w-]+\./

export const parsePattern = (pattern: string): ParsedPattern => {
  const opts: ParsedPattern = {
    protocol: 'unknown',
    protocolLiteral: '',
    subdomains: 'unknown',
    domainLiteral: pattern?.trim(),
    path: 'unknown',
  }

  if (opts.domainLiteral.includes('://')) {
    const endOfProtocol = opts.domainLiteral.indexOf('://') + 3
    opts.protocolLiteral = opts.domainLiteral.slice(0, endOfProtocol)
    opts.domainLiteral = opts.domainLiteral.slice(endOfProtocol)

    for (const [subpattern, name] of PROTOCOL_SUBPATTERNS) {
      if (subpattern === opts.protocolLiteral) {
        opts.protocol = name
        break
      }
    }
  }

  for (const [subpattern, name] of PATH_SUBPATTERNS) {
    if (opts.domainLiteral.endsWith(subpattern)) {
      opts.path = name
      opts.domainLiteral = opts.domainLiteral.slice(0, -subpattern.length)
      break
    }
  }

  let didFindPathLiteral = false
  const startOfPath = opts.domainLiteral.indexOf('/')
  if (startOfPath > -1) {
    // There is still a lingering path literal
    const restOfPath = opts.domainLiteral.slice(startOfPath)
    if (restOfPath === '/') {
      opts.path = 'empty'
    } else {
      didFindPathLiteral = true
      opts.path = 'unknown'
    }

    opts.domainLiteral = opts.domainLiteral.slice(0, startOfPath)
  }

  for (const [subpattern, name] of SUBDOMAIN_SUBPATTERNS) {
    if (opts.domainLiteral.indexOf(subpattern) === 0) {
      opts.subdomains = name
      opts.domainLiteral = opts.domainLiteral.slice(subpattern.length)
      break
    }
  }
  // Removes escape chars for domain values
  opts.domainLiteral = opts.domainLiteral.replace(/\\/g, '')
  if (HOST_PATTERN.test(opts.domainLiteral)) {
    if (opts.protocol === 'unknown' && opts.protocolLiteral === '') {
      opts.protocol = 'empty'
    }

    if (opts.subdomains === 'unknown') {
      opts.subdomains = 'exact'
    }

    if (opts.path === 'unknown' && didFindPathLiteral === false) {
      opts.path = 'empty'
    }
  }

  return opts
}

export const generatePattern = (opts: ParsedPattern): string => {
  let pattern = ''
  pattern += PROTOCOL_SUBPATTERNS.find(([, name]) => name === opts.protocol)?.[0] ?? opts.protocolLiteral
  pattern += SUBDOMAIN_SUBPATTERNS.find(([, name]) => name === opts.subdomains)?.[0] ?? ''
  pattern += opts.domainLiteral.trim()
  pattern += PATH_SUBPATTERNS.find(([, name]) => name === opts.path)?.[0] ?? ''
  return pattern
}

export const patternCanBeInterpreted = (pattern: string): boolean => {
  if (!pattern) {
    return false
  }
  if (pattern === '') {
    return true
  }
  const parsed = parsePattern(pattern)
  return (
    parsed.protocol !== 'unknown' &&
    parsed.subdomains !== 'unknown' &&
    parsed.path !== 'unknown' &&
    HOST_PATTERN.test(parsed.domainLiteral)
  )
}
