import { Client, StatusMessage, SuccessStatusMessage } from '@hanseltime/janus-simple-command'
import { WebSocketConnection } from './ws-client'
import type { CommandMap, Commands, StatusMap, InterMap } from './bot-types'
import { debug } from 'app/shared/utils/debug'

type AsSuccess<T extends StatusMessage<any>> = T extends SuccessStatusMessage<infer A> ? SuccessStatusMessage<A> : never

export type RunBotOpts = {
  url: string
  claimId: string
  orgId: string
  portal: string
  payer: string
  credentialType: string
}
export const startBot = async (
  opts: RunBotOpts,
  getLog: (status: InterMap['startBot']) => Promise<void>,
): Promise<AsSuccess<StatusMap['startBot']>> => {
  const { url, claimId, orgId, portal, payer, credentialType } = opts
  const fullUrl = `wss://${url}`
  const ws = new WebSocket(fullUrl, 'echo-protocol')
  const clientConnection = new WebSocketConnection(ws, 'client')
  const client = new Client<Commands, CommandMap, StatusMap, never, InterMap>({
    commands: ['startBot'],
    ackRetryDelay: 10000,
    maxAckRetries: 3,
    connection: clientConnection,
    debug: (...args: any) => {
      debug('bot-qa', args)
    },
  })

  await client.open()
  const sender = await client.createSender()
  const message = (await sender.command(
    'startBot',
    {
      claimId,
      orgId,
      botType: 'getClaimStatus',
      portal,
      payer,
      credentialType,
    },
    getLog,
  )) as AsSuccess<StatusMap['startBot']>

  await sender.close()

  await client.close()

  return message
}
