import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { FormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbModule, NgbTimeStruct, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subscription } from 'rxjs'

/**
 * Component to display a daterange picker
 *
 * @export
 * @class DatepickerRangeComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  imports: [CommonModule, FormsModule, NgbModule],
  standalone: true
})
export class DatepickerRangeComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChildren('timepicker') timepickers: QueryList<NgbTimepicker>;
  @Output() rangeSelected = new EventEmitter<{ from: string; to: string; fromTime?: string; toTime?: string }>()
  @Output() blur =  new EventEmitter()

  @Input() selectedDates: { from: string; to: string } = null
  @Input() selectedTimes: { from: string; to: string } = null
  @Input() clearDates: Observable<void>
  @Input() focus: boolean
  @Input() allowSingleDate: boolean = false
  @Input() selectTime: boolean = false

  @ViewChild('inputFromDate') input: ElementRef
  @ViewChild('dp') dp: NgbDatepicker

  clearDates$: Subscription

  hoveredDate: NgbDate | null = null

  fromDate: NgbDate | null
  toDate: NgbDate | null = null
  fromTime: NgbTimeStruct
  toTime: NgbTimeStruct = this.timeToNgb(this.selectedTimes?.to)

  constructor(public calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}

  ngOnInit(): void {
    if (this.clearDates) {
      this.clearDates$ = this.clearDates.subscribe((event) => {
        this.fromDate = null
        this.toDate = null
      })
    }
  }

  ngOnDestroy(): void {
    if (this.clearDates$) {
      this.clearDates$.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.input.nativeElement.focus()
    }
    if (this.selectedDates !== null) {
      setTimeout(() => {
        // can NOT modify the view during angular lifecycle ( view init )
        // so do the following on the next JS cycle
        this.setDate('from', this.selectedDates.from)
        this.setDate('to', this.selectedDates.to)
        this.fromTime = this.timeToNgb(this.selectedTimes?.from)
        this.toTime = this.timeToNgb(this.selectedTimes?.to)
      })
    }
    
    if (this.timepickers?.length) {
      this.timepickers.forEach(picker => {
        picker.handleBlur = () => {
          picker.onTouched()
          this.blur.emit()
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDates?.currentValue) {
      this.setDate('from', this.selectedDates.from)
      this.setDate('to', this.selectedDates.to)
    }
  }

  /**
   * Convert an NgbDate object to a display string
   *
   * @param {NgbDate} ngb
   * @return {*}  {string}
   * @memberof DatepickerRangeComponent
   */
  ngbToDate(ngb: NgbDate): string {
    if (!ngb) {
      return ''
    }
    let day = ngb.day < 10 ? `0${ngb.day}` : ngb.day
    let month = ngb.month < 10 ? `0${ngb.month}` : ngb.month
    return `${month}/${day}/${ngb?.year}`
  }

  /**
   * Notify consumers that a date has been selected
   *
   * @param {NgbDate} date
   * @memberof DatepickerRangeComponent
   */
  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date
      if (!this.allowSingleDate) {
        this.emitValues()
      }
    } else {
      this.toDate = null
      this.fromDate = date
    }
    if (this.allowSingleDate) {
      this.emitValues()
    }
  }

  /**
   * Notify consumers that a time has been selected
   *
   * @memberof DatepickerRangeComponent
   */
  onTimeSelection(): void {
    this.emitValues()
  }

  /**
   * Helper for highlighting date range in calendar
   *
   * @param {NgbDate} date
   * @return {*}  {boolean}
   * @memberof DatepickerRangeComponent
   */
  isHovered(date: NgbDate): boolean {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    )
  }

  /**
   * Helper to determine if picked date is between fromDate and toDate
   *
   * @param {NgbDate} date
   * @return {*}  {boolean}
   * @memberof DatepickerRangeComponent
   */
  isInside(date: NgbDate): boolean {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
  }

  /**
   * Helper to determine if two date constitute a range
   *
   * @param {NgbDate} date
   * @return {*}  {boolean}
   * @memberof DatepickerRangeComponent
   */
  isRange(date: NgbDate): boolean {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    )
  }

  /**
   * Helper to validate user input as date
   *
   * @param {(NgbDate | null)} currentValue
   * @param {string} input
   * @return {*}  {(NgbDate | null)}
   * @memberof DatepickerRangeComponent
   */
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    let parts = input.split('/')
    if (parts.length !== 3 || parts[2]?.length < 4) {
      return null
    }
    input = `${parts[2]}-${parts[0]}-${parts[1]}`

    const parsed = this.formatter.parse(input)

    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
  }

  /**
   * Convert user input to visual date selection in calendar
   *
   * @param {('from' | 'to')} type
   * @param {string} str
   * @memberof DatepickerRangeComponent
   */
  setDate(type: 'from' | 'to', str: string): void {
    if (type === 'from') {
      this.fromDate = this.validateInput(this.fromDate, str)
      if (this.fromDate) {
        this.dp.navigateTo(this.fromDate)
      }
    } else {
      this.toDate = this.validateInput(this.toDate, str)
      this.dp.navigateTo(this.toDate)
    }
    this.emitValues()
    this.blur.emit()
  }

  /**
   * Convert user time input to NgbTime format
   *
   * @param {string} time
   * @return {*}  {NgbTimeStruct}
   * @memberof DatepickerRangeComponent
   */
  timeToNgb(time: string): NgbTimeStruct {
    if (!time) {
      return undefined
    }
    let splitTime = time.split(':')
    let hour = Number(splitTime[0])
    let minute = Number(splitTime[1])
    return { hour, minute, second: 0 }
  }

  /**
   * Convert NgbTime format to display string
   *
   * @param {NgbTimeStruct} ngb
   * @return {*}  {string}
   * @memberof DatepickerRangeComponent
   */
  ngbToTime(ngb: NgbTimeStruct): string {
    if (!ngb) {
      return undefined
    }
    let hour = ngb.hour < 10 ? `0${ngb.hour}` : ngb.hour
    let minute = ngb.minute < 10 ? `0${ngb.minute}` : ngb.minute
    return `${hour}:${minute}`
  }

  /**
   * Notify consumers of date / time selection
   *
   * @memberof DatepickerRangeComponent
   */
  emitValues(): void {
    if (this.selectTime) {
      this.rangeSelected.emit({
        from: this.ngbToDate(this.fromDate),
        to: this.ngbToDate(this.toDate),
        fromTime: this.ngbToTime(this.fromTime),
        toTime: this.ngbToTime(this.toTime),
      })
    } else {
      this.rangeSelected.emit({
        from: this.ngbToDate(this.fromDate),
        to: this.ngbToDate(this.toDate),
      })
    }
  }
}
