<div class="modal-header p-4">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="close font-weight-bold" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center p-5" [innerHtml]="body"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="activeModal.dismiss()" data-t="confirm-modal-cancel-button">
    Cancel
  </button>
  <button
    type="button"
    class="btn {{ yesClass }} px-4 rounded-lg"
    (click)="activeModal.close()"
    data-t="confirm-modal-yes-button"
    [innerHtml]="yes"
  ></button>
</div>
