<div class="modal-header">
  <h4 class="modal-title">Select Columns to Display</h4>
</div>
<form [formGroup]="form" (ngSubmit)="changeColumns()">
  <div class="modal-body">
    <div class="row row-cols-4">
      <div class="col form-group" formArrayName="columns" *ngFor="let check of checksFromArray.controls; let i = index">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" [formControlName]="i" [attr.id]="possible[i].property" />
          <label class="form-check-label" [attr.for]="possible[i].property">{{ possible[i].label }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="modal.dismiss('Close click')"
      data-t="claim-columns-cancel-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="claim-columns-apply-button">Apply</button>
  </div>
</form>
