import G6, { EdgeConfig, LabelStyle, NodeConfig } from '@antv/g6'

//#region
const computedStyle = getComputedStyle(document.body)

export const DEFAULT_NODE: Partial<NodeConfig> = {
  type: 'rect',
  size: [260, 50],
  anchorPoints: [
    [0.5, 0],
    [0.5, 1],
    [1, 0.5],
    [0, 0.5],
  ],
  radius: 2,
  labelCfg: {
    style: {
      fill: computedStyle.getPropertyValue('--alt-dark-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
      fontSize: 14,
      textAlign: 'center',
      textBaseline: 'middle',
      fontWeight: 'bold',
    },
  },
}

export const RED_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-red-light'),
    stroke: computedStyle.getPropertyValue('--alt-red-lighter'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-red-lighter'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
  },
  ...DEFAULT_NODE,
}
export const BLUE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-barely-blue'),
    stroke: computedStyle.getPropertyValue('--alt-baby-blue'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-baby-blue'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
  },
  ...DEFAULT_NODE,
}
export const GREEN_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-green-light'),
    stroke: computedStyle.getPropertyValue('--alt-green'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-green'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
  },
  ...DEFAULT_NODE,
}
export const ORANGE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-orange-light'),
    stroke: computedStyle.getPropertyValue('--alt-orange-lighter'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-orange-lighter'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
  },
  ...DEFAULT_NODE,
}
export const PURPLE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-barely-purple'),
    stroke: computedStyle.getPropertyValue('--alt-purple-light'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-purple-light'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
  },
  ...DEFAULT_NODE,
}

export const DEFAULT_EDGE_STYLE = {
  radius: 10,
  offset: -20,
  lineWidth: 4,
  // lineAppendWidth: 20,
}
export const DEFAULT_EDGE: Partial<EdgeConfig> = {
  type: 'polyline',
  labelCfg: {
    refY: 25,
    refX: 0,
    style: {
      fill: computedStyle.getPropertyValue('--alt-dark-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
      fontSize: 14,
      textAlign: 'center',
      textBaseline: 'middle',
      fontWeight: 'bold',
    },
  },
}

export const RED_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-red-light'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-red-light'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-red-lighter'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-red-lighter'),
      },
    },
  },
  ...DEFAULT_EDGE,
}
export const RED_DASH_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-red-light'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-red-light'),
    },
    lineDash: [4, 8],
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-red-lighter'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-red-lighter'),
      },
      lineDash: [4, 8],
    },
  },
  ...DEFAULT_EDGE,
}
export const RED_DASH_LINE_EDGE: Partial<EdgeConfig> = {
  type: 'line',
  labelCfg: {
    refY: 25,
    refX: 0,
    style: {
      fill: computedStyle.getPropertyValue('--alt-dark-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
      fontSize: 14,
      textAlign: 'center',
      textBaseline: 'middle',
      fontWeight: 'bold',
    },
  },
  style: {
    stroke: computedStyle.getPropertyValue('--alt-red-light'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-red-light'),
    },
    lineDash: [4, 8],
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-red-lighter'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-red-lighter'),
      },
      lineDash: [4, 8],
    },
  },
}
export const BLUE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-barely-blue'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-barely-blue'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-baby-blue'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-baby-blue'),
      },
    },
  },
  ...DEFAULT_EDGE,
}
export const GREEN_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-green-light'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-green-light'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-green'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-green'),
      },
    },
  },
  ...DEFAULT_EDGE,
}
export const ORANGE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-orange-light'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-orange-light'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-orange-lighter'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-orange-lighter'),
      },
    },
  },
  ...DEFAULT_EDGE,
}
export const PURPLE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-barely-purple'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-barely-purple'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-purple-light'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-purple-light'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export type IMA_NODE = {
  id: string
  label: string | LabelStyle
  totalTime: string
  totalPercent: string
  totalCount: number | string
}

export type IMA_EDGE = {
  id: string
  source: string
  sourceAnchor?: number
  targetAnchor?: number
  target: string
  label: string | LabelStyle
  percent: string
  time: number
  count: number
}
