import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ToastService } from 'app/shared/services/toast.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

// total size allowed in bytes
export const totalFileSizeAllowed = 5242880
const acceptedFileTypes = ['application/pdf']

type UploadStatusType = 'ready' | 'uploading' | 'success' | 'fail'
type FileDataType = 'supportedFile' | 'unsupportedFile' | 'hugeFile' | 'totalFileSizeExceeded'
type FileData = { file: File }
type SupportedFile = FileData & { dataType: 'supportedFile' }
type UnsupportedFileError = FileData & { dataType: 'unsupportedFile' }
type TooLargeFileError = FileData & { dataType: 'hugeFile' }
type TooLargePayload = FileData & { dataType: 'totalFileSizeExceeded' }
type FileResult = SupportedFile | UnsupportedFileError | TooLargeFileError | TooLargePayload

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  files: FileResult[] = []
  totalFileSize: number = 0
  fileControl: FormControl<File[] | null> = new FormControl<File[] | null>([])
  uploadStatus: UploadStatusType = 'ready'
  canceled$ = new Subject<void>()

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void { }

  async onFileSelected(event: Event): Promise<void> {
    const fileInput = event.target as HTMLInputElement
    const fileList: FileList = fileInput.files
    await this.loadFiles(fileList)
    // we do this for edge case behavior because the html file input will hold onto the value of the filepath so in the event that
    // we select a single file, delete it, and try to select it again, it won't be loaded since it's registered as the currently selected file
    // this is also okay to do since we have custom behavior for storing and submitting loaded files,
    // so we aren't actually using the file input value for anything
    this.fileControl.setValue(null)
  }

  async onFilesDropped(event: DragEvent): Promise<void> {
    event.preventDefault()
    const fileList: FileList = event.dataTransfer?.files
    if (fileList) {
      await this.loadFiles(fileList)
    }
  }

  async loadFiles(fileList: FileList): Promise<void> {
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList[i]
      await this.loadFile(file)
    }
  }

  async loadFile(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      let dataType: FileDataType = 'supportedFile'

      reader.onload = () => {

        const fileDataToValidate: FileResult = {
          dataType,
          file,
        }
        this.validateFileData([fileDataToValidate], true)

        return resolve()
      }

      reader.onerror = (errorEvent: ProgressEvent<FileReader>) => {
        const error = errorEvent.target?.error
        if (error) {
          return reject(error)
        }
      }

      reader.readAsDataURL(file)
    })
  }

  async uploadFiles(): Promise<void> {
    this.uploadStatus = 'uploading'

    // initialize form object
    const formData: FormData = new FormData()

    // append metadata
    const procedureSetId = this.route.snapshot.queryParamMap.get('procedure_set_id')
    const orgId = (await this.authenticationService.getUserOrg()).id

    const meta = {
      orgId,
      procedureSetId,
      files: this.files.map((file) => {
        const { name, type, size } = file.file
        return {
          fileName: name,
          mimeType: type,
          sizeBytes: size,
        }
      }),
    }

    const blobMeta = new Blob([JSON.stringify(meta)], {
      type: 'application/json',
    })

    formData.append('meta', blobMeta)

    // append pdf
    this.files.forEach((file, index) => {
      formData.append(`file:${index}`, file.file, file.file.name)
    })

    // send request
    this.http.post('/api/document-upload', formData, { withCredentials: true }).pipe(
      takeUntil(this.canceled$)
    ).subscribe(
      () => this.uploadStatus = 'success',
      () => this.uploadStatus = 'fail',
    )
  }
  cancel(): void {
    this.canceled$.next();
    // TODO: some other stuff to reset the component state
    this.removeAllFiles()
  }

  removeAllFiles(): void {
    this.files = []
    this.totalFileSize = 0
  }
  removeFile(index: number): void {
    const file = this.files.splice(index, 1)[0]
    this.totalFileSize -= file.file.size
    this.validateFileData(this.files, false)
  }

  validateFileData(files: FileResult[], isLoading: boolean): void {
    const numFiles = files.length

    for (let i = 0; i < numFiles; i++) {
      const file = files[i].file

      // Assume supported
      files[i].dataType = 'supportedFile'

      if (!acceptedFileTypes.includes(file.type)) {
        files[i].dataType = 'unsupportedFile'
      }
      // If this file is too large
      if (file.size > totalFileSizeAllowed) {
        files[i].dataType = 'hugeFile'
      }
    }

    // Set values on class
    if (isLoading) {
      this.files.push(...files)
    } else {
      this.files = files
    }

    this.updateTotalFileSize()
  }

  updateTotalFileSize(): void {
    this.totalFileSize = this.files.reduce((total, file) => total + file.file.size, 0)
  }

  isTotalFileSizeExceeded(): boolean {
    return this.totalFileSize > totalFileSizeAllowed
  }

  checkAllFilesForErrors(): boolean {
    return this.files.some((file) => file.dataType !== 'supportedFile') || this.isTotalFileSizeExceeded()
  }

  uploadForbiddenHelpMessage(): string {
    if (this.isTotalFileSizeExceeded()) {
      return 'Your upload exceeds 5 MB.\nRemove some files to continue.'
    } else {
      return 'Only PDF files are allowed.\nRemove unsupported files to continue.'
    }
  }

  formatFileSize(bytes: number): string {
    const suffixes = ['B', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1000))
    return `${bytes / Math.pow(1000, i)} ${suffixes[i]}`
  }
}
