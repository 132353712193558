import { Facet, FacetType, Selected } from 'app/shared/types/facet'
import { ElasticsearchScriptInput } from 'generated/graphql'
import { compact, isNil } from 'lodash'

/**
 * Helper to determine if all values are negated
 *
 * @param {string[]} strs
 * @return {*}
 */
const allNegated = (strs: string[]) => {
  let count = 0
  strs.forEach((str) => {
    if (str.indexOf('!') >= 0) {
      count++
    }
  })
  return count === strs?.length
}

/**
 * Format an array of facets into an elastic search string and scripts
 *
 * @param {Facet[]} facets
 * @return {*}  {{ search?: string; scripts?: ElasticsearchScriptInput[] }}
 */
export const facetsToClaimQueryParams = (
  facets: Facet[],
): { search?: string; scripts?: ElasticsearchScriptInput[] } => {
  let subsearches: string[] = []
  let scripts: ElasticsearchScriptInput[] = []
  facets.forEach((facet) => {
    if (facet.selected?.length > 0) {
      if (facet.id === 'wildcard') {
        return subsearches.push(`(${facet.selected[0].value})`)
      }
      if (facet.type === FacetType.RADIO) {
        if (facet.existsQuery) {
          const ids = facet.otherIds ? [facet.id, ...facet.otherIds] : [facet.id]
          const existenceQueries = ids.map((id) => `(_exists_:${id})`)
          if (facet.selected[0].value) {
            return subsearches.push(existenceQueries.join(' OR '))
          } else {
            return subsearches.push('!' + existenceQueries.join(' AND !'))
          }
        } else {
          if (isNil(facet.selected[0].value)) {
            return subsearches.push(`!(_exists_:${facet.id})`)
          } else {
            return subsearches.push(`(${facet.id}: ${facet.selected[0].value})`)
          }
        }
      }
      let format
      switch (facet.type) {
        case FacetType.DATERANGE:
        case FacetType.NUMBERRANGE:
        case FacetType.SLIDER: {
          format = (f: Facet) => f.selected.map((s: Selected) => `[${s.value[0] || '*'} TO ${s.value[1] || '*'}]`)
          break
        }
        case FacetType.PREDEFINED: {
          // build string from selected ONLY! with key as field
          let orderByKey = {}
          facet.selected.forEach((s: Selected) => {
            if (!orderByKey[s.key]) {
              orderByKey[s.key] = []
            }
            orderByKey[s.key].push({ value: s.value, display: s.display })
          })
          let innerArray = []
          Object.keys(orderByKey).forEach((key) => {
            let inner = orderByKey[key].map((s: Selected) => {
              return `(${s.value})`
            })
            let join = inner?.join(` ${allNegated(inner) ? 'AND' : 'OR'} `)

            let joined = inner?.length > 1 ? `(${join})` : inner[0]

            innerArray.push(`(${key}: ${joined})`)
          })
          return subsearches.push(innerArray.join(' AND '))
        }
        case FacetType.COMPARISON: {
          let selectedScript = facet.selected[0].value
          // NOTE: api support exists to also add params along with each script, but not utilizing yet
          return selectedScript ? scripts.push({ type: selectedScript }) : null
        }
        default: {
          format = (f: Facet) => {
            let inner = f.selected.map((s: Selected) => {
              return `(${s.value})`
            })
            let conjunction = allNegated(inner) ? 'AND' : 'OR'
            let join = inner?.join(` ${conjunction} `)

            return inner.length > 1 ? `(${join})` : inner[0]
          }
          break
        }
      }
      let ids = facet.otherIds
      if (!ids) {
        return subsearches.push(`(${facet.id}: ${format(facet)})`)
      }

      ids.push(facet.id)

      let formattedFacet = format(facet)
      let conjunction = allNegated([formattedFacet]) ? ' AND ' : ' OR '
      let mapped = ids.map((id) => `(${id}: ${formattedFacet})`).join(conjunction)

      return subsearches.push(`(${mapped})`)
    }
  })
  let search = compact(subsearches).join(' AND ')
  return { search, scripts }
}
