import { Component, OnDestroy, OnInit, inject } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { JuiDialogService } from '@janushealthinc/janus-ui-kit'
import { AuthenticationService } from 'app/auth/authentication.service'
import { AuthorizationService } from 'app/auth/authorization.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Organization } from 'generated/graphql'
import { pull } from 'lodash'
import { Subject, combineLatest, of } from 'rxjs'
import { map, shareReplay, startWith, takeUntil } from 'rxjs/operators'
import { OrgService } from '../org.service'
import {
  ConfirmDeleteOrgDialogComponent,
  ConfirmDeleteOrgDialogData,
} from './confirm-delete-org-modal/confirm-delete-org-modal.component'

interface OrganizationWithMeta extends Organization {
  /**
   * Will be `true` if this org matches the user's signed in org.
   */
  isCurrentOrg: boolean
}

@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.page.html',
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class OrgListPage implements OnDestroy, OnInit {
  dialogService = inject(JuiDialogService)
  organizationService = inject(OrgService)

  isDrawerOpen: boolean = false

  search = new UntypedFormControl('')
  searching: boolean = false

  organizations: OrganizationWithMeta[] = []
  selected: string[] = []

  orgSetId = new UntypedFormControl(null)

  destroy$ = new Subject<void>()
  dedOrgs$ = new Subject<void>()

  trashIcon = faTrash
  userOrgId: string | undefined

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private featureFlagService: FeatureFlagService,
    private toast: ToastService,
    private users: UsersService,
  ) {
    this.search.valueChanges.pipe(startWith(''), takeUntil(this.destroy$)).subscribe(async (value) => {
      this.dedOrgs$.next()
      this.searching = true
      this.organizationService
        .getOrgs(value, 99, 0)
        .pipe(takeUntil(this.dedOrgs$))
        .subscribe((result) => {
          if (result.error || result.errors) {
            this.toast.error(
              parseGraphQLError(result, 'Could not load organizations'),
              JSON.stringify(result.error || result.errors),
            )
          } else {
            const currentOrgId: string = this.authenticationService.getUser()?.orgId

            this.organizations = result?.data?.organizations?.entities.map((org) => ({
              ...org,
              isCurrentOrg: org.id === currentOrgId,
            }))
          }
          this.searching = false
        })
    })
  }

  isSuperUser$ = of(this.authorizationService.isSuper())
  hasDeleteFeatureFlag = this.featureFlagService
    .getFeatureFlag(FeatureFlags.admin.organization.delete)
    .pipe(map((result) => result.data.featureFlag?.value ?? false))

  /**
   * Emit true if the current user is a super user and they have the
   * `FeatureFlags.admin.organization.delete` feature flag enabled.
   */
  canDelete$ = combineLatest([this.isSuperUser$, this.hasDeleteFeatureFlag]).pipe(
    map(([isSuperUser, hasDeleteFeatureFlag]) => {
      return isSuperUser && hasDeleteFeatureFlag
    }),
    shareReplay(),
  )

  ngOnInit(): void {
    this.userOrgId = this.authenticationService.getUser()?.orgId
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
    this.dedOrgs$.next()
    this.dedOrgs$.complete()
  }

  openDrawerCreateOrg(): void {
    this.selected = []
    this.openDrawer()
  }

  openDrawer(): void {
    this.isDrawerOpen = true
  }

  drawerCancel(): void {
    this.orgSetId.setValue(null)
    this.isDrawerOpen = false
  }

  toggleSelected(id: string): void {
    if (this.selected.includes(id)) {
      pull(this.selected, id)
    } else {
      this.selected.push(id)
    }
  }

  async assignOrgSetId(): Promise<void> {
    try {
      await Promise.all(
        this.organizations
          .filter((org) => this.selected.includes(org.id))
          .map((org) =>
            this.organizationService.updateOrg(org.id, {
              orgSetId: this.orgSetId.value?.length ? this.orgSetId.value : null,
            }),
          ),
      )
      this.toast.success('Assigned orgs to Org Set ID')
      this.drawerCancel()
      this.search.updateValueAndValidity()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not assign Org Set ID'), JSON.stringify(e))
    }
  }

  async createOrg(org: Organization): Promise<void> {
    try {
      await this.organizationService.createOrg(org.name, org.orgSetId, org.testOrg)
      this.toast.success('Successfully created organization')
      this.drawerCancel()
      this.search.updateValueAndValidity()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not create organization'), JSON.stringify(e))
    }
  }

  async showDeleteOrgModal(org: Organization): Promise<void> {
    const modalData: ConfirmDeleteOrgDialogData = {
      org,
    }

    const dialogRef = this.dialogService.openDialog(ConfirmDeleteOrgDialogComponent, modalData)
    const component = dialogRef.componentInstance

    component.dialogCancel.pipe(takeUntil(this.destroy$)).subscribe(() => {
      dialogRef.close()
    })

    component.dialogDelete.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      this.deleteOrg(org).then((wasDeleted) => {
        if (wasDeleted) {
          dialogRef.close()

          this.toast.success('Successfully deleted organization')

          // This will update the org list.
          this.search.updateValueAndValidity()
        }
      })
    })
  }

  async deleteOrg(org: Organization): Promise<boolean> {
    return this.organizationService.deleteOrg(org.id).catch((error) => {
      this.toast.error('Failed to delete org', parseGraphQLError(error))

      return false
    })
  }
}
