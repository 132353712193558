import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import {
  DeleteSavedSearch,
  GetSavedSearch,
  SaveSearch,
  SearchSavedSearches,
} from 'app/shared/services/saved-search.gql'
import { Facet, FacetType, Selected } from 'app/shared/types/facet'
import { debug } from 'app/shared/utils/debug'
import {
  ListResponseMetaData,
  MutationDeleteSavedSearchArgs,
  MutationSaveSearchArgs,
  QuerySavedSearchArgs,
  QuerySavedSearchesArgs,
  SaveSearchInput,
  SavedSearch,
  SavedSearchList,
} from 'generated/graphql'
import { cloneDeep } from 'lodash'
import { BehaviorSubject } from 'rxjs'

/**
 * Handle saved search operations and facet interactions
 *
 * @export
 * @class FacetedSearchService
 */
@Injectable({
  providedIn: 'root',
})
export class FacetedSearchService {
  private meta: ListResponseMetaData
  private facets: Facet[] = []
  facets$ = new BehaviorSubject<Facet[]>([])

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*} {ListResponseMetaData}
   * @memberof FacetedSearchService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve a single saved search
   *
   * @param {string} id
   * @return {*}  {Promise<SavedSearch>}
   * @memberof FacetedSearchService
   */
  async getSavedSearch(id: string): Promise<SavedSearch> {
    debug('facet-service', 'get saved search', id)
    let result = await this.apollo
      .query<{ savedSearch: SavedSearch }, QuerySavedSearchArgs>({
        query: GetSavedSearch,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.savedSearch
  }

  /**
   * Retrieve a list of saved searches
   *
   * @param {string} [term]
   * @return {*}  {Promise<SavedSearch[]>}
   * @memberof FacetedSearchService
   */
  async searchSavedSearches(term?: string): Promise<SavedSearch[]> {
    debug('facet-service', 'search saved searches', term)
    let result = await this.apollo
      .query<{ savedSearches: SavedSearchList }, QuerySavedSearchesArgs>({
        query: SearchSavedSearches,
        variables: {
          search: term,
          limit: 10,
        },
      })
      .toPromise()

    this.meta = result.data.savedSearches?.meta

    return result.data.savedSearches?.entities
  }

  /**
   * Create a new saved search
   *
   * @param {SaveSearchInput} obj
   * @param {string} [id]
   * @return {*}  {Promise<SavedSearch>}
   * @memberof FacetedSearchService
   */
  async saveSearch(obj: SaveSearchInput, id?: string): Promise<SavedSearch> {
    debug('facet-service', 'save search', obj)

    let variables = {
      data: obj,
    }
    if (id) {
      variables['id'] = id
    }

    let result = await this.apollo
      .mutate<{ saveSearch: SavedSearch }, MutationSaveSearchArgs>({
        mutation: SaveSearch,
        variables: variables,
      })
      .toPromise()

    return result.data.saveSearch
  }

  /**
   * Delete an existing saved search
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof FacetedSearchService
   */
  async deleteSavedSearch(id: string): Promise<boolean> {
    debug('saved-search-service', 'delete saved search', id)
    let result = await this.apollo
      .mutate<{ deleteSavedSearch: boolean }, MutationDeleteSavedSearchArgs>({
        mutation: DeleteSavedSearch,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.deleteSavedSearch
  }

  /**
   * Add a value to an applied facet
   *
   * @param {string} id
   * @param {Selected} selected
   * @memberof FacetedSearchService
   */
  addToFacet(id: string, selected: Selected): void {
    let existing = this.facets.findIndex((f) => f.id === id)
    if (existing > -1) {
      if (this.facets[existing].selected.find((e) => e.display === selected.display)) {
        return
      }
      let single = this.facets[existing].onlySingle

      if (!single || this.facets[existing].selected.length < 1) {
        this.facets[existing].selected.push(selected)
      } else if (single) {
        this.facets[existing].selected = [selected]
      }
      this.facets[existing].skipFocus = true
      this.facets$.next(cloneDeep(this.facets))
      debug('facet-service', 'facets updated to', this.facets$.value)
    }
  }

  /**
   * Remove a value from an applied facet
   *
   * @param {string} id
   * @param {Selected} selected
   * @memberof FacetedSearchService
   */
  removeFromFacet(id: string, selected: Selected): void {
    let existing = this.facets.findIndex((f) => f.id === id)
    if (existing > -1) {
      let found = this.facets[existing].selected.findIndex((s) => s.display === selected.display)
      if (found === -1) {
        return
      }
      this.facets[existing].selected.splice(found, 1)
      this.facets$.next(cloneDeep(this.facets))
      debug('facet-service', 'facets updated to', this.facets$.value)
    }
  }

  /**
   * Add a facet to the list of applied facets
   *
   * @param {Facet} facet
   * @memberof FacetedSearchService
   */
  addFacet(facet: Facet): void {
    if (this.facets.findIndex((f) => f?.id === facet?.id) > -1) {
      return
    }
    if (facet?.type !== FacetType.PREDEFINED) {
      facet.selected = []
    }
    this.facets.push(facet)
    this.facets$.next(cloneDeep(this.facets))
    debug('facet-service', 'facets updated to', this.facets$.value)
  }

  /**
   * Set the list of applied facets
   *
   * @param {Facet[]} facets
   * @memberof FacetedSearchService
   */
  setFacets(facets: Facet[]): void {
    this.facets = facets
    this.facets$.next(cloneDeep(this.facets))
    debug('facet-service', 'facets updated to', this.facets$.value)
  }

  /**
   * Remove a facet from the list of applied facets
   *
   * @param {Facet} facet
   * @memberof FacetedSearchService
   */
  removeFacet(facet: Facet): void {
    let existing = this.facets.findIndex((f) => f.id === facet.id)
    if (existing === -1) {
      return
    }
    this.facets.splice(existing, 1)
    this.facets$.next(cloneDeep(this.facets))
    debug('facet-service', 'facets updated to', this.facets$.value)
  }

  /**
   * Clear the list of applied facets
   *
   * @memberof FacetedSearchService
   */
  removeAllFacets(): void {
    this.facets.splice(1) // wildcard search
    this.facets$.next(cloneDeep(this.facets))
    debug('facet-service', 'facets updated to', this.facets$.value)
  }
}
