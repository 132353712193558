import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-badge-pill',
  templateUrl: './badge-pill.component.html',
  styleUrls: ['./badge-pill.component.scss'],
})
export class BadgePillComponent implements OnInit {
  @Input() boldedLabel: string
  @Input() label: string
  @Output() onDeleteClicked = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}
}
