<nav class="navbar navbar-expand-xl navbar-dark bg-transparent p-0">
  <div class="d-flex" [ngClass]="isCollapsed ? 'h-100' : ''">
    <app-org-logo></app-org-logo>
    <a class="navbar-brand text-hide ml-4" routerLink="home">Janus</a>
  </div>
  <button
    class="navbar-toggler border-0 btn btn-link mr-4"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="navigation-links"
    aria-label="Toggle navigation"
  >
    <i
      class="fa fa-fw fa-lg"
      [ngClass]="{ 'text-alt-light-blue fa-bars': isCollapsed, 'text-alt-white fa-close': !isCollapsed }"
    ></i>
  </button>
  <div [ngbCollapse]="isCollapsed" class="d-xl-flex collapse navbar-collapse justify-content-end pl-5">
    <ul
      class="navbar-nav"
      id="navigation-links"
      data-t="nav-links"
      [ngClass]="{
        'bg-alt-light-blue like-dupe-nav': !isCollapsed,
        'flex-grow-1 justify-content-start': isCollapsed
      }"
    >
      <ng-template ngFor let-item [ngForOf]="menu">
        <ng-container *ngIf="item.type === 'link'">
          <ng-container *ngIf="item.visible !== false">
            <li
              *hasFeatureFlag="item.featureFlag"
              class="nav-item text-center d-flex flex-column"
              [ngClass]="{ 'styled-nav-link mx-4 ': isCollapsed, 'border-bottom border-alt-white': !isCollapsed }"
              [class.active]="isLinkActive(item.routerLink)"
            >
              <a
                class="nav-link d-flex flex-column"
                [routerLink]="item.routerLink"
                [ngClass]="{
                  disabled: item.routerLink ? null : true,
                  'position-relative': isCollapsed,
                  'px-4': !isCollapsed
                }"
                (click)="!isCollapsed ? (isCollapsed = true) : null"
              >
                {{ item.label }}
              </a>
            </li>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="item.type === 'menu'">
          <ng-container *ngIf="item.visible !== false">
            <li
              *hasFeatureFlag="item.featureFlag"
              ngbDropdown
              #navbarDropdown="ngbDropdown"
              class="nav-item text-center d-flex flex-column"
              [ngClass]="{ 'styled-nav-link mx-4': isCollapsed, 'border-bottom border-alt-white': !isCollapsed }"
              [class.active]="isLinkActive(item.children)"
            >
              <a
                class="nav-link"
                ngbDropdownToggle
                [ngClass]="{
                  disabled: item.children ? null : true,
                  'position-relative': isCollapsed,
                  'px-4': !isCollapsed
                }"
                data-t="nav-dropdown"
              >
                {{ item.label }}
              </a>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <ng-template ngFor let-child [ngForOf]="item.children">
                  <ng-container *ngIf="child.visible !== false">
                    <a
                      *hasFeatureFlag="child.featureFlag"
                      ngbDropdownItem
                      [routerLink]="child.routerLink"
                      [queryParamsHandling]="child.queryParamsHandling || null"
                      [class.active]="isLinkActive(child.routerLink)"
                    >
                      {{ child.label }}
                    </a>
                  </ng-container>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ng-container>
      </ng-template>
    </ul>
    <ul
      class="navbar-nav"
      id="navigation-icons"
      data-t="nav-icons"
      [ngClass]="{ 'bg-alt-light-blue like-dupe-nav pb-3': !isCollapsed }"
    >
      <li class="nav-item text-center pr-4 pl-3">
        <a
          class="nav-link"
          id="zendesk"
          [href]="zendesk.createTicketUrl"
          target="_blank"
          placement="bottom"
          ngbTooltip="Submit Help Ticket"
          aria-label="Submit Help Ticket"
        >
          <div class="d-inline-block align-middle pt-2 text-alt-white">
            <i class="fa fa-question-circle fa-2x fa-fw"></i>
          </div>
        </a>
      </li>
      <li
        #globalSearch="ngbDropdown"
        ngbDropdown
        placement="bottom-left"
        class="nav-item text-center pr-4 pl-3"
        [ngClass]="{ 'border-bottom border-alt-white': !isCollapsed }"
        (openChange)="onGlobalSearchToggled($event)"
      >
        <a role="button" class="nav-link" id="globalSearch" ngbDropdownToggle>
          <div class="d-inline-block align-middle pt-2 text-alt-white">
            <i class="fa fa-search fa-2x fa-fw"></i>
          </div>
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="globalSearch"
          class="dropdown-menu dropdown-menu-right dropdown-styled border-0 shadow-sm mt-0 p-3 pb-1"
        >
          <div class="input-group">
            <label for="global-claim-search-input" class="sr-only">Search Claims</label>
            <input
              #claimsSearch
              class="form-control w-auto"
              placeholder="Search claims"
              [formControl]="globalSearchQuery"
              (keyup.enter)="onSearchClaim()"
              name="global-claim-search-input"
              id="global-claim-search-input"
            />
            <span class="in-input-icon d-flex align-items-center"><app-loading *ngIf="isSearching"></app-loading></span>
          </div>
        </div>
      </li>
      <li
        *ngIf="isFavoritesEnabled"
        ngbDropdown
        #favsDrop="ngbDropdown"
        placement="bottom-left"
        class="nav-item text-center px-4 position-static"
        [ngClass]="{ 'border-bottom border-alt-white': !isCollapsed }"
      >
        <a role="button" class="nav-link" id="favsDropdown" ngbDropdownToggle data-t="favorites-dropdown">
          <div class="d-inline-block align-middle pt-2">
            <i
              class="fa fa-star fa-2x fa-fw"
              [ngClass]="{
                'fa-spin': isLoadingFavorites,
                'text-alt-white': !favsDrop.isOpen()
              }"
            ></i>
          </div>
        </a>
        <div
          ngbDropdownMenu
          aria-labelledby="favsDropdown"
          class="dropdown-menu dropdown-menu-right dropdown-styled border-0 shadow-sm mt-0"
        >
          <div class="d-flex flex-row-reverse flex-wrap" *ngIf="!isLoadingFavorites">
            <ng-template ngFor let-fav [ngForOf]="favoriteClaims" let-i="index">
              <div class="card bg-alt-lightest-grey m-3">
                <div class="card-body">
                  <h5 class="card-title text-brand-dark-gray" data-t="fav-provider-id">{{ fav?.providerClaimId }}</h5>
                  <h6 class="card-subtitle mb-2 text-alt-dark-gray">{{ fav?.patientName }}</h6>
                  <h6 class="card-subtitle mb-2 text-alt-light-gray">{{ fav?.payer?.name }}</h6>
                  <a
                    [routerLink]="['/claims', fav.providerClaimId]"
                    [queryParams]="{ billing_module: fav.billingModule }"
                    class="stretched-link"
                    (click)="favsDrop.close(); !isCollapsed ? (isCollapsed = true) : null"
                  ></a>
                </div>
              </div>
            </ng-template>
            <div
              class="col d-flex justify-content-center"
              *ngIf="!favoriteClaims || favoriteClaims?.length < 1"
              data-t="no-favorites"
            >
              No Favorites
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item text-center px-4 mr-xl-4" [ngClass]="{ 'border-bottom border-alt-white': !isCollapsed }">
        <app-user-avatar-menu></app-user-avatar-menu>
      </li>
    </ul>
  </div>
</nav>
