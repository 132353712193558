import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateObserveAccessControl,
  DeleteObserveAccessControl,
  AclAuditRecords,
  LastModifiedAclDate,
  ObserveAccessControl as ObserveAccessControlQuery,
  SearchObserveAccessControls,
  UpdateObserveAccessControl,
} from 'app/admin/observe-access-controls/observe-access-controls.gql'
import { debug } from 'app/shared/utils/debug'
import {
  CreateObserveAccessControlInput,
  ListResponseMetaData,
  MutationCreateObserveAccessControlArgs,
  MutationDeleteObserveAccessControlArgs,
  MutationUpdateObserveAccessControlArgs,
  ObserveAccessControl,
  ObserveAccessControlsList,
  ObserveAuditTrailList,
  QueryGetAclAuditRecordsArgs,
  QueryObserveAccessControlArgs,
  QueryObserveAccessControlsArgs,
  UpdateObserveAccessControlInput,
} from 'generated/graphql'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

/**
 * Handle Observe Access Control operations
 *
 * @export
 * @class ObserveAccessControlsService
 */
@Injectable({
  providedIn: 'root',
})
export class ObserveAccessControlsService {
  private meta: ListResponseMetaData
  private search: string = null
  public lastModifiedDate: string = null

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof ObserveAccessControlsService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve single observe access control
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ observeAccessControl: ObserveAccessControl }>>}
   * @memberof ObserveAccessControlsService
   */
  getObserveAccessControl(id: string): Observable<ApolloQueryResult<{ observeAccessControl: ObserveAccessControl }>> {
    return this.apollo.watchQuery<{ observeAccessControl: ObserveAccessControl }, QueryObserveAccessControlArgs>({
      query: ObserveAccessControlQuery,
      variables: {
        id,
      },
    }).valueChanges
  }

  /**
   * Retrieve the last modified audit record date
   *
   * @return {*}  {Observable<ApolloQueryResult<{ lastModifiedAclDate: ObserveAuditTrailList }>>}
   * @memberof ObserveAccessControlsService
   */
  getLastModifiedAclDate(): Observable<ApolloQueryResult<{ lastModifiedAclDate: string }>>{
      return this.apollo.watchQuery<{ lastModifiedAclDate: string }>({
        query: LastModifiedAclDate,
      }).valueChanges.pipe(tap(res => {
        this.lastModifiedDate = res.data.lastModifiedAclDate
      }))
  }

  /**
   * Retrieve a list of observe access audit records
   *
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [sort]
   * @return {*}  {Observable<ApolloQueryResult<{ getAclAuditRecords: ObserveAuditTrailList }>>}
   * @memberof ObserveAccessControlsService
   */
  getAclAuditRecords(
    limit: number = 50,
    offset: number = 0,
    sort: string = 'createdAt:asc',
  ): Observable<ApolloQueryResult<{ getAclAuditRecords: ObserveAuditTrailList }>> {
    return this.apollo.watchQuery<{ getAclAuditRecords: ObserveAuditTrailList }, QueryGetAclAuditRecordsArgs>({
      fetchPolicy: 'cache-first',
      query: AclAuditRecords,
      variables: {
        limit,
        offset,
        sort,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of observe access controls
   *
   * @param {QueryObserveAccessControlsArgs} [variables]
   * @return {*}  {Observable<ApolloQueryResult<{ observeAccessControls: ObserveAccessControlsList }>>}
   * @memberof ObserveAccessControlsService
   */
  searchObserveAccessControls(
    variables: QueryObserveAccessControlsArgs,
  ): Observable<ApolloQueryResult<{ observeAccessControls: ObserveAccessControlsList }>> {
    debug('observeAccessControls-service', 'search for observe access controls', variables)
    this.search = variables.search
    return this.apollo
      .watchQuery<{ observeAccessControls: ObserveAccessControlsList }, QueryObserveAccessControlsArgs>({
        query: SearchObserveAccessControls,
        variables,
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.observeAccessControls?.meta
        }),
      )
  }

  /**
   * Create a new observe access control
   *
   * @param {ObserveAccessControlInput} data
   * @return {*}  {Promise<ObserveAccessControl>}
   * @memberof ObserveAccessControlsService
   */
  async createObserveAccessControl(
    data: CreateObserveAccessControlInput,
  ): Promise<ObserveAccessControl | Observable<never>> {
    debug('observeAccessControls-service', 'create observe access control', data)
    let result = await this.apollo
      .mutate<{ createObserveAccessControl: ObserveAccessControl }, MutationCreateObserveAccessControlArgs>({
        mutation: CreateObserveAccessControl,
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: SearchObserveAccessControls, 
            variables: {
              sort: this.meta?.sort,
              offset: this.meta?.offset,
              limit: this.meta?.limit,
              search: '',
            }
          }
        ],
      })
      .toPromise()

    return result.data?.createObserveAccessControl
  }

  /**
   * Update an existing observe access control
   *
   * @param {string} id
   * @param {UpdateObserveAccessControlInput} data
   * @return {*}  {Promise<ObserveAccessControl>}
   * @memberof ObserveAccessControlsService
   */
  async updateObserveAccessControl(id: string, data: UpdateObserveAccessControlInput): Promise<ObserveAccessControl> {
    debug('observeAccessControls-service', 'update observe access control', id, data)
    let result = await this.apollo
      .mutate<{ updateObserveAccessControl: ObserveAccessControl }, MutationUpdateObserveAccessControlArgs>({
        mutation: UpdateObserveAccessControl,
        variables: {
          id,
          data,
        },
        refetchQueries: [
          {
            query: SearchObserveAccessControls, 
            variables: {
              sort: this.meta?.sort,
              offset: this.meta?.offset,
              limit: this.meta?.limit,
              search: '',
            }
          }
        ]
      })
      .toPromise()

    return result.data?.updateObserveAccessControl
  }

  /**
   * Delete an existing observe access control
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof ObserveAccessControlsService
   */
  async deleteObserveAccessControl(id: string): Promise<boolean> {
    debug('observeAccessControls-service', 'delete observe access control', id)
    let result = await this.apollo
      .mutate<{ deleteObserveAccessControl: boolean }, MutationDeleteObserveAccessControlArgs>({
        mutation: DeleteObserveAccessControl,
        variables: {
          id,
        },
        update: () => {
          let cache = this.apollo.client.cache
          cache.evict({ id: cache.identify({ id: id, __typename: 'ObserveAccessControl' }) })
          cache.gc()
        },
      })
      .toPromise()

    return result.data?.deleteObserveAccessControl
  }
}
