import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { User } from 'generated/graphql'

/**
 * Modal to allow filtering the list of task groups
 *
 * @export
 * @class TaskGroupFilterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-group-filter',
  templateUrl: './task-group-filter.component.html',
  styleUrls: ['./task-group-filter.component.scss'],
})
export class TaskGroupFilterComponent implements OnInit {
  @Input() selectedUser: User
  //@Input() completedValue: string
  @Input() includeCompleted: boolean

  filters = new UntypedFormGroup({
    userId: new UntypedFormControl(''),
    //completed: new FormControl('all'),
  })

  showAllUsers = false

  userId = this.filters.get('userId') as UntypedFormControl
  //completed = this.filters.get('completed') as FormControl

  constructor(public authenticationService: AuthenticationService, public modal: NgbActiveModal) {
    this.showAllUsers = this.authenticationService.canAccessManagerViews()
  }

  ngOnInit(): void {
    //this.completed.setValue(this.completedValue)
    this.filters.addControl('includeCompleted', new UntypedFormControl(this.includeCompleted))
    if (this.selectedUser?.id) {
      this.filters.get('userId').setValue(this.selectedUser?.id)
    }
  }

  /**
   * Close modal with selected filter values
   *
   * @memberof TaskGroupFilterComponent
   */
  applyFilters(): void {
    let user: User = null
    if (this.userId.value === true) {
      user = this.authenticationService.getUser()
    } else if (this.userId.value === this.selectedUser?.id || this.userId.value === this.selectedUser?.name) {
      user = this.selectedUser
    } else if (this.userId.value !== false) {
      user = this.userId.value
    }
    this.modal.close({ user: user, includeCompleted: this.filters.get('includeCompleted').value })
  }
}
