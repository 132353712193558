<div class="d-flex">
  <div class="flex-grow-1">
    <app-search-field [label]="'Search Workflows'" [search]="workflowSearch" [searching]="searching"></app-search-field>
  </div>
</div>
<small class="d-block text-center" *ngIf="workflows?.length">
  Showing {{ workflowsToShow?.length }} of {{ totalWorkflows }}
  <ng-container [ngPlural]="totalWorkflows">
    <ng-template ngPluralCase="1">workflow</ng-template>
    <ng-template ngPluralCase="other">workflows</ng-template>
  </ng-container>
</small>

<ng-template ngFor let-wf [ngForOf]="workflowsToShow" let-i="index">
  <app-workflow-card
    [workflow]="wf"
    [isCollapsed]="true"
    showRight="Assign"
    (clickRight)="selectWorkflow.emit($event)"
    [attr.data-t]="'workflow-' + wf.name"
  ></app-workflow-card>
</ng-template>
