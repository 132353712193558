<div class="modal-header">
  <h4 class="modal-title">Save Claims Search</h4>
</div>
<form [formGroup]="form" (ngSubmit)="saveSearch()" novalidate>
  <div class="modal-body">
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">Name</label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="name"
          required
          [appFcBsValidation]="form.get('name')"
        />
      </div>
    </div>
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">Description</label>
      <div class="col-sm-8">
        <textarea formControlName="description" class="form-control" cols="30" rows="10"></textarea>
      </div>
    </div>
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">Terms</label>
      <div class="col-sm-8">
        <app-selected-facets></app-selected-facets>
      </div>
    </div>
    <div class="row my-4">
      <label
        class="col-sm-4 d-flex align-items-center task-input-label"
        for="saveToTaskGroup"
        ngbTooltip="Select this to assign current and future claims matching this search to a task group"
        container="body"
      >
        Create Task Group
      </label>
      <div class="col-sm-8">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="saveToTaskGroup" formControlName="saveToTaskGroup" />
        </div>
      </div>
    </div>
    <div class="row my-4" *ngIf="savedSearch?.id">
      <div class="col-sm-8 offset-sm-4">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" id="newSearch" formControlName="newSearch" />
          <label class="d-flex align-items-center task-input-label pl-0" for="newSearch">Save as new search</label>
        </div>
        <small class="form-text text-danger" *ngIf="!canSaveExistingSearch">
          Only a manager can override this saved search.
        </small>
        <small class="form-text text-danger" *ngIf="savedSearch.assignToTaskGroupId && authenticationService.canAccessAdmin()">
          This search has a recurring task assignment associated with it!
        </small>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="modal.dismiss('Close click')"
      data-t="save-search-cancel-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="save-search-save-button">
      Save
      <app-loading *ngIf="saving" class="pl-2"></app-loading>
    </button>
  </div>
</form>
