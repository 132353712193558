import { Component, OnDestroy } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { timer, BehaviorSubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { InactivityService } from './inactivity.service'

export const INACTIVITY_WARNING_MS = 60 * 1000 // 1min

@Component({
  selector: 'app-inactivity-warning',
  templateUrl: './inactivity-warning.component.html',
})
export class InactivityWarningComponent implements OnDestroy {
  private countdownSubscription: Subscription
  isCountingDown$ = new BehaviorSubject(true)
  countdown$ = timer(0, 1000).pipe(map(() => this.inactivity.secondsUntilLogout))

  constructor(private modal: NgbActiveModal, private inactivity: InactivityService) {
    this.countdownSubscription = this.countdown$.subscribe((secondsRemaining) => {
      if (secondsRemaining < 1) {
        this.logout()
      }
    })
  }

  ngOnDestroy(): void {
    this.countdownSubscription.unsubscribe()
  }

  private stopCountdown(): void {
    this.isCountingDown$.next(false)
    this.isCountingDown$.complete()
  }

  keepalive(): void {
    this.stopCountdown()
    this.modal.close('keepalive')
  }

  logout(): void {
    this.stopCountdown()
    this.modal.close('logout')
  }
}
