<div class="row mt-3">
  <div class="col-6 mb-1 py-3 mr-auto">
      <div class="justify-content-start">
        Last modified:&nbsp;<a routerLink="/admin/observe/access-controls/audit-trail">{{
          lastModifiedAclDate | date: 'MM/dd/yyyy hh:mm a'
        }}</a>
      </div>
  </div>
  <div class="col-4 offset-1 ml-auto">
    <app-pagination [metaData]="accessControlMetaData" (fetchPage)="onPageUpdate($event)"> </app-pagination>
  </div>
  <div class="col-1 mt-2 ml-auto">
    <button
      type="button"
      class="btn btn-link bg-white mb-0 create-button float-right"
      ngbTooltip="Create Rule"
      (click)="createRule()"
      container="body"
    >
      <i class="fa fa-fw fa-lg fa-plus-square text-alt-light-gray"></i>
    </button>
  </div>
</div>

<div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto">
  <div class="row h-100 no-gutters offcanvas-wrapper">
    <div class="table-responsive col">
      <table class="table table-janus-new table-striped bg-white" data-t="observe-access-control-table">
        <thead>
          <tr>
            <th scope="col" class="text-brand-dark-gray">Rule</th>
            <th scope="col" class="text-brand-dark-gray">Type</th>
            <th scope="col" class="text-brand-dark-gray">Pattern</th>
            <th scope="col" class="text-brand-dark-gray">Name</th>
            <th scope="col"><span class="sr-only">Actions</span></th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoading && observeAccessControls?.length">
          <tr
            *ngFor="let accessControl of observeAccessControls; let i = index"
            data-t="observe-access-control-row"
            [id]="accessControl.id"
            [ngClass]="{
              'bg-alt-orange-med': matchingRegexIndexes?.includes(i) && matchClass === 'multi',
              'bg-alt-red-med': matchingRegexIndexes?.includes(i) && matchClass === 'denied',
              'bg-alt-green-med': matchingRegexIndexes?.includes(i) && matchClass === 'allowed'
            }"
            [class.bg-alt-baby-blue-high]="selectedACLId === accessControl?.id"
          >
            <td class="text-center">
              <div class="bg-white border-1 border-alt-medium-gray rounded-lg p-1">
                <div
                  class="h6 m-0 px-5 py-2 text-white rounded-lg"
                  [ngClass]="accessControl?.allowed ? 'bg-alt-green' : 'bg-alt-red'"
                >
                  {{ accessControl?.allowed ? 'ALLOW' : 'DENY' }}
                </div>
              </div>
            </td>
            <td class="text-center">{{ accessControl?.type }}</td>
            <td class="text-center">
              <span>{{ accessControl?.pattern }}</span>
              <span
                *ngIf="accessControl.type === 'URL' && accessControl.pattern?.trim() !== accessControl.pattern"
                class="ml-3 badge badge-warning position-relative"
                style="top: -3px"
              >
                Whitespace
              </span>
              <span
                *ngIf="accessControl.type === 'URL' && !patternCanBeInterpreted(accessControl.pattern)"
                class="ml-3 badge badge-warning position-relative"
                style="top: -3px"
              >
                Custom Pattern
              </span>
            </td>
            <td class="text-center">{{ accessControl?.name }}</td>
            <td class="text-right text-nowrap">
              <button
                type="button"
                class="mx-2 btn btn-link"
                (click)="createRule(accessControl)"
                ngbTooltip="Edit"
                container="body"
              >
                <i class="fa fa-fw fa-lg fa-pencil text-alt-light-gray"></i>
                <span class="sr-only">Edit access control</span>
              </button>
              <button
                type="button"
                class="btn btn-link p-0 mx-2 mb-2"
                data-t="delete-access-control-button"
                (click)="confirmDelete(accessControl?.id, $event)"
                ngbTooltip="Delete"
                container="body"
              >
                <i class="fa fa-fw fa-lg fa-trash text-alt-light-gray"></i>
                <span class="sr-only">Delete access control</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="isLoading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      ></app-loading>
      <div
        *ngIf="!observeAccessControls?.length && !isLoading"
        class="d-flex justify-content-center alert alert-danger"
        role="alert"
      >
        Your search returned no results.
      </div>
    </div>
  </div>
</div>
