import { Injectable } from '@angular/core'
import { Apollo, MutationResult, QueryRef } from 'apollo-angular'
import { SortDirection, SortColumn } from 'app/shared/directives/sortable.directive'
import { ObserveUserData, ObserveUserTableData, ObserveUserFilterTypes, ObserveUserFilter } from 'generated/graphql'
import { Observable } from 'rxjs'
import {
  ObserveUsers,
  ObserveUserFilters,
  UpdateObserveUser,
  RemoveObserveUser,
  RestoreObserveUser,
} from 'src/app/admin/observe-users/services/observe-user.gql'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ApolloQueryResult } from '@apollo/client'

@Injectable({
  providedIn: 'root',
})
export class ObserveUserService {
  API_URL = '/api'
  constructor(private apollo: Apollo, private http: HttpClient) {}

  public getObserveUsers(orgId: string, includeDeactivated?: boolean, includeDeleted?: boolean): QueryRef<{ observeUsersByOrgId: ObserveUserTableData }> {
    return this.apollo.watchQuery<{ observeUsersByOrgId: ObserveUserTableData }>({
      query: ObserveUsers,
      variables: {
        orgId,
        includeDeactivated,
        includeDeleted
      },
    })
  }

  public createCsvFileRequest(
    orgId: string,
    includeUsers: boolean,
    filters: Record<string, string[]> = {},
  ): Observable<any> {
    let queryParams = `orgId=${orgId}&includeUsers=${includeUsers}`

    for (let key of Object.keys(filters)) {
      if (filters[key].length) {
        queryParams = queryParams + '&' + `${key}=${filters[key].join(',')}`
      }
    }

    return this.http.get(`${this.API_URL}/observeUsers/download?${queryParams}`, {
      responseType: 'blob' as 'json',
    })
  }

  public uploadObserveUsersFile(orgId: string, file: File): Observable<{ success: boolean; errors: string[] }> {
    const data = new FormData()
    data.append('observeUsersFile', file)

    const headers = new HttpHeaders()
    headers.set('Content-Type', file.type)
    return this.http.post<{ success: boolean; errors: string[] }>(
      `${this.API_URL}/observeUsers/upload?orgId=${orgId}`,
      data,
      { headers },
    )
  }

  public sortObserveUsers(
    userList: ObserveUserData[],
    sortColumn: SortColumn,
    sortDirection: SortDirection,
  ): ObserveUserData[] {
    switch (sortColumn) {
      case ObserveUserTableColumns.fullName: {
        return userList.sort((a, b) => {
          return sortDirection == 'desc' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
        })
      }
      case ObserveUserTableColumns.userName: {
        return userList.sort((a, b) => {
          if (!a.userName) {
            return 1
          } else if (!b.userName) {
            return -1
          }
          return sortDirection == 'desc' ? b.userName.localeCompare(a.userName) : a.userName.localeCompare(b.userName)
        })
      }
      case ObserveUserTableColumns.functionalArea: {
        return userList.sort((a, b) => {
          if (!a.team.functionalArea.name) {
            return 1
          } else if (!b.team.functionalArea.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.team.functionalArea.name.localeCompare(a.team.functionalArea.name)
            : a.team.functionalArea.name.localeCompare(b.team.functionalArea.name)
        })
      }
      case ObserveUserTableColumns.teamName: {
        return userList.sort((a, b) => {
          if (!a.team.name) {
            return 1
          } else if (!b.team.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.team.name.localeCompare(a.team.name)
            : a.team.name.localeCompare(b.team.name)
        })
      }
      case ObserveUserTableColumns.manager: {
        return userList.sort((a, b) => {
          if (!a.manager.name) {
            return 1
          } else if (!b.manager.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.manager.name.localeCompare(a.manager.name)
            : a.manager.name.localeCompare(b.manager.name)
        })
      }
      case ObserveUserTableColumns.supervisor: {
        return userList.sort((a, b) => {
          if (!a.supervisor.name) {
            return 1
          } else if (!b.supervisor.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.supervisor.name.localeCompare(a.supervisor.name)
            : a.supervisor.name.localeCompare(b.supervisor.name)
        })
      }
      case ObserveUserTableColumns.director: {
        return userList.sort((a, b) => {
          if (!a.director.name) {
            return 1
          } else if (!b.director.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.director.name.localeCompare(a.director.name)
            : a.director.name.localeCompare(b.director.name)
        })
      }
      case ObserveUserTableColumns.role: {
        return userList.sort((a, b) => {
          if (!a.role.name) {
            return 1
          } else if (!b.role.name) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.role.name.localeCompare(a.role.name)
            : a.role.name.localeCompare(b.role.name)
        })
      }
      case ObserveUserTableColumns.epicUserName: {
        return userList.sort((a, b) => {
          if (!a.epicUserName) {
            return 1
          } else if (!b.epicUserName) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.epicUserName.localeCompare(a.epicUserName)
            : a.epicUserName.localeCompare(b.epicUserName)
        })
      }
      case ObserveUserTableColumns.resqUserName: {
        return userList.sort((a, b) => {
          if (!a.resqUserName) {
            return 1
          } else if (!b.resqUserName) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.resqUserName.localeCompare(a.resqUserName)
            : a.resqUserName.localeCompare(b.resqUserName)
        })
      }
      case ObserveUserTableColumns.enforcerUserName: {
        return userList.sort((a, b) => {
          if (!a.enforcerUserName) {
            return 1
          } else if (!b.enforcerUserName) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.enforcerUserName.localeCompare(a.enforcerUserName)
            : a.enforcerUserName.localeCompare(b.enforcerUserName)
        })
      }
      case ObserveUserTableColumns.athenaIdxUserName: {
        return userList.sort((a, b) => {
          if (!a.athenaIdxUsername) {
            return 1
          } else if (!b.athenaIdxUsername) {
            return -1
          }
          return sortDirection == 'desc'
            ? b.athenaIdxUsername.localeCompare(a.athenaIdxUsername)
            : a.athenaIdxUsername.localeCompare(b.athenaIdxUsername)
        })
      }
      default: {
        return userList
      }
    }
  }

  public fetchObserveUserFilters(
    orgId: string,
    filterType: ObserveUserFilterTypes,
  ): Observable<ApolloQueryResult<{ observeUserFilters: ObserveUserFilter[] }>> {
    return this.apollo.watchQuery<{ observeUserFilters: ObserveUserFilter[] }>({
      query: ObserveUserFilters,
      variables: {
        orgId,
        type: filterType,
      },
    }).valueChanges
  }

  public async updateObserveUser(
    orgId: string,
    observeUser: ObserveUserData,
    updatedByUserId: string,
  ): Promise<MutationResult<{ data: { updateObserveUser: ObserveUserData } }>> {
    const result = this.apollo.mutate<{ data: { updateObserveUser: ObserveUserData } }>({
      mutation: UpdateObserveUser,
      variables: {
        orgId,
        updatedByUserId,
        observeUser: {
          ...observeUser,
          role: {
            name: observeUser.role.name,
            id: observeUser.role.id,
          },
          manager: {
            name: observeUser.manager.name,
            id: observeUser.manager.id,
          },
          director: {
            name: observeUser.director.name,
            id: observeUser.director.id,
          },
          supervisor: {
            name: observeUser.supervisor.name,
            id: observeUser.supervisor.id,
          },
        },
      },
    })
    return result.toPromise()
  }

  public removeUser(
    deleteUser: boolean,
    orgId: string,
    observeUserId: string,
    updatedByUserId: string,
  ): Observable<MutationResult<{ removeObserveUser: boolean }>> {
    const result = this.apollo.mutate<{ removeObserveUser: boolean }>({
      mutation: RemoveObserveUser,
      variables: {
        orgId,
        observeUserId,
        updatedByUserId,
        deleteUser,
      },
    })

    return result
  }

  public restoreUser(
    undeleteUser: boolean,
    orgId: string,
    observeUserId: string,
    updatedByUserId: string,
    statusChangeDate: string,
  ): Observable<MutationResult<{ restoreObserveUser: boolean }>> {
    const result = this.apollo.mutate<{ restoreObserveUser: boolean }>({
      mutation: RestoreObserveUser,
      variables: {
        orgId,
        observeUserId,
        updatedByUserId,
        statusChangeDate,
        undeleteUser,
      },
    })

    return result
  }
}

export enum ObserveUserTableColumns {
  fullName = 'User',
  userName = 'Username',
  functionalArea = 'Functional Area',
  teamName = 'Team',
  manager = 'Manager',
  supervisor = 'Supervisor',
  director = 'Director',
  groups = 'Groups',
  role = 'Role',
  epicUserName = 'Epic Username',
  resqUserName = 'ResQ Username',
  enforcerUserName = 'Enforcer Username',
  athenaIdxUserName = 'athenaIdx Username',
  status = 'Status'
}
