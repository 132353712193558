import { Type } from '@angular/core'

export interface Selected {
  key?: string
  value: any
  display: string
  displayPill?: string
}

export enum FacetType {
  TEXT,
  TYPEAHEAD,
  RADIO,
  CHECKBOX,
  DATERANGE,
  NUMBERRANGE,
  SLIDER,
  PREDEFINED,
  COMPARISON,
}

export interface Facet {
  id: string
  otherIds?: string[]
  title: string
  type: FacetType
  component: Type<any>
  selected: Selected[]
  onlySingle?: boolean
  skipFocus?: boolean
  existsQuery?: boolean
  options?: Selected[]
  labelPrepend?: string
  search?: any
  valueProp?: string
  displayProp?: string
  inputFormatter?: any
  resultFormatter?: any
  max?: number
  comparisonProps?: [string, string]
}
