<div class="card detail-card my-4 border-0">
  <div class="card-header d-flex justify-content-between align-items-center with-arrow">
    <span class="flex-grow-1">{{ payer?.name }}</span>
    <span class="flex-shrink-0 pr-3">
      <button
        *ngIf="showRight"
        class="btn btn-sm btn-link"
        (click)="clickRight(payer, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRight"
        data-t="assign-arrow"
      >
        <i class="fa fa-2x fa-caret-right text-light pt-1"></i>
      </button>
      <button
        *ngIf="showTwoRight"
        class="btn btn-sm btn-link"
        (click)="clickTwoRight(payer, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showTwoRight"
        data-t="merge-forward-button"
      >
        <i class="fa fa-lg fa-forward text-light"></i>
      </button>
      <button
        *ngIf="showRemove"
        class="btn btn-sm btn-link"
        (click)="clickRemove(payer, $event)"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRemove"
      >
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
</div>
