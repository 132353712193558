import { Injectable, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd } from '@angular/router'
import { debug } from '../utils/debug'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { uniqBy } from 'lodash'
import { getFinalChildRouteWithTitle } from '../../getFinalChildRouteWithTitle'

export type Breadcrumb = {
  label: string
  link: string
  preserveFilters?: boolean
}

const TITLE_PREFIX = 'Janus AI'

/**
 * Handle displaying breadcrumbs
 *
 * @export
 * @class BreadcrumbsService
 */
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService implements OnDestroy {
  breadcrumbs: Breadcrumb[] = []
  disabledBreadcrumbDisplay = false

  isAuthRoute: boolean = false
  isVxRoute: boolean = false

  routerEvent$: BehaviorSubject<any> = new BehaviorSubject(null)

  destroy$ = new Subject<void>()

  constructor(private title: Title, private activatedRoute: ActivatedRoute) {
    this.routerEvent$.pipe(takeUntil(this.destroy$)).subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        if (window !== parent) {
          debug('django', 'navigation event', routerEvent)
          parent.postMessage(JSON.stringify({ type: 'NavigationEnd', data: routerEvent }), '*')
        }
        this.isAuthRoute = routerEvent.url.includes('/login')
        this.isVxRoute = routerEvent.url.includes('/vx')

        if (!this.isAuthRoute && this.isVxRoute) {
          this.breadcrumbs = uniqBy(this.createBreadcrumbs(this.activatedRoute.root), 'label')
        } else if (!this.isVxRoute) {
          this.breadcrumbs = uniqBy(this.createBreadcrumbs(this.activatedRoute.root), 'label')
          this.isVxRoute = false
        }

        let route: ActivatedRoute = getFinalChildRouteWithTitle(this.activatedRoute)
        if (route?.snapshot?.data?.title) {
          this.setPageTitle(route.snapshot.data.title)
        } else if (route?.snapshot?.data?.title === null) {
          this.setPageTitle()
        }

        this.checkIfBreadcrumbsDisabled(this.activatedRoute)
      }
    })
  }

  checkIfBreadcrumbsDisabled(activatedRoute: ActivatedRoute) {
    let currentRoute = activatedRoute.root
    let data = {} as any
    // Recursively retrieve the data object from each route, starting from the outermost parent
    // Data properties with the same name will be overwritten by the innermost child value
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild
      data = { ...data, ...(currentRoute.snapshot.data ?? {}) }
    }
    this.disabledBreadcrumbDisplay = !!data.disableBreadcrumbs
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  setPageTitle(str?: string): void {
    if (!str || str?.length < 1) {
      this.title.setTitle(TITLE_PREFIX)
    } else {
      this.title.setTitle(TITLE_PREFIX + ' - ' + str)
    }
  }

  /**
   * Create breadcrumbs from Angular route definitions for the current route
   *
   * @param {ActivatedRoute} route
   * @param {string} [link='']
   * @param {Breadcrumb[]} [breadcrumbs=[]]
   * @return {*}  {Breadcrumb[]}
   * @memberof BreadcrumbsService
   */
  createBreadcrumbs(route: ActivatedRoute, link: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    debug('breadcrumbs', 'createBreadcrumbs', route, link, breadcrumbs)
    const children: ActivatedRoute[] = route.children

    if (children.length === 0) {
      return breadcrumbs
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/')
      if (routeURL !== '') {
        link += `/${routeURL}`
      }

      let label = child.snapshot.data['breadcrumb']
      let preserveFilters = child.snapshot.data['preserveFilters']
      if (label !== null && label !== undefined) {
        label = label.startsWith(':') ? '' : label
        breadcrumbs.push({ label, link, preserveFilters })
      }

      return this.createBreadcrumbs(child, link, breadcrumbs)
    }
  }

  /**
   *
   * @description clear all breadcrumbs
   * @memberof BreadcrumbsService
   */
  clearBreadcrumbs(): void {
    this.breadcrumbs = []
  }

  /**
   * Change the breadcrumb label of the current route
   *
   * @param {string} label
   * @memberof BreadcrumbsService
   */
  relabelCurrentBreadcrumb(label: string, preserveFilters: boolean = false): void {
    debug('breadcrumbs', 'relabelCurrentBreadcrumb', label)
    let lastBreadcrumb = this.breadcrumbs.pop()
    this.breadcrumbs.push({ ...lastBreadcrumb, label, preserveFilters })
  }
}
