<div class="card detail-card">
  <app-loading
    *ngIf="loading"
    innerClass="fa-3x"
    class="d-flex flex-column w-100 h-100 flex-grow-1 justify-content-center bg-white position-absolute"
    outerClass="align-self-center text-brand-light-blue"
  ></app-loading>
  <div class="card-header">{{ taskGroup?.name }}</div>
  <div class="card-body">
    <form [formGroup]="taskGroupForm" (ngSubmit)="editTaskGroup()" class="d-flex flex-column" novalidate>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label required"> Group Name </label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            formControlName="taskGroup"
            required
            [appFcBsValidation]="taskGroupForm.get('taskGroup')"
            data-t="task-group-name-input"
          />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label"> Group Description </label>
        <div class="col-sm-8">
          <textarea
            formControlName="description"
            class="form-control"
            cols="30"
            rows="10"
            [appFcBsValidation]="taskGroupForm.get('description')"
          ></textarea>
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label"> Group Score </label>
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            formControlName="score"
            [appFcBsValidation]="taskGroupForm.get('score')"
          />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex task-input-label"> Assign Users </label>
        <div class="col-sm-8">
          <app-user-search (onSelect)="addUser($event)" [disabledUserIds]="selectedUserIds"> </app-user-search>
          <ul class="list-unstyled my-4 height-restrict">
            <app-loading *ngIf="loadingUsers" class="pl-2"></app-loading>
            <li *ngFor="let user of selectedUsers" class="d-flex justify-content-between text-dark">
              <span>
                <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeUser(user)"></i>{{ user?.name ||
                user?.email }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-sm-4 d-flex flex-column">
          <label class="task-input-label pl-0"> Tasks Assigned to Group </label>
          <small> Showing {{ tasks?.length > 10 ? '10' : tasks?.length }} of about {{ tasks?.length }} </small>
        </div>

        <div class="col-sm-8">
          <ul class="list-unstyled">
            <li *ngFor="let task of tasks | slice:0:10; let i=index">{{ task?.taskType?.name }}</li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-end mt-5">
        <div class="col-sm-2">
          <button type="button" class="btn btn-danger" data-t="delete-task-group-button" (click)="confirmDelete()">
            Delete
          </button>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-cancel mr-3"
            data-t="edit-task-group-cancel-button"
            (click)="cancelEditing()"
          >
            Cancel
          </button>
          <button type="submit" class="btn btn-brand-green px-5" data-t="save-task-group-button">
            Save
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
