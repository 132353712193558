<div class="modal-header">
  <h4 class="modal-title">Filter Tasks</h4>
</div>
<form [formGroup]="filters" (ngSubmit)="applyFilters()">
  <div class="modal-body">
    <fieldset class="form-group py-3">
      <legend>Show</legend>
      <div class="form-check">
        <input type="radio" class="form-check-input" formControlName="completed" value="all" id="all" />
        <label class="form-check-label" for="all">All Tasks</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          formControlName="completed"
          value="true"
          id="completedOnly"
          data-t="completed-tasks-option"
        />
        <label class="form-check-label" for="completedOnly">Only Completed Tasks</label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          formControlName="completed"
          value="false"
          id="undoneOnly"
          data-t="unfinished-tasks-option"
        />
        <label class="form-check-label" for="undoneOnly">Only Unfinished Tasks</label>
      </div>
    </fieldset>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="modal.dismiss('Close click')"
      data-t="cancel-task-group-filters-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="apply-task-group-filters-button" ngbAutofocus>
      Apply
    </button>
  </div>
</form>
