import { Injectable } from '@angular/core'
import { Payer } from 'generated/graphql'
import { Subject } from 'rxjs'

/**
 * Handle payer card interactions
 *
 * @export
 * @class PayerCardActionsService
 */
@Injectable({
  providedIn: 'root',
})
export class PayerCardActionsService {
  private rightSource = new Subject<Payer>()
  private twoRightSource = new Subject<Payer>()
  private removeSource = new Subject<Payer>()
  private hideCardSource = new Subject<{ id: string; hide: boolean }>()

  rightClicked$ = this.rightSource.asObservable()
  twoRightClicked = this.twoRightSource.asObservable()
  removeClicked$ = this.removeSource.asObservable()
  hideCard$ = this.hideCardSource.asObservable()

  hidden: string[] = []

  constructor() {}

  /**
   * User clicked single arrow
   *
   * @param {Payer} payer
   * @memberof PayerCardActionsService
   */
  clickRight(payer: Payer): void {
    this.rightSource.next(payer)
  }

  /**
   * User clicked double arrow
   *
   * @param {Payer} payer
   * @memberof PayerCardActionsService
   */
  clickTwoRight(payer: Payer): void {
    this.twoRightSource.next(payer)
  }

  /**
   * User clicked remove
   *
   * @param {Payer} payer
   * @memberof PayerCardActionsService
   */
  clickRemove(payer: Payer): void {
    this.removeSource.next(payer)
  }

  /**
   * User closed the card
   *
   * @param {Payer} payer
   * @param {boolean} hide
   * @memberof PayerCardActionsService
   */
  hideCard(payer: Payer, hide: boolean): void {
    this.hideCardSource.next({ id: payer.id, hide })
  }
}
