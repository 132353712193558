<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between row">
    <div class="col-sm">
      <div class="input-group form-group">
        <app-search-field
          [label]="'Search Groups'"
          [search]="groupSearch"
          [searching]="isSearchingGroups"
        ></app-search-field>
      </div>
    </div>
    <div class="col-3 d-flex justify-content-end">
      <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
    </div>
  </div>
  <div class="d-flex flex-column flex-shrink-1 flex-basis-0 table-responsive-md">
    <table class="table">
      <thead class="bg-alt-light-blue text-alt-white">
        <tr>
          <th scope="col" class="width-1" sortable="groupName" direction="asc" (sort)="onSort($event)">Group Name</th>
          <th scope="col" class="width-1 text-nowrap">Number of Mapped Plans</th>
          <th scope="col" class="width-1 text-nowrap">Number of Portal Mappings</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of payerPlanGroups">
          <td><a routerLink="/admin/payer-plan-groups/{{ group.name }}">{{group.name}}</a></td>
          <td>{{group.planMapCount}}</td>
          <td>{{group.portalMapCount}}</td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="isLoading"
      innerClass="fa-4x"
      outerClass="align-self-center text-primary"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
  </div>
  <div class="d-flex justify-content-end row">
    <div class="col-3 d-flex justify-content-end">
      <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
    </div>
  </div>
  <div class="d-flex row">
    <div class="col-sm-4">
      <div class="input-group form-group justify-content-start">
        <ng-container *ngIf="!isAddingGroup; else addGroupSection">
          <button class="btn bg-alt-light-blue text-alt-white text-nowrap" (click)="toggleAddingGroup()">
            Add Group
          </button>
        </ng-container>
        <ng-template #addGroupSection>
          <button class="btn bg-alt-light-gray text-alt-white text-nowrap" (click)="toggleAddingGroup()">Cancel</button>
          <input
            type="text"
            placeholder="Group Name"
            [(ngModel)]="groupToAdd"
            class="fixed-input form-control"
            container="body"
            (keyup.enter)="createGroupName()"
          />
          <button class="btn bg-alt-light-blue text-alt-white text-nowrap fixed-input" [disabled]="!groupToAdd" (click)="createGroupName()">Save</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
