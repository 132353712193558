<jui-dialog title="Confirm Delete">
  <p>
    Are you sure you want to delete the following <strong>{{ data.users.length }}</strong> users? Once deleted, their
    data cannot be recovered.
  </p>

  <ul>
    @for (user of data.users; track user.id) {
    <li>{{ user.name }}</li>
    }
  </ul>

  <div class="button-grid">
    @if (deleteIsDisabled()) {
    <jui-button [disabled]="true">Delete ({{ disableTimeout() }}s)</jui-button>
    } @else {
    <jui-button (click)="onDelete()">Delete</jui-button>
    }

    <jui-button (click)="onCancel()" variant="secondary">Cancel</jui-button>
  </div>
</jui-dialog>
