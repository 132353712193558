<ng-container *ngIf="!loading; else loadingIndicator">
  <div *ngIf="password.dirty && password.value !== ''" class="mt-n4 mb-4">
    <h6 class="alert-heading" [ngSwitch]="passwordFeedback?.score">
      <span *ngSwitchCase="0" class="text-danger">Very Weak</span>
      <span *ngSwitchCase="1" class="text-danger">Weak</span>
      <span *ngSwitchCase="2" class="text-warning">Getting there...</span>
      <span *ngSwitchDefault class="text-success">Strong Enough!</span>
    </h6>
  </div>

  <div *ngIf="isInvalid()" class="form-text mb-3">
    <div *ngIf="passwordFeedback?.warning" class="alert alert-warning" role="alert">
      <i class="fa fa-fw fa-exclamation-triangle"></i>
      {{ passwordFeedback.warning }}
    </div>
    <ul class="list-unstyled">
      <!-- Suggestions are dynamic, so will only be shown as errors -->
      <li *ngFor="let suggestion of passwordFeedback?.suggestions" class="text-danger">
        <i class="fa fa-fw pr-3 fa-times-circle"></i>
        {{ suggestion }}
      </li>
    </ul>
  </div>
</ng-container>

<ng-template #loadingIndicator>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
