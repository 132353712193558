import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { Organization } from 'generated/graphql';
import { firstValueFrom } from 'rxjs';
import { ObserveBuildService, ObserveBuildConfigResponse, ObserveBuildConfig } from '../services/observe-build.service';
import { TagSearchComponent } from '../tag-search/tag-search.component';
import { ToastService } from 'app/shared/services/toast.service';

@Component({
  selector: 'app-observe-build-config-page',
  templateUrl: './observe-build-config-page.component.html'
})

export class ObserveBuildConfigPageComponent implements OnInit {
  private readonly orgService = inject(OrgService)
  private readonly authenticationService = inject(AuthenticationService)
  private readonly observeBuildService = inject(ObserveBuildService) 
  private readonly toastService = inject(ToastService)

  private currentBuildConfig: ObserveBuildConfig = null

  protected availableTags: string[] = []
  protected currentOrg: Organization = null
  protected shouldUpdate = new FormControl(false)
  protected shouldObfuscate = new FormControl(false)
  protected installPrerequisites = new FormControl(true)
  protected tagToBuild = new FormControl('')

  @ViewChild('tagSearch') tagSearchComponent: TagSearchComponent

  async ngOnInit(): Promise<void> {
    await this.onOrgChange()
    try{
      this.availableTags = await firstValueFrom(this.observeBuildService.getObserveTags())
    } catch(error) {
      this.toastService.error('Error retrieving Observe Github tags')
    }
  }

  public async onOrgChange($event?: Organization): Promise<void> {
    const orgId = $event ? $event.id : this.authenticationService.getUser()?.orgId

    let orgResult = null
    try {
      orgResult = await firstValueFrom(this.orgService.getOrg(orgId))
    }
    catch(error) {
      this.toastService.error('Error retrieving organization')
    }
    this.currentOrg = orgResult?.data?.organization 

    let buildConfigResult: ObserveBuildConfigResponse = null
    try {
      buildConfigResult =  await firstValueFrom(this.observeBuildService.getObserveBuildConfig(this.currentOrg.id))
      this.currentBuildConfig = buildConfigResult?.buildConfig
    } catch (error) {
      this.toastService.error(`Error loading build config for organization: ${this.currentOrg.name}`)
    }
    this.shouldUpdate.setValue(buildConfigResult?.buildConfig?.shouldUpdate ?? false)
    this.shouldObfuscate.setValue(buildConfigResult?.buildConfig?.shouldObfuscate ?? false)
    this.installPrerequisites.setValue(buildConfigResult?.buildConfig?.installPrerequisites ?? true)
  }

  public async onTagChange($event: string) {
    this.tagToBuild.setValue($event)
  }

  public async onCancel($event: Event) {
    await this.onOrgChange(this.currentOrg)
    this.tagSearchComponent.clearTag($event)
  }

  public async onSubmit() {
    this.currentBuildConfig.shouldUpdate = this.shouldUpdate.value
    this.currentBuildConfig.shouldObfuscate = this.shouldObfuscate.value
    this.currentBuildConfig.installPrerequisites = this.installPrerequisites.value

    const buildConfigToSave: ObserveBuildConfig = {
      id: this.currentBuildConfig.id,
      orgId: this.currentBuildConfig.orgId,
      orgKey: this.currentBuildConfig.orgKey,
      env: this.currentBuildConfig.env,
      shouldObfuscate: this.shouldObfuscate.value,
      shouldUpdate: this.shouldUpdate.value,
      installPrerequisites: this.installPrerequisites.value
    }
    if(this.currentBuildConfig.uploadData) {
      buildConfigToSave.uploadData = this.currentBuildConfig.uploadData
    }
    
    let savedBuildConfig: ObserveBuildConfig = null;
    try{
      savedBuildConfig = await firstValueFrom(this.observeBuildService.saveObserveBuildConfig(buildConfigToSave))
    } catch (error) {
      this.toastService.error(`Error saving build config for organization: ${this.currentOrg.name}`)
    }

    if(savedBuildConfig === null) {
      return
    }

    try {
      const buildResponse = await firstValueFrom(this.observeBuildService.startObserveBuild(savedBuildConfig.orgId, 
        this.tagToBuild.value, 
        savedBuildConfig.id)
      )
      this.toastService.success(buildResponse)
    } catch (error) {
      this.toastService.error(`Error starting build for organization: ${this.currentOrg.name}`)
    }
  }
}
