import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { TeleportService } from 'app/teleport/teleport.service'
import { FeatureFlags, FeatureFlagService } from '../shared/services/feature-flag.service'

type UrlParams = {
  teleportHistoryId: string
  //procedure set
  userId?: string
  procedureSetId?: string
  botType?: string
  credPool?: string
  patientDob?: string
  patientFirstName?: string
  patientLastName?: string
  patientMemberId?: string
  payer?: string
  portal?: string
  billingNpi?: string
  referringNpi?: string
  renderingNpi?: string
  billingAddress?: string
  referringAddress?: string
  renderingAddress?: string
  serviceDatesEnd?: string
  serviceDatesStart?: string
  orgId?: string
  urgency?: string
  diagnosisCode?: string
  diagnosisCodeSecondary?: string
  primaryProcedureCode?: string
  tertiaryProcedureCodes?: string
  zyncAllowed?: string
  reuseTab?: string
  providerBillingName?: string
  providerReferringName?: string
  providerRenderingName?: string
  billingTaxId?: string
  claimId?: string
  attendingNpi?: string
  availityOrgId?: string
  billingModule?: string

  claimAmount?: string
  credentialTypeModifier?: string
  operatingNpi?: string
  patientAccountNumber?: string
  payerName?: string
  subscriberName?: string
  patientRelationship?: string
  payerAddress?: string
  providerGroupNpi?: string
  providerNpi?: string
  providerTaxId?: string
  serviceDate?: string
  submissionDate?: string
  subscriberId?: string
  patientSex?: string
  groupNumber?: string
  formType?: string
}

/**
 * Page to display the Teleport landing screen
 *
 * @export
 * @class TeleportPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-teleport',
  templateUrl: './teleport.page.html',
  styleUrls: ['./teleport.page.scss'],
})
export class TeleportPage implements OnInit {
  constructor(
    private router: Router,
    private teleportService: TeleportService,
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
  ) {}

  teleportTakeover: boolean = false
  teleportFailed: boolean = false
  ngOnInit(): void {
    setTimeout(() => (this.teleportTakeover = true), 1000)
    setTimeout(async () => {
      //If Teleport doesn't pick up 2 minutes after alerting users it should, then Teleport likely failed
      const teleportUiObservability = await this.featureFlagService.getFeatureFlagValue(
        FeatureFlags.ui.teleportUiObservability,
      )
      const { botType, payer, portal, procedureSetId, claimId, userId, orgId, teleportHistoryId } = Object.fromEntries(
        new URL(window.location.href).searchParams,
      ) as UrlParams

      const baseFields = {
        botType,
        payer,
        portal,
        status: 'FAILED',
        errorType: 'TELEPORT_FAILED_TO_LAUNCH',
        userId,
        browserType: this.teleportService.getBrowserType(),
        networkDetails: this.teleportService.getNetworkDetails(),
      }

      if (teleportUiObservability && teleportHistoryId) {
        await this.http
          .post(
            '/api/teleport/history/update',
            {
              id: teleportHistoryId,
              ...baseFields,
              relatedEntityId: procedureSetId ?? claimId,
              relatedEntityType: procedureSetId ? 'procedure_set' : 'claim',
              startedAt: new Date(),
              logs: [],
              metrics: {
                ...baseFields,
                orgId,
                env: 'browser',
                errorMsg: 'teleport failed to take over from janus ui',
                lastPhase: 'LAUNCH',
              },
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .toPromise()
      }
      this.teleportFailed = true
      this.teleportTakeover = false
      //Teleport is most likely to fail this way due to an unsupported payer. This is our best bet until teleport can find out if a payer is supported or not.
      this.router.navigate(['teleport', 'warning'], {
        queryParams: { errorType: 'UnsupportedPayer' },
      })
    }, 2 * 60 * 1000 + 1000) // m * s * ms + ms
  }
}
