import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { ObserveActivityLogModels, ObserveActivityListResponse} from 'generated/graphql';
import { Observable } from 'rxjs';
import { ActivityLogs } from 'src/app/admin/observe/services/observe-services.gql'


@Injectable({
  providedIn: 'root'
})
export class ObserveActivityLogService {
  API_URL = '/api'
  constructor(private apollo: Apollo, private http: HttpClient) { }

  public getObserveActivityLogs(orgId: string, modelType: ObserveActivityLogModels, search: string = '', limit: number = 100, 
    offset: number = 0, sort: string = '' ): Observable<ApolloQueryResult<{activityLogs: ObserveActivityListResponse}>> {
    return this.apollo.watchQuery<{activityLogs: ObserveActivityListResponse}>(
      {
        query: ActivityLogs, 
        variables: {
          orgId,
          limit,
          offset,
          searchText: search, 
          sort,
          entityType: modelType
        }
      }
    ).valueChanges
  }

  public exportObserveActivityLogs(orgId: string, entityType: ObserveActivityLogModels): Observable<any>{
    return this.http.get<any>(`${this.API_URL}/observeActivityLog?orgId=${orgId}&entityType=${entityType}`, {
    responseType: 'blob' as 'json'
   })
 }
}
