import { Component, Input, OnInit } from '@angular/core';

export type JanusIcon = 'bot' | 'dag' | 'janus' | 'teleport'

const glyphMap = new Map<JanusIcon, string>([
  ['bot', 'd'],
  ['dag', 'a'],
  ['janus', 'b'],
  ['teleport', 'c']
])

@Component({
  selector: 'app-janus-icon',
  templateUrl: './janus-icon.component.html',
  styleUrls: ['./janus-icon.component.scss']
})
export class JanusIconComponent implements OnInit {

  @Input() icon: JanusIcon
  glyph: string

  ngOnInit(): void {
    this.glyph = glyphMap.get(this.icon)
  }

}
