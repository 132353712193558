import { Injectable, OnDestroy } from '@angular/core'
import { Modifiers } from '@apollo/client/cache/core/types/common'
import { Apollo } from 'apollo-angular'
import { debug } from 'app/shared/utils/debug'
import { FileChanges } from 'app/vision-x/vision-x.gql'
import { SubscriptionNotification } from 'generated/graphql'
import { Subject } from 'rxjs'
import { delay, takeUntil } from 'rxjs/operators'

export enum FileTopics {
  fileManuallyCategorized = 'fileManuallyCategorized',
}

@Injectable({
  providedIn: 'root',
})
export class FileSubscriptionService implements OnDestroy {
  destroy$ = new Subject<void>()

  constructor(private apollo: Apollo) {}

  subscribe(): void {
    this.apollo
      .subscribe<{ fileChanges: SubscriptionNotification }>({
        query: FileChanges,
      })
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(
        (event) => {
          const payload = event.data.fileChanges
          if (payload.type === FileTopics.fileManuallyCategorized) {
            const notificationData: {
              oldPageTypeId: string
              newPageTypeId: string
              oldPageTypeConfidence: 'manual' | 'low' | 'medium' | 'high'
            } = JSON.parse(payload.data)
            let cache = this.apollo.client.cache
            try {
              cache.modify({
                id: cache.identify({ id: payload.entityId, __typename: 'VxPage' }),
                fields: {
                  vxPageTypeId() {
                    return notificationData.newPageTypeId
                  },
                },
              })
            } catch (e) {
              debug(
                'subscriptions',
                'file category cache update went sideways',
                JSON.stringify(payload),
                JSON.stringify(e),
              )
            }
            if (notificationData.newPageTypeId) {
              try {
                cache.modify({
                  id: cache.identify({ id: notificationData.newPageTypeId, __typename: 'VxPageType' }),
                  fields: {
                    fileCount(existing: number) {
                      return existing + 1
                    },
                    manualCategorizeFileCount(existing: number) {
                      return existing + 1
                    },
                  },
                })
              } catch (e) {
                debug(
                  'subscriptions',
                  'new visionX category cache update went sideways',
                  JSON.stringify(payload),
                  JSON.stringify(e),
                )
              }
            }
            if (notificationData.oldPageTypeId) {
              const fieldsToUpdateOnOldCategory: Modifiers = {
                fileCount(existing: number) {
                  return existing - 1
                },
              }
              try {
                cache.modify({
                  id: cache.identify({ id: notificationData.oldPageTypeId, __typename: 'VxPageType' }),
                  fields: fieldsToUpdateOnOldCategory,
                })
              } catch (e) {
                debug(
                  'subscriptions',
                  'old visionX category cache update went sideways',
                  JSON.stringify(payload),
                  JSON.stringify(e),
                )
              }
            }
          }
        },
        (error) => {
          debug('subscriptions', 'file sub got error')
        },
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
