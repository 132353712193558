<app-collapsing-card
  *ngFor="let task of sortedTasks; index as i; trackBy: trackById"
  [attr.id]="task?.id"
  [isCollapsed]="isCollapsed[i]"
  (onToggled)="loadComments($event, task?.id, i)"
  class="bg-light border mb-3"
>
  <div slot="title">
    <div
      class="d-flex flex-row align-items-center"
      [ngClass]="{
        'text-success': task?.completionType !== 'Rejected' && task?.completedUserId !== null,
        'text-danger': task?.completionType === 'Rejected'
      }"
    >
      <i
        class="fa fa-lg fa-fw circle pr-3"
        [class.fa-check-circle-o]="task?.completionType !== 'Rejected'"
        [class.fa-circle-o]="task?.completedUserId == null"
        [class.fa-times-circle-o]="task?.completionType === 'Rejected'"
        aria-hidden="true"
        data-t="task-accordion-status-icon"
      ></i>
      <i class="fa fa-lg fa-fw fa-cogs circle" aria-hidden="true" *ngIf="task?.taskType?.isBotTask"></i>
      <span class="item link d-flex flex-grow-1 justify-content-between">
        <span>{{ task?.taskType?.name }}</span>
        <span class="align-self-end text-dark">
          <span class="small">
            {{ task?.completedBy }}
            <span class="date" *ngIf="task?.completedUserId != null">
              {{ task?.completedDate ? (task.completedDate | date: 'MM/dd/yyyy') : '' }}
            </span>
            <app-task-picked-by [userIds]="task?.pickedByUserIds"></app-task-picked-by>
          </span>
        </span>
      </span>
    </div>
  </div>
  <div slot="content">
    <div class="row my-3">
      <div class="col-lg-6 col-xl-12 d-flex flex-column">
        <div class="position-absolute align-self-end" *ngIf="authenticationService.isStaff()">
          <!-- <button *ngIf="task?.botRun?.rawData" type="button" class="btn btn-link" (click)="showRawData(rawData)">
            <i class="fa fa-fw fa-cogs"></i>
          </button> -->
          <button
            *ngIf="authenticationService.canAccessAdmin()"
            type="button"
            class="btn btn-link"
            ngbTooltip="Delete task"
            container="body"
            (click)="confirmTaskDelete(task)"
            [disabled]="deleting"
          >
            <i class="fa fa-fw fa-trash text-danger"></i>
          </button>
        </div>

        <!-- instructions-->
        <h3>Task Instructions</h3>
        <div class="task-instructions taller-height-restrict">
          <p [innerHTML]="task?.taskType?.description"></p>
        </div>
        <app-task-actions [task]="task" (taskEvent)="loadComments(true, task?.id, -1)"></app-task-actions>
      </div>
      <div class="col-lg-6 col-xl-12">
        <app-task-comments [comments]="comments" [taskId]="task?.id" [loading]="loadingComments"></app-task-comments>
      </div>
    </div>
  </div>
</app-collapsing-card>
<ng-template #rawData let-modal>
  <div class="modal-header">
    <h4>Raw Data for BotRun {{ currentTask?.botRun.id }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <pre class="pre-wrap">{{ currentTask?.botRun.rawData }}</pre> -->
  </div>
</ng-template>
