import { formatCurrency, formatDate } from '@angular/common'
import { Component, Input, OnChanges } from '@angular/core'
import { ToastService } from 'app/shared/services/toast.service'
import { Claim } from 'generated/graphql'

/**
 * Component to display a generated note for a claim
 *
 * @export
 * @class ClaimGenerateNoteComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-claim-generate-note',
  templateUrl: './claim-generate-note.component.html',
  styleUrls: ['./claim-generate-note.component.scss'],
})
export class ClaimGenerateNoteComponent implements OnChanges {
  @Input() claim: Claim = null

  noteCollapsed = true
  noteText = ''

  constructor(public toast: ToastService) {}

  ngOnChanges(): void {
    if (this.claim) {
      let statuses = ['Category', 'Status', 'Remit Advice Remark', 'Payer', 'EHR']
      let claimStatus = ['', '', '', '', '']
      this.claim.servicelines.forEach((s) => {
        if (s.categoryCodes?.length) {
          claimStatus[0] += s.categoryCodes.map((c) => c.code).join(', ')
        }
        if (s.claimStatusCodes?.length) {
          claimStatus[1] += s.claimStatusCodes.map((c) => c.code).join(', ')
        }
        if (s.remitAdviceRemarkCodes?.length) {
          claimStatus[2] += s.remitAdviceRemarkCodes.map((c) => c.code).join(', ')
        }
        if (s.payerStatusCodes?.length) {
          claimStatus[3] += s.payerStatusCodes.map((c) => c.code).join(', ')
        }
        if (s.ehrStatusCodes?.length) {
          claimStatus[4] += s.ehrStatusCodes.map((c) => c.code).join(', ')
        }
      })
      let statusString = claimStatus
        .map((s, i) => {
          return claimStatus[i].length ? `${statuses[i]}: ${claimStatus[i]}` : ''
        })
        .filter((s) => s?.length)
        .join(', ')
      this.noteText = `
        DEPARTMENT: ${this.claim?.department || ''}
        DOS: ${formatDate(this.claim?.serviceDate, 'longDate', 'en')}
        PROVIDER: ${this.claim?.providerBilling?.name || ''}
        CHARGE AMOUNT: ${formatCurrency(this.claim?.claimAmount, 'en', '$')}
        OUTSTANDING AMOUNT: ${formatCurrency(this.claim?.outstandingAmount, 'en', '$')}
        CPT CODE: ${this.claim.servicelines.map((s) => s.cpt || null).join(', ')}
        DENIAL REASON: ${this.claim.servicelines
          .map((s) => s.claimAdjustmentReasonCodes?.map((c) => c.code).join(', '))
          .filter((s) => s?.length)
          .join(', ')}
        CLAIM STATUS: ${statusString}
        INSURANCE NAME: ${this.claim?.payer?.name || ''}
        INSURANCE PHONE NUMBER:
        CLAIM REPRESENTATIVE:
        STATUS:
      `
    }
  }

  /**
   * Toggle the note open / closed
   *
   * @param {*} note
   * @memberof ClaimGenerateNoteComponent
   */
  toggleNote(note: { toggle: () => void }): void {
    note.toggle()
  }
}
