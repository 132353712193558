<div class="h-100 d-flex flex-column justify-content-center align-items-stretch position-relative" *ngIf="src">
  <ng-container *ngIf="isLoading">
    <span class="h1 text-center"><i class="fa fa-image"></i></span>
    <p class="text-center"><app-loading></app-loading> Loading image</p>
  </ng-container>
  <ng-container *ngIf="hasError">
    <span class="h1 text-center"><i class="fa fa-exclamation-circle text-danger"></i></span>
    <p class="text-center">Could not load image</p>
  </ng-container>
  <div class="position-absolute text-center top-0 right-0 bottom-0 left-0" id="image-container">
    <img
      [src]="src"
      (load)="isLoading = false"
      (error)="isLoading = false; hasError = true"
      class="mw-100 mh-100 shadow"
      [ngClass]="{ 'd-none': isLoading || hasError }"
    />
  </div>
</div>
