import { Component, Input, OnChanges } from '@angular/core'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskGroup, TaskGroupStats, User } from 'generated/graphql'

/**
 * Component to display task group overview as a card
 * used in task-groups.page
 *
 * @export
 * @class TaskGroupOverviewCardComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-task-group-overview-card',
  templateUrl: './task-group-overview-card.component.html',
  styleUrls: ['./task-group-overview-card.component.scss'],
})
export class TaskGroupOverviewCardComponent implements OnChanges {
  @Input() taskGroup: TaskGroup = null
  @Input() stats: TaskGroupStats = null

  users: User[] = null

  constructor(
    private userService: UsersService,
    private taskGroupService: TaskGroupsService,
    private toast: ToastService,
  ) {}

  async ngOnChanges(): Promise<void> {
    if (this.taskGroup.assignedToUserIds?.length) {
      this.onUsersChange(this.taskGroup.assignedToUserIds)
    }
  }

  /**
   * Get users assigned to task group
   *
   * @param {string[]} [userIds]
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupOverviewCardComponent
   */
  async onUsersChange(userIds?: string[]): Promise<void> {
    if (userIds?.length) {
      try {
        this.users = await this.userService.getUsers('', userIds)
      } catch (e) {
        this.users = []
      }
    } else {
      this.users = []
    }
  }

  /**
   * Add or remove user assignment to task group
   *
   * @param {*} $event
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupOverviewCardComponent
   */
  async onEditUsers($event: { userId: string; remove: boolean }): Promise<void> {
    try {
      let userIds = this.users?.map((user) => user.id) || []
      if ($event.remove) {
        let oldUserIndex = userIds.findIndex((id) => id === $event.userId)
        userIds.splice(oldUserIndex, 1)
      } else {
        userIds.push($event.userId)
      }
      let result = await this.taskGroupService.saveTaskGroup(
        {
          name: this.taskGroup.name,
          description: this.taskGroup.description,
          score: this.taskGroup.score,
          assignedToUserIds: userIds,
        },
        this.taskGroup.id,
      )
      await this.onUsersChange(result.assignedToUserIds)
      this.toast.success('Task group successfully edited')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save task group'), JSON.stringify(e))
    }
  }
}
