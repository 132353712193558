import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Pill } from 'app/shared/components/faceted-search/selected-facets/selected-facets.component'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { ToastService } from 'app/shared/services/toast.service'
import { SEARCHFIELD } from 'app/shared/types/facets'
import { searchToFacet } from 'app/shared/utils/search-to-facet'
import { SavedSearch } from 'generated/graphql'

/**
 * Modal to load a previously saved facet
 *
 * @export
 * @class LoadSearchComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-load-search',
  templateUrl: './load-search.component.html',
  styleUrls: ['./load-search.component.scss'],
  host: {
    'data-t': 'load-search-modal',
  },
})
export class LoadSearchComponent implements OnInit {
  selectedSearch: SavedSearch = null
  pills: Pill[]

  constructor(private facetService: FacetedSearchService, public modal: NgbActiveModal, private toast: ToastService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  /**
   * Load the detail view of a selected search
   *
   * @param {SavedSearch} selected
   * @memberof LoadSearchComponent
   */
  selectSearch(selected: SavedSearch): void {
    let pills = []
    selected.facets.forEach((search) => {
      search.selected.forEach((s) => {
        pills.push({ facet: search.facet, title: search.title, selected: s })
      })
    })
    this.pills = pills
    this.selectedSearch = selected
  }

  /**
   * Load and execute a saved search
   *
   * @memberof LoadSearchComponent
   */
  load(): void {
    if (this.selectedSearch) {
      this.facetService.removeAllFacets()
      let facets = this.selectedSearch.facets.map(searchToFacet)
      if (!facets.find((f) => f.id === 'wildcard')) {
        facets.unshift(SEARCHFIELD)
      }
      this.facetService.setFacets(facets)
      this.modal.close(this.selectedSearch)
    }
  }
}
