<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <div class="form-group">
      <label class="sr-only">Search</label>
      <input
        type="text"
        class="form-control"
        placeholder="Search for a {{ data.title }}"
        [ngbTypeahead]="search"
        [resultFormatter]="data.resultFormatter || defaultFormatter"
        [inputFormatter]="data.inputFormatter || defaultFormatter"
        (focus)="focus$.next($any($event).target.value)"
        (click)="click$.next($any($event).target.value)"
        (selectItem)="onSelect($event, input)"
        #input
        #instance="ngbTypeahead"
      />
    </div>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [formControl]="exact" [attr.id]="data.id + 'exact'" />
      <label
        class="form-check-label"
        [attr.for]="data.id + 'exact'"
        ngbTooltip="Search for terms that are exactly this one"
        container="body"
      >
        Search exact term only
      </label>
    </div>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [formControl]="negate" [attr.id]="data.id + 'negate'" />
      <label
        class="form-check-label"
        [attr.for]="data.id + 'negate'"
        ngbTooltip="Search for all terms NOT matching this one"
        container="body"
      >
        NOT
      </label>
    </div>
  </div>
</div>
