import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { OrgService } from 'app/admin/org/org.service'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { Subject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'
import { ZendeskService } from 'app/shared/services/zendesk.service'

export type AuthError =
  | 'INVALID_CREDENTIALS'
  | 'MFA_CODE_REQUIRED'
  | 'INVALID_MFA_CODE'
  | 'INVALID_EMAIL'
  | 'INACTIVE'
  | 'STALE'
  | 'LOCKOUT'
  | 'MULTIPLE_USERS'
  | 'NO_ORG'

const UNKNOWN_LOGIN_ERROR_MESSAGE = 'An unknown error has occured'

export type View = 'USERNAME' | 'PASSWORD' | 'MFA' | 'LOADING' | 'SELECT_ORG'

/**
 * Login page component
 *
 * @export
 * @class LoginPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()
  view: View = 'USERNAME'

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(null),
    pass: new UntypedFormControl(null),
    mfaCode: new UntypedFormControl(null),
  })

  isAuthenticating: boolean = false
  loginError: string = null
  queryParams: Params
  errorPane: AuthError = null

  constructor(
    private authenticationService: AuthenticationService,
    private organizationService: OrgService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private featureFlagService: FeatureFlagService,
    protected zendesk: ZendeskService,
    private router: Router,
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.errorPane = null
      if (params.error) {
        if (params.error === 'saml') {
          this.view = 'USERNAME'
          this.loginError = 'Something went wrong with your SSO provider'
        }
      }
    })
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((queryParams) => {
      this.queryParams = queryParams
    })
    this.loginForm
      .get('username')
      .valueChanges.pipe(
        switchMap(async (username) => {
          this.view = 'LOADING'
          this.loginError = null
          try {
            let result = await this.authenticationService.loginPreCheck(username)
            return result
          } catch (e) {
            if (e.status === 300) {
              // SAML required
              window.location.assign(e.error)
              return null
            }
            this.toast.error("Could not determine user's authentication type", JSON.stringify(e))
            this.view = 'USERNAME'
            return null
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((auth) => {
        debug('app', 'login pre-check got', JSON.stringify(auth))
        if (auth?.body) {
          this.view = auth.body === 'EMAIL_AUTH' ? 'MFA' : 'PASSWORD'
        }
      })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$?.next()
    this.destroy$?.complete()
  }

  /**
   * Save username to page form when sent
   *
   * @param {string} username
   * @memberof LoginPage
   */
  receiveUsername(username: string): void {
    this.loginForm.get('username').setValue(username)
  }

  /**
   * Save password to page form when sent
   *
   * @param {string} password
   * @return {*}  {Promise<void>}
   * @memberof LoginPage
   */
  async receivePassword(password: string): Promise<void> {
    this.loginForm.get('pass').setValue(password)
    await this.login()
  }

  /**
   * Save mfa code to page form when sent
   *
   * @param {string} mfaCode
   * @return {*}  {Promise<void>}
   * @memberof LoginPage
   */
  async receiveMfaCode(mfaCode: string): Promise<void> {
    this.loginForm.get('mfaCode').setValue(mfaCode)
    await this.login()
  }

  /**
   * Log in a user based on credentials entered
   *
   * @return {*}  {Promise<void>}
   * @memberof LoginPage
   */
  async login(): Promise<void> {
    if (!this.loginForm.valid) {
      this.loginError = 'Please fill in all fields before attempting to login'
      return
    }

    this.loginError = null
    this.isAuthenticating = true

    try {
      await this.authenticationService.login(
        this.loginForm.get('username').value || undefined,
        this.loginForm.get('pass').value || undefined,
        this.loginForm.get('mfaCode').value || undefined,
        this.queryParams,
        false,
      )

      this.authenticationService.setUser()

      const relayState = this.route.snapshot.queryParamMap.get('relayState') || DEFAULT_HOME_PATH
      const orgId = this.router.parseUrl(relayState).queryParams['orgId']

      if (!orgId && this.authenticationService.userHasMultipleOrgs()) {
        this.view = 'SELECT_ORG'
      } else {
        this.authenticationService.redirectAfterLogin(relayState)
      }
    } catch (e) {
      const error = e?.error?.error as AuthError

      const paneErrors = ['LOCKOUT', 'NO_ORG', 'INACTIVE', 'STALE'] as AuthError[]
      const mfaErrors = ['MFA_CODE_REQUIRED', 'INVALID_MFA_CODE']

      if (paneErrors.includes(error)) {
        this.errorPane = error
      } else if (mfaErrors.includes(error)) {
        if (this.view === 'MFA') {
          this.loginError = e?.error?.message
        } else {
          this.view = 'MFA'
        }
      } else {
        const message = e?.error?.message
        if (message) {
          this.loginError = message
        } else {
          this.loginError = UNKNOWN_LOGIN_ERROR_MESSAGE
        }
      }
    }
    this.isAuthenticating = false
  }
}
