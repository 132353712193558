import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  ObserveSummaryList,
  ObserveUrlGroup,
  ScreenLabelRecord,
  ObserveImageLink,
  ObserveMissConditions,
  ObserveRecordFields,
  ObserveConfigTypes,
} from 'generated/graphql'
import { Observable } from 'rxjs'
import {
  ObserveConfigurations,
  UrlGroupsList,
  CreateObserveConfig,
  ObserveImageUrls,
  ObserveRecord,
} from 'src/app/admin/observe-configurations/service/org-config.gql'

@Injectable({
  providedIn: 'root',
})
export class ObserveConfigService {
  constructor(private apollo: Apollo) {}

  getObserveConfigurations(orgId: string): Observable<ApolloQueryResult<{ observeSummaryList: ObserveSummaryList }>> {
    return this.apollo.watchQuery<{ observeSummaryList: ObserveSummaryList }>({
      query: ObserveConfigurations,
      variables: {
        orgId: orgId,
      },
    }).valueChanges
  }

  getUrlGroups(
    orgId: string,
    url: string,
    missReason: ObserveMissConditions,
  ): Observable<ApolloQueryResult<{ urlGroupsList: ObserveUrlGroup[] }>> {
    return this.apollo.watchQuery<{ urlGroupsList: ObserveUrlGroup[] }>({
      query: UrlGroupsList,
      variables: {
        orgId: orgId,
        url: url,
        missReason,
      },
    }).valueChanges
  }

  async createObserveConfig(params: ScreenLabelParams): Promise<ScreenLabelRecord> {
    let result = await this.apollo
      .mutate<{ createObserveConfig: ScreenLabelRecord }>({
        mutation: CreateObserveConfig,
        variables: {
          regex: params.regexValue,
          screenLabel: params.screenLabel,
          url: params.url,
          fieldName: params.fieldName,
          type: params.configType,
        },
      })
      .toPromise()
    return result?.data?.createObserveConfig
  }

  getImageUrls(
    orgId: string,
    url: string,
    offset: number,
  ): Observable<ApolloQueryResult<{ observeImageUrls: ObserveImageLink[] }>> {
    return this.apollo.watchQuery<{ observeImageUrls: ObserveImageLink[] }>({
      query: ObserveImageUrls,
      variables: {
        rawUrl: url,
        orgId: orgId,
        offset,
      },
    }).valueChanges
  }

  getObserveRecord(
    rawUrl: string,
    orgId: string,
    imageId: string,
  ): Observable<ApolloQueryResult<{ observeRecordFields: ObserveRecordFields }>> {
    return this.apollo.watchQuery<{ observeRecordFields: ObserveRecordFields }>({
      query: ObserveRecord,
      variables: {
        rawUrl,
        orgId,
        imageFileId: imageId,
      },
    }).valueChanges
  }
}

export type ScreenLabelParams = {
  regexValue: string
  screenLabel: string
  url: string
  fieldName: string
  configType: ObserveConfigTypes
}

export type reasonsWithLabel = {
  label: string
  condition: ObserveMissConditions
}

export enum ConfigViewModes {
  CONFIG = 'config',
  ANNONATION_BUILDER = 'annonationBuilder',
  URL_GROUPS = 'urlGroups',
}
