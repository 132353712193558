<div class="full-height d-flex flex-column">
  <!-- toolbar -->
  <div class="container-fluid d-flex flex-column flex-grow-1">
    <div class="bg-brand-gray row d-flex flex-row">
      <div class="flex-grow-1 my-auto py-4 px-4 d-flex flex-row">
        <div class="flex-grow-1 d-flex flex-row justify-content-between">
          <!-- left hand filters-->
          <div class="flex-grow-1 d-flex flex-row justify-content-start">
            <!-- query selection -->
            <div class="mr-4">
              <div class="dropdown" ngbDropdown #dropdown="ngbDropdown" placement="bottom-left">
                <button
                  class="btn btn-light dropdown-toggle border border-alt-light-gray text-alt-medium-gray"
                  type="button"
                  id="dropdownMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ngbDropdownAnchor
                  (focus)="dropdown.open()"
                >
                  <small *ngIf="(selectedQuery$ | async)?.isSuperuserOnly" class="text-brand-light-blue"
                    ><app-janus-icon icon="janus"></app-janus-icon
                  ></small>
                  {{ (selectedQuery$ | async)?.name || 'Select Query' | startCase }}
                </button>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ng-template ngFor let-query [ngForOf]="queries$ | async">
                    <button
                      type="button"
                      ngbDropdownItem
                      class="dropdown-item text-alt-medium-gray"
                      [ngClass]="{ 'bg-alt-light-gray': query === (selectedQuery$ | async) }"
                      (click)="selectQuery(query)"
                      *ngIf="!query?.isSuperuserOnly || authenticationService.isSuper()"
                    >
                      <small *ngIf="query?.isSuperuserOnly" class="text-brand-light-blue"
                        ><app-janus-icon icon="janus"></app-janus-icon
                      ></small>
                      {{ query?.name | startCase }}
                    </button>
                  </ng-template>
                </div>
              </div>
            </div>
            <!-- org typeahead -->
            <div
              class="mr-4"
              *ngIf="(selectedQuery$ | async)?.args.includes(QueryArg.OrgId) && authenticationService.isSuper()"
            >
              <app-typeahead-select
                placeholder="Select Org"
                [searchByTerm]="searchOrgs"
                [resultFormatter]="formatOrgSearchResult"
                (selectValue)="selectOrg($event)"
                [selectedItem]="selectedOrg$ | async"
              ></app-typeahead-select>
            </div>
            <!-- datepicker -->
            <div class="mr-4" *ngIf="(selectedQuery$ | async)?.args.includes(QueryArg.Date)">
              <input
                id="date"
                class="form-control border-alt-light-gray text-alt-medium-gray"
                name="date"
                type="date"
                [ngModel]="args.get(QueryArg.Date) | async"
                (ngModelChange)="args.get(QueryArg.Date).next($event)"
              />
            </div>
            <!-- date range picker -->
            <div
              class="mr-4"
              *ngIf="
                (selectedQuery$ | async)?.args.includes(QueryArg.StartDate) ||
                (selectedQuery$ | async)?.args.includes(QueryArg.EndDate)
              "
            >
              <vx-date-range-picker
                class="mb-3"
                [startDate]="args.get(QueryArg.StartDate) | async"
                [endDate]="args.get(QueryArg.EndDate) | async"
                (dateChange)="selectDateRange($event)"
                [includeOpenEndedPresets]="false"
              ></vx-date-range-picker>
            </div>
          </div>

          <!-- right hand actions-->
          <div>
            <button
              class="btn btn-light border border-alt-light-gray text-alt-medium-gray mr-3"
              *ngIf="selectedQuery$ | async"
              [disabled]="isExecutingQuery$ | async"
              (click)="repeatQueryClickEvents$.next($event)"
            >
              Re-execute Query
            </button>
            <button
              class="btn btn-light border border-alt-light-gray text-alt-medium-gray"
              *ngIf="selectedQuery$ | async"
              [disabled]="(isExecutingQuery$ | async) || !(resultData$ | async)?.length"
              (click)="exportCsv()"
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- messages -->
    <ng-container *ngIf="(resultData$ | async) || [] as resultData">
      <div class="row d-flex flex-grow-1" *ngIf="!resultData?.length || (isMissingArg$ | async)">
        <div class="col d-flex align-items-center justify-content-center">
          <div *ngIf="!(selectedQuery$ | async)">Select a query to get started</div>
          <div *ngIf="isExecutingQuery$ | async">Executing query <app-loading></app-loading></div>
          <div *ngIf="isMissingArg$ | async">Select values for all parameters above to execute query</div>
          <div *ngIf="(resultRowCount$ | async) === 0">Query returned no results</div>
          <div *ngIf="queryError$ | async">
            <div class="d-flex flex-row align-items-center">
              <i class="fa fa-2x fa-exclamation-circle text-danger mr-3"></i>
              <span>Error executing query</span>
            </div>
          </div>
        </div>
      </div>

      <!-- results -->
      <div class="row flex-grow-1 position-relative bg-brand-gray" *ngIf="resultData?.length">
        <div class="position-absolute top-0 left-0 right-0 bottom-0 overflow-auto">
          <div class="col">
            <table class="w-100">
              <tr>
                <th
                  *ngFor="let columnName of resultDataColumns$ | async"
                  class="bg-white p-3 border-bottom border-brand-gray"
                >
                  <strong>{{ columnName | startCase }}</strong>
                </th>
              </tr>
              <tr
                *ngFor="
                  let row of currentRowsDisplayed != null ? resultData.slice(0, currentRowsDisplayed) : resultData
                "
                class="bg-white"
              >
                <td *ngFor="let columnName of resultDataColumns$ | async" class="p-3 border-bottom border-brand-gray">
                  <i class="fa fa-check-square-o" *ngIf="row[columnName] === true"></i>
                  <i class="fa fa-square-o" *ngIf="row[columnName] === false"></i>
                  <span *ngIf="row[columnName] !== true && row[columnName] !== false">{{ row[columnName] }}</span>
                </td>
              </tr>
              <tr
                *ngIf="currentRowsDisplayed != null && resultData.length > currentRowsDisplayed"
                class="bg-white no-hover"
              >
                <td colspan="100" class="text-center text-alt-medium-gray p-3 border-bottom border-brand-gray">
                  <small>
                    <i class="fa fa-warning text-warning"></i> Only showing first {{ currentRowsDisplayed }} rows!
                    Export to CSV to see all rows, or
                    <button class="font-bold py-1 px-2 rounded" (click)="expandDisplayedResults()">
                      click here to show another {{ rowDisplayLimit }} rows
                    </button>
                  </small>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- bottom toolbar -->
      <div class="row bg-brand-gray" *ngIf="resultData?.length">
        <div class="col d-flex flex-row justify-content-between p-4">
          <!-- left hand info-->
          <div class="inline">
            <small>
              Query executed in
              <span [ngClass]="{ 'text-danger': (resultExecutionMs$ | async) > 500 }">{{
                resultExecutionMs$ | async
              }}</span>
              ms.
            </small>
            <small *ngIf="currentRowsDisplayed == null || resultData?.length <= currentRowsDisplayed"
              >Returned {{ resultRowCount$ | async }}
              <span [ngPlural]="resultRowCount$ | async"
                ><ng-template ngPluralCase="=1">row</ng-template
                ><ng-template ngPluralCase="other">rows</ng-template></span
              >.
            </small>
            <span *ngIf="currentRowsDisplayed != null && resultData?.length > currentRowsDisplayed">
              <small> Showing {{ currentRowsDisplayed }} rows of {{ resultRowCount$ | async }}. </small>
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
