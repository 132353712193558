import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { PayerPlanPipe } from 'app/shared/pipes/payer-plan.pipe'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { PayerPlan } from 'generated/graphql'
import { defer } from 'lodash'
import { Observable, Subject, from, merge, of } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'

/**
 * Component to allow searching for and selecting a plan using the PayerPlan model.
 */
@Component({
  selector: 'app-payerplan-plan-typeahead',
  templateUrl: './payerplan-plan-typeahead.component.html',
  styleUrls: ['./payerplan-plan-typeahead.component.scss'],
})
export class PayerPlanPlanTypeaheadComponent {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  private _payerName: string = null
  @Input() set payerName(value: string) {
    if ((this._payerName || '') === value?.trim()) return
    if (this._payerName !== value) this._payerName = value?.trim()
    this.clearPlan()
  }

  get payerName(): string {
    return this._payerName
  }

  lastQueryPayer = ''
  lastQueryResult: PayerPlan[]
  selectedPlan: PayerPlan

  @Input() disabledPlanIds: string[] = []
  @Input() disabled = false
  @Output() onSelect = new EventEmitter<PayerPlan>()
  @Output() onAdd = new EventEmitter<PayerPlan>()

  searching: boolean = false

  constructor(
    private payerPlanService: PayerPlanService,
    private toastService: ToastService,
    private payerPlanPipe: PayerPlanPipe,
  ) {}

  /** Get plans based on search term in typeahead */
  query = (text$: Observable<string>): Observable<PayerPlan[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((plan) => {
        if (this.lastQueryPayer !== this.payerName) {
          return from(this.payerPlanService.getPlansByPayer({ payerName: this.payerName })).pipe(
            catchError((err) => {
              this.toastService.error(parseGraphQLError(err, 'Could not query plans.'), JSON.stringify(err))
              return of([] as PayerPlan[])
            }),
            map((plans) => {
              this.lastQueryResult = plans
              this.lastQueryPayer = this._payerName
              return this.filterPlans(this.lastQueryResult)
            }),
          )
        } else {
          return of(this.filterPlans(this.lastQueryResult))
        }
      }),
      tap(() => (this.searching = false)),
    )
  }

  /** Filters and orders the list of plans, returned from cache or the server, with the search query. */
  filterPlans(plans: PayerPlan[]): PayerPlan[] {
    const value = (this.element.nativeElement.value || '').toLowerCase().trim()
    const resultPlans = plans.filter(
      (plan) =>
        !this.disabledPlanIds.includes(plan.id) &&
        (value ? (plan.planName || '').toLowerCase().indexOf(value.toLowerCase()) > -1 : true),
    )
    const exactMatch = resultPlans.filter((payer) => (payer.planName ?? '').toLowerCase().trim() === value)
    const otherMatches = resultPlans.filter((payer) => (payer.planName ?? '').toLowerCase().trim() !== value)
    const result: PayerPlan[] = [...exactMatch, ...otherMatches].map((p) => ({ ...p, planName: p.planName ?? '' }))

    const emptyIdx = result.findIndex((p) => !p.payerCode && !p.planName)
    if (emptyIdx > -1) result.unshift({ ...result.splice(emptyIdx, 1)[0] })
    return result
  }

  /** Format Plan typeahead value in input. */
  inputFormatter = (x: PayerPlan): string => this.payerPlanPipe.transform(x, true)

  /** Format Plan typeahead results list. */
  resultFormatter = (x: PayerPlan): string => this.payerPlanPipe.transform(x, true)

  /**
   * Notify consumers that a plan has been selected
   * @emits PayerPlan
   */
  onSelectPlan(event: { item: PayerPlan }): void {
    this.selectedPlan = event.item
    debug('payerplan plans', 'selected a plan', this.selectedPlan)
    this.onSelect.emit(this.selectedPlan)
  }

  /**
   * Notify consumers that a plan has been added
   * @emits PayerPlan
   */
  onAddPlan(event: Event): void {
    debug('payerplan plans', 'add a plan', this.selectedPlan)
    this.onAdd.emit(this.selectedPlan)
    defer(() => {
      this.clearPlan(event)
      this.element.nativeElement.focus()
    })
  }

  /**
   * Clear typeahead input
   * @emits undefined
   */
  clearPlan(event?: Event): void {
    if (event) event.preventDefault()

    this.selectedPlan = undefined
    if (this.element) this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
