<div class="row full-height">
  <div class="col-lg-3 scrolling-sidebar left-column">
    <app-workflow-search
      showRight="Assign"
      [hiddenWorkflows]="workflows"
      (selectWorkflow)="addSelectedWorkflow($event)"
    ></app-workflow-search>
  </div>
  <div class="col-lg-9 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1 overflow-hidden">
        <!-- selected workflows -->
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body flex-basis-lg-0">
              <h3 class="text-center">Workflows</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <ng-template ngFor let-wf [ngForOf]="workflows" let-i="index">
                  <app-workflow-card
                    [workflow]="wf"
                    showRemove="Remove"
                    [isCollapsed]="true"
                    (clickRemove)="removeSelectedWorkflow($event)"
                  ></app-workflow-card>
                </ng-template>
              </div>
            </div>
          </div>
          <!-- task group-->
          <div class="card mt-5">
            <div class="card-body">
              <h3 class="text-center">Task Group</h3>
              <hr class="w-75 mx-auto" />
              <app-task-group-typeahead (onSelect)="selectedTaskGroup($event)"></app-task-group-typeahead>
            </div>
          </div>
        </div>
        <!-- claims -->
        <div class="col-lg-6 d-flex flex-column flex-grow-1 mt-5 mt-lg-0">
          <div class="card flex-grow-1">
            <div class="card-body scrolling-main flex-basis-lg-0 d-flex flex-column">
              <h3 class="text-center">Claims</h3>
              <hr class="w-75 mx-auto" />
              <textarea
                [formControl]="claimIds"
                class="form-control flex-grow-1"
                cols="20"
                rows="6"
                data-t="claim-id-input"
              ></textarea>
              <small class="form-text text-muted"> Enter Claim IDs separated by commas, tabs, or one per line </small>
              <hr class="w-75 mx-auto" />
              <h4 class="text-center">Parsed {{claims?.length | number}} Claims</h4>
            </div>
          </div>
        </div>
      </div>
      <!-- buttons -->
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="assign-workflows-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-green px-5"
            data-t="assign-workflows-button"
            (click)="save()"
            [disabled]="workflows.length < 1 || claims.length < 1 || !taskGroupId"
          >
            Assign
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
