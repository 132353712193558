<ng-template #tooltip>
  <h6>Score Factors:</h6>
  <div class="d-flex justify-content-between">
    <div class="flex-grow-1 mr-2">Average Claim Amount:</div>
    <div>{{ stats?.averageClaimAmount | currency }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="flex-grow-1 mr-2">Predicted Value per Claim:</div>
    <div>{{ stats?.remainingPredictedAmount / stats?.remainingClaims | currency }}</div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="flex-grow-1 mr-2">Predicted Effort per Claim:</div>
    <div>{{ taskGroup?.predictedEffort | duration }}</div>
  </div>
</ng-template>
<div class="score-circle mt-2" placement="left" [ngbTooltip]="tooltip" container="body">
  {{ taskGroup?.score }}
</div>
