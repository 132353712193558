import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  transform(value: any): string {
    let seconds = parseInt(value)
    if (!seconds) return ''
    let hours = Math.floor(seconds / 3600)
    seconds -= hours * 3600
    let minutes = Math.floor(seconds / 60)
    seconds -= minutes * 60
    let segments = [`${seconds}s`]
    if (minutes) {
      segments.unshift(`${minutes}m`)
    }
    if (hours) {
      segments.unshift(`${hours}h`)
    }
    return segments.join(' ')
  }
}
