import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet, Selected } from 'app/shared/types/facet'
import { Subscription } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

/**
 * Component to display a radio button facet for claim properties
 *
 * @export
 * @class RadioFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet
  @ViewChildren('input') inputs: QueryList<ElementRef>

  isCollapsed = false

  form = new UntypedFormGroup({
    radios: new UntypedFormControl(''),
  })

  radios$: Subscription
  facets$: Subscription

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.radios$ = this.form.valueChanges
      .pipe(
        tap((form) => {
          let value = form.radios
          let selected = this.data?.options?.find((selected: Selected) => selected.value === value)
          this.facetService.addToFacet(this.data.id, selected)
        }),
      )
      .subscribe()

    this.facets$ = this.facetService.facets$
      .pipe(
        filter((facets) => facets.find((f) => f.id === this.data.id) !== undefined),
        tap((facets: Facet[]) => {
          let selected = facets.find((f) => f.id === this.data.id).selected
          if (selected.length === 0) {
            this.form.get('radios').setValue('', { emitEvent: false })
          }
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.radios$.unsubscribe()
    this.facets$.unsubscribe()
  }

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.inputs.first?.nativeElement?.focus()
    }
  }

  /**
   * Remove this facet
   *
   * @memberof RadioFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
