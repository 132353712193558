import { Injectable } from '@angular/core'
import {
  ListResponseMetaData,
  QueryPayerPlanGroupNamesArgs,
  PayerPlanGroupNameList,
  PayerPlanGroupOptions,
  PayerPlanGroup,
  QueryGroupByNameArgs,
  MutationUpdateGroupNameArgs,
  MutationDeleteGroupArgs,
  UpdateGroupNameDocument,
  DeleteGroupDocument,
  PayerPlanGroupByNameDocument,
  MutationCreateGroupArgs,
  CreateGroupDocument,
} from 'generated/graphql'
import { Apollo } from 'apollo-angular'
import { from, Observable } from 'rxjs'
import { concatMap, map, tap } from 'rxjs/operators'
import { PayerPlanGroupNamesDocument } from 'generated/graphql'

@Injectable({
  providedIn: 'root',
})
export class PayerPlanGroupService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo) {}

  getPayerPlanGroupNames(
    offset: number,
    limit: number,
    options?: PayerPlanGroupOptions,
  ): Observable<PayerPlanGroupNameList> {
    return this.apollo
      .query<{ payerPlanGroupNames: PayerPlanGroupNameList }, QueryPayerPlanGroupNamesArgs>({
        query: PayerPlanGroupNamesDocument,
        variables: {
          offset,
          limit,
          options,
        },
      })
      .pipe(
        tap((result) => {
          this.meta = result?.data?.payerPlanGroupNames?.meta
        }),
        map((result) => result.data?.payerPlanGroupNames),
      )
  }

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof PayerPlanGroupService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  getGroupByName(name: string): Observable<PayerPlanGroup> {
    return this.apollo
      .query<{ groupByName: PayerPlanGroup }, QueryGroupByNameArgs>({
        query: PayerPlanGroupByNameDocument,
        variables: {
          name,
        },
      })
      .pipe(map((result) => result.data?.groupByName))
  }

  updateGroupName(id: string, newName: string, user: string): Observable<PayerPlanGroup> {
    return this.apollo
      .mutate<{ updateGroupName: PayerPlanGroup }, MutationUpdateGroupNameArgs>({
        mutation: UpdateGroupNameDocument,
        variables: {
          id, newName, user
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.updateGroupName))
  }

  deleteGroup(id: string, user: string): Observable<number> {
    return this.apollo
      .mutate<{ deleteGroup: number }, MutationDeleteGroupArgs>({
        mutation: DeleteGroupDocument,
        variables: {
          id, user
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.deleteGroup))
  }

  createGroup(name: string, user: string): Observable<PayerPlanGroup> {
    return this.apollo
      .mutate<{ createGroup: PayerPlanGroup }, MutationCreateGroupArgs>({
        mutation: CreateGroupDocument,
        variables: {
          name, user
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.createGroup))
  }
}
