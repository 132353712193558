import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { ObserveUsageSummary as ObserveUsageSummaryQuery} from 'app/admin/observe-access-controls/observe-usage.gql'
import { ObserveUsageSummary, ObserveUsageSummaryList, QueryObserveUsageSummaryArgs } from 'generated/graphql'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ObserveUsageService {
  constructor(private apollo: Apollo) {}

  getObserveSummaryList(
    variables: QueryObserveUsageSummaryArgs,
  ): Observable<ApolloQueryResult<{ observeUsageSummary: ObserveUsageSummaryList }>> {
    return this.apollo.watchQuery<{ observeUsageSummary: ObserveUsageSummaryList }, QueryObserveUsageSummaryArgs>({
      query: ObserveUsageSummaryQuery,
      variables,
    }).valueChanges
  }

  sortSummaries(
    observeUsageSummaries: ObserveUsageSummary[],
    column: string,
    direction?: string,
  ): ObserveUsageSummary[] {
    let sortedSummaries = [...observeUsageSummaries]
    if (!direction) {
      sortedSummaries = sortedSummaries.sort((a, b) => b.count - a.count)
    } else if (column === 'domain') {
      switch (direction) {
        case 'asc':
          sortedSummaries = sortedSummaries.sort((a, b) => {
            if (a.applicationOrUrl > b.applicationOrUrl) {
              return 1
            }
            if (a.applicationOrUrl < b.applicationOrUrl) {
              return -1
            }
            return 0
          })
          return sortedSummaries
        case 'desc':
          sortedSummaries = sortedSummaries.sort((a, b) => {
            if (a.applicationOrUrl < b.applicationOrUrl) {
              return 1
            }
            if (a.applicationOrUrl > b.applicationOrUrl) {
              return -1
            }
            return 0
          })
          return sortedSummaries
      }
    } else if (column === 'hits') {
      if (direction === 'desc') {
        sortedSummaries = sortedSummaries.sort((a, b) => a.count - b.count)
      } else {
        sortedSummaries = sortedSummaries.sort((a, b) => b.count - a.count)
      }
    } else if (column === 'status') {
      sortedSummaries =
        direction === 'desc'
          ? sortedSummaries.sort((a) => (a.allowed ? 1 : -1))
          : sortedSummaries.sort((a) => (a.allowed ? -1 : 1))
    }
    return sortedSummaries
  }
}
