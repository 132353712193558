import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { AppRoute } from 'app/app-routing.module'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { AuthenticationService } from 'app/auth/authentication.service'
import { AuthorizationService } from 'app/auth/authorization.service'
import { debug } from 'app/shared/utils/debug'
import { ToastService } from '../services/toast.service'

/**
 * Angular route guard that requires one or more permissions to be granted for the user.
 *
 * This guard works with permissions and/or legacy roles.
 *
 * @export
 * @function PermissionGuard
 */
export const PermissionGuard: CanActivateFn | CanActivateChildFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => validatePermissionOnRoute(next)

export const validatePermissionOnRoute = async (
  next: ActivatedRouteSnapshot,
  displayToastOnRestricted: boolean = true,
): Promise<boolean | UrlTree> => {
  const authenticationService = inject(AuthenticationService)
  const authorizationService = inject(AuthorizationService)
  const router = inject(Router)
  const toast = inject(ToastService)

  // Roles will be checked as a fallback.
  const { roles, permissions, permissionType } = next.data as AppRoute['data']

  if (!roles && !permissions) {
    // Likely this route is a child of the guarded route. Or permissions are missing.
    return true
  }

  debug(
    'authorization',
    'permission guard got',
    JSON.stringify({
      permissions,
      permissionType,
      roles,
    }),
  )

  try {
    const hasPermission = await authorizationService.hasPermissionsOrRoles({
      permissions,
      permissionType,
      roles,
    })

    if (!hasPermission) {
      if (displayToastOnRestricted) {
        toast.error('Restricted')
      }
      debug(
        'authorization',
        'permission guard error',
        JSON.stringify({ roles: roles, userRole: authenticationService.getUser()?.role }),
      )
      return router.parseUrl(DEFAULT_HOME_PATH)
    }

    return true
  } catch (e) {
    debug('authorization', 'permission guard error')
    return router.parseUrl(DEFAULT_HOME_PATH)
  }
}
