<h5 class="mb-3">Service Lines</h5>
<div class="card bg-light mb-5">
  <div class="card-body">
    <div class="table-responsive mb-5" *ngFor="let s of servicelines">
      <table class="table table-sm table-details small text-left">
        <thead>
          <tr>
            <th scope="col">{{ getPropDetails('servicelines.cpt')?.label }}</th>
            <th scope="col">{{ getPropDetails('servicelines.dateOfServiceStart')?.label }}</th>
            <th scope="col">{{ getPropDetails('servicelines.dateOfServiceEnd')?.label }}</th>
            <th scope="col">{{ getPropDetails('servicelines.chargeAmount')?.label }}</th>
            <th scope="col">{{ getPropDetails('servicelines.modifier')?.label }}</th>
            <th scope="col">{{ getPropDetails('servicelines.diagnosis')?.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ s?.cpt }}</td>
            <td>{{ s?.dateOfServiceStart ? (s.dateOfServiceStart | date: 'MM/dd/yyyy') : '' }}</td>
            <td>{{ s?.dateOfServiceEnd ? (s.dateOfServiceEnd | date: 'MM/dd/yyyy') : '' }}</td>
            <td>{{ s?.chargeAmount | currency }}</td>
            <td>{{ s?.modifier }}</td>
            <td>{{ s?.diagnosis }}</td>
          </tr>
          <tr *ngIf="hasCodes">
            <th>Type</th>
            <th>Code</th>
            <th colspan="4" class="text-left">Description</th>
          </tr>
          <tr *ngFor="let code of s?.payerStatusCodes">
            <td>{{ getPropDetails('servicelines.payerStatusCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let code of s?.ehrStatusCodes">
            <td>{{ getPropDetails('servicelines.ehrStatusCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let code of s?.categoryCodes">
            <td>{{ getPropDetails('servicelines.categoryCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let code of s?.claimStatusCodes">
            <td>{{ getPropDetails('servicelines.claimStatusCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let code of s?.claimAdjustmentReasonCodes">
            <td>{{ getPropDetails('servicelines.claimAdjustmentReasonCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
          <tr *ngFor="let code of s?.remitAdviceRemarkCodes">
            <td>{{ getPropDetails('servicelines.remitAdviceRemarkCodes.code')?.heading }}</td>
            <td data-t="payer-status-code">{{ code?.code }}</td>
            <td colspan="4" class="text-left mw-100p" [ngbTooltip]="code?.description" container="body">
              <div class="text-truncate">
                {{ code?.description }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
