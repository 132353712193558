<div class="container align-items-start p-5 bg-alt-white">
  <h4 class="text-center">TERMS OF USE</h4>
  <p class="my-5">Effective Date: June 1, 2021</p>
  <p>
    Janus Health Technologies, Inc. and its affiliates (“Janus”, “we”, “our” or “us”) own and operate (a) the Janus
    website and its domain and sub-domains located at <u><a href="http://www.janus-ai.com/">www.janus-ai.com</a></u>
    (the “Site”) and (b) the Janus revenue cycle management software platform (the “Platform”) which provides a solution
    to its business clients (“Customers”) in accordance with Customer’s applicable master software agreement. When we
    refer to the “Janus Service” throughout this Terms of Use, we mean the Site, Platform, and any related services
    provided by us through or in connection with any of the foregoing.
  </p>
  <p>
    These Terms of Use, along with our <u><a routerLink="/policies/privacy-policy">Privacy Policy</a></u> apply to all
    visits and use of the Site, as well as to the content, information, recommendations and information provided to you
    on or through the Site. By accessing and using the Site, you signify your consent to these Terms of Use in their
    entirety. If you do not agree to be bound by these Terms of Use in their entirety, please leave the Site.
  </p>
  <p>
    PLEASE READ THESE TERMS OF USE AND ALL OTHER TERMS AND POLICIES REFERENCED HEREIN CAREFULLY AS THEY CONTAIN
    IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.
  </p>
  <p>
    NOTICE OF AGREEMENT TO ARBITRATE: THESE TERMS OF USE CONTAIN AN AGREEMENT TO ARBITRATE WHICH WILL REQUIRE YOU TO
    SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION AND A WAIVER OF YOUR RIGHT TO PARTICIPATE IN
    CLASS ACTIONS AS SET FORTH IN, AND SUBJECT TO, SECTION 13.
  </p>
  <p>
    <b class="pr-4">1. Use of Site.</b> The information contained within this Site is offered solely for your
    consideration and is subject to your verification. It is not to be taken as a warranty or representation for which
    Janus assumes legal responsibility, nor does Janus grant permission to use or practice anything contained therein,
    nor recommends such use or practice.
  </p>
  <p class="mb-4">
    This Site is an online information service offered by Janus providing information about Janus goods and services and
    may contain information related to healthcare industry. This Site is not intended to be a used for professional
    medical advice. Do not use information from this Site to diagnose or treat a health condition or disease without
    consulting a qualified health care provider. There is no physician-patient relationship between you and Janus
    arising solely by virtue of the Site. Never disregard professional medical advice or delay seeking medical attention
    because of something you have read and experienced on the Site. Do not use the Site for medical emergency
    services<span class="text-uppercase"
      >. In an emergency, call 911, your personal physician and/or your local emergency assistance number.</span
    >
  </p>
  <p class="mb-4">
    <b class="pr-4">2. Minimum Age Requirement.</b> You must be at least 18 years old or the age of majority in your
    jurisdiction in order to access and use the Janus Services. This Site is not intended for users under the age of
    majority and does not knowingly collect personally identifiable information from such. By accessing the Site
    expressly prohibited from submitting their personally identifiable information to this Site, and from using portions
    of the Site for which registration is required.
  </p>
  <p>
    <b class="pr-4">3. Ownership of Site and Content.</b>
    The Site, and the information which it contains, is the property of Janus and its licensors, and is protected from
    unauthorized copying and dissemination by United States copyright law, trademark law, international conventions and
    other intellectual property laws.
  </p>
  <p class="mb-4">
    The contents of this Site may be viewed and accessed only for your personal, non-commercial use, provided that all
    copyright and other proprietary notices are retained on any copies made of the materials. The materials at this Site
    may not be modified in any way, and may not be reproduced, distributed or used for any public or commercial purpose
    unless expressly permitted by Janus in writing. Any use of the materials on this Site for any other purpose is
    prohibited. Nothing herein shall be construed as conferring by implication, estoppel, or otherwise, any license or
    right under any copyright, patent, trademark or other proprietary interest of Janus, or third parties. If you breach
    any of these terms, your authorization to use this Site is terminated and you must immediately destroy any
    downloaded or printed materials.
  </p>
  <p class="mb-4">
    <b class="pr-4">4. Trademark.</b> All trademarks, service marks and trade names of (“Janus Trademarks”) are
    trademarks or registered trademarks of Janus, unless stated otherwise. You may not use or display Janus Trademarks
    for any purpose, including but not limited to, in advertising or publicity pertaining to distribution of materials
    on this Site, without Janus’s prior written consent. Any other trademarks appearing on third party pages linked to
    by this site are the property of the respective owners and operators of those sites.
  </p>
  <p class="mb-4">
    <b class="pr-4">5. Links to Other Websites.</b> If there are links to third-party websites on this Site, they are
    provided solely as a convenience. If you use these links, you will leave this Site. Janus does not claim to have
    reviewed these third-party websites in their entirety, nor does it control these websites. Janus is not responsible
    for any of these websites or their content. Janus is not responsible for any charges or fees associated with
    financial transactions that may occur on or through a third-party website. Moreover, Janus does not endorse or make
    any representations about any of the websites for which links are provided, or any information, software or other
    products or materials found there, or any results that may be obtained from using them. If you decide to access any
    of the third-party websites linked to this Site, you do so entirely at your own risk. The mention of specific
    products, processes, or services on this Site does not constitute or imply a recommendation or endorsement by Janus
    under any circumstances.The storage or reproduction of (a part of) the Site in any external internet site or the
    creation of links, hypertext, or deeplinks between this Site and any other internet website, is prohibited without
    the express written consent of Janus.
  </p>
  <p>
    <b class="pr-4">6. Consumer Laws.</b> Despite any other provision of these Terms of Use, if any legislation affects
    your rights under these Terms of Use (“Consumer Law”), then to the extent that any term or provision contained in
    these Terms of Use:
  </p>
  <p>(A)becomes void or unenforceable for any reason;</p>
  <p>or</p>
  <p>
    (B)would be unfair under the applicable consumer law if applied or relied upon in a particular way, that term or
    provision will be severed such that all remaining terms and provisions of these Terms of Use will continue to be in
    full force and effect and be unaffected by the severance of any other term or provision.
  </p>
  <p class="mb-4">
    No provision of these Terms of Use limits, excludes or modifies any right or remedy, or any guarantee, warranty or
    other term or condition implied or imposed by any Consumer Law which cannot lawfully be excluded, limited or
    modified.
  </p>
  <p>
    <b class="pr-4">7. Disclaimer.</b>
    YOU AGREE TO ASSUME THE RISK OF ACCESSING AND USING THIS SITE. THE SITE AND THE CONTENT ARE PROVIDED “AS IS” AND “AS
    AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, WARRANTIES
    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY, TO THE FULLEST
    EXTENT PERMITTED BY LAW. THE INFORMATION ON THE SITE IS FOR GENERAL INFORMATION PURPOSES ONLY AND DOES NOT
    CONSTITUTE ADVICE.
  </p>
  <p>
    JANUS DOES NOT REPRESENT OR WARRANT THAT THE INFORMATION CONTAINED IN THE SITE IS ACCURATE, COMPLETE OR CURRENT, OR
    THAT THE SITE WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT ANY DEFECTS IN THE SITE WILL BE CORRECTED OR THAT THE
    SITE OR THE SERVER THAT MAKES THE SITE AVAILABLE ARE FREE OF VIRUSES OR ANY OTHER HARMFUL COMPONENTS. JANUS DOES NOT
    MAKE ANY WARRANTEES OR REPRESENTATIONS REGARDING THE USE OF THE MATERIALS IN THE SITE IN TERMS OF THEIR CORRECTNESS,
    ACCURACY, ADEQUACY, USEFULNESS, TIMELINESS, RELIABILITY OR OTHERWISE, IN EACH CASE TO THE FULLEST EXTENT PERMITTED
    BY APPLICABLE LAW. INFORMATION PUBLISHED AT THIS SITE MAY REFER TO PRODUCTS, PROGRAMS OR SERVICES THAT ARE NOT
    AVAILABLE IN YOUR REGION.
  </p>
  <p>
    YOU UNDERSTAND AND ACKNOWLEDGE THAT YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR DISSATISFACTION
    WITH THE SITE IS TO CEASE USE OF THE SITE.
  </p>
  <p class="mb-4">
    JANUS RESERVES THE RIGHT TO SUSPEND OR WITHDRAW THE WHOLE OR ANY PART OF THE SITE AT ANY TIME WITHOUT NOTICE WITHOUT
    INCURRING ANY LIABILITY.
  </p>
  <p>
    <b class="pr-4">8. Limitation of Liability.</b> IN NO EVENT WILL JANUS, ITS AFFILIATES, OFFICERS, DIRECTORS, AGENTS,
    ANY PARTY INVOLVED IN CREATING, PRODUCING OR DELIVERING THE SITE, OR ANY OTHER THIRD PARTIES MENTIONED AT THIS SITE
    BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER (INCLUDING,
    WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE,
    INABILITY TO USE, OR THE RESULTS OF USE OF THIS SITE, ANY WEBSITES LINKED TO THIS SITE, OR THE MATERIALS OR
    INFORMATION CONTAINED AT ANY OR ALL SUCH WEBSITES, INCLUDING DAMAGES CAUSED BY VIRUSES OR ANY INCORRECTNESS OR
    INCOMPLETENESS OF THE INFORMATION ON THE SITE, OR THE PERFORMANCE OF THE PRODUCTS OR SERVICES, WHETHER BASED ON
    WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
  </p>
  <p class="mb-4">
    NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL JANUS’S LIABILITY TO YOU RELATING TO THE SITE EXCEED ONE HUNDRED
    DOLLARS ($100).
  </p>
  <p>
    <b class="pr-4">9. User Submissions.</b> Any information, including but not limited to remarks, suggestions, ideas,
    graphics, or other submissions, communicated to Janus through this Site is the exclusive property of Janus. Janus is
    entitled to use any information submitted for any purpose, without restriction (except as stated in Janus’s Privacy
    Policy) or compensation to the person sending the submission. The user acknowledges the originality of any
    submission communicated to Janus and accepts responsibility for its accuracy, appropriateness, and legality.
  </p>
  <p class="mb-4">
    You are prohibited from using the Site to post or transmit any unlawful, threatening, libelous, defamatory, obscene,
    pornographic, illegal, or any material that could constitute or encourage conduct that would be considered a
    criminal offence, violate the rights of any party or which may otherwise give rise to civil liability or violate any
    law. You are also prohibited from using the Site to advertise or perform any commercial solicitation. Janus will
    have no obligations with respect to such communications. Janus reserves the right to remove any and all user
    submissions it deems in violation of these Terms of Use.
  </p>
  <p>
    <b class="pr-4">10. Indemnity.</b> You agree to defend, indemnify, and hold Janus, its officers, directors,
    employees, agents, and licensors harmless from and against any and all claims, losses, liability, costs, and
    expenses (including attorneys’ fees) arising from your use of the Site, violation of these Terms of Use, or
    violation of any third party rights.
  </p>
  <p class="mb-4">
    YOU AGREE TO WAIVE ANY AND ALL LAWS THAT MAY LIMIT THE EFFICACY OF ANY RELEASES CONTAINED IN THESE TERMS OF USE,
    INCLUDING THE PROVISIONS OF CALIFORNIA CIVIL CODE SECTION 1542.
  </p>
  <p class="mb-4">
    <b class="pr-4">11. Forward-Looking Statements.</b> Some statements on this Site are not historical facts and
    therefore are considered forward-looking statements. These forward-looking statements are subject to risks and
    uncertainties as which could cause our actual results to differ materially from those expressed in or implied by the
    content of this Site. Forward-looking statements made on this Site are made as of the date of the initial
    publication and Janus undertakes no obligation to update any of these forward-looking statements as actual events
    unfold.
  </p>
  <p class="mb-4">
    <b class="pr-4">12. Third Party Payments.</b> Janus is not responsible for any charges or fees associated with
    financial transactions that occur on or through third party websites. Any payments you may make for services you
    have found on this Site are made exclusively through an affiliated third party website the separate terms and
    privacy policy will apply.
  </p>
  <p>
    <b class="pr-4">13. Dispute Resolution. </b>If a dispute arises between you and Janus, our goal is to resolve the
    dispute quickly and cost-effectively. Accordingly, unless you opt out, you agree to resolve any claim, dispute, or
    controversy that arises out of or relates to these Terms of Use or the Site in accordance with this Section 13.
  </p>
  <p class="mb-4">
    If, within 30 days after written notice of dispute, parties have not succeeded in resolving the dispute, then such
    dispute shall be settled by final and binding arbitration before a single arbitrator in Chicago, Illinois, pursuant
    to the rules of the American Arbitration Association. The parties agree to: (a) submit to the jurisdiction of any
    competent state or federal court for purposes of enforcingthe parties’ agreement to arbitrate any disputes
    hereunder; and (b) waive any issues pertaining to personal jurisdiction or venue. The costs of the arbitration shall
    be shared equally between the parties, except that each party shall be responsible for its own attorneys’ fees and
    costs in preparing and presenting its case, and the prevailing party shall be entitled to reimbursement of such fees
    and costs. Notwithstanding the above, a party shall be entitled to equitable relief, injunction and/or specific
    performance in any court of competent jurisdiction, to protect its Confidential Information or intellectual property
    rights, without having to post a bond or other security, or to prove damages or the inadequacy of damages.
  </p>
  <p>
    <b class="pr-4">14. Choice of Law and Venue.</b> These Terms of Use are governed by the laws of the State of
    Illinois, excluding its conflict of law principles. The United Nations Convention on Contracts for the International
    Sale of Goods shall not apply. The parties agree that the federal or state courts sitting in Cook County, Illinois,
    shall be the exclusive courts of jurisdiction and venue for any litigation or proceeding as between the Parties that
    may be brought, or arise out of, or in connection with, or by reason of these Terms of Use.
  </p>
  <p class="mb-4">
    You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or
    related to the Site, the Terms of Use or Janus’ Privacy Policy must be filed within one year after such claim or
    cause of action arose.
  </p>
  <p class="mb-2">
    <b class="pr-4">15. Invalidity and Waiver.</b> If any provision of these Terms of Use is found to be invalid by any
    court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining
    provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any of these Terms of
    Use shall be deemed a further or continuing waiver of such term or condition or any other term or condition.
  </p>
  <p class="mb-2">
    <b class="pr-4">16. We Can Change our Site and these Terms of Use.</b>
    From time to time, we may modify these Terms of Use, as well as the Site. We will post or display a notice of
    material changes to any of our Terms of Use on the Site affected by the change and also change the effective date
    specified above. If you use the Site after the effective date, it means you are agreeing to be bound by the changes.
    You should check on a regular basis and review the Terms of Use that apply to you so you are aware of the current
    rights and obligations that apply to you. Certain provisions of these Terms of Use may be superseded by expressly
    designated legal notices, your software license agreement or terms on particular pages at this Site.
  </p>
  <p class="mb-2">
    <b class="pr-4">17. Section Titles.</b>
    The section titles of the Terms of Use are merely for convenience and will not have any effect on the substantive
    meaning of these Terms of Use.
  </p>
  <p class="mb-2">
    <b class="pr-4">18. Contact.</b> Janus welcomes your questions and comments regarding our Terms of Use and anything
    else on this Site. Please e-mail us at <u><a href="mailto:info@janus-ai.com">info&commat;janus-ai.com</a></u
    >.
  </p>
  <p class="mb-2">
    <b class="pr-4">19. Entire Agreement.</b> Except as expressly provided in a particular &quot;legal notice&quot; on
    the Janus Site, these Terms of Use constitute the entire agreement between you and Janus with respect to the use of
    the Site and content contained therein.
  </p>
  <p>
    <b class="pr-4">20. Site Features.</b> If a free feature is provided, it is being provided to you as a convenience.
    Janus reserves the right to change such feature from time to time in its sole discretion and Provider has the right
    to terminate, limit or revoke your Site access at any time for any reason, with or without notice. This can include
    cases where Janus determines that it is not in your best interest to continue to have access to the Service. Janus
    reserves the right to charge for Services in the future.
  </p>
  <p class="mb-2">
    Janus will use reasonable efforts to keep the Site free from error, but Janus cannot guarantee the completeness,
    accuracy, availability, reliability or adequacy of the information. Janus cannot guarantee the Site itself will be
    fault-free, and it is in Provider’s sole discretion whether it will attempt to correct reported faults or errors.
  </p>
  <p class="mb-2">
    <b class="pr-4">21. Electronic Communications.</b> When you submit communications to the Site’s contact page or send
    e-mails to us, you are communicating with us electronically. You acknowledge that information sent by e-mail may be
    intercepted by someone unknown to you because of the nature of e-mail, through no fault of Janus, and you agree to
    accept this risk. By using the Site or sending e-mails, you consent to receive communications from us
    electronically. We will communicate with you by e-mail or by posting notices on the Site. You agree that all
    agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal
    requirement that such communications be in writing. You further agree that any notices provided by us electronically
    are deemed to be given and received on the date we transmit any such electronic communication. The contents of any
    communications using the Site or send e-mails to us may be stored in your medical record.
  </p>
  <p class="mb-2">
    <b class="pr-4">22. Security.</b> The security of any data you submit through the Site is very important to Janus.
    However, no system can completely guard against risks of intentional intrusion or inadvertent disclosure of
    information sent to us. Moreover, when you transmit information via the internet, your information will be
    transmitted over a medium that is beyond our control, and the security of the transmission may be compromised before
    it reaches us. Accordingly, Janus makes no guarantee as to confidentiality or security. In addition, and to the
    extent allowed under law, YOU HEREBY EXPRESSLY AND SOLELY ASSUME THE RISK OF ANY UNAUTHORIZED DISCLOSURE OR
    INTENTIONAL INTRUSION, OR OF ANY DELAY, FAILURE, INTERRUPTION OR CORRUPTION OF DATA OR OTHER INFORMATION TRANSMITTED
    IN CONNECTION WITH YOUR USE OF THE SITE.
  </p>
  <p class="mb-4">
    In addition, you understand that you are responsible to ensure that your computer has protections against viruses,
    malware, spyware, Trojan horses, and other types of problematic computer cookies, downloads, or software. Janus
    cannot guarantee that your use of the Services will be free of such problems. By using the Site and Services you
    agree that you will hold harmless, indemnify, and covenant not to sue Janus for any damage to your computer, laptop,
    software, or other computer system resulting from your access to or use of the Site and Services.
  </p>
  <p>
    <b
      >If you do not consent to the information collection and use practices described above or in the Privacy Policy,
      please exit this Site immediately.</b
    >
  </p>
</div>
