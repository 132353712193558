import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CopyWorkflowType,
  CreateWorkflow,
  CreateWorkflowType,
  DeleteWorkflow,
  DeleteWorkflowType,
  ExtendWorkflowType,
  GetWorkflow,
  GetWorkflowType,
  GetWorkflowTypes,
  PublishWorkflowType,
  RejectWorkflow,
  UnrejectWorkflow,
  UpdateWorkflowType,
} from 'app/admin/workflows/workflows.gql'
import { debug } from 'app/shared/utils/debug'
import {
  ListResponseMetaData,
  MutationCopyWorkflowTypeArgs,
  MutationCreateWorkflowArgs,
  MutationCreateWorkflowTypeArgs,
  MutationDeleteWorkflowTypeArgs,
  MutationExtendWorkflowTypeArgs,
  MutationPublishWorkflowTypeArgs,
  MutationRejectWorkflowArgs,
  MutationUnrejectWorkflowArgs,
  MutationUpdateWorkflowTypeArgs,
  QueryWorkflowArgs,
  QueryWorkflowTypeArgs,
  QueryWorkflowTypesArgs,
  Workflow,
  WorkflowInput,
  WorkflowType,
  WorkflowTypeInput,
  WorkflowTypeList,
} from 'generated/graphql'
import { Observable } from 'rxjs'

/**
 * Handle workflow operations
 *
 * @export
 * @class WorkflowService
 */
@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof WorkflowService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve a single workflow
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ workflow: Workflow }>>}
   * @memberof WorkflowService
   */
  getWorkflow(id: string): Observable<ApolloQueryResult<{ workflow: Workflow }>> {
    return this.apollo.watchQuery<{ workflow: Workflow }, QueryWorkflowArgs>({
      query: GetWorkflow,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Reject a workflow on a claim
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof WorkflowService
   */
  async rejectWorkflow(id: string): Promise<string> {
    let result = await this.apollo
      .mutate<{ rejectWorkflow: Workflow }, MutationRejectWorkflowArgs>({
        mutation: RejectWorkflow,
        variables: {
          id: id,
        },
        // refetchQueries: [
        //   {
        //     query: GetWorkflow,
        //     variables: {
        //       id: id,
        //     },
        //   },
        // ],
      })
      .toPromise()

    return result?.data?.rejectWorkflow?.id
  }

  /**
   * Re-open a workflow on a claim
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof WorkflowService
   */
  async unrejectWorkflow(id: string): Promise<string> {
    let result = await this.apollo
      .mutate<{ unrejectWorkflow: Workflow }, MutationUnrejectWorkflowArgs>({
        mutation: UnrejectWorkflow,
        variables: {
          id: id,
        },
        // refetchQueries: [
        //   {
        //     query: GetWorkflow,
        //     variables: {
        //       id: id,
        //     },
        //   },
        // ],
      })
      .toPromise()

    return result?.data?.unrejectWorkflow?.id
  }

  /**
   * Retrieve list of workflow types
   *
   * @param {QueryWorkflowTypesArgs} [variables]
   * @return {*}  {Promise<WorkflowType[]>}
   * @memberof WorkflowService
   */
  async getWorkflowTypes(variables?: QueryWorkflowTypesArgs): Promise<WorkflowType[]> {
    let result = await this.apollo
      .query<{ workflowTypes: WorkflowTypeList }, QueryWorkflowTypesArgs>({
        query: GetWorkflowTypes,
        variables,
      })
      .toPromise()
    this.meta = result.data.workflowTypes.meta
    return result.data?.workflowTypes?.entities
  }

  /**
   * Retrieve single workflow type
   *
   * @param {string} id
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async getWorkflowType(id: string): Promise<WorkflowType> {
    let result = await this.apollo
      .query<{ workflowType: WorkflowType }, QueryWorkflowTypeArgs>({
        query: GetWorkflowType,
        variables: { id },
      })
      .toPromise()
    return result.data?.workflowType
  }

  /**
   * Create new workflow type
   *
   * @param {WorkflowTypeInput} data
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async createWorkflowType(data: WorkflowTypeInput): Promise<WorkflowType> {
    debug('workflow', 'createWorkflowType', data)
    let result = await this.apollo
      .mutate<{ createWorkflowType: WorkflowType }, MutationCreateWorkflowTypeArgs>({
        mutation: CreateWorkflowType,
        variables: { data },
      })
      .toPromise()
    return result.data?.createWorkflowType
  }

  /**
   * Create duplicate of existing workflow type
   *
   * @param {string} id
   * @param {string} name
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async copyWorkflowType(id: string, name: string): Promise<WorkflowType> {
    debug('workflow', 'copyWorkflowType', id, name)
    let result = await this.apollo
      .mutate<{ copyWorkflowType: WorkflowType }, MutationCopyWorkflowTypeArgs>({
        mutation: CopyWorkflowType,
        variables: { id, name },
      })
      .toPromise()
    return result.data?.copyWorkflowType
  }

  /**
   * Update existing workflow type
   *
   * @param {string} id
   * @param {WorkflowTypeInput} data
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async updateWorkflowType(id: string, data: WorkflowTypeInput): Promise<WorkflowType> {
    debug('workflow', 'updateWorkflowType', { id, data })
    let result = await this.apollo
      .mutate<{ updateWorkflowType: WorkflowType }, MutationUpdateWorkflowTypeArgs>({
        mutation: UpdateWorkflowType,
        variables: { id, data },
      })
      .toPromise()
    return result.data?.updateWorkflowType
  }

  /**
   * Create a new version of a published workflow type
   *
   * @param {string} id
   * @param {WorkflowTypeInput} data
   * @param {boolean} updateExisting
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async extendWorkflowType(id: string, data: WorkflowTypeInput, updateExisting: boolean): Promise<WorkflowType> {
    debug('workflow', 'extendWorkflowType', { id, data })
    let result = await this.apollo
      .mutate<{ extendWorkflowType: WorkflowType }, MutationExtendWorkflowTypeArgs>({
        mutation: ExtendWorkflowType,
        variables: { id, data, updateExisting },
      })
      .toPromise()
    return result.data?.extendWorkflowType
  }

  /**
   * Delete existing workflow type
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof WorkflowService
   */
  async deleteWorkflowType(id: string): Promise<boolean> {
    debug('workflow', 'deleteWorkflowType', id)
    let result = await this.apollo
      .mutate<{ deleteWorkflowType: boolean }, MutationDeleteWorkflowTypeArgs>({
        mutation: DeleteWorkflowType,
        variables: { id },
      })
      .toPromise()
    return result.data?.deleteWorkflowType
  }

  /**
   * Delete existing workflow on claim
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof WorkflowService
   */
  async deleteWorkflow(id: string): Promise<boolean> {
    debug('workflow', 'deleteWorkflow', id)
    let result = await this.apollo
      .mutate<{ deleteWorkflow: boolean }, { id: string }>({
        mutation: DeleteWorkflow,
        variables: { id },
      })
      .toPromise()
    return result.data?.deleteWorkflow
  }

  /**
   * Publish an existing workflow type
   *
   * @param {string} id
   * @return {*}  {Promise<WorkflowType>}
   * @memberof WorkflowService
   */
  async publishWorkflowType(id: string): Promise<WorkflowType> {
    debug('workflow', 'publishWorkflowType', id)
    let result = await this.apollo
      .mutate<{ publishWorkflowType: WorkflowType }, MutationPublishWorkflowTypeArgs>({
        mutation: PublishWorkflowType,
        variables: { id },
      })
      .toPromise()
    return result.data?.publishWorkflowType
  }

  /**
   * Create a workflow on a claim
   *
   * @param {WorkflowInput} data
   * @return {*}  {Promise<Workflow>}
   * @memberof WorkflowService
   */
  async createWorkflow(data: WorkflowInput): Promise<Workflow> {
    debug('workflow', 'createWorkflow', data)
    let result = await this.apollo
      .mutate<{ createWorkflow: Workflow }, MutationCreateWorkflowArgs>({
        mutation: CreateWorkflow,
        variables: { data },
      })
      .toPromise()
    return result.data?.createWorkflow
  }
}
