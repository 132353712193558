import { Component, Input, OnChanges } from '@angular/core'

const LETTERS = {
  high: 'H',
  medium: 'M',
  low: 'L',
  null: 'N',
}

/**
 * Component to display the level or variance of an opportunity or path
 *
 * @export
 * @class LevelBadgeComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-level-badge',
  templateUrl: './level-badge.component.html',
  styleUrls: ['./level-badge.component.scss'],
})
export class LevelBadgeComponent implements OnChanges {
  @Input() level: 'low' | 'medium' | 'high' | 'null'

  @Input() text: string = null
  _text: string

  constructor() {}

  ngOnChanges(): void {
    this._text = this.text || LETTERS[this.level]
  }
}
