<div class="bg-alt-lighter-gray container-fluid">
  <ng-container>
    <div class="row p-4 mx-2">
      <label>Missing Reason: {{ missReason ? (missReason | sentenceCase | startCase) : '' }}</label>
    </div>

    <div class="row m-1 pb-4 justify-content-around">
      <div class="col-5 bg-alt-white">
        <div class="row alert alert-warning" role="alert">
          <div class="col text-center">You must be connected to the VPN in order to view Observe images</div>
        </div>
        <div class="row bg-alt-white mt-2 align-items-end overflow-hidden no-gutters">
          <div class="col-4">
            <app-pagination [metaData]="imagesMetaData" (fetchPage)="onImageUpdate($event)"> </app-pagination>
          </div>
          <div class="col-2">
            <button type="button" class="btn btn-link" (click)="refreshImages()">Refresh Images</button>
          </div>
          <div class="col-6">
            <app-canvas-controls
              class="mb-0"
              [zoomLevel]="zoomLevel$ | async"
              (zoomIn)="zoomIn()"
              (zoomOut)="zoomOut()"
              (resetZoom)="resetZoom()"
              (setZoom)="setZoom($event)"
              [isPanning]="isPanningEnabled"
              (togglePanning)="togglePanning()"
            ></app-canvas-controls>
          </div>
        </div>

        <div class="row bg-alt-white px-2">
          <div class="container-fluid image-container m-2 px-0" id="image-container">
            <ng-container *ngIf="!imageError && !imagesLoading">
              <img
                *ngIf="imageLinks?.length"
                [src]="imageLinks[imageIndex]?.imageUrl"
                class="shadow"
                [style.width.%]="imageWidthPercent"
                style="height: auto"
                (error)="errorLoadingImg()"
              />
            </ng-container>
            <ng-container *ngIf="imageError">
              <div class="col alert alert-danger mb-0" role="alert">
                <div class="col text-center">Error loading images</div>
              </div>
            </ng-container>
            <ng-container *ngIf="imagesLoading then loadingView"></ng-container>
          </div>
        </div>
      </div>

      <div class="col-4 bg-alt-white ml-3">
        <div class="table-container mt-3 pb-2">
          <div class="row">
            <div class="col text-center border-bottom pt-2">
              <h5><span>Processed Observe Fields</span></h5>
            </div>
          </div>

          <ng-container *ngIf="!observeFieldsLoading; else loadingView">
            <div
              *ngFor="let row of recordFields | keyvalue"
              class="content-row p-1"
              [ngClass]="row.key != '__typename' ? 'border-bottom ' : ''"
            >
              <ng-container *ngIf="row.key != '__typename'">
                <div class="label ml-1">{{ row.key | sentenceCase | startCase }}</div>
                <div class="value-cell ml-1 p-2">
                  {{ row.value }}
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-2 ml-2 bg-white">
        <form [formGroup]="screenLabelForm">
          <div class="form-row mb-4 pt-2">
            <label for="regexField">Regex Field</label>
            <select formControlName="typeOfConfig" class="form-control shadow-sm" id="regexField">
              <option [value]="typeOfConfgs.Locate" class="text-alt-medium-gray">Regex on field value</option>
              <option [value]="typeOfConfgs.Identify" class="text-alt-medium-gray">Template matching</option>
            </select>
          </div>

          <div class="form-row mb-4 pt-2">
            <label for="metaDataFields">Metadata fields</label>
            <input formControlName="metaDataField" class="form-control shadow-sm" id="metaDataFields" readonly />
          </div>

          <div class="form-row mb-4 pt-2 pb-4 border-bottom border-dark">
            <label for="regex">Regex</label>
            <input
              type="text"
              class="form-control"
              id="regex"
              formControlName="regexValue"
              placeholder="Enter expression"
            />
          </div>

          <div class="form-row mb-4 pt-2">
            <label for="labelName">Label Name</label>
            <input
              type="text"
              class="form-control"
              id="labelName"
              formControlName="labelName"
              placeholder="Enter label name"
            />
          </div>
        </form>

        <div class="row py-4">
          <div class="col-4">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              (click)="openConfirmModal()"
              [disabled]="!this.screenLabelForm.valid"
            >
              Save
            </button>
          </div>
          <div class="col-4">
            <a href="{{ regexRefUrl }}" target="_blank"> Validate </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loadingView>
    <div class="p-4">
      <app-loading
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      >
      </app-loading>
    </div>
  </ng-template>

  <ng-template #confirmCreateConfigModal let-modal>
    <app-generic-modal
      title="Create Config"
      confirmText="Create"
      cancelText="Edit"
      (onConfirm)="dialogConfirm$.next(true); modal.close()"
      (onCancel)="dialogConfirm$.next(false); modal.close()"
    >
      <div>
        <p>
          Regular Expression: <b>{{ screenLabelForm?.value.regexValue }} </b>
        </p>
        <p>
          Screen Label: <b> {{ screenLabelForm?.value.labelName }} </b>
        </p>
      </div>
    </app-generic-modal>
  </ng-template>
</div>
