import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrgService } from 'app/admin/org/org.service';
import { ToastService } from 'app/shared/services/toast.service';
import { Organization } from 'generated/graphql';
import { first } from 'rxjs/operators';
import { ObserveOrgSettingsService } from '../services/observe-org-settings.service';

@Component({
  selector: 'app-observe-org-settings',
  templateUrl: './observe-org-settings.component.html'
})
export class ObserveOrgSettingsComponent implements OnInit {

  orgSettingsForm: FormGroup = new FormGroup({
    collectData: new FormControl(false),
    enqueueRecords: new FormControl(false),
  })
  orgId: string
  organization: Organization

  constructor(private route: ActivatedRoute, private orgService: OrgService, private observeOrgSettingsService: ObserveOrgSettingsService, private toast: ToastService,) {
   }

  async ngOnInit(): Promise<void> {
    this.orgId = (await this.route.params.pipe(first()).toPromise())?.orgId
    this.organization = (await this.orgService.getOrg(this.orgId).pipe(first()).toPromise()).data?.organization

    const observeOrgSettings = (await this.observeOrgSettingsService.getObserveOrgSettings(this.orgId).pipe(first()).toPromise()).data?.observeOrgSettingsByOrgId
    const collectDataSetting = observeOrgSettings.find(setting => {
      return setting.key === 'ingestion-api.collect-data'
     })
    const queueRecordsSetting = observeOrgSettings.find(setting => {
      return setting.key == 'ingestion-api.queue-records'
    })
        
    this.orgSettingsForm.patchValue({
      collectData: String(collectDataSetting?.value).toLocaleLowerCase() === 'true',
      enqueueRecords:  String(queueRecordsSetting?.value.toLocaleLowerCase()) === 'true'
    })
  }

  async saveOrgChanges(): Promise<void> {
      try {
        if(this.orgSettingsForm.get('collectData').touched) {
          const val = this.orgSettingsForm.get('collectData').value
          const saveResult = (await this.observeOrgSettingsService.updateObserveOrgSetting(this.orgId, 'ingestion-api.collect-data', val.toString())).data.updateObserveOrgSetting
          if(saveResult) {
              this.toast.success('Successfully Updated, Org Collect Data Setting')
          }
        } 

        if(this.orgSettingsForm.get('enqueueRecords').touched) {
          const val = this.orgSettingsForm.get('enqueueRecords').value
          const saveResult = (await this.observeOrgSettingsService.updateObserveOrgSetting(this.orgId, 'ingestion-api.queue-records', val.toString())).data.updateObserveOrgSetting
          if(saveResult) {
              this.toast.success('Successfully Updated, Org Queue Data Setting')
            }
        
        }
    } catch(error) {
      this.toast.error('A error occurred updating the observe org settings')
    }
  }

}
