import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { Claim, Task, Workflow } from 'generated/graphql'

/**
 * Component to display details about a task's claim
 *
 * @export
 * @class TaskClaimCardComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-task-claim-card',
  templateUrl: './task-claim-card.component.html',
  host: {
    '[class.w-100]': 'true',
    '[class.flex-grow-1]': 'true',
    '[class.mb-2]': 'true',
  },
})
export class TaskClaimCardComponent implements OnChanges {
  @Input() claim: Claim = null
  @Input() selected: Task | Workflow = null

  @Input() isNextEnabled: boolean = null
  @Input() isPreviousEnabled: boolean = null
  @Output() taskNavButtonClick = new EventEmitter<'next' | 'prev'>()

  @Input() isSidebarCollapsed: boolean = true
  @Output() isSidebarCollapsedChange = new EventEmitter<boolean>()

  otherTasksOnClaim: Task[] = []
  otherWorkflowsOnClaim: Task[] = []

  constructor() {}

  ngOnChanges(): void {
    if (this.claim?.tasks?.length && this.selected?.id) {
      let array = this.claim.tasks.slice()
      this.otherTasksOnClaim = this.orderTasks(array)
    }
  }

  /**
   * Helper to determine if a task or workflow is complete
   *
   * @param {(Task | Workflow)} task
   * @return {*}  {boolean}
   * @memberof TaskClaimCardComponent
   */
  isCompleted(task: Task | Workflow): boolean {
    return task.__typename === 'Task'
      ? task?.completedUserId !== null && task?.completionType === 'Completed'
      : task?.completed
  }

  /**
   * Helper to determine if a task or workflow is rejected
   *
   * @param {(Task | Workflow)} task
   * @return {*}  {boolean}
   * @memberof TaskClaimCardComponent
   */
  isRejected(task: Task | Workflow): boolean {
    return task.__typename === 'Task' ? task?.completedUserId !== null && task?.completionType === 'Rejected' : false
  }

  /**
   * Helper for ordering OTHER tasks on a claim
   *
   * @private
   * @param {Task[]} tasks
   * @return {*}  {Task[]}
   * @memberof TaskClaimCardComponent
   */
  private orderTasks(tasks: Task[]): Task[] {
    if (tasks.length) {
      let t = this.selected.__typename === 'Task' ? tasks.filter((ts) => ts.id !== this.selected?.id) : tasks
      let completed = t.filter((ts) => ts.completedBy)
      let open = t
        .filter((ts) => !ts.completedBy)
        .sort((a, b) => {
          let one = Number(new Date(a.createdAt))
          let two = Number(new Date(b.createdAt))
          return one - two
        })
      return open.concat(completed)
    } else {
      return []
    }
  }
}
