<label class="sr-only" for="text-typeahead-input">Type to search</label>
<div class="input-group">
  <input
    id="text-typeahead-input"
    type="text"
    class="form-control"
    [readonly]="readonly"
    [placeholder]="placeholder"
    container="body"
    [ngbTypeahead]="search"
    popupClass="above-modal"
    [resultFormatter]="resultFormatter"
    [inputFormatter]="inputFormatter"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectItem($event)"
    #input="ngbTypeahead"
    #element
  />
  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="element.value.length && !readonly"
      type="button"
      (click)="clearText($event)"
      class="btn bg-transparent"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
  </span>
</div>
