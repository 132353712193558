import { Component } from '@angular/core'
import { FeatureFlags } from 'app/shared/services/feature-flag.service'

/**
 * Component that is the links for observe admin
 */
@Component({
  selector: 'app-observe-admin-links',
  templateUrl: './observe-admin-links.component.html',
})
export class ObserveAdminLinksComponent {
  flags = FeatureFlags
}
