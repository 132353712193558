import { Component, OnInit } from '@angular/core'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'

@Component({
  selector: 'app-denials-dashboard',
  templateUrl: './denials-dashboard.page.html',
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class DenialsDashboardPage implements OnInit {
  readonly dashboardType: QuicksightDashboardType = QuicksightDashboardType.Standard
  constructor() {}

  ngOnInit(): void {}
}
