<div class="w-100">
  <app-badge-pill
    *ngFor="let pill of pills; trackBy: trackById"
    [boldedLabel]="pill?.title + (pill?.selected?.display.length ? ':' : '')"
    [label]="pill?.selected?.display"
    (onDeleteClicked)="removePill(pill)"
    data-t="search-value-tag"
  >
  </app-badge-pill>
</div>
