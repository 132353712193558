<div class="d-flex ml-0">
  <h5 class="mb-3 flex-grow-1">Key Information</h5>
  <button
    *ngIf="nodeBotAllowed"
    (click)="openBotModal()"
    class="btn btn-sm mb-3 mr-2 btn-outline-primary flex-shrink-1 d-flex align-items-center"
    target="_blank"
  >
    <app-janus-icon icon="bot" class="pt-2 mr-1 d-flex align-items-center"></app-janus-icon>
    Node Bot
  </button>
  <div class="btn-group border-0 justify-content-end gap-3 mb-3">
    <a
      *ngIf="isQediEnabled && !!claim"
      class="btn btn-sm btn-outline-primary flex-shrink-1 d-flex align-items-center"
      [routerLink]="['/admin', 'qedi', 'launchpad']"
      [queryParams]="{ claimId: claim.id }"
      target="_blank"
    >
      <app-janus-icon icon="bot" class="mr-1 d-flex align-items-center"></app-janus-icon>
      Demonstrate with QEDI
    </a>
    <app-teleport-button [claim]="claim"></app-teleport-button>
  </div>
  <div>
    <button
      class="btn btn-sm btn-outline-primary float-sm-right ml-2"
      *ngIf="org.isDemo()"
      (click)="showOpNote(imageModal)"
    >
      Retrieve Op Note <i class="fa fa-fw fa-envelope ml-2"></i>
    </button>
    <button
      class="btn btn-sm btn-outline-primary float-sm-right ml-2"
      *ngIf="org.isDemo()"
      (click)="showImages(images)"
    >
      Status <i class="fa fa-fw fa-picture-o ml-2"></i>
    </button>
  </div>
</div>
<div class="card bg-light mb-5">
  <div class="card-body">
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('providerClaimId')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.providerClaimId" data-t="claim-page-claim-id"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('ehrStatusCode')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value
          [value]="claim?.ehrStatusCode"
          data-t="claim-page-claim-status"
          [ngbTooltip]="claim?.ehrStatusText"
          container="body"
        ></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('icn')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.icn" data-t="detail-page-claim-icn"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('patientName')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.patientName" facet="patientName"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('patientSex')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.patientSex"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('patientMrn')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.patientMrn"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('patientDob')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.patientDob ? (claim.patientDob | date : 'MM/dd/yyyy') : ''"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('payer.name')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value
          [value]="claim?.payer?.name"
          facet="payer.name"
          data-t="detail-page-claim-payer-name"
        ></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('serviceDate')?.label }}</dt>
      <dd class="flex-grow-1 text-right">
        <app-copy-value [value]="claim?.serviceDate ? (claim.serviceDate | date : 'MM/dd/yyyy') : ''"></app-copy-value>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('providerBilling.name')?.parentHeading }}</dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span
          class="flex-shrink-1"
          [ngClass]="{
            'border-right border-dark pr-2': claim?.providerBilling?.npi
          }"
        >
          <app-copy-value
            [value]="claim?.providerBilling?.name"
            facet="providerBilling.name"
            data-t="detail-page-claim-provider-name"
          ></app-copy-value>
        </span>
        <span class="pl-2">
          <app-copy-value [value]="claim?.providerBilling?.npi"></app-copy-value>
        </span>
      </dd>
    </div>
    <div class="d-flex w-100 py-1">
      <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('providerRendering.name')?.parentHeading }}</dt>
      <dd class="flex-grow-1 justify-content-end d-flex">
        <span
          class="flex-shrink-1"
          [ngClass]="{
            'border-right border-dark pr-2': claim?.providerRendering?.npi
          }"
        >
          <app-copy-value [value]="claim?.providerRendering?.name" facet="providerRendering.name"></app-copy-value>
        </span>
        <span class="pl-2">
          <app-copy-value [value]="claim?.providerRendering?.npi"></app-copy-value>
        </span>
      </dd>
    </div>
  </div>
</div>
<ng-template #images let-modal class="d-flex justify-content-center">
  <ngb-carousel [interval]="null">
    <ng-template ngbSlide>
      <div class="d-flex picsum-img-wrapper justify-content-center mx-5">
        <img src="/assets/img/demo-claim-status.png" class="w-75" />
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="d-flex picsum-img-wrapper justify-content-center mx-5">
        <img src="/assets/img/demo-claim-payment.png" class="w-75" />
      </div>
    </ng-template>
  </ngb-carousel>
</ng-template>
<ng-template #imageModal let-modal class="d-flex justify-content-center">
  <img src="/assets/img/op-note.png" class="img-fluid" />
</ng-template>
