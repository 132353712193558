<div class="row no-gutters h-100">
  <div
    class="col-12 d-flex flex-column justify-content-center align-items-stretch border-1 border-alt-light-gray bg-brand-gray"
  >
    <ng-container *ngIf="(file$ | async)">
      <h1 class="h5 m-0 pl-3 p-2 bg-alt-white border-bottom-1 border-alt-light-gray">
        <strong>View File:</strong> {{ (fileName$ | async) }}
      </h1>
      <ng-container [ngSwitch]="fileType$ | async">
        <app-pdf-viewer *ngSwitchCase="'pdf'" [src]="fileUrl$ | async" class="flex-grow-1"></app-pdf-viewer>
        <app-image-viewer *ngSwitchCase="'image'" [src]="fileUrl$ | async" class="flex-grow-1 m-4"></app-image-viewer>
        <div *ngSwitchDefault class="h-100 d-flex flex-column align-items-center justify-content-center">
          <span class="h1 text-center"><i class="fa fa-circle-question"></i></span>
          <p>Unable to display preview for files of this type</p>
          <a class="btn btn-primary" [href]="fileUrl$ | async" [download]="fileName$ | async">Download</a>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(result$ | async)?.errors?.length">
      <span class="h1 text-center"><i class="fa fa-exclamation-circle text-danger"></i></span>
      <p class="text-center">Could not find file with given ID</p>
    </ng-container>
  </div>
</div>
