<div class="row mt-2">
  <div class="col-xl-6 mt-4 flex-grow-1 d-flex justify-content-start">
    <nav ngbNav #nav="ngbNav" [(activeId)]="currentType" (navChange)="typeChangeEvent($event)">
      <ng-container ngbNavItem [ngbNavItem]="acl_Types.ALL">
        <a ngbNavLink class="px-4" [ngClass]="{ 'bg-white': currentType == acl_Types.ALL }" role="button">ALL</a>
      </ng-container>
      <ng-container ngbNavItem [ngbNavItem]="acl_Types.APP">
        <a ngbNavLink class="px-4" [ngClass]="{ 'bg-white': currentType == acl_Types.APP }" role="button">APP</a>
      </ng-container>
      <ng-container ngbNavItem [ngbNavItem]="acl_Types.URL">
        <a ngbNavLink class="px-4" [ngClass]="{ 'bg-white': currentType == acl_Types.URL }" role="button">URL</a>
      </ng-container>
    </nav>
  </div>
</div>

<div class="d-flex flex-column flex-grow-1 flex-basis-0 table-responsive-md overflow-auto col p-0">
  <table class="table table-janus-new table-striped bg-white" data-t="observe-usage-summary-table">
    <thead class="bg-white">
      <tr>
        <th scope="col" class="text-brand-dark-gray text-left" sortable="domain" (sort)="onSort($event)">Domain</th>
        <th scope="col" class="text-brand-dark-gray px-4" sortable="status" (sort)="onSort($event)">Status</th>
        <th scope="col" class="text-brand-dark-gray px-4">Type</th>
        <th scope="col" class="text-brand-dark-gray px-4" sortable="hits" (sort)="onSort($event)">Hits</th>
        <th scope="col"><span class="sr-only width-1">Create Rule</span></th>
      </tr>
    </thead>
    <tbody *ngIf="!isLoading && observeUsageSummaries?.length">
      <tr *ngFor="let summary of displayedSummaries" data-t="observe-usage-summary-row">
        <td class="domain-col" ngbTooltip="{{summary.applicationOrUrl}}" container="body">{{ summary.applicationOrUrl }}</td>
        <td class="text-center">
          <i [class]="summary?.allowed ? 'text-alt-green fa fa-check-square' : 'text-alt-red fa fa-window-close'"></i>
        </td>
        <td class="text-center">{{ summary.type }}</td>
        <td class="text-right">{{ summary.count | number }}</td>
        <td class="text-right">
          <button type="button" class="btn btn-link p-0" (click)="createRule(summary)" ngbTooltip="Create Rule"  container="body">
            <i class="fa fa-fw fa-lg fa-plus-square text-alt-light-gray"></i>
            <span class="sr-only">Create Rule</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <app-loading
    *ngIf="isLoading"
    innerClass="fa-4x"
    outerClass="align-self-center text-brand-light-blue"
    class="d-flex flex-column flex-grow-1 justify-content-center"
  ></app-loading>
  <div
    *ngIf="!observeUsageSummaries?.length && !isLoading"
    class="d-flex justify-content-center alert alert-danger"
    role="alert"
  >
    Your search returned no results.
  </div>
</div>
