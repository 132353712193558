
<div class="h-100">
  <div class="row justify-content-center p-4 m-4">
    <app-loading
    *ngIf="isLoading"
    innerClass="fa-4x"
    outerClass="align-self-center text-brand-light-blue"
    class="d-flex flex-column flex-grow-1 justify-content-center"
  ></app-loading>
  </div>
   

    <div *ngIf="!isLoading && !embedUrl">Quicksight Dashboard is not available</div>
    <div id="{{ destinationDivId }}" class="h-100"></div>
</div>
