import { Component, EventEmitter, Input, Output } from '@angular/core'
import { WorkflowType } from 'generated/graphql'

/**
 * Component to display a single workflow type in a card
 *
 * @export
 * @class WorkflowCardComponent
 */
@Component({
  selector: 'app-workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss'],
})
export class WorkflowCardComponent {
  @Input() workflow: WorkflowType
  @Input() isCollapsed: boolean

  @Input() showRight: string = null
  @Input() showRemove: string = null

  @Output() clickRight = new EventEmitter<WorkflowType>()
  @Output() clickRemove = new EventEmitter<WorkflowType>()

  constructor() {}
}
