<div class="modal-header">
  <h4 class="modal-title">Load Search</h4>
</div>
<div class="modal-body">
  <div class="row mb-2">
    <div class="col-md-4">
      <app-saved-search-search (selectSearch)="selectSearch($event)"></app-saved-search-search>
    </div>
    <div class="col-md-8" *ngIf="selectedSearch">
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control task-input text-left" [value]="selectedSearch.name" readonly />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Description</label>
        <div class="col-sm-8">
          <textarea class="form-control task-input text-left" cols="30" rows="10" readonly>{{
            selectedSearch.description
          }}</textarea>
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label">Terms</label>
        <div class="col-sm-8">
          <span
            class="badge badge-pill badge-alt-steel-blue mx-2"
            *ngFor="let pill of pills"
            placement="top"
            [ngbTooltip]="pill?.title"
            container="body"
          >
            <span class="align-middle">{{ pill?.selected?.display }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="modal.dismiss()" data-t="load-search-cancel-button">
    Cancel
  </button>
  <button type="button" class="btn btn-brand-green px-5" data-t="load-search-save-button" (click)="load()">Load</button>
</div>
