import { Component, Input, TemplateRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  @Input() src: string = null

  constructor(private modal: NgbModal) {}

  openModal(modal: TemplateRef<NgbModal>): void {
    this.modal.open(modal, {
      centered: true,
      size: 'xl',
    })
  }
}
