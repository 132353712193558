<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center">
    <div>{{ organization.name }}<span *ngIf="primary" class="text-muted ml-1">(Primary)</span></div>

    <div>
      <button *ngIf="canEdit()" type="button" class="btn btn-link btn-sm" (click)="toggleEditing()">Manage</button>

      <button
        *ngIf="editing && isOrganizationRemovable"
        type="button"
        class="btn btn-danger btn-sm"
        (click)="removeOrg()"
      >
        Remove
      </button>

      <button *ngIf="editing" type="button" class="btn btn-secondary btn-sm ml-1" (click)="toggleEditing()">
        Done
      </button>
    </div>
  </div>

  <div class="card-body">
    <div *ngIf="!loading">
      <div *ngIf="!(roles && roles.length > 0)">
        <p>No roles available for this organization.</p>
      </div>

      <!-- Display Mode -->

      <div *ngIf="!editing">
        <span class="badge badge-secondary mr-2" *ngFor="let role of selectedRoles">
          {{ role.name }}
          <span class="fa-stack" (click)="removeRole(role)">
            <i class="fa fa-circle fa-stack-2x"></i>

            <i class="fa fa-times fa-stack-1x fa-inverse text-dark"></i>
          </span>
        </span>

        <jui-theme>
          <jui-button type="button" variant="dark" class="btn-sm" *ngIf="roles.length && !selectedRoles.length" (click)="toggleEditing()">
            Assign Roles
          </jui-button>
        </jui-theme>
      </div>

      <!-- Editing Mode -->

      <form *ngIf="editing" [formGroup]="rolesForm">
        <div *ngIf="roles.length" class="mb-2">
          <button type="button" class="btn btn-link btn-sm" (click)="selectAll()">Select All</button>

          <button type="button" class="btn btn-link btn-sm ml-2" (click)="clearAll()">Clear All</button>
        </div>

        <div class="form-group" *ngFor="let role of roles">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="role-{{ role.id }}" [formControlName]="role.name" />

            <label class="form-check-label" for="role-{{ role.id }}">{{ role.name }}</label>
          </div>
        </div>
      </form>
    </div>

    <!-- Loading Mode -->

    <app-loading *ngIf="loading"></app-loading>
  </div>
</div>
