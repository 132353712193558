<form (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Contact Payer </h4>
  </div>
  <div class="modal-body text-center">
    <p>Please contact the payer for the Authorization requirements on these procedures.</p>
    <p>Select Auth Required, Auth Not Required, or Contact Payer</p>
  </div>
  <div *ngFor="let procedureItem of tempProcedures" class="modal-body">
    <app-contact-payer-modal-dropdown [procedure]="procedureItem"></app-contact-payer-modal-dropdown>
  </div>
  <div class="modal-footer text-center d-flex flex-column">
    <h5 class="font-weight-bold d-flex">
      <p><strong>WARNING</strong></p>
    </h5>
    <p class="d-flex">If Auth Not Required is selected, that procedure will be removed from Teleport.</p>
    <p class="d-flex">When status is confirmed, the changes are permanent.</p>
    <div class="mt-5">
      <button type="submit" class="btn btn-brand-light-blue mr-5">
        Confirm
      </button>
      <button type="button" class="btn btn-brand-dark-blue ml-5" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</form>
