import { Component, Input, OnInit } from '@angular/core'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'

/**
 * Component to display a custom pre-defined facet for claim properties
 *
 * @export
 * @class PreDefinedFacetComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-faceted-search-pre-defined',
  templateUrl: './pre-defined.component.html',
  styleUrls: ['./pre-defined.component.scss'],
})
export class PreDefinedFacetComponent implements OnInit {
  @Input() data: Facet

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.facetService.addToFacet(this.data.id, { value: '', display: '' })
  }

  /**
   * Remove this facet
   *
   * @memberof PreDefinedFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
