import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { PermissionsConfig } from './permissions-config.interface'
import { AuthorizationService } from '../authorization.service'

/**
 * A structural directive for hiding/showing content based on user permissions.
 *
 * @export
 * @class PermissionsDirective
 * @example <div *appPermissions="config"></div>
 */
@Directive({
  selector: '[appPermissions]',
})
export class PermissionsDirective {
  @Input('appPermissions') config: PermissionsConfig

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    this.checkPermissions()
  }
  async checkPermissions(): Promise<void> {
    if (this.config && (await this.authorizationService.hasPermissionsOrRoles(this.config))) {
      this._show()
    } else {
      this._hide()
    }
  }

  private _hide(): void {
    if (this._viewContainer.length) this._viewContainer.clear()
  }

  private _show(): void {
    if (!this._viewContainer.length) this._viewContainer.createEmbeddedView(this._templateRef)
  }
}
