import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Subject, combineLatest } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'
import { ZendeskService } from 'app/shared/services/zendesk.service'

type ErrorTypes = (typeof errorTypes)[number]
const errorTypes = [
  'General',
  'UnsupportedPayer',
  'DuplicateAutomation',
  'WebsocketDisconnected',
  'TeleportExtensionUnresponsive',
] as const
// Type guard
const isErrorType = (str: any): str is ErrorTypes => {
  return errorTypes.includes(str)
}

@Component({
  selector: 'app-teleport-error',
  templateUrl: './teleport-error.page.html',
})
export class TeleportErrorPage implements OnDestroy {
  destroy$ = new Subject<void>()
  isLoading: boolean = true
  errorType: ErrorTypes
  triggerRoute$ = new Subject<boolean>()

  constructor(router: Router, private route: ActivatedRoute, protected zendesk: ZendeskService) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        map(() => this.route.snapshot.queryParamMap.get('errorType')),
        takeUntil(this.destroy$),
      )
      .subscribe(async (errorType: string) => {
        this.errorType = isErrorType(errorType) ? errorType : 'General'
        this.isLoading = false
      })
  }
  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
