import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client'
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Team, User } from 'generated/graphql'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { TeamsService } from '../teams.service'

/**
 * Page to allow editing an existing team
 *
 * @export
 * @class TeamEditPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.page.html',
  styleUrls: ['./team-edit.page.scss'],
  host: { class: 'h-100' },
})
export class TeamEditPage implements OnInit, OnDestroy {
  isLoading: boolean = false
  isSaving: boolean = false
  routerSub$: Subscription
  editForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    supervisorUserId: new UntypedFormControl(undefined),
  })
  teamPod: {
    team: Team
    members: User[]
    supervisor: User
  } = { team: null, members: [], supervisor: null }
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public teamsService: TeamsService,
    private usersService: UsersService,
    private toast: ToastService,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger]) => event instanceof NavigationEnd || trigger))
      .subscribe(() => {
        this.onRouteChange()
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  /**
   * Load an existing team and full user objects
   *
   * @return {*}  {Promise<void>}
   * @memberof TeamEditPage
   */
  async onRouteChange(): Promise<void> {
    this.isLoading = true
    let teamId = this.route.snapshot.paramMap.get('teamId')
    try {
      if (teamId) {
        let result: ApolloQueryResult<{ team: Team }> = await this.teamsService
          .getTeam(teamId)
          .pipe(first())
          .toPromise()
          .catch((e) => {
            throw new Error(e)
          })
        this.teamPod.team = result?.data?.team

        this.crumbs.setPageTitle('Admin - Teams - ' + this.teamPod.team?.name)

        if (this.teamPod?.team?.memberUserIds?.length) {
          this.teamPod.members = await Promise.all(
            this.teamPod.team.memberUserIds.map((id) =>
              this.usersService
                .getUser(id)
                .pipe(first())
                .toPromise()
                .catch((e) => null),
            ),
          )
        }
        if (this.teamPod?.team?.supervisorUserId) {
          this.teamPod.supervisor = await this.usersService
            .getUser(this.teamPod.team.supervisorUserId)
            .pipe(first())
            .toPromise()
            .catch((e) => null)
        }
        this.editForm.get('description').setValue(this.teamPod?.team?.description)
        this.editForm.get('supervisorUserId').setValue(this.teamPod?.team?.supervisorUserId)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load team'), JSON.stringify(e))
      this.router.navigate(['admin', 'teams'])
    }
    this.isLoading = false
  }

  /**
   * Launch modal to confirm deleting current team
   *
   * @memberof TeamEditPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete team?'
    modalRef.componentInstance.body = `Are you sure you want to delete this team?`
    modalRef.componentInstance.yes = 'Delete'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTeam()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current team
   *
   * @return {*}  {Promise<void>}
   * @memberof TeamEditPage
   */
  async deleteTeam(): Promise<void> {
    try {
      await this.teamsService.deleteTeam(this.teamPod?.team?.id)
      this.toast.success('Team successfully deleted')
      this.router.navigate(['admin/teams'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete team'), JSON.stringify(e))
    }
  }

  /**
   * Save edits to current team
   *
   * @return {*}  {Promise<void>}
   * @memberof TeamEditPage
   */
  async saveTeam(): Promise<void> {
    this.isSaving = true
    try {
      let team = {
        name: this.teamPod?.team?.name,
        description: this.editForm.get('description')?.value,
        supervisorUserId: this.editForm.get('supervisorUserId')?.value,
        memberUserIds: this.teamPod?.members?.map((member) => member?.id),
      }
      await this.teamsService.updateTeam(this.teamPod?.team?.id, team)
      this.toast.success('Team successfully updated')
      this.router.navigate(['admin/teams'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not update team'), JSON.stringify(e))
    }
    this.isSaving = false
  }

  /**
   * Remove a user from the current team
   *
   * @param {string} userId
   * @return {*}  {Promise<void>}
   * @memberof TeamEditPage
   */
  async removeTeamMember(userId: string): Promise<void> {
    try {
      await this.teamsService.removeMemberFromTeam(userId, this.teamPod?.team?.id)
      let removedUser = this.teamPod.members.findIndex((member) => member?.id === userId)
      this.teamPod.members.splice(removedUser, 1)
      this.toast.success('User successfully removed from team')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not remove user from team'), JSON.stringify(e))
    }
  }

  /**
   * Add user to the current team
   *
   * @param {User} user
   * @param {NgbPopover} popover
   * @return {*}  {Promise<void>}
   * @memberof TeamEditPage
   */
  async addTeamMember(user: User, popover: NgbPopover): Promise<void> {
    if (user?.id) {
      let exists = this.teamPod.members.findIndex((member) => member?.id === user?.id)
      if (exists != -1) {
        this.toast.error('User already exists on team', 'user exists on team')
        popover.close()
        return
      }
      try {
        await this.teamsService.addMemberToTeam(user?.id, this.teamPod?.team?.id)
        this.teamPod.members.push(user)
        popover.close()
        this.toast.success('User successfully added to team')
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not add user to team'), JSON.stringify(e))
      }
    }
  }

  /**
   * Set the supervisor of the current team
   *
   * @param {User} user
   * @memberof TeamEditPage
   */
  updateSupervisor(user: User): void {
    if (user?.id) {
      this.editForm.get('supervisorUserId').setValue(user.id)
    }
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }
}
