import { Pipe, PipeTransform } from '@angular/core'
import { get, uniq } from 'lodash'

/**
 * Angular pipe to allow getting the value at path of object
 * - mimics lodash's get
 *
 * @export
 * @class GetPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'get',
})
export class GetPipe implements PipeTransform {
  transform(value: unknown, prop: string | string[], safetyValue: string): string {
    let parent = get(value, Array.isArray(prop) ? prop[0] : prop, safetyValue)
    if (Array.isArray(parent)) {
      return uniq(parent.map((p, index) => get(p, prop[1], safetyValue))).join(', ')
    } else if (typeof parent === 'object' && parent !== null && Array.isArray(prop)) {
      let newProp = prop.slice()
      newProp.splice(0, 1)
      return this.transform(parent, newProp, safetyValue)
    }

    return parent || safetyValue
  }
}
