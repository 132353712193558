<div class="card my-1 p-0 border-0 bg-transparent">
  <div class="card-header border-0 bg-primary text-white">Upload Report</div>
  <div class="card-body mt-2 px-5 bg-white shadow">
    <form [formGroup]="form" (ngSubmit)="uploadFile()" class="d-flex flex-column px-4">
      <div class="row my-3">
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="name"
              id="name"
              [appFcBsValidation]="form.get('name')"
            />
            <small class="text-danger" *ngIf="form.get('name').touched && form.get('name').invalid">
              Field is required
            </small>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-6 col-xl-4">
          <div class="form-group">
            <label for="category">Category</label>
            <input
              type="text"
              class="form-control"
              formControlName="category"
              id="category"
              [appFcBsValidation]="form.get('category')"
            />
            <small class="text-danger" *ngIf="form.get('category').touched && form.get('category').invalid">
              Field is required
            </small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xl-4">
          <label for="report">Report</label>
          <div class="custom-file">
            <input
              id="report"
              type="file"
              class="custom-file-input"
              formControlName="report"
              [appFcBsValidation]="form.get('report')"
            />
            <label class="custom-file-label" for="report">Choose Report</label>
          </div>
          <small class="text-danger" *ngIf="form.get('report').touched && form.get('report').invalid">
            Upload is required
          </small>
        </div>
      </div>
      <div class="row mt-5">
        <div class="d-flex justify-content-end col-md-6 col-xl-4">
          <a class="btn btn-cancel mx-1" routerLink="/reports">Cancel</a>
          <button type="submit" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-report-button">
            <i class="fa fa-floppy-o mr-3"></i>
            Save
            <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
