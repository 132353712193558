<div class="card h-100 mx-3 px-5 py-3 border-grey bg-light">
  <div class="row p-3">
    <div class="col-xl-4 font-weight-bold">Linked Claim</div>
    <div class="col-xl-8">
      <a routerLink="/claims/{{ botRun?.providerClaimId }}" data-t="bot-run-linked-claim">
        {{ botRun?.providerClaimId }}
      </a>
    </div>
  </div>
  <div class="row p-3">
    <div class="col-xl-4 font-weight-bold">Date & Time</div>
    <div class="col-xl-8">
      {{ botRun?.createdAt ? (botRun.createdAt | date: 'MM/dd/yyyy hh:mm a') : 'Bot run has no created date' }}
    </div>
  </div>
  <div class="row p-3">
    <div class="col-xl-4 font-weight-bold">Status</div>
    <div
      class="col-xl-8"
      [ngClass]="{
        'text-success': botRun?.status === 'SUCCEEDED',
        'text-danger': botRun?.status === 'FAILED',
        'text-brand-light-blue': botRun?.status === 'RUNNING',
        'text-warning':
          botRun?.status === 'UNSTABLE' || botRun?.status === 'MISSING_DATA' || botRun?.status === 'CLAIM_NOT_FOUND',
        'text-secondary':
          botRun?.status !== 'SUCCEEDED' &&
          botRun?.status !== 'FAILED' &&
          botRun?.status !== 'RUNNING' &&
          botRun?.status !== 'UNSTABLE' &&
          botRun?.status !== 'MISSING_DATA' &&
          botRun?.status !== 'CLAIM_NOT_FOUND' &&
          !lateQueuedRun
      }"
    >
      {{ (botRun?.status === 'CLAIM_NOT_FOUND' ? 'claim not found' : botRun?.status?.replace('_', ' ')) | titlecase }}
    </div>
  </div>
  <div class="row p-3">
    <div class="col-xl-4 font-weight-bold">Primary Result</div>
    <div class="col-xl-8">
      {{ botRun?.primaryResult }}
    </div>
  </div>
  <div class="row p-3">
    <div class="col-xl-4 font-weight-bold">Secondary Result</div>
    <div class="col-xl-8">
      {{ botRun?.secondaryResult }}
    </div>
  </div>
</div>
