import { Injectable } from '@angular/core'
import { AnalyticsBaseService } from './_analytics.base.service'
import { HttpRequest } from '@angular/common/http'
import LogRocket from 'logrocket'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'
import { User } from 'generated/graphql'
import { distinctUntilChanged } from 'rxjs/operators'
import { Router } from '@angular/router'
import { debug } from 'app/shared/utils/debug'

/**
 * @prop tags - Additional data to be grouped as "tags".
 * @prop extra - Additional arbitrary data associated with the event.
 */
interface LogRocketErrorData {
  tags?: any
  extra?: any
}

/**
 * Service which implements the LogRocket SDK for an analytics provider.
 *
 * Documentation: https://docs.logrocket.com/reference
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends AnalyticsBaseService {
  private loaded = false

  /**
   * Replacement list for URLs sent to and stored by LogRocket
   * @example [/secret_key=([^&]*)/, 'secret_key=**redacted**']
   */
  readonly urlReplacements: [RegExp, string][] = []

  /**
   * Excludes specified GraphQL operations from being logged.
   */
  readonly gqlExcludeOperations: string[] = ['GetFeatureFlag', 'GetFeatureFlags', 'GetMe']
  /**
   * Excludes specified API endpoints from being logged.
   */
  readonly apiExcludes: string[] = ['timeout', 'logo']

  /**
   * The attribute selector used to exclude elements from session recordings.
   * @see https://docs.logrocket.com/reference/dom
   */
  readonly privateAttribute: string = 'data-private'

  /**
   * This member should be used to access LogRocket SDK.
   * If it isn't initialized, then we don't need to access the SDK at all.
   */
  get logRocket(): typeof LogRocket {
    if (this.loaded) return LogRocket

    debug('analytics', 'LogRocket inaccessible: failed to start.')
    return {
      track: () => {},
      identify: () => {},
      captureException: () => {},
    } as any
  }

  constructor(router: Router) {
    super(router)
    this.initialize()
  }
  async initialize(): Promise<void> {
    debug(`env`, `Deployed Build: ${environment.name}`)
    if (environment.analytics.logRocketAppId) {
      try {
        LogRocket.init(environment.analytics.logRocketAppId, {
          ingestServer: environment.analytics.ingestServer || undefined,
          dom: {
            textSanitizer: false, // the following attributes: mailto(in an href), alt, title, aria-label, and aria-description.
            inputSanitizer: 'lipsum',
          },
          mergeIframes: true,
          network: {
            requestSanitizer: this.networkSanitizer.bind(this),
          },
          browser: {
            urlSanitizer: this.urlSanitizer.bind(this),
          },
        })
        this.loaded = true
      } catch (ex) {
        debug('analytics', 'cannot init LogRocket', ex)
      }
    }
  }

  /**
   * Sends API requests to LogRocket.
   */
  onApiRequest(req: HttpRequest<any>): void {
    if (!this.apiExcludes.find((path) => req.url.includes(path))) {
      this.event(`API: ${req.url}`)
    }
  }

  /**
   * Sends GraphQL requests to LogRocket.
   */
  onGqlRequest(req: HttpRequest<any>): void {
    req.body
      .map((itm) => itm.operationName)
      .filter((op) => !!op && !this.gqlExcludeOperations.find((gql) => gql === op))
      .forEach((op) => {
        this.event(`GQL: ${op}`)
      })
  }

  /**
   * Filters out network requests that should not be sent to LogRocket.
   */
  networkSanitizer(request: HttpRequest<any>): any {
    // Example of how you could filter and not send the request to LogRocket
    // if (request.url.toLowerCase().indexOf('ignore') !== -1) {
    //   return null
    // }
    return request
  }

  /**
   * Modifies urls that are sent to LogRocket.
   */
  urlSanitizer(url: string): any {
    let sanitizedUrl = url
    this.urlReplacements.forEach((pair) => {
      sanitizedUrl = sanitizedUrl.replace(pair[0], pair[1])
    })
    return sanitizedUrl
  }

  /**
   * Send a custom event or action from a user.
   * @param key Lookup key used from the LogRocket Dashboard
   * @param obj Must contain only primitive values or arrays of primitives. No objects.
   */
  event<T = any>(key: string, obj?: T): void {
    this.logRocket.track(key, obj as any)
  }

  /**
   * Send errors to LogRocket.
   * @param err Error exception object
   * @param data Optional data to send to LogRocket
   */
  error(err: Error, data?: LogRocketErrorData): void {
    this.logRocket.captureException(err, data)
  }

  /**
   * Monitors user change so LogRocket knows who to apply metrics to.
   */
  watchUser(obs: BehaviorSubject<User>): void {
    obs.pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)).subscribe((v) => {
      if (!v) {
        this.logRocket.identify(null)
      } else {
        this.logRocket.identify(v.id, {
          screenWidth: window.screen?.width,
          screenHeight: window.screen?.height,
          ...v,
          __typename: undefined,
          allowedOrgIds: (v?.allowedOrgIds || []).join(', '),
        })
      }
    })
  }
}
