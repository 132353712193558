<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar h-100">
    <div class="row no-gutters h-100">
      <div class="card col-md-6 my-1 p-0 border-0 bg-transparent px-2">
        <div class="card-header border-0 bg-primary text-white">{{payer?.id ? 'Edit Payer Details' : 'Add Payer'}}</div>
        <div class="card-body mt-2 px-5 bg-white shadow">
          <form [formGroup]="form" (ngSubmit)="savePayer()" class="d-flex flex-column px-4">
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="name"
                    id="name"
                    [appFcBsValidation]="form.get('name')"
                  />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="address">Address</label>
                  <input type="text" class="form-control" formControlName="address" id="address" />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="siteUrl">Site URL</label>
                  <input type="text" class="form-control" formControlName="siteUrl" id="siteUrl" />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="payerId">National ID</label>
                  <input type="text" class="form-control" formControlName="payerId" id="payerId" />
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col d-flex justify-content-end">
                <a class="btn btn-cancel mx-1" routerLink="/admin/payers"> Cancel </a>
                <button type="submit" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-payer-button">
                  <i class="fa fa-floppy-o mr-3"></i>
                  Save
                  <app-loading *ngIf="saving" class="pl-2"></app-loading>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card col-md-6 my-1 p-0 border-0 bg-transparent px-2">
        <div class="card-header border-0 bg-primary text-white">Combined Payers</div>
        <div class="card-body mt-2 px-5 bg-white shadow">
          <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
            <table class="table table-janus table-striped" data-t="payers-table">
              <thead>
                <tr>
                  <th scope="col" class="width-1"><span class="sr-only"> Payer Site URL</span></th>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Address</th>
                  <th scope="col" class="text-left">National ID</th>
                </tr>
              </thead>
              <tbody *ngIf="childrenPayers?.length">
                <tr *ngFor="let payer of childrenPayers" data-t="payer-row">
                  <td>
                    <a href="{{payer?.siteUrl}}" target="_blank" class="text-nowrap" *ngIf="payer?.siteUrl">
                      <i class="fa fa-globe"></i>
                    </a>
                  </td>
                  <td><a routerLink="/admin/payers/{{ payer?.id }}" class="text-nowrap"> {{ payer?.name }} </a></td>
                  <td>{{payer?.address}}</td>
                  <td>{{payer?.payerId}}</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!childrenPayers?.length" class="d-flex justify-content-center alert alert-danger" role="alert">
              Payer has no combined payers.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
