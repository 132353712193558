import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TeamsService } from 'app/admin/teams/teams.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { User } from 'generated/graphql'

/**
 * Modal to allow creating a new team
 *
 * @export
 * @class CreateTeamComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
})
export class CreateTeamComponent implements OnInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl(''),
    supervisorUserId: new UntypedFormControl(),
    members: new UntypedFormControl([]),
  })
  saving: boolean = false

  constructor(public activeModal: NgbActiveModal, private toast: ToastService, private teamsService: TeamsService) {}

  ngOnInit(): void {}

  /**
   * Create new team
   *
   * @return {*}  {Promise<void>}
   * @memberof CreateTeamComponent
   */
  async saveNewTeam(): Promise<void> {
    this.saving = true
    try {
      let memberIds = this.form.get('members').value.map((user) => user?.id)
      let data = {
        name: this.form.get('name').value,
        description: this.form.get('description').value,
        supervisorUserId: this.form.get('supervisorUserId').value,
        memberUserIds: memberIds,
      }
      let newTeam = await this.teamsService.createTeam(data)
      this.activeModal.close(newTeam)
      this.toast.success('Team created')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not create team'), JSON.stringify(e))
    }
    this.saving = false
  }

  /**
   * Add user to list of new team members
   *
   * @param {User} user
   * @memberof CreateTeamComponent
   */
  addMember(user: User): void {
    if (user?.id) {
      let members = this.form.get('members').value
      members.push(user)
      this.form.get('members').setValue(members)
    }
  }

  /**
   * Remove user from list of new team members
   *
   * @param {User} user
   * @memberof CreateTeamComponent
   */
  removeMember(user: User): void {
    let memberIds = this.form.get('members').value
    let i = memberIds.findIndex((u) => u.id === user.id)
    if (i >= 0) {
      memberIds.splice(i, 1)
    }
    this.form.get('members').setValue(memberIds)
  }

  /**
   * Set supervisor for new team
   *
   * @param {User} user
   * @memberof CreateTeamComponent
   */
  addSupervisor(user: User): void {
    if (user?.id) {
      this.form.get('supervisorUserId').setValue(user.id)
    }
  }
}
