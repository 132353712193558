import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { Subscription } from 'rxjs'
import { ToastService } from 'app/shared/services/toast.service'

/**
 * Page that a user is sent to via the activation email
 *
 * @export
 * @class NewPasswordPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-activate-page',
  templateUrl: './activate.page.html',
})
export class ActivatePage implements OnInit, OnDestroy {
  token: string = null
  error: boolean = false
  param: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.param = this.route.paramMap.subscribe(async (params) => {
      this.token = params.get('token')
      try {
        await this.authenticationService.activateByToken(this.token)

        this.toastService.success('User Activated')
        this.router.navigate(['login'])
      } catch {
        this.error = true
      }
    })
  }

  ngOnDestroy(): void {
    this.param.unsubscribe()
  }
}
