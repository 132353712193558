<div class="bg-brand-gray-low">
  <div class="d-flex border-top border-bottom border-alt-light-gray">
    <div
      class="w-8 my-2 ml-2 text-center py-2 border-4"
      [class]="'bg-' + phaseColor + '-high' + ' border-' + phaseColor"
    >
      Phase {{ phase }}
    </div>
    <div class="w-100 my-2 mr-2 p-2 text-white" [class]="'bg-' + phaseColor">{{ phaseDescription }}</div>
  </div>
  <div class="row">
    <div class="col pl-24-per">
      <app-table-item
        [baseLine]="opportunity?.monthlyWorkflowYield?.baseLine"
        [otherPhase]="opportunity?.monthlyWorkflowYield[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        [hasSecondChild]="!!phaseKey"
        view="monthly"
      ></app-table-item>
    </div>
    <div class="col">
      <app-table-item
        [baseLine]="opportunity?.monthlyCostToCollect?.baseLine"
        [otherPhase]="opportunity?.monthlyCostToCollect[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        view="average"
        [isLowerBetter]="true"
        [hasSecondChild]="!!phaseKey"
        [showDecimals]="true"
      ></app-table-item>
    </div>
    <div class="col">
      <app-table-item
        [baseLine]="opportunity?.monthlyCostToCollect?.baseLine"
        [otherPhase]="opportunity?.monthlyCostToCollect[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        [isLowerBetter]="true"
        [hasSecondChild]="!!phaseKey"
        view="monthly"
      ></app-table-item>
    </div>
    <div class="col">
      <app-table-item
        [baseLine]="opportunity?.averagePaymentLag?.baseLine"
        [otherPhase]="opportunity?.averagePaymentLag[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        view="average"
        [isLowerBetter]="true"
        units="days"
        [hasSecondChild]="!!phaseKey"
      ></app-table-item>
    </div>
    <div class="col">
      <app-table-item
        [baseLine]="opportunity?.monthlyNetProfitabilityOfCollection?.baseLine"
        [otherPhase]="opportunity?.monthlyNetProfitabilityOfCollection[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        view="average"
        [hasSecondChild]="!!phaseKey"
        [showDecimals]="true"
      ></app-table-item>
    </div>
    <div class="col">
      <app-table-item
        [baseLine]="opportunity?.monthlyNetProfitabilityOfCollection?.baseLine"
        [otherPhase]="opportunity?.monthlyNetProfitabilityOfCollection[phaseKey]"
        [processVolume]="opportunity?.processVolume"
        [hasSecondChild]="!!phaseKey"
        view="monthly"
      ></app-table-item>
    </div>
  </div>
</div>
