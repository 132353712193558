import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet, Selected } from 'app/shared/types/facet'
import { find } from 'lodash'
import { Subscription } from 'rxjs'
import { pairwise, startWith } from 'rxjs/operators'

/**
 * Component to display a comparison facet for claim properties
 * - must have script defined by API
 *
 * @export
 * @class ComparisonFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-faceted-search-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss'],
})
export class ComparisonFacetComponent implements OnInit, OnDestroy {
  @Input() data: Facet
  isCollapsed = false
  selectedOperator = new UntypedFormControl()
  selectedOperatorSub$: Subscription

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.selectedOperatorSub$ = this.selectedOperator.valueChanges
      .pipe(startWith(''), pairwise())
      .subscribe(([oldValue, newValue]) => {
        if (oldValue) {
          this.facetService.removeFromFacet(
            this.data.id,
            find(this.data.options, (o) => o.value === oldValue),
          )
        }
        let selected = this.data?.options?.find((selected: Selected) => selected.value === newValue)
        this.facetService.addToFacet(this.data.id, selected)
      })
  }

  ngOnDestroy(): void {
    this.selectedOperatorSub$?.unsubscribe()
  }

  /**
   * Remove this facet
   *
   * @memberof ComparisonFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
