import { Component } from '@angular/core'

/**
 * Component to layout a well
 *
 * @export
 * @class WellComponent
 */
@Component({
  selector: 'app-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss'],
  host: {
    class: 'd-block py-2',
  },
})
export class WellComponent {
  constructor() {}
}
