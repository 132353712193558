import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ClaimRowConfig, ProcedureSetRowConfig, TeleportService } from 'app/teleport/teleport.service'
import { Subject, of } from 'rxjs'
import { catchError, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-teleport-link-table-row',
  templateUrl: './teleport-link-table-row.component.html',
  styleUrls: ['./teleport-link-table-row.component.scss'],
  host: {
    class: 'no-wrapper-box',
  },
})
export class TeleportLinkTableRowComponent implements OnInit {
  @Input() config: ProcedureSetRowConfig | ClaimRowConfig

  @Output()
  onLoaded: EventEmitter<{
    listId: number
    canTeleport: boolean
  }> = new EventEmitter()

  // Used to indicate that we're still checking if the row is teleportable
  isTest: boolean = false
  isLoading: boolean = true
  tooltip: string = 'Checking if Teleport is currently supported for this payer'
  teleportUrl: string = ''

  // Type Casing hacks for use with "as" pipe
  ProcedureSetRowConfig = {} as ProcedureSetRowConfig
  ClaimRowConfig = {} as ClaimRowConfig

  private destroy$ = new Subject<void>()

  constructor(private teleportService: TeleportService, private toast: ToastService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.teleportService
      .getTeleportUrlForEntity(this.config.rowType, this.config.typeDef.id, this.config.botType, this.config.isTest)
      .pipe(
        catchError((e) => {
          this.toast.error(parseGraphQLError(e, 'Could not find valid teleport job'), JSON.stringify(e))
          return of(undefined)
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((teleportUrl: string) => {
        this.isTest = this.config.isTest
        this.tooltip = teleportUrl ? 'Launch Teleport' : 'Teleport is not currently supported for this payer'
        this.teleportUrl = teleportUrl
        this.isLoading = false
        this.onLoaded.emit({
          canTeleport: !!teleportUrl,
          listId: this.config.listId,
        })
      })
  }

  launchTeleport(): void {
    this.teleportService.launchTeleport({
      url: this.teleportUrl,
      refType: this.config.rowType,
      refId: this.config.typeDef.id,
      botType: this.config.botType,
      payer: this.config.botPayer || this.config.payer?.name,
      portal: this.config.portal,
      isTest: this.config.isTest,
      reuseTab: this.config.reuseTab,
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
