<div class="modal-header d-flex justify-content-between align-items-center">
  <h4 class="modal-title">Create a team</h4>
  <button type="button" class="close mx-2" aria-label="Close" (click)="activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="saveNewTeam()" novalidate>
  <div class="modal-body p-4">
    <div class="mt-2 mb-4">
      <span>Please enter team details below</span>
    </div>
    <label class="sr-only" for="name"> Team Name </label>
    <input
      type="text"
      class="form-control col-md-6 mt-5"
      formControlName="name"
      required
      data-t="team-name"
      placeholder="Team Name"
      id="name"
    />
    <div class="mt-5">
      <label class="text-muted"> Team Description </label>
      <textarea
        formControlName="description"
        class="form-control"
        cols="25"
        rows="5"
        placeholder="Add a team description"
      ></textarea>
    </div>
    <div class="mt-5 row">
      <label class="text-muted col-sm-4"> Supervisor </label>
      <div class="col-sm-8">
        <app-user-search (onSelect)="addSupervisor($event)" [clearInput]="false"> </app-user-search>
      </div>
    </div>
    <div class="mt-5 row">
      <label class="text-muted col-sm-4"> Team Members </label>
      <div class="col-sm-8">
        <app-user-search (onSelect)="addMember($event)"> </app-user-search>
        <ul class="list-unstyled my-4 height-restrict">
          <li *ngFor="let user of form.get('members').value" class="text-dark">
            <span>
              <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeMember(user)"></i>
              {{ user?.name || user?.email }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="activeModal.close('Close click')"
      data-t="create-team-cancel-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green pl-4 pr-5" data-t="create-team-save-button">
      <i class="fa fa-floppy-o mr-3"></i>
      Save
      <app-loading *ngIf="saving" class="pl-2"></app-loading>
    </button>
  </div>
</form>
