import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'

const INVALID_EMAIL_MESSAGE: string = 'The email address format you entered is not valid. Please try again.'
const REQUIRED_EMAIL_MESSAGE: string = 'Email is a required field'

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef
  @Output() onSubmit = new EventEmitter<string>()
  username = new UntypedFormControl(null, [Validators.required, Validators.pattern('\\S+[@].+.\\S+')])

  invalidMessage: string | null

  ngAfterViewInit(): void {
    this.input.nativeElement.focus()
  }

  warnErrors(): boolean {
    if (this.username.invalid && this.username.dirty) {
      const errors = this.username.errors
      if (errors['pattern']) {
        this.invalidMessage = INVALID_EMAIL_MESSAGE
      }

      if (errors['required']) {
        this.invalidMessage = REQUIRED_EMAIL_MESSAGE
      }

      return true
    }

    this.invalidMessage = null
    return false
  }

  /**
   * Alert user if username field is invalid, or send it
   *
   * @memberof UsernameComponent
   */
  submit(): void {
    this.username.markAllAsTouched()
    this.username.markAsDirty()
    if (this.warnErrors()) {
      return
    }
    this.onSubmit.emit(this.username.value)
  }
}
