import { Component, Input, OnInit } from '@angular/core'
import { TaskGroup, TaskGroupStats } from 'generated/graphql'

/**
 * Component to display the task group score
 *
 * @export
 * @class TaskGroupScoreComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-group-score',
  templateUrl: './task-group-score.component.html',
  styleUrls: ['./task-group-score.component.scss'],
})
export class TaskGroupScoreComponent implements OnInit {
  @Input() taskGroup: TaskGroup
  @Input() stats: TaskGroupStats

  constructor() {}

  ngOnInit(): void {}
}
