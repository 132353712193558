import { Component, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { SaturatedTaskComment, TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Task } from 'generated/graphql'
import { combineLatest, empty, Subject } from 'rxjs'
import { catchError, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-task-panel',
  templateUrl: './task-panel.component.html',
})
export class TaskPanelComponent implements OnChanges, OnDestroy {
  @Input() tasks: Task[] = null
  sortedTasks: Task[] = null

  isCollapsed: boolean[] = null
  currentTask: Task = null

  comments: SaturatedTaskComment[] = null
  loadingComments: boolean = false

  deleting: boolean = false

  destroy$ = new Subject<void>()

  constructor(
    private taskService: TasksService,
    private toast: ToastService,
    public authenticationService: AuthenticationService,
    public modal: NgbModal,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.destroy$.next()
    if (changes.tasks?.currentValue?.length !== changes.tasks?.previousValue?.length) {
      this.isCollapsed = new Array(this.tasks.length).fill(true)
    }
    this.sortTasks()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Load the selected task's comments
   *
   * @param {boolean} event
   * @param {string} taskId
   * @return {*}  {Promise<void>}
   * @memberof TaskPanelComponent
   */
  async loadComments(event: boolean, taskId: string, index: number): Promise<void> {
    this.currentTask = this.sortedTasks.find((t) => t.id === taskId)
    this.destroy$.next()
    this.isCollapsed[index] = event
    if (!event) {
      // opening! collapse others!
      for (let i = 0; i < this.isCollapsed.length; i++) {
        if (i !== index) this.isCollapsed[i] = true
      }
      try {
        await this.taskService.pickTask(taskId)
        this.taskService
          .getTaskComments(taskId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (event) => {
            this.loadingComments = event.loading
            this.comments = await this.taskService.saturateTaskComments(event.data.taskComments)
          })
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not load comments'), JSON.stringify(e))
      }
    } else {
      // closing!
      this.taskService.unpickAllTasks()
      this.comments = []
      if (index === -1) {
        this.sortTasks()
      }
    }
  }

  /**
   * Helper to sort tasks
   *
   * @private
   * @memberof TaskPanelComponent
   */
  private sortTasks(): void {
    combineLatest(
      this.taskService
        .sortTasks(this.tasks)
        .map((task) => this.taskService.getTask(task.id).pipe(catchError((task) => empty()))),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((results) => {
        this.sortedTasks = results.map((result) => result?.data?.task)
      })
  }

  /**
   * Launch modal to view the raw data of a task's bot run
   *
   * @param {*} modal
   * @memberof TaskPanelComponent
   */
  showRawData(modal: TemplateRef<NgbModal>): void {
    this.modal.open(modal, {
      centered: true,
      size: 'xl',
    })
  }

  /**
   * Launch modal to confirm deleting a task
   *
   * @param {Task} task
   * @memberof TaskPanelComponent
   */
  confirmTaskDelete(task: Task): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Task?'
    modalRef.componentInstance.body = `Task "${task.taskType.name}" will be deleted from this claim`
    modalRef.componentInstance.yes = 'Delete Task'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTask(task.id)
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete a task from the claim
   *
   * @param {string} taskId
   * @return {*}  {Promise<void>}
   * @memberof TaskPanelComponent
   */
  async deleteTask(taskId: string): Promise<void> {
    this.deleting = true
    try {
      let result = await this.taskService.deleteTasks([taskId])
      if (result[0].succeeded !== true) {
        throw new Error('API returned all successes as false')
      }
      this.toast.success(`Successfully deleted task on claim`)
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete task on claim'), JSON.stringify(e))
    }
    this.deleting = false
  }

  /**
   * Helper for performant ngFor
   *
   * @param {number} index
   * @param {Task} item
   * @return {*} {string | number}
   * @memberof TaskPanelComponent
   */
  trackById(index: number, item: Task): string | number {
    return item?.id || index
  }
}
