import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { BotJob, BotRun, TaskType } from 'generated/graphql'
import * as moment from 'moment'

/**
 * Component to display a bot's key details
 *
 * @export
 * @class BotKeyDetailsComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-bot-key-details',
  templateUrl: './bot-key-details.component.html',
  styleUrls: ['./bot-key-details.component.scss'],
})
export class BotKeyDetailsComponent implements OnInit, OnChanges {
  @Input() botJob: BotJob
  @Input() botRuns: BotRun[]
  @Input() taskType: TaskType
  successRate: number
  avgRunTime: number
  lastRun: Date

  constructor(private botRunsService: BotRunsService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    let ranBots = this.botRuns?.filter((botRun) => botRun.runStartAt && botRun.runEndAt)
    if (ranBots?.length) {
      this.successRate = this.botRunsService.getAverageSuccessRate(ranBots)
      this.lastRun = new Date(
        Math.max.apply(
          null,
          ranBots.map(function (bot) {
            return new Date(bot.runEndAt)
          }),
        ),
      )
    }
  }

  /**
   * Helper for formatting a bot run's average run time
   *
   * @readonly
   * @type {number}
   * @memberof BotKeyDetailsComponent
   */
  get avgBotRunTime(): number {
    let ranBots = this.botRuns?.filter((botRun) => botRun.runStartAt && botRun.runEndAt)
    if (ranBots) {
      let botRunTimes = ranBots.map((botRun) => moment(botRun.runEndAt).diff(moment(botRun.runStartAt), 'seconds'))
      let total = 0
      for (let runTime of botRunTimes) {
        if (runTime && runTime > 0) {
          total += Number(runTime)
        }
      }
      return Math.ceil(total / ranBots.length)
    }
  }
}
