import { Component, Input, OnInit } from '@angular/core'
import { getPropDetails } from 'app/shared/types/claim'
import { Serviceline } from 'generated/graphql'

/**
 * Component to display a claim's servicelines
 *
 * @export
 * @class ServicelinesComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-claim-servicelines',
  templateUrl: './claim-servicelines.component.html',
  styleUrls: ['./claim-servicelines.component.scss'],
})
export class ServicelinesComponent implements OnInit {
  @Input() servicelines: Serviceline[]

  getPropDetails = getPropDetails

  hasCodes = false

  constructor() {}

  ngOnInit(): void {
    if (this.servicelines?.length) {
      this.hasCodes = this.servicelines.some((s) => {
        return (
          s.payerStatusCodes?.length > 0 ||
          s.claimStatusCodes?.length > 0 ||
          s.categoryCodes?.length > 0 ||
          s.ehrStatusCodes?.length > 0 ||
          s.claimAdjustmentReasonCodes?.length > 0 ||
          s.remitAdviceRemarkCodes?.length > 0
        )
      })
    }
  }
}
