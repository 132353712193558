import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationStart, Router } from '@angular/router'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { SavedSearch } from 'generated/graphql'
import { of, Subscription } from 'rxjs'
import { catchError, debounceTime, filter, tap } from 'rxjs/operators'

/**
 * Component to show and allow searching a list of saved searches
 * I never want to write the word search again
 *
 * @export
 * @class SavedSearchSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-saved-search-search',
  templateUrl: './saved-search-search.component.html',
  styleUrls: ['./saved-search-search.component.scss'],
})
export class SavedSearchSearchComponent implements OnInit, OnDestroy {
  @Output() selectSearch = new EventEmitter<SavedSearch>()

  isCollapsed: boolean[] = []

  searchSearch = new UntypedFormControl('')
  searchSearchSub$: Subscription

  searches: SavedSearch[] = []
  total = 0

  searching: boolean = false

  routerSub$: Subscription

  constructor(private facetService: FacetedSearchService, private toast: ToastService, private router: Router) {
    this.routerSub$ = router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      let reloadSearch = this.router.getCurrentNavigation().extras?.state?.data?.reloadSearch
      if (reloadSearch) {
        this.searchSearch.updateValueAndValidity({ onlySelf: true, emitEvent: true })
      }
    })
  }

  async ngOnInit(): Promise<void> {
    await this.searchSearches()
    this.searchSearchSub$ = this.searchSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap(async (term) => await this.searchSearches(term)),
        catchError((e) => {
          return of([])
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.searchSearchSub$?.unsubscribe()
    this.routerSub$?.unsubscribe()
  }

  /**
   * Get list of saved searches
   * I wrote search again!!!!!
   *
   * @param {string} [term]
   * @return {*}  {Promise<void>}
   * @memberof SavedSearchSearchComponent
   */
  async searchSearches(term?: string): Promise<void> {
    this.searching = true
    try {
      this.searches = await this.facetService.searchSavedSearches(term)
      this.total = this.facetService.getMeta().total
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find saved searches'), JSON.stringify(e))
    }

    this.searching = false
    this.isCollapsed = new Array(this.searches.length).fill(true)
  }
}
