import { Clipboard } from '@angular/cdk/clipboard'
import { Component, Input, OnInit } from '@angular/core'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { debug } from 'app/shared/utils/debug'
import { TeleportService } from 'app/teleport/teleport.service'
import { BotJob, Claim } from 'generated/graphql'
import { isEqual } from 'lodash'
import { take } from 'rxjs/operators'
import { BotJobsService } from '../../../admin/bot-jobs/bot-jobs.service'
import { AuthorizationService } from '../../../auth/authorization.service'
import { ToastService } from '../../services/toast.service'
import { Router } from '@angular/router'

/**
 * Component to display a button that launches Teleport
 *
 * @export
 * @class TeleportButtonComponent
 */
@Component({
  selector: 'app-teleport-button',
  templateUrl: './teleport-button.component.html',
  styleUrls: ['./teleport-button.component.scss'],
  host: {
    class: 'd-flex',
  },
})
export class TeleportButtonComponent implements OnInit {
  private _claim: Claim = null
  private botjob: BotJob = null
  @Input()
  set claim(val: Claim) {
    if (!isEqual(val, this._claim)) {
      this._claim = val
      this.getTeleportUrl()
      this.getBotJob()
      this.setIsVisible()
    }
  }
  get claim(): Claim {
    return this._claim
  }

  isDemoEnabled: boolean = false
  teleportUrl: string = ''
  isVisible: boolean = false
  isCopyBtnVisible: boolean = false
  isLoading: boolean = false

  constructor(
    private teleportService: TeleportService,
    private featureFlagService: FeatureFlagService,
    private botjobsService: BotJobsService,
    private clipboard: Clipboard,
    private authService: AuthorizationService,
    private toast: ToastService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.demo).then((demoFeatureFlag) => {
      this.isDemoEnabled = demoFeatureFlag
    })
  }

  onClick(): void {
    this.teleportService.launchTeleport({
      url: this.teleportUrl,
      refId: this._claim.id,
      refType: 'claim',
      portal: this.botjob.portal,
      payer: this.botjob.payer?.toLowerCase(),
      botType: 'getClaimStatus',
      reuseTab: this.botjob.reuseTab,
    })
  }

  onCopyClick(): void {
    try {
      const searchParams = new URL(this.teleportUrl).searchParams
      const copyContent = {
        url: this.teleportUrl,
        ...Object.fromEntries(searchParams),
      }
      const copySuccess = this.clipboard.copy(JSON.stringify(copyContent, null, '\t'))
      if (copySuccess) {
        this.toast.success('Teleport params copied to clipboard!')
      } else {
        throw new Error('Error occurred copying Teleport params to clipboard')
      }
    } catch (err) {
      this.toast.error('Issue occurred copying Teleport params to clipboard')
    }
  }

  setIsVisible(): void {
    if (!this._claim) {
      return
    }

    this.botjobsService
      .botJobFromClaim({ claim: this._claim })
      .pipe(take(1))
      .subscribe((botjob) => (this.botjob = botjob))

    this.teleportService
      .IsTeleportEnabled('claim', this.claim.id)
      .pipe(take(1))
      .subscribe((teleportIsEnabled) => {
        this.isVisible = !this.isDemoEnabled && teleportIsEnabled
        this.isCopyBtnVisible = this.isVisible && this.authService.isSuper()
      })
  }

  getBotJob(): void {
    if (!this._claim) {
      return
    }
    this.botjobsService
      .botJobFromClaim({ claim: this._claim })
      .pipe(take(1))
      .subscribe((botjob) => (this.botjob = botjob))
  }

  getTeleportUrl(): void {
    if (!this._claim) {
      return
    }

    this.teleportService
      .getTeleportUrlForEntity('claim', this.claim.id)
      .pipe(take(1))
      .subscribe(
        (url) => {
          this.teleportUrl = url
        },
        (err) => {
          debug('teleportButton', 'Could not fetch teleport URL', JSON.stringify(err))
        },
      )
  }
}
