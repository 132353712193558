import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ClaimProperties, PropertyDef } from 'app/shared/types/claim'

/**
 * Modal to allow changing the columns displayed on the claims list
 *
 * @export
 * @class ClaimListColumnsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-claim-list-columns',
  templateUrl: './claim-list-columns.component.html',
  styleUrls: ['./claim-list-columns.component.scss'],
})
export class ClaimListColumnsComponent implements OnInit {
  @Input() selected: PropertyDef[]
  possible = ClaimProperties.slice()

  form = new UntypedFormGroup({
    columns: new UntypedFormArray([]),
  })

  /**
   * Helper to get a formcontrol as a formarray
   *
   * @readonly
   * @type {FormArray}
   * @memberof ClaimListColumnsComponent
   */
  get checksFromArray(): UntypedFormArray {
    return this.form.get('columns') as UntypedFormArray
  }

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.selected?.length) {
      this.possible
        .sort((a, b) => a.order - b.order)
        .forEach((option: PropertyDef) => {
          if (option.property === 'providerClaimId') {
            this.checksFromArray.push(new UntypedFormControl({ value: true, disabled: true }))
          } else {
            let checked = this.selected?.find((s) => s.property === option.property) ? true : false
            this.checksFromArray.push(new UntypedFormControl(checked))
          }
        })
    }
  }

  /**
   * Close modal with the set of selected columns
   *
   * @memberof ClaimListColumnsComponent
   */
  changeColumns(): void {
    if (!this.form.valid) {
      return
    }

    this.modal.close(this.possible.filter((prop, index) => this.checksFromArray.controls[index].value))
  }
}
