import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  Annotate,
  CreateIlTask,
  GetAnnotations,
  GetIlTasks,
  GetIlWorkflowGroup,
  GetIlWorkflowGroups,
  UpdateObserveRecordAnnotated,
} from 'app/annotations/annotations.gql'
import {
  IlTask,
  IlTaskList,
  IlWorkflowGrouping,
  IlWorkflowGroupingList,
  MutationCreateIlTaskArgs,
  MutationObserveAnnotateArgs,
  MutationUpdateObserveRecordAnnotatedArgs,
  ObserveRecordAnnotated,
  ObserveRecordAnnotatedInput,
  QueryIlTasksArgs,
  QueryIlWorkflowGroupingArgs,
  QueryIlWorkflowGroupsArgs,
  QueryPickObserveRecordGroupArgs,
} from 'generated/graphql'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AnnotationsService {
  constructor(private apollo: Apollo) {}

  /**
   * Fetch a single workflow grouping.
   *
   * @param id Workflow grouping ID
   */
  getWorkflowGroup(id: string): Observable<ApolloQueryResult<{ ilWorkflowGrouping: IlWorkflowGrouping }>> {
    return this.apollo.watchQuery<{ ilWorkflowGrouping: IlWorkflowGrouping }, QueryIlWorkflowGroupingArgs>({
      query: GetIlWorkflowGroup,
      variables: {
        id,
      },
    }).valueChanges
  }

  /**
   * Fetch a list of Illuminate Workflow Groupings by name
   *
   * @param {string} [search]
   * @return {*}  {Observable<ApolloQueryResult<{ ilWorkflowGroups: IlWorkflowGroupingList }>>}
   * @memberof AnnotationsService
   */
  getWorkflowGroups(search?: string): Observable<ApolloQueryResult<{ ilWorkflowGroups: IlWorkflowGroupingList }>> {
    return this.apollo.watchQuery<{ ilWorkflowGroups: IlWorkflowGroupingList }, QueryIlWorkflowGroupsArgs>({
      query: GetIlWorkflowGroups,
      variables: {
        search: search || '',
        offset: 0,
        limit: 10,
      },
    })?.valueChanges
  }

  /**
   * Fetch all Observe Record Annotated entities in Worklfow Grouping by its Id
   *
   * @param {string} workflowGroupingId
   * @return {*}  {Promise<ObserveRecordAnnotated[]>}
   * @memberof AnnotationsService
   */
  pickRecords(
    workflowGroupingId: string,
  ): Observable<ApolloQueryResult<{ pickObserveRecordGroup: ObserveRecordAnnotated[] }>> {
    return this.apollo.watchQuery<
      { pickObserveRecordGroup: ObserveRecordAnnotated[] },
      QueryPickObserveRecordGroupArgs
    >({
      query: GetAnnotations,
      variables: {
        workflowGroupingId: workflowGroupingId,
      },
    }).valueChanges
  }

  /**
   * Save a single Observe Record Annotated entity
   *
   * @param {{
   *     id: string
   *     isStart: boolean
   *     isEnd: boolean
   *     isRejected: boolean
   *     taskId: string
   *   }} data
   * @return {*}  {Promise<ObserveRecordAnnotated>}
   * @memberof AnnotationsService
   */
  async annotate(data: {
    id: string
    isStart: boolean
    isEnd: boolean
    isRejected: boolean
    taskId: string
  }): Promise<ObserveRecordAnnotated> {
    let result = await this.apollo
      .mutate<{ observeAnnotate: ObserveRecordAnnotated }, MutationObserveAnnotateArgs>({
        mutation: Annotate,
        variables: {
          data: data,
        },
      })
      .toPromise()

    return result.data.observeAnnotate
  }

  /**
   * Fetch a list of Illuminate Tasks by name
   *
   * @param {string} [search]
   * @return {*}  {Observable<ApolloQueryResult<{ ilTasks: IlTaskList }>>}
   * @memberof AnnotationsService
   */
  getTasks(search?: string): Observable<ApolloQueryResult<{ ilTasks: IlTaskList }>> {
    return this.apollo.watchQuery<{ ilTasks: IlTaskList }, QueryIlTasksArgs>({
      query: GetIlTasks,
      variables: {
        search: search || '',
        offset: 0,
        limit: 10,
      },
    })?.valueChanges
  }

  /**
   * Create a new Illuminate Task
   *
   * @param {string} name
   * @return {*}  {Promise<IlTask>}
   * @memberof AnnotationsService
   */
  async createTask(name: string): Promise<IlTask> {
    let result = await this.apollo
      .mutate<{ createIlTask: IlTask }, MutationCreateIlTaskArgs>({
        mutation: CreateIlTask,
        variables: {
          data: { name: name },
        },
      })
      .toPromise()

    return result?.data?.createIlTask
  }

  /**
   * Update an annotated observe record.
   *
   * @param id Record ID
   * @param updateRecord Update data
   */
  async updateObserveRecordAnnotated(
    originalRecord: ObserveRecordAnnotated,
    updateRecord: ObserveRecordAnnotatedInput,
  ): Promise<ObserveRecordAnnotated> {
    const result = await this.apollo
      .mutate<{ updateObserveRecordAnnotated: ObserveRecordAnnotated }, MutationUpdateObserveRecordAnnotatedArgs>({
        mutation: UpdateObserveRecordAnnotated,
        variables: {
          data: updateRecord,
          id: originalRecord.id,
        },
        optimisticResponse: { updateObserveRecordAnnotated: Object.assign(cloneDeep(originalRecord), updateRecord) },
        update: (store, { data: { updateObserveRecordAnnotated } }) => {
          store.modify({
            id: store.identify({ id: originalRecord.id, __typename: 'ObserveRecordAnnotated' }),
            fields: {
              isStart() {
                return updateObserveRecordAnnotated.isStart
              },
              isEnd() {
                return updateObserveRecordAnnotated.isEnd
              },
              taskId() {
                return updateObserveRecordAnnotated.taskId
              },
            },
          })
        },
      })
      .toPromise()
    return result.data?.updateObserveRecordAnnotated
  }
}
