<div class="modal-header">
  <h4 class="modal-title">Create New Task Group</h4>
</div>
<form [formGroup]="taskGroupForm" (ngSubmit)="saveNewTaskGroup()" novalidate>
  <div class="modal-body">
    <div class="row mb-2">
      <label class="col-sm-4 d-flex align-items-center task-input-label required"> Group Name </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="taskGroup"
          required
          [appFcBsValidation]="taskGroupForm.get('taskGroup')"
          data-t="group-name-input"
        />
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-sm-4 d-flex align-items-center task-input-label">Group Description</label>
      <div class="col-sm-8">
        <textarea
          formControlName="description"
          class="form-control"
          cols="30"
          rows="10"
          [appFcBsValidation]="taskGroupForm.get('description')"
          data-t="group-name-descripton"
        ></textarea>
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-sm-4 d-flex align-items-center task-input-label"> Group Score </label>
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control"
          formControlName="score"
          [appFcBsValidation]="taskGroupForm.get('score')"
        />
      </div>
    </div>
    <div class="row mb-2">
      <label class="col-sm-4 d-flex task-input-label"> Assign Users </label>
      <div class="col-sm-8">
        <app-user-search (onSelect)="addUser($event)" [disabledUserIds]="selectedUserIds"> </app-user-search>
        <ul class="list-unstyled my-4 height-restrict">
          <li *ngFor="let user of selectedUsers" class="d-flex justify-content-between text-dark">
            <span>
              <i class="fa fa-times-circle-o text-danger mr-4" (click)="removeUser(user)"></i
              >{{ user?.name || user.email }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="activeModal.close('Close click')"
      data-t="create-task-group-cancel-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="create-task-group-save-button">
      Save
      <app-loading *ngIf="saving" class="pl-2"></app-loading>
    </button>
  </div>
</form>
