import { NgModule } from '@angular/core'
import { AppRoute } from '../../app-routing.module'
import { BotJobsListPage } from './bot-jobs-list/bot-jobs-list.page'
import { EditBotJobPage } from './edit/edit-bot-job.page'
import { RouterModule } from '@angular/router'

const routes: AppRoute[] = [
  {
    path: '',
    component: BotJobsListPage,
    data: { title: 'Admin - Bot Jobs' },
  },
  {
    path: ':botJobId',
    component: EditBotJobPage,
    data: { breadcrumb: ':botJobId' },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BotJobsRoutingModule {}
