import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-select-org-page',
  templateUrl: './select-org.page.html',
})
export class SelectOrgPage {
  user$ = from(this.authenticationService.setUser()).pipe(map(() => this.authenticationService.getUser()))

  shouldWaitForUserToSelectOrg$ = this.user$.pipe(
    map(() => {
      // Only show the org select if the user has more than one org to pick from
      return this.authenticationService.userHasMultipleOrgs()
    }),
  )

  constructor(private authenticationService: AuthenticationService, private route: ActivatedRoute) {
    this.shouldWaitForUserToSelectOrg$.toPromise().then((shouldWait) => {
      if (!shouldWait) {
        this.setRedirectUrlAndRedirect()
      }
    })
  }

  setRedirectUrlAndRedirect(): void {
    // Check if the login flow provided the original URL the user was trying to reach.
    // If so, redirect to that URL now that the login has completed and the organization has been selected.
    const relayState = this.route.snapshot.queryParamMap.get('relayState')
    this.authenticationService.redirectAfterLogin(relayState || null)
  }
}
