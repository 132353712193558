<div class="d-flex mt-4 mr-2 row no-gutters justify-content-center">
  <div class="card detail-card p-0 col-sm-3 mx-2">
    <div class="card-header text-nowrap">Statuses</div>
    <div class="card-body py-4 px-3">
      <div class="form-check text-nowrap mb-2" *ngFor="let status of statuses">
        <input
          class="form-check-input"
          type="checkbox"
          [attr.id]="status"
          [checked]="botRunsService.statuses?.includes(status)"
          (change)="selectStatus(status, $event)"
        />
        <label class="form-check-label" [attr.for]="status">
          {{ (status === 'CLAIM_NOT_FOUND' ? 'claim not found' : status?.replace('_', ' ')) | titlecase }}</label
        >
      </div>
    </div>
  </div>
  <div class="card detail-card p-0 col-sm-8 mx-2">
    <div class="card-header">Date & Time</div>
    <div class="card-body py-4 px-3">
      <app-datepicker-range
        (rangeSelected)="onRangeSelected($event)"
        [selectedDates]="{ from: startDate, to: endDate }"
        [selectedTimes]="{ from: startTime, to: endTime }"
        [allowSingleDate]="true"
        [selectTime]="true"
      ></app-datepicker-range>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-cancel" (click)="modal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-brand-green m-2" (click)="applySelection()" data-t="apply-revbot-filter-button">
    Apply
  </button>
</div>
