<span
  *ngIf="status"
  class="badge"
  [ngClass]="{
    'bg-success': status === 'DONE' || status === 'COMPLETED_EXTERNALLY',
    'bg-danger': status === 'AUTOMATION_ERRORS' || status === 'REJECTED_INVALID',
    'bg-warning': status?.indexOf('NEEDS') === 0,
    'bg-info': status === 'READY_FOR_AUTOMATION'
  }"
>
  <div [placement]="tooltipPlacement" container="body" [ngbTooltip]="statusTooltip">{{ humanReadableStatus }}</div>
</span>
