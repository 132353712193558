import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { QueriesQuery, QueryQuery } from 'app/shared/services/query-center.gql'
import { QueryArgs, QueryCenterQuery, QueryResult } from 'generated/graphql'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class QueryCenterService {
  constructor(private apollo: Apollo) {}

  fetchQueries(queryProduct: string): Observable<ApolloQueryResult<{ queries: QueryCenterQuery[] }>> {
    return this.apollo.watchQuery<{ queries: QueryCenterQuery[] }>({
      query: QueriesQuery,
      variables: { queryProduct },
    }).valueChanges
  }

  executeQuery(
    name: string,
    queryProduct: string,
    args?: QueryArgs,
  ): Observable<ApolloQueryResult<{ query: QueryResult }>> {
    return this.apollo.watchQuery<{ query: QueryResult }>({
      query: QueryQuery,
      variables: { queryProduct, name, args },
      fetchPolicy: 'network-only',
    }).valueChanges
  }
}
