/**
 * Helper to toggle password visibility
 *
 * @param {('password' | 'text')} passType
 * @return {*}  {('password' | 'text')}
 * @memberof AuthenticationService
 */
export function togglePassType(passType: 'password' | 'text'): 'password' | 'text' {
  return passType === 'text' ? 'password' : 'text'
}
