import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'

/**
 * This directive shows or hides an element based on if a given feature flag is true or false.
 * If a feature flag is not passed in nothing happens.
 *
 * It is suggested to use the FeatureFlags Object to pass in your feature flag.
 *
 * Usage:
 *
 * my-cool.page.ts
 *
 * import { FeatureFlags } from 'app/shared/services/feature-flag.service'
 *
 * @Component(...)
 * export class MyCoolPage {
 *   flags = FeatureFlags
 * }
 *
 * my-cool.page.html
 *
 * <h1 *hasFeatureFlag="flags.ui.mycoolpage">
 *   Only show me if the feature flag is enabled
 * </h1>
 */
@Directive({ selector: '[hasFeatureFlag]' })
export class FeatureFlagDirective {
  @Input('hasFeatureFlag') set featureFlag(featureFlag: string) {
    this.update(featureFlag)
  }

  // May want to update this eventually, but currently where
  // this is being used (header menu component) we want it to show
  // if the feature flag is null. Eventually when we change how the header menu
  // works we will want to set this to false.
  private showIfNoFeatureFlag = true
  private isShowing = false

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  private async update(featureFlag: string): Promise<void> {
    const shouldShow: boolean = featureFlag
      ? await this.featureFlagService.getFeatureFlagValue(featureFlag)
      : this.showIfNoFeatureFlag

    if (shouldShow && !this.isShowing) {
      this.viewContainer.createEmbeddedView(this.templateRef)
      this.isShowing = true
    } else if (!shouldShow && this.isShowing) {
      this.viewContainer.clear()
      this.isShowing = false
    }
  }
}
