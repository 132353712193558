<div class="card detail-card">
  <div class="card-header">Upload Logo</div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-6 col-xl-4">
        <div class="custom-file">
          <input id="orgLogo" type="file" class="custom-file-input" [formControl]="logo" />
          <label class="custom-file-label" for="orgLogo">Choose image</label>
        </div>
        <div class="p-4 my-3 w-100 logo-bg">
          <img src="{{imgSrc}}" height="30" />
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button type="button" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-logo-button" (click)="submit()">
            <i class="fa fa-floppy-o mr-3"></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
