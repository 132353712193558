import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetClaimTypeaheadValues } from 'app/shared/services/possible-values.gql'
import { debug } from 'app/shared/utils/debug'
import { QueryClaimTypeaheadArgs } from 'generated/graphql'

/**
 * Get possible values for claim properties
 *
 * @export
 * @class PossibleValuesService
 */
@Injectable({
  providedIn: 'root',
})
export class PossibleValuesService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve list of values for one claim property from elastic search
   *
   * @param {string} search
   * @param {string} field
   * @return {*}  {Promise<string[]>}
   * @memberof PossibleValuesService
   */
  async getValuesForField(search: string, field: string): Promise<string[]> {
    debug('possible-values-service', 'get values for field', field, search)
    let result = await this.apollo
      .query<{ claimTypeahead: string[] }, QueryClaimTypeaheadArgs>({
        query: GetClaimTypeaheadValues,
        variables: {
          search,
          field,
        },
      })
      .toPromise()

    let values = result.data.claimTypeahead
    debug('possible-values-service', 'got values', values)
    return values
  }
}
