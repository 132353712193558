<div class="container-fluid">
  <div class="row p-2 ml-4">
    <h3>Activity Log</h3>
  </div>

  <div class="container-fluid bg-alt-lighter-gray">
    <ng-container>
      <div class="row p-4">
        <div class="col-2 mt-1 mx-2">
          <div class="input-group form-group">
            <input
              type="text"
              class="form-control rounded-lg"
              placeholder="Search For ..."
              [formControl]="activityLogSearchText"
            />
            <div class="input-group-append">
              <span class="in-input-icon d-flex align-items-center">
                <button type="button" class="btn bg-transparent border-left">
                  <i class="fa fa-search fa-fw text-brand-dark-gray"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div class="col-2 ml-auto">
          <button
            *ngIf="!creatingExport"
            type="button"
            class="btn btn-primary float-right"
            (click)="exportData()"
            [disabled]="!observeActivityLogs.length"
          >
            Export Log
          </button>
          <ng-container *ngIf="creatingExport">
            <p class="text-center text-alt-dark-blue">
              Creating File....
              <app-loading
                innerClass="fa-2x"
                outerClass=" text-brand-light-blue"
                class="justify-content-center"
              ></app-loading>
            </p>
          </ng-container>
        </div>
        <div class="col-2 mr-2">
          <app-pagination [metaData]="activitylistMetaData" (fetchPage)="fetchPage($event)"> </app-pagination>
        </div>
      </div>
      <ng-container *ngIf="isLoading">
        <app-loading
          innerClass="fa-4x"
          outerClass="align-self-center text-brand-light-blue"
          class="d-flex flex-column flex-grow-1 justify-content-center py-4 my-4"
        ></app-loading>
      </ng-container>
      <div *ngIf="!isLoading" class="container-fluid">
        <div class="col m-1 pb-4">
          <table class="table bg-white m-1" data-t="observe-activity-table" *ngIf="!isLoading">
            <thead>
              <tr>
                <th scope="col" class="text-left text-brand-dark-gray">Updated By</th>
                <th scope="col" class="text-left text-brand-dark-gray" sortable="createdAtDate" (sort)="onSort($event)">
                  Date & Time
                </th>
                <th scope="col" class="text-left text-brand-dark-gray">Summary</th>
                <th scope="col" class="text-left text-brand-dark-gray">Affected User</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of observeActivityLogs" data-t="observe-activity-table-row">
                <td class="text-left">{{ row.updatedByUserName }}</td>
                <td class="text-left">{{ row.createdAt | date : 'medium' }}</td>
                <td class="text-left">{{ row.summary }}</td>
                <td class="text-left">{{ row.affectedUserName }}</td>
              </tr>
            </tbody>
          </table>

          <div
            *ngIf="!observeActivityLogs?.length && !isLoading"
            class="d-flex justify-content-center alert alert-danger"
            role="alert"
          >
            No Observe Activity Logs Found
          </div>
        </div>
      </div>
      <div class="row pb-4 mx-2">
        <div class="col float-right">
          <app-pagination [metaData]="activitylistMetaData" (fetchPage)="fetchPage($event)"></app-pagination>
        </div>
      </div>
    </ng-container>
  </div>
</div>
