import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { BotRunsFilterComponent } from 'app/revbot/bot-detail/components/bot-runs-filter/bot-runs-filter.component'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotJob, BotRun, TaskType } from 'generated/graphql'
import { combineLatest, of, Subject } from 'rxjs'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'

/**
 * Page to display overview data for a specific bot
 *
 * @export
 * @class BotOverviewPage
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-bot-overview',
  templateUrl: './bot-overview.page.html',
})
export class BotOverviewPage implements OnDestroy, OnInit {
  destroy$ = new Subject<void>()
  botJob: BotJob
  taskType: TaskType
  botRuns: BotRun[]
  isLoading: boolean = true
  triggerRoute$ = new Subject<boolean>()

  constructor(
    public botRunsService: BotRunsService,
    public authenticationService: AuthenticationService,
    private botJobsService: BotJobsService,
    private taskTypesService: TaskTypesService,
    private toast: ToastService,
    private route: ActivatedRoute,
    router: Router,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        map(() => this.route.snapshot.paramMap.get('botJobId')),
        switchMap((botJobId) => {
          let botJob$ = this.botJobsService.getBotJob(botJobId)
          let botRuns$ = this.botRunsService.filterBotRuns(botJobId, 0)
          return combineLatest([botJob$, botRuns$])
        }),
        catchError((e) => {
          this.toast.error(parseGraphQLError(e, 'Could not fetch bot job'), JSON.stringify(e))
          return of([])
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(async ([botJob, botRuns]) => {
        try {
          this.botJob = botJob?.data?.botJob

          this.crumbs.setPageTitle('RevBots - ' + this.botJob?.name)

          this.botRuns = botRuns?.data?.botRuns?.entities
          this.taskType = await this.taskTypesService.getTaskType(botJob?.data?.botJob?.taskTypeId)
        } catch (e) {
          this.toast.error(parseGraphQLError(e, 'Could not fetch bot job'), JSON.stringify(e))
        }
        this.isLoading = false
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Launch modal to filter bot runs
   *
   * @memberof BotOverviewPage
   */
  open(): void {
    this.modal.open(BotRunsFilterComponent, { size: 'lg', centered: true })
  }
}
