import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateOrganization,
  DeleteOrganization,
  GetOrganization,
  GetOrganizations,
  UpdateOrganization,
} from 'app/admin/org/orgs.gql'
import { GetHomePage, SetHomePage } from 'app/auth/homepage.gql'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import {
  ListResponseMetaData,
  MutationSetHomepageArgs,
  Organization,
  OrganizationList,
  QueryOrganizationArgs,
  QueryOrganizationsArgs,
  UpdateOrganizationInput,
  User,
} from 'generated/graphql'
import { Observable, firstValueFrom } from 'rxjs'
import { map, tap } from 'rxjs/operators'

/**
 * Handle organization operations
 *
 * @export
 * @class OrgService
 */
@Injectable({
  providedIn: 'root',
})
export class OrgService {
  private meta: ListResponseMetaData

  constructor(private http: HttpClient, private apollo: Apollo, private featureFlagService: FeatureFlagService) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof OrgService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  private isDemoEnabled: boolean = false

  /**
   * Get api url
   *
   * @readonly
   * @private
   * @memberof OrgService
   */
  private get API_URL() {
    return '/api'
  }

  /**
   * Retrieve demo feature toggle and store locally
   *
   * @return {*}  {Promise<void>}
   * @memberof OrgService
   */
  async getDemoFt(): Promise<void> {
    this.isDemoEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.demo)
  }

  /**
   * Return local value for demo feature toggle
   *
   * @return {*} {boolean}
   * @memberof OrgService
   */
  isDemo(): boolean {
    return this.isDemoEnabled
  }

  /**
   * Retrieve home content
   *
   * @deprecated
   * @return {*}  {Promise<string>}
   * @memberof OrgService
   */
  async getHomeContent(): Promise<string> {
    let result = await this.apollo
      .query<{ homepage: string }>({
        query: GetHomePage,
      })
      .toPromise()

    return result.data.homepage
  }

  /**
   * Save home content
   *
   * @deprecated
   * @param {string} content
   * @return {*}  {Promise<boolean>}
   * @memberof OrgService
   */
  async setHomeContent(content: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ setHomepage: boolean }, MutationSetHomepageArgs>({
        mutation: SetHomePage,
        variables: {
          homepage: content,
        },
      })
      .toPromise()

    return result.data.setHomepage
  }

  /**
   * Create an organization. superuser only
   *
   * @param {string} name
   * @param {string} [orgSetId=null]
   * @param {boolean} [testOrg=true]
   * @return {*}  {Promise<Organization>}
   * @memberof OrgService
   */
  async createOrg(name: string, orgSetId: string = null, testOrg: boolean = true): Promise<Organization> {
    let result = await this.apollo
      .mutate<{ createOrganization: Organization }>({
        mutation: CreateOrganization,
        variables: {
          name: name,
          orgSetId: orgSetId,
          testOrg: testOrg,
        },
      })
      .toPromise()

    return result?.data?.createOrganization
  }

  async deleteOrg(id: string): Promise<boolean> {
    const obs = await this.apollo
      .mutate<{ deleteOrganization: boolean }>({
        mutation: DeleteOrganization,
        variables: { id },
      })
      .pipe(map((result) => result.data.deleteOrganization))

    return firstValueFrom(obs)
  }

  /**
   * Update an existing
   *
   * @param {UpdateOrganizationInput} data
   * @return {*}  {Promise<Organization>}
   * @memberof OrgService
   */
  async updateOrg(id: string, data: UpdateOrganizationInput): Promise<Organization> {
    let result = await this.apollo
      .mutate<{ updateOrganization: Organization }, { id: string; data: UpdateOrganizationInput }>({
        mutation: UpdateOrganization,
        variables: { id: id, data: data },
      })
      .toPromise()

    return result?.data?.updateOrganization
  }

  /**
   * Retrieve a list of organizations
   *
   * @param {string} [search]
   * @param {number} [limit]
   * @param {number} [offset]
   * @return {*}  {Promise<Organization[]>}
   * @memberof OrgService
   */
  getOrgs(
    search?: string,
    limit?: number,
    offset?: number,
  ): Observable<ApolloQueryResult<{ organizations: OrganizationList }>> {
    return this.apollo
      .watchQuery<{ organizations: OrganizationList }, QueryOrganizationsArgs>({
        query: GetOrganizations,
        variables: {
          limit: limit,
          offset: offset,
          search: search,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.organizations?.meta
        }),
      )
  }

  /**
   * Retrieve a single organization
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ organization: Organization }>>}
   * @memberof OrgService
   */
  getOrg(id: string, tryCache = false): Observable<ApolloQueryResult<{ organization: Organization }>> {
    return this.apollo.watchQuery<{ organization: Organization }, QueryOrganizationArgs>({
      query: GetOrganization,
      variables: {
        id: id,
      },
      fetchPolicy: tryCache ? 'cache-and-network' : 'network-only',
    }).valueChanges
  }

  /**
   * Set a user's current organization.
   *
   * @param uuid The UUID of the organization.
   * @return The updated user.
   */
  async setOrg(uuid: string): Promise<User> {
    return this.http
      .patch(
        `${this.API_URL}/users/me`,
        {
          orgId: uuid,
        },
        {
          withCredentials: true,
        },
      )
      .toPromise()
      .then((updatedUser: User) => {
        this.featureFlagService.flags = {}
        this.getDemoFt()
        this.apollo.client.resetStore()
        return updatedUser
      })
  }

  /**
   * Retrieve the current organization's logo
   *
   * @return {*}  {Promise<any>}
   * @memberof OrgService
   */
  async getOrgLogo(): Promise<any> {
    return this.http
      .get(`${this.API_URL}/organizations/logo`, {
        withCredentials: true,
        responseType: 'blob',
      })
      .toPromise()
  }

  /**
   * Set the current organization's logo
   *
   * @param {File} file
   * @return {*}  {Promise<any>}
   * @memberof OrgService
   */
  async setOrgLogo(file: File): Promise<any> {
    let data = new FormData()
    data.append('file', file, file.name)
    return this.http
      .post(`${this.API_URL}/organizations/logo`, data, {
        withCredentials: true,
      })
      .toPromise()
  }
}
