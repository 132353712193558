import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { togglePassType } from 'app/shared/utils/togglePassType'

/**
 * Component to display a password field that can
 * toggle to an input field
 *
 * @export
 * @class PasswordInputComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  passType: 'password' | 'text' = 'password'
  @Input() password: UntypedFormControl | AbstractControl
  @Input() parentForm: UntypedFormGroup

  constructor() {}

  ngOnInit(): void {}

  togglePassType(): void {
    this.passType = togglePassType(this.passType)
  }
}
