import { CommonModule } from '@angular/common'
import { Component, OnDestroy, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  JuiBadgeComponent,
  JuiBreadcrumbLink,
  JuiBulkSelectBarComponent,
  JuiBulkSelectBarInputsForDataTableView,
  JuiButtonComponent,
  JuiDataTableViewComponent,
  JuiDialogService,
  JuiPageHeaderComponent,
  JuiTagComponent,
  JuiTemplateNameDirective,
  JuiThemeComponent,
  TableConfig,
} from '@janushealthinc/janus-ui-kit'
import { UserDataSource } from 'app/admin/users/users-list/data-source'
import { UsersFilterService } from 'app/admin/users/users-list/users-filter.service'
import { AuthorizationService } from 'app/auth/authorization.service'
import { LoadingComponent } from 'app/shared/components/loading/loading.component'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { User } from 'generated/graphql'
import { Subject, takeUntil } from 'rxjs'
import {
  ConfirmDeleteUsersDialogComponent,
  ConfirmDeleteUsersDialogData,
} from './confirm-delete-users-modal/confirm-delete-users-modal.component'

/**
 * Page to display and search a list of users
 */
@Component({
  imports: [
    CommonModule,
    FontAwesomeModule,
    JuiBadgeComponent,
    JuiBulkSelectBarComponent,
    JuiButtonComponent,
    JuiDataTableViewComponent,
    JuiPageHeaderComponent,
    JuiTagComponent,
    JuiTemplateNameDirective,
    JuiThemeComponent,
    LoadingComponent,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [UserDataSource, UsersFilterService],
  selector: 'app-users-list',
  standalone: true,
  styleUrls: ['./users-list.page.scss'],
  templateUrl: './users-list.page.html',
})
export class UsersListPage implements OnDestroy {
  dataSource = inject(UserDataSource)
  dialogService = inject(JuiDialogService)
  router = inject(Router)
  toastService = inject(ToastService)
  usersFilterService = inject(UsersFilterService)

  /**
   * Component inputs for the bulk select bar used in the data table view.
   *
   * Will be undefined if the user does not have authorization to delete. This
   * is because the only bulk action we have now is delete. When we add more
   * bulk actions, we should change this to support more granularity.
   *
   * TODO: Change in WA-1757
   */
  bulkSelectBarInputs: JuiBulkSelectBarInputsForDataTableView<User> | undefined = (() => {
    // Delete is the only bulk action we have right now, so don't even bother
    // showing the bulk select bar.
    if (!this.canDelete()) {
      return
    }

    return {
      actionItems: [
        [
          {
            alwaysShow: true,
            // TODO: WA-2133 Rethink the Row<T> type, because RowValue is
            // typed too strictly.
            handler: async (selectedUsers) => {
              const deleted = this.showDeleteUsersModal(selectedUsers as unknown as User[])

              return deleted
            },
            icon: faTrash,
            label: 'Delete',
          },
        ],
      ],
    }
  })()

  dataConfig: TableConfig<User> = {
    columns: [
      {
        field: 'email',
        header: 'Email',
        sortable: true,
        templateName: 'email',
      },
      {
        field: 'name',
        header: 'Name',
        sortable: true,
      },
      {
        field: 'role',
        header: 'Role',
        sortable: true,
      },
      {
        field: 'isActive',
        header: 'User',
        sortable: true,
        templateName: 'is-active',
      },
    ],
    // TODO: Change in WA-1757
    selectableRows: this.canDelete(),
  }

  pageHeaderLinks: JuiBreadcrumbLink[] = [
    {
      label: 'Home',
      path: '/home',
    },
    {
      label: 'Admin',
      path: '/admin',
    },
    {
      label: 'Users',
      path: '/admin/users',
    },
  ]

  createUserIcon = faPlus
  destroy$ = new Subject<void>()
  loading: boolean = false
  usersSearch = new UntypedFormControl('')
  users: User[]

  constructor(private authorizationService: AuthorizationService, private usersService: UsersService) {
    // When the service's search value changes, refetch the data with the
    // updated search value.
    this.usersFilterService.search$.pipe(takeUntilDestroyed()).subscribe((search) => {
      this.usersService.getUsersQuery$.refetch({ search })
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Called when the data-table-view search query changes. This will pass the
   * new query value to the users filter service.
   */
  onSearchChange(value: string): void {
    this.usersFilterService.setSearch(value)
  }

  /**
   * Check if users can be deleted.
   */
  canDelete(): boolean {
    return this.authorizationService.isSuper()
  }

  async showDeleteUsersModal(users: User[]): Promise<boolean> {
    const modalData: ConfirmDeleteUsersDialogData = {
      users,
    }

    const dialogRef = this.dialogService.openDialog(ConfirmDeleteUsersDialogComponent, modalData)
    const component = dialogRef.componentInstance

    return new Promise<boolean>((resolve) => {
      component.dialogCancel.pipe(takeUntil(this.destroy$)).subscribe(() => {
        dialogRef.close()

        resolve(false)
      })

      component.dialogDelete.pipe(takeUntil(this.destroy$)).subscribe(async () => {
        this.deleteUsers(users).then((wasDeleted) => {
          if (wasDeleted) {
            dialogRef.close()
          }

          resolve(wasDeleted)
        })
      })
    })
  }

  /**
   * Delete a list of users. Return `true` if the operation was successful,
   * `false` otherwise.
   */
  async deleteUsers(users: User[]): Promise<boolean> {
    return Promise.all(users.map((user) => this.usersService.deleteUser(user.id)))
      .catch((error) => {
        this.toastService.error(parseGraphQLError(error, 'Could not delete users'), JSON.stringify(error))

        return false
      })
      .then(() => {
        this.toastService.success('User successfully deleted')
        this.usersService.getUsersQuery$.refetch()

        return true
      })
  }

  /**
   * Check if user can create a new user.
   */
  canCreate() {
    return this.authorizationService.isSuper()
  }

  /**
   * Navigate to the user creation page.
   */
  navigateToUserCreation(): void {
    this.router.navigate(['/admin', 'users', 'new'])
  }
}
