<label class="sr-only" for="taskTypeSearch">Search for a task type</label>
<div class="input-group">
  <input
    id="taskTypeSearch"
    type="text"
    class="form-control"
    placeholder="Search for a task type"
    container="body"
    [ngbTypeahead]="search"
    [resultFormatter]="resultFormatter"
    [inputFormatter]="inputFormatter"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectTaskType($event)"
    [appFcBsValidation]="taskTypeForm"
    [formControl]="taskTypeForm"
    #input="ngbTypeahead"
    #element
  />
  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="element.value.length && !searching"
      type="button"
      (click)="clearTaskType($event)"
      class="btn bg-transparent"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
    <app-loading *ngIf="searching"></app-loading>
  </span>
</div>
