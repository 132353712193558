<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar h-100">
    <div class="d-flex row bg-breadcrumb align-items-center rounded-lg mx-1 pt-2 pl-3 pr-1">
      <div class="h5 text-uppercase py-3">
        <a routerLink="/admin">Admin</a> /
        <a routerLink="/admin/teams">Teams</a>
        {{'/ '+teamPod?.team?.name}}
      </div>
    </div>
    <div *ngIf="!isLoading;else loadingBlock" class="row no-gutters h-100">
      <div class="col text-alt-dark-gray font-weight-bold mx-1">
        <div class="card bg-breadcrumb border-0 h-100">
          <form [formGroup]="editForm" (ngSubmit)="saveTeam()" novalidate>
            <div class="card-body p-0 mt-4 mx-5 flex-nowrap">
              <div class="row mb-5">
                <div class="col-md-8">
                  <div class="row mx-0">
                    <div class="px-2 col-md-4">
                      <div class="card border-0 p-0 h-100">
                        <h5
                          class="card-header rounded-top border-0 px-5 py-4"
                          [ngClass]="teamsService.getTeamColor(teamPod?.team?.id)"
                        >
                          Supervisor
                        </h5>
                        <div class="card-body rounded-bottom p-0">
                          <div class="rounded-lg bg-light pt-4 pr-4 pb-5 pl-5">
                            <div class="text-alt-dark-gray font-weight-normal mb-2">Current</div>
                            <div class="p-0">
                              <app-user-search
                                (onSelect)="updateSupervisor($event)"
                                [clearInput]="false"
                                [selectedUser]="teamPod?.supervisor?.name || teamPod?.supervisor?.email"
                              >
                              </app-user-search>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="px-2 col-md-8">
                      <div class="card border-0 p-0">
                        <div
                          class="card-header rounded-top border-0 h5 px-5 py-4"
                          [ngClass]="teamsService.getTeamColor(teamPod?.team?.id)"
                        >
                          Description
                        </div>
                        <div class="card-body rounded-bottom p-0">
                          <div class="rounded-lg bg-light pt-4 px-5 pb-5">
                            <textarea
                              formControlName="description"
                              class="form-control mt-3 rounded-lg shadow-sm"
                              cols="25"
                              rows="5"
                              placeholder="Edit team description"
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1 d-none d-md-block">
                  <div
                    class="rounded h-100 user-background d-flex justify-content-center align-items-center mr-4"
                    [ngClass]="teamsService.getTeamColor(teamPod?.team?.id)"
                  >
                    <div class="flex-column text-white">
                      <div class="display-3 text-center font-weight-bold">{{teamPod?.members?.length}}</div>
                      <div [ngPlural]="teamPod?.team?.memberUserIds?.length" class="display-3">
                        <ng-template ngPluralCase="=1">Member</ng-template>
                        <ng-template ngPluralCase="other">Members</ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <div class="card px-0 border-0">
                    <div
                      class="card-header rounded-top border-0 h5 px-5 py-4"
                      [ngClass]="teamsService.getTeamColor(teamPod?.team?.id)"
                    >
                      Team Members
                    </div>
                    <div class="card-body px-5 pb-5 pt-4 rounded-bottom">
                      <div class="rounded bg-light">
                        <div
                          class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md rounded-lg"
                        >
                          <table class="table table-janus font-weight-normal" data-t="users-table">
                            <thead>
                              <tr class="inverted">
                                <th scope="col">Active</th>
                                <th scope="col" class="text-left">Email</th>
                                <th scope="col" class="text-left">Name</th>
                                <th scope="col" class="text-left">Role</th>
                                <th scope="col" class="text-right">
                                  <button
                                    type="button"
                                    class="btn btn-link text-white mr-1"
                                    ngbTooltip="Add user to team"
                                    placement="bottom-right"
                                    container="body"
                                    [ngbPopover]="userSearch"
                                    [autoClose]="false"
                                    #popover="ngbPopover"
                                  >
                                    <i class="fa fa-lg fa-plus-square"></i>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody *ngIf="teamPod?.members?.length">
                              <tr *ngFor="let user of teamPod.members" data-t="user-row">
                                <td class="text-center">
                                  <i
                                    [class]="user?.isActive ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
                                  ></i>
                                </td>
                                <td>{{ user?.email }}</td>
                                <td>{{ user?.name }}</td>
                                <td>{{ user?.role }}</td>
                                <td class="text-right pr-3">
                                  <button
                                    type="button"
                                    class="btn btn-link p-0"
                                    data-t="remove-user-button"
                                    ngbTooltip="Remove user from team"
                                    placement="left"
                                    container="body"
                                    (click)="removeTeamMember(user?.id)"
                                  >
                                    <i class="fa fa-fw fa-lg fa-trash text-dark"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <ng-template #userSearch>
                            <app-user-search (onSelect)="addTeamMember($event, popover)"></app-user-search>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0 d-flex justify-content-end mt-auto">
              <a class="btn btn-cancel" routerLink="/admin/teams"> Cancel </a>
              <button
                type="button"
                class="btn btn-alt-red px-5 mr-2"
                ngbTooltip="Delete Team"
                container="body"
                (click)="confirmDelete()"
              >
                <i class="fa fa-trash mr-2"></i> Delete
              </button>
              <button type="submit" class="btn btn-alt-light-blue px-5 mr-4" ngbTooltip="Save Team" container="body">
                <i class="fa fa-floppy-o mr-2"></i>
                Save
                <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingBlock class="h-100 d-flex align-items-center justify-content-center">
  <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
</ng-template>
