import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ClaimsService } from 'app/claims/claims.service'
import { CreateTaskComponent } from 'app/pathfinder/tasks/components/task-create/task-create.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, Task } from 'generated/graphql'
import { combineLatest, of, Subject } from 'rxjs'
import { catchError, filter, takeUntil } from 'rxjs/operators'

/**
 * Page to show details of a specific claim
 */
@Component({
  selector: 'app-claim-detail-page',
  templateUrl: './claim-detail.page.html',
  styleUrls: ['./claim-detail.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class ClaimDetailPage implements OnDestroy, OnInit {
  claim: Claim
  tasks: Task[] = []
  loading: boolean = true

  changedClaim$ = new Subject<void>()
  destroy$ = new Subject<void>()
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private claimsService: ClaimsService,
    private breadcrumbService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    private toast: ToastService,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.changedClaim$.next()
        this.onRouteChange()
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
    this.changedClaim$.next()
    this.changedClaim$.complete()
  }

  /**
   * Retrieve a specific claim by ID from route fragment
   */
  async onRouteChange(): Promise<void> {
    let claimId = this.route.snapshot.paramMap.get('claimId')
    debug('claims', 'route change', claimId)

    this.breadcrumbService.setPageTitle('Claims - ' + claimId)

    const foundBillingModule = this.route.snapshot.queryParamMap.get('billing_module')
    const KNOWN_BILLING_MODULES = ['pb', 'hb']
    const billingModule = KNOWN_BILLING_MODULES.includes(foundBillingModule)
      ? (foundBillingModule as 'pb' | 'hb')
      : undefined

    this.claimsService
      .getClaim(claimId, { markAsOpened: true, billingModule })
      .pipe(
        catchError((e) => {
          return of({ data: null, loading: false, error: e })
        }),
        takeUntil(this.changedClaim$),
      )
      .subscribe((event) => {
        this.loading = event.loading
        this.tasks = []
        if (event.error) {
          this.toast.error(parseGraphQLError(event, 'Could not load claim'), JSON.stringify(event.error))
          this.router.navigate(['/claims'])
        }
        if (event.data) {
          this.claim = event.data.claim
          this.tasks = this.claim.tasks
        }
      })
  }

  /**
   * Launch modal for adding a task to claim
   */
  createTask(): void {
    const modalRef = this.modal.open(CreateTaskComponent, { size: 'lg', centered: true })
    modalRef.componentInstance.providerClaimId = this.claim.providerClaimId
  }
}
