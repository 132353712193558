<div class="row justify-content-between">
  <div class="col-xl-auto">
    <app-search-field label="Search Orgs" [search]="search" [searching]="searching"></app-search-field>
  </div>
  <div class="col-xl-auto">
    <button type="button" class="btn btn-primary mr-3" (click)="openDrawer()" [disabled]="!selected.length">
      Edit Org Set
    </button>
    <button type="button" class="btn btn-primary" (click)="openDrawerCreateOrg()">
      <i class="fa fa-fw fa-plus mr-2"></i>
      Create Org
    </button>
  </div>
</div>
<div class="row flex-grow-1 offcanvas-wrapper">
  <div [ngClass]="isDrawerOpen ? 'col-7' : 'col'" class="d-flex flex-column flex-grow-1">
    <div class="table-responsive-md d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto">
      <table class="table table-janus-new table-striped overflow-auto" data-t="orgs-table">
        <thead>
          <tr>
            <th><span class="sr-only">Select Org</span></th>
            <th class="text-center">Name</th>
            <th class="text-center">Org Set</th>
            @if (canDelete$ | async) {
            <th class="text-center">Delete Org</th>
            }
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let org of organizations" [ngClass]="{'table-alt-light-blue': selected.includes(org.id)}">
            <td scope="row" class="text-center width-1">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="selected.includes(org.id)"
                  data-t="org-checkbox"
                  (click)="toggleSelected(org.id)"
                />
              </div>
            </td>
            <td class="text-center">{{org.name}}</td>
            <td class="text-center">{{org.orgSetId}}</td>
            @if (canDelete$ | async ) {
            <td class="text-center">
              @if (org.isCurrentOrg) {
              <jui-button [disabled]="true" title="Cannot delete current org">
                <fa-icon [icon]="trashIcon"></fa-icon>
              </jui-button>
              } @else {
              <jui-button title="Delete" (click)="showDeleteOrgModal(org)">
                <fa-icon [icon]="trashIcon"></fa-icon>
              </jui-button>
              }
            </td>
            }
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="searching"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      ></app-loading>
      <div
        *ngIf="!organizations?.length && !searching"
        class="d-flex justify-content-center alert alert-danger"
        role="alert"
      >
        Your search returned no results.
      </div>
    </div>
  </div>
  <div
    class="offcanvas-collapse bg-alt-white shadow d-flex flex-column flex-grow-1 overflow-hidden"
    [ngbCollapse]="!isDrawerOpen"
    [ngClass]="{'col-5' : isDrawerOpen}"
  >
    <div class="fade" [ngClass]="{'show': isDrawerOpen}"></div>
    <div class="bg-alt-white flex-grow-1 collapse-content">
      <div class="card p-0 border-0 bg-transparent h-100">
        <div class="card-header bg-white border-bottom-1 d-flex align-items-center">
          <button type="button" class="close font-weight-bold mr-5" aria-label="Close" (click)="drawerCancel()">
            <span aria-hidden="true">&times;</span>
          </button>
          <h5 class="mb-0" *ngIf="selected.length">Edit Org Set</h5>
          <h5 class="mb-0" *ngIf="!selected?.length">Create Org</h5>
        </div>
        <app-create-org
          *ngIf="!selected?.length; else selectOrgId"
          (onSave)="createOrg($event)"
          (onCancel)="drawerCancel()"
        ></app-create-org>
        <ng-template #selectOrgId>
          <div class="card-body flex-grow-0" *ngIf="selected.length">
            <p>Set the Org Set Id for all {{selected.length}} selected Organizations.</p>
            <p class="text-muted small">
              <strong>NOTE</strong> that this will move the selected orgs into the selected org set, even if they
              already belong to a different org set!
            </p>
            <app-select-org-set [orgSetId]="orgSetId"></app-select-org-set>
          </div>
          <div class="card-footer border-top-1 bg-alt-white d-flex justify-content-end">
            <button type="button" class="btn btn-cancel mr-3" (click)="drawerCancel()">Cancel</button>
            <button class="btn btn-primary" (click)="assignOrgSetId()">
              <i class="fa fa-fw fa-floppy-o mr-2"></i>
              Save
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
