import { Component, OnInit } from '@angular/core'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'

@Component({
  selector: 'app-observe-dashboard',
  templateUrl: './observe-dashboard.page.html',
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class ObserveDashboardPage implements OnInit {
  readonly dashboardType: QuicksightDashboardType = QuicksightDashboardType.Observe
  constructor() {}

  ngOnInit(): void {}
}
