import { Client, FailStatusMessage, IntermediateStatusMessage } from '@hanseltime/janus-simple-command'
import { WebSocketConnection } from './ws-client'
import type { CommandMessage, SuccessStatusMessage } from '@hanseltime/janus-simple-command'
import { hostname } from './bot-modal.component'
import { debug } from 'app/shared/utils/debug'

export type Commands = 'deployBot'
export type CommandMap = {
  deployBot: CommandMessage<'deployBot', { image: { tag?: string; branch?: string } }>
}
type DeployBotSucess = SuccessStatusMessage<{
  url: string
}>

export type StatusMap = {
  deployBot: DeployBotSucess | FailStatusMessage
}

export type deployBotState = 'Running' | 'Pending' | 'NotRunning'

export type InterMap = {
  deployBot: IntermediateStatusMessage<{
    state: deployBotState
    status?: any
  }>
}

const isMessageError = (message: StatusMap['deployBot']): message is FailStatusMessage => {
  if (message.result === 'fail') {
    return true
  }
  return false
}

export const deployBot = async (branch: string | null): Promise<DeployBotSucess> => {
  if (!branch) {
    debug('bot-qa', 'no branch selected')
    return
  }
  const url = `wss://ephemeral-coordinator.${hostname}`
  const clientConnection = new WebSocketConnection(new WebSocket(url), 'client')

  const client = new Client<Commands, CommandMap, StatusMap, never, InterMap>({
    commands: ['deployBot'],
    ackRetryDelay: 10000,
    maxAckRetries: 3,
    connection: clientConnection,
    debug: (...args: any) => {
      debug('bot-qa', args)
    },
  })

  await client.open()
  const sender = await client.createSender()
  const message = await sender.command('deployBot', { image: { branch } })

  if (isMessageError(message)) {
    throw new Error(`Error deploying Bot, ${message}`)
  }

  await sender.close()

  await client.close()

  return message
}
