import { Component, Input, OnInit } from '@angular/core'
import { PayerCardActionsService } from 'app/admin/payers/payer-card-actions.service'
import { Payer } from 'generated/graphql'

/**
 * Component to display a single payer in a card
 *
 * @export
 * @class PayerCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-payer-card',
  templateUrl: './payer-card.component.html',
  styleUrls: ['./payer-card.component.scss'],
})
export class PayerCardComponent implements OnInit {
  @Input() payer: Payer

  @Input() showRight: string = null
  @Input() showTwoRight: string = null
  @Input() showRemove: string = null

  constructor(private actionService: PayerCardActionsService) {}

  ngOnInit(): void {}

  /**
   * User clicked on arrow
   *
   * @param {Payer} payer
   * @param {*} $event
   * @memberof PayerCardComponent
   */
  clickRight(payer: Payer, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRight(payer)
  }

  /**
   * User clicked on double arrow
   *
   * @param {Payer} payer
   * @param {*} $event
   * @memberof PayerCardComponent
   */
  clickTwoRight(payer: Payer, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickTwoRight(payer)
  }

  /**
   * User clicked on close
   *
   * @param {Payer} payer
   * @param {*} $event
   * @memberof PayerCardComponent
   */
  clickRemove(payer: Payer, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRemove(payer)
  }
}
