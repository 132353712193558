<div class="row full-height">
  <div class="col-lg-3 scrolling-sidebar left-column">
    <app-payer-search showRight="Keep" showTwoRight="Combine"></app-payer-search>
  </div>

  <div class="col-lg-9 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1">
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body">
              <h3 class="text-center">Keep</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <app-payer-card *ngIf="keep" [payer]="keep" showRemove="Remove"></app-payer-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column flex-grow-1 mt-5 mt-lg-0">
          <div class="card flex-grow-1">
            <div class="card-body">
              <h3 class="text-center">Combine</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <ng-template ngFor let-p [ngForOf]="combine" let-i="index">
                  <app-payer-card [payer]="p" showRemove="Remove"></app-payer-card>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="combine-payer-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button type="button" class="btn btn-brand-green px-5" data-t="combine-payer-button" (click)="confirmSave()">
            Combine
            <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
