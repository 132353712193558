<div class="row full-height">
  <div class="col-lg-3 scrolling-sidebar left-column">
    <app-task-type-search showRight="Keep" showTwoRight="Merge"></app-task-type-search>
  </div>

  <div class="col-lg-9 h-100">
    <div class="d-flex flex-column h-100">
      <div class="row flex-grow-1">
        <div class="col-lg-6 d-flex flex-column flex-grow-1">
          <div class="card flex-grow-1">
            <div class="card-body">
              <h3 class="text-center">Keep</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <app-task-type-card
                  *ngIf="keep"
                  [taskType]="keep"
                  [isCollapsed]="keepCollapsed"
                  showRemove="Remove"
                  showPencil="Edit"
                ></app-task-type-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex flex-column flex-grow-1 mt-5 mt-lg-0">
          <div class="card flex-grow-1">
            <div class="card-body">
              <h3 class="text-center">Merge</h3>
              <hr class="w-75 mx-auto" />
              <div class="scrolling-main">
                <ng-template ngFor let-tt [ngForOf]="merge" let-i="index">
                  <app-task-type-card
                    [taskType]="tt"
                    [isCollapsed]="mergeCollapsed[i]"
                    showRemove="Remove"
                    showPencil="Edit"
                  ></app-task-type-card>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 justify-content-end">
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="merge-task-type-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-brand-green px-5"
            data-t="merge-task-type-button"
            (click)="confirmSave()"
            [disabled]="!keep || merge.length < 1"
          >
            Merge
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
