import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, filter, first, map, switchMap } from 'rxjs/operators'
import { merge, of, Subject, Subscription, Observable } from 'rxjs'
import { AuthenticationService } from '../authentication.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { InactivityWarningComponent, INACTIVITY_WARNING_MS } from '../inactivity/inactivity-warning.component'
import { debug } from 'app/shared/utils/debug'
import { FeatureFlags, FeatureFlagService } from 'app/shared/services/feature-flag.service'

const NO_INACTIVITY_TIMEOUT: null = null
const ONE_MINUTE = 60 * 1000

// The timeout will appear this number of MS before the session officially ends
// to ensure that there is time to keepalive and the session doesn't die on the backend
// before the front end
export const INACTIVITY_WARNING_BUFFER = 5 * 1000

const BROWSER_SUPPORTS_BROADCAST_CHANNEL = !!window.BroadcastChannel

@Injectable({ providedIn: 'root' })
export class InactivityService implements OnDestroy {
  private inactivityDurationSubscription = Subscription.EMPTY
  private warningTimer: ReturnType<typeof setTimeout>
  private logoutTimestampMs: number
  private userIsLoggedIn$ = this.authenticationService.user$.pipe(filter((user) => !!user))
  private userKeptSessionAlive$ = new Subject<void>()
  private cachedInactivityTimeoutMs: number
  private broadcastChannel?: BroadcastChannel
  private modalRef?: NgbModalRef

  get secondsUntilLogout(): number {
    let msUntilLogout = this.logoutTimestampMs - Date.now() - INACTIVITY_WARNING_BUFFER

    if (msUntilLogout < 0) {
      return 0
    }

    return Math.round(msUntilLogout / 1000)
  }

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private modal: NgbModal,
    private featureFlagService: FeatureFlagService,
  ) {
    /**
     * Note: the inactivity timers need to be re-calculated after the user
     * changes orgs (either during a session or during the login process).
     * Currently this happens implicitly because the org switching process
     * manually reloads the browser window thus re-initializes this service. If
     * we ever change how the org switching process works, this service will
     * need to be updated to accommodate orgs with different inactivity durations.
     */
    this.userIsLoggedIn$
      .pipe(first())
      .toPromise()
      .then(() => this.setupTimersAfterUserIsLoggedIn())
  }

  private async setupTimersAfterUserIsLoggedIn(): Promise<void> {
    const isSessionInactivityWarningEnabled = await this.featureFlagService.getFeatureFlagValue(
      FeatureFlags.ui.sessionInactivityWarning,
    )

    if (!isSessionInactivityWarningEnabled) {
      debug('authentication', `${FeatureFlags.ui.sessionInactivityWarning} NOT enabled`)
      return
    }

    if (BROWSER_SUPPORTS_BROADCAST_CHANNEL) {
      this.broadcastChannel = new BroadcastChannel(FeatureFlags.ui.sessionInactivityWarning)
      this.broadcastChannel.addEventListener('message', this.onBroadcastMessage)
    }

    const timeoutRenewed$: Observable<number> = merge(
      this.userKeptSessionAlive$.pipe(map(() => this.cachedInactivityTimeoutMs)),
      this.userIsLoggedIn$.pipe(
        switchMap(() => {
          return this.http.get<{ inactivityTimeoutMs: number }>(`/api/organizations/timeout`).pipe(
            map((res) => (this.cachedInactivityTimeoutMs = res.inactivityTimeoutMs)),
            catchError(() => of(NO_INACTIVITY_TIMEOUT)),
          )
        }),
      ),
    )

    this.inactivityDurationSubscription = timeoutRenewed$
      .pipe(filter((inactivityTimeoutMs) => inactivityTimeoutMs > 0))
      .subscribe((inactivityTimeoutMs) => {
        const warningTimeoutMs = inactivityTimeoutMs - INACTIVITY_WARNING_MS - INACTIVITY_WARNING_BUFFER
        debug('authentication', `inactivity warning set for ${warningTimeoutMs / ONE_MINUTE}min`)
        clearTimeout(this.warningTimer)
        this.warningTimer = setTimeout(() => this.warnAboutInactivity(), warningTimeoutMs)
        this.logoutTimestampMs = Date.now() + inactivityTimeoutMs

        if (this.modalRef) {
          // If the dialog is open but some activity in this tab or another tab
          // keeps the session alive, close the tab to prevent a logout.
          this.modalRef.close()
        }
      })
  }

  private onBroadcastMessage = ({ data }: MessageEvent): void => {
    if (data === 'keepalive') {
      debug('authentication', `session timeout reset from another tab`)
      this.userKeptSessionAlive$.next()
    } else if (data === 'logout') {
      debug('authentication', `session logout from another tab`)
      this.reloadAfterOtherTabLogout()
    }
  }

  private warnAboutInactivity(): void {
    this.modalRef = this.modal.open(InactivityWarningComponent, {
      centered: true,
      windowClass: 'modal-extra-padding',

      // Prevents the dialog from being dismissed either by clicking the backdrop
      // or by pressing ESC. This forces the user to interact with the dialog in
      // order to dismiss it (either clicking 'Resume' or 'Log out').
      backdrop: 'static',
      keyboard: false,
    })

    const modalSubscription = this.modalRef.closed.subscribe(async (action: unknown) => {
      modalSubscription.unsubscribe()
      this.modalRef = undefined

      if (action === 'keepalive') {
        this.keepAlive()
        this.resetTimeoutInAllTabs()
      } else if (action === 'logout') {
        this.logout()
      }
    })
  }

  private async keepAlive(): Promise<void> {
    try {
      // POST to the keepalive endpoint to reset the session expiration
      await this.http.post(`/api/session/keepalive`, {}).toPromise()
    } catch {
      // NOTE: If the keepalive endpoint fails there's a high chance that the
      // session has already been expired on the backend. If the session has
      // expired then there's a good chance the logout request will 500. We may
      // want to fix the logout endpoint so that it handles logout requests from
      // expired sessions more gracefully.
      await this.logout()
    }
  }

  private async logout(): Promise<void> {
    await this.authenticationService.logout()

    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage('logout')
    }
  }

  private reloadAfterOtherTabLogout(): void {
    document.location.reload()
  }

  ngOnDestroy(): void {
    this.inactivityDurationSubscription.unsubscribe()

    if (this.broadcastChannel) {
      this.broadcastChannel.close()
      this.broadcastChannel = undefined
    }
  }

  resetTimeoutInAllTabs(): void {
    this.userKeptSessionAlive$.next()

    if (this.broadcastChannel) {
      this.broadcastChannel.postMessage('keepalive')
    }
  }
}
