import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { GetFavClaims, RemoveFavClaim, SetFavClaim } from 'app/claims/favorite-claims.gql'
import {
  Claim,
  ClaimList,
  MutationMarkClaimAsFavoriteArgs,
  MutationUnmarkClaimAsFavoriteArgs,
  QueryFavoriteClaimsArgs,
} from 'generated/graphql'
import { cloneDeep, remove } from 'lodash'
import { Observable } from 'rxjs'

/**
 * Handle adding / removing / getting user's favorite claims
 *
 * @export
 * @class FavoriteClaimsService
 */
@Injectable({
  providedIn: 'root',
})
export class FavoriteClaimsService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve a user's favorited claims
   *
   * @return {*}  {Observable<ApolloQueryResult<{ favoriteClaims: ClaimList }>>}
   * @memberof FavoriteClaimsService
   */
  getFavoriteClaims(): Observable<ApolloQueryResult<{ favoriteClaims: ClaimList }>> {
    return this.apollo.watchQuery<{ favoriteClaims: ClaimList }, QueryFavoriteClaimsArgs>({
      query: GetFavClaims,
      fetchPolicy: 'cache-first',
    }).valueChanges
  }

  /**
   * Add a claim to a user's favorites
   *
   * @param {Claim} claim
   * @return {*}  {Promise<boolean>}
   * @memberof FavoriteClaimsService
   */
  async addFavoriteClaim(claim: Claim): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ markClaimAsFavorite: boolean }, MutationMarkClaimAsFavoriteArgs>({
        mutation: SetFavClaim,
        variables: {
          claimId: claim.id,
        },
        update: async (store) => {
          const data = cloneDeep(
            store.readQuery<{ favoriteClaims: ClaimList }, QueryFavoriteClaimsArgs>({
              query: GetFavClaims,
            }),
          )
          data.favoriteClaims.entities.push(claim)
          if (data.favoriteClaims.meta) {
            data.favoriteClaims.meta.total++
          }
          store.writeQuery({
            query: GetFavClaims,
            data,
          })
        },
      })
      .toPromise()

    return result.data.markClaimAsFavorite
  }

  /**
   * Remove a claim from a user's favorites
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof FavoriteClaimsService
   */
  async removeFavoriteClaim(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ unmarkClaimAsFavorite: boolean }, MutationUnmarkClaimAsFavoriteArgs>({
        mutation: RemoveFavClaim,
        variables: {
          claimId: id,
        },
        update: (store) => {
          const data = cloneDeep(
            store.readQuery<{ favoriteClaims: ClaimList }, QueryFavoriteClaimsArgs>({
              query: GetFavClaims,
            }),
          )
          remove(data.favoriteClaims.entities, (c) => c.id === id)
          if (data.favoriteClaims.meta) {
            data.favoriteClaims.meta.total--
          }
          store.writeQuery({
            query: GetFavClaims,
            data,
          })
        },
      })
      .toPromise()

    return result.data.unmarkClaimAsFavorite
  }
}
