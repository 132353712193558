<fieldset>
  <div class="input-group">
    <label for="searchField" class="sr-only">{{ label }}:</label>
    <input
      type="text"
      class="form-control text-alt-medium-gray rounded-left border-alt-light-gray border-right-0"
      aria-label="Search Bar"
      [formControl]="searchFc"
      [attr.placeholder]="label"
      autocomplete="off"
      data-t="search-field"
      (keyup.enter)="enterKeyHandler()"
    />
    <span
      *ngIf="search.value.length && !searching"
      class="in-input-icon d-flex align-items-center justify-content-center"
    >
      <button type="button" (click)="clearInput()" class="btn bg-transparent">
        <i class="fa fa-times fa-fw text-brand-dark-gray"></i>
      </button>
    </span>
    <div class="input-group-append">
      <button (click)="enterKeyHandler()" class="btn btn-outline-alt-light-gray input-group-text" type="button">
        <i class="fa fa-search fa-fw text-alt-medium-gray"></i>
      </button>
    </div>
  </div>
</fieldset>
