<div class="card detail-card">
  <div class="card-header">{{ taskType?.name }}</div>
  <div class="card-body">
    <form [formGroup]="taskForm" (ngSubmit)="updateTaskType()" class="d-flex flex-column" novalidate>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label"> Task Name </label>
        <div class="col-sm-8">
          <input
            type="text"
            class="form-control"
            formControlName="taskType"
            required
            [appFcBsValidation]="taskForm.get('taskType')"
          />
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label"> Task Instructions </label>
        <div class="col-sm-8">
          <textarea class="form-control" formControlName="instructions" rows="5"></textarea>
        </div>
      </div>
      <div class="row mb-2">
        <label class="col-sm-4 d-flex align-items-center task-input-label"> Average Completion Time (minutes) </label>
        <div class="col-sm-8">
          <input
            type="number"
            class="form-control"
            formControlName="aveMinutes"
            required
            [appFcBsValidation]="taskForm.get('aveMinutes')"
          />
        </div>
      </div>
      <div class="row my-5">
        <div class="col-sm-4 d-flex flex-column">
          <label class="task-input-label pl-0"> Assigned to Claims </label>
          <small>Showing {{ totalClaims > claims?.length ? '10' : claims?.length }} of about {{ totalClaims }}</small>
        </div>

        <div class="col-sm-8">
          <ul class="list-unstyled">
            <li *ngFor="let claim of claims">
              <a routerLink="/claims/{{ claim?.providerClaimId }}">{{ claim?.providerClaimId }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-end mt-5">
        <div class="col-sm-2">
          <button type="button" class="btn btn-danger" data-t="delete-task-type-button" (click)="confirmDelete()">
            Delete
          </button>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <button type="button" class="btn btn-cancel" data-t="edit-task-type-cancel-button" (click)="cancel()">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-outline-brand-green px-5 mx-3"
            data-t="copy-task-type-button"
            (click)="duplicate()"
          >
            Copy
            <app-loading *ngIf="copying" class="pl-2"></app-loading>
          </button>
          <button
            type="submit"
            class="btn btn-brand-green px-5"
            data-t="save-task-type-button"
            [disabled]="taskForm.invalid"
          >
            Save
            <app-loading *ngIf="saving" class="pl-2"></app-loading>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
