import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-observe-org-list',
  templateUrl: './observe-org-list.component.html'
})
export class ObserveOrgListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
