import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { CreatePayerInput, Payer } from 'generated/graphql'
import { pickBy } from 'lodash'
import { Subscription } from 'rxjs'
import { RESOLVED } from '../payer-resolver.service'

/**
 * Page to create and edite existing payer
 *
 * @export
 * @class PayerEditPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-payer-edit',
  templateUrl: './payer-edit.page.html',
  styleUrls: ['./payer-edit.page.scss'],
  host: { class: 'h-100' },
})
export class PayerEditPage implements OnInit {
  payer: Payer
  childrenPayers: Payer[]
  dataSub$: Subscription
  saving: boolean = false
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    address: new UntypedFormControl(null),
    siteUrl: new UntypedFormControl(null),
    payerId: new UntypedFormControl(null),
  })

  constructor(
    private toast: ToastService,
    private payersService: PayersService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
  ) {}

  ngOnInit(): void {
    this.dataSub$ = this.route.data.subscribe(async (data: { resolved: RESOLVED }) => {
      this.payer = data.resolved?.payer

      this.breadcrumbService.setPageTitle('Admin - Payers' + this.payer?.name ? ' - ' + this.payer.name : '')

      this.childrenPayers = data.resolved?.childrenPayers
      this.setFormAndBreadcrumb()
    })
  }

  /**
   * Populate form for existing payer
   *
   * @memberof PayerEditPage
   */
  setFormAndBreadcrumb(): void {
    // let lastCrumb = this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1]
    // lastCrumb.label = this.payer?.name || 'New Payer'
    if (this.payer?.id) {
      this.form.setValue({
        name: this.payer?.name,
        address: this.payer?.address,
        siteUrl: this.payer?.siteUrl,
        payerId: this.payer?.payerId,
      })
    }
  }

  /**
   * Save changes to, or create new, payer
   *
   * @return {*}  {Promise<void>}
   * @memberof PayerEditPage
   */
  async savePayer(): Promise<void> {
    this.saving = true
    if (this.form.invalid) {
      this.toast.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.form)),
      )
    } else {
      let form = pickBy(this.form.value, (value) => value != null) as CreatePayerInput
      try {
        if (this.payer?.id) {
          this.payer = await this.payersService.updatePayer(this.payer.id, form)
          this.setFormAndBreadcrumb()
        } else {
          await this.payersService.createPayer(form)
        }
        this.toast.success('Payer successfully saved')
        this.router.navigate(['admin/payers'])
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not save payer'), JSON.stringify(e))
      }
    }
    this.saving = false
  }
}
