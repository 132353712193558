<div class="card p-0 border-0 bg-transparent h-100">
  <div class="card-header bg-white border-bottom-1 text-alt-dark-gray row align-items-center">
    <div class="col-2">
      <button type="button" class="btn btn-link mx-3" (click)="cancel()">
        <i class="fa fa-lg fa-times text-brand-dark-gray"></i>
        <span class="sr-only">Close</span>
      </button>
    </div>
    <div class="col">
      <h5 class="mb-0">{{ isNewRule ? 'Add Control' : 'Edit Control' }}</h5>
    </div>
  </div>

  <div class="row h-100">
    <div class="col">
      <form [formGroup]="createRuleForm" (ngSubmit)="saveOAC(false)" class="h-100">
        <div class="card-body bg-white shadow d-flex flex-column flex-basis-0 flex-grow-1 h-100">
          <div class="d-flex flex-column flex-basis-0 flex-grow-1 px-4">
            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="type" class="required">Type</label>
                  <select
                    formControlName="type"
                    class="form-control shadow-sm"
                    [appFcBsValidation]="createRuleForm.get('type')"
                  >
                    <option [ngValue]="null" disabled class="text-alt-medium-gray">Select&hellip;</option>
                    <option
                      value="URL-MANAGED"
                      [disabled]="createRuleForm.value?.type && !patternCanBeInterpreted(createRuleForm.value?.type)"
                    >
                      URL
                    </option>

                    <option value="URL-CUSTOM">URL (custom regular expression)</option>
                    <option value="APP">APP</option>
                  </select>
                </div>
              </div>
            </div>

            <ng-container *ngIf="showManagedPatternOptions">
              <ng-container formGroupName="managedPatternOptions">
                <div class="row my-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="type" class="required">Protocol</label>
                      <select
                        formControlName="protocol"
                        class="form-control shadow-sm"
                        [appFcBsValidation]="managedPatternCtrls.get('protocol')"
                      >
                        <option value="empty">Any</option>
                        <option value="https">HTTPS only</option>
                        <option value="http">HTTP only</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col">
                    <div class="form-group">
                      <label for="domain" class="required">Domain</label>
                      <input
                        type="text"
                        class="form-control shadow-sm"
                        formControlName="domain"
                        id="domain"
                        [appFcBsValidation]="managedPatternCtrls.get('domain')"
                      />
                      <p class="fs-14 mt-1 text-danger" *ngIf="managedPatternCtrls.get('domain')?.errors?.pattern">
                        Invalid domain
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col">
                    <div class="row my-3">
                      <div class="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="allow-subdomains-check"
                            formControlName="allowSubdomains"
                          />
                          <label for="allow-subdomains-check" class="custom-control-label">Allow subdomains</label>
                        </div>
                      </div>
                    </div>

                    <div class="row my-3">
                      <div class="col">
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="allow-paths-check"
                            formControlName="allowPaths"
                          />
                          <label for="allow-paths-check" class="custom-control-label">Allow paths and parameters</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="pattern">Pattern</label>
                    <input type="text" class="form-control shadow-sm" formControlName="pattern" id="pattern" readonly />
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!showManagedPatternOptions">
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="pattern" class="required">Pattern</label>
                    <input
                      type="text"
                      class="form-control shadow-sm"
                      formControlName="pattern"
                      id="pattern"
                      [appFcBsValidation]="createRuleForm.get('pattern')"
                    />
                    <p class="fs-14 mt-1 text-danger" *ngIf="createRuleForm.get('pattern')?.errors?.invalidRegex">
                      Invalid regular expression
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="row my-3">
              <div class="col">
                <div class="form-group">
                  <label for="name">Rule Name</label>
                  <input type="text" class="form-control shadow-sm" formControlName="name" id="name" />
                </div>
              </div>
            </div>
            <div class="row my-3 px-4">
              <div class="col">
                <div class="form-group row"><label class="required">Allowed/Denied</label></div>
                <div class="form-group row">
                  <div class="form-check custom-control custom-radio mr-5">
                    <input
                      type="radio"
                      class="form-check-input custom-control-input"
                      formControlName="allowed"
                      [value]="true"
                      id="allowed"
                    />
                    <label class="custom-control-label" for="allowed">Allowed</label>
                  </div>
                  <div class="form-check custom-control custom-radio">
                    <input
                      type="radio"
                      class="form-check-input custom-control-input"
                      formControlName="allowed"
                      [value]="false"
                      id="denied"
                    />
                    <label class="custom-control-label" for="denied">Denied</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3 px-4" *ngIf="_inputRule?.type === 'APP'">
              <div class="col">
                <div class="form-group row">
                  <div class="custom-control custom-switch mr-5">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      formControlName="enhancedMetadataCapture"
                      id="enhancedMetadataCapture"
                    />
                    <label class="custom-control-label" for="enhancedMetadataCapture">Enhanced Metadata Capture</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3 card-footer border-top-1 bg-transparent">
              <div class="col p-0">
                <div class="flex-row d-flex justify-content-between flex-nowrap">
                  <div class="flex-column">
                    <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
                  </div>
                  <div class="flex-column">
                    <button
                      type="button"
                      class="btn btn-alt-green"
                      ngbTooltip="Save control and return to list"
                      container="body"
                      placement="bottom"
                      (click)="saveOAC(true)"
                    >
                      <i class="fa fa-floppy-o mr-2"></i>
                      Save + Close
                      <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
                    </button>
                  </div>
                  <div class="flex-column">
                    <button
                      type="submit"
                      class="btn btn-alt-light-blue"
                      ngbTooltip="Save control and add another"
                      placement="bottom-right"
                      container="body"
                    >
                      <i class="fa fa-floppy-o mr-2"></i>
                      Save + Add
                      <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
