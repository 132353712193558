<app-task-group-overview *ngIf="taskGroup" [taskGroup]="taskGroup"></app-task-group-overview>
<h2 class="text-dark" *ngIf="!taskGroup">My Tasks</h2>
<div class="d-flex justify-content-between">
  <div class="flex-grow-1">
    <button
      type="button"
      class="btn btn-sm py-2 btn-outline-alt-steel-blue mr-3"
      (click)="toggleFilters()"
      data-t="task-filters-button"
    >
      <i class="fa fa-sliders"></i>
      <small class="px-3">Filters</small>
      <span *ngIf="completedValue !== 'all'" class="badge badge-primary d-none d-sm-inline-block d-md-none"> 1 </span>
    </button>
    <span class="badge badge-light p-3 d-none d-md-inline-block text-reset" *ngIf="completedValue !== 'all'">
      Task Status:
      <span class="font-weight-normal">{{completedValue === 'true' ? 'completed' : 'incomplete'}}</span>
      <i class="fa fa-fw fa-times ml-3 mr-2 clickable" (click)="completed.next('all')" data-t="remove-task-filters"></i>
    </span>
  </div>
  <div ngbDropdown container="body" class="d-flex align-items-center" *ngIf="false">
    Sort by <button class="btn btn-link" ngbDropdownToggle>Score</button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem>Score</button>
    </div>
  </div>
</div>
<div class="row flex-grow-1">
  <div class="col-lg-6 px-1 d-flex flex-column flex-grow-1">
    <app-loading
      *ngIf="searching && !tasks?.length"
      innerClass="fa-4x"
      outerClass="align-self-center text-brand-light-blue"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div class="d-flex flex-column flex-grow-1 flex-basis-lg-0 scrolling-sidebar px-2">
      <app-task-card
        *ngFor="let task of tasks; let i = index"
        [isSelected]="selected?.id === task.id"
        [(isSidebarCollapsed)]="isSidebarCollapsed"
        [task]="selected?.id === task.id ? selected : task"
        [claim]="claim"
        (taskResolved)="onTaskResolved()"
        [attr.data-t]="'task-'+i"
      ></app-task-card>
    </div>
  </div>
  <div class="col-lg-6 px-1 d-none d-lg-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 flex-basis-lg-0 px-2 scrolling-sidebar">
      <app-task-claim-card
        *ngIf="selected?.id; else overview"
        [claim]="claim"
        [isPreviousEnabled]="!!prev"
        [isNextEnabled]="!!next"
        (taskNavButtonClick)="onTaskNavigation($event)"
        [selected]="selected"
        [(isSidebarCollapsed)]="isSidebarCollapsed"
        class="mt-2"
      ></app-task-claim-card>
    </div>
  </div>
  <div
    class="offcanvas-collapse-lg d-md-flex flex-column d-lg-none scrolling-main"
    [(ngbCollapse)]="isSidebarCollapsed"
  >
    <div class="modal-backdrop fade" [ngClass]="{'show': !isSidebarCollapsed}"></div>
    <div class="bg-white flex-grow-1 collapse-content">
      <app-task-claim-card
        *ngIf="selected?.id; else overview"
        [claim]="claim"
        [isPreviousEnabled]="!!prev"
        [isNextEnabled]="!!next"
        (taskNavButtonClick)="onTaskNavigation($event)"
        [selected]="selected"
        [(isSidebarCollapsed)]="isSidebarCollapsed"
      ></app-task-claim-card>
    </div>
  </div>
</div>
<ng-template #overview>
  <div class="card detail-card-new bg-light my-4 border-left-8 border-success">
    <div class="card-header border-0">
      <h4>Task Group Progress <small class="text-muted"> - {{taskGroup?.name}}</small></h4>
    </div>
    <div class="card-body d-flex flex-column">
      <div class="d-flex w-100">
        <span class="flex-grow-1 align-self-center mr-3">
          <ngb-progressbar
            type="success"
            class="progress"
            [max]="taskGroupStats?.totalTasks"
            [value]="taskGroupStats?.completedTasks"
            [striped]="true"
          ></ngb-progressbar>
        </span>
        <small class="flex-shrink-1">
          {{ taskGroupStats?.completedTasks | number }} / {{ taskGroupStats?.totalTasks | number }}
        </small>
      </div>
    </div>
  </div>
  <div class="card detail-card-new bg-light my-4 border-left-8 border-primary">
    <div class="card-header border-0">
      <h4>Time per Task <small class="text-muted"> - {{taskGroup?.name}}</small></h4>
    </div>
    <div class="card-body">
      <div class="w-75 mx-auto d-flex justify-content-center">
        <span class="d-flex flex-fill flex-column align-items-center">
          <h5>Average per Task</h5>
          <span class="h1 d-flex align-items-center">
            {{ taskGroupStats?.timeToComplete / taskGroupStats?.totalTasks | number:'1.0-0' }} minutes
          </span>
        </span>
        <span class="d-flex flex-fill flex-column align-items-center border-left">
          <h5>Total Time for Group</h5>
          <span class="h1 d-flex align-items-center">
            {{ taskGroupStats?.timeToComplete / 60 | number:'1.0-0' }} hours</span
          >
        </span>
      </div>
    </div>
    <span class="position-absolute right-icon see-thru d-flex align-items-center">
      <i class="fa fa-fw fa-4x fa-clock-o text-muted"></i>
    </span>
  </div>
  <div class="card detail-card-new bg-light my-4">
    <div class="card-header border-0">
      <h4>Addressed vs Outstanding A/R <small class="text-muted"> - {{taskGroup?.name}}</small></h4>
    </div>
    <div class="card-body d-flex flex-column">
      <h4 class="card-title"></h4>
      <div class="w-75 align-self-center">
        <canvas
          baseChart
          [datasets]="cashCollected"
          [labels]="labels"
          [options]="chartOptions"
          [legend]="chartLegend"
          [plugins]="chartPlugins"
        >
        </canvas>
      </div>
    </div>
  </div>
</ng-template>
