import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { BotJob, Claim, Organization, PayerPlan } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { deployBot, StatusMap } from './deploy-bot'
import { RunBotOpts, startBot } from './start-bot'
import type { AsSuccess } from './bot-types'
import { debug } from 'app/shared/utils/debug'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'

export const hostname = location.hostname === 'localhost' ? 'dev.janus-ai.com' : location.hostname
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
type BotOutputOption = {
  name: 'logs' | 'result'
}
type Branch = {
  branch: string
  images: { tag: string }[]
}

const TAG = 'bot-qa'
/**
 * Modal for running qa-bots
 * Has a dropdown to select branches, and a deploy button for deploying image to eks through argocd
 *
 * @export
 * @class BotModal
 * @implements {OnInit}
 */
@Component({
  selector: 'bot-modal',
  templateUrl: './bot-modal.component.html',
})
export class BotModal implements OnInit {
  organization: Organization = null
  deploying: boolean = false
  deployedBranchName: string = null
  branches: Branch[] = []
  botJobs: BotJob[] = []
  selectedBranch = new FormControl<Branch | null>(null)
  selectedBotJob = new FormControl<BotJob | null>(null)
  bot: AsSuccess<StatusMap['deployBot']> = null
  logs: string[] = []
  translate: Record<string, any> = null
  warningMessage: string = ''
  output: BotOutputOption = { name: 'logs' }
  runBotOpts: PartialBy<RunBotOpts, 'url'>
  isLegacyPayerPlan = true

  @Input() claim: Claim = null

  get payerPlan(): PayerPlan {
    return this.selectedBotJob?.value?.botJobPayerPlanBridges[0]?.payerPlan
  }

  constructor(
    public modal: NgbActiveModal,
    private orgService: OrgService,
    private authenticationService: AuthenticationService,
    private botJobsService: BotJobsService,
    private payerPlanService: PayerPlanService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadOrganization()
    await Promise.all([this.loadBranches(), this.loadBotJobs()])
  }

  selectBranch($event: Branch): void {
    this.selectedBranch.setValue($event)
  }

  selectBotJob($event: BotJob): void {
    this.selectedBotJob.setValue($event)
    this.setRunBotOptions()
  }

  handleModalClose(): void {
    this.modal.dismiss()
  }

  async deployBranch(): Promise<void> {
    let selectedbranch: Branch = this.selectedBranch?.value
    if (selectedbranch) {
      try {
        const branch = selectedbranch.branch
        if (branch) {
          this.deploying = true
          this.bot = await deployBot(branch)
          this.deploying = false
          this.deployedBranchName = branch
        }
      } catch (e) {
        debug(TAG, e)
      }
    }
  }

  startButtonDisabled = (): boolean => !this.selectedBranch.value || !this.selectedBotJob.value

  runBotOptionsValid = (): boolean => Object.values(this.runBotOpts).every((v) => !!v)

  setRunBotOptions = (): void => {
    if (!this.selectedBotJob.value) {
      debug(TAG, 'tried to set run bot options without botjob set')
      return
    }

    const { payer, portal, credentialType } = this.selectedBotJob.value as BotJob
    this.runBotOpts = {
      orgId: this.organization.id,
      claimId: this.claim.providerClaimId,
      payer,
      portal,
      credentialType,
    }
  }

  async startBot(): Promise<void> {
    if (!this.runBotOptionsValid()) {
      this.warningMessage =
        'One or more bot parameters above is not defined. Running the bot is still possible but will likely result in runtime errors'
      debug(TAG, this.warningMessage)
    }

    if (this.bot) {
      this.logs = []
      const bot = await startBot(
        {
          url: this.bot.data.url,
          ...this.runBotOpts,
        },
        async ({ data }) => {
          this.logs.push(data.log)
        },
      )
      this.translate = bot.data.claimScraped
    }
  }

  onSelectOutput(output: BotOutputOption): void {
    this.output = output
  }

  branchInputFormatter = (x: Branch): string => x.branch
  branchResultFormatter = (x: Branch): string => x.branch
  branchSearchFilter = (branch: Branch, target: string): boolean => {
    return branch.branch.toLowerCase().includes(target.toLowerCase())
  }

  botJobResultFormatter = (x: BotJob): string => x.name
  botJobInputFormatter = (x: BotJob): string => x.name
  botJobSearchFilter = (botJob: BotJob, target: string): boolean => {
    return botJob.name.toLowerCase().includes(target.toLowerCase())
  }

  private async loadBotJobs(): Promise<void> {
    let payerId: string
    if (await this.payerPlanService.getLegacyEnabled(this.organization.id)) {
      payerId = this.claim.payer?.parentId || this.claim.payer?.id
    } else {
      payerId = this.claim.payerPlanId
    }
    const botJobRes = this.botJobsService.getBotJobs(0, payerId, null, null, false, this.organization.id, true)
    const { entities } = (await botJobRes.pipe(first()).toPromise()).data.botJobsV2

    if (entities.length === 0) {
      this.warningMessage = 'Bot parameters could not be found because no botjob exists for this claim'
      debug(TAG, this.warningMessage)
      debug(
        TAG,
        `claim payer exists: ${!!this.claim.payer}, claim payer parentId was: ${
          this.claim.payer?.parentId
        }, claim payer id was: ${this.claim.payer?.id}, organization id was: ${this.organization.id}`,
      )
    } else if (entities.length === 1) {
      this.selectBotJob(entities[0])
      this.botJobs = entities
    } else {
      this.botJobs = entities
    }
  }

  private async loadBranches(): Promise<void> {
    try {
      const branches = await fetch(`https://ephemeral-coordinator.${hostname}/bot-qa/branches`, {
        method: 'GET',
      })
      const data = await branches.json()
      if (!data || data?.length === 0) {
        throw new Error('Failed to load branches from ephemeral coordinator')
      }
      this.branches = data
    } catch (error) {
      debug(TAG, error)
      this.warningMessage = 'Failed to load branches'
    }
  }

  private async loadOrganization(): Promise<void> {
    try {
      const orgResponse = await this.orgService
        .getOrg(this.authenticationService.getUser()?.orgId)
        .pipe(first())
        .toPromise()
      if (!orgResponse) {
        throw new Error('Org response not received from org service')
      }

      const { organization } = orgResponse.data
      this.organization = organization
      this.isLegacyPayerPlan = await this.payerPlanService.getLegacyEnabled(this.organization.id)
    } catch (error) {
      debug(TAG, error)
      this.warningMessage =
        'Could not find current org. This should not happen there is likey an outage or deeper problem then the qa-system'
      debug(TAG, this.warningMessage)
    }
  }
}
