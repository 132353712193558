import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { PayersService } from 'app/shared/services/payers.service'
import { debug } from 'app/shared/utils/debug'
import { Payer } from 'generated/graphql'
import { defer } from 'lodash'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators'

// Pseudo-payer that we use for "other" option selection
export const otherPayerOption: Payer = { id: null, name: '--- Other ---' }

/**
 * Component to allow searching for and selecting a payer
 * Should be in admin/payers/components
 *
 * @export
 * @class PayerTypeaheadComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-payer-typeahead',
  templateUrl: './payer-typeahead.component.html',
  styleUrls: ['./payer-typeahead.component.scss'],
})
export class PayerTypeaheadComponent implements OnInit {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() disabledPayerIds: string[] = []
  @Input() allowOther: boolean = false
  @Input() orgId: string = undefined // If supplied, this will only supply payers with the corresponding orgId (includeNullOrg exception)
  @Input() includeNullOrg: boolean = true // If supplied, this will also include legacy payers that have a null org - to be deprecated
  @Output() onSelect = new EventEmitter<Payer>()

  searching: boolean = false

  constructor(private payersService: PayersService) {}

  ngOnInit(): void {}

  /**
   * Get payers based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof PayerTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<Payer[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((search) => {
        if (search?.length < 2) {
          this.input?.dismissPopup()
        }
      }),
      filter((search) => search?.length >= 2),
      tap(() => (this.searching = true)),
      switchMap((search) =>
        from(this.payersService.searchPayers({ search, includeChildren: true })).pipe(
          catchError(() => {
            return of([])
          }),
          map((payers) => {
            let resultPayers = payers.filter((payer) => !this.disabledPayerIds.includes(payer.id))

            if (!this.includeNullOrg) {
              resultPayers = resultPayers.filter((payer) => !!payer.orgId)
            }

            if (this.orgId) {
              resultPayers = resultPayers.filter(
                (payer) => payer.orgId === this.orgId || (this.includeNullOrg && !payer.orgId),
              )
            }

            if (this.allowOther) {
              resultPayers = [...resultPayers, otherPayerOption]
            }

            return resultPayers
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {{ name: string }} x
   * @memberof PayerTypeaheadComponent
   */
  inputFormatter = (x: { name: string }): string => x.name

  /**
   * Format typeahead results list
   *
   * @param {{ name: string }} x
   * @memberof PayerTypeaheadComponent
   */
  resultFormatter = (x: { name: string }): string => x.name

  /**
   * Notify consumers that a payer has been selected
   *
   * @param {*} event
   * @memberof PayerTypeaheadComponent
   */
  onSelectPayer(event: { item: Payer }): void {
    let payer = event.item as Payer
    debug('payers', 'selected a payer', payer)
    this.onSelect.emit(payer)
    defer(() => (this.element.nativeElement.value = ''))
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof PayerTypeaheadComponent
   */
  clearPayer(event: Event): void {
    event.preventDefault()

    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
