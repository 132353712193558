<app-auth-layout-component>
  <div class="d-flex justify-content-center" *ngIf="loginError">
    <div class="d-flex alert alert-danger mb-4" role="alert" [innerHtml]="loginError"></div>
  </div>

  <div [ngSwitch]="errorPane" *ngIf="errorPane">
    <ng-container *ngSwitchCase="'STALE'">
      <app-auth-error-card title="Reactivate Your Account">
        <p>Your account has been inactive for more than 30 days.</p>
        <p>An email has been sent to you with a link to reactivate your account and log in.</p>
      </app-auth-error-card>
    </ng-container>
    <ng-container *ngSwitchCase="'INACTIVE'">
      <app-auth-error-card title="Account Disabled">
        <p>Your account has been disabled.</p>
        <p>Please contact your account administrator for assistance.</p>
      </app-auth-error-card>
    </ng-container>
    <ng-container *ngSwitchCase="'LOCKOUT'">
      <app-auth-error-card title="Account Locked">
        <p>Your account has been locked due to multiple invalid login attempts.</p>
        <p>You will need to reset your password to unlock this account.</p>
        <div class="pt-3">
          <a [routerLink]="['/login/reset']">Reset Password</a>
        </div>
      </app-auth-error-card>
    </ng-container>
    <ng-container *ngSwitchCase="'NO_ORG'">
      <app-auth-error-card title="Inactive Organization">
        <p>Your account is not associated with an active organization.</p>
        <p>
          Please contact your account administrator or submit a ticket at
          <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
        </p>
      </app-auth-error-card>
    </ng-container>
  </div>

  <ng-container *ngIf="!errorPane" [ngSwitch]="view">
    <app-username *ngSwitchCase="'USERNAME'" (onSubmit)="receiveUsername($event)"></app-username>

    <app-password
      *ngSwitchCase="'PASSWORD'"
      [loading]="isAuthenticating"
      (onSubmit)="receivePassword($event)"
    ></app-password>

    <app-mfa *ngSwitchCase="'MFA'" [loading]="isAuthenticating" (onSubmit)="receiveMfaCode($event)"></app-mfa>

    <app-org-switcher *ngSwitchCase="'SELECT_ORG'"></app-org-switcher>

    <app-loading
      *ngSwitchCase="'LOADING'"
      innerClass="fa-3x"
      class="d-flex justify-content-center text-brand-light-blue"
    ></app-loading>
  </ng-container>
</app-auth-layout-component>
