import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { SaturatedTaskComment } from 'app/pathfinder/tasks/tasks.service'
import { FeatureFlags, FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { CompletionType, Task, Workflow } from 'generated/graphql'
import { sortBy } from 'lodash'
import moment from 'moment'

/**
 * Component to display the tasks belonging to a workflow
 *
 * @export
 * @class WorkflowTasksComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-workflow-tasks',
  templateUrl: './workflow-tasks.component.html',
  styleUrls: ['./workflow-tasks.component.scss'],
})
export class WorkflowTasksComponent implements OnInit, OnChanges {
  @Input() workflow: Workflow = null
  @Input() isSelected: boolean = false
  @Input() comments: SaturatedTaskComment[] = []
  showComments = false

  @Output() completeTask = new EventEmitter<{ id: string; completion: CompletionType; outcome: number }>()
  @Output() navigate = new EventEmitter<string>()
  @Output() reject = new EventEmitter<{ id: string; reject: boolean }>() // true = reject, false = unreject

  outcomes: UntypedFormGroup
  tasks: Task[] = []
  selectedTaskId = null

  isRejected: boolean = false
  completing: boolean = false

  isDemoEnabled: boolean = false

  constructor(private toast: ToastService, private featureFlagService: FeatureFlagService) {}

  async ngOnInit(): Promise<void> {
    this.isDemoEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.demo)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.completing = false

    if (this.workflow?.tasks?.length >= 0 && this.isSelected) {
      let tasks = sortBy(this.workflow?.tasks, (a, b) => moment(a?.createdAt))

      this.outcomes = new UntypedFormGroup({})

      tasks.forEach((task) => {
        this.outcomes.addControl(
          task.id,
          new UntypedFormControl(task?.workflowTypeTaskOutcomeIndex, Validators.required),
        )
      })

      this.tasks = tasks
      if (!this.workflow.completed && (!changes['comments'] || changes['comments']?.isFirstChange())) {
        this.goToTask(tasks[tasks.length - 1]?.id)
      }

      this.isRejected = this.workflow.completed && this.workflow.completionType === CompletionType.Rejected
    }
  }

  /**
   * Send task navigation to parent component
   *
   * @param {string} id
   * @memberof WorkflowTasksComponent
   */
  goToTask(id: string): void {
    this.navigate.emit(id)
    this.selectedTaskId = id
  }

  /**
   * Handle opening / closing of task card
   *
   * @param {string} id
   * @param {boolean} collapsed
   * @memberof WorkflowTasksComponent
   */
  handleTaskToggle(id: string, collapsed: boolean): void {
    if (!collapsed) {
      this.navigate.emit(id)
      this.selectedTaskId = id
    }
  }

  /**
   * Notify parent that a task has been completed with an outcome
   *
   * @param {Task} task
   * @memberof WorkflowTasksComponent
   */
  finishTask(task: Task): void {
    this.completing = true
    try {
      this.outcomes.get(task.id).markAsTouched()
      let outcome = this.outcomes.get(task.id)?.value
      if (outcome === null && this.workflow?.workflowType?.tasks[task.workflowTypeTaskIndex]?.outcomes?.length) {
        this.toast.error('Please select an outcome', JSON.stringify([task, this.workflow]))
        this.completing = false
        return
      }
      this.completeTask.emit({ id: task.id, completion: CompletionType.Completed, outcome: outcome })
    } catch (e) {
      debug('tasks', 'finish task error', JSON.stringify(e))
    } finally {
      // uncommented to resolve UI-644
      this.completing = false
    }
  }
}
