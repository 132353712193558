<div class="my-2">Emails</div>
<div *ngIf="isLoadingPage" class="h-100 d-flex align-items-center justify-content-center">
  <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
</div>
<ngb-accordion
  #accordion="ngbAccordion"
  (panelChange)="loadEmailContent($event)"
  [closeOthers]="true"
  data-t="emails-list"
>
  <ng-container *ngFor="let email of emailRender(); let i = index; trackBy: trackBy">
    <ngb-panel id="p-{{ i }}" [cardClass]="cardClass">
      <ng-template ngbPanelHeader let-opened="opened">
        <button type="button" class="btn w-100 btn-link link" ngbPanelToggle data-t="task-accordion-button">
          <div class="d-flex flex-row align-items-center with-arrow" [class.rotated]="opened">
            <span class="item link d-flex flex-grow-1">{{ email?.created | date : 'MM/dd/yyyy - h:mm:ss a' }}</span>
          </div>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-loading
          *ngIf="isLoadingEmail"
          innerClass="fa-2x"
          outerClass="align-self-center text-brand-light-blue"
          class="d-flex justify-content-center my-5"
        ></app-loading>
        <div class="mx-5 my-3" *ngIf="!isLoadingEmail">
          <div class="row my-2">
            <div class="col-xl-2 font-weight-bold">From</div>
            <div class="col-xl-10">{{ currentEmail?.from }}</div>
          </div>
          <div class="row my-2">
            <div class="col-xl-2 font-weight-bold">To</div>
            <div class="col-xl-10">{{ currentEmail?.to.join(', ') }}</div>
          </div>
          <div class="row mt-5 mb-3">
            <div class="col-xl-2 font-weight-bold text-nowrap">Subject</div>
            <div class="col-xl-10">{{ currentEmail?.subject }}</div>
          </div>
          <div class="row">
            <div class="col p-5 border rounded border-grey bg-light overflow-auto">
              <span
                *ngIf="currentEmail?.html || currentEmail?.text"
                [innerHTML]="htmlEmailContent ? htmlEmailContent : currentEmail?.text"
              ></span>
              <span *ngIf="!currentEmail?.html && !currentEmail?.text" class="d-flex justify-content-center">
                Email has no content to display
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ng-container>
</ngb-accordion>
<div class="w-100 px-3 d-flex justify-content-end">
  <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
</div>
