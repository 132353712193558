import { Component, OnInit } from '@angular/core'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { ToastService } from 'app/shared/services/toast.service'
import { ObserveAccessControlsService } from '../observe-access-controls.service'
import { ListResponseMetaData, ObserveAuditTrail } from 'generated/graphql'
import { take } from 'rxjs/operators'
import { SortEvent } from 'app/shared/directives/sortable.directive'

const PAGE_SIZE = 50

@Component({
  selector: 'app-observe-access-control-audit-trail',
  templateUrl: './observe-access-control-audit-trail.page.html',
  styleUrls: ['./observe-access-control-audit-trail.page.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class ObserveAccessControlAuditTrailPage implements OnInit {
  loading: boolean = false
  observeAuditTrailRecords: ObserveAuditTrail[] = []
  pagingMetadata: ListResponseMetaData = {
    total: 0,
    sort: 'createdAt:desc',
    offset: 0, 
    limit: PAGE_SIZE
  }
  lastModifiedAclDate: string = null
  currentPage = 1

  constructor(
    private observeService: ObserveAccessControlsService,
    private toast: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.getAuditRecords() 
      this.lastModifiedAclDate  =  this.observeService.lastModifiedDate ||
      (await this.observeService.getLastModifiedAclDate().pipe(take(1)).toPromise()).data.lastModifiedAclDate
    } catch (err) {
      this.toast.error('Cannot fetch ACL audit records', JSON.stringify(err))
      this.loading = false
    }
  }

  async getAuditRecords(): Promise<void> {
    this.loading = true
    const data = (await this.observeService.getAclAuditRecords(
      PAGE_SIZE, this.pagingMetadata.offset, this.pagingMetadata.sort).pipe(take(1)).toPromise()).data
    this.observeAuditTrailRecords = data.getAclAuditRecords.entities
    this.pagingMetadata.total = data.getAclAuditRecords?.meta.total
    this.loading = false
  }

  onPageUpdate(event: PaginationUpdateEvent): void {
    if(event.newPageNumber > this.currentPage) {
      this.pagingMetadata.offset = this.pagingMetadata.offset + PAGE_SIZE
    } else {
      this.pagingMetadata.offset = this.pagingMetadata.offset - PAGE_SIZE
    }
  
    this.currentPage = event.newPageNumber
    this.getAuditRecords()
  }

  onSort($event: SortEvent): void {
    if($event.direction) {
      this.pagingMetadata = {
        ...this.pagingMetadata, 
        sort: `${$event.column}:${$event.direction}`
      }
      this.getAuditRecords()
    }
  }
}
