import { EdgeConfig, NodeConfig } from '@antv/g6'
import {
  GREEN_EDGE,
  GREEN_NODE,
  IMA_EDGE,
  IMA_NODE,
  ORANGE_EDGE,
  ORANGE_NODE,
  PURPLE_EDGE,
  PURPLE_NODE,
  RED_EDGE,
  RED_NODE,
} from '../nodes'

export const MainNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  // {
  //   id: 'start',
  //   label: 'ADR Requests for NGS - Procedural CPTs\nCount:2281',
  //   ...GREEN_NODE,
  // },
  {
    id: '1',
    label: 'Open Patient WQ\nCount: 439',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 439,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select and Open Encounter\nCount: 439',
    totalTime: '15',
    totalPercent: '100',
    totalCount: '439',
    ...GREEN_NODE,
  },
  {
    id: '3a',
    label: 'Mark Encounter as\nNo Prior Auth Required\nCount: 23',
    totalTime: '105',
    totalPercent: '5',
    totalCount: '23',
    impactOnDenial: 10,
    relativeImpactOnCollection: -6,
    absoluteImpactOnCollection: -1005.23,
    ...ORANGE_NODE,
  },
  {
    id: '3b',
    label: 'Login to DME Software\nCount: 416',
    totalTime: '57',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open Browser and Login to Evicore\nCount: 416',
    totalTime: '91',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Initiate Authorization for Sleep\nCount: 416',
    totalTime: '109',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '7',
    label: 'Complete Requesting\nProvider Information\nCount: 416',
    totalTime: '140',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8a',
    label: 'Add Contact Info\nCount: 416',
    totalTime: '153',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8b',
    label: 'Patient Eligibility Lookup\nCount: 416',
    totalTime: '175',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '9a',
    label: 'Member Not Found\nCount: 41',
    totalTime: '180',
    totalPercent: '9',
    totalCount: '41',
    impactOnDenial: 32,
    relativeImpactOnCollection: -23,
    absoluteImpactOnCollection: -1420.92,
    ...RED_NODE,
  },
  {
    id: '9b',
    label: 'Update Encounter with Status\nCount: 41',
    totalTime: '208',
    totalPercent: '9',
    totalCount: '41',
    impactOnDenial: 32,
    relativeImpactOnCollection: -23,
    absoluteImpactOnCollection: -1420.92,
    ...RED_NODE,
  },
  {
    id: '9c',
    label: 'Transfer to Pre-Reg/IV WQ\nCount: 41',
    totalTime: '217',
    totalPercent: '9',
    totalCount: '41',
    impactOnDenial: 32,
    relativeImpactOnCollection: -23,
    absoluteImpactOnCollection: -1420.92,
    ...RED_NODE,
  },
  {
    id: '10',
    label: 'Member Found\nCount: 375',
    totalTime: '180',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '11',
    label: 'Enter Procedure Code\nCount: 375',
    totalTime: '184',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '12a',
    label: 'Enter Primary Diagnosis\nCount: 144',
    totalTime: '188',
    totalPercent: '33',
    totalCount: '144',
    impactOnDenial: 8,
    relativeImpactOnCollection: -6,
    absoluteImpactOnCollection: -870.22,
    ...ORANGE_NODE,
  },
  {
    id: '12b',
    label: 'Enter Primary Diagnosis\nand Secondary Diagnosis\nCount: 231',
    totalTime: '192',
    totalPercent: '53',
    totalCount: '231',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Add Site of Service\nCount: 375',
    totalTime: '216',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '14a',
    label: 'Save and Finish Later\nCount: 29',
    totalTime: '218',
    totalPercent: '7',
    totalCount: '29',
    impactOnDenial: 64,
    relativeImpactOnCollection: -53,
    absoluteImpactOnCollection: -2230.54,
    ...RED_NODE,
  },
  {
    id: '14b',
    label: 'Complete Clinical Information Collection\nCount: 346',
    totalTime: '310',
    totalPercent: '79',
    totalCount: '346',
    ...GREEN_NODE,
  },
  {
    id: '15a',
    label: 'Case Approved\nCount: 240',
    totalTime: '324',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '15b',
    label: 'Case Pending\nCount: 33',
    totalTime: '324',
    totalPercent: '8',
    totalCount: '33',
    ...ORANGE_NODE,
  },
  {
    id: '15c',
    label: 'Case Denied\nCount: 73',
    totalTime: '324',
    totalPercent: '17',
    totalCount: '73',
    impactOnDenial: 85,
    relativeImpactOnCollection: -80,
    absoluteImpactOnCollection: -2230.54,
    ...RED_NODE,
  },
  {
    id: '16a',
    label: 'Authorization Number Pasted\ninto Encounter\nCount: 240',
    totalTime: '334',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '16b',
    label: 'Account Transferred\nCount: 73',
    totalTime: '343',
    totalPercent: '17',
    totalCount: '73',
    ...RED_NODE,
  },
  {
    id: '17a',
    label: 'Take Screenshot of Authorization\nCount: 219',
    totalTime: '343',
    totalPercent: '50',
    totalCount: '219',
    ...GREEN_NODE,
  },
  {
    id: '17b',
    label: 'No Screenshot Taken\nCount: 21',
    totalTime: '343',
    totalPercent: '5',
    totalCount: '21',
    impactOnDenial: 0,
    relativeImpactOnCollection: -10,
    absoluteImpactOnCollection: -1308.21,
    ...ORANGE_NODE,
  },
  {
    id: '18a',
    label: 'Upload Screenshot to EMR\nCount: 212',
    totalTime: '374',
    totalPercent: '48',
    totalCount: '212',
    ...GREEN_NODE,
  },
  {
    id: '18b',
    label: 'Save Screenshot Locally\nCount: 7',
    totalTime: '353',
    totalPercent: '2',
    totalCount: '7',
    impactOnDenial: 0,
    relativeImpactOnCollection: -5,
    absoluteImpactOnCollection: -801.35,
    ...ORANGE_NODE,
  },
  {
    id: '19',
    label: 'Update Encounter Status in WQ\nCount: 240',
    totalTime: '387',
    totalPercent: '50',
    totalCount: '240',
    ...GREEN_NODE,
  },
]

export const MainEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  // {
  //   id: 'path4a4b',
  //   source: '4a',
  //   target: '4b',
  //   label: '319',
  //   percent: '14',
  //   time: 408,
  //   count: 319,
  //   style: {
  //     lineDash: [4, 8],
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth * 1.3,
  //     radius: edgeRadius,
  //     stroke: redEdgeColor,
  //     strokeOpacity: 1,
  //     endArrow: '',
  //   },
  //   labelCfg: {
  //     refX: 5,
  //     refY: 20,
  //     style: {
  //       fontSize: 14,
  //       fontWeight: 400,
  //     },
  //   },
  // },
  // {
  //   id: 'path4b2',
  //   source: '4b',
  //   target: '2',
  //   label: '291',
  //   percent: '13',
  //   time: 105,
  //   count: 291,
  //   style: {
  //     lineDash: [4, 8],
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth * 1.3,
  //     radius: edgeRadius,
  //     stroke: redEdgeColor,
  //     strokeOpacity: 1,
  //     endArrow: defaultArrow,
  //   },
  //   labelCfg: {
  //     refX: 5,
  //     refY: 20,
  //     style: {
  //       fontSize: 14,
  //       fontWeight: 400,
  //     },
  //   },
  // },
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '439',
    percent: '100',
    time: 15,
    count: 439,
    ...GREEN_EDGE,
  },
  {
    id: 'path23a',
    source: '2',
    target: '3a',
    label: '23',
    percent: '5',
    time: 90,
    count: 23,
    ...ORANGE_EDGE,
  },
  {
    id: 'path23b',
    source: '2',
    target: '3b',
    label: '416',
    percent: '95',
    time: 42,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path3b5',
    source: '3b',
    target: '5',
    label: '416',
    percent: '95',
    time: 34,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '416',
    percent: '95',
    time: 18,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    label: '416',
    percent: '95',
    time: 31,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path78a',
    source: '7',
    target: '8a',
    label: '416',
    percent: '95',
    time: 13,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path8a8b',
    source: '8a',
    target: '8b',
    label: '416',
    percent: '95',
    time: 22,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path8b9a',
    source: '8b',
    target: '9a',
    label: '41',
    percent: '9',
    time: 5,
    count: 41,
    ...RED_EDGE,
  },
  {
    id: 'path9a9b',
    source: '9a',
    target: '9b',
    label: '41',
    percent: '9',
    time: 28,
    count: 41,
    ...RED_EDGE,
  },
  {
    id: 'path9b9c',
    source: '9b',
    target: '9c',
    label: '41',
    percent: '9',
    time: 9,
    count: 41,
    ...RED_EDGE,
  },
  {
    id: 'path8b10',
    source: '8b',
    target: '10',
    label: '375',
    percent: '85',
    time: 5,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1011',
    source: '10',
    target: '11',
    label: '375',
    percent: '85',
    time: 4,
    count: 375,
    ...GREEN_EDGE,
  },

  {
    id: 'path1112a',
    source: '11',
    target: '12a',
    label: '144',
    percent: '33',
    time: 4,
    count: 144,
    ...ORANGE_EDGE,
  },

  {
    id: 'path1112b',
    source: '11',
    target: '12b',
    label: '231',
    percent: '53',
    time: 8,
    count: 231,
    ...GREEN_EDGE,
  },

  // {
  //   id: "path1415",
  //   source: "14",
  //   target: "15",
  //   label: "1962",
  //   percent: "86",
  //   time: 20,
  //   count: 1962,
  //   style: {
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth*3.1,
  //     radius: edgeRadius,
  //     // stroke: edgeColor,
  //     stroke: greenEdgeColor,
  //     strokeOpacity: 1,
  //      endArrow: defaultArrow,
  //   },
  //   labelCfg: {
  //     refY: 20,
  //     refX: 5,
  //   },
  // },
  {
    id: 'path12a13',
    source: '12a',
    target: '13',
    label: '144',
    percent: '33',
    time: 24,
    count: 144,
    ...ORANGE_EDGE,
  },
  {
    id: 'path12b13',
    source: '12b',
    target: '13',
    label: '231',
    percent: '53',
    time: 24,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path1314a',
    source: '13',
    target: '14a',
    label: '29',
    percent: '7',
    time: 2,
    count: 29,
    ...RED_EDGE,
  },
  {
    id: 'path1314b',
    source: '13',
    target: '14b',
    label: '346',
    percent: '79',
    time: 94,
    count: 346,
    ...GREEN_EDGE,
  },
  {
    id: 'path14b15a',
    source: '14b',
    target: '15a',
    label: '240',
    percent: '55',
    time: 14,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path14b15b',
    source: '14b',
    target: '15b',
    label: '33',
    percent: '8',
    time: 14,
    count: 33,
    ...ORANGE_EDGE,
  },
  {
    id: 'path14a15c',
    source: '14b',
    target: '15c',
    label: '73',
    percent: '17',
    time: 14,
    count: 73,
    ...RED_EDGE,
  },
  {
    id: 'path15a16a',
    source: '15a',
    target: '16a',
    label: '240',
    percent: '55',
    time: 10,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path15c16b',
    source: '15c',
    target: '16b',
    label: '73',
    percent: '17',
    time: 19,
    count: 73,
    ...RED_EDGE,
  },
  {
    id: 'path16a17a',
    source: '16a',
    target: '17a',
    label: '219',
    percent: '50',
    time: 9,
    count: 219,
    ...GREEN_EDGE,
  },
  {
    id: 'path16a17b',
    source: '16a',
    target: '17b',
    label: '21',
    percent: '5',
    time: 0,
    count: 21,
    ...ORANGE_EDGE,
  },
  {
    id: 'path17a18a',
    source: '17a',
    target: '18a',
    label: '212',
    percent: '48',
    time: 31,
    count: 212,
    ...GREEN_EDGE,
  },
  {
    id: 'path17a18b',
    source: '17a',
    target: '18b',
    label: '7',
    percent: '2',
    time: 10,
    count: 7,
    ...ORANGE_EDGE,
  },
  {
    id: 'path18a19',
    source: '18a',
    target: '19',
    label: '212',
    percent: '48',
    time: 13,
    count: 212,
    ...GREEN_EDGE,
  },
  {
    id: 'path18b19',
    source: '18b',
    target: '19',
    label: '7',
    percent: '2',
    time: 13,
    count: 7,
    ...ORANGE_EDGE,
  },
  {
    id: 'path17b19',
    source: '17b',
    target: '19',
    label: '21',
    percent: '5',
    time: 12,
    count: 21,
    ...ORANGE_EDGE,
  },
  {
    id: 'path15b19',
    source: '15b',
    target: '19',
    label: '33',
    percent: '8',
    time: 13,
    count: 33,
    ...ORANGE_EDGE,
  },
]

export const IdealNodes = [
  {
    id: '1',
    label: 'Open Patient WQ\nCount: 439',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 439,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select and Open Encounter\nCount: 439',
    totalTime: '15',
    totalPercent: '100',
    totalCount: '439',
    ...GREEN_NODE,
  },
  {
    id: '3b',
    label: 'Login to DME Software\nCount: 416',
    totalTime: '57',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open Browser and Login to Evicore\nCount: 416',
    totalTime: '91',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Initiate Authorization for Sleep\nCount: 416',
    totalTime: '109',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '7',
    label: 'Complete Requesting\nProvider Information\nCount: 416',
    totalTime: '140',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8a',
    label: 'Add Contact Info\nCount: 416',
    totalTime: '153',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8b',
    label: 'Patient Eligibility Lookup\nCount: 416',
    totalTime: '175',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '10',
    label: 'Member Found\nCount: 375',
    totalTime: '180',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '12b',
    label: 'Enter Primary Diagnosis\nand Secondary Diagnosis\nCount: 231',
    totalTime: '192',
    totalPercent: '53',
    totalCount: '231',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Add Site of Service\nCount: 375',
    totalTime: '216',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '14b',
    label: 'Complete Clinical Information Collection\nCount: 346',
    totalTime: '310',
    totalPercent: '79',
    totalCount: '346',
    ...GREEN_NODE,
  },
  {
    id: '15a',
    label: 'Case Approved\nCount: 240',
    totalTime: '324',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '16a',
    label: 'Authorization Number Pasted\ninto Encounter\nCount: 240',
    totalTime: '334',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '17a',
    label: 'Take Screenshot of Authorization\nCount: 219',
    totalTime: '343',
    totalPercent: '50',
    totalCount: '219',
    ...GREEN_NODE,
  },
  {
    id: '18a',
    label: 'Upload Screenshot to EMR\nCount: 212',
    totalTime: '374',
    totalPercent: '48',
    totalCount: '212',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Update Encounter Status in WQ\nCount: 240',
    totalTime: '387',
    totalPercent: '50',
    totalCount: '240',
    ...GREEN_NODE,
  },
]
export const IdealEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '439',
    percent: '100',
    time: 15,
    count: 439,
    ...GREEN_EDGE,
  },
  {
    id: 'path23b',
    source: '2',
    target: '3b',
    label: '416',
    percent: '95',
    time: 42,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path3b5',
    source: '3b',
    target: '5',
    label: '416',
    percent: '95',
    time: 34,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '416',
    percent: '95',
    time: 18,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    label: '416',
    percent: '95',
    time: 31,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path78a',
    source: '7',
    target: '8a',
    label: '416',
    percent: '95',
    time: 13,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path8a8b',
    source: '8a',
    target: '8b',
    label: '416',
    percent: '95',
    time: 22,
    count: 416,
    ...GREEN_EDGE,
  },

  {
    id: 'path8b10',
    source: '8b',
    target: '10',
    label: '375',
    percent: '85',
    time: 5,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1011',
    source: '10',
    target: '11',
    label: '375',
    percent: '85',
    time: 4,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1112b',
    source: '11',
    target: '12b',
    label: '231',
    percent: '53',
    time: 8,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path12b13',
    source: '12b',
    target: '13',
    label: '231',
    percent: '53',
    time: 24,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path1314b',
    source: '13',
    target: '14b',
    label: '346',
    percent: '79',
    time: 94,
    count: 346,
    ...GREEN_EDGE,
  },
  {
    id: 'path14b15a',
    source: '14b',
    target: '15a',
    label: '240',
    percent: '55',
    time: 14,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path15a16a',
    source: '15a',
    target: '16a',
    label: '240',
    percent: '55',
    time: 10,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path16a17a',
    source: '16a',
    target: '17a',
    label: '219',
    percent: '50',
    time: 9,
    count: 219,
    ...GREEN_EDGE,
  },
  {
    id: 'path17a18a',
    source: '17a',
    target: '18a',
    label: '212',
    percent: '48',
    time: 31,
    count: 212,
    ...GREEN_EDGE,
  },
  {
    id: 'path18a19',
    source: '18a',
    target: '19',
    label: '212',
    percent: '48',
    time: 13,
    count: 212,
    ...GREEN_EDGE,
  },
]
// }

export const PathfinderNodes = [
  {
    id: '1',
    label: 'Open Patient WQ\nCount: 439',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 439,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select and Open Encounter\nCount: 439',
    totalTime: '15',
    totalPercent: '100',
    totalCount: '439',
    ...GREEN_NODE,
  },
  {
    id: '3b',
    label: 'Login to DME Software\nCount: 416',
    totalTime: '57',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open Browser and Login to Evicore\nCount: 416',
    totalTime: '91',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Initiate Authorization for Sleep\nCount: 416',
    totalTime: '109',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '7',
    label: 'Complete Requesting\nProvider Information\nCount: 416',
    totalTime: '140',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8a',
    label: 'Add Contact Info\nCount: 416',
    totalTime: '153',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8b',
    label: 'Patient Eligibility Lookup\nCount: 416',
    totalTime: '175',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '10',
    label: 'Member Found\nCount: 375',
    totalTime: '180',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '12b',
    label: 'Enter Primary Diagnosis\nand Secondary Diagnosis\nCount: 231',
    totalTime: '192',
    totalPercent: '53',
    totalCount: '231',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Add Site of Service\nCount: 375',
    totalTime: '216',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '14b',
    label: 'Complete Clinical Information Collection\nCount: 346',
    totalTime: '310',
    totalPercent: '79',
    totalCount: '346',
    ...GREEN_NODE,
  },
  {
    id: '15a',
    label: 'Case Approved\nCount: 240',
    totalTime: '324',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '16a',
    label: 'Authorization Number Pasted\ninto Encounter\nCount: 240',
    totalTime: '334',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '17a',
    label: 'Take Screenshot of Authorization\nCount: 219',
    totalTime: '343',
    totalPercent: '50',
    totalCount: '219',
    ...GREEN_NODE,
  },
  {
    id: '18a',
    label: 'Upload Screenshot to EMR\nCount: 212',
    totalTime: '374',
    totalPercent: '48',
    totalCount: '212',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Update Encounter Status in WQ\nCount: 240',
    totalTime: '387',
    totalPercent: '50',
    totalCount: '240',
    ...GREEN_NODE,
  },
]
export const PathfinderEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '439',
    percent: '100',
    time: 15,
    count: 439,
    ...GREEN_EDGE,
  },
  {
    id: 'path23b',
    source: '2',
    target: '3b',
    label: '416',
    percent: '95',
    time: 42,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path3b5',
    source: '3b',
    target: '5',
    label: '416',
    percent: '95',
    time: 34,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '416',
    percent: '95',
    time: 18,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    label: '416',
    percent: '95',
    time: 31,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path78a',
    source: '7',
    target: '8a',
    label: '416',
    percent: '95',
    time: 13,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path8a8b',
    source: '8a',
    target: '8b',
    label: '416',
    percent: '95',
    time: 22,
    count: 416,
    ...GREEN_EDGE,
  },

  {
    id: 'path8b10',
    source: '8b',
    target: '10',
    label: '375',
    percent: '85',
    time: 5,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1011',
    source: '10',
    target: '11',
    label: '375',
    percent: '85',
    time: 4,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1112b',
    source: '11',
    target: '12b',
    label: '231',
    percent: '53',
    time: 8,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path12b13',
    source: '12b',
    target: '13',
    label: '231',
    percent: '53',
    time: 24,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path1314b',
    source: '13',
    target: '14b',
    label: '346',
    percent: '79',
    time: 94,
    count: 346,
    ...GREEN_EDGE,
  },
  {
    id: 'path14b15a',
    source: '14b',
    target: '15a',
    label: '240',
    percent: '55',
    time: 14,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path15a16a',
    source: '15a',
    target: '16a',
    label: '240',
    percent: '55',
    time: 10,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path16a17a',
    source: '16a',
    target: '17a',
    label: '219',
    percent: '50',
    time: 9,
    count: 219,
    ...GREEN_EDGE,
  },
  {
    id: 'path17a18a',
    source: '17a',
    target: '18a',
    label: '212',
    percent: '48',
    time: 31,
    count: 212,
    ...GREEN_EDGE,
  },
  {
    id: 'path18a19',
    source: '18a',
    target: '19',
    label: '212',
    percent: '48',
    time: 13,
    count: 212,
    ...GREEN_EDGE,
  },
]
// }

export const FullAutoNodes = [
  {
    id: '1',
    label: 'Open Patient WQ\nCount: 439',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 439,
    ...PURPLE_NODE,
  },
  {
    id: '2',
    label: 'Select and Open Encounter\nCount: 439',
    totalTime: '15',
    totalPercent: '100',
    totalCount: '439',
    ...PURPLE_NODE,
  },
  {
    id: '3b',
    label: 'Login to DME Software\nCount: 416',
    totalTime: '57',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '5',
    label: 'Open Browser and Login to Evicore\nCount: 416',
    totalTime: '91',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '6',
    label: 'Initiate Authorization for Sleep\nCount: 416',
    totalTime: '109',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '7',
    label: 'Complete Requesting\nProvider Information\nCount: 416',
    totalTime: '140',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '8a',
    label: 'Add Contact Info\nCount: 416',
    totalTime: '153',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '8b',
    label: 'Patient Eligibility Lookup\nCount: 416',
    totalTime: '175',
    totalPercent: '95',
    totalCount: '416',
    ...PURPLE_NODE,
  },
  {
    id: '10',
    label: 'Member Found\nCount: 375',
    totalTime: '180',
    totalPercent: '85',
    totalCount: '375',
    ...PURPLE_NODE,
  },
  {
    id: '12b',
    label: 'Enter Primary Diagnosis\nand Secondary Diagnosis\nCount: 231',
    totalTime: '192',
    totalPercent: '53',
    totalCount: '231',
    ...PURPLE_NODE,
  },
  {
    id: '13',
    label: 'Add Site of Service\nCount: 375',
    totalTime: '216',
    totalPercent: '85',
    totalCount: '375',
    ...PURPLE_NODE,
  },
  {
    id: '14b',
    label: 'Complete Clinical Information Collection\nCount: 346',
    totalTime: '310',
    totalPercent: '79',
    totalCount: '346',
    ...PURPLE_NODE,
  },
  {
    id: '15a',
    label: 'Case Approved\nCount: 240',
    totalTime: '324',
    totalPercent: '55',
    totalCount: '240',
    ...PURPLE_NODE,
  },
  {
    id: '16a',
    label: 'Authorization Number Pasted\ninto Encounter\nCount: 240',
    totalTime: '334',
    totalPercent: '55',
    totalCount: '240',
    ...PURPLE_NODE,
  },
  {
    id: '17a',
    label: 'Take Screenshot of Authorization\nCount: 219',
    totalTime: '343',
    totalPercent: '50',
    totalCount: '219',
    ...PURPLE_NODE,
  },
  {
    id: '18a',
    label: 'Upload Screenshot to EMR\nCount: 212',
    totalTime: '374',
    totalPercent: '48',
    totalCount: '212',
    ...PURPLE_NODE,
  },
  {
    id: '19',
    label: 'Update Encounter Status in WQ\nCount: 240',
    totalTime: '387',
    totalPercent: '50',
    totalCount: '240',
    ...PURPLE_NODE,
  },
]
export const FullAutoEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '439',
    percent: '100',
    time: 15,
    count: 439,
    ...PURPLE_EDGE,
  },
  {
    id: 'path23b',
    source: '2',
    target: '3b',
    label: '416',
    percent: '95',
    time: 42,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path3b5',
    source: '3b',
    target: '5',
    label: '416',
    percent: '95',
    time: 34,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '416',
    percent: '95',
    time: 18,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    label: '416',
    percent: '95',
    time: 31,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path78a',
    source: '7',
    target: '8a',
    label: '416',
    percent: '95',
    time: 13,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path8a8b',
    source: '8a',
    target: '8b',
    label: '416',
    percent: '95',
    time: 22,
    count: 416,
    ...PURPLE_EDGE,
  },
  {
    id: 'path8b10',
    source: '8b',
    target: '10',
    label: '375',
    percent: '85',
    time: 5,
    count: 375,
    ...PURPLE_EDGE,
  },
  {
    id: 'path1011',
    source: '10',
    target: '11',
    label: '375',
    percent: '85',
    time: 4,
    count: 375,
    ...PURPLE_EDGE,
  },
  {
    id: 'path1112b',
    source: '11',
    target: '12b',
    label: '231',
    percent: '53',
    time: 8,
    count: 231,
    ...PURPLE_EDGE,
  },
  {
    id: 'path12b13',
    source: '12b',
    target: '13',
    label: '231',
    percent: '53',
    time: 24,
    count: 231,
    ...PURPLE_EDGE,
  },
  {
    id: 'path1314b',
    source: '13',
    target: '14b',
    label: '346',
    percent: '79',
    time: 94,
    count: 346,
    ...PURPLE_EDGE,
  },
  {
    id: 'path14b15a',
    source: '14b',
    target: '15a',
    label: '240',
    percent: '55',
    time: 14,
    count: 240,
    ...PURPLE_EDGE,
  },
  {
    id: 'path15a16a',
    source: '15a',
    target: '16a',
    label: '240',
    percent: '55',
    time: 10,
    count: 240,
    ...PURPLE_EDGE,
  },
  {
    id: 'path16a17a',
    source: '16a',
    target: '17a',
    label: '219',
    percent: '50',
    time: 9,
    count: 219,
    ...PURPLE_EDGE,
  },
  {
    id: 'path17a18a',
    source: '17a',
    target: '18a',
    label: '212',
    percent: '48',
    time: 31,
    count: 212,
    ...PURPLE_EDGE,
  },
  {
    id: 'path18a19',
    source: '18a',
    target: '19',
    label: '212',
    percent: '48',
    time: 13,
    count: 212,
    ...PURPLE_EDGE,
  },
]

export const LowestNodes = [
  {
    id: '1',
    label: 'Open Patient WQ\nCount: 439',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 439,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select and Open Encounter\nCount: 439',
    totalTime: '15',
    totalPercent: '100',
    totalCount: '439',
    ...GREEN_NODE,
  },
  {
    id: '3b',
    label: 'Login to DME Software\nCount: 416',
    totalTime: '57',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open Browser and Login to Evicore\nCount: 416',
    totalTime: '91',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Initiate Authorization for Sleep\nCount: 416',
    totalTime: '109',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '7',
    label: 'Complete Requesting\nProvider Information\nCount: 416',
    totalTime: '140',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8a',
    label: 'Add Contact Info\nCount: 416',
    totalTime: '153',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '8b',
    label: 'Patient Eligibility Lookup\nCount: 416',
    totalTime: '175',
    totalPercent: '95',
    totalCount: '416',
    ...GREEN_NODE,
  },
  {
    id: '10',
    label: 'Member Found\nCount: 375',
    totalTime: '180',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '12b',
    label: 'Enter Primary Diagnosis\nand Secondary Diagnosis\nCount: 231',
    totalTime: '192',
    totalPercent: '53',
    totalCount: '231',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Add Site of Service\nCount: 375',
    totalTime: '216',
    totalPercent: '85',
    totalCount: '375',
    ...GREEN_NODE,
  },
  {
    id: '14b',
    label: 'Complete Clinical Information Collection\nCount: 346',
    totalTime: '310',
    totalPercent: '79',
    totalCount: '346',
    ...GREEN_NODE,
  },
  {
    id: '15a',
    label: 'Case Approved\nCount: 240',
    totalTime: '324',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '16a',
    label: 'Authorization Number Pasted\ninto Encounter\nCount: 240',
    totalTime: '334',
    totalPercent: '55',
    totalCount: '240',
    ...GREEN_NODE,
  },
  {
    id: '17a',
    label: 'Take Screenshot of Authorization\nCount: 219',
    totalTime: '343',
    totalPercent: '50',
    totalCount: '219',
    ...GREEN_NODE,
  },
  {
    id: '18a',
    label: 'Upload Screenshot to EMR\nCount: 212',
    totalTime: '374',
    totalPercent: '48',
    totalCount: '212',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Update Encounter Status in WQ\nCount: 240',
    totalTime: '387',
    totalPercent: '50',
    totalCount: '240',
    ...GREEN_NODE,
  },
]
export const LowestEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '439',
    percent: '100',
    time: 15,
    count: 439,
    ...GREEN_EDGE,
  },
  {
    id: 'path23b',
    source: '2',
    target: '3b',
    label: '416',
    percent: '95',
    time: 42,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path3b5',
    source: '3b',
    target: '5',
    label: '416',
    percent: '95',
    time: 34,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '416',
    percent: '95',
    time: 18,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    label: '416',
    percent: '95',
    time: 31,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path78a',
    source: '7',
    target: '8a',
    label: '416',
    percent: '95',
    time: 13,
    count: 416,
    ...GREEN_EDGE,
  },
  {
    id: 'path8a8b',
    source: '8a',
    target: '8b',
    label: '416',
    percent: '95',
    time: 22,
    count: 416,
    ...GREEN_EDGE,
  },

  {
    id: 'path8b10',
    source: '8b',
    target: '10',
    label: '375',
    percent: '85',
    time: 5,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1011',
    source: '10',
    target: '11',
    label: '375',
    percent: '85',
    time: 4,
    count: 375,
    ...GREEN_EDGE,
  },
  {
    id: 'path1112b',
    source: '11',
    target: '12b',
    label: '231',
    percent: '53',
    time: 8,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path12b13',
    source: '12b',
    target: '13',
    label: '231',
    percent: '53',
    time: 24,
    count: 231,
    ...GREEN_EDGE,
  },
  {
    id: 'path1314b',
    source: '13',
    target: '14b',
    label: '346',
    percent: '79',
    time: 94,
    count: 346,
    ...GREEN_EDGE,
  },
  {
    id: 'path14b15a',
    source: '14b',
    target: '15a',
    label: '240',
    percent: '55',
    time: 14,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path15a16a',
    source: '15a',
    target: '16a',
    label: '240',
    percent: '55',
    time: 10,
    count: 240,
    ...GREEN_EDGE,
  },
  {
    id: 'path16a17a',
    source: '16a',
    target: '17a',
    label: '219',
    percent: '50',
    time: 9,
    count: 219,
    ...GREEN_EDGE,
  },
  {
    id: 'path17a18a',
    source: '17a',
    target: '18a',
    label: '212',
    percent: '48',
    time: 31,
    count: 212,
    ...GREEN_EDGE,
  },
  {
    id: 'path18a19',
    source: '18a',
    target: '19',
    label: '212',
    percent: '48',
    time: 13,
    count: 212,
    ...GREEN_EDGE,
  },
]

export const IdealData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
export const PathfinderData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
export const PartialAutoData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
export const FullAutoData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
export const LongestData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
export const LowestData = {
  count: '401',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '820.18',
  payment: '2280.20',
  payment_frequency: '94.8',
  outcome: '2124.03',
  time: 430,
  fte_cost: 40,
  mean_cost: '2.01',
  net_cost: '2122.02',
  lag: '28',
  variance: 1,
}
