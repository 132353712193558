import { HttpErrorResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ToastService } from '../services/toast.service'

/**
 * Check route for `orgId` param. If present, set org for logged in user.
 *
 * Usage:
 *
 * {
 *  path: 'my-route',
 *  data: { ... },
 *  resolve: { orgId: OrgIdResolver },
 *  children: [ ... ]
 * }
 */
export const OrgIdResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const organizationService = inject(OrgService)
  const authenticationService = inject(AuthenticationService)
  const toastService = inject(ToastService)

  const orgId = route.queryParamMap.get('orgId')
  const user = authenticationService.getUser()

  if (user && orgId && user.orgId !== orgId) {
    try {
      await organizationService.setOrg(orgId)
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        handleError(e, toastService)
      }
    }
  }
}

function handleError(e: HttpErrorResponse, toastService: ToastService): void {
  if (e.status === 403) {
    toastService.error(e.message)
  } else {
    throw e
  }
}
