import { Component } from '@angular/core'

// We don't want to blast 1000s of rows into the DOM. This restricts the number of rows displayed.
const ROW_LIMIT = 200
@Component({
  selector: 'app-automations-query-center',
  templateUrl: './automations-query-center.page.html',
})
export class AutomationsQueryCenterPage {
  rowDisplayLimit = ROW_LIMIT

  constructor() {}
}
