import { Injectable, OnDestroy } from '@angular/core'
import { FileSubscriptionService } from './subscriptions/file-subscription.service'
import { TaskSubscriptionService } from './subscriptions/task-subscription.service'
import { VxDocumentSubscriptionService } from './subscriptions/vx-document-subscription.service'
import { WorkflowSubscriptionService } from './subscriptions/workflow-subscription.service'
import { Subject } from 'rxjs'

/**
 * Handle websocket messages
 *
 * @export
 * @class SubscriptionService
 * @implements {OnDestroy}
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionService implements OnDestroy {
  destroy$ = new Subject<void>()

  constructor(
    private fileSubscriptionService: FileSubscriptionService,
    private taskSubscriptionService: TaskSubscriptionService,
    private vxDocumentSubscriptionService: VxDocumentSubscriptionService,
    private workflowSubscriptionService: WorkflowSubscriptionService,
  ) {}

  /**
   * Setup & subscribe to websocket notifications
   *
   * @memberof SubscriptionService
   */
  init(): void {
    this.fileSubscriptionService.subscribe()
    // Below line is commented out because it was causing a LOT of network traffic when loading `/pathfinder` specifically in UnityPoint Health
    // org in prod (could possibly happen with other orgs too)
    // I am not comfortable deleting this quite yet so not going to delete it and the service entirely
    // this.taskSubscriptionService.subscribe()
    this.vxDocumentSubscriptionService.subscribe()
    this.workflowSubscriptionService.subscribe()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
