import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { AuthorizationService } from 'app/auth/authorization.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { MIN_PASSWORD_LENGTH } from 'app/shared/components/password-validator/password-validator.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { generateRandomPassword } from 'app/shared/utils/gen-rand-password'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { CreateUserInput, Organization, Role, User, UserRole, UserStatus } from 'generated/graphql'
import { pickBy, uniqBy } from 'lodash'
import { Subject, combineLatest, of } from 'rxjs'
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators'

/**
 * Page to allow editing an existing, or creating a new, user
 *
 * @export
 * @class EditUserPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.page.html',
  styleUrls: ['./edit-user.page.scss'],
  host: { class: 'h-100' },
})
export class EditUserPage implements OnDestroy, OnInit {
  user: User
  userRoles: Role[] = []
  legacyUserRoles: UserRole[]
  legacyUserRole = UserRole
  destroy$ = new Subject<void>()
  saving: boolean = false
  isLoading: boolean = true
  userForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    role: new UntypedFormControl(null),
    isStaff: new UntypedFormControl(null),
    isSuperuser: new UntypedFormControl(null),
    isActive: new UntypedFormControl(true),
    password: new UntypedFormControl(null),
  })
  primaryOrg: Organization | undefined
  allowedOrgs: Organization[] = []
  authServiceEnabled: boolean
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private orgService: OrgService,
    private toast: ToastService,
    private modal: NgbModal,
    private router: Router,
    public authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private crumbs: BreadcrumbsService,
    private featureFlagService: FeatureFlagService,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        tap(() => {
          this.userForm?.reset()
          this.isLoading = true
        }),
        map(() => this.route.snapshot.paramMap.get('userId')),
        // include isSamlEmail in the getUser call
        switchMap((userId) => (!userId ? of(null) : this.usersService.getUser(userId, { includeIsSamlEmail: true }))),
        takeUntil(this.destroy$),
      )
      .subscribe(async (result: ApolloQueryResult<{ user: User }>) => {
        const user = result?.data?.user
        await this.setUserFromResponse(user)
      })
  }

  async ngOnInit(): Promise<void> {
    this.authServiceEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.auth.authorization.v2023)
    this.triggerRoute$.next(true)

    this.userForm.get('role').setValidators((control) => {
      if (!this.authServiceEnabled && !control.value) {
        return { required: true }
      }
      return null
    })
  }

  onAllowedOrganizationsChange(orgs: Organization[]): void {
    this.allowedOrgs = orgs
  }

  onUserRolesChange(roles: Role[]): void {
    this.userRoles = roles
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  async setUserFromResponse(user: User): Promise<void> {
    this.user = user
    try {
      if (this.user?.id) {
        const { name, email, role, isStaff, isSuperuser, isActive } = this.user
        this.userForm?.setValue({ name, email, role, isStaff, isSuperuser, isActive, password: null })
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Error setting form data'), JSON.stringify(e))
    }

    const orgsSet: Set<string> = new Set<string>()
    if (this.user?.orgId) {
      orgsSet.add(this.user?.orgId)
    }

    this.user?.allowedOrgIds?.forEach((orgId: string) => {
      orgsSet.add(orgId)
    })

    const allowedOrgs = Array.from(orgsSet)

    try {
      const orgs: ApolloQueryResult<{ organization: Organization }>[] = await Promise.all(
        allowedOrgs.map((id: string) => this.orgService.getOrg(id).pipe(first()).toPromise()),
      )
      this.allowedOrgs = orgs.map(
        (result: ApolloQueryResult<{ organization: Organization }>) => result.data.organization,
      )
    } catch (e) {
      this.toast?.error(parseGraphQLError(e, "Could not load user's organizations"), JSON.stringify(e))
      this.allowedOrgs = []
    }

    if (this.user?.orgIdPrimary) {
      this.primaryOrg = (await this.orgService.getOrg(user.orgIdPrimary).pipe(first()).toPromise()).data.organization
    } else {
      this.primaryOrg = await this.authenticationService.getUserOrg()
    }

    try {
      this.legacyUserRoles = this.authenticationService?.getAllowedRoles()
      this.crumbs.setPageTitle('Admin - Users - ' + (this.user?.email || 'New User'))
    } catch (e) {
      this.toast?.error(parseGraphQLError(e, 'Could not get user roles'), JSON.stringify(e))
    }

    this.isLoading = false
  }

  /**
   * Save existing or create new user
   *
   * @return {*}  {Promise<void>}
   * @memberof EditUserPage
   */
  async saveUser(): Promise<void> {
    this.saving = true
    this.userForm.markAllAsTouched()
    if (this.userForm.invalid) {
      this.toast.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.userForm)),
      )
    } else {
      let form = this.userForm.value
      try {
        const allowedOrgIds = this.allowedOrgs.map((org) => org.id)
        const allAllowedOrgIds =
          this.authServiceEnabled && !allowedOrgIds.includes(this.primaryOrg.id)
            ? [this.primaryOrg.id, ...allowedOrgIds]
            : allowedOrgIds

        if (this.user?.id) {
          this.user = await this.usersService.updateUser(
            this.user.id,
            form.name,
            form.email,
            form.role,
            form.isStaff,
            form.isSuperuser,
            form.isActive,
            allAllowedOrgIds,
            this.userRoles.map((r) => r.id),
          )
        } else {
          const status = form.isActive ? UserStatus.Active : UserStatus.Inactive
          let params = this.removeEmpty(this.userForm.value)
          params = {
            ...params,
            allowedOrgIds: allAllowedOrgIds,
            status,
            roleIds: this.userRoles.map((r) => r.id),
          }
          delete params['isActive']
          await this.usersService.createUser(params)
        }
        this.toast.success('User successfully saved')
        this.router.navigate(['admin/users'])
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not save user'), JSON.stringify(e))
      }
    }
    this.saving = false
  }

  /**
   * Check if users can be deleted.
   */
  canDelete(): boolean {
    return this.authorizationService.isSuper()
  }

  /**
   * Launch modal to confirm deleting current user
   *
   * @memberof EditUserPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete user?'
    modalRef.componentInstance.body = `Once this user is deleted their data cannot be recovered.`
    modalRef.componentInstance.yes = 'Delete user'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteUser()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current user
   *
   * @return {*}  {Promise<void>}
   * @memberof EditUserPage
   */
  async deleteUser(): Promise<void> {
    try {
      await this.usersService.deleteUser(this.user.id)
      this.toast.success('User successfully deleted')
      this.router.navigate(['admin/users'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete user'), JSON.stringify(e))
    }
  }

  /**
   * Helper for removing null values from form
   *
   * @param {*} object
   * @return {*}  {CreateUserInput}
   * @memberof EditUserPage
   */
  removeEmpty(object: CreateUserInput): CreateUserInput {
    return pickBy(object, (value) => value != null) as CreateUserInput
  }

  /**
   * Helper to generate a random password
   */
  generatePassword(): void {
    this.userForm.get('password').setValue(generateRandomPassword(MIN_PASSWORD_LENGTH))
  }

  /**
   * Add organization to allowed org list
   *
   * @param {Organization} org
   * @memberof EditUserPage
   */
  pickedOrg(org: Organization): void {
    if (org.id) {
      this.allowedOrgs = uniqBy([...(this.allowedOrgs || []), org], 'id')
    }
  }

  /**
   * Remove org from allowed orgs list
   *
   * @param {Organization} org
   * @memberof EditUserPage
   */
  removeOrg(org: Organization): void {
    let i = this.allowedOrgs.findIndex((u) => u.id === org.id)
    if (i >= 0) {
      this.allowedOrgs.splice(i, 1)
    }
  }
}
