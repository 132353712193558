import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ListResponseMetaData, Payer } from 'generated/graphql'

/**
 * Page to display a list of payers
 *
 * @export
 * @class PayersListPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-payers-list',
  templateUrl: './payers-list.page.html',
  styleUrls: ['./payers-list.page.scss'],
  host: { class: 'h-100' },
})
export class PayersListPage implements OnInit {
  loading: boolean = false
  payersSearch = new UntypedFormControl('')
  payers: Payer[]
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 25

  constructor(private payersService: PayersService, private toast: ToastService) {}

  async ngOnInit(): Promise<void> {
    debug('payers-search', 'ngOnInit')
    await this.searchPayers()
  }

  /**
   * Search and paginate the list of payers
   *
   * @param {number} [page]
   * @return {*}  {Promise<void>}
   * @memberof PayersListPage
   */
  async searchPayers(page?: number): Promise<void> {
    this.loading = true
    try {
      let offset
      if (page) {
        offset = (page - 1) * this.meta.limit
      }
      this.payers = await this.payersService.searchPayers({
        search: this.payersSearch?.value,
        limit: this.pageSize,
        offset,
      })
      this.meta = this.payersService.getMeta()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load payers'), JSON.stringify(e))
    }
    this.loading = false
  }
}
