<div *ngIf="chartData" class="d-flex h-100 card mx-3 px-5 py-3 border-grey bg-light">
  <canvas
    baseChart
    [labels]="chartLabels"
    [options]="chartOptions"
    [legend]="chartLegend"
    [datasets]="chartData"
    data-t="revbot-graphs"
  >
  </canvas>
</div>
