<div class="bg-alt-white border rounded shadow p-3 d-flex flex-column flex-basis-0 flex-grow-1 flex-shrink-1 h-100">
  <div class="d-flex mb-3">
    <span class="bg-alt-light-gray-high py-1 my-0 px-3 d-flex flex-grow-1">
      <ng-content></ng-content>
    </span>
    <form [formGroup]="canvasModes">
      <div class="btn-group btn-group-toggle">
        <label
          class="btn btn-brand-light-blue rounded mx-2"
          ngbButtonLabel
          ngbTooltip="Pan by dragging and Zoom by mousewheel"
          container="body"
        >
          <input
            type="checkbox"
            formControlName="panzoom"
            aria-label="Pan / Zoom Mode"
            ngbButton
            #btnPanzoom
            (click)="btnPanzoom.blur()"
          />
          <i class="fa fa-search-plus fa-fw"></i>
        </label>

        <label class="btn btn-brand-light-blue rounded mr-2" ngbButtonLabel ngbTooltip="Draw">
          <input
            type="checkbox"
            formControlName="draw"
            aria-label="Drawing Mode"
            ngbButton
            #btnDraw
            (click)="btnDraw.blur()"
          />
          <i class="fa fa-pencil-square-o fa-fw"></i>
        </label>

        <label class="btn btn-brand-light-blue rounded mr-2" ngbButtonLabel ngbTooltip="Select" container="body">
          <input
            type="checkbox"
            formControlName="select"
            aria-label="Selecting Mode"
            ngbButton
            #btnSelect
            (click)="btnSelect.blur()"
          />
          <i class="fa fa-mouse-pointer fa-fw"></i>
        </label>
        <button
          type="button"
          class="btn btn-brand-light-blue rounded mr-2"
          [ngbTooltip]="selectedAnnotationId ? 'Copy' : 'Select a node to copy'"
          container="body"
          [disabled]="!selectedAnnotationId"
          (click)="copyNode()"
          aria-label="Copy Node"
          #btnCopy
        >
          <i class="fa fa-files-o fa-fw"></i>
        </button>

        <button
          type="button"
          class="btn btn-brand-light-blue rounded"
          [disabled]="!selectedAnnotationId"
          [ngbTooltip]="selectedAnnotationId ? 'Delete' : 'Select a node to delete'"
          container="body"
          (click)="deleteNode()"
          aria-label="Delete Node"
          #btnDelete
        >
          <i class="fa fa-trash fa-fw"></i>
        </button>
      </div>
    </form>
  </div>
  <div class="flex-column flex-basis-0 flex-grow-1 canvas-wrapper" #canvasWrapper>
    <canvas
      id="image-annotate-canvas"
      class="h-100 w-100"
      #canvas
      resize
      [ngClass]="[canvasClass, 'drawing-canvas']"
    ></canvas>
  </div>
</div>
