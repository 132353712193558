<div class="card detail-card my-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="flex-grow-1">{{ workflow?.name }}</span>
    <span class="flex-shrink-0 pr-3">
      <button
        *ngIf="showRight"
        class="btn btn-sm btn-link"
        (click)="clickRight.emit(workflow); $event.stopPropagation()"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRight"
        data-t="workflow-card-assign"
      >
        <i class="fa fa-2x fa-caret-right text-light pt-1"></i>
      </button>
      <button
        *ngIf="showRemove"
        class="btn btn-sm btn-link"
        (click)="clickRemove.emit(workflow); $event.stopPropagation()"
        type="button"
        placement="bottom"
        container="body"
        [ngbTooltip]="showRemove"
      >
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <span [innerHTML]="workflow?.description"></span>
  </div>
</div>
