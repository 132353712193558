<ng-template #filterLabel let-item="item">
  <span>{{ item.data.name }}</span>
</ng-template>

<app-multiselect-typeahead
  #multiselectTypeahead
  data-t="document-type-multiselect-typeahead"
  [filterLabel]="filterLabel"
  [fetchOnSearch]="fetchOnSearch"
  [placeholderText]="placeholderText"
  [selectedIds]="selectedVxDocumentTypeIds"
  (onSelect)="onSelect.emit($event)"
></app-multiselect-typeahead>
