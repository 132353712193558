<i
  class="fa fa-lg fa-fw pr-3 pt-2"
  [ngClass]="{
    'fa-check-circle-o text-success': completed,
    'fa-times-circle-o text-danger': rejected,
    'fa-circle-o text-dark': !completed && !rejected
  }"
  aria-hidden="true"
  data-t="task-status-icon"
></i>
