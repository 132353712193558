import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { DEFAULT_HOME_PATH } from 'app/app-routing.routes'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from '../services/toast.service'
import { debug } from '../utils/debug'

export type FeatureLogicCanActivate = {
  features: string[]
  isAndOperator: boolean
}

/**
 * @export
 * @constant FeatureFlagGuard
 *
 * @description If a single string of a feature flag is passed in the routes data.feature param
 * return the value of that feature flag. If data.feature is of type FeatureLogicCanActivate
 * get the values from the array of features given and return based on if isAndOperator is true or false.
 * isAndOperator true ensures that none of the values are false, isAndOperator false ensures at least one of the values
 * is true.
 */

export const FeatureFlagGuard: CanActivateFn = async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> => {
  const featureFlagService = inject(FeatureFlagService)
  const toastService = inject(ToastService)
  const router = inject(Router)

  const feature: string | FeatureLogicCanActivate = next?.data?.feature

  if (!feature) {
    return true
  }

  try {
    if (!isLogicFeature(feature)) {
      const result = await featureFlagService.getFeatureFlagValue(feature as string)
      if (!result) {
        return redirectToHome(router, toastService)
      }
      return true
    }

    const isAndOperator = feature.isAndOperator
    const features = feature.features
    const isOrOperator = !isAndOperator
    let foundEnabledFeatureFlag = false

    for (const feat of features) {
      const val = await featureFlagService.getFeatureFlagValue(feat)

      if (val) {
        foundEnabledFeatureFlag = true
      }

      // Short circuit if it is an OR operator and one of the feature flags is enabled.
      if (foundEnabledFeatureFlag && isOrOperator) {
        return true
      }

      // Short circuit if it is an AND operator and the value is false.
      if (!val && isAndOperator) {
        return redirectToHome(router, toastService)
      }
    }

    if (!foundEnabledFeatureFlag) {
      return redirectToHome(router, toastService)
    }

    return true
  } catch (error) {
    debug('feature-flags', 'feature flag guard error', error)
    toastService.error('An error occurred while checking feature access.')
    return redirectToHome(router, toastService)
  }
}

function redirectToHome(router: Router, toastService: ToastService): UrlTree {
  toastService.error('You do not have access to this page.')
  return router.parseUrl(DEFAULT_HOME_PATH)
}

function isLogicFeature(feature: FeatureLogicCanActivate | string): feature is FeatureLogicCanActivate {
  return (feature as FeatureLogicCanActivate).features !== undefined
}
