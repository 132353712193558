<div class="d-flex">
  <div class="flex-grow-1">
    <app-search-field
      [label]="'Search Task Types'"
      [search]="taskTypeSearch"
      [searching]="searching"
    ></app-search-field>
  </div>
  <div class="flex-shrink-1">
    <button type="button" class="btn btn-brand-green font-weight-bold ml-2" (click)="createTask()">
      <i class="fa fa-fw fa-plus-circle" data-t="create-task-button"></i>
    </button>
  </div>
</div>
<small class="d-block text-center" *ngIf="taskTypes?.length">
  Showing {{ taskTypes.length - actions.hidden.length }} of about {{ totalTaskTypes }} Task Types
</small>

<ng-template ngFor let-tt [ngForOf]="taskTypes" let-i="index">
  <app-task-type-card
    [class.d-none]="actions.hidden.includes(tt.id)"
    [taskType]="tt"
    [isCollapsed]="isCollapsed[i]"
    [showPencil]="showPencil"
    [showRight]="showRight"
    [showRemove]="showRemove"
    [showTwoRight]="showTwoRight"
    [attr.data-t]="tt.name + '-task'"
  ></app-task-type-card>
</ng-template>
