import { Component, Input, OnChanges } from '@angular/core'
import { isNil } from 'lodash'

/**
 * Component to display an opportunity's specific value and
 * any applicable delta over basline
 *
 * @export
 * @class TableItemComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-table-item',
  templateUrl: './table-item.component.html',
  styleUrls: ['./table-item.component.scss'],
})
export class TableItemComponent implements OnChanges {
  @Input() baseLine: number
  @Input() currentPhase: number
  @Input() fullAutomation: number
  @Input() otherPhase: number

  @Input() processVolume: number
  @Input() view: 'average' | 'monthly'

  @Input() isLowerBetter: boolean = false
  @Input() units: 'dollars' | 'days' = 'dollars'
  @Input() hasSecondChild: boolean = true
  @Input() showDecimals: boolean = false

  decrease: boolean
  showDelta: boolean = true
  compareValue
  delta: number
  periodMultiplier: number = 12

  constructor() {}

  isNil = isNil

  ngOnChanges(): void {
    this.compareValue = this.currentPhase
    if (isNil(this.compareValue)) {
      this.compareValue = this.fullAutomation
    }
    if (isNil(this.compareValue)) {
      this.compareValue = this.otherPhase
    }

    if (this.view === 'monthly') {
      this.periodMultiplier = 1
    } else if (this.view === 'average') {
      this.periodMultiplier = 1 / (this.processVolume || 1)
    }

    if (isNil(this.compareValue) || !this.hasSecondChild) {
      this.showDelta = false
    } else {
      if (this.compareValue < this.baseLine || (this.isLowerBetter && this.compareValue === this.baseLine)) {
        this.decrease = true
      }

      this.showDelta =
        this.baseLine && (!isNil(this.currentPhase) || !isNil(this.fullAutomation) || !isNil(this.otherPhase))
      if (this.showDelta) {
        if (this.units === 'dollars') {
          this.delta = (((this.compareValue - this.baseLine) * this.periodMultiplier) / 100) * (this.decrease ? -1 : 1)

          if (
            (this.view === 'average' && Math.abs(this.delta) < 0.005) ||
            ((!this.view || this.view === 'monthly') && Math.abs(this.delta) < 0.5)
          ) {
            this.showDelta = false
          }
        } else if (this.units === 'days') {
          this.delta = (this.compareValue - this.baseLine) * (this.decrease ? -1 : 1)
          if (this.delta === 0) {
            this.showDelta = false
          }
        }
      }
    }
  }
}
