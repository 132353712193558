import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { pull } from 'lodash'

/**
 * Modal to allow filtering a bot's runs
 *
 * @export
 * @class BotRunsFilterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-bot-runs-filter',
  templateUrl: './bot-runs-filter.component.html',
  styleUrls: ['./bot-runs-filter.component.scss'],
})
export class BotRunsFilterComponent implements OnInit {
  statuses: string[] = [
    'SUCCEEDED',
    'FAILED',
    'UNSTABLE',
    'QUEUED',
    'RUNNING',
    'SCATTERED',
    'ABORTED',
    'CLAIM_NOT_FOUND',
    'MISSING_DATA',
    'DEFERRED',
    'EXPIRED'
  ]
  selectedStatuses: string[] = this.botRunsService.statuses || [
    'SUCCEEDED',
    'FAILED',
    'UNSTABLE',
    'RUNNING',
    'SCATTERED',
    'ABORTED',
    'CLAIM_NOT_FOUND',
    'MISSING_DATA',
  ]
  startDate: string = this.botRunsService.startDate
  endDate: string = this.botRunsService.endDate
  startTime: string = this.botRunsService.startTime
  endTime: string = this.botRunsService.endTime

  constructor(
    public botRunsService: BotRunsService,
    public modal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {}

  /**
   * Add or remove a desired status
   *
   * @param {string} status
   * @param {Event} $event
   * @memberof BotRunsFilterComponent
   */
  selectStatus(status: string, $event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedStatuses.push(status)
    } else {
      pull(this.selectedStatuses, status)
    }
  }

  /**
   * Set start / end dates based on range
   *
   * @param {{ from: string; fromTime?: string; to: string; toTime?: string }} event
   * @memberof BotRunsFilterComponent
   */
  onRangeSelected(event: { from: string; fromTime?: string; to: string; toTime?: string }): void {
    this.startDate = event.from
    this.startTime = event.fromTime
    this.endDate = event.to
    this.endTime = event.toTime
  }

  /**
   * Add selected filters to URL as query params
   *
   * @memberof BotRunsFilterComponent
   */
  applySelection(): void {
    let statusParam
    if (this.selectedStatuses?.length != this.statuses?.length) {
      statusParam = this.selectedStatuses
    }
    this.router.navigate([], {
      relativeTo: this.route,
      skipLocationChange: false,
      queryParams: {
        status: statusParam,
        startDate: this.startDate,
        startTime: this.startTime,
        endDate: this.endDate,
        endTime: this.endTime,
      },
      queryParamsHandling: 'merge',
    })
    this.modal.close()
  }
}
