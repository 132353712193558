<div class="container-fluid">
    <div class="p-4 row">
      <div class="col-3 pt-2 input-group">
        <input [formControl]="orgSearchText" type="text" class="form-control" placeholder="Search Organizations" />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <i class="fa fa-search fa-fw"></i>
          </span>
        </div>
      </div>
    </div>
  
    <div class="container-fluid">
      <ng-container>
        <table class="table table-janus-new table-striped" data-t="dashboard-management-orgs-table">
          <thead class="bg-alt-lighter-gray">
            <tr>
              <th scope="col" class="text-left text-white">Organizations</th>
            </tr>
          </thead>
          <tbody class="bg-alt-white">
            <tr *ngFor="let organization of displayedOrgs" data-t="dashboard-management-orgs-table-row">
              <td class="text-left">
                <a class="active" [routerLink]="[route, organization?.id ]">{{ organization?.name }} </a>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
  