<div class="container d-flex flex-column min-vh-100">
  <div class="row justify-content-around my-5">
    <div class="col-lg-4 col-sm-8">
      <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" class="img-fluid" />
    </div>
  </div>

  <div class="row justify-content-around">
    <div class="col-lg-6">
      <div class="card login-wrapper py-5 px-4 shadow">
        <ng-content></ng-content>
      </div>
    </div>
  </div>

  <div class="row justify-content-around mt-4">
    <div class="col text-center text-muted">
      <a class="mx-4" routerLink="/policies/privacy-policy">Privacy Policy</a>
      <a class="mx-4" routerLink="/policies/terms-of-use">Terms of Use</a>
    </div>
  </div>

  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      To report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
