import G6, { EdgeConfig, NodeConfig } from '@antv/g6'

const computedStyle = getComputedStyle(document.body)

export const START_NODE: NodeConfig = {
  id: 'start',
  type: 'circle',
  size: 50,
  style: {
    fill: computedStyle.getPropertyValue('--success'),
    stroke: computedStyle.getPropertyValue('--alt-white'),
    cursor: 'not-allowed',
  },
  icon: {
    show: true,
    width: 40,
    height: 40,
    img: 'assets/icons/play-fill.svg',
  },
}

export const END_NODE: NodeConfig = {
  id: 'end',
  type: 'circle',
  size: 50,
  style: {
    fill: computedStyle.getPropertyValue('--danger'),
    stroke: computedStyle.getPropertyValue('--alt-white'),
    cursor: 'not-allowed',
  },
  icon: {
    show: true,
    width: 40,
    height: 40,
    img: 'assets/icons/stop-fill.svg',
  },
}

export const DEFAULT_NODE: Partial<NodeConfig> = {
  type: 'rect',
  size: [260, 50],
  anchorPoints: [
    [0.5, 0],
    [0.5, 1],
    [1, 0.5],
    [0, 0.5],
  ],
  radius: 2,
  labelCfg: {
    style: {
      fill: computedStyle.getPropertyValue('--alt-dark-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
      fontSize: 14,
      textAlign: 'center',
      textBaseline: 'middle',
      fontWeight: 'bold',
    },
  },
}

export const DEFAULT_EDGE_STYLE = {
  radius: 10,
  offset: -20,
  lineWidth: 4,
  // lineAppendWidth: 20,
}

export const DEFAULT_EDGE: Partial<EdgeConfig> = {
  type: 'polyline',
  labelCfg: {
    refY: 25,
    refX: 0,
    style: {
      fill: computedStyle.getPropertyValue('--alt-dark-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
      fontSize: 14,
      textAlign: 'center',
      textBaseline: 'middle',
      fontWeight: 'bold',
    },
  },
}

export const BLUE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-barely-blue'),
    stroke: computedStyle.getPropertyValue('--alt-baby-blue'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-baby-blue'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-barely-blue-low'),
      stroke: computedStyle.getPropertyValue('--alt-barely-blue'),
    },
  },
  ...DEFAULT_NODE,
}

export const BLUE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-barely-blue'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-barely-blue'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-baby-blue'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-baby-blue'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-barely-blue-low'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-barely-blue-low'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export const ORANGE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-orange-med'),
    stroke: computedStyle.getPropertyValue('--alt-orange'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-orange-high'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-orange-low'),
      stroke: computedStyle.getPropertyValue('--alt-orange-low'),
    },
  },
  ...DEFAULT_NODE,
}

export const ORANGE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-orange-med'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-orange-med'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-orange-high'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-orange-high'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-orange-low'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-orange-low'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export const GRAY_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--brand-gray'),
    stroke: computedStyle.getPropertyValue('--alt-light-gray'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-light-gray'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-lightest-gray'),
      stroke: computedStyle.getPropertyValue('--alt-lightest-gray'),
    },
  },
  ...DEFAULT_NODE,
}

export const GRAY_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--brand-gray'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--brand-gray'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-light-gray'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-light-gray'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-lightest-gray'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-lightest-gray'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export const GREEN_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-green-med'),
    stroke: computedStyle.getPropertyValue('--alt-green'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-green-high'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-green-low'),
      stroke: computedStyle.getPropertyValue('--alt-green-low'),
    },
  },
  ...DEFAULT_NODE,
}

export const GREEN_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-green-med'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-green-med'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-green-high'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-green-high'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-green-low'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-green-low'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export const RED_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-red-med'),
    stroke: computedStyle.getPropertyValue('--alt-orange'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-red-high'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-red-low'),
      stroke: computedStyle.getPropertyValue('--alt-red-low'),
    },
  },
  ...DEFAULT_NODE,
}

export const RED_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-red-med'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-red-med'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-red-high'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-red-high'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-red-low'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-red-low'),
      },
    },
  },
  ...DEFAULT_EDGE,
}

export const PURPLE_NODE: Partial<NodeConfig> = {
  style: {
    fill: computedStyle.getPropertyValue('--alt-purple-med'),
    stroke: computedStyle.getPropertyValue('--alt-purple'),
  },
  stateStyles: {
    selected: {
      fill: computedStyle.getPropertyValue('--alt-purple-high'),
      stroke: computedStyle.getPropertyValue('--alt-dark-gray'),
    },
    disabled: {
      fill: computedStyle.getPropertyValue('--alt-purple-low'),
      stroke: computedStyle.getPropertyValue('--alt-purple-low'),
    },
  },
  ...DEFAULT_NODE,
}

export const PURPLE_EDGE: Partial<EdgeConfig> = {
  style: {
    stroke: computedStyle.getPropertyValue('--alt-purple-med'),
    endArrow: {
      path: G6.Arrow.triangle(10, 15, 5),
      d: 5,
      fill: computedStyle.getPropertyValue('--alt-purple-med'),
    },
    ...DEFAULT_EDGE_STYLE,
  },
  stateStyles: {
    selected: {
      stroke: computedStyle.getPropertyValue('--alt-purple-high'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-purple-high'),
      },
    },
    disabled: {
      stroke: computedStyle.getPropertyValue('--alt-purple-low'),
      endArrow: {
        path: G6.Arrow.triangle(10, 15, 5),
        d: 5,
        fill: computedStyle.getPropertyValue('--alt-purple-low'),
      },
    },
  },
  ...DEFAULT_EDGE,
}
