import { Component, Input, OnInit, SecurityContext, signal } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { EmailService } from 'app/admin/client-credentials/emails.service'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotEmail, BotEmailIdentifier, ListResponseMetaData } from 'generated/graphql'

/**
 * Component to display the content of a list emails for one bot
 *
 * @export
 * @class ViewEmailsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-view-emails',
  templateUrl: './view-emails.component.html',
  host: {
    class: 'h-100',
  },
})
export class ViewEmailsComponent implements OnInit {
  @Input() username: string

  isLoadingPage: boolean
  isLoadingEmail: boolean
  emailKeys: BotEmailIdentifier[]
  currentEmail: BotEmail
  htmlEmailContent: string = null
  meta: ListResponseMetaData = null

  cardClass = 'previous-task rounded-xl shadow-lgr border-top-0 mb-4'

  emailRender = signal([])

  constructor(private toast: ToastService, private emailsService: EmailService, private sanitizer: DomSanitizer) {}

  async ngOnInit(): Promise<void> {
    this.isLoadingPage = true
    try {
      if (!this.username) {
        throw new Error('No username provided')
      } else {
        this.emailKeys = await this.emailsService.getBotEmailKeys(this.username)
        this.meta = {
          total: this.emailKeys.length,
          sort: 'desc',
          offset: 0,
          limit: 100,
        }
        this.setEmailsForCurrentPage()
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find emails'), JSON.stringify(e))
    }
    this.isLoadingPage = false
  }

  changePage(updated: PaginationUpdateEvent) {
    this.meta = { ...this.meta, offset: (updated.newPageNumber - 1) * this.meta.limit }
    this.setEmailsForCurrentPage()
  }

  setEmailsForCurrentPage() {
    this.emailRender.set(this.emailKeys.slice(this.meta.offset, this.meta.offset + this.meta.limit))
  }

  trackBy(index: number, obj: BotEmailIdentifier) {
    return obj.key
  }

  /**
   * Load bot email content based on selected email panel
   *
   * @param {NgbPanelChangeEvent} event
   * @return {*}  {Promise<void>}
   * @memberof ViewEmailsComponent
   */
  async loadEmailContent(event: NgbPanelChangeEvent): Promise<void> {
    this.isLoadingEmail = true
    if (event.nextState) {
      this.cardClass += ' open'
      try {
        let selectedIndex = this.meta.offset + Number(event.panelId.substr(2))
        let selectedKey = this.emailKeys[selectedIndex]?.key
        this.currentEmail = await this.emailsService.getBotEmail(selectedKey)
        this.htmlEmailContent = this.sanitizer.sanitize(SecurityContext.HTML, this.currentEmail?.html)
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not load email'), JSON.stringify(e))
        this.cardClass = this.cardClass.replace(' open', '')
        this.currentEmail = null
      }
    } else {
      this.cardClass = this.cardClass.replace(' open', '')
      this.currentEmail = null
      this.htmlEmailContent = null
    }
    this.isLoadingEmail = false
  }
}
