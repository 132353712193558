import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrgService } from 'app/admin/org/org.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { FeatureFlag, FeatureFlagType, ListResponseMetaData } from 'generated/graphql'
import { startCase } from 'lodash'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-feature-flag',
  templateUrl: './feature-flag.page.html',
  host: {
    class: 'h-100',
  },
})
export class FeatureFlagPage implements OnInit {
  isLoading: boolean = true
  meta: ListResponseMetaData
  featureFlags: { featureFlag: FeatureFlag; type: string }[]
  lastFlag: string
  isShowTable: boolean = true

  constructor(
    private route: ActivatedRoute,
    private toast: ToastService,
    private featureFlagService: FeatureFlagService,
    private usersService: UsersService,
    private orgService: OrgService,
    private crumbs: BreadcrumbsService,
  ) {}

  ngOnInit(): void {
    this.load()
  }

  async load(): Promise<void> {
    const featureFlagName = this.route.snapshot.paramMap.get('featureFlagName')
    try {
      if (featureFlagName === this.lastFlag) {
        return
      }
      if (featureFlagName === 'new') {
        this.isShowTable = false
        return
      }
      this.lastFlag = featureFlagName

      this.crumbs.setPageTitle('Admin - Feature Flags - ' + startCase(featureFlagName.toLocaleLowerCase()))

      let featureFlagResult = await this.featureFlagService.getFeatureFlags([featureFlagName]).pipe(first()).toPromise()
      let featureFlags = featureFlagResult.data.featureFlags
      let userIds = featureFlags
        .filter((flag) => flag.type === 'User' && flag.typeId)
        .map((flag) => {
          return flag.typeId
        })
      let orgIds = featureFlags
        .filter((flag) => flag.type === 'Organization' && flag.typeId)
        .map((flag) => {
          return flag.typeId
        })
      let users = await this.usersService.getUsers(undefined, userIds)
      let orgResult = await Promise.all(
        orgIds.map((orgId) =>
          this.orgService
            .getOrg(orgId)
            .pipe(first())
            .toPromise()
            .catch((e) => null),
        ),
      )
      let orgs = orgResult.map((result) => result?.data?.organization).filter((org) => org != null)

      this.featureFlags = featureFlags
        .filter((flag) => {
          const displayObject = this.featureFlagService.saturateFeatureFlagType(flag, users, orgs)
          return flag.type !== FeatureFlagType.User || (flag.type === FeatureFlagType.User && displayObject.type)
        })
        .map((flag) => this.featureFlagService.saturateFeatureFlagType(flag, users, orgs))
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find feature flags'), JSON.stringify(e))
    }
    this.isLoading = false
  }
}
