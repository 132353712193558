<div class="modal-header">
  <h4 class="modal-title">Filter Task Groups</h4>
</div>
<form [formGroup]="filters" (ngSubmit)="applyFilters()">
  <div class="modal-body">
    <div class="form-group py-3">
      <div class="form-check" *ngIf="!showAllUsers">
        <input type="checkbox" class="form-check-input" formControlName="userId" id="onlyMine" />
        <label class="form-check-label" for="onlyMine">Show only my Task Groups</label>
      </div>
      <ng-template [ngIf]="showAllUsers">
        <label for="userSearch">Assigned to User</label>
        <app-user-search
          (onSelect)="userId.setValue($event)"
          [selectedUser]="selectedUser?.name"
          [clearInput]="false"
        ></app-user-search>
      </ng-template>
    </div>
    <div class="form-check py-3">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="includeCompleted"
        id="includeCompleted"
        data-t="include-completed"
      />
      <label class="form-check-label" for="includeCompleted">Include completed task groups</label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="modal.dismiss('Close click')"
      data-t="cancel-task-group-filters-button"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-brand-green px-5" data-t="apply-task-group-filters-button" ngbAutofocus>
      Apply
    </button>
  </div>
</form>
