import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { GraphData, GraphOptions, Node } from '@antv/g6'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { getGraphDataFromWorkflowTypeTasks } from 'app/shared/utils/dag-utils'
import { debug } from 'app/shared/utils/debug'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskType, WorkflowType, WorkflowTypeInput, WorkflowTypeTask, WorkflowTypeTaskOutcome } from 'generated/graphql'
import { isNumber, pull, uniq } from 'lodash'
import { Subject } from 'rxjs'
import { debounceTime, pluck, takeUntil, tap } from 'rxjs/operators'
import { OutcomeOption, OutcomeSelection } from '../outcome-selector/outcome-selector.component'
import { WorkflowService } from '../workflow.service'

/**
 * Page to edit existing, or create new, workflow type
 *
 * @export
 * @class WorkflowPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.page.html',
  styleUrls: ['./workflow.page.scss'],
  host: {
    class: 'flex-grow-1 position-relative',
  },
})
export class WorkflowPage implements OnInit, OnDestroy {
  workflowType: WorkflowType
  canPreviewWorkflow = true
  isSaving = false
  isDeleting = false
  selectedTaskIndex = 0
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(null, Validators.required),
    tasks: new UntypedFormArray([]),
  })
  tasks = this.form.get('tasks') as UntypedFormArray
  tasksWithLockedDescriptions: number[] = []
  savedFormState: any
  graphData: GraphData
  outcomeOptionsMap: OutcomeOption[][] = []
  destroyed$ = new Subject<void>()
  @ViewChildren('taskCard') taskCards: QueryList<any>

  computedStyle = getComputedStyle(document.body)
  graphOptions: Partial<GraphOptions> = {
    fitView: true,
    maxZoom: 1,
    layout: {
      type: 'dagre',
      nodesep: 25,
      ranksep: 50,
      controlPoints: true,
    },
    defaultNode: {
      type: 'workflowTask',
      size: [200, 50],
    },
    defaultEdge: {
      type: 'polyline',
      style: {
        radius: 50,
        offset: 120,
        endArrow: true,
        lineWidth: 4,
        stroke: '#BBBFC2',
      },
    },
    nodeStateStyles: {
      selected: {
        stroke: '#537783',
        fill: this.computedStyle.getPropertyValue('--alt-light-blue'),
      },
    },
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private workflowService: WorkflowService,
    private taskTypesService: TaskTypesService,
    private router: Router,
    private modal: NgbModal,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    // set everything up with initial data
    this.activatedRoute.data.pipe(pluck('workflowType')).subscribe((workflowType) => {
      this.workflowType = workflowType
      this.breadcrumbsService.relabelCurrentBreadcrumb(workflowType.name || 'New Workflow')

      this.breadcrumbsService.setPageTitle('Admin - Workflows - ' + (workflowType?.name || 'New'))

      this.processTasks(this.workflowType.tasks)
      this.form.patchValue({
        name: workflowType.name,
        description: workflowType.description,
      })
      this.tasks.clear()
      workflowType.tasks?.forEach((task: WorkflowTypeTask) => this.addTaskToForm(task))
      this.savedFormState = this.form.value
      debug('workflow', 'initial form state', this.form.getRawValue())
    })

    // handle changes to tasks
    this.tasks.valueChanges
      .pipe(
        debounceTime(250),
        tap((tasks) => {
          debug('workflow', 'tasks changed to', tasks)
          let tasksSaturated = tasks.map((task) => this.saturateTask(task))
          this.processTasks(tasksSaturated)
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe()
  }

  /**
   * take value of a formcontrol in this.tasks
   * and alter structure to make it a valid WorkflowTypeTask
   *
   * @private
   * @param {*} task
   * @return {*}  {WorkflowTypeTask}
   * @memberof WorkflowPage
   */
  private saturateTask(task: any): WorkflowTypeTask {
    return {
      ...task,
      taskType: {
        name: task.taskTypeName || 'Task',
      },
    }
  }

  /**
   * Helper to redraw graph based on existing tasks
   *
   * @private
   * @param {WorkflowTypeTask[]} tasks
   * @memberof WorkflowPage
   */
  private processTasks(tasks: WorkflowTypeTask[]): void {
    this.graphData = getGraphDataFromWorkflowTypeTasks(tasks)
    this.buildOutcomeOptionsMap(tasks)
  }

  /**
   * Helper to set outcome map of existing tasks
   *
   * @private
   * @param {WorkflowTypeTask[]} tasks
   * @memberof WorkflowPage
   */
  private buildOutcomeOptionsMap(tasks: WorkflowTypeTask[]): void {
    if (tasks?.length || 0 < 1) return
    debug('workflow', 'buildOutcomeOptionsMap', tasks)
    let begin = Date.now()
    let parentTaskMap = new Array(tasks?.length).fill(null).map(() => [])
    tasks.forEach((task, taskIndex) => {
      task.outcomes?.forEach((outcome) => {
        let toIndex = outcome.nextWorkflowTypeTaskIndex
        if (isNumber(toIndex)) {
          parentTaskMap[toIndex].push(taskIndex)
        }
      })
    })
    debug('workflow', 'parentTaskMap', parentTaskMap)
    // TODO: memoize this (if it gets too slow with many nodes/edges); cache only within this call to outer fn
    let getAncestorTasks = function (taskIndex: number) {
      let parents = parentTaskMap[taskIndex]
      return uniq([...parents, ...parents.flatMap((parent) => getAncestorTasks(parent))])
    }
    this.outcomeOptionsMap = tasks.map((task, taskIndex) => {
      let ancestorTasks
      try {
        ancestorTasks = getAncestorTasks(taskIndex)
      } catch (e) {
        // swallow the maximum call stack error; ancestorTasks will remain undefined & be handled below
      }
      debug('workflow', 'task', taskIndex, 'ancestors', ancestorTasks || 'CYCLE')
      let outcomeOptions = tasks.map((nextTask, nextTaskIndex) => {
        return {
          taskIndex: nextTaskIndex,
          taskName: nextTask.taskType.name,
          disabled:
            !ancestorTasks || // can't link to anything if in a loop
            ancestorTasks.includes(nextTaskIndex) || // can't link to any ancestor
            nextTaskIndex === taskIndex || // can't link to itself
            nextTaskIndex === 0, // can't link to first step (if orphan with no ancestors)
        }
      })
      return outcomeOptions
    })
    debug('workflow', 'built outcomeMap in', Date.now() - begin, 'ms', this.outcomeOptionsMap)
  }

  /**
   * Add task type to workflow builder
   *
   * @param {WorkflowTypeTask} [task]
   * @param {boolean} [selectNewTask=false]
   * @memberof WorkflowPage
   */
  addTaskToForm(task?: WorkflowTypeTask, selectNewTask = false): void {
    this.tasks.push(
      new UntypedFormGroup({
        taskTypeId: new UntypedFormControl(task?.taskTypeId),
        taskTypeName: new UntypedFormControl(task?.taskType.name, Validators.required),
        taskTypeDescription: new UntypedFormControl(task?.taskType.description, Validators.required),
        outcomes: new UntypedFormArray([]),
      }),
    )
    let taskIndex = this.tasks.length - 1
    if (!task) {
      this.addOutcomeToForm(taskIndex)
    } else {
      task.outcomes?.forEach((outcome: WorkflowTypeTaskOutcome) => {
        this.addOutcomeToForm(taskIndex, outcome)
      })
    }
    if (selectNewTask) {
      this.selectTask(this.tasks.length - 1)
    }
  }

  /**
   * Remove a task type from workflow builder
   *
   * @param {number} taskIndex
   * @memberof WorkflowPage
   */
  removeTaskFromForm(taskIndex: number): void {
    debug('workflow', `removing task ${taskIndex} from form`)
    this.unlockDescription(taskIndex)
    this.tasks.removeAt(taskIndex)
    this.tasks.controls.forEach((taskGroup) => {
      let taskOutcomes = this.tasks.get(`${taskIndex}.outcomes`) as UntypedFormArray
      taskOutcomes.controls.forEach((outcomeGroup) => {
        let nextIndexControl = outcomeGroup.get('nextWorkflowTypeTaskIndex')
        if (nextIndexControl.value === taskIndex) {
          nextIndexControl.setValue(null)
        } else if (nextIndexControl.value > taskIndex) {
          nextIndexControl.setValue(nextIndexControl.value - 1)
        }
      })
    })
  }

  /**
   * Add an outcome as an option to the form
   *
   * @param {number} taskIndex
   * @param {WorkflowTypeTaskOutcome} [outcome]
   * @memberof WorkflowPage
   */
  addOutcomeToForm(taskIndex: number, outcome?: WorkflowTypeTaskOutcome): void {
    let taskOutcomes = this.tasks.get(`${taskIndex}.outcomes`) as UntypedFormArray
    taskOutcomes.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(outcome?.name, Validators.required),
        nextWorkflowTypeTaskIndex: new UntypedFormControl(outcome?.nextWorkflowTypeTaskIndex),
        isWorkflowTerminus: new UntypedFormControl(outcome?.isWorkflowTerminus),
      }),
    )
  }

  /**
   * Remove an outcome from the form
   *
   * @param {number} taskIndex
   * @param {number} outcomeIndex
   * @memberof WorkflowPage
   */
  removeOutcomeFromForm(taskIndex: number, outcomeIndex: number): void {
    let taskOutcomes = this.tasks.get(`${taskIndex}.outcomes`) as UntypedFormArray
    taskOutcomes.removeAt(outcomeIndex)
  }

  /**
   * Open / close a task's detail card
   *
   * @param {number} taskIndex
   * @param {boolean} isCollapsed
   * @memberof WorkflowPage
   */
  toggledTask(taskIndex: number, isCollapsed: boolean): void {
    debug('workflow', 'toggled task', { taskIndex, isCollapsed })
    if (!isCollapsed) {
      this.selectTask(taskIndex)
    } else if (taskIndex === this.selectedTaskIndex) {
      this.selectTask(null)
    }
  }

  /**
   * Create a task type instead of selecting an existing one
   *
   * @param {number} taskIndex
   * @param {string} name
   * @memberof WorkflowPage
   */
  changedTaskName(taskIndex: number, name: string): void {
    debug('workflow', `set task name for task ${taskIndex} to "${name}"`)
    let task = this.tasks.at(taskIndex)
    task.get('taskTypeName').setValue(name)
    if (task.get('taskTypeId').value) {
      task.patchValue({
        tasktypeId: null,
        taskTypeDescription: null,
      })
    }
  }

  /**
   * Select an existing task type
   *
   * @param {number} taskIndex
   * @param {TaskType} taskType
   * @memberof WorkflowPage
   */
  selectedTaskType(taskIndex: number, taskType: TaskType): void {
    debug('workflow', `set task type for task ${taskIndex} to ${JSON.stringify(taskType)}`)
    this.tasks.at(taskIndex).patchValue({
      taskTypeId: taskType ? taskType.id : null,
      taskTypeName: taskType ? taskType.name : null,
      taskTypeDescription: taskType ? taskType.description : null,
    })
    this.tasksWithLockedDescriptions.push(taskIndex)
  }

  /**
   * Update task's outcomes based on user selection of outcome
   *
   * @param {number} taskIndex
   * @param {number} outcomeIndex
   * @param {OutcomeSelection} selection
   * @memberof WorkflowPage
   */
  selectedOutcome(taskIndex: number, outcomeIndex: number, selection: OutcomeSelection): void {
    debug('workflow', `selected outcome ${JSON.stringify(selection)} for task ${taskIndex} outcome ${outcomeIndex}`)
    let outcomeGroup = this.tasks.at(taskIndex).get(`outcomes.${outcomeIndex}`)
    switch (selection) {
      case 'terminus':
        outcomeGroup.patchValue({
          nextWorkflowTypeTaskIndex: null,
          isWorkflowTerminus: true,
        })
        break
      case 'new_task':
        this.addTaskToForm()
        outcomeGroup.patchValue({
          nextWorkflowTypeTaskIndex: this.tasks.length - 1,
          isWorkflowTerminus: false,
        })
        break
      default:
        outcomeGroup.patchValue({
          nextWorkflowTypeTaskIndex: selection,
          isWorkflowTerminus: false,
        })
    }
  }

  /**
   * Get selected outcome
   *
   * @param {number} taskIndex
   * @param {number} outcomeIndex
   * @return {*}  {OutcomeSelection}
   * @memberof WorkflowPage
   */
  getOutcomeSelection(taskIndex: number, outcomeIndex: number): OutcomeSelection {
    let outcomeGroup = this.tasks.at(taskIndex).get(`outcomes.${outcomeIndex}`)
    let nextTaskIndex = outcomeGroup.get('nextWorkflowTypeTaskIndex').value
    if (isNumber(nextTaskIndex)) {
      return nextTaskIndex
    } else if (outcomeGroup.get('isWorkflowTerminus').value) {
      return 'terminus'
    } else {
      return null
    }
  }

  /**
   * Select / deselect graph node
   *
   * @param {Node} node
   * @memberof WorkflowPage
   */
  selectedGraphNode(nodes: Node[]): void {
    let node = nodes[0]
    if (!node || node.getID() === 'complete') {
      this.selectTask(null)
    } else {
      this.selectTask(parseInt(node.getID()))
    }
  }

  /**
   * Helper to set selected task in graph & scroll to it's card
   *
   * @private
   * @param {number} taskIndex
   * @memberof WorkflowPage
   */
  private selectTask(taskIndex: number): void {
    this.selectedTaskIndex = taskIndex
    if (isNumber(taskIndex)) {
      setTimeout(() => {
        let cardElement = this.taskCards.toArray()[taskIndex].elementRef.nativeElement
        cardElement.parentElement.scrollTo(0, cardElement.offsetTop - 65)
      }, 250)
    }
  }

  /**
   * Launch modal to confirm deleting current workflow type
   *
   * @memberof WorkflowPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete workflow?'
    modalRef.componentInstance.body = `Once this workflow is deleted its data cannot be recovered.`
    modalRef.componentInstance.yes = 'Delete workflow'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.delete()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current workflow type
   *
   * @return {*}  {Promise<void>}
   * @memberof WorkflowPage
   */
  async delete(): Promise<void> {
    this.isDeleting = true
    try {
      await this.workflowService.deleteWorkflowType(this.workflowType.id)
      this.toast.success('Workflow successfully deleted')
      this.router.navigate(['admin/workflows'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete workflow'), JSON.stringify(e))
      this.isDeleting = false
    }
  }

  /**
   * Cancel current edits and reset inputs
   *
   * @memberof WorkflowPage
   */
  clear(): void {
    debug('workflow', 'resetting form state to', this.savedFormState)
    this.form.reset(this.savedFormState)
    this.tasks.clear()
    this.savedFormState.tasks.forEach((task) => this.addTaskToForm(this.saturateTask(task)))
  }

  /**
   * Helper to determine if current workflow type can be published
   *
   * @readonly
   * @type {boolean}
   * @memberof WorkflowPage
   */
  get isPublishable(): boolean {
    return (
      this.workflowType.id &&
      !this.workflowType.published &&
      this.workflowType.validationResult.valid &&
      this.form.pristine &&
      this.tasks.length === this.savedFormState?.tasks.length
    )
  }

  /**
   * Save current workflow type
   *
   * @return {*}  {Promise<void>}
   * @memberof WorkflowPage
   */
  async save(): Promise<void> {
    if (this.form.invalid) {
      this.toast.error('Please fill in all fields before saving', JSON.stringify(readableFormGroupErrors(this.form)))
      if (this.tasks?.invalid) {
        let taskIndex = 0
        for (let task of this.tasks.controls) {
          if (task.invalid) {
            this.selectTask(taskIndex)
            return
          }
          taskIndex++
        }
      }
      return
    }
    this.isSaving = true
    if (this.isPublishable) {
      try {
        this.workflowType = await this.workflowService.publishWorkflowType(this.workflowType.id)
        this.toast.success('Successfully published workflow')
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Unable to publish workflow'), JSON.stringify(e))
      }
    } else {
      await this.saveDraft()
    }
    this.isSaving = false
  }

  /**
   * Save a draft of the current workflow type
   *
   * @return {*}  {Promise<void>}
   * @memberof WorkflowPage
   */
  async saveDraft(): Promise<void> {
    try {
      await Promise.all(
        this.tasks.controls.map(async (task) => {
          if (!task.get('taskTypeId').value) {
            let taskType = await this.taskTypesService.createTaskType({
              name: task.get('taskTypeName').value,
              description: task.get('taskTypeDescription').value,
            })
            debug('workflow', 'saved new tasktype', JSON.stringify(taskType))
            task.get('taskTypeId').setValue(taskType.id)
          }
        }),
      )

      let workflowTypeData: WorkflowTypeInput = {
        name: this.form.get('name').value,
        description: this.form.get('description').value,
        tasks: this.tasks.controls.map((task) => ({
          taskTypeId: task.get('taskTypeId').value,
          outcomes: (task.get('outcomes') as UntypedFormArray).getRawValue(),
        })),
      }

      let newWorkflowType: WorkflowType
      let isUnpublished = false

      if (!this.workflowType.id) {
        newWorkflowType = await this.workflowService.createWorkflowType(workflowTypeData)
      } else {
        try {
          newWorkflowType = await this.workflowService.updateWorkflowType(this.workflowType.id, workflowTypeData)
        } catch (e) {
          if (e.message.indexOf('must extendWorkflowType')) {
            debug('workflow', 'unable to update (locked); trying to create a new version instead')
            newWorkflowType = await this.workflowService.extendWorkflowType(
              this.workflowType.id,
              workflowTypeData,
              true,
            )
            if (!newWorkflowType.published) {
              isUnpublished = true
            }
          } else {
            throw e
          }
        }
      }

      this.toast.success('Successfully saved workflow')
      if (isUnpublished) {
        this.toast.info(
          'Workflow was unpublished due to errors. Please resolve errors and publish to continue using this workflow in Pathfinder.',
        )
      }
      this.savedFormState = this.form.value
      this.breadcrumbsService.relabelCurrentBreadcrumb(newWorkflowType.name)
      if (newWorkflowType.id !== this.workflowType.id) {
        debug('workflow', `id changed from ${this.workflowType.id} to ${newWorkflowType.id}; redirecting`)
        this.router.navigate(['admin', 'workflows', newWorkflowType.id])
      }
      this.workflowType = newWorkflowType
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Unable to save workflow'), JSON.stringify(e))
    }
  }

  /**
   * Helper to parse a single task's validation errors
   *
   * @param {number} [taskIndex=null]
   * @param {(number | '*')} [outcomeIndex=null]
   * @return {*}  {string}
   * @memberof WorkflowPage
   */
  getErrorCode(taskIndex: number = null, outcomeIndex: number | '*' = null): string {
    let error = this.workflowType.validationResult?.errors?.find(
      (e) => e.taskIndex === taskIndex && (outcomeIndex === '*' || e.outcomeIndex === outcomeIndex),
    )
    if (error) {
      return this.errorCodeMap(error?.code)
    }
  }

  /**
   * Helper to map validation code to human-readable error message
   *
   * @param {string} errorCode
   * @return {*}  {string}
   * @memberof WorkflowPage
   */
  errorCodeMap(errorCode: string): string {
    switch (errorCode) {
      case 'no_tasks':
        return 'Workflow has no tasks'
      case 'ambiguous_outcome':
        return 'Outcome has next task but is also flagged as an end step'
      case 'invalid_next_task_index':
        return 'Next task does not exist'
      case 'cyclic_next_task_index':
        return 'Outcome may create a task that leads back to this task'
      case 'unreachable_task':
        return 'This task cannot be reached'
      default:
        return 'Workflow cannot be published due to unexpected problem'
    }
  }

  /**
   * Helper to unlock a task's description
   *
   * @param {number} taskIndex
   * @memberof WorkflowPage
   */
  unlockDescription(taskIndex: number): void {
    pull(this.tasksWithLockedDescriptions, taskIndex)
  }

  /**
   * Duplicate a task
   *
   * @param {number} taskIndex
   * @param {AbstractControl} task
   * @memberof WorkflowPage
   */
  copyTask(taskIndex: number, task: AbstractControl): void {
    task.value.taskTypeId = null
    pull(this.tasksWithLockedDescriptions, taskIndex)
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }
}
