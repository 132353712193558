import { Component } from "@angular/core";

@Component({
  selector: 'app-edi-reader',
  templateUrl: './edi-reader.page.html',
  styleUrls: ['./edi-reader.page.scss'],
})

export class EdiReader {
  formattedText = ''
  parsedText = ''

  parseEdi = (data: string) => {
    const parsedData = this.parseEdiToHumanReadable(data)
    const parsedStr = parsedData.join('<br>')
    this.formattedText= `<p>${parsedStr}</p>`
    
    const incomingParserInfo = this.incoming278Parser(parsedData)
    const sortedParserInfo = {}
    Object.keys(incomingParserInfo).forEach((key) => sortedParserInfo[key] = incomingParserInfo[key])
    const sortedStr = JSON.stringify(this.incoming278Parser(parsedData), null, 4).replace(/['"]/g, '')
  
    this.parsedText = `<pre>${sortedStr}</pre>`
  }

  private parseEdiToHumanReadable = (raw: string) => {
    const rows: string[] = []
    let buff = ''
    for (const char of raw) {
      buff += char
      if (char === '\u0003' || char === '\u0002' || char === '\x02') continue
      if (char === '~') {
        rows.push(buff)
        buff = ''
      }
      if (buff === 'HL' || buff === 'SE') {
        rows.push('')
      }
    }
    return rows
  }
  
  private breakIntoColumns = (str: string) => {
    const res: string[] = []
    let temp = ''
  
    for (let char of str) {
      if (char === '*') {
        if (temp.length) res.push(temp)
        res.push('*')
        temp = ''
      } else {
        temp += char
      }
    }
    if (temp.length) res.push(temp)
  
    return res
  }
  
  private formatDate = (str: string) => {
    const year = str.slice(0,4)
    const month = str.slice(4,6)
    const day = str.slice(6,8)
  
    return [month, day, year].join('/')
  }

  private res = {}
  private loop = 'subscriber'

  private addKeyVal = (str: string, key: string) => {
    if (str && str !== '*') this.res[key] = [...str].filter((char) => !(char === '*' || char === '~')).join('')
  }
  
  private incoming278Parser = (rows: string[]) => {    
    this.res = {}
    this.loop = 'subscriber'

    for (let row of rows) {
      const col = this.breakIntoColumns(row)
      this.mainSegment[col[0]]?.(col)
    }
    return this.res
  }

  private mainSegment: Record<string, (col: string[]) => void> = {
    NM1: (col) => { this.segmentNM1[col[2]]?.(col) },
    DMG: (col) => {
      if (col[2] === 'DB') {
        const date = this.formatDate(col[4])
        this.segmentDMG[this.loop](date, col)
      }
    },
    INS: (col) => { if (this.loop === 'dependent') this.addKeyVal(col[4], 'relationToSubscriber') },
    UM: (col) => { this.addKeyVal(String(col[2] === 'AR'), 'isInpatient'); this.addKeyVal(col[4], 'serviceTypeCode') },
    DTP: (col) => {
      if ((col[2] === 'AAH' || col[2] === '435') && this.loop === 'patientEvent') {
        const date = this.formatDate(col[6])
        this.addKeyVal(date, 'serviceDateStart')
        this.addKeyVal(date, 'serviceDateEnd')
      }
    },
    HI: (col) => {
      if (this.loop === 'patientEvent') {
        const diagnosisCodes = []
        for (let i = 2; i < col.length; i += 2) diagnosisCodes.push(col[i].split(':')[1])
        this.addKeyVal(String(diagnosisCodes), 'diagnosisCodes')
      }
    },
    MSG: (col) => { if (this.loop === 'patientEvent') this.addKeyVal(col[2], 'patientMrn') },
    HL: () => { this.segmentHL[this.loop]?.() },
    TRN: (col) => {
      if (this.loop === 'patientEvent') {
        this.addKeyVal(col[4], 'epicReferralId')
        if (!this.res['patientMrn']) this.addKeyVal(col[6], 'patientMrn')
      }
    },
    N3: (col) => { this.segmentN3[this.loop]?.(col) },
    N4: (col) => { this.segmentN4[this.loop]?.(col) },
    SV1: (col) => { this.segmentSV(col) },
    SV2: (col) => { this.segmentSV(col) },
    BHT: (col) => { this.addKeyVal(col[6], 'epicTrnId') },
    REF: (col) => {  if (col[2] === 'EI' && this.loop === 'serviceFacility') this.addKeyVal(col[4], 'taxId') },
    HSD: (col) => { if (col[2] === 'VS') this.addKeyVal(col[4], 'numVisits') },
  }

  private segmentNM1: Record<string, (col: string[]) => void> = {
    X3: (col) => {
      this.addKeyVal(col[14], 'payerId')
      this.addKeyVal(col[6], 'name')
    },
    IP: (col) => {
      this.addKeyVal(col[16], 'referringProviderNpi')
      this.addKeyVal(col[6], 'referringLastName')
      this.addKeyVal(col[8], 'referringFirstName')
      this.addKeyVal(col[10], 'referringMiddleName')
    },
    DN: (col) => {
      this.addKeyVal(col[16], 'referringProviderNpi')
      this.addKeyVal(col[6], 'referringLastName')
      this.addKeyVal(col[8], 'referringFirstName')
      this.addKeyVal(col[10], 'referringMiddleName')
    },
    IL: (col) => {
      this.addKeyVal(col[6], 'subscriberLastName')
      this.addKeyVal(col[8], 'subscriberFirstName')
      this.addKeyVal(col[10], 'subscriberMiddleName')
      this.addKeyVal(col[12], 'subscriberId')
    },
    QC: (col) => {
      this.loop = 'dependent'
      this.addKeyVal(col[6], 'dependentLastName')
      this.addKeyVal(col[8], 'dependentFirstName')
      this.addKeyVal(col[10], 'dependentMiddleName')
      this.addKeyVal(col[12], 'dependentSubscriberId')
    },
    SJ: (col) => {
      this.loop = 'serviceProvider'
      this.addKeyVal(col[16], 'serviceProviderNpi')
      this.addKeyVal(col[6], 'serviceLastName')
      this.addKeyVal(col[8], 'serviceFirstName')
      this.addKeyVal(col[10], 'serviceMiddleName')
    },
    '77': (col) => {
      this.loop = 'serviceFacility'
      this.addKeyVal(col[14], 'serviceFacilityNpi')
      this.addKeyVal(col[6], 'serviceFacilityName')
    },
    'FA': (col) => {
      this.loop = 'serviceFacility'
      this.addKeyVal(col[14], 'serviceFacilityNpi')
      this.addKeyVal(col[6], 'serviceFacilityName')
    },
  }

  private segmentDMG: Record<string, (date: string, col: string[]) => void> = {
    subscriber: (date, col) => {
      this.addKeyVal(date, 'subscriberDOB')
      this.addKeyVal(col[6], 'subscriberSex')
    },
    dependent: (date, col) => {
      this.addKeyVal(date, 'dependentDOB')
      this.addKeyVal(col[6], 'dependentSex')
    }
  }

  private segmentHL: Record<string,  () => void> = {
    EV: () => { this.loop = 'patientEvent' },
    SS: () => { this.loop = 'serviceLines' },
  }

  private segmentN3: Record<string, (col: string[]) => void> = {
    serviceProvider: (col) => { this.addKeyVal(col[2], 'renderingProviderAddress') },
    referringProvider: (col) => { this.addKeyVal(col[2], 'referringProviderAddress') },
    serviceFacility: (col) => { this.addKeyVal(col[2], 'billingProviderAddress') },
  }

  private segmentN4: Record<string, (col: string[]) => void> = {
    serviceProvider: (col) => { this.res['renderingProviderAddress'] += [col[2], col[4], col[6]].join(' ') },
    referringProvider: (col) => { this.res['referringProviderAddress'] += [col[2], col[4], col[6]].join(' ') },
    serviceFacility: (col) => { this.res['billingProviderAddress'] += [col[2], col[4], col[6]].join(' ') },
  }

  private segmentSV = (col: string[]) => {
    if (this.res['procedures']) {
      this.res['procedures'] += `,${col[2].split(':')[1]}`
    } else {
      this.addKeyVal(col[2].split(':')[1], 'procedures')
    }
  }
}
