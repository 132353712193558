<div class="container-fluid overflow-none">
  <div class="row">
    <div class="col-lg-4 vh-90">
      <div class="row">
        <div class="d-flex col-9 pt-3">
          <h4 class="mr-5 text-secondary">USAGE</h4>
        </div>
        <div class="col-3 justify-content-end">
          <div class="input-group form-group">
            <select id="timeFrame" class="custom-select custom-select shadow-sm" [formControl]="timeFrame">
              <option value="1">24 Hours</option>
              <option value="7">7 Days</option>
              <option value="30">30 Days</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row bg-alt-lighter-gray vh-100 overflow-auto">
        <app-observe-usage-summary
          class="col-sm-12"
          [timeFilter]="timeFrame?.value"
          (createRuleForUsageSummary)="toggleEditControlSummary($event)"
        ></app-observe-usage-summary>
      </div>
    </div>

    <div class="vh-90" [ngClass]="showEditControl ? 'col-lg-4' : 'col-lg-8'">
      <div class="row">
        <div class="d-flex col-6 pt-3">
          <h4 class="mr-5 text-secondary">RULES</h4>
        </div>

        <div class="col-3 justify-content-end">
          <div class="input-group form-group">
            <input
              type="text"
              class="form-control rounded-lg"
              placeholder="Search Observe ACL"
              [formControl]="accessControlSearchText"
            />
            <div class="input-group-append">
              <span class="in-input-icon d-flex align-items-center">
                <button type="button" class="btn bg-transparent">
                  <i *ngIf="!showEditControl" class="fa fa-search fa-fw text-brand-dark-gray"></i>
                  <span class="sr-only">Search Observe access controls</span>
                </button>
              </span>
            </div>
          </div>
        </div>

        <div class="col-3 justify-content-end">
          <div class="input-group form-group">
            <input type="text" class="form-control rounded-lg" placeholder="Run Test" [formControl]="regexTest" />
          </div>
        </div>
      </div>
      <div class="row bg-alt-lighter-gray vh-100 overflow-auto">
        <app-observe-access-control-list
          class="col-sm-12"
          [searchText]="accessControlSearchText?.value"
          [patternToMatch]="debouncedRegexTest"
          (createRuleForAccessControl)="toggleEditControlACL($event)"
        >
        </app-observe-access-control-list>
      </div>
    </div>

    <div
      class="shadow overflow-hidden mb-4 fade"
      [ngbCollapse]="!showEditControl"
      [ngClass]="{ 'col-4 p-0': showEditControl }"
    >
      <div class="bg-white collapse-content" *ngIf="showEditControl">
        <app-observe-access-control-edit
          (closeEvent)="closeEditControl()"
          [inputRule]="inputRule"
        ></app-observe-access-control-edit>
      </div>
    </div>
  </div>
</div>
