import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskCardActionsService } from 'app/admin/task-types/task-card-actions.service'
import { ClaimsService } from 'app/claims/claims.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ProgressModalComponent } from 'app/shared/components/progress-modal/progress-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { chunkTasksAndClaims } from 'app/shared/utils/chunk-tasks-claims'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { jitter } from 'app/shared/utils/retry-jitter'
import type BN from 'bottleneck'
import { BulkTaskCreateResponse, TaskType } from 'generated/graphql'
import { flatten, uniq } from 'lodash'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

declare const Bottleneck: typeof BN

interface BulkTaskCreateReportPlus extends BulkTaskCreateResponse {
  taskName?: string
}

/**
 * Page to allow assigning a list of task types to claim(s)
 *
 * @export
 * @class TaskTypeAssignPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-type-assign-page',
  templateUrl: './assign.page.html',
  styleUrls: ['./assign.page.scss'],
})
export class TaskTypeAssignPage implements OnInit, OnDestroy {
  rightSub$: Subscription
  removeSub$: Subscription
  pencilSub$: Subscription

  routerSub$: Subscription

  tasks: TaskType[] = []
  taskCollapsed = []

  claimIds = new UntypedFormControl('')
  claimIds$: Subscription
  claims: string[] = []

  duplicates = new UntypedFormControl(false)

  saving: boolean = false
  assigned: BulkTaskCreateReportPlus[] = []
  failed: BulkTaskCreateReportPlus[] = []
  triggerRoute$ = new Subject<boolean>()

  // Modal
  @ViewChild('content', { static: true }) content: ElementRef

  constructor(
    private router: Router,
    private actionService: TaskCardActionsService,
    private toast: ToastService,
    private claimService: ClaimsService,
    private tasktypeService: TaskTypesService,
    private taskService: TasksService,
    private modal: NgbModal,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
    .pipe(
      filter(([event, trigger], index) => event instanceof NavigationEnd || trigger)
    ).subscribe(() => {
      this.onRouteChange()
    })
    this.rightSub$ = actionService.rightClicked$.subscribe((taskType: TaskType) => {
      let exists = this.tasks.findIndex((tt) => tt.id === taskType.id)
      if (exists === -1) {
        this.tasks.push(taskType)
        this.taskCollapsed.push(true)
      }
      this.actionService.hideCard(taskType, true)
    })
    this.removeSub$ = actionService.removeClicked$.subscribe((taskType: TaskType) => {
      let exists = this.tasks.findIndex((tt) => tt.id === taskType.id)
      this.tasks.splice(exists, 1)
      this.taskCollapsed.splice(exists, 1)
      this.actionService.hideCard(taskType, false)
    })
    this.pencilSub$ = actionService.pencilClicked$.subscribe((taskType: TaskType) => {
      router.navigate(['admin/task-types/edit', taskType.id])
    })
    this.claimIds$ = this.claimIds.valueChanges
      .pipe(
        tap((ids: string) => {
          if (ids?.length > 0) {
            let claims = uniq(ids.split(/\t|\n|,/))
            this.claims = claims.filter((c) => c?.length)
          } else {
            this.claims = []
          }
        }),
      )
      .subscribe()
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.rightSub$.unsubscribe()
    this.removeSub$.unsubscribe()
    this.pencilSub$.unsubscribe()
    this.claimIds$.unsubscribe()
    this.routerSub$.unsubscribe()
  }

  /**
   * Set list of claims to which to assign tasks
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeAssignPage
   */
  async onRouteChange(): Promise<void> {
    let providerClaimIDs = this.router.getCurrentNavigation().extras?.state?.data?.providerClaimIDs
    if (providerClaimIDs) {
      this.claimIds.setValue(providerClaimIDs.join())
    }
  }

  /**
   * Helper for performant ngFor lists
   *
   * @param {number} index
   * @param {string} item
   * @return {*} {string}
   * @memberof TaskTypeAssignPage
   */
  trackBy(index: number, item: string): string {
    return item
  }

  /**
   * Cancel current assignment and clear inputs
   *
   * @memberof TaskTypeAssignPage
   */
  cancel(): void {
    this.tasks = []
    this.claims = []
    this.claimIds.reset()
    this.actionService.hidden = []
  }

  /**
   * Assign list of task types to list of claims
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeAssignPage
   */
  async save(): Promise<void> {
    this.assigned = []
    this.failed = []
    this.saving = true

    let promises = chunkTasksAndClaims(100, this.tasks, this.claims)

    let limiter = new Bottleneck({
      maxConcurrent: 6,
      minTime: 333,
      trackDoneStatus: true, // this might be problematic!
    })

    let jitters = {}

    limiter.on('failed', (error, info) => {
      if (info.retryCount < 10) {
        let previous = info.retryCount === 0 ? 0 : jitters[info.options.id]
        let jit = jitter(previous, info.retryCount + 1)
        jitters[info.options.id] = jit
        return jit
      }
    })

    let call = (p) => {
      return this.taskService.createTasks(p, this.duplicates.value)
    }
    let wrapped = limiter.wrap(call)

    let modalRef = this.modal.open(ProgressModalComponent, {
      size: 'lg',
      centered: true,
    })
    modalRef.componentInstance.title = 'Creating tasks on selected claims...'
    modalRef.componentInstance.total = promises.length
    modalRef.componentInstance.current = 0

    let interval = setInterval(() => {
      let count = limiter.counts()
      modalRef.componentInstance.current = count.DONE
    }, 1000)

    modalRef.result.then(
      () => {},
      () => {
        clearInterval(interval)
        limiter.stop({ dropWaitingJobs: true })
      },
    )
    try {
      let reps: BulkTaskCreateResponse[][] = await Promise.all(promises.map((p) => wrapped(p)))
      modalRef.componentInstance.current = promises.length
      modalRef.dismiss()

      let responses = flatten(reps) || []

      responses.forEach((r: BulkTaskCreateReportPlus) => {
        r.taskName = this.tasks.find((t) => t.id === r.taskTypeId)?.name
      })

      this.assigned = responses.filter((r) => r.succeeded)
      this.failed = responses.filter((r) => !r.succeeded)

      const successModal = this.modal.open(this.content, {
        centered: true,
      })

      successModal.result.then(
        (closed) => this.cancel(),
        (dismissed) => this.cancel(),
      )
    } catch (e) {
      modalRef.dismiss()
      this.toast.error(parseGraphQLError(e, 'Could not assign tasks'), JSON.stringify(e))
    }
    this.saving = false
  }

  /**
   * Navigate to 'assign tasks to task group' page with newly
   * created tasks
   *
   * @memberof TaskTypeAssignPage
   */
  assignTasksToTaskGroup(): void {
    this.modal.dismissAll()
    this.router.navigate(['admin/task-groups/assign'], { state: { data: { providerClaimIDs: this.claims } } })
  }
}
