import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
})
export class MfaComponent implements AfterViewInit {
  @Input() loading = false
  @Output() onSubmit = new EventEmitter<string>()
  @ViewChild('authCode') input: ElementRef
  authenticationForm = new UntypedFormGroup({
    code1: new UntypedFormControl(null, Validators.required),
    code2: new UntypedFormControl(null, Validators.required),
    code3: new UntypedFormControl(null, Validators.required),
    code4: new UntypedFormControl(null, Validators.required),
    code5: new UntypedFormControl(null, Validators.required),
    code6: new UntypedFormControl(null, Validators.required),
  })

  constructor(private toast: ToastService) {}

  ngAfterViewInit(): void {
    this.input.nativeElement.focus()
  }

  /**
   * Alert user if mfa field is invalid, or send it
   *
   * @return {*}  {Promise<void>}
   * @memberof LoginPage
   */
  async submit(): Promise<void> {
    this.authenticationForm.markAllAsTouched()
    if (this.authenticationForm.invalid) {
      this.toast.error(
        'Please fill in required field',
        JSON.stringify(readableFormGroupErrors(this.authenticationForm)),
      )
      return
    }
    let form = this.authenticationForm.value
    let authCode = `${form.code1}${form.code2}${form.code3}${form.code4}${form.code5}${form.code6}`
    this.onSubmit.emit(authCode)
  }

  /**
   * Helper for pasting an authorization code into the form
   *
   * @param {ClipboardEvent} event
   * @memberof LoginPage
   */
  onPaste(event: ClipboardEvent): void {
    let pastedText = event.clipboardData.getData('text').split('')
    this.authenticationForm.setValue({
      code1: pastedText[0],
      code2: pastedText[1],
      code3: pastedText[2],
      code4: pastedText[3],
      code5: pastedText[4],
      code6: pastedText[5],
    })
  }

  onKeydown(event: Event): void {
    let key = (event as KeyboardEvent).key
    if (key === 'Backspace') {
      event.preventDefault()
      let dataT = (event.target as HTMLInputElement).getAttribute('data-t')
      this.authenticationForm.get(dataT).setValue('')
      ;((event.target as HTMLInputElement).previousElementSibling as HTMLInputElement)?.focus()
    } else if (key.match(/^\d$/)) {
      event.preventDefault()
      let dataT = (event.target as HTMLInputElement).getAttribute('data-t')
      this.authenticationForm.get(dataT).setValue(key)
      ;((event.target as HTMLInputElement).nextElementSibling as HTMLInputElement)?.focus()
    }
  }
}
