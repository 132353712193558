import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { User } from 'generated/graphql'
import * as md5 from 'md5'

const SIZE = {
  sm: 50,
  md: 50,
  lg: 125,
}

/**
 * Component to display a user avatar
 *
 * @export
 * @class UserAvatarComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  host: {
    '[class.d-block]': 'size === "lg"',
    '[class.d-inline-block]': 'size === "sm"',
  },
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() size: 'sm' | 'md' | 'lg' = 'sm'
  @Input() user: User = null
  @Input() randomColor = false
  @Input() invertColor = false
  @Input() showTooltip = false

  gravatar: SafeUrl
  initials = ''
  color = ''
  showDefault: boolean = false
  toolTipText = ''

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.user && this.size) {
      let hash = md5(this.user?.email?.toString())
      this.gravatar = this.sanitizer.bypassSecurityTrustUrl(
        `https://www.gravatar.com/avatar/${hash}?d=404&s=${SIZE[this.size]}`,
      )
    } else {
      this.showDefault = true
    }
    let initials =
      this.user?.name
        ?.split(' ')
        .map((name) => name?.substr(0, 1)?.toUpperCase())
        .join('') || 'NA'

    if (initials?.length < 2) {
      initials += '_' // hackity hack
    }
    this.initials = initials

    this.color = this.randomColor ? `cp-${Math.floor(Math.random() * (5 - 1) + 1)}` : ''

    this.toolTipText = this.showTooltip ? this.user?.name || this.user?.email : ''
  }
}
