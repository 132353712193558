import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ClaimsService } from 'app/claims/claims.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, TaskType } from 'generated/graphql'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

/**
 * Page to edit an existing task type
 *
 * @export
 * @class TaskTypeDetailsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-type-edit-details-page',
  templateUrl: './task-type-details.page.html',
  styleUrls: ['./task-type-details.page.scss'],
})
export class TaskTypeDetailsPage implements OnInit, OnDestroy {
  taskType: TaskType
  taskForm = new UntypedFormGroup({
    taskType: new UntypedFormControl('', Validators.required),
    instructions: new UntypedFormControl('', Validators.required),
    aveMinutes: new UntypedFormControl(0, Validators.required),
  })

  claims: Claim[]
  totalClaims = 0

  saving: boolean = false
  loading: boolean = false
  copying: boolean = false

  routerSub$: Subscription
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private tasktypeService: TaskTypesService,
    private claimService: ClaimsService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private modal: NgbModal,
    private crumbs: BreadcrumbsService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger], index) => event instanceof NavigationEnd || trigger))
      .subscribe((event) => {
        this.onRouteChange()
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }

  /**
   * Load a task type and associated data
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeDetailsPage
   */
  async onRouteChange(): Promise<void> {
    let taskTypeId = this.route.snapshot.paramMap.get('taskTypeId')

    if (taskTypeId) {
      this.loading = true
      try {
        this.taskType = await this.tasktypeService.getTaskType(taskTypeId)

        this.crumbs.setPageTitle('Admin - Task Types - ' + this.taskType?.name)

        this.taskForm.setValue({
          taskType: this.taskType?.name,
          instructions: this.taskType?.description,
          aveMinutes: this.taskType?.averageMinutes,
        })
        // let lastCrumb = this.breadcrumbs.breadcrumbs[this.breadcrumbs.breadcrumbs.length - 1]
        // lastCrumb.label = this.taskType.name

        this.claims = await this.claimService.getClaimsByTaskType(taskTypeId)

        this.totalClaims = this.claimService.getMeta()?.total
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not find task type'), JSON.stringify(e))
        this.taskType = null
      }
      this.loading = false
    } else {
      this.taskType = null
    }
  }

  /**
   * Save edits to current task type
   *
   * @return {*} {Promise<void>}
   * @memberof TaskTypeDetailsPage
   */
  async updateTaskType(): Promise<void> {
    try {
      await this.tasktypeService.updateTaskType(this.taskType.id, {
        name: this.taskForm.get('taskType').value,
        description: this.taskForm.get('instructions').value,
        averageMinutes: this.taskForm.get('aveMinutes').value,
      })
      this.toast.success('Task successfully updated')
      this.router.navigate(['admin/task-types/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not update task type'), JSON.stringify(e))
    }
  }

  /**
   * Launch modal to confirm deleting current task type
   *
   * @memberof TaskTypeDetailsPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete task type?'
    modalRef.componentInstance.body = `This task will be removed from about ${this.totalClaims} claim(s)`
    modalRef.componentInstance.yes = 'Delete task type'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTaskType()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current task type
   *
   * @return {*} {Promise<void>}
   * @memberof TaskTypeDetailsPage
   */
  async deleteTaskType(): Promise<void> {
    try {
      await this.tasktypeService.deleteTaskType(this.taskType.id)
      this.toast.success('Task successfully deleted')
      this.router.navigate(['admin/task-types/edit'], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete task type'), JSON.stringify(e))
    }
  }

  /**
   * Create a copy of the current task type and open it
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeDetailsPage
   */
  async duplicate(): Promise<void> {
    try {
      this.copying = true
      let task = await this.tasktypeService.createTaskType({
        name: this.taskType.name,
        description: this.taskType.description,
        averageMinutes: this.taskType.averageMinutes,
      })
      this.toast.success('Task Type copied')
      this.router.navigate(['admin/task-types/edit', task.id], { state: { data: { reloadSearch: true } } })
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not create Task Type'), JSON.stringify(e))
    }
    this.copying = false
  }

  /**
   * Cancel current edits and navigate back to task type list
   *
   * @memberof TaskTypeDetailsPage
   */
  cancel(): void {
    this.router.navigate(['admin/task-types/edit'])
  }
}
