<div class="d-flex align-items-center justify-content-center flex-column">
  <div class="d-flex flex-row">
    <h4 class="text-dark mt-2 mb-4">Email Authentication</h4>
  </div>
  <div class="d-flex flex-row w-75">
    <form [formGroup]="authenticationForm" (ngSubmit)="submit()" class="d-flex flex-column">
      <label for="authCode" class="text-muted text-center my-4">
        Please enter the authentication code that was sent to your email. 
      </label>
      <div class="input-group form-group">
        <input
          #authCode
          id="authCode"
          type="text"
          formControlName="code1"
          autofocus=""
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code1"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
        <input
          type="text"
          formControlName="code2"
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code2"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
        <input
          type="text"
          formControlName="code3"
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code3"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
        <input
          type="text"
          formControlName="code4"
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code4"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
        <input
          type="text"
          formControlName="code5"
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code5"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
        <input
          type="text"
          formControlName="code6"
          maxlength="1"
          class="form-control bg-light mt-3 text-center mx-1"
          data-t="code6"
          (paste)="onPaste($event)"
          (keydown)="onKeydown($event)"
        />
      </div>
      <button class="btn btn-alt-light-blue py-4 btn-block border-0 text-light text-uppercase mt-4" type="submit">
        Submit Code
        <app-loading *ngIf="loading" class="pl-2"></app-loading>
      </button>
    </form>
  </div>
</div>
