import { Component } from '@angular/core'

/**
 * Component that is the links for qedi admin
 */
@Component({
  selector: 'app-qedi-admin-links',
  template: `
    <h3>QEDI</h3>
    <ul class="list-unstyled pl-4">
      <li><a routerLink="/admin/qedi/functional-areas">Functional Areas</a></li>
      <li><a routerLink="/admin/qedi/workflows">Workflows</a></li>
    </ul>
  `,
})
export class QediAdminLinksComponent {}
