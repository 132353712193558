<div class="row h-100">
  <div class="col-lg-3 d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 flex-basis-0 scrolling-sidebar">
      <app-team-quick-stats></app-team-quick-stats>
    </div>
  </div>
  <div class="col-lg-9 d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column flex-grow-1 flex-basis-0 scrolling-sidebar">
      <app-employee-productivity></app-employee-productivity>
    </div>
  </div>
</div>
