import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { IlWorkflowGrouping, ObserveRecordAnnotated } from 'generated/graphql'

@Injectable({
  providedIn: 'root',
})
export class AnnotationsStateService {
  /**
   * Emits changes to the "active" workflow grouping.
   */
  currentWorkflowGroup$ = new BehaviorSubject<IlWorkflowGrouping>(null)

  /**
   * Emits changes to the "active" annotated observe record.
   */
  currentObserveRecordAnnotated$ = new BehaviorSubject<ObserveRecordAnnotated>(null)

  constructor() {}
}
