import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetBotEmail, GetBotEmails } from 'app/admin/client-credentials/emails.gql'
import {
  BotEmail,
  BotEmailIdentifier,
  BotEmailIdentifierList,
  QueryBotEmailArgs,
  QueryBotEmailsArgs,
} from 'generated/graphql'

/**
 * Retrieve bot emails
 *
 * @export
 * @class EmailService
 */
@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve a list of bot emails for a user
   *
   * @param {string} username
   * @return {*}  {Promise<BotEmailIdentifier[]>}
   * @memberof EmailService
   */
  async getBotEmailKeys(username: string): Promise<BotEmailIdentifier[]> {
    let result = await this.apollo
      .query<{ botEmails: BotEmailIdentifierList }, QueryBotEmailsArgs>({
        query: GetBotEmails,
        variables: {
          username,
        },
      })
      .toPromise()

    return result.data?.botEmails?.entities
  }

  /**
   * Retrieve a single bot email
   *
   * @param {string} key
   * @return {*}  {Promise<BotEmail>}
   * @memberof EmailService
   */
  async getBotEmail(key: string): Promise<BotEmail> {
    let result = await this.apollo
      .query<{ botEmail: BotEmail }, QueryBotEmailArgs>({
        query: GetBotEmail,
        variables: {
          key,
        },
      })
      .toPromise()

    return result.data?.botEmail
  }
}
