import { Component, Input, OnDestroy } from '@angular/core'
import { AbstractControl, UntypedFormControl } from '@angular/forms'
import { OrgService } from 'app/admin/org/org.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { uniq } from 'lodash'
import { Observable, OperatorFunction, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators'
import { v4 as uuid } from 'uuid'

@Component({
  selector: 'app-select-org-set',
  templateUrl: './select-org-set.component.html',
  styleUrls: ['./select-org-set.component.scss'],
})
export class SelectOrgSetComponent implements OnDestroy {
  @Input() orgSetId: UntypedFormControl | AbstractControl = null

  get orgSetIdFc(): UntypedFormControl {
    return this.orgSetId as UntypedFormControl
  }

  existingOrgSetIds: string[] = []
  destroy$ = new Subject<void>()

  constructor(private orgs: OrgService, private toast: ToastService) {
    this.setExistingOrgSetIds()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private setExistingOrgSetIds(): void {
    this.orgs
      .getOrgs(null, 100)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result.error || result.errors) {
          this.toast.error(
            parseGraphQLError(result, 'Could not load org sets'),
            JSON.stringify(result.error || result.errors),
          )
        } else {
          this.existingOrgSetIds = uniq(
            result?.data?.organizations?.entities?.filter((org) => org.orgSetId).map((org) => org.orgSetId),
          )
        }
      })
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term?.length < 1 ? [] : this.existingOrgSetIds.filter((v) => v.indexOf(term) > -1).slice(0, 10))),
    )

  createNewOrgSetId(): void {
    this.orgSetIdFc.setValue(uuid())
  }
}
