import { Component } from '@angular/core'
import { ZendeskService } from 'app/shared/services/zendesk.service'

@Component({
  selector: 'app-auth-layout-component',
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent {
  constructor(protected zendesk: ZendeskService) {}
}
