import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { CreatePayer, GetPayer, MergePayers, SearchPayers, UpdatePayer } from 'app/shared/services/payers.gql'
import { debug } from 'app/shared/utils/debug'
import {
  CreatePayerInput,
  ListResponseMetaData,
  MutationCreatePayerArgs,
  MutationMergePayersArgs,
  MutationUpdatePayerArgs,
  Payer,
  PayerList,
  QueryPayerArgs,
  QueryPayersArgs,
  UpdatePayerInput,
} from 'generated/graphql'

/**
 * Handle payer operations
 *
 * @export
 * @class PayersService
 */
@Injectable({
  providedIn: 'root',
})
export class PayersService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof PayersService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve a single payer
   *
   * @param {string} id
   * @return {*}  {Promise<Payer>}
   * @memberof PayersService
   */
  async getPayer(id: string): Promise<Payer> {
    let result = await this.apollo
      .query<{ payer: Payer }, QueryPayerArgs>({
        query: GetPayer,
        variables: {
          id,
        },
      })
      .toPromise()

    return result.data.payer
  }

  /**
   * Retrieve a list of payers
   *
   * @param {QueryPayersArgs} [variables]
   * @return {*}  {Promise<Payer[]>}
   * @memberof PayersService
   */
  async searchPayers(variables?: QueryPayersArgs): Promise<Payer[]> {
    debug('payer-service', 'search for payer', variables?.search)
    let result = await this.apollo
      .query<{ payers: PayerList }, QueryPayersArgs>({
        query: SearchPayers,
        variables,
      })
      .toPromise()

    this.meta = result.data.payers.meta

    return result.data.payers.entities
  }

  /**
   * Create a new payer
   *
   * @param {CreatePayerInput} data
   * @return {*}  {Promise<Payer>}
   * @memberof PayersService
   */
  async createPayer(data: CreatePayerInput): Promise<Payer> {
    let result = await this.apollo
      .mutate<{ createPayer: Payer }, MutationCreatePayerArgs>({
        mutation: CreatePayer,
        variables: { data },
      })
      .toPromise()

    return result.data?.createPayer
  }

  /**
   * Update an existing payer
   *
   * @param {string} id
   * @param {UpdatePayerInput} data
   * @return {*}  {Promise<Payer>}
   * @memberof PayersService
   */
  async updatePayer(id: string, data: UpdatePayerInput): Promise<Payer> {
    let result = await this.apollo
      .mutate<{ updatePayer: Payer }, MutationUpdatePayerArgs>({
        mutation: UpdatePayer,
        variables: { id, data },
      })
      .toPromise()

    return result.data?.updatePayer
  }

  /**
   * Merge a list of payers into a single source payer
   *
   * @param {string} parentId
   * @param {string[]} childIds
   * @return {*}  {Promise<boolean>}
   * @memberof PayersService
   */
  async mergePayers(parentId: string, childIds: string[]): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ mergePayers: boolean }, MutationMergePayersArgs>({
        mutation: MergePayers,
        variables: { parentId, childIds },
      })
      .toPromise()

    return result.data?.mergePayers
  }
}
