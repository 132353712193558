<div class="d-flex flex-column flex-grow-1 dashboard-background m-n4">
  <div class="tableauPlaceholder" style="width: 100%; height: 877px">
    <object class="tableauViz" width="100%" height="877px" style="display: none">
      <param name="host_url" [value]="'https%3A%2F%2Ftableau.' + env + '.janus-ai.com%2F'" />
      <param name="embed_code_version" value="3" />
      <param name="site_root" value="" />
      <param name="name" [value]="dashboard" />
      <param name="tabs" value="yes" />
      <param name="toolbar" value="top" />
      <param name="dataDetails" value="no" />
      <param name="showAppBanner" value="false" />
    </object>
  </div>
</div>
