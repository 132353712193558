import { Component, Input } from '@angular/core'

/**
 * Component to display the task status
 *
 * @export
 * @class TaskStatusIconComponent
 */
@Component({
  selector: 'app-task-status-icon',
  templateUrl: './task-status-icon.component.html',
  styleUrls: ['./task-status-icon.component.scss'],
  host: {
    '[class.d-flex]': 'true',
    '[class.align-self-stretch]': 'true',
  },
})
export class TaskStatusIconComponent {
  @Input() completed: boolean = null
  @Input() rejected: boolean = null

  constructor() {}
}
