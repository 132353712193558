import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { compact, startCase } from 'lodash'

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: [],
})
export class FilterDropdownComponent implements OnInit {
  @Input() filterFormGroups: UntypedFormGroup[] = []
  @Input() iconButton?: boolean = false
  @Input() placeholderText?: string = 'Filter'

  filters: { title: string; filterName: string }[][] = []

  constructor() {}

  get placeholder(): string {
    if (this.selectedFilterCount === 0) {
      return this.placeholderText
    } else {
      return `${this.placeholderText} (${this.selectedFilterCount})`
    }
  }

  get selectedFilterCount(): number {
    return this.filterFormGroups.reduce(
      (total, formGroup) => (total += compact(Object.values(formGroup.value)).length),
      0,
    )
  }

  ngOnInit(): void {
    const filters = this.filterFormGroups.map((filterFormGroup) => {
      return filterFormGroup.controls ? Object.keys(filterFormGroup.controls) : []
    })

    this.filters = filters.map((filterGroup) => {
      return filterGroup.map((filter) => {
        return {
          title: startCase(filter),
          filterName: filter,
        }
      })
    })
  }

  setAllToValue(value: boolean): void {
    this.filterFormGroups.forEach((filterFormGroup) => {
      const filters = Object.keys(filterFormGroup.controls)
      const formGroupValue = {}
      filters.forEach((control) => {
        formGroupValue[control] = value
      })
      filterFormGroup.setValue(formGroupValue)
    })
  }
}
