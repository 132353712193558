<div class="row border-bottom border-primary mb-3">
  <h4 class="col-lg-9 text-dark pt-2">{{ taskGroup?.name }}</h4>
  <div class="d-flex col-lg-3 my-3 justify-content-lg-end justify-content-start align-items-center">
    <app-user-avatar
      class="flex-shrink-1 mx-3"
      *ngFor="let user of users"
      [user]="user"
      [randomColor]="true"
      [showTooltip]="true"
    ></app-user-avatar>
  </div>
</div>
<!-- <div class="row">
  <div class="col">
    <p [innerHTML]="taskGroup?.description"></p>
  </div>
</div> -->
<!-- <div class="row">
  <div class="col col-lg-6 d-flex">
    <app-task-group-score [taskGroup]="taskGroup"></app-task-group-score>
    <small>Something about <strong>$money</strong></small>
    <small><strong>$$$$</strong> out of <strong>$$$$$$</strong></small>
    <div class="d-flex w-100">
      <span class="flex-grow-1 align-self-center mr-3">
        <ngb-progressbar type="success" class="progress" max="12" value="4" striped="true"></ngb-progressbar>
      </span>
      <small class="flex-shrink-1"> 4/12 </small>
    </div>
  </div>
</div> -->
