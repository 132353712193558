import { Injectable } from '@angular/core'
import { EdgeConfig, NodeConfig } from '@antv/g6-core'
import { isNil } from 'lodash'

import { opportunities, Opportunity } from 'app/illuminate/opportunities'
import { CumulativeOpportunityData } from 'app/illuminate/opportunities/cumulativeOpportunityData'

import * as AdrRequestsProcess from './processes/adr-requests-process'
import * as ModifierReviewProcess from './processes/modifier-review-process'
import * as PriorAuthProcess from './processes/prior-auth-process'

// Processes by their ID
const processesById = {
  '111': AdrRequestsProcess,
  '222': ModifierReviewProcess,
  'e9742b47-480a-4a9c-a525-5dfcd2b4bb89': PriorAuthProcess,
}

const isProcessKey = (inputId: unknown): inputId is keyof typeof processesById => {
  if (typeof inputId !== 'string') {
    return false
  }
  return Object.keys(processesById).includes(inputId)
}

/**
 * Load opportunity data
 * CURRENTLY DEMO
 *
 * @export
 * @class OpportunitiesService
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunitiesService {
  constructor() {}

  /**
   * Retrieve and sort list of opportunities
   *
   * @return {*}  {Opportunity[]}
   * @memberof OpportunitiesService
   */
  getOpportunities(): Opportunity[] {
    return opportunities.sort((a, b) => {
      return (
        b.monthlyNetProfitabilityOfCollection.fullAutomation -
        b.monthlyNetProfitabilityOfCollection.baseLine -
        (a.monthlyNetProfitabilityOfCollection.fullAutomation - a.monthlyNetProfitabilityOfCollection.baseLine)
      )
    })
  }

  /**
   * Retrieve single opportunity
   *
   * @param {string} opportunityId
   * @return {*}  {Opportunity}
   * @memberof OpportunitiesService
   */
  getOpportunity(opportunityId: string): Opportunity {
    return opportunities.find((opportunity) => opportunity.id === opportunityId)
  }

  /**
   * Get cumulative data about opportunities
   *
   * @param {Opportunity[]} opportunities
   * @return {*}  {CumulativeOpportunityData}
   * @memberof OpportunitiesService
   */
  getCumulativeOpportunityData(opportunities: Opportunity[]): CumulativeOpportunityData {
    let forcastedValue = 0
    let actualValue = 0
    //let totalHoursSaved = 0
    let totalClaimsAutomated = 0

    opportunities.forEach((opportunity) => {
      if (!isNil(opportunity?.monthlyNetProfitabilityOfCollection?.baseLine)) {
        if (!isNil(opportunity?.monthlyNetProfitabilityOfCollection?.fullAutomation)) {
          forcastedValue +=
            ((opportunity.monthlyNetProfitabilityOfCollection.fullAutomation -
              opportunity.monthlyNetProfitabilityOfCollection.baseLine) *
              12) /
            100
        }

        if (!isNil(opportunity?.monthlyNetProfitabilityOfCollection?.currentPhase)) {
          actualValue +=
            ((opportunity.monthlyNetProfitabilityOfCollection.currentPhase -
              opportunity.monthlyNetProfitabilityOfCollection.baseLine) *
              12) /
            100
        }

        if (opportunity?.processVolume) {
          //totalHoursSaved += (opportunity.processVolume * (opportunity.processTime || 0) * 12) / 60

          totalClaimsAutomated += opportunity.processVolume * 12
        }
      }
    })

    const round = (decimal: number): number => {
      return Math.round(decimal * 100) / 100
    }

    return {
      forcastedValue: round(forcastedValue),
      actualValue: round(actualValue),
      totalBotsDeployed: 238, // this seems fine
      totalHoursSaved: 48.1 * 10 ** 3, // round(totalHoursSaved),
      totalClaimsAutomated: round(totalClaimsAutomated),
    }
  }

  /**
   * Retrieve main / left graph's data for opportunity
   *
   * @param {string} opportunityId
   * @return {*}  {*}
   * @memberof OpportunitiesService
   */
  getMainProcessData(opportunityId: string): any {
    if (!isProcessKey(opportunityId)) {
      // Not implemented!
      return // Do nothing with it
    }

    const process = processesById[opportunityId]
    return { nodes: process.MainNodes, edges: process.MainEdges }
  }

  /**
   * Retrieve path / right graph for opportunity & selected path
   *
   * @param {string} opportunityId
   * @param {('ideal' | 'decision support' | 'partial Automation' | 'full Automation' | 'longest' | 'lowest')} path
   * @return {*}  {{ nodes: NodeConfig[]; edges: EdgeConfig[]; data: any }}
   * @memberof OpportunitiesService
   */
  getPathData(
    opportunityId: string,
    path: 'ideal' | 'decision support' | 'partial Automation' | 'full Automation' | 'longest' | 'lowest',
  ): { nodes: NodeConfig[]; edges: EdgeConfig[]; data: any } {
    if (!isProcessKey(opportunityId)) {
      // Not implemented!
      return // Do nothing with it
    }

    const process = processesById[opportunityId]

    // It seems each opportunity encodes a partially specialized return value
    // depending on the path. That makes removing this conditional nest very
    // difficult or impossible
    switch (path) {
      case 'ideal': {
        // Same for all
        return { nodes: process.IdealNodes, edges: process.IdealEdges, data: process.IdealData }
      }
      case 'decision support': {
        // Return differs based on which process this is. Yuck.
        if (process === AdrRequestsProcess || process === PriorAuthProcess) {
          return { nodes: process.PathfinderNodes, edges: process.PathfinderEdges, data: process.PathfinderData }
        } else if (process === ModifierReviewProcess) {
          return { nodes: process.IdealNodes, edges: process.IdealEdges, data: process.IdealData }
        }
        break // Not really required but linting insists
      }
      case 'full Automation': {
        if (process === AdrRequestsProcess || process === PriorAuthProcess) {
          return { nodes: process.FullAutoNodes, edges: process.FullAutoEdges, data: process.FullAutoData }
        } else if (process === ModifierReviewProcess) {
          return {
            nodes: process.PartialAutomationNodes,
            edges: process.PartialAutomationEdges,
            data: process.PartialAutoData,
          }
        }
        break
      }
      case 'lowest': {
        return { nodes: process.LowestNodes, edges: process.LowestEdges, data: process.LowestData }
      }
      case 'partial Automation': {
        if (process === AdrRequestsProcess || process === PriorAuthProcess) {
          return { nodes: process.FullAutoNodes, edges: process.FullAutoEdges, data: process.PartialAutoData }
        } else if (process === ModifierReviewProcess) {
          return {
            nodes: process.PartialAutomationNodes,
            edges: process.PartialAutomationEdges,
            data: process.PartialAutoData,
          }
        }
        break
      }
      case 'longest': {
        if (process === AdrRequestsProcess || process === PriorAuthProcess) {
          return { nodes: process.LowestNodes, edges: process.LowestEdges, data: process.LongestData }
        } else if (process === ModifierReviewProcess) {
          return { nodes: process.LongestNodes, edges: process.LongestEdges, data: process.LongestData }
        }
        break
      }
    }
  }
}
