<div class="card-header border-0 bg-primary text-white">
  <span *ngIf="organization">Edit SAML Configuration for {{ organization.name }}</span>
  <span><app-loading *ngIf="!organization" class="pl-2"></app-loading></span>
</div>

<form [formGroup]="form">
  <div class="row my-3">
    <div class="col-md-6 col-xl-4">
      <div class="form-group custom-control custom-checkbox">
        <input
          id="enable-saml-sso"
          name="enableSamlSsoInput"
          type="checkbox"
          class="custom-control-input"
          formControlName="enableSamlSso"
          aria-describedby="enableSamlHelp"
        />
        <label class="custom-control-label pr-2" for="enable-saml-sso">Enable SAML Single Sign On</label>
        <small id="enableSamlHelp" class="form-text text-muted">
          If checked use SAML authentication, if not checked use email and password authentication.
        </small>
      </div>
    </div>
  </div>

  <button type="button" (click)="addNewOrgSamlFormGroup()" class="btn btn-primary mr-auto">
    <i class="fa fa-plus mr-3"></i>
    Add Identity Provider
  </button>

  <div
    class="pt-5"
    *ngFor="let orgSaml of form.controls.orgSamlConfigurations.controls; let index = index"
    [formGroup]="orgSaml"
  >
    <div class="row my-3">
      <div class="col-md-6 col-xl-4 d-flex">
        <h2 class="mr-auto">Identity Provider</h2>
        <button class="btn btn-danger" (click)="removeOrgSamlFormGroup(index)">Remove</button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 col-xl-4">
        <div class="form-group d-flex flex-row mb-0">
          <!-- Disable this button when there is no orgSaml id and show a tooltip -->
          <ng-template #disabledDownloadButtonTooltip
            >Please save identity provider to download configuration</ng-template
          >
          <button
            class="btn btn-primary mr-2"
            type="button"
            [ngbTooltip]="!orgSaml.controls.id.value ? disabledDownloadButtonTooltip: ''"
            [disabled]="!orgSaml.controls.id.value"
            (click)="onDownloadMetadataFile(index)"
          >
            Download Identity Provider Configuration
          </button>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label class="pr-2" for="customer-metadata-url">Customer Metadata URL</label>
          <input
            id="customer-metadata-url"
            class="form-control"
            name="metadataUrlInput"
            type="text"
            [class.is-invalid]="(orgSaml.controls.metadataUrl.invalid && orgSaml.controls.metadataUrl.touched)|| ((orgSaml.hasError('oneRequired') || orgSaml.hasError('notBoth')) && orgSaml.touched)"
            formControlName="metadataUrl"
            aria-describedby="metadataUrlHelp"
          />
          <small id="metadataUrlHelp" class="form-text text-muted">
            Metadata URL given by the customer. Only a metadata URL or metadata file can be given, not both.
          </small>
          <div
            *ngIf="orgSaml.controls.metadataUrl.hasError('isValidUrl') && orgSaml.controls.metadataUrl.touched"
            class="invalid-feedback"
          >
            Metadata URL must be valid
          </div>
          <div *ngIf="orgSaml.hasError('oneRequired')" class="invalid-feedback">
            Must provide a metadata URL or metadata file
          </div>
          <div *ngIf="orgSaml.hasError('notBoth')" class="invalid-feedback">
            Must provide a metadata URL or metadata file not both
          </div>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label class="pr-2" for="email-claim-name">Email Claim</label>
          <input
            id="email-claim-name"
            class="form-control"
            [class.is-invalid]="orgSaml.controls.emailClaimName.invalid && orgSaml.controls.emailClaimName.touched"
            name="emailClaimNameInput"
            type="text"
            formControlName="emailClaimName"
            aria-describedby="emailClaimHelp"
          />
          <small id="emailClaimHelp" class="form-text text-muted">SAML email claim provided by the customer.</small>
          <div class="invalid-feedback">Email claim is required</div>
        </div>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-md-6 col-xl-4">
        <div class="form-group">
          <label class="pr-2" for="name-claim-name">Name Claim</label>
          <input
            id="name-claim-name"
            class="form-control"
            name="nameClaimNameInput"
            type="text"
            [class.is-invalid]="orgSaml.controls.nameClaimName.invalid && orgSaml.controls.nameClaimName.touched"
            formControlName="nameClaimName"
            aria-describedby="nameClaimHelp"
          />
          <small id="nameClaimHelp" class="form-text text-muted">SAML name claim provided by the customer.</small>
          <div class="invalid-feedback">Name claim is required</div>
        </div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-md-6 col-xl-4">
        <app-multi-input
          label="Email Domains"
          [arrayFormControl]="orgSaml.controls.emailDomains"
          inputDescribedBy="emailDomainsHelp"
        >
          <small id="emailDomainsHelp" class="form-text text-muted">
            List of one or more email domains that use this identity provider.<br />If a user will log in with
            jordan&#64;janus-ai.com the email domain would be janus-ai.com.
          </small>
          <div class="invalid-feedback" *ngIf="orgSaml.controls.emailDomains.getError('isValidDomain') as domain">
            <span>{{ domain }}</span> is not a valid email domain
          </div>
          <div
            class="invalid-feedback"
            *ngIf="orgSaml.controls.emailDomains.getError('noDuplicatesInFormArray') as domain"
          >
            <span>{{ domain }}</span> is a duplicate email domain
          </div>
          <div class="invalid-feedback" *ngIf="orgSaml.controls.emailDomains.hasError('required')">
            Email domains are required
          </div>
        </app-multi-input>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 col-xl-4">
        <div class="form-group d-flex flex-row">
          <ng-template #disabledUploadButtonTooltip>Please remove Metadata URL to upload a metadata file</ng-template>
          <button
            type="button"
            class="btn btn-primary mr-2"
            (click)="metadataFileUpload.click()"
            [ngbTooltip]="orgSaml.controls.metadataUrl.value ? disabledUploadButtonTooltip: ''"
            [disabled]="orgSaml.controls.metadataUrl.value"
          >
            Upload Metadata File
            <i *ngIf="!orgSaml.controls.metadataFile.value" class="fa fa-upload ml-2"></i>
            <i *ngIf="orgSaml.controls.metadataFile.value" class="fa fa-check ml-2"></i>
            <input
              #metadataFileUpload
              (change)="onMetadataFileUpload($event, index)"
              name="metadataFileUploadInput"
              class="d-none"
              type="file"
            />
            <span class="sr-only">Upload Metadata File</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="orgSaml.controls.metadataFile.value">
      <div class="col-md-6 col-xl-4">
        <div class="form-group d-flex flex-row">
          <span>
            <i class="fa fa-times-circle-o text-danger mr-4 cursor-pointer" (click)="onClearMetadataFile(index)"></i>
            {{ orgSaml.controls.metadataFile.value.name }}
            <i class="fa fa-file-o fa-fw"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4 mb-3" *ngIf="form.controls.orgSamlConfigurations.controls.length > 0 || deleteIds.length > 0">
    <div class="col-md-6 col-xl-4 d-flex">
      <a (click)="onCancel()" class="btn btn-cancel pl-3 pr-4 mr-4"><span class="pl-2">Cancel</span></a>
      <button type="button" (click)="onSave()" class="btn btn-primary pl-4 pr-4">
        <i class="fa fa-floppy-o mr-3"></i>
        Save
      </button>
    </div>
  </div>
</form>
