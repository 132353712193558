import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  AddMemberToTeam,
  CreateTeam,
  DeleteTeam,
  RemoveMemberFromTeam,
  SearchTeams,
  TeamGet,
  UpdateTeam,
} from 'app/admin/teams/teams.gql'
import { debug } from 'app/shared/utils/debug'
import {
  CreateTeamInput,
  MutationAddMemberToTeamArgs,
  MutationCreateTeamArgs,
  MutationDeleteTeamArgs,
  MutationRemoveMemberFromTeamArgs,
  MutationUpdateTeamArgs,
  QueryTeamArgs,
  QueryTeamsArgs,
  Team,
  TeamList,
} from 'generated/graphql'
import { Observable } from 'rxjs'

/**
 * Handle teams operations
 *
 * @export
 * @class TeamsService
 */
@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve a single team
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ team: Team }>>}
   * @memberof TeamsService
   */
  getTeam(id: string): Observable<ApolloQueryResult<{ team: Team }>> {
    debug('teams-service', 'get team', id)
    return this.apollo.watchQuery<{ team: Team }, QueryTeamArgs>({
      query: TeamGet,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of teams
   *
   * @param {string} [term]
   * @return {*}  {Observable<ApolloQueryResult<{ teams: TeamList }>>}
   * @memberof TeamsService
   */
  searchTeams(term?: string): Observable<ApolloQueryResult<{ teams: TeamList }>> {
    debug('teams-service', 'search for teams', term)
    return this.apollo.watchQuery<{ teams: TeamList }, QueryTeamsArgs>({
      query: SearchTeams,
      variables: {
        search: term,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  /**
   * Create a new team
   *
   * @param {CreateTeamInput} data
   * @return {*}  {Promise<Team>}
   * @memberof TeamsService
   */
  async createTeam(data: CreateTeamInput): Promise<Team> {
    debug('teams-service', 'create team', data)
    let result = await this.apollo
      .mutate<{ createTeam: Team }, MutationCreateTeamArgs>({
        mutation: CreateTeam,
        variables: {
          data,
        },
        update: (cache, { data }) => {
          const existingTeams: { teams: TeamList } = cache.readQuery({
            query: SearchTeams,
          })
          const newTeam: Team = data.createTeam
          cache.writeQuery({
            query: SearchTeams,
            data: { teams: [newTeam, ...existingTeams?.teams?.entities] },
          })
        },
      })
      .toPromise()

    return result.data?.createTeam
  }

  /**
   * Delete an existing team
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof TeamsService
   */
  async deleteTeam(id: string): Promise<boolean> {
    debug('team-service', 'delete team', id)
    let result = await this.apollo
      .mutate<{ deleteTeam: boolean }, MutationDeleteTeamArgs>({
        mutation: DeleteTeam,
        variables: {
          id: id,
        },
        update: (store) => {
          let cache = this.apollo.client.cache
          cache.evict({ id: cache.identify({ id: id, __typename: 'Team' }) })
          cache.gc()
        },
      })
      .toPromise()

    return result.data?.deleteTeam
  }

  /**
   * Update an existing team
   *
   * @param {string} id
   * @param {{ name: string; description: string; supervisorUserId: string; memberUserIds: string[] }} team
   * @return {*}  {Promise<Team>}
   * @memberof TeamsService
   */
  async updateTeam(
    id: string,
    team: { name: string; description: string; supervisorUserId: string; memberUserIds: string[] },
  ): Promise<Team> {
    debug('team-service', 'update team', team)
    let result = await this.apollo
      .mutate<{ updateTeam: Team }, MutationUpdateTeamArgs>({
        mutation: UpdateTeam,
        variables: {
          data: team,
          id: id,
        },
      })
      .toPromise()

    return result.data?.updateTeam
  }

  /**
   * Remove a user from a team
   *
   * @param {string} userId
   * @param {string} teamId
   * @return {*}  {Promise<boolean>}
   * @memberof TeamsService
   */
  async removeMemberFromTeam(userId: string, teamId: string): Promise<boolean> {
    debug('team-service', 'remove team member', userId, teamId)
    let result = await this.apollo
      .mutate<{ removeMemberFromTeam: boolean }, MutationRemoveMemberFromTeamArgs>({
        mutation: RemoveMemberFromTeam,
        variables: {
          teamId,
          userId,
        },
        update: (cache) => {
          let query: { team: Team } = cache.readQuery({
            query: TeamGet,
            variables: { id: teamId },
          })
          let existingTeam = JSON.parse(JSON.stringify(query))
          let newMembers = existingTeam.team.memberUserIds.filter((id) => id !== userId)
          existingTeam.team.memberUserIds = newMembers
          cache.writeQuery({
            query: TeamGet,
            variables: { id: teamId },
            data: { existingTeam },
          })
        },
      })
      .toPromise()

    return result.data?.removeMemberFromTeam
  }

  /**
   * Add a user to a team
   *
   * @param {string} userId
   * @param {string} teamId
   * @return {*}  {Promise<boolean>}
   * @memberof TeamsService
   */
  async addMemberToTeam(userId: string, teamId: string): Promise<boolean> {
    debug('team-service', 'add team member', userId, teamId)
    let result = await this.apollo
      .mutate<{ addMemberToTeam: boolean }, MutationAddMemberToTeamArgs>({
        mutation: AddMemberToTeam,
        variables: {
          teamId,
          userId,
        },
        update: (cache) => {
          let query: { team: Team } = cache.readQuery({
            query: TeamGet,
            variables: { id: teamId },
          })
          let existingTeam = JSON.parse(JSON.stringify(query))
          let newMembers = existingTeam.team.memberUserIds.push(userId)
          existingTeam.team.memberUserIds = newMembers
          cache.writeQuery({
            query: TeamGet,
            variables: { id: teamId },
            data: { existingTeam },
          })
        },
      })
      .toPromise()

    return result.data?.addMemberToTeam
  }

  /**
   * Helper to set a team's color for display
   *
   * @param {string} id
   * @return {*}  {string}
   * @memberof TeamsService
   */
  getTeamColor(id: string): string {
    return `teams-cp-${Math.ceil((parseInt(id.charAt(0), 16) + 1) / 2)}`
  }
}
