import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/**
 * Modal to display an mp4 video
 *
 * @export
 * @class VideoModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  @Input() url: string
  safeUrl: SafeUrl

  constructor(private sanitizer: DomSanitizer, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }
}
