<ng-container *ngIf="!task?.taskType?.isBotTask">
  <h5 class="mt-5 mb-3">Take Action</h5>
  <div class="d-flex flex-grow-1 justify-content-end">
    <button
      type="button"
      class="btn btn-block btn-danger font-weight-bold mr-3 mt-auto w-50"
      *ngIf="task?.completedUserId == null"
      data-t="task-reject-button"
      (click)="rejectTask()"
    >
      Reject
    </button>
    <button
      type="button"
      class="btn btn-block btn-success font-weight-bold ml-3 mt-auto w-50"
      *ngIf="task?.completedUserId == null"
      data-t="task-complete-button"
      (click)="completeTask()"
    >
      Complete
    </button>
    <button
      type="button"
      class="btn btn-block btn-success font-weight-bold mt-auto w-50"
      *ngIf="task?.completedUserId !== null && canReopen"
      data-t="task-uncomplete-button"
      (click)="uncompleteTask()"
    >
      Re-open Task
    </button>
  </div>
</ng-container>
