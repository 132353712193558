import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { debug } from 'app/shared/utils/debug'
import { TaskGroup } from 'generated/graphql'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'

/**
 * Component to search and suggest task groups in an input
 * Should be moved to admin/task-groups/components
 *
 * @export
 * @class TaskGroupTypeaheadComponent
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-task-group-typeahead',
  templateUrl: './task-group-typeahead.component.html',
  styleUrls: ['./task-group-typeahead.component.scss'],
})
export class TaskGroupTypeaheadComponent implements AfterViewInit {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() selectedTaskGroup: string = ''

  @Output() onSelect = new EventEmitter<TaskGroup>()
  @Output() onEdit = new EventEmitter<string>()

  searching: boolean = false

  constructor(private taskGroupsService: TaskGroupsService) {}

  ngAfterViewInit(): void {
    if (this.selectedTaskGroup?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedTaskGroup
      })
    }
  }

  /**
   * Get task groups based on search term in typeahead
   *
   * @return {*} {Observable<any[] | TaskGroup[]>}
   * @param {Observable<string>} text$
   * @memberof TaskGroupTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<TaskGroup[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((term) => {
        this.onEdit.emit(term)
        this.searching = true
      }),
      switchMap((term) =>
        from(this.taskGroupsService.searchTaskGroups(term, null, undefined, undefined, true)).pipe(
          catchError(() => {
            return of([])
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @return {*} {string}
   * @param {{ name: string }} x
   * @memberof TaskGroupTypeaheadComponent
   */
  inputFormatter = (x: { name: string }): string => x.name

  /**
   * Format typeahead results list
   *
   * @return {*} {string}
   * @param {{ name: string }} x
   * @memberof TaskGroupTypeaheadComponent
   */
  resultFormatter = (x: { name: string }): string => x.name

  /**
   * Notify consumers that a task group has been selected
   *
   * @return {*} {string}
   * @param {*} event
   * @memberof TaskGroupTypeaheadComponent
   */
  onSelectTaskGroup(event: { item: TaskGroup }): void {
    let taskGroup = event.item as TaskGroup
    debug('taskGroups', 'selected a taskGroup', taskGroup)
    this.onSelect.emit(taskGroup)
    this.element.nativeElement.value = taskGroup?.name
  }

  clearTaskGroup(event: Event): void {
    event.preventDefault()

    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
