import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface DateRange {
  startDate: string
  endDate: string
}

@Component({
  selector: 'vx-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
  host: {
    class: 'd-block',
  },
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() startDate: string
  @Input() endDate: string
  @Input() includeOpenEndedPresets = true

  @Output() dateChange = new EventEmitter<DateRange>()

  destroy$ = new Subject<void>()

  presets: ({ label: string } & DateRange)[] = []

  dateRangeFormGroup = new UntypedFormGroup({
    startDate: new UntypedFormControl(''),
    endDate: new UntypedFormControl(''),
  })

  get startDateControl(): UntypedFormControl {
    return this.dateRangeFormGroup.controls.startDate as UntypedFormControl
  }

  get endDateControl(): UntypedFormControl {
    return this.dateRangeFormGroup.controls.endDate as UntypedFormControl
  }

  constructor() {
    this.generatePresets()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousStartDateValue = changes.startDate?.previousValue
    const currentStartDateValue = changes.startDate?.currentValue
    const previousEndDateValue = changes.endDate?.previousValue
    const currentEndDateValue = changes.endDate?.currentValue

    if (previousStartDateValue !== currentStartDateValue) {
      this.setDates(currentStartDateValue, this.endDateControl.value)
    }

    if (previousEndDateValue !== currentEndDateValue) {
      this.setDates(this.startDateControl.value, currentEndDateValue)
    }
  }

  ngOnInit(): void {
    this.setDates(this.startDate, this.endDate)
    this.dateRangeFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((newValue) => {
      this.dateChange.emit(newValue)
    })
  }

  /**
   * Handles preset selection from a dropdown
   *
   * @param preset
   */
  onSelectPreset(preset: { label: string } & DateRange): void {
    this.dateRangeFormGroup.setValue({
      startDate: preset.startDate,
      endDate: preset.endDate,
    })
  }

  private setDates(startDate?: string, endDate?: string) {
    const defaultStartDate = startDate ?? this.dateToString(moment().subtract(1, 'week'))
    const defaultEndDate = endDate ?? this.dateToString(moment())

    this.dateRangeFormGroup.setValue({
      startDate: defaultStartDate,
      endDate: defaultEndDate,
    })
    this.generatePresets()
  }

  /**
   * Creates the different date presets for selection
   *
   * Note: This needs to update every time dates change
   */
  private generatePresets(): void {
    const todaysDate: moment.Moment = moment()
    this.presets = []
    this.presets.push(
      {
        label: 'Today',
        startDate: this.dateToString(todaysDate),
        endDate: this.dateToString(todaysDate),
      },
      {
        label: 'Yesterday',
        startDate: this.dateToString(todaysDate.clone().subtract(1, 'day')),
        endDate: this.dateToString(todaysDate.clone().subtract(1, 'day')),
      },
      {
        label: 'Last 7 Days',
        startDate: this.dateToString(todaysDate.clone().subtract(7, 'days')),
        endDate: this.dateToString(todaysDate),
      },
      {
        label: 'Month From End Date',
        startDate: this.dateToString(moment(this.endDateControl.value).subtract(1, 'month')),
        endDate: this.endDateControl.value,
      },
      {
        label: 'Previous Month',
        startDate: this.dateToString(todaysDate.clone().subtract(1, 'month')),
        endDate: this.dateToString(todaysDate),
      },
    )
    if (this.includeOpenEndedPresets) {
      this.presets.push(
        {
          label: 'All Dates Before',
          startDate: '',
          endDate: this.dateToString(moment(this.endDateControl.value)),
        },
        {
          label: 'All Dates After',
          startDate: this.dateToString(moment(this.startDateControl.value)),
          endDate: '',
        },
      )
    }
  }

  private dateToString(date: moment.Moment): string {
    if (!date.isValid()) {
      return ''
    }
    return date.format('YYYY-MM-DD')
  }
}
