<a
  class="card detail-card-new my-4 bg-light nostyle"
  [class.selected]="botRun?.id == selectedBotRunId"
  routerLink="/revbots/{{ botId }}/{{ botRun?.id }}"
  queryParamsHandling="preserve"
>
  <div class="p-2 row" #botRunCard>
    <div class="col-1">
      <i
        data-t="bot-run-status-icon"
        class="fa"
        [ngClass]="{
          'fa-times-circle': botRun?.status === 'FAILED' || botRun?.status === 'EXPIRED',
          'text-danger': botRun?.status === 'FAILED',
          'fa-spin fa-spinner text-brand-light-blue': botRun?.status === 'RUNNING',
          'fa-check-circle':
            botRun?.status === 'SUCCEEDED' ||
            botRun?.status === 'UNSTABLE' ||
            botRun?.status === 'CLAIM_NOT_FOUND' ||
            botRun?.status === 'MISSING_DATA',
          'text-success': botRun?.status === 'SUCCEEDED',
          'text-warning':
            botRun?.status === 'UNSTABLE' || botRun?.status === 'CLAIM_NOT_FOUND' || botRun?.status === 'MISSING_DATA',
          'fa-question-circle':
            botRun?.status !== 'SUCCEEDED' &&
            botRun?.status !== 'FAILED' &&
            botRun?.status !== 'RUNNING' &&
            botRun?.status !== 'UNSTABLE' &&
            botRun?.status !== 'CLAIM_NOT_FOUND' &&
            botRun?.status !== 'MISSING_DATA' &&
            botRun?.status !== 'EXPIRED',
          'text-secondary': !lateQueuedRun,
          'text-dark': lateQueuedRun
        }"
      ></i>
    </div>
    <div class="col-lg-7 col-md-7 col-sm-8">
      <strong class="row">{{
        botRun?.createdAt ? (botRun.createdAt | date: 'MM/dd/yyyy hh:mm a') : 'Bot run has no created date'
      }}</strong>
      <small *ngIf="botRun?.primaryResult" class="row" data-t="bot-run-primary-result">
        Primary Result: {{ botRun.primaryResult }}
      </small>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-3">Claim ID: {{ botRun?.providerClaimId }}</div>
  </div>
</a>
