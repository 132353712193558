import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { debug } from 'app/shared/utils/debug'
import { TaskType } from 'generated/graphql'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'

/**
 * Component to allow searching for and selecting a task type
 * Should be in admin/task-types/components
 *
 * @export
 * @class TaskTypeTypeaheadComponent
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-task-type-typeahead',
  templateUrl: './task-type-typeahead.component.html',
  styleUrls: ['./task-type-typeahead.component.scss'],
})
export class TaskTypeTypeaheadComponent implements OnChanges, AfterViewInit {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Input() selectedTaskType: string = ''

  @Input() required: boolean = false
  taskTypeForm = new UntypedFormControl(null)

  @Output() onSelect = new EventEmitter<TaskType>()
  @Output() onEdit = new EventEmitter<string>()

  searching: boolean = false

  constructor(private taskTypesService: TaskTypesService) {}

  ngOnChanges(): void {
    if (this.required) {
      this.taskTypeForm.setValidators([Validators.required])
    }
  }

  ngAfterViewInit(): void {
    if (this.selectedTaskType?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedTaskType
      })
    }
  }

  /**
   * Get task types based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof TaskTypeTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<TaskType[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((term) => {
        this.onEdit.emit(term)
        this.searching = true
      }),
      switchMap((term) =>
        from(this.taskTypesService.searchTaskTypes(term)).pipe(
          catchError(() => {
            return of([])
          }),
        ),
      ),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {{ name: string }} x
   * @memberof TaskTypeTypeaheadComponent
   */
  inputFormatter = (x: { name: string }): string => x.name

  /**
   * Format typeahead results list
   *
   * @param {{ name: string }} x
   * @memberof TaskTypeTypeaheadComponent
   */
  resultFormatter = (x: { name: string }): string => x.name

  /**
   * Notify consumers that a task type has been selected
   *
   * @param {*} event
   * @memberof TaskTypeTypeaheadComponent
   */
  onSelectTaskType(event: { item: TaskType }): void {
    let taskType = event.item as TaskType
    debug('taskTypes', 'selected a taskType', taskType)
    this.onSelect.emit(taskType)
    this.element.nativeElement.value = taskType?.name
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof TaskTypeTypeaheadComponent
   */
  clearTaskType(event: Event): void {
    event.preventDefault()

    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
