import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { SortEvent } from 'app/shared/directives/sortable.directive'
import { PayerPlanGroupService } from 'app/shared/services/payer-plan-group.service'
import { PayerPlanService } from 'app/shared/services/payer-plan.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { FilterOp, ListResponseMetaData, PayerPlan } from 'generated/graphql'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, filter, finalize, first, map, takeUntil, tap } from 'rxjs/operators'

type PlanMode = 'add' | 'remove'

@Component({
  selector: 'app-payer-plan-group-edit',
  templateUrl: './payer-plan-group-edit.page.html',
  styleUrls: ['./payer-plan-group-edit.page.scss'],
  host: { class: 'h-100' },
})
export class PayerPlanGroupEditPage implements OnInit, OnDestroy {
  routerSub$: Subscription
  destroy$ = new Subject<void>()

  payerNameSearch = new FormControl<string>('')
  planNameSearch = new FormControl<string>('')
  payerCodeSearch = new FormControl<string>('')

  planMode: PlanMode = 'remove'

  //groupMutation
  isEditingGroupName = false
  saveGroupNameModalOpen = false
  editedGroupNameValue: string

  // plan mutation
  removeList: string[] = []
  addList: string[] = []
  updateList: string[] = []
  username: string

  // plan table
  payerName = ''
  planName = ''
  payerCode = ''
  payerPlanGroupName: string
  payerPlanGroupId: string
  payerPlans: PayerPlan[]
  payerNameSortDirection = 1
  planNameSortDirection = 1
  payerCodeSortDirection = 0

  //pagination
  meta: ListResponseMetaData
  isLoading: boolean = false
  page: number = 1
  pageSize: number = 15

  constructor(
    private route: ActivatedRoute,
    private payerPlanService: PayerPlanService,
    private router: Router,
    private toast: ToastService,
    private modal: NgbModal,
    private payerPlanGroupService: PayerPlanGroupService,
    private authenticationService: AuthenticationService,
  ) {
    this.routerSub$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          // Get group name from params
          const group = this.route.snapshot.paramMap.get('payerPlanGroupName')
          this.payerPlanGroupName = group
          this.getGroupId()
          this.getPlans()
        }),
      )
      .subscribe()
  }

  ngOnInit(): void {
    this.username = this.authenticationService.getUser()?.name

    this.payerNameSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((term) => {
      this.payerName = term
      this.page = 1
      this.getPlans()
    })

    this.planNameSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((term) => {
      this.planName = term
      this.page = 1
      this.getPlans()
    })

    this.payerCodeSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((term) => {
      this.payerCode = term
      this.page = 1
      this.getPlans()
    })
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()

    this.destroy$.next()
    this.destroy$.complete()
  }

  startEditingGroupName(): void {
    this.editedGroupNameValue = this.payerPlanGroupName
    this.isEditingGroupName = true
  }

  saveGroupNameChanges(): void {
    // if modal is open, do nothing
    if (this.saveGroupNameModalOpen) {
      return
    }

    // don't bother opening modal if we didn't make a change
    if (this.editedGroupNameValue === this.payerPlanGroupName) {
      this.isEditingGroupName = false
      return
    }

    const bodyMessage = `Are you sure you want to save?\n\n
    You are about to change the name of group "${this.payerPlanGroupName}" to "${this.editedGroupNameValue}"`

    const modalRef = this.modal.open(ConfirmModalComponent)
    this.saveGroupNameModalOpen = true
    modalRef.componentInstance.title = `Confirm Group Name Update?`
    modalRef.componentInstance.body = bodyMessage
    modalRef.componentInstance.yes = 'Yes'
    modalRef.componentInstance.yesClass = 'btn-primary'

    modalRef.result
      .then(
        (result) => {
          //Handle Save
          this.updateGroupName(this.editedGroupNameValue)
        },
        () => {
          // Handle modal dismissal
        },
      )
      .finally(() => {
        this.isEditingGroupName = false
        this.saveGroupNameModalOpen = false
      })
  }

  updateGroupName(newGroupName: string): void {
    this.isLoading = true

    this.payerPlanGroupService
      .updateGroupName(this.payerPlanGroupId, newGroupName, this.username)
      .pipe(first())
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Updated Group Name`)
          this.payerPlanGroupName = newGroupName
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not update group name'), JSON.stringify(e))
        },
        () => {
          this.isLoading = false
        },
      )
  }

  deleteGroup(id: string): void {
    this.isLoading = true

    this.payerPlanGroupService
      .deleteGroup(id, this.username)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Deleted Group "${this.payerPlanGroupName}"`)
          this.router.navigate(['../'], { relativeTo: this.route })
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not delete group'), JSON.stringify(e))
        },
      )
  }

  changePage(event: PaginationUpdateEvent): void {
    this.page = event.newPageNumber
    this.getPlans()
  }

  setPlanMode(mode: PlanMode): void {
    if (this.planMode !== mode) {
      this.planMode = mode
      this.getPlans()
    }
  }

  onSort(event: SortEvent): void {
    let newDirection = 0
    if (event.direction === 'asc') {
      newDirection = 1
    } else if (event.direction === 'desc') {
      newDirection = -1
    }

    switch (event.column) {
      case 'payerName':
        this.payerNameSortDirection = newDirection
        break
      case 'planName':
        this.planNameSortDirection = newDirection
        break
      case 'payerCode':
        this.payerCodeSortDirection = newDirection
        break
    }

    this.getPlans()
  }

  isChecked(payerPlanId: string): boolean {
    if (this.planMode === 'add') {
      return this.addList.includes(payerPlanId) || this.updateList.includes(payerPlanId)
    }

    return this.removeList.includes(payerPlanId)
  }

  onCheckboxChange(event: Event, payerPlan: PayerPlan): void {
    const checked = (event.target as HTMLInputElement).checked

    //if payerPlan has a payerPlanGroupBridge
    const planMapExists = !!payerPlan.payerPlanGroupBridges.length

    if (checked) {
      // add payer plan from appropriate list
      if (this.planMode === 'add') {
        // if plan has a mapping, update, else we need to create a record
        planMapExists ? this.updateList.push(payerPlan.id) : this.addList.push(payerPlan.id)
      } else {
        this.removeList.push(payerPlan.id)
      }
    } else {
      // remove payer plan from appropriate list
      if (this.planMode === 'add') {
        if (planMapExists) {
          this.updateList = this.updateList.filter((e) => e !== payerPlan.id)
        } else {
          this.addList = this.addList.filter((e) => e !== payerPlan.id)
        }
      } else {
        this.removeList = this.removeList.filter((e) => e !== payerPlan.id)
      }
    }
  }

  addPlanMappings(): void {
    this.isLoading = true

    const plansToAdd = this.addList.map((pp) => {
      return {
        payerPlanGroupId: this.payerPlanGroupId,
        payerPlanId: pp,
      }
    })

    this.payerPlanService
      .bulkCreatePlanMappings(plansToAdd, this.username)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
          this.getPlans()
        }),
      )
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Created Plan Mappings`)
          this.addList = []
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not create plan mappings'), JSON.stringify(e))
        },
      )
  }

  updatePlanMappings(): void {
    this.isLoading = true

    const plansToUpdate = this.updateList.map((pp) => {
      return {
        payerPlanGroupId: this.payerPlanGroupId,
        payerPlanId: pp,
      }
    })

    this.payerPlanService
      .bulkUpdatePlanMappings(plansToUpdate, this.username)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
          this.getPlans()
        }),
      )
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Updated Plan Mappings`)
          this.updateList = []
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not update plan mappings'), JSON.stringify(e))
        },
      )
  }

  deletePlanMappings(): void {
    this.isLoading = true

    const plansToRemove = this.removeList.map((pp) => {
      return {
        payerPlanGroupId: this.payerPlanGroupId,
        payerPlanId: pp,
      }
    })

    this.payerPlanService
      .bulkDestroyPlanMappings(plansToRemove, this.username)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
          this.getPlans()
        }),
      )
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Removed Plan Mappings`)
          this.removeList = []
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not remove plan mappings'), JSON.stringify(e))
        },
      )
  }

  openConfirmSaveModal() {
    const bodyMessage = `Are you sure you want to save?\n\n
    You are about to remove ${this.removeList.length} ${this.removeList.length === 1 ? 'plan' : 'plans'} from ${
      this.payerPlanGroupName
    }
    and add ${this.addList.length}.\n\nAny existing plan mapping involving a plan being added will be overwritten.`

    const modalRef = this.modal.open(ConfirmModalComponent)
    modalRef.componentInstance.title = `Confirm Plan Updates for ${this.payerPlanGroupName}?`
    modalRef.componentInstance.body = bodyMessage
    modalRef.componentInstance.yes = 'Yes'
    modalRef.componentInstance.yesClass = 'btn-primary'

    modalRef.result.then(
      (result) => {
        //Handle Save
        if (this.addList.length) {
          this.addPlanMappings()
        }

        if (this.updateList.length) {
          this.updatePlanMappings()
        }

        if (this.removeList.length) {
          this.deletePlanMappings()
        }
      },
      () => {
        // Handle modal dismissal
      },
    )
  }

  openConfirmClearModal() {
    const modalRef = this.modal.open(ConfirmModalComponent)
    modalRef.componentInstance.title = `Confirm Clear Selected Plans`
    modalRef.componentInstance.body = 'Are you sure you want to clear all selected plans?'
    modalRef.componentInstance.yes = 'Yes'
    modalRef.componentInstance.yesClass = 'btn-primary'

    modalRef.result.then(
      () => {
        this.addList = []
        this.removeList = []
        this.updateList = []
        this.toast.success('Cleared Selected Plans')
      },
      () => {},
    )
  }

  openConfirmDeleteModal() {
    const modalRef = this.modal.open(ConfirmModalComponent)
    modalRef.componentInstance.title = `Confirm Delete Group`
    modalRef.componentInstance.body = `Are you sure you want to delete "${this.payerPlanGroupName}"?\n\n
    This will remove any mappings tied to the group. You will be unable to delete the group if a portal mapping uses the group.`
    modalRef.componentInstance.yes = 'Yes'
    modalRef.componentInstance.yesClass = 'btn-primary'

    modalRef.result.then(
      () => {
        this.deleteGroup(this.payerPlanGroupId)
      },
      () => {},
    )
  }

  async getGroupId(): Promise<void> {
    this.payerPlanGroupService
      .getGroupByName(this.payerPlanGroupName)
      .pipe(first())
      .subscribe(
        async (payerPlanGroup) => {
          this.payerPlanGroupId = payerPlanGroup?.id
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, `Could not find group "${this.payerPlanGroupName}"`), JSON.stringify(e))
        },
      )
  }

  async getPlans(): Promise<void> {
    this.isLoading = true
    const offset = (this.page - 1) * this.pageSize
    const payerNameValue = this.payerName ? `%${this.payerName}%` : undefined
    const planNameValue = this.planName ? `%${this.planName}%` : undefined
    const payerCodeValue = this.payerCode ? `%${this.payerCode}%` : undefined

    this.payerPlanService
      .getPlansByFilter(this.pageSize, offset, {
        groupName: {
          value: this.payerPlanGroupName,
          filterOp: this.planMode === 'remove' ? FilterOp.Like : FilterOp.Not,
        },
        payerName: { value: payerNameValue, filterOp: FilterOp.Ilike, sortDirection: this.payerNameSortDirection },
        planName: { value: planNameValue, filterOp: FilterOp.Ilike, sortDirection: this.planNameSortDirection },
        payerCode: { value: payerCodeValue, filterOp: FilterOp.Ilike, sortDirection: this.payerCodeSortDirection },
      })
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe(
        async (planList) => {
          this.meta = this.payerPlanService.getMeta()
          this.payerPlans = planList?.entities
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not find payer-plans'), JSON.stringify(e))
        },
      )
  }
}
