import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Organization } from 'generated/graphql'

@Component({
  selector: 'app-assign-org-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Assign Organization</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">&times;</button>
    </div>
    <div class="modal-body">
      <p>
        Assigning a user to an organization enables role access for their account. Assigning a role grants the user
        access to a specified part of the app.
      </p>
      <hr />
      <select id="select-assign-org" class="form-control" [(ngModel)]="selectedOrg">
        <option [ngValue]="null" disabled selected>Select organization</option>
        <option *ngFor="let org of organizations" [ngValue]="org">{{ org.name }}</option>
      </select>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="assign()" data-test-id="assign-org-btn">Assign</button>
      <button type="button" class="btn btn-secondary" (click)="dismiss()">Cancel</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
})
export class AssignOrgModalComponent {
  @Input() organizations: Organization[]
  @Output() organizationAssigned = new EventEmitter<Organization>()
  selectedOrg: Organization | null = null

  constructor(private modalService: NgbModal) {}

  assign(): void {
    if (this.selectedOrg) {
      this.organizationAssigned.emit(this.selectedOrg)
      this.dismiss()
    }
  }

  dismiss(): void {
    this.modalService.dismissAll()
  }
}
