import { Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Annotation, Rect } from 'app/shared/components/image-annotate/image-annotate.component'
import { ExtractionRule, VxDocument, VxDocumentType, VxPage, VxPageType } from 'generated/graphql'
import { compact, flatten, remove, uniqBy } from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { v4 as uuid } from 'uuid'

export interface ExtractedRow {
  [fieldKey: string]: {
    value: string
    ocrTextIds: string[]
  }
}

export interface VxFileFilters {
  todayOnly: boolean
  cash: boolean
  correspondence: boolean
  highConfidence: boolean
  mediumConfidence: boolean
  lowConfidence: boolean
  userCategorization: boolean
  automatedCategorization: boolean
  successfulExtract: boolean
  failedExtract: boolean
  needsVxDocumentGroupingStatus: boolean
  needsAdditionalReview: boolean
  needsCategorizationStatus: boolean
  needsExtractionRulesStatus: boolean
  needsExtractionStatus: boolean
  needsExtractionReviewStatus: boolean
  readyForAutomationStatus: boolean
  automationErrorsStatus: boolean
  completedExternallyStatus: boolean
  doneStatus: boolean
}

type FieldGroup = {
  id: string
  name: string
  annotations: Annotation[]
  repeating: boolean
  nestedFieldGroups: FieldGroup[]
  extractionRules?: ExtractionRule // this is always from the API, we do not populate this on the FE
  needsReview?: boolean
}

export type VxTabs = 'documents' | 'extraction' | 'automation' | 'errors'

/**
 * Service ( singleton ) to hold common VX state
 *
 * @export
 * @class VxStateService
 */
@Injectable({
  providedIn: 'root',
})
export class VisionXStateService {
  // Page Type list
  vxPageType = new BehaviorSubject<VxPageType>(null) // selected Page Type
  uncategorizedVxPageType: VxPageType = {
    id: 'uncategorized',
    name: 'Uncategorized',
    description: 'Uncategorized',
    source: 'visionX',
    confidenceThreshold: 0,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    deletedAt: null,
    enforceReview: true,
    rulesFinishedFlag: true,
  }
  fileFilters = new BehaviorSubject<VxFileFilters>({
    todayOnly: false,
    cash: false,
    correspondence: false,
    highConfidence: false,
    mediumConfidence: false,
    lowConfidence: false,
    userCategorization: false,
    automatedCategorization: false,
    successfulExtract: false,
    failedExtract: false,
    needsVxDocumentGroupingStatus: false,
    needsAdditionalReview: false,
    needsCategorizationStatus: false,
    needsExtractionRulesStatus: false,
    needsExtractionStatus: false,
    needsExtractionReviewStatus: false,
    readyForAutomationStatus: false,
    automationErrorsStatus: false,
    completedExternallyStatus: false,
    doneStatus: false,
  })
  fileSearch = new BehaviorSubject<string>('')

  // Tabs
  selectedTab = new BehaviorSubject<VxTabs>('documents')

  // Pages / Docs
  vxPages = new BehaviorSubject<VxPage[]>([])
  vxPagesInPageType = new BehaviorSubject<VxPage[]>([])
  vxDocuments = new BehaviorSubject<VxDocument[]>([])
  vxDocumentTypes = new BehaviorSubject<VxDocumentType[]>([])

  // selected doc / page
  vxDocument = new BehaviorSubject<VxDocument>(null)
  vxPage = new BehaviorSubject<VxPage>(null)

  fieldGroups = new BehaviorSubject<FieldGroup[]>([])
  selectedFieldGroupIds = new BehaviorSubject<string[]>([])

  ungroupedAnnotations = new BehaviorSubject<Annotation[]>([])

  categorizingPageId$ = new BehaviorSubject<string>('')
  searchAllVxPageTypes = new UntypedFormControl(false)

  isLeftSidebarCollapsed$ = new BehaviorSubject<boolean>(false)

  isDisplayingAllVxPages$ = new BehaviorSubject<boolean>(false)

  constructor() {}

  /**
   * Used in VxDocument view to store references to vxPages so we can find vxPages for each document
   *
   * @param {VxPage[]} vxPages
   * @memberof VxStateService
   */
  addVxPagesToList(vxPages: VxPage[]): void {
    const MAX_PAGES_IN_MEMORY = 1000
    const uniqueVxPages = uniqBy(vxPages.concat(this.vxPages.value), (vxPage) => vxPage.id)

    const newVxPages = uniqueVxPages.slice(0, MAX_PAGES_IN_MEMORY)
    const newVxPagesInPageType = uniqueVxPages
      .filter((vxPage) => {
        const pageTypeId = vxPage.vxPageTypeId || this.uncategorizedVxPageType.id
        return pageTypeId === this.vxPageType.value.id
      })
      .slice(0, MAX_PAGES_IN_MEMORY)
    this.vxPages.next(newVxPages)
    this.vxPagesInPageType.next(newVxPagesInPageType)
  }

  fileFiltersToQueryString(): string {
    let filters = this.fileFilters.value
    return Object.keys(filters)
      .filter((filter) => filters[filter])
      .join(',')
  }

  updateFieldGroups(fieldGroup: FieldGroup): void {
    let fgGroups = this.fieldGroups.value
    fgGroups[fgGroups.findIndex((fg) => fg.id === fieldGroup.id)] = fieldGroup
    this.fieldGroups.next([...fgGroups])
  }

  setPreviousAndNextVxPageIds(): { previousId: string; nextId: string } {
    const indexOfCurrentPage = this.vxPages.value?.findIndex((vxPage) => vxPage.id === this.vxPage.value?.id)
    return {
      previousId: this.vxPages.value[indexOfCurrentPage - 1]?.id,
      nextId: this.vxPages.value[indexOfCurrentPage + 1]?.id,
    }
  }

  findMatchingFieldGroup(fieldGroupIds: string[]): FieldGroup {
    let idIndex = 0
    let fieldGroup: FieldGroup
    while (idIndex <= fieldGroupIds.length - 1) {
      let fieldGroups = fieldGroup ? fieldGroup.nestedFieldGroups : this.fieldGroups.value
      fieldGroup = fieldGroups.find((fg) => fg.id === fieldGroupIds[idIndex])
      idIndex += 1
    }
    return fieldGroup
  }

  findMatchingAnnotation(annotationId: string, fieldGroupIds: string[]): Annotation {
    const fieldGroup = this.findMatchingFieldGroup(fieldGroupIds)
    return fieldGroup.annotations.find((annotation) => annotation.id === annotationId)
  }

  createFieldGroupFromExtractedRow(
    extractedRow: ExtractedRow,
    rowTitle: string,
    extractionRule: ExtractionRule,
    id?: string,
    needsReview?: boolean,
  ): FieldGroup {
    let ungroupedAnnotations = this.ungroupedAnnotations.value
    return {
      id: id || uuid(),
      name: rowTitle,
      repeating: false,
      extractionRules: extractionRule,
      needsReview,
      annotations: extractedRow
        ? this.fuzzySortAnnotations(
            compact(
              Object.keys(extractedRow).map(
                (fieldKey): Annotation => {
                  const fieldValue = extractedRow[fieldKey]
                  let required: boolean = false
                  if (extractionRule) {
                    const matchingFieldExtractionRule = extractionRule.fields.find((fieldExtractionRule) =>
                      fieldExtractionRule.dataKeys.includes(fieldKey),
                    )
                    required = matchingFieldExtractionRule ? matchingFieldExtractionRule.required : false
                  }

                  if (Array.isArray(fieldValue)) {
                    return null
                  } else if (fieldValue.ocrTextIds.length > 0) {
                    const matchingOcrTexts = remove(ungroupedAnnotations, (a) =>
                      a.id?.split('_')?.some((id) => fieldValue.ocrTextIds.includes(id)),
                    )
                    if (matchingOcrTexts.length > 0) {
                      this.ungroupedAnnotations.next(ungroupedAnnotations)
                      let confidence: number
                      if (matchingOcrTexts.some((ocrText) => ocrText.required && !ocrText.value)) {
                        confidence = 0
                      } else {
                        confidence = Math.min(...matchingOcrTexts.map((ocrText) => ocrText.confidence))
                      }

                      return {
                        id: matchingOcrTexts.map((ocrText) => ocrText.id).join('_'),
                        key: fieldKey,
                        ocrTextIds: fieldValue.ocrTextIds,
                        rect: this.combineAnnotationDimensions(matchingOcrTexts.map((ocrText) => ocrText.rect)),
                        confidence,
                        value: this.fuzzySortAnnotations(matchingOcrTexts)
                          .map((ocrText) => ocrText.value)
                          .join(' '),
                        highlighted: false,
                        required,
                        anchor: false,
                      }
                    } else {
                      return {
                        id: fieldValue.ocrTextIds.join('_'),
                        key: fieldKey,
                        ocrTextIds: fieldValue.ocrTextIds,
                        rect: {
                          x: 0,
                          y: 0,
                          w: 0,
                          h: 0,
                        },
                        confidence: 1,
                        value: fieldValue.value,
                        highlighted: false,
                        required,
                        anchor: false,
                      }
                    }
                  } else {
                    return {
                      id: uuid(),
                      ocrTextIds: fieldValue.ocrTextIds,
                      rect: {
                        w: 0,
                        h: 0,
                        x: 0,
                        y: 0,
                      },
                      highlighted: false,
                      key: fieldKey,
                      value: fieldValue.value,
                      required,
                      anchor: false,
                    }
                  }
                },
              ),
            ),
          )
        : extractionRule.fields.map(
            (field): Annotation => {
              return {
                id: uuid(),
                ocrTextIds: [],
                rect: {
                  w: 0,
                  h: 0,
                  x: 0,
                  y: 0,
                },
                highlighted: false,
                key: field.dataKeys[0],
                value: '',
                required: false,
                anchor: false,
              }
            },
          ),

      nestedFieldGroups:
        extractedRow && extractionRule?.childGroups?.[0]
          ? flatten(
              compact(
                Object.keys(extractedRow).map((fieldKey): FieldGroup[] => {
                  const fieldValue = extractedRow[fieldKey]
                  if (Array.isArray(fieldValue)) {
                    return fieldValue.map((row, rowIdx) => {
                      return this.createFieldGroupFromExtractedRow(
                        row,
                        `${fieldKey} dataset ${rowIdx + 1}`,
                        extractionRule.childGroups[0],
                      )
                    })
                  }
                }),
              ),
            )
          : [],
    }
  }
  private combineAnnotationDimensions(rects: Rect[]): Rect {
    const minX = Math.min(...rects.map((rect) => rect.x))
    const minY = Math.min(...rects.map((rect) => rect.y))
    return {
      x: minX,
      y: minY,
      w: Math.max(...rects.map((rect) => rect.x + rect.w)) - minX,
      h: Math.max(...rects.map((rect) => rect.y + rect.h)) - minY,
    }
  }

  private fuzzySortAnnotations(annotations: Annotation[], fuzziness: number = 10): Annotation[] {
    return annotations.sort((a, b) => {
      if (Math.abs(a.rect.y - b.rect.y) > fuzziness) {
        return a.rect.y - b.rect.y
      } else {
        return a.rect.x - b.rect.x
      }
    })
  }
}
