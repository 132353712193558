<ng-container *ngIf="dashboardMode; else loading">
  <div [ngSwitch]="dashboardMode" class="h-100">
    <ng-container *ngSwitchCase="'tableau'">
      <app-tableau-dashboard></app-tableau-dashboard>
    </ng-container>
    <ng-container *ngSwitchCase="'quicksight'">
      <app-quicksight-dashboard [dashboardType]="quicksightDashboardType"></app-quicksight-dashboard>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-center">
          <h1 class="text-primary">No Dashboard Found</h1>
          <p class="text-muted">You have permission to view the dashboard page, but your dashboard type isn't set!</p>
          <p class="text-muted">
            For assistance, please submit a ticket at
            <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #loading></ng-template>
