const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@!#$%^&()_+= '

function rand(max) {
  return Math.floor(Math.random() * max)
}

function random(set) {
  return set[rand(set.length - 1)]
}

function generate(length, set) {
  let result = []
  while (length--) {
    result.push(random(set))
  }
  return result
}

export function generateRandomPassword(length) {
  let randPass = generate(length, ALPHABET)
  return randPass.join('')
}
