import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { togglePassType } from 'app/shared/utils/togglePassType'

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef
  @Input() loading = false 
  @Input() showForgot = true
  @Input() validateOnServer = false
  @Input() hasConfirm = false  
  @Input() buttonText = 'Log In'  

  private _isDisabled = false
  @Input() 
  set disable(val: boolean) {
    if (val) {
      this.password.disable()
      this.confirm.disable()
    } else {
      this.password.enable()
      this.confirm.enable()
    }
    this._isDisabled = val
  }
  get disable() {
    return this._isDisabled
  }


  @Output() onSubmit = new EventEmitter<string>()
  password = new UntypedFormControl(null, Validators.required)
  confirm = new UntypedFormControl(null, Validators.required)

  passType: 'password' | 'text' = 'password'

  constructor(private toast: ToastService) {}

  ngAfterViewInit(): void {
    this.input.nativeElement.focus()
  }

  /**
   * Alert user if password field is invalid, or send it
   *
   * @memberof PasswordComponent
   */
  submit(): void {
    this.password.markAllAsTouched()
    if (this.password.invalid) {
      if (this.validateOnServer) {
        this.toast.error('Password is not valid.')
      } else {
        this.toast.error('Please fill in required field.', JSON.stringify(this.password.errors))
      }
      
      return
    }
    if (this.hasConfirm) {
      if (this.confirm.value !== this.password.value) {
        this.toast.error('Passwords do not match.')
        return
      }
    }
    this.onSubmit.emit(this.password.value)
  }

  /**
   * Helper to toggle password visibility
   *
   * @memberof LoginPage
   */
  togglePassType(): void {
    this.passType = togglePassType(this.passType)
  }
}
