<div
  class="container d-flex flex-column min-vh-100"
  data-testid="docs-upload-container"
  *ngIf="!isLoading; else loadingBlock"
>
  <div class="row d-flex justify-content-center my-5">
    <div class="col-md-4 col-8 d-flex justify-content-center">
      <div class="px-4" id="janus-logo">
        <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" height="50px" />
      </div>
      <div class="px-4 text-white d-inline d-flex align-items-center" style="white-space: nowrap; font-size: 25px">
        Clinical Documentation
      </div>
    </div>
  </div>
  <div class="row text-alt-light-gray">
    <div class="col-8 offset-2 py-3">
      <p>
        Submit clinical documents for the purpose of case adjudication when the case is in a pending status. Please
        verify your case and proceed with attaching files to your request
      </p>
    </div>
  </div>
  <div class="d-flex flex-column mx-n2">
    <app-procedure-set-details class="container" [procedureSet]="procedureSet"></app-procedure-set-details>
    <app-document-upload></app-document-upload>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
<ng-template #loadingBlock>
  <div class="min-vh-100 d-flex align-items-center justify-content-center">
    <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue" data-testid="loading-spinner"></app-loading>
  </div>
</ng-template>
