<h1 class="m-3">Audit Trail</h1>
<div class="bg-alt-light-gray">
  <div class="row my-2">
    <div class="col-sm-auto ml-4 float-left">Last modified: {{lastModifiedAclDate | date: 'MM/dd/yyyy hh:mm a'}}</div>
    <div class="col-sm-auto ml-auto mx-3 pr-4">
      <div
        *ngIf="pagingMetadata?.limit < pagingMetadata?.total && !loading"
        class="d-flex justify-content-end align-items-center"
      >
        <app-pagination [metaData]="pagingMetadata" (fetchPage)="onPageUpdate($event)"></app-pagination>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column flex-grow-1 mx-3">
    <div *ngIf="loading; else auditRecordResults">
      <app-loading
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-between"
      ></app-loading>
    </div>
    <ng-template #auditRecordResults>
      <div *ngIf="!observeAuditTrailRecords.length">
        <h3 class="text-center pb-2">No results found</h3>
      </div>
      <div *ngIf="observeAuditTrailRecords.length">
        <div
          class="table-responsive-md bg-alt-white d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto"
        >
          <table class="table table-hover overflow-auto" data-t="audit-records-table">
            <thead>
              <tr>
                <th scope="col" sortable="userId"
                (sort)="onSort($event)">Username</th>
                <th scope="col" sortable="createdAt"
                (sort)="onSort($event)">Date & Time</th>
                <th scope="col" sortable="summary"
                (sort)="onSort($event)">Summary</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let observeAuditTrailRecord of observeAuditTrailRecords">
                <td>{{observeAuditTrailRecord.userId}}</td>
                <td>{{observeAuditTrailRecord.createdAt | date: 'MM/dd/yyyy hh:mm a'}}</td>
                <td>{{observeAuditTrailRecord.summary}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="row justify-content-end no-gutters m-3">
    <div class="col-sm-auto">
      <div *ngIf="pagingMetadata?.limit < pagingMetadata?.total && !loading" class="d-flex justify-content-end">
        <app-pagination [metaData]="pagingMetadata" (fetchPage)="onPageUpdate($event)"></app-pagination>
      </div>
    </div>
  </div>
</div>
