<div class="form-group input-group" [formGroup]="parentForm">
  <input
    [type]="passType"
    class="form-control shadow-sm"
    formControlName="password"
    id="password"
    [appFcBsValidation]="password"
  />
  <div class="input-group-append">
    <span class="in-input-icon d-flex align-items-center">
      <button type="button" class="btn btn-link bg-transparent" (click)="togglePassType()">
        <i
          class="fa fw fa-lg text-reset"
          [class.fa-eye]="passType === 'password'"
          [class.fa-eye-slash]="passType === 'text'"
        ></i>
      </button>
    </span>
  </div>
</div>
