import { Component, Input, OnChanges } from '@angular/core'
import { getPropDetails } from 'app/shared/types/claim'
import { Claim, ServicelineCode } from 'generated/graphql'
import { flatten, uniqBy } from 'lodash'

/**
 * Component to display a claim's status information
 * combining both servicelines statuses and claim
 * level statuses
 *
 * @export
 * @class ClaimStatusComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.scss'],
})
export class ClaimStatusComponent implements OnChanges {
  @Input() claim: Claim = null

  getPropDetails = getPropDetails

  servicelineEHR: ServicelineCode[] = []
  servicelinePayer: ServicelineCode[] = []
  servicelineClaim: ServicelineCode[] = []
  servicelineCat: ServicelineCode[] = []
  servicelineCAR: ServicelineCode[] = []
  servicelineRAR: ServicelineCode[] = []

  constructor() {}

  /**
   * Combine a claim's serviceline statuses with
   * the claim-level statuses for display
   *
   * @memberof ClaimStatusComponent
   */
  ngOnChanges(): void {
    if (this.claim) {
      this.servicelineEHR = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.ehrStatusCodes))?.concat({
          code: this.claim?.ehrStatusCode,
          description: this.claim?.ehrStatusText,
        }),
        'code',
      ).filter((val) => val?.code)
      this.servicelinePayer = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.payerStatusCodes))?.concat({
          code: this.claim?.payerStatusCode,
          description: this.claim?.payerStatusCodeText,
        }),
        'code',
      ).filter((val) => val?.code)
      this.servicelineClaim = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.claimStatusCodes)),
        'code',
      ).filter((val) => val?.code)
      this.servicelineCat = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.categoryCodes))?.concat({
          code: this.claim?.categoryCode,
          description: this.claim?.categoryCodeDescription,
        }),
        'code',
      ).filter((val) => val?.code)
      this.servicelineCAR = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.claimAdjustmentReasonCodes)),
        'code',
      ).filter((val) => val?.code)
      this.servicelineRAR = uniqBy(
        flatten(this.claim?.servicelines?.map((serviceLine) => serviceLine?.remitAdviceRemarkCodes))?.concat({
          code: this.claim?.remitCode,
          description: this.claim?.remitCodeDescription,
        }),
        'code',
      ).filter((val) => val?.code)
    }
  }
}
