import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { User } from 'generated/graphql'

/**
 * Component to display / edit users assigned to a task group
 *
 * @export
 * @class TaskGroupAssignAvatarComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-task-group-assign-avatar',
  templateUrl: './task-group-assign-avatar.component.html',
  host: {
    '[class.d-flex]': 'true',
  },
})
export class TaskGroupAssignAvatarComponent implements OnInit, OnChanges {
  @Input() users: User[] = null
  @Output() editUsers = new EventEmitter<{ userId: string; remove: boolean }>()

  canEdit = false
  isHoveredOver: boolean[]
  userIds: string[]

  // Modal
  @ViewChild('content', { static: true }) content: ElementRef

  constructor(private authenticationService: AuthenticationService, private modal: NgbModal) {}

  ngOnInit(): void {
    this.canEdit = this.authenticationService.canAccessManagerViews()
  }

  ngOnChanges(): void {
    if (this.users) {
      this.userIds = this.users?.length ? this.users.map((user) => user.id) : []
      this.isHoveredOver = new Array(this.userIds.length).fill(false)
    }
  }

  /**
   * Remove a user assigned to this task group
   *
   * @param {*} $event
   * @param {User} user
   * @return {*}  {boolean}
   * @memberof TaskGroupAssignAvatarComponent
   */
  remove($event: Event, user: User): boolean {
    $event?.stopPropagation()
    this.editUsers.emit({ userId: user?.id, remove: true })
    return false
  }

  /**
   * Launch modal to select a user to assign to this task group
   *
   * @param {*} $event
   * @return {*}  {boolean}
   * @memberof TaskGroupAssignAvatarComponent
   */
  add($event: Event): boolean {
    $event?.stopPropagation()
    this.modal.open(this.content, {
      centered: true,
    })

    return false
  }

  /**
   * Assign selected user to this task group
   *
   * @param {*} $event
   * @memberof TaskGroupAssignAvatarComponent
   */
  addUser($event: { id: string }): void {
    this.editUsers.emit({ userId: $event?.id, remove: false })
    this.modal.dismissAll()
  }
}
