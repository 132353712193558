import { VxDocumentType } from 'generated/graphql'

/**
 * Default values from VxDocumentTypes in modals
 */
export const allVxDocumentTypesOption: VxDocumentType = {
  id: null,
  name: 'All Types',
  dsThreshold: 0,
  needsExtraction: 'always',
}
