import { Component, EventEmitter, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ToastService } from 'app/shared/services/toast.service'
import { Payer } from 'generated/graphql'

@Component({
  selector: 'app-payer-picker',
  templateUrl: './payer-picker.component.html',
})
export class PayerPickerComponent {
  isAddingPayer: boolean = false
  newPayerName = new UntypedFormControl(null)
  @Output() pickedPayer = new EventEmitter<Payer>()
  @Output() newPayer = new EventEmitter<string>()

  constructor(private toast: ToastService) {}

  onPayerPicked(payer: Payer): void {
    if (payer?.id) {
      this.pickedPayer.emit(payer)
    } else {
      this.isAddingPayer = true
    }
  }

  addNewPayer(): void {
    if (this.newPayerName.value.trim()?.length) {
      this.newPayer.emit(this.newPayerName.value)
      this.isAddingPayer = false
    } else {
      this.toast.error('Please enter in value before attempting to suggest new payer', 'new payer empty')
    }
  }
}
