import { Component, Input, OnChanges } from '@angular/core'

/**
 * Component to display improvement in an opportunity over baseline
 *
 * @export
 * @class ProfitImprovementComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-profit-improvement',
  templateUrl: './profit-improvement.component.html',
  styleUrls: ['./profit-improvement.component.scss'],
})
export class ProfitImprovementComponent implements OnChanges {
  @Input() baseLine: number
  @Input() compareValue: number
  @Input() color: string = null

  improvement: number
  showImprovement: boolean = true

  constructor() {}

  ngOnChanges(): void {
    if (!this.compareValue || !this.baseLine) {
      this.showImprovement = false
    } else {
      this.improvement = ((this.compareValue - this.baseLine) * 12) / 100
      if (this.improvement === 0) {
        this.showImprovement = false
      }
    }
  }
}
