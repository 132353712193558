import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { RouterModule } from '@angular/router'
import { DataSource, GetDataParams, JuiButtonComponent, JuiDataTableViewComponent, JuiTemplateNameDirective, Row, TableConfig } from '@janushealthinc/janus-ui-kit'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { FeatureFlagNames } from 'generated/graphql'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * FeatureFlagDataSource class implements the DataSource interface for fetching, searching,
 * sorting, and paginating feature flag data using the FeatureFlagService.
 * This class provides the necessary methods to integrate feature flag data with data tables.
 *
 * @template FeatureFlagNames - Represents the structure of the feature flag data rows.
 */
class FeatureFlagDataSource<FeatureFlagNames> implements DataSource<FeatureFlagNames> {
  constructor(private featureFlagService: FeatureFlagService) {}

  getData(params: GetDataParams<FeatureFlagNames>): Observable<Row<FeatureFlagNames>[]> {
    const { page, pageSize, search } = params
    const offset = page * pageSize
    return this.featureFlagService.getFeatureFlagNames({ offset, limit: pageSize, search }).pipe(
      map((response) => {
        return response.data.featureFlagNames.entities as Row<FeatureFlagNames>[]
      })
    )
  }

  getTotal(): Promise<number> {
    return Promise.resolve(this.featureFlagService.getMeta()?.total ?? 0)
  }
}

@Component({
  selector: 'app-feature-flag-names-list',
  templateUrl: './feature-flag-names-list.page.html',
  styleUrls: ['./feature-flag-names-list.page.scss'],
  host: { class: 'h-100' },
  imports: [
    JuiButtonComponent,
    JuiDataTableViewComponent,
    JuiTemplateNameDirective,
    CommonModule,
    RouterModule,
  ],
  standalone: true,
})
export class FeatureFlagNamesListPage {
  pageSize: number = 25

  dataSource: FeatureFlagDataSource<FeatureFlagNames>
  tableConfig: TableConfig<FeatureFlagNames> = {
    columns: [
      {
        field: 'name',
        header: 'Feature',
        templateName: 'feature-flag-name'
      }
    ]
  }

  constructor(featureFlagService: FeatureFlagService) {
    this.dataSource = new FeatureFlagDataSource(featureFlagService)
  }
}
