import { Injectable } from '@angular/core'
import { AnalyticsService } from '../services/analytics.service'
import { AnalyticsBaseInterceptor } from './_analytics-base.interceptor'
import { AnalyticsInterceptorType } from '../enums/analytics-interceptor-type.enum'
import { HttpRequest } from '@angular/common/http'

@Injectable()
export class AnalyticsGqlInterceptor extends AnalyticsBaseInterceptor {
  constructor(analytics: AnalyticsService) {
    super(analytics, AnalyticsInterceptorType.GQL, ['/graphql'])
  }
  public override action(req: HttpRequest<any>): void {
    this.analytics.onGqlRequest(req);
  }
}
