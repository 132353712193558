export type CostComparison = {
  currentPhase: number // cents
  baseLine: number // cents
  phaseOne: number // cents
  phaseTwo: number // cents
  fullAutomation: number // cents
}

export type Opportunity = {
  // Opportunities List
  id: string
  processName: string
  description: string
  processVolume: number // also called count
  processTime: number // minutes
  fteCost: number // cents
  esimatedprofitOpportunity: number // cents
  completedPhaseSteps: number // cents
  monthlyWorkflowYield: CostComparison
  monthlyCostToCollect: CostComparison
  monthlyNetProfitabilityOfCollection: CostComparison
  averagePaymentLag: CostComparison // but with days
  riskLevel: 'low' | 'medium' | 'high' | 'null'
  complexityLevel: 'low' | 'medium' | 'high' | 'null'
  favorite: boolean

  // Process Map
  totalOutstanding?: number // cents, also called volume
  averageOutstanding?: number // cents
  averagePayment?: number // cents
  averageOutcome?: number //cents
  paymentFrequency?: number // percentage
  averageCompletionTime?: number // minutes
  meanCollectionCost?: number // cents
  netCollectionProfit?: number // cents
  workflowVariance?: number // no clue
}

export const opportunities: Opportunity[] = [
  {
    id: '111',
    processName: 'ADR Requests from NGS Medicare - Surgical CPTs',
    description: 'Workflow to process and fulfill ADR requests for procedure-range CPTs for NGS Medicare.',
    processVolume: 2281,
    processTime: 791 * 60,
    fteCost: 3161466,
    esimatedprofitOpportunity: 6359902,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 200023171,
      baseLine: 200023171,
      phaseOne: 220287575,
      phaseTwo: 221895680,
      fullAutomation: 240691120,
    },
    monthlyCostToCollect: {
      currentPhase: 3161466,
      baseLine: 3161466,
      phaseOne: 1763213,
      phaseTwo: 609027,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 200021785,
      baseLine: 200021785,
      phaseOne: 220286802,
      phaseTwo: 221895413,
      fullAutomation: 240691120,
    },
    averagePaymentLag: {
      currentPhase: 33,
      baseLine: 33,
      phaseOne: 28,
      phaseTwo: 27,
      fullAutomation: 23,
    },
    riskLevel: 'low',
    complexityLevel: 'medium',
    favorite: false,
    totalOutstanding: 255513058,
    averageOutstanding: 112018,
    averagePayment: 95733,
    averageOutcome: 87691,
    paymentFrequency: 91.6,
    averageCompletionTime: 1249,
    meanCollectionCost: 1386,
    netCollectionProfit: 86305,
    workflowVariance: 1.6,
  },
  {
    id: '222',
    processName: '	Modifier Review - E&M - Blue Cross	',
    description: '	Review for appropriate modifer usage with both Procedure and E&M charged	',
    processVolume: 7804,
    processTime: 678 * 60,
    fteCost: 4327680,
    esimatedprofitOpportunity: 6264110,
    completedPhaseSteps: 4,
    monthlyWorkflowYield: {
      currentPhase: 163189400,
      baseLine: 136585608,
      phaseOne: 163189444,
      phaseTwo: 164508320,
      fullAutomation: 165273112,
    },
    monthlyCostToCollect: {
      currentPhase: 1404700,
      baseLine: 2601333,
      phaseOne: 1404720,
      phaseTwo: 874048,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 161784700,
      baseLine: 133984275,
      phaseOne: 161784724,
      phaseTwo: 163634272,
      fullAutomation: 165273112,
    },
    averagePaymentLag: {
      currentPhase: 28,
      baseLine: 29,
      phaseOne: 28,
      phaseTwo: 27,
      fullAutomation: 26,
    },
    riskLevel: 'medium',
    complexityLevel: 'medium',
    favorite: true,
    totalOutstanding: 170283280,
    averageOutstanding: 21820,
    averagePayment: 20540,
    averageOutcome: 17502,
    paymentFrequency: 85,
    averageCompletionTime: 245,
    meanCollectionCost: 333,
    netCollectionProfit: 17169,
    workflowVariance: 2.2,
  },
  {
    id: '333',
    processName: '	Modifier Review - E&M - Medicare	',
    description: '	Review for appropriate modifer usage with both Procedure and E&M charged	',
    processVolume: 6549,
    processTime: 504 * 60,
    fteCost: 1763051,
    esimatedprofitOpportunity: 3454876,
    completedPhaseSteps: 5,
    monthlyWorkflowYield: {
      currentPhase: 95862300,
      baseLine: 80231799,
      phaseOne: 92930310,
      phaseTwo: 95864262,
      fullAutomation: 97088925,
    },
    monthlyCostToCollect: {
      currentPhase: 1178820,
      baseLine: 2183000,
      phaseOne: 1178820,
      phaseTwo: 733488,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 94683500,
      baseLine: 78048799,
      phaseOne: 91751490,
      phaseTwo: 95130774,
      fullAutomation: 97088925,
    },
    averagePaymentLag: {
      currentPhase: 28,
      baseLine: 30,
      phaseOne: 29,
      phaseTwo: 28,
      fullAutomation: 26,
    },
    riskLevel: 'high',
    complexityLevel: 'medium',
    favorite: true,
    totalOutstanding: null,
    averageOutstanding: null,
    averagePayment: null,
    paymentFrequency: null,
    averageCompletionTime: null,
    workflowVariance: null,
  },
  {
    id: '444',
    processName: 'Medical Necessity - File Appeal - Cardiology - Aetna	',
    description: 'Cardiology procedures denied for medical necessity 	',
    processVolume: 125,
    processTime: 556 * 60,
    fteCost: 1529763,
    esimatedprofitOpportunity: 2657051,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 676390000,
      baseLine: 676390000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 687501500,
    },
    monthlyCostToCollect: {
      currentPhase: 183400,
      baseLine: 183400,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 676206600,
      baseLine: 676206600,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 687501500,
    },
    averagePaymentLag: {
      currentPhase: 34,
      baseLine: 34,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 31,
    },
    riskLevel: 'null',
    complexityLevel: 'low',
    favorite: false,
  },
  {
    id: '555',
    processName: 'Medical Necessity - File Appeal - BCBS	',
    description: 'Radiology procedures denied for medical necessity 	',
    processVolume: 1280,
    processTime: 1249 * 60,
    fteCost: 3747750,
    esimatedprofitOpportunity: 2413054,
    completedPhaseSteps: 8,
    monthlyWorkflowYield: {
      currentPhase: 91238400,
      baseLine: 77454100,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 94990100,
    },
    monthlyCostToCollect: {
      currentPhase: 1433600,
      baseLine: 1536000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 89804800,
      baseLine: 75918100,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 94990100,
    },
    averagePaymentLag: {
      currentPhase: 41,
      baseLine: 46,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 38,
    },
    riskLevel: 'low',
    complexityLevel: 'medium',
    favorite: true,
  },
  {
    id: '666',
    processName: '	Modifier Review - Bundling - Humana	',
    description: '	Review modifier usage for procedures that are bundled	',
    processVolume: 2003,
    processTime: 304 * 60,
    fteCost: 683494,
    esimatedprofitOpportunity: 1936467,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 109387800,
      baseLine: 109387800,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 134225000,
    },
    monthlyCostToCollect: {
      currentPhase: 2537800,
      baseLine: 2537800,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 106850000,
      baseLine: 106850000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 134225000,
    },
    averagePaymentLag: {
      currentPhase: 35,
      baseLine: 35,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 32,
    },
    riskLevel: 'medium',
    complexityLevel: 'low',
    favorite: false,
  },
  {
    id: '777',
    processName: 'Missing Authorization - Auth Review - Aetna	',
    description: 'OP procedures denied for missing authorization	',
    processVolume: 1800,
    processTime: 1770 * 60,
    fteCost: 4409111,
    esimatedprofitOpportunity: 1789320,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 119376000,
      baseLine: 119376000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 151416000,
    },
    monthlyCostToCollect: {
      currentPhase: 959400,
      baseLine: 959400,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 118416600,
      baseLine: 118416600,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 151416000,
    },
    averagePaymentLag: {
      currentPhase: 38,
      baseLine: 38,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 32,
    },
    riskLevel: 'high',
    complexityLevel: 'high',
    favorite: false,
  },
  {
    id: '888',
    processName: 'Missing Authorization - Auth Review - BCBS	',
    description: 'OP procedures denied for missing authorization	',
    processVolume: 1920,
    processTime: 951 * 60,
    fteCost: 3327528,
    esimatedprofitOpportunity: 1658572,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 117542400,
      baseLine: 117542400,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 156266900,
    },
    monthlyCostToCollect: {
      currentPhase: 1023400,
      baseLine: 1023400,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 116519000,
      baseLine: 116519000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 156266900,
    },
    averagePaymentLag: {
      currentPhase: 31,
      baseLine: 31,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 29,
    },
    riskLevel: 'medium',
    complexityLevel: 'high',
    favorite: false,
  },
  {
    id: '999',
    processName: 'Eligibility Review - Outpatient Registration - Aetna	',
    description: 'Patients not eligible based on registered insurance	',
    processVolume: 7067,
    processTime: 1249 * 60,
    fteCost: 3435437,
    esimatedprofitOpportunity: 1475335,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 337731900,
      baseLine: 337731900,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 367625300,
    },
    monthlyCostToCollect: {
      currentPhase: 1886900,
      baseLine: 1886900,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 335845000,
      baseLine: 335845000,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 367625300,
    },
    averagePaymentLag: {
      currentPhase: 38,
      baseLine: 38,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 31,
    },
    riskLevel: 'low',
    complexityLevel: 'high',
    favorite: false,
  },
  {
    id: '101010',
    processName: 'Eligibility Review - Outpatient Registration - BCBS	',
    description: 'Patients not eligible based on registered insurance	',
    processVolume: 9980,
    processTime: 1249 * 60,
    fteCost: 3435437,
    esimatedprofitOpportunity: 1475335,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 500197600,
      baseLine: 500197600,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 530137600,
    },
    monthlyCostToCollect: {
      currentPhase: 2664700,
      baseLine: 2664700,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 497532900,
      baseLine: 497532900,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 530137600,
    },
    averagePaymentLag: {
      currentPhase: 39,
      baseLine: 39,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 31,
    },
    riskLevel: 'low',
    complexityLevel: 'high',
    favorite: false,
  },
  {
    id: '202020',
    processName: 'E&M Denial - X175 Custom Error - County Care	',
    description:
      'Process to appeal denials from County Care with reason code X175 - "THIS PATIENT RECEIVED CARE BY PROVIDER WITHIN THE LAST THREE YEARS. AN ESTABLISHED PATIENT E/M CODE SHOULD HAVE BEEN USED."	',
    processVolume: 890,
    processTime: 1249 * 60,
    fteCost: 3435437,
    esimatedprofitOpportunity: 1475335,
    completedPhaseSteps: 11,
    monthlyWorkflowYield: {
      currentPhase: 44606800,
      baseLine: 44606800,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 47276800,
    },
    monthlyCostToCollect: {
      currentPhase: 237600,
      baseLine: 237600,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 44369200,
      baseLine: 44369200,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 47276800,
    },
    averagePaymentLag: {
      currentPhase: 39,
      baseLine: 39,
      phaseOne: 0,
      phaseTwo: 0,
      fullAutomation: 31,
    },
    riskLevel: 'low',
    complexityLevel: 'high',
    favorite: true,
  },
  {
    id: 'e9742b47-480a-4a9c-a525-5dfcd2b4bb89',
    processName: 'Authorization Request in Evicore - Sleep Management',
    description: 'Workflow to process authorization requests for sleep related procedure codes.',
    processVolume: 439,
    processTime: 366 * 60,
    fteCost: 25707,
    esimatedprofitOpportunity: 164932,
    completedPhaseSteps: 0,
    monthlyWorkflowYield: {
      currentPhase: 200023171,
      baseLine: 200023171,
      phaseOne: 220287575,
      phaseTwo: 221895680,
      fullAutomation: 240691120,
    },
    monthlyCostToCollect: {
      currentPhase: 3161466,
      baseLine: 3161466,
      phaseOne: 1763213,
      phaseTwo: 609027,
      fullAutomation: 0,
    },
    monthlyNetProfitabilityOfCollection: {
      currentPhase: 200021785,
      baseLine: 200021785,
      phaseOne: 220286802,
      phaseTwo: 221895413,
      fullAutomation: 240240020,
    },
    averagePaymentLag: {
      currentPhase: 33,
      baseLine: 33,
      phaseOne: 28,
      phaseTwo: 27,
      fullAutomation: 23,
    },
    riskLevel: 'medium',
    complexityLevel: 'medium',
    favorite: false,
    totalOutstanding: 145513058,
    averageOutstanding: 112018,
    averagePayment: 195733,
    averageOutcome: 187691,
    paymentFrequency: 80.2,
    averageCompletionTime: 387,
    meanCollectionCost: 1386,
    netCollectionProfit: 86305,
    workflowVariance: 1.6,
  },
]
