import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo, MutationResult } from 'apollo-angular'
import { ObserveOrgSetting } from 'generated/graphql'
import { Observable } from 'rxjs'
import {
  ObserveOrgSettings,
  UpdateObserveOrgSetting
} from 'src/app/admin/observe/org-settings/services/observe-org-settings.gql'

@Injectable({
  providedIn: 'root',
})
export class ObserveOrgSettingsService {
  constructor(private apollo: Apollo) {}

  public getObserveOrgSettings(
    orgId: string
  ): Observable<ApolloQueryResult<{ observeOrgSettingsByOrgId: ObserveOrgSetting[] }>> {
    return this.apollo.watchQuery<{ observeOrgSettingsByOrgId: ObserveOrgSetting[] }>({
      query: ObserveOrgSettings,
      variables: {
        orgId,
      },
    }).valueChanges
  }


  public updateObserveOrgSetting(
    orgId: string,
    lookupKey: string,
    value: string,
  ): Promise<
    MutationResult<{
      updateObserveOrgSetting: ObserveOrgSetting
    }>
  > {
    const variables = {
      orgId,
      key: lookupKey,
      value
    }

    return this.apollo
      .mutate<{ updateObserveOrgSetting: ObserveOrgSetting }>({
        mutation: UpdateObserveOrgSetting,
        variables,
        refetchQueries: [
          {
            query: ObserveOrgSettings,
            variables: {
              orgId
            },
          },
        ],
      })
      .toPromise()
  }
}
