<div class="modal-header">
  <h4 class="modal-title">Create New Task</h4>
</div>
<form [formGroup]="taskForm" (ngSubmit)="saveNewTask()" novalidate>
  <div class="modal-body">
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">
        {{ providerClaimId ? 'Task ' : 'Task Type ' }}
        Name
      </label>
      <div class="col-sm-8">
        <input
          type="text"
          class="form-control"
          formControlName="taskType"
          [ngbTypeahead]="search"
          [resultFormatter]="resultFormatter"
          [inputFormatter]="inputFormatter"
          (selectItem)="onSelectItem($event)"
          required
          [appFcBsValidation]="taskForm.get('taskType')"
          data-t="task-type-name"
        />
      </div>
    </div>
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">
        {{ providerClaimId ? 'Task ' : 'Task Type ' }}
        Instructions
      </label>
      <div class="col-sm-8">
        <textarea
          class="form-control"
          formControlName="instructions"
          rows="5"
          data-t="task-instruction-text-area"
        ></textarea>
      </div>
    </div>
    <div class="row my-4">
      <label class="col-sm-4 d-flex align-items-center task-input-label">
        {{ providerClaimId ? 'Task ' : 'Task Type ' }}
        Average Completion Time (minutes)
      </label>
      <div class="col-sm-8">
        <input
          type="number"
          class="form-control"
          formControlName="aveMinutes"
          required
          [appFcBsValidation]="taskForm.get('aveMinutes')"
          data-t="task-type-average-minutes"
        />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-cancel"
      (click)="activeModal.dismiss('Close click')"
      data-t="create-task-cancel-button"
    >
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-brand-green px-5"
      data-t="create-task-save-button"
      [disabled]="taskForm.invalid"
    >
      Save
      <app-loading *ngIf="saving" class="pl-2"></app-loading>
    </button>
  </div>
</form>
