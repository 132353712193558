<div class="text-center">
  <div
    class="profit text-nowrap"
    [ngClass]="{
      'bg-alt-light-blue-low': !isNil(currentPhase),
      'bg-alt-purple-low': !isNil(fullAutomation)
    }"
  >
    {{
      units === 'dollars'
        ? (((isNil(compareValue) ? baseLine : compareValue) * periodMultiplier) / 100
          | currency: 'USD':'symbol':(showDecimals ? '1.2-2' : '1.0-0'))
        : '' + (isNil(compareValue) ? baseLine : compareValue) + ' DAYS'
    }}
  </div>
  <div
    class="profit text-nowrap delta"
    *ngIf="showDelta"
    [ngClass]="{
      'bg-alt-light-green-med': isLowerBetter ? decrease : compareValue >= baseLine,
      'bg-alt-red-med': isLowerBetter ? compareValue > baseLine : decrease
    }"
  >
    <i class="fa fa-plus-circle" [class]="decrease ? 'fa-minus-circle' : 'fa-plus-circle'"></i>
    {{
      units === 'dollars' ? (delta | currency: 'USD':'symbol':(showDecimals ? '1.2-2' : '1.0-0')) : '' + delta + ' DAYS'
    }}
  </div>
  <div class="profit" *ngIf="!showDelta && hasSecondChild"></div>
</div>
