import { Directive, Input, OnInit, Type, ViewContainerRef } from '@angular/core'

/**
 * Angular directive to load another component dynamically
 *
 * @export
 * @class DynamicComponentDirective
 * @implements {OnInit}
 */
@Directive({
  selector: '[appDynamicComponent]',
})
export class DynamicComponentDirective implements OnInit {
  @Input() component: Type<any>
  @Input() data: any

  constructor(private vcr: ViewContainerRef) {}

  ngOnInit(): void {
    this.vcr.clear()
    let component = this.vcr.createComponent(this.component)
    component.instance.data = this.data
  }
}
