import { debug } from 'app/shared/utils/debug'

const getCurrentPathAndQueryStringsFromUrl = (): string => {
  return `${document.location.pathname}${document.location.search}`
}

export const buildPathForLoginWithRelayState = (relayState = getCurrentPathAndQueryStringsFromUrl()): string => {
  if (relayState === '') {
    return `/login`
  }

  return `/login?relayState=${encodeURIComponent(relayState)}`
}

export const setPathForLoginWithRelayState = (relayState = getCurrentPathAndQueryStringsFromUrl()): void => {
  debug('authentication', 'redirect to login', relayState)
  const path = buildPathForLoginWithRelayState(relayState)
  document.location.assign(`${path}`)
}
