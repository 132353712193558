import { Component, TemplateRef, ViewEncapsulation } from '@angular/core'
import { ToastService } from 'app/shared/services/toast.service'

/**
 * Component to display toast notifications
 * See: toast.service.ts
 *
 * @export
 * @class NotificationsComponent
 */
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  host: { class: 'our-toasts' },
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: { textOrTpl: TemplateRef<boolean> | string }): boolean {
    return toast?.textOrTpl instanceof TemplateRef
  }
}
