import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { SortEvent } from 'app/shared/directives/sortable.directive'
import { PayerPlanGroupService } from 'app/shared/services/payer-plan-group.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ListResponseMetaData, PayerPlanGroupName } from 'generated/graphql'
import { Subscription } from 'rxjs'
import { debounceTime, finalize, first, tap } from 'rxjs/operators'

@Component({
  selector: 'app-payer-plan-groups',
  templateUrl: './payer-plan-groups.page.html',
  styleUrls: ['./payer-plan-groups.page.scss'],
  host: { class: 'h-100' },
})
export class PayerPlanGroupPage implements OnInit, OnDestroy {
  groupSearchSub$: Subscription

  isSearchingGroups: boolean = false
  groupSearch = new UntypedFormControl('')

  isAddingGroup = false
  groupToAdd = ''
  username: string

  isLoading: boolean = false
  payerPlanGroups: PayerPlanGroupName[]
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 10
  sortDirection = 1
  nameLike = ''

  constructor(
    private payerPlanGroupService: PayerPlanGroupService,
    private toast: ToastService,
    private modal: NgbModal,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.username = this.authenticationService.getUser()?.name

    this.groupSearchSub$ = this.groupSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap((term) => {
          this.nameLike = term
          this.page = 1
          this.getPayerPlanGroupNames()
        }),
      )
      .subscribe()

    this.getPayerPlanGroupNames()
  }

  ngOnDestroy(): void {
    this.groupSearchSub$.unsubscribe()
  }

  toggleAddingGroup(): void {
    this.isAddingGroup = !this.isAddingGroup
  }

  createGroup(name: string): void {
    this.isLoading = true

    this.payerPlanGroupService
      .createGroup(name, this.username)
      .pipe(
        first(),
        finalize(() => {
          this.isLoading = false
        }),
      )
      .subscribe(
        async (results) => {
          this.toast.success(`Successfully Created Group "${this.groupToAdd}"`)
          this.groupToAdd = ''
          this.getPayerPlanGroupNames()
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not create group'), JSON.stringify(e))
        },
      )
  }

  createGroupName(): void {
    if (!this.groupToAdd) {
      return
    }

    const bodyMessage = `Are you sure you want to create the group "${this.groupToAdd}"?`

    const modalRef = this.modal.open(ConfirmModalComponent)
    modalRef.componentInstance.title = `Confirm Create Group`
    modalRef.componentInstance.body = bodyMessage
    modalRef.componentInstance.yes = 'Yes'
    modalRef.componentInstance.yesClass = 'btn-primary'

    modalRef.result
      .then(
        (result) => {
          //Handle Save
          this.createGroup(this.groupToAdd)
        },
        () => {
          // Handle modal dismissal
        },
      )
      .finally(() => {
        this.isAddingGroup = false
      })
  }

  changePage(event: PaginationUpdateEvent): void {
    this.page = event.newPageNumber
    this.getPayerPlanGroupNames()
  }

  onSort(event: SortEvent): void {
    if (event.direction === 'asc') {
      this.sortDirection = 1
    } else if (event.direction === 'desc') {
      this.sortDirection = -1
    } else {
      this.sortDirection = 0
    }

    this.getPayerPlanGroupNames()
  }

  async getPayerPlanGroupNames(): Promise<void> {
    this.isLoading = true
    const offset = (this.page - 1) * this.pageSize

    this.payerPlanGroupService
      .getPayerPlanGroupNames(offset, this.pageSize, {
        sortDirection: this.sortDirection,
        nameLike: this.nameLike,
      })
      .pipe(first())
      .subscribe(
        async (groupList) => {
          this.payerPlanGroups = groupList?.entities // will need to concat if want to keep in memory
          this.meta = this.payerPlanGroupService.getMeta()
        },
        (e) => {
          this.toast.error(parseGraphQLError(e, 'Could not find payer-plan-groups'), JSON.stringify(e))
        },
        () => {
          this.isLoading = false
        },
      )
  }
}
