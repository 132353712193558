<div class="container d-flex flex-column min-vh-100">
  <div class="row d-flex justify-content-center my-5">
    <div class="col-md-4 col-8 d-flex justify-content-center">
      <div class="px-4" id="janus-logo">
        <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" height="50px" />
      </div>
    </div>
  </div>
  <div class="row flex-grow-1 justify-content-start">
    <div class="col-6 offset-3 py-3">
      <h1 class="h3 text-center text-alt-white">System Status</h1>
      <div class="card">
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex flex-row justify-content-between align-items-center">
            <span>API Connectivity</span>
            <app-loading *ngIf="connectivity === 'checking'"></app-loading>
            <i
              *ngIf="connectivity !== 'checking'"
              class="fa fs-18"
              [ngClass]="{
                'fa-check-circle text-success': connectivity === 'good',
                'fa-times-circle text-danger': connectivity === 'bad'
              }"
            ></i>
          </li>
          <li class="list-group-item d-flex flex-row justify-content-between align-items-center">
            <span>API Health</span>
            <app-loading *ngIf="health === 'checking'"></app-loading>
            <i
              *ngIf="health !== 'checking'"
              class="fa fs-18"
              [ngClass]="{
                'fa-check-circle text-success': health === 'good',
                'fa-times-circle text-danger': health === 'bad',
                'fa-exclamation-circle text-warning': health === 'slow'
              }"
            ></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
