<div class="d-flex flex-column h-100">
  <jui-data-table-view
    [dataSource]="dataSource"
    [config]="tableConfig"
    [pageSize]="pageSize"
    [enableSearch]="true"
  >
    <jui-button routerLink="/admin/feature-flags/new/new" ngProjectAs="toolbar-top">Add Feature Flag</jui-button>
    <ng-template juiTableTemplateName="feature-flag-name" let-value="value">
      <a routerLink="/admin/feature-flags/{{value}}">{{value}}</a>
    </ng-template>
  </jui-data-table-view>
</div>
