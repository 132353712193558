import { Injectable } from '@angular/core'
import { Apollo, gql } from 'apollo-angular'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { debug } from 'app/shared/utils/debug'
import { Role, User, UserRole } from 'generated/graphql'
import { map } from 'rxjs/operators'
import { GetUserPermissions } from 'src/app/auth/auth.gql'
import { AuthenticationService } from './authentication.service'
import { PermissionsConfig } from './permissions/permissions-config.interface'
import { Permission, PermissionConditionType } from './types'

/**
 * Service to handle authorization
 *
 * @export
 * @class AuthorizationService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(
    private apollo: Apollo,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  /**
   * Get all granted user permissions.
   *
   * @memberof AuthorizationService
   */
  async getUserPermissions(): Promise<Permission[]> {
    const result = await this.apollo
      .query<{ userPermissions: Permission[] }>({
        query: GetUserPermissions,
      })
      .toPromise()

    return result.data.userPermissions
  }

  /**
   * Check if a user is granted one or more permissions.
   *
   * @param permissions
   * @param options
   * @memberof AuthorizationService
   */
  async hasPermissions(
    permissions: Permission[],
    options?: { conditionType: PermissionConditionType },
  ): Promise<boolean> {
    if (this.isSuper()) return true

    const userPermissions = await this.getUserPermissions()
    const conditionType = options?.conditionType ?? 'AND'

    if (!permissions.length) {
      return false
    }

    if (conditionType === 'AND' && permissions.every((value) => userPermissions.includes(value))) {
      return true
    }

    if (conditionType === 'OR' && permissions.some((value) => userPermissions.includes(value))) {
      return true
    }

    return false
  }

  /**
   * Check if a user has valid permissions while also falling back to check roles too.
   *
   * @param config
   * @memberof AuthorizationService
   */
  async hasPermissionsOrRoles(config: PermissionsConfig): Promise<boolean> {
    const { permissions, permissionType, roles } = config

    debug(
      'authorization',
      'permission guard got',
      JSON.stringify({
        permissions,
        permissionType,
        roles,
      }),
    )

    try {
      const isSuper = this.isSuper()

      if (isSuper) return true

      if (!(await this._user())) return false

      if (
        permissions?.length &&
        (await this.featureFlagService.getFeatureFlagValue(FeatureFlags.auth.authorization.v2023)) &&
        (await this.hasPermissions(permissions, { conditionType: permissionType }))
      ) {
        return true
      }

      // Fallback to role check if roles are provided.
      if (
        roles?.length &&
        !roles.includes('Super') && // User is not super at this point.
        roles.some((r) => this.authenticationService.checkUserRole(r as UserRole))
      ) {
        return true
      }

      return false
    } catch (e) {
      debug('role', 'has permission or roles error', e)
      return false
    }
  }

  /**
   * Gets the user from authentication service.
   *
   * @memberof AuthorizationService
   */
  private async _user(): Promise<User | null> {
    // Fetch user if not already set.
    if (!this.authenticationService.getUser()?.id) {
      await this.authenticationService.setUser()
    }
    return this.authenticationService.getUser()
  }

  /**
   * Is the current user Janus staff
   *
   * @memberof AuthorizationService
   */
  isStaff(): boolean {
    return this.authenticationService.getUser().isStaff || false
  }

  /**
   * Is the current user Janus Super user
   *
   * @memberof AuthorizationService
   */
  isSuper(): boolean {
    return this.authenticationService.getUser()?.isSuperuser || false
  }

  /**
   * Super users do not have more than one 'allowedOrgIds' so we also check for isSuperuser here.
   * @memberof AuthorizationService
   */
  userHasMultipleOrgs(): boolean {
    return (this.authenticationService.getUser().allowedOrgIds?.length > 1 || this.isSuper()) ?? false
  }

  getOrgRoles(orgId: string): Promise<Role[]> {
    return this.apollo
      .query<{ orgRoles: Role[] }>({
        query: gql`
          query GetOrgRoles($orgId: String!) {
            orgRoles(orgId: $orgId) {
              id
              name
            }
          }
        `,
        variables: { orgId },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.orgRoles))
      .toPromise()
  }

  getUserRoles(userId: string, orgId: string): Promise<Role[]> {
    return this.apollo
      .query<{ userRoles: Role[] }>({
        query: gql`
          query GetUserRoles($userId: String!, $orgId: String!) {
            userRoles(userId: $userId, orgId: $orgId) {
              id
              name
            }
          }
        `,
        variables: { userId, orgId },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result) => result.data.userRoles))
      .toPromise()
  }
}
