import { Directive, ElementRef, HostListener } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

/**
 * Angular directive to connect HTML File Input to Angular Forms API
 *
 * @export
 * @class FileUploadDirective
 * @implements {ControlValueAccessor}
 */
@Directive({
  selector: 'input[type=file]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadDirective,
      multi: true,
    },
  ],
})
export class FileUploadDirective implements ControlValueAccessor {
  onChange
  private file: File | null = null

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    const file = event && event.item(0)
    if(this.onChange) {
      this.onChange(file)
      this.file = file
    }
    
  }

  constructor(private host: ElementRef<HTMLInputElement>) {}

  writeValue(value: null): void {
    // clear file input
    this.host.nativeElement.value = ''
    this.file = null
  }

  registerOnChange(fn: File): void {
    this.onChange = fn
  }

  registerOnTouched(): void {}
}
