import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { first, map, switchMap, takeUntil } from 'rxjs/operators'
import { OrgService } from 'app/admin/org/org.service'
import { Subject } from 'rxjs'
import { AuthType, BulkUpdateOrgSamlInput, OrgSaml, Organization } from 'generated/graphql'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { SamlService } from '../services/saml.service'
import { ToastService } from 'app/shared/services/toast.service'

interface OrgSamlFormGroup {
  id: FormControl<string | null>
  metadataUrl: FormControl<string | null>
  emailClaimName: FormControl<string | null>
  nameClaimName: FormControl<string | null>
  emailDomains: FormControl<string[]>
  metadataFile: FormControl<{ contents: string; name: string; fromDb: boolean } | null>
}

@Component({
  selector: 'app-saml-configuration-page',
  templateUrl: './saml-configuration.page.html',
})
export class SamlConfigurationPage implements OnInit, OnDestroy {
  defaultMetadataFileName = 'metadata.xml'
  organization: Organization = null
  form = new FormGroup({
    enableSamlSso: new FormControl<boolean | null>(null),
    orgSamlConfigurations: new FormArray<FormGroup<OrgSamlFormGroup>>([]),
  })
  deleteIds: string[] = []

  destroy$ = new Subject<void>()

  orgId$ = this.activatedRoute.params.pipe(
    takeUntil(this.destroy$),
    map((route) => route.orgId),
  )

  org$ = this.orgId$.pipe(
    switchMap((orgId) => this.orgService.getOrg(orgId)),
    takeUntil(this.destroy$),
  )

  constructor(
    private activatedRoute: ActivatedRoute,
    private orgService: OrgService,
    private samlService: SamlService,
    private toastService: ToastService,
    private router: Router,
  ) {}

  /**
   * Fetch existing SAML data and setup FileReader.
   */
  ngOnInit(): void {
    this.org$.subscribe(({ data }) => {
      this.organization = data.organization
      this.form.controls.enableSamlSso.setValue(data.organization.authType === AuthType.SamlAuth)
    })

    this.orgId$
      .pipe(
        switchMap((orgId) => this.samlService.getAllByOrgId(orgId)),
        first(),
      )
      .subscribe((originalOrgSamlConfigurations) => {
        for (const orgSaml of originalOrgSamlConfigurations) {
          this.form.controls.orgSamlConfigurations.push(this.createFormGroup(orgSaml))
        }
      })
  }

  /**
   * Navigate back to the saml org selection list.
   */
  onCancel() {
    this.router.navigate(['../..'], { relativeTo: this.activatedRoute })
  }

  /**
   * Trigger end of takeUntils in other parts of code.
   */
  ngOnDestroy(): void {
    this.destroy$?.next()
    this.destroy$?.complete()
  }

  /**
   * Add a new org saml form
   */
  addNewOrgSamlFormGroup() {
    // Insert to the beginning of the array per docs:
    // If index is greatly negative (less than -length), prepends to the array.
    const insertIndex = 0 - this.form.controls.orgSamlConfigurations.length

    this.form.controls.orgSamlConfigurations.insert(insertIndex, this.createFormGroup(null))
  }

  /**
   * Get an org saml configuration form control by index.
   */
  getOrgSamlFormControlByIndex(index: number) {
    return this.form.controls.orgSamlConfigurations.controls[index].controls
  }

  /**
   * Remove OrgSaml form group by index
   */
  removeOrgSamlFormGroup(index: number) {
    const orgSaml = this.getOrgSamlFormControlByIndex(index)
    const orgSamlId = orgSaml.id.value

    this.form.controls.orgSamlConfigurations.removeAt(index)

    if (orgSamlId) {
      this.deleteIds.push(orgSamlId)
    }
  }

  /**
   * Read in metadata xml file and post it to the saml endpoint.
   * @param {Event} event
   */
  async onMetadataFileUpload(event: Event, index: number): Promise<void> {
    if ('files' in event.target) {
      const file = event.target.files[0]
      const name = file.name

      const orgSaml = this.getOrgSamlFormControlByIndex(index)
      const contents = await this.getFileContents(file)
      orgSaml.metadataFile.setValue({ contents, name, fromDb: false })
    }
  }

  /**
   * Given a File object get the contents of that file
   */
  getFileContents(file: File): Promise<string> {
    return new Promise((resolve) => {
      const fileReader = new FileReader()
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event.target.result as string)
      }

      fileReader.readAsText(file)
    })
  }

  /**
   * Clear metadataFile fields.
   */
  async onClearMetadataFile(index: number): Promise<void> {
    const orgSaml = this.getOrgSamlFormControlByIndex(index)

    orgSaml.metadataFile.setValue(null)
  }

  /**
   * Fetch saml xml file from API and download it.
   */
  async onDownloadMetadataFile(index: number): Promise<void> {
    const orgSaml = this.getOrgSamlFormControlByIndex(index)
    const orgSamlId = orgSaml.id.value

    if (!orgSamlId) {
      return
    }

    const metadata: string = await this.samlService.fetchSamlMetadata(orgSamlId)
    const xmlBlob: Blob = new Blob([metadata], { type: 'text/plain' })
    const xmlUrl: string = window.URL.createObjectURL(xmlBlob)
    const anchor: HTMLAnchorElement = document.createElement('a')
    anchor.href = xmlUrl
    anchor.download = this.defaultMetadataFileName
    anchor.click()
    anchor.remove()
  }

  /**
   * Create a form group based on an existing org saml or a null org saml
   */
  createFormGroup(orgSaml: OrgSaml | null) {
    let metadataFile = null

    if (orgSaml?.metadata) {
      metadataFile = { contents: orgSaml?.metadata, name: this.defaultMetadataFileName, fromDb: true }
    }

    const formGroup = new FormGroup<OrgSamlFormGroup>({
      id: new FormControl(orgSaml?.id ?? null),
      metadataUrl: new FormControl(orgSaml?.metadataUrl ?? null, [isValidUrl()]),
      emailClaimName: new FormControl(orgSaml?.emailClaimName ?? null, [Validators.required]),
      nameClaimName: new FormControl(orgSaml?.nameClaimName ?? null, [Validators.required]),
      emailDomains: new FormControl(orgSaml?.emailDomains ?? null, [
        Validators.required,
        isValidDomain(),
        noDuplicatesInFormArray('emailDomains', this.form.controls.orgSamlConfigurations),
      ]),
      metadataFile: new FormControl(metadataFile),
    })

    const formGroupValidators = [oneRequired('metadataUrl', 'metadataFile'), onlyOneMetadataFileOrMetadataUrl()]

    formGroup.setValidators(formGroupValidators)

    return formGroup
  }

  /**
   * On save of all org saml controls
   */
  async onSave(): Promise<void> {
    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    const orgSamlControls = this.form.controls.orgSamlConfigurations.controls
    const samlArray: BulkUpdateOrgSamlInput[] = []

    for (const orgSamlFormGroup of orgSamlControls) {
      const { id, metadataUrl, emailClaimName, nameClaimName, emailDomains, metadataFile } = orgSamlFormGroup.controls

      const metadataContents = metadataFile.value?.contents ?? null

      const orgSamlData = {
        emailDomains: emailDomains.value,
        emailClaimName: emailClaimName.value,
        nameClaimName: nameClaimName.value,
        metadata: metadataContents,
        metadataUrl: metadataUrl.value,
        orgId: this.organization.id,
      }

      if (id.value) {
        orgSamlData['id'] = id.value
      }

      samlArray.push(orgSamlData)
    }

    const newSamlArray = await this.samlService.createOrUpdateBulk(samlArray).toPromise()

    this.form.controls.orgSamlConfigurations.clear()

    for (const orgSaml of newSamlArray.data.createOrUpdateSamlBulk.entities) {
      this.form.controls.orgSamlConfigurations.push(this.createFormGroup(orgSaml))
    }

    const authType = this.form.controls.enableSamlSso.value ? AuthType.SamlAuth : AuthType.PasswordAuth

    const promises = []

    promises.push(
      this.orgService.updateOrg(this.organization.id, {
        authType,
      }),
    )

    for (const id of this.deleteIds) {
      promises.push(this.samlService.delete(id).toPromise())
    }

    try {
      await Promise.all(promises)
      this.toastService.success('SAML configuration updated')
    } catch (error) {
      this.toastService.error('Could not update SAML configuration')
    }
  }
}

/**
 * Angular Validator that checks to ensure one input is filled out but not both.
 */
function oneRequired(controlNameOne: string, controlNameTwo: string): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const controlOne = group.get(controlNameOne)
    const controlTwo = group.get(controlNameTwo)

    if (!controlOne || !controlTwo) {
      return null
    }

    if (!controlOne.value && !controlTwo.value) {
      return { oneRequired: true }
    }

    return null
  }
}

/**
 * Angular Validator that checks to ensure only the metadata URL OR the metadata file is filled out
 * unless the metadata file came from the database
 */
function onlyOneMetadataFileOrMetadataUrl(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const metadataUrlControl = group.get('metadataUrl')
    const metadataFileControl = group.get('metadataFile')

    if (!metadataUrlControl || !metadataFileControl) {
      return null
    }

    // If the metadata file came from the DB allow both because the URL sets the metadata file so
    // both should exist
    if (metadataFileControl?.value?.fromDb) {
      return null
    }

    if (metadataFileControl.value && metadataUrlControl.value) {
      return { notBoth: true }
    }

    return null
  }
}

/**
 * Ensure there are no duplicate values for the given control name across all form groups
 * in the given form array
 */
function noDuplicatesInFormArray(controlName: string, formArray: FormArray): ValidatorFn {
  return (thisArrayCtrl: AbstractControl): ValidationErrors | null => {
    const groupArray = formArray.controls

    if (!thisArrayCtrl.value) {
      return null
    }

    for (const group of groupArray) {
      const otherArrayCtrl = group.get(controlName)
      if (otherArrayCtrl === thisArrayCtrl) {
        // Check for duplicates in own array
        const domainsSoFar = []
        for (const domain of thisArrayCtrl.value) {
          if (domainsSoFar.includes(domain)) {
            return { noDuplicatesInFormArray: domain }
          }

          domainsSoFar.push(domain)
        }
      } else {
        // Check for duplicates in other array
        for (const domain of thisArrayCtrl.value) {
          if (otherArrayCtrl.value.includes(domain)) {
            return { noDuplicatesInFormArray: domain }
          }
        }
      }
    }

    return null
  }
}

/**
 * Angular Validator that checks if a form control value is a URL
 */
function isValidUrl(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value

    if (!value) {
      return null
    }

    try {
      new URL(value)
      return null
    } catch (error) {
      return { isValidUrl: true }
    }
  }
}

/**
 * Ensure given domain is valid
 */
function isValidDomain(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const domainArray = control.value

    if (!domainArray) {
      return null
    }

    for (const domain of domainArray) {
      const validRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/g.test(domain)

      if (!validRegex) {
        return { isValidDomain: domain }
      }
    }

    return null
  }
}
