import { EdgeConfig, NodeConfig } from '@antv/g6'
import {
  GREEN_EDGE,
  GREEN_NODE,
  IMA_EDGE,
  IMA_NODE,
  ORANGE_EDGE,
  ORANGE_NODE,
  PURPLE_EDGE,
  PURPLE_NODE,
  RED_DASH_EDGE,
  RED_DASH_LINE_EDGE,
  RED_EDGE,
  RED_NODE,
} from '../nodes'

export const MainNodes: (IMA_NODE & Partial<NodeConfig>)[] = [
  // {
  //   id: 'start',
  //   label: 'ADR Requests for NGS - Procedural CPTs\nCount:2281',
  //   ...GREEN_NODE,
  // },
  {
    id: '1',
    label: 'ADR Denial from NGS\nCount: 2281',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 2281,
    ...GREEN_NODE,
  },
  {
    id: '4a',
    label: 'Transfer item to alternate WQ\nCount: 319',
    totalTime: '216',
    totalPercent: '14',
    totalCount: '319',
    impactOnDenial: 8,
    relativeImpactOnCollection: -12,
    absoluteImpactOnCollection: -105.23,
    ...RED_NODE,
  },
  {
    id: '2',
    label: 'Select Next WQ Item\nCount: 2281',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: 'Open ADR doc from WQ Item\nCount: 1962',
    idealEdge: 'path24',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open ROI Activity\nCount: 1962',
    totalTime: '211',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Lookup Patient Visit \nin ROI Activity\nCount: 1962',
    totalTime: '429',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '8',
    label: "'Op Note' Only\nCount: 1393",
    totalTime: '642',
    totalPercent: '62',
    totalCount: '1393',
    impactOnDenial: -86,
    relativeImpactOnCollection: 56,
    absoluteImpactOnCollection: 491.07,
    ...GREEN_NODE,
  },
  {
    id: '8a',
    label: "'Op Note' and \nother documents\nCount: 353",
    totalTime: '647',
    totalPercent: '15',
    totalCount: '353',
    impactOnDenial: 8,
    relativeImpactOnCollection: -8,
    absoluteImpactOnCollection: -78.92,
    ...ORANGE_NODE,
  },
  {
    id: '8b',
    label: "No 'Op Note' Selected\nCount: 216",
    totalTime: '638',
    totalPercent: '9.5',
    totalCount: '216',
    impactOnDenial: 78,
    relativeImpactOnCollection: -48,
    absoluteImpactOnCollection: -420.92,
    ...RED_NODE,
  },
  {
    id: '9',
    label: 'Document(s) Generated\nCount: 1962',
    totalTime: '655',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '10a',
    label: 'Save to Network Driven\nCount: 235',
    totalTime: '699',
    totalPercent: '10',
    totalCount: '235',
    ...ORANGE_NODE,
  },
  {
    id: '11',
    label: 'File saved to Local Drive\nCount: 235',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '235',
    ...GREEN_NODE,
  },
  {
    id: '11a',
    label: 'Copy to Local Drive\nCount: 235',
    totalTime: '730',
    totalPercent: '10',
    totalCount: '235',
    ...ORANGE_NODE,
  },
  {
    id: '12',
    label: 'Navigate to NGS Portal\nCount: 1962',
    totalTime: '808',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Log in to NGS Portal\nCount: 1962',
    totalTime: '856',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '14',
    label: 'Navigate to ADR Lookup Form\nCount: 1962',
    totalTime: '967',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '15a',
    label: 'Tab to EHR Window\nCount: 863',
    totalTime: '992',
    totalPercent: '38',
    totalCount: '863',
    ...ORANGE_NODE,
  },
  {
    id: '15b',
    label: 'Open Claims WQ\nCount: 863',
    totalTime: '1014',
    totalPercent: '38',
    totalCount: '863',
    ...ORANGE_NODE,
  },
  {
    id: '16',
    label: 'Tab to ADR Document Window\nCount: 1099',
    totalTime: '991',
    totalPercent: '48',
    totalCount: '1099',
    ...GREEN_NODE,
  },
  {
    id: '17',
    label: 'Copy Lookup Fields from ADR\ndoc to NGS Portal\nCount: 1099',
    totalTime: '1122',
    totalPercent: '48',
    totalCount: '1099',
    ...GREEN_NODE,
  },
  {
    id: '17a',
    label: 'Open ADR Document\nCount: 863',
    totalTime: '1075',
    totalPercent: '38',
    totalCount: '863',
    ...ORANGE_NODE,
  },
  {
    id: '17b',
    label: 'Select next WQ item\nCount: 863',
    totalTime: '1050',
    totalPercent: '38',
    totalCount: '863',
    ...ORANGE_NODE,
  },
  {
    id: '18',
    label: 'Look up ADR\nCount: 1962',
    totalTime: '1142',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Upload ADR File\nCount: 1962',
    totalTime: '1207',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '20',
    label: 'Submit Reconsideration\nCount: 1962',
    totalTime: '1249',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
]

export const MainEdges: IMA_EDGE[] & Partial<EdgeConfig>[] = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    label: '2281',
    percent: '100',
    time: 15,
    count: 2281,
    ...GREEN_EDGE,
  },
  {
    id: 'path4a17b',
    source: '4a',
    sourceAnchor: 3,
    target: '2',
    targetAnchor: 2,
    label: '269',
    percent: '14',
    time: 408,
    count: 291,
    ...RED_DASH_LINE_EDGE,
  },
  {
    id: 'path24',
    source: '2',
    target: '4',
    label: '1962',
    percent: '86',
    time: 17,
    count: 1962,
    ...GREEN_EDGE,
  },

  {
    id: 'path24a',
    source: '2',
    target: '4a',
    label: '319',
    percent: '14',
    time: 186,
    count: 319,
    ...RED_EDGE,
  },

  // {
  //   id: 'path4a4b',
  //   source: '4a',
  //   target: '4b',
  //   label: '319',
  //   percent: '14',
  //   time: 408,
  //   count: 319,
  //   style: {
  //     lineDash: [4, 8],
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth * 1.3,
  //     radius: edgeRadius,
  //     stroke: redEdgeColor,
  //     strokeOpacity: 1,
  //     endArrow: '',
  //   },
  //   labelCfg: {
  //     refX: 5,
  //     refY: 20,
  //     style: {
  //       fontSize: 14,
  //       fontWeight: 400,
  //     },
  //   },
  // },
  // {
  //   id: 'path4b2',
  //   source: '4b',
  //   target: '2',
  //   label: '291',
  //   percent: '13',
  //   time: 105,
  //   count: 291,
  //   style: {
  //     lineDash: [4, 8],
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth * 1.3,
  //     radius: edgeRadius,
  //     stroke: redEdgeColor,
  //     strokeOpacity: 1,
  //     endArrow: defaultArrow,
  //   },
  //   labelCfg: {
  //     refX: 5,
  //     refY: 20,
  //     style: {
  //       fontSize: 14,
  //       fontWeight: 400,
  //     },
  //   },
  // },
  {
    id: 'path45',
    source: '4',
    target: '5',
    label: '1962',
    percent: '86',
    time: 23,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    label: '1962',
    percent: '86',
    time: 218,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path68',
    source: '6',
    target: '8',
    label: '1393',
    percent: '61',
    time: 12,
    count: 1393,
    ...GREEN_EDGE,
  },
  {
    id: 'path68a',
    source: '6',
    target: '8a',
    label: '353',
    percent: '15',
    time: 17,
    count: 353,
    ...ORANGE_EDGE,
  },
  {
    id: 'path68b',
    source: '6',
    target: '8b',
    label: '216',
    percent: '9.5',
    time: 8,
    count: 216,
    ...RED_EDGE,
  },
  {
    id: 'path89',
    source: '8',
    target: '9',
    label: '1393',
    percent: '61',
    time: 12,
    count: 1393,
    ...GREEN_EDGE,
  },
  {
    id: 'path8a9',
    source: '8a',
    target: '9',
    label: '353',
    percent: '15',
    time: 11,
    count: 207,
    ...ORANGE_EDGE,
  },
  {
    id: 'path8b9',
    source: '8b',
    target: '9',
    label: '216',
    percent: '9.5',
    time: 9,
    count: 207,
    ...RED_EDGE,
  },
  {
    id: 'path911',
    source: '9',
    target: '11',
    label: '1727',
    percent: '76',
    time: 1,
    count: 1727,
    ...GREEN_EDGE,
  },
  {
    id: 'path910a',
    source: '9',
    target: '10a',
    label: '235',
    percent: '10',
    time: 18,
    count: 235,
    ...ORANGE_EDGE,
  },
  {
    id: 'path10a11a',
    source: '10a',
    target: '11a',
    label: '235',
    percent: '10',
    time: 33,
    count: 235,
    ...ORANGE_EDGE,
  },
  {
    id: 'path11a11',
    source: '11a',
    target: '11',
    label: '235',
    percent: '10',
    time: 12,
    count: 235,
    ...ORANGE_EDGE,
  },
  {
    id: 'path1112',
    source: '11',
    target: '12',
    label: '1962',
    percent: '86',
    time: 126,
    count: 1962,
    ...GREEN_EDGE,
  },

  {
    id: 'path1213',
    source: '12',
    target: '13',
    label: '1962',
    percent: '86',
    time: 48,
    count: 1962,
    ...GREEN_EDGE,
  },

  {
    id: 'path1314',
    source: '13',
    target: '14',
    label: '1962',
    percent: '86',
    time: 111,
    count: 1962,
    ...GREEN_EDGE,
  },

  // {
  //   id: "path1415",
  //   source: "14",
  //   target: "15",
  //   label: "1962",
  //   percent: "86",
  //   time: 20,
  //   count: 1962,
  //   style: {
  //     lineAppendWidth: appendEdgeInteractionWidth,
  //     lineWidth: edgeWidth*3.1,
  //     radius: edgeRadius,
  //     // stroke: edgeColor,
  //     stroke: greenEdgeColor,
  //     strokeOpacity: 1,
  //      endArrow: defaultArrow,
  //   },
  //   labelCfg: {
  //     refY: 20,
  //     refX: 5,
  //   },
  // },
  {
    id: 'path1416',
    source: '14',
    target: '16',
    label: '1099',
    percent: '48',
    time: 4,
    count: 1099,
    ...GREEN_EDGE,
  },
  {
    id: 'path1415a',
    source: '14',
    target: '15a',
    label: '863',
    percent: '38',
    time: 5,
    count: 863,
    ...ORANGE_EDGE,
  },
  {
    id: 'path15a15b',
    source: '15a',
    target: '15b',
    label: '863',
    percent: '38',
    time: 22,
    count: 863,
    ...ORANGE_EDGE,
  },
  {
    id: 'path15b17b',
    source: '15b',
    target: '17b',
    label: '863',
    percent: '38',
    time: 36,
    count: 863,
    ...ORANGE_EDGE,
  },
  {
    id: 'path1617',
    source: '16',
    target: '17',
    label: '1099',
    percent: '48',
    time: 95,
    count: 1099,
    ...GREEN_EDGE,
  },
  {
    id: 'path17b17a',
    source: '17b',
    target: '17a',
    label: '863',
    percent: '38',
    time: 25,
    count: 863,
    ...ORANGE_EDGE,
  },
  {
    id: 'path17a17',
    source: '17a',
    target: '17',
    label: '863',
    percent: '38',
    time: 98,
    count: 863,
    ...ORANGE_EDGE,
  },
  {
    id: 'path1718',
    source: '17',
    target: '18',
    label: '1962',
    percent: '86',
    time: 20,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1819',
    source: '18',
    target: '19',
    label: '1962',
    percent: '86',
    time: 65,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1920',
    source: '19',
    target: '20',
    label: '1962',
    percent: '86',
    time: 42,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path201',
    source: '20',
    target: '1',
    label: '361',
    percent: '14',
    time: 100,
    count: 319,
    ...RED_DASH_EDGE,
  },
]

export const IdealNodes = [
  {
    id: '1',
    label: 'ADR Denial from NGS',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 2281,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select Next WQ Item',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: 'Open ADR doc from WQ Item',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open ROI Activity',
    totalTime: '211',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Lookup Patient Visit in ROI Activity',
    totalTime: '429',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '8',
    label: "'Op Note' Only",
    totalTime: '642',
    totalPercent: '62',
    totalCount: '1393',
    impactOnDenial: -86,
    relativeImpactOnCollection: 56,
    absoluteImpactOnCollection: 491.07,
    ...GREEN_NODE,
  },
  {
    id: '9',
    label: 'Document(s) Generated',
    totalTime: '655',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '11',
    label: 'File saved to Local Drive',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '235',
    ...GREEN_NODE,
  },
  {
    id: '12',
    label: 'Navigate to NGS Portal',
    totalTime: '808',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Log in to NGS Portal',
    totalTime: '856',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '14',
    label: 'Navigate to ADR Lookup Form',
    totalTime: '967',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '16',
    label: 'Tab to ADR Document Window',
    totalTime: '991',
    totalPercent: '48',
    totalCount: '1099',
    ...GREEN_NODE,
  },
  {
    id: '17',
    label: 'Copy Lookup Fields from ADR\ndoc to NGS Portal',
    totalTime: '1122',
    totalPercent: '48',
    totalCount: '1099',
    ...GREEN_NODE,
  },
  {
    id: '18',
    label: 'Look up ADR',
    totalTime: '1142',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Upload ADR File',
    totalTime: '1207',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '20',
    label: 'Submit Reconsideration',
    totalTime: '1249',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
]
export const IdealEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    percent: '100',
    time: 15,
    count: 2281,
    ...GREEN_EDGE,
  },
  {
    id: 'path24',
    source: '2',
    target: '4',
    percent: '86',
    time: 17,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path45',
    source: '4',
    target: '5',
    percent: '86',
    time: 23,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    percent: '86',
    time: 218,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path68',
    source: '6',
    target: '8',
    percent: '86',
    time: 201,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path89',
    source: '8',
    target: '9',
    percent: '61',
    time: 12,
    count: 1393,
    ...GREEN_EDGE,
  },
  {
    id: 'path911',
    source: '9',
    target: '11',
    percent: '86',
    time: 31,
    count: 207,
    ...GREEN_EDGE,
  },
  {
    id: 'path1112',
    source: '11',
    target: '12',
    percent: '86',
    time: 126,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1213',
    source: '12',
    target: '13',
    percent: '86',
    time: 48,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1314',
    source: '13',
    target: '14',
    percent: '86',
    time: 111,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1416',
    source: '14',
    target: '16',
    percent: '86',
    time: 20,
    count: 1962,
    ...GREEN_EDGE,
  },
  // {
  //   id: 'path1516',
  //   source: '15',
  //   target: '16',
  //   percent: '48',
  //   time: 4,
  //   count: 1099,
  //   ...GREEN_EDGE,
  // },
  {
    id: 'path1617',
    source: '16',
    target: '17',
    percent: '48',
    time: 95,
    count: 1099,
    ...GREEN_EDGE,
  },
  {
    id: 'path1718',
    source: '17',
    target: '18',
    percent: '86',
    time: 20,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1819',
    source: '18',
    target: '19',
    percent: '86',
    time: 65,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1920',
    source: '19',
    target: '20',
    percent: '86',
    time: 42,
    count: 1962,
    ...GREEN_EDGE,
  },
]
// }

export const PathfinderNodes = [
  {
    id: '1',
    label: 'ADR Denial from NGS',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 2281,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select Next WQ Item',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '4',
    label: 'Open ADR doc from WQ Item',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open ROI Activity',
    totalTime: '211',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Lookup Patient Visit in ROI Activity',
    totalTime: '429',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '8',
    label: "'Op Note' Only",
    totalTime: '642',
    totalPercent: '62',
    totalCount: '2281',
    impactOnDenial: -86,
    relativeImpactOnCollection: 56,
    absoluteImpactOnCollection: 491.07,
    ...GREEN_NODE,
  },
  {
    id: '9',
    label: 'Document(s) Generated',
    totalTime: '655',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '11',
    label: 'File saved to Local Drive',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '12',
    label: 'Teleport into NGS Portal',
    totalTime: '808',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '14',
    label: 'Navigate to ADR Lookup Form',
    totalTime: '967',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '16',
    label: 'Tab to ADR Document Window',
    totalTime: '991',
    totalPercent: '48',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '17',
    label: 'Copy Lookup Fields from ADR\ndoc to NGS Portal',
    totalTime: '1122',
    totalPercent: '48',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '18',
    label: 'Look up ADR',
    totalTime: '1142',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Upload ADR File',
    totalTime: '1207',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '20',
    label: 'Submit Reconsideration',
    totalTime: '1249',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
]
export const PathfinderEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    percent: '100',
    time: 15,
    count: 2281,
    ...GREEN_EDGE,
  },
  {
    id: 'path24',
    source: '2',
    target: '4',
    percent: '86',
    time: 17,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path45',
    source: '4',
    target: '5',
    percent: '86',
    time: 23,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    percent: '86',
    time: 218,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path68',
    source: '6',
    target: '8',
    percent: '86',
    time: 201,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path89',
    source: '8',
    target: '9',
    percent: '61',
    time: 12,
    count: 1393,
    ...GREEN_EDGE,
  },
  {
    id: 'path911',
    source: '9',
    target: '11',
    percent: '86',
    time: 31,
    count: 207,
    ...GREEN_EDGE,
  },
  {
    id: 'path1112',
    source: '11',
    target: '12',
    percent: '86',
    time: 126,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1214',
    source: '12',
    target: '14',
    percent: '86',
    time: 48,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1416',
    source: '14',
    target: '16',
    percent: '86',
    time: 20,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1617',
    source: '16',
    target: '17',
    percent: '48',
    time: 95,
    count: 1099,
    ...GREEN_EDGE,
  },
  {
    id: 'path1718',
    source: '17',
    target: '18',
    percent: '86',
    time: 20,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1819',
    source: '18',
    target: '19',
    percent: '86',
    time: 65,
    count: 1962,
    ...GREEN_EDGE,
  },
  {
    id: 'path1920',
    source: '19',
    target: '20',
    percent: '86',
    time: 42,
    count: 1962,
    ...GREEN_EDGE,
  },
]
// }

export const FullAutoNodes = [
  {
    id: '1',
    label: 'Log in to EHR',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 2281,
    ...PURPLE_NODE,
  },
  {
    id: '2',
    label: 'Select Next WQ Item',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...PURPLE_NODE,
  },
  {
    id: '3',
    label: 'Open ADR doc from WQ Item',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '4',
    label: 'OCR Document',
    totalTime: '211',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '5',
    label: 'Open ROI for Visit',
    totalTime: '429',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '6',
    label: 'Create and Save Op Note',
    totalTime: '630',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '7',
    label: 'Log in to NG Portal',
    totalTime: '642',
    totalPercent: '62',
    totalCount: '1393',
    ...PURPLE_NODE,
  },
  {
    id: '8',
    label: 'Lookup ADR',
    totalTime: '655',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '9',
    label: 'Upload Files',
    totalTime: '681',
    totalPercent: '86',
    totalCount: '1962',
    ...PURPLE_NODE,
  },
  {
    id: '10',
    label: 'Submit Reconsideration',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '235',
    ...PURPLE_NODE,
  },
]
export const FullAutoEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    percent: '100',
    time: 15,
    count: 2281,
    ...PURPLE_EDGE,
  },
  {
    id: 'path23',
    source: '2',
    target: '3',
    percent: '86',
    time: 17,
    count: 1962,
    ...PURPLE_EDGE,
  },
  {
    id: 'path34',
    source: '3',
    target: '4',
    percent: '86',
    time: 17,
    count: 1962,
    ...PURPLE_EDGE,
  },
  {
    id: 'path45',
    source: '4',
    target: '5',
    percent: '86',
    time: 23,
    count: 1962,
    ...PURPLE_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    percent: '86',
    time: 218,
    count: 1962,
    ...PURPLE_EDGE,
  },
  {
    id: 'path67',
    source: '6',
    target: '7',
    percent: '86',
    time: 201,
    count: 1962,
    ...PURPLE_EDGE,
  },
  {
    id: 'path78',
    source: '7',
    target: '8',
    percent: '61',
    time: 12,
    count: 1393,
    ...PURPLE_EDGE,
  },
  {
    id: 'path89',
    source: '8',
    target: '9',
    percent: '61',
    time: 12,
    count: 1393,
    ...PURPLE_EDGE,
  },
  {
    id: 'path910',
    source: '9',
    target: '10',
    percent: '86',
    time: 31,
    count: 207,
    ...PURPLE_EDGE,
  },
]

export const LowestNodes = [
  {
    id: '1',
    label: 'ADR Denial from NGS',
    totalTime: '0',
    totalPercent: '100',
    totalCount: 2281,
    ...GREEN_NODE,
  },
  {
    id: '2',
    label: 'Select Next WQ Item',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '4a',
    label: 'Transfer Item to alternate WQ',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '2281',
    impactOnDenial: 8,
    relativeImpactOnCollection: -12,
    absoluteImpactOnCollection: -105.23,
    ...RED_NODE,
  },
  {
    id: '4b',
    label: 'Transfer back from alternate WQ',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '2281',
    ...RED_NODE,
  },
  {
    id: 'd2',
    label: 'Select Next WQ Item',
    totalTime: '171',
    totalPercent: '100',
    totalCount: '2281',
    ...ORANGE_NODE,
  },
  {
    id: 'd4',
    label: 'Open ADR Document from WQ Item',
    totalTime: '188',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '5',
    label: 'Open ROI Activity',
    totalTime: '211',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '6',
    label: 'Lookup Patient Visit in ROI Activity',
    totalTime: '429',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '8b',
    label: "No 'Op Note' Selected",
    totalTime: '642',
    totalPercent: '62',
    totalCount: '2281',
    impactOnDenial: 78,
    relativeImpactOnCollection: -48,
    absoluteImpactOnCollection: -420.92,
    ...RED_NODE,
  },
  {
    id: '9',
    label: 'Document(s) Generated',
    totalTime: '655',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '10a',
    label: 'Save to Network Drive',
    totalTime: '681',
    totalPercent: '86',
    totalCount: '2281',
    ...ORANGE_NODE,
  },
  {
    id: '11a',
    label: 'Copy to Local Drive',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '2281',
    ...ORANGE_NODE,
  },
  {
    id: '11',
    label: 'File saved to Local Drive',
    totalTime: '689',
    totalPercent: '10',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '12',
    label: 'Navigate to NGS Portal',
    totalTime: '808',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '13',
    label: 'Log in to NGS Portal',
    totalTime: '967',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '14',
    label: 'Navigate to ADR Lookup Form',
    totalTime: '967',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '16',
    label: 'Tab to ADR Document Window',
    totalTime: '991',
    totalPercent: '48',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '17',
    label: 'Copy Lookup Fields from ADR\ndoc to NGS Portal',
    totalTime: '1122',
    totalPercent: '48',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '18',
    label: 'Look up ADR',
    totalTime: '1142',
    totalPercent: '86',
    totalCount: '1962',
    ...GREEN_NODE,
  },
  {
    id: '19',
    label: 'Upload ADR File',
    totalTime: '1207',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
  {
    id: '20',
    label: 'Submit Reconsideration',
    totalTime: '1249',
    totalPercent: '86',
    totalCount: '2281',
    ...GREEN_NODE,
  },
]
export const LowestEdges = [
  {
    id: 'path12',
    source: '1',
    target: '2',
    ...GREEN_EDGE,
  },
  {
    id: 'path24a',
    source: '2',
    target: '4a',
    ...RED_EDGE,
  },
  {
    id: 'path4a4b',
    source: '4a',
    target: '4b',
    ...RED_EDGE,
  },
  {
    id: 'path4bd2',
    source: '4b',
    target: 'd2',
    ...RED_EDGE,
  },
  {
    id: 'pathd2d4',
    source: 'd2',
    target: 'd4',
    ...ORANGE_EDGE,
  },
  {
    id: 'pathd45',
    source: 'd4',
    target: '5',
    ...GREEN_EDGE,
  },
  {
    id: 'path56',
    source: '5',
    target: '6',
    ...GREEN_EDGE,
  },
  {
    id: 'path68b',
    source: '6',
    target: '8b',
    ...GREEN_EDGE,
  },
  {
    id: 'path8b9',
    source: '8b',
    target: '9',
    ...GREEN_EDGE,
  },
  {
    id: 'path910a',
    source: '9',
    target: '10a',
    ...RED_EDGE,
  },
  {
    id: 'path10a11a',
    source: '10a',
    target: '11a',
    ...RED_EDGE,
  },
  {
    id: 'path11a11',
    source: '11a',
    target: '11',
    ...GREEN_EDGE,
  },
  {
    id: 'path1112',
    source: '11',
    target: '12',
    ...GREEN_EDGE,
  },
  {
    id: 'path1213',
    source: '12',
    target: '13',
    ...GREEN_EDGE,
  },
  {
    id: 'path1314',
    source: '13',
    target: '14',
    ...GREEN_EDGE,
  },
  {
    id: 'path1416',
    source: '14',
    target: '16',
    ...GREEN_EDGE,
  },
  {
    id: 'path1617',
    source: '16',
    target: '17',
    ...GREEN_EDGE,
  },
  {
    id: 'path1718',
    source: '17',
    target: '18',
    ...GREEN_EDGE,
  },
  {
    id: 'path1819',
    source: '18',
    target: '19',
    ...GREEN_EDGE,
  },
  {
    id: 'path1920',
    source: '19',
    target: '20',
    ...GREEN_EDGE,
  },
]

export const IdealData = {
  count: '978',
  frequency: '42.9',
  volume: '1095536.04',
  outstanding: '1120.18',
  payment: '1080.20',
  payment_frequency: '94.8',
  outcome: '1024.03',
  time: 1061,
  fte_cost: 40,
  mean_cost: '11.78',
  net_cost: '1012.25',
  lag: '28',
  variance: 0,
}
export const PathfinderData = {
  count: null,
  frequency: null,
  volume: null,
  outstanding: '1120.18',
  payment: '1080.20',
  payment_frequency: '94.8',
  outcome: '1024.03',
  time: 1061,
  fte_cost: 40,
  mean_cost: '11.78',
  net_cost: '1012.25',
  lag: '28',
  variance: 0,
}
export const PartialAutoData = {
  count: null,
  frequency: null,
  volume: null,
  outstanding: '1120.18',
  payment: '1080.20',
  payment_frequency: '98.3',
  outcome: '1061.84',
  time: 204,
  fte_cost: 40,
  mean_cost: '2.67',
  net_cost: '1059.17',
  lag: '27',
  variance: 0,
}
export const FullAutoData = {
  count: null,
  frequency: null,
  volume: null,
  outstanding: '1120.18',
  payment: '1080.20',
  payment_frequency: '98.3',
  outcome: '1061.84',
  time: 32,
  fte_cost: 0,
  mean_cost: '0',
  net_cost: '1061.84',
  lag: '23',
  variance: 0,
}
export const LongestData = {
  count: '102',
  frequency: '4.5',
  volume: '114258.36',
  outstanding: '1120.18',
  payment: '851.20',
  payment_frequency: '66.1',
  outcome: '562.64',
  time: 773,
  fte_cost: 40,
  mean_cost: '24.59',
  net_cost: '538.05',
  lag: '122',
  variance: '3.4',
}
export const LowestData = {
  count: '88',
  frequency: '3.9',
  volume: '98575.84',
  outstanding: '1120.18',
  payment: '830.12',
  payment_frequency: '49.1',
  outcome: '407.59',
  time: 541,
  fte_cost: 40,
  mean_cost: '22.00',
  net_cost: '358.59',
  lag: '123',
  variance: '3.6',
}
