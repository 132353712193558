import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationStart, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskGroupCardActionsService } from 'app/admin/task-groups/task-group-card-actions.service'
import { CreateTaskGroupComponent } from 'app/pathfinder/task-groups/components/task-group-create/task-group-create.component'
import { TaskGroupsService } from 'app/pathfinder/task-groups/task-groups.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskGroup } from 'generated/graphql'
import { pull } from 'lodash'
import { of, Subscription } from 'rxjs'
import { catchError, debounceTime, filter, tap } from 'rxjs/operators'

/**
 * Component to show and search a list of task groups
 *
 * @export
 * @class TaskGroupSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-task-group-search',
  templateUrl: './task-group-search.component.html',
  styleUrls: ['./task-group-search.component.scss'],
})
export class TaskGroupSearchComponent implements OnInit, OnDestroy {
  @Input() showPencil = ''
  @Input() showRight = ''
  @Input() showRemove = ''
  @Output() onTaskGroupCreate = new EventEmitter<TaskGroup>()

  taskGroups: TaskGroup[]
  taskGroupSearch = new UntypedFormControl('')
  searching: boolean = false
  totalTaskGroups: number
  taskGroupSearchSub$: Subscription

  isCollapsed: boolean[] = []

  routerSub$: Subscription
  hideSub$: Subscription

  constructor(
    private router: Router,
    private taskgroupService: TaskGroupsService,
    private toast: ToastService,
    private modal: NgbModal,
    public actions: TaskGroupCardActionsService,
  ) {
    this.routerSub$ = router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      this.onRouteChange()
    })
    this.hideSub$ = actions.hideCard$.subscribe(({ id, hide }) => {
      if (hide) {
        this.actions.hidden.push(id)
      } else {
        pull(this.actions.hidden, id)
      }
    })
  }

  /**
   * Possibly reload the current search
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupSearchComponent
   */
  async onRouteChange(): Promise<void> {
    let reloadSearch = this.router.getCurrentNavigation().extras?.state?.data?.reloadSearch
    if (reloadSearch) {
      this.taskGroupSearch.updateValueAndValidity({ onlySelf: true, emitEvent: true })
    }
  }

  async ngOnInit(): Promise<void> {
    debug('task-group-search', 'ngOnInit')
    this.taskGroupSearchSub$ = this.taskGroupSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => (this.searching = true)),
        tap(async (term) => await this.searchTaskGroups(term)),
        catchError((e) => {
          return of([])
        }),
      )
      .subscribe()

    await this.searchTaskGroups('')
  }

  ngOnDestroy(): void {
    debug('task-group-search', 'ngOnDestroy')
    this.taskGroupSearchSub$?.unsubscribe()
    this.routerSub$?.unsubscribe()
    this.hideSub$?.unsubscribe()
  }

  /**
   * Load a list of task groups
   *
   * @param {string} term
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupSearchComponent
   */
  async searchTaskGroups(term: string): Promise<void> {
    this.searching = true
    try {
      this.taskGroups = await this.taskgroupService.searchTaskGroups(term, null, undefined, undefined, true)
      this.totalTaskGroups = this.taskgroupService.getMeta()?.total
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find task groups'), JSON.stringify(e))
    }
    this.searching = false
    this.isCollapsed = new Array(this.taskGroups?.length).fill(true)
  }

  /**
   * Launch modal to allow creating a new task group
   *
   * @memberof TaskGroupSearchComponent
   */
  createTaskGroup(): void {
    let modalRef = this.modal.open(CreateTaskGroupComponent, {
      size: 'lg',
      centered: true,
    })
    modalRef.result.then(
      (result: TaskGroup) => {
        this.taskGroupSearch.updateValueAndValidity({ onlySelf: true, emitEvent: true })
        this.onTaskGroupCreate.emit(result)
      },
      () => {},
    )
  }
}
