import { Component, OnInit } from '@angular/core'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'
import { FeatureFlagService, FeatureFlags } from 'app/shared/services/feature-flag.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'

/**
 * Dashboard page. Shows embedded Tableau if the feature toggle is turned on
 *
 * @export
 * @class DashboardPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class DashboardPage implements OnInit {
  readonly quicksightDashboardType = QuicksightDashboardType.Standard
  dashboardMode: 'tableau' | 'quicksight' | 'error'
  activeTabId = 1
  constructor(private featureFlagService: FeatureFlagService, protected zendesk: ZendeskService) {}

  async ngOnInit(): Promise<void> {
    const [quicksightDashboard, tableauDashboard] = await Promise.all([
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.quicksightDashboard.root),
      this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.tableauDashboard),
    ])

    if (quicksightDashboard) {
      this.dashboardMode = 'quicksight'
    } else if (tableauDashboard) {
      this.dashboardMode = 'tableau'
    } else {
      this.dashboardMode = 'error'
    }
  }
}
