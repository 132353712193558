import { Component, EventEmitter, OnDestroy, Output, TemplateRef } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { IlluminateService } from 'app/illuminate-v2/illuminate.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { IlSelectedPath, User } from 'generated/graphql'
import { isEqual } from 'lodash'
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-illuminate-graph-panel-toolbar',
  templateUrl: './illuminate-graph-panel-toolbar.component.html',
  styleUrls: ['./illuminate-graph-panel-toolbar.component.scss'],
})
export class IlluminateGraphPanelToolbarComponent implements OnDestroy {
  workflowGroupingId: string = null

  @Output() onPanelOpen = new EventEmitter()

  @Output() onSelectPathMode = new EventEmitter<boolean>()

  selectedPath: { name: string; id: string } = null

  selectedPaths: IlSelectedPath[] = []
  isSelectingPath: boolean = false

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
  })

  selectedUsersLabel = 'All'
  selectedUsers: User[] = []
  selectedUserIds: string[] = []
  allowedUserIds: string[] = []

  selectedDatesLabel = 'All time'
  selectedDates = {
    from: '',
    to: '',
  }

  destroy$ = new Subject<void>()

  /**
   * Notify parent of changed filters
   *
   * @private
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  private emit(): void {
    this.illuminate.filters.next({
      path: this.selectedPath,
      users: {
        possible: this.illuminate.filters.value?.users?.possible,
        selected: this.selectedUsers,
      },
      dates: {
        possible: this.illuminate.filters.value?.dates?.possible,
        selected: {
          startAt: this.selectedDates.from,
          endAt: this.selectedDates.to,
        },
      },
    })
  }

  /**
   * Set current path
   *
   * @param {string} str
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  setPath({ name, id }: { name: string; id: string }): void {
    this.selectedPath = { name, id }
    this.emit()
  }

  /**
   * Add user to filters
   *
   * @param {User} user
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  addUser(user: User): void {
    if (user?.id) {
      this.selectedUsers = [...this.selectedUsers, user]
      this.selectedUserIds = this.selectedUsers.map((u) => u.id)
      this.emit()
    }
  }

  /**
   * Remove user from filters
   *
   * @param {User} user
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  removeUser(user: User): void {
    let i = this.selectedUsers.findIndex((u) => u.id === user.id)
    let tmp = this.selectedUsers.slice()
    tmp.splice(i, 1)
    this.selectedUsers = tmp.slice()
    this.selectedUserIds = tmp.map((u) => u.id)
    this.emit()
  }

  /**
   * Add date filter
   *
   * @param {{ from: string; to: string }} { from, to }
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  setDates({ from, to }: { from: string; to: string }): void {
    setTimeout(() => {
      // daterange-picker component emits too quickly, so wait until the next paint
      if (!isEqual(this.selectedDates, { from, to })) {
        if (from?.length && to?.length) {
          this.selectedDates = { from, to }
          this.selectedDatesLabel = `${from} - ${to}`
          this.emit()
        }
      }
    })
  }

  /**
   * Set all filters to original
   *
   * @memberof IlluminateGraphPanelToolbarComponent
   */
  resetFilters(): void {
    this.illuminate.filters.next({
      path: { id: null, name: 'Baseline' },
      users: {
        possible: this.illuminate.filters.value?.users?.possible,
        selected: this.illuminate.filters.value?.users?.possible,
      },
      dates: {
        possible: this.illuminate.filters.value?.dates?.possible,
        selected: this.illuminate.filters.value?.dates?.possible,
      },
    })
  }

  selectPathMode(isStarting: boolean): void {
    this.isSelectingPath = isStarting
    this.onSelectPathMode.emit(isStarting)
  }

  saveSelectedPath(modal: TemplateRef<NgbModal>): void {
    let modalRef = this.modal.open(modal, {
      centered: true,
      size: 'lg',
    })
    modalRef.result.then(
      async (name: string) => {
        try {
          let createdPath = await this.illuminate.createSelectedPath({
            name: name,
            workflowGroupingId: this.workflowGroupingId,
            pathId: this.selectedPath?.id,
          })
          this.selectPathMode(false)
          this.setPath({ name, id: createdPath?.pathId })
        } catch (e) {
          this.toast.error(parseGraphQLError(e, 'Could not save selected path'), JSON.stringify(e))
        }
      },
      () => {},
    )
  }

  confirmDeletePath(pathId: string): boolean {
    let confirm = this.modal.open(ConfirmModalComponent, { centered: true })

    confirm.componentInstance.title = 'Delete User Created Path?'
    confirm.componentInstance.yes = 'Delete'
    confirm.componentInstance.yesClass = 'btn-danger'

    confirm.result.then(
      async (confirmed) => {
        try {
          await this.illuminate.deleteSelectedPath(pathId)
          this.toast.success('Deleted user created path')
          this.setPath({ name: null, id: null })
        } catch (e) {
          this.toast.error(parseGraphQLError(e, 'Could not delete user created path'), JSON.stringify(e))
        }
      },
      () => {},
    )
    return false
  }

  constructor(private illuminate: IlluminateService, private toast: ToastService, private modal: NgbModal) {
    this.illuminate.currentWorkflowGroup
      .pipe(
        filter((workflowGroup) => workflowGroup !== null),
        takeUntil(this.destroy$),
      )
      .subscribe((workflowGroup) => {
        this.workflowGroupingId = workflowGroup?.id
        this.illuminate
          .getSelectedPaths(this.workflowGroupingId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            if (data?.data?.ilSelectedPaths && !data?.error && !data?.errors) {
              this.selectedPaths = data?.data?.ilSelectedPaths
            } else {
              this.toast.error(
                parseGraphQLError(event, 'Could not load saved paths'),
                JSON.stringify(data?.error || data?.errors),
              )
              this.selectedPaths = []
            }
          })
      })

    this.illuminate.filters
      .pipe(
        filter((filters) => filters !== null),
        takeUntil(this.destroy$),
      )
      .subscribe((filters) => {
        const { path, users, dates } = filters
        this.selectedPath = path

        this.selectedUsers = [...users?.selected]
        this.selectedUserIds = users?.selected?.map((u) => u.id)
        this.allowedUserIds = users?.possible?.map((user) => user.id)

        this.selectedDates = { from: dates?.selected?.startAt, to: dates?.selected?.endAt }

        if (isEqual(dates.selected, dates.possible)) {
          this.selectedDatesLabel = 'All Time'
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
