import { Component, OnInit, OnDestroy } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ObserveAccessControl, ObserveUsageSummary } from 'generated/graphql'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-observe-access-control-page',
  templateUrl: './observe-access-control.page.html',
})
export class ObserveAccessControlPage implements OnInit, OnDestroy {
  timeFrame = new UntypedFormControl(1)
  accessControlSearchText = new UntypedFormControl('')
  regexTest = new UntypedFormControl('')
  showEditControl = false
  debouncedRegexTest: string
  destroy$ = new Subject<void>()
  inputRule: ObserveAccessControl | ObserveUsageSummary | null = null

  ngOnInit(): void {
    this.regexTest.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.debouncedRegexTest = value
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  toggleEditControlSummary(summary: ObserveUsageSummary): void {
    if (summary) {
      this.inputRule = summary
    }
    this.displayEditControl()
  }

  toggleEditControlACL(accessControl?: ObserveAccessControl): void {
    if (accessControl) {
      this.inputRule = accessControl
    } else {
      this.inputRule = null
    }
    this.displayEditControl()
  }

  displayEditControl(): void {
    this.showEditControl = true
  }

  closeEditControl(): void {
    this.showEditControl = false
    this.inputRule = null
  }
}
