import { Directive, Input } from '@angular/core'
import { AbstractControl, UntypedFormControl } from '@angular/forms'

/**
 * Angular directive to set bootstrap classes based on Reactive Form status
 *
 * @export
 * @class FcBsValidationDirective
 */
@Directive({
  selector: '[appFcBsValidation]',
  host: {
    '[class.is-valid]': 'touched() && !isInvalid()',
    '[class.is-invalid]': 'isInvalid()',
  },
})
export class FcBsValidationDirective {
  @Input() appFcBsValidation: UntypedFormControl | AbstractControl

  constructor() {}

  touched(): boolean {
    return this.appFcBsValidation.touched
  }

  isInvalid(): boolean {
    return (this.appFcBsValidation.touched || this.appFcBsValidation.dirty) && this.appFcBsValidation.invalid
  }
}
