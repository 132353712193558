import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core'
import { AbstractControl, UntypedFormControl } from '@angular/forms'

/**
 * Component to show a search input field
 *
 * @export
 * @class SearchFieldComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
})
export class SearchFieldComponent implements OnInit {
  @Input() label: string = ''
  @Input() search: UntypedFormControl | AbstractControl = null
  @Input() searching: boolean = false
  @Output() onEnterPressed?: EventEmitter<string> = new EventEmitter<string>()

  get searchFc(): UntypedFormControl {
    return this.search as UntypedFormControl
  }

  enterKeyHandler(): void {
    this.onEnterPressed.emit(this.search.value?.trim() ?? '')
  }

  clearInput(): void {
    this.search.setValue('')
    this.enterKeyHandler()
  }

  constructor() {}

  ngOnInit(): void {}
}
