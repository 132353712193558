import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo, QueryRef } from 'apollo-angular'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FullClaimQuery } from 'app/claims/claim-detail/claim-detail.gql'
import { GetTaskGroupObjects } from 'app/pathfinder/task-groups/task-groups.gql'
import { CreateTaskCommentMutation, GetTaskCommentsQuery } from 'app/pathfinder/tasks/task-comments.gql'
import {
  CompleteTaskMutation,
  CompleteWorkflowTaskMutation,
  CreateTaskMutation,
  CreateTasksMutation,
  DeleteTasksMutation,
  PickTaskMutation,
  UncompleteTaskMutation,
  UnpickAllTasksMutation,
} from 'app/pathfinder/tasks/task-mutations.gql'
import { GetTaskListQuery, GetTaskQuery } from 'app/pathfinder/tasks/task-queries.gql'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import {
  BulkTaskCreateResponse,
  BulkTaskDeleteResponse,
  Claim,
  CompletionType,
  CreateTaskInput,
  ListResponseMetaData,
  MutationCompleteTaskArgs,
  MutationCompleteWorkflowTaskArgs,
  MutationCreateTaskArgs,
  MutationCreateTaskCommentArgs,
  MutationCreateTasksArgs,
  MutationDeleteTasksArgs,
  MutationPickTaskArgs,
  MutationUncompleteTaskArgs,
  QueryClaimArgs,
  QueryTaskArgs,
  QueryTaskCommentsArgs,
  QueryTaskGroupObjectsArgs,
  QueryTasksArgs,
  Task,
  TaskComment,
  TaskGroupObjectList,
  TaskList,
  User,
  Workflow,
} from 'generated/graphql'
import { cloneDeep, orderBy } from 'lodash'
import * as moment from 'moment'
import { Observable } from 'rxjs'

export type SaturatedTaskComment = {
  comment: TaskComment
  user: User
}

/**
 * Handle task and task comment operations
 *
 * @export
 * @class TasksService
 */
@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private meta: ListResponseMetaData

  constructor(
    private apollo: Apollo,
    private userService: UsersService,
    private authenticationService: AuthenticationService,
  ) {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*} {ListResponseMetaData}
   * @memberof TasksService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve an individual task based on ID
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ task: Task }>>}
   * @memberof TasksService
   */
  getTask(id: string): Observable<ApolloQueryResult<{ task: Task }>> {
    debug('task-service', 'get task', id)
    return this.apollo.watchQuery<{ task: Task }, QueryTaskArgs>({
      query: GetTaskQuery,
      variables: {
        id: id,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of tasks based on task group and status
   *
   * @param {QueryTasksArgs} vars
   * @return {*}  {Observable<ApolloQueryResult<{ tasks: TaskList }>>}
   * @memberof TasksService
   */
  getTasks(vars: QueryTasksArgs): Observable<ApolloQueryResult<{ tasks: TaskList }>> {
    debug('task-service', 'get tasks')
    let { taskGroupId, completed, offset, limit, sort } = vars

    let variables = {
      taskGroupId: taskGroupId,
      offset: offset || 0,
      limit: limit || 99,
      sort: sort || 'completionType:desc,claim.outstandingAmount:desc',
    }
    if (completed !== null && completed !== undefined) {
      variables['completed'] = completed
    }
    return this.apollo.watchQuery<{ tasks: TaskList }, QueryTasksArgs>({
      query: GetTaskListQuery,
      variables: variables,
    }).valueChanges
  }

  /**
   * Retrieve a list of tasks and workflows based on task group and status
   *
   * @param {QueryTaskGroupObjectsArgs} vars
   * @return {*}  {QueryRef<{ taskGroupObjects: TaskGroupObjectList }, QueryTaskGroupObjectsArgs>}
   * @memberof TasksService
   */
  getTaskGroupObjects(
    vars: QueryTaskGroupObjectsArgs,
  ): QueryRef<{ taskGroupObjects: TaskGroupObjectList }, QueryTaskGroupObjectsArgs> {
    debug('task-service', 'get taskgroup objects', vars)
    let { taskGroupId, completed, offset, limit } = vars

    let variables = {
      taskGroupId: taskGroupId,
      offset: offset || 0,
      limit: limit || 99,
      //sort: sort || 'completionType:desc,claim.outstandingAmount:desc',
    }
    if (completed !== null && completed !== undefined) {
      variables['completed'] = completed
    }
    return this.apollo.watchQuery<{ taskGroupObjects: TaskGroupObjectList }, QueryTaskGroupObjectsArgs>({
      query: GetTaskGroupObjects,
      variables: variables,
      fetchPolicy: 'network-only',
    })
  }

  /**
   * Retrieve a task's comments
   *
   * @param {string} taskId
   * @return {*}  {Observable<ApolloQueryResult<{ taskComments: TaskComment[] }>>}
   * @memberof TasksService
   */
  getTaskComments(taskId: string): Observable<ApolloQueryResult<{ taskComments: TaskComment[] }>> {
    debug('task-service', 'get task comments', taskId)
    return this.apollo.watchQuery<{ taskComments: TaskComment[] }, QueryTaskCommentsArgs>({
      query: GetTaskCommentsQuery,
      variables: {
        taskId: taskId,
      },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  /**
   * Helper to add full user objects and format date to a task's comments
   *
   * @param {TaskComment[]} tcs
   * @return {*}  {Promise<SaturatedTaskComment[]>}
   * @memberof TasksService
   */
  async saturateTaskComments(tcs: TaskComment[]): Promise<SaturatedTaskComment[]> {
    return Promise.all(
      tcs.map(async (tc) => {
        let copied = Object.assign({}, tc)
        let user = null
        if (copied.userId !== null) {
          user = await this.userService.getUser(copied.userId)
        }
        if (copied.createdAt) {
          copied.createdAt = moment(copied.createdAt, ['x', moment.ISO_8601]).toISOString()
        }
        return { comment: copied, user: user }
      }),
    )
  }

  /**
   * Complete or Reject a task
   *
   * @param {string} id
   * @param {boolean} [reject=false]
   * @return {*}  {Promise<string>}
   * @memberof TasksService
   */
  async completeTask(id: string, reject: boolean = false): Promise<string> {
    debug('task-service', 'complete task', id, reject)
    let vars: MutationCompleteTaskArgs = {
      id: id,
    }
    if (reject) {
      vars.completionType = CompletionType.Rejected
    }
    let result = await this.apollo
      .mutate<{ completeTask: Task }, MutationCompleteTaskArgs>({
        mutation: CompleteTaskMutation,
        variables: vars,
      })
      .toPromise()

    return result.data.completeTask?.id
  }

  /**
   * Complete a workflow's task with an outcome
   *
   * @param {string} taskId
   * @param {number} [workflowTaskOutcomeIndex=null]
   * @return {*}  {Promise<Workflow>}
   * @memberof TasksService
   */
  async completeWorkflowTask(taskId: string, workflowTaskOutcomeIndex: number = null): Promise<Workflow> {
    debug('task-service', 'completeWorkflowTask', taskId, workflowTaskOutcomeIndex)
    let result = await this.apollo
      .mutate<{ completeWorkflowTask: Workflow }, MutationCompleteWorkflowTaskArgs>({
        mutation: CompleteWorkflowTaskMutation,
        variables: { taskId, workflowTaskOutcomeIndex },
      })
      .toPromise()
    return result.data.completeWorkflowTask
  }

  /**
   * Uncomplete / reopen a task
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof TasksService
   */
  async unCompleteTask(id: string): Promise<string> {
    debug('task-service', 'uncomplete task', id)
    let result = await this.apollo
      .mutate<{ uncompleteTask: Task }, MutationUncompleteTaskArgs>({
        mutation: UncompleteTaskMutation,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.uncompleteTask?.id
  }

  /**
   * Pick a task by the current user
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof TasksService
   */
  async pickTask(id: string): Promise<string> {
    debug('task-service', 'pick task', id)
    if (!id) return null
    let result = await this.apollo
      .mutate<{ pickTask: Task }, MutationPickTaskArgs>({
        mutation: PickTaskMutation,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data.pickTask?.id
  }

  /**
   * Unpick all tasks from the current user
   *
   * @return {*}  {Promise<boolean>}
   * @memberof TasksService
   */
  async unpickAllTasks(): Promise<boolean> {
    debug('task-service', 'unpick all tasks')
    let result = await this.apollo
      .mutate<{ unpickAllTasks: boolean }>({
        mutation: UnpickAllTasksMutation,
      })
      .toPromise()

    return result.data.unpickAllTasks
  }

  /**
   * Add a task to a claim
   *
   * @param {CreateTaskInput} task
   * @return {*}  {Promise<Task>}
   * @memberof TasksService
   */
  async createTask(task: CreateTaskInput): Promise<Task> {
    let result = await this.apollo
      .mutate<{ createTask: Task }, MutationCreateTaskArgs>({
        mutation: CreateTaskMutation,
        variables: {
          data: task,
        },
        update: (store, { data: { createTask } }) => {
          const updatedClaim = cloneDeep(
            store.readQuery<{ claim: Claim }, QueryClaimArgs>({
              query: FullClaimQuery,
              variables: {
                providerClaimId: task.providerClaimId,
              },
            }),
          )
          updatedClaim.claim.tasks = [...updatedClaim.claim.tasks, createTask]
          store.writeQuery({
            query: FullClaimQuery,
            variables: {
              providerClaimId: task.providerClaimId,
            },
            data: updatedClaim,
          })
        },
      })
      .toPromise()

    return result.data.createTask
  }

  /**
   * Add multiple tasks to a claim
   *
   * @param {CreateTaskInput[]} tasks
   * @param {boolean} [allowDuplicates=false]
   * @return {*}  {Promise<BulkTaskCreateResponse[]>}
   * @memberof TasksService
   */
  async createTasks(tasks: CreateTaskInput[], allowDuplicates = false): Promise<BulkTaskCreateResponse[]> {
    debug('task-service', 'create task', tasks)
    let result = await this.apollo
      .mutate<{ createTasks: BulkTaskCreateResponse[] }, MutationCreateTasksArgs>({
        mutation: CreateTasksMutation,
        variables: {
          allowDuplicates: allowDuplicates,
          data: tasks,
        },
      })
      .toPromise()

    return result.data.createTasks
  }

  /**
   * Add a comment to a task
   *
   * @param {string} comment
   * @param {string} taskId
   * @return {*}  {Promise<void>}
   * @memberof TasksService
   */
  async createTaskComment(comment: string, taskId: string): Promise<void> {
    debug('task-service', 'create task comment', taskId, comment)
    await this.apollo
      .mutate<{ createTaskComment: TaskComment }, MutationCreateTaskCommentArgs>({
        mutation: CreateTaskCommentMutation,
        variables: {
          comment: comment,
          taskId: taskId,
        },
        optimisticResponse: {
          createTaskComment: {
            __typename: 'TaskComment',
            id: null,
            userId: this.authenticationService.getUser()?.id,
            taskId: taskId,
            comment: comment,
            createdAt: new Date().toString(),
          },
        },
        update: (store, { data: { createTaskComment } }) => {
          const data = store.readQuery<{ taskComments: TaskComment[] }, QueryTaskCommentsArgs>({
            query: GetTaskCommentsQuery,
            variables: {
              taskId: taskId,
            },
          })
          const comments = [...data.taskComments, createTaskComment]
          store.writeQuery({
            query: GetTaskCommentsQuery,
            variables: {
              taskId: taskId,
            },
            data: { comments },
          })
        },
      })
      .toPromise()
  }

  /**
   * Remove tasks from a claim
   *
   * @param {string[]} taskIds
   * @return {*}  {Promise<BulkTaskDeleteResponse>}
   * @memberof TasksService
   */
  async deleteTasks(taskIds: string[]): Promise<BulkTaskDeleteResponse> {
    debug('task-service', 'delete tasks')
    let result = await this.apollo
      .mutate<{ deleteTasks: BulkTaskDeleteResponse }, MutationDeleteTasksArgs>({
        mutation: DeleteTasksMutation,
        variables: {
          taskIds: taskIds,
        },
        update: (store) => {
          let cache = this.apollo.client.cache
          taskIds.forEach((id) => {
            cache.evict({ id: cache.identify({ id: id, __typename: 'Task' }) })
          })
          cache.gc()
        },
      })
      .toPromise()

    return result.data.deleteTasks
  }

  /**
   * Helper to order tasks for display
   *
   * @param {Task[]} tasks
   * @return {*}  {Task[]}
   * @memberof TasksService
   */
  sortTasks(tasks: Task[]): Task[] {
    return orderBy(tasks, ['createdAt'], ['asc'])
  }
}
