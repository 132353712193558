<tr
  class="text-center clickable"
  (click)="contactPayerModalAppear()"
  role="button"
  tabindex="0"
  [attr.aria-label]="'Move Procedure to Auth Required'"
  [ngbTooltip]="tooltip"
  container="body"
  placement="bottom"
>
  <td class="teleport-icon rounded-left-lg">
    <app-janus-icon icon="teleport"></app-janus-icon>
  </td>
  <td>
    {{ config.serviceDates.startDate | date: 'MM/dd/yyyy':'+0000'
    }}<span *ngIf="config.serviceDates.endDate && config.serviceDates.startDate !== config.serviceDates.endDate"
      >&ndash;{{ config.serviceDates.endDate | date: 'MM/dd/yyyy':'+0000' }}</span
    >
  </td>
  <td class="text-left">
    {{ config?.payer?.name || config?.typeDef.payerPlan?.payerName }}
  </td>
  <td>
    {{ (config.epicReferralId) ? config.epicReferralId : '' }}
  </td>
  <td class="rounded-right-lg">
    <div *ngFor="let procedure of config.typeDef.procedures">
        <ng-template [ngIf]="procedure && procedure?.status !== 'NA'">
          {{ procedure?.code }}
          <ng-template [ngIf]="procedure?.description"> - {{ procedure.description }}</ng-template>
        </ng-template>
    </div>
  </td>
</tr>
