<div class="modal-header p-4pl-5">
  <h5 class="modal-title">Launch Bot</h5>
  <button type="button" class="close font-weight-bold" aria-label="Close" (click)="handleModalClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-5" style="height: 80vh">
  <div class="d-flex flex-row h-100 w-100">
    <div class="form-group h-100" style="width: 40%">
      <label for="branchList" class="sr-only">Select Branch</label>
      <app-text-typeahead
        [items]="branches"
        [readonly]="!!selectedBranch.value"
        (onSelect)="selectBranch($event)"
        [clearInput]="false"
        [placeholder]="selectedBranch.value?.branch ?? 'Branch Name'"
        [resultFormatter]="branchResultFormatter"
        [inputFormatter]="branchInputFormatter"
        [searchFilter]="branchSearchFilter"
        id="branchList"
      ></app-text-typeahead>
      <div class="p-2"></div>
      <button
        [disabled]="!selectedBranch.value"
        (click)="deployBranch()"
        class="btn btn-brand-green px-5"
        data-t="change-org-button"
      >
        Deploy
      </button>
      <div class="p-2"></div>
      <label for="botJobList" class="sr-only">Select BotJob</label>
      <app-text-typeahead
        [items]="botJobs"
        [readonly]="!!selectedBotJob.value"
        (onSelect)="selectBotJob($event)"
        [clearInput]="false"
        [placeholder]="selectedBotJob.value?.name ?? 'BotJob'"
        [resultFormatter]="botJobResultFormatter"
        [inputFormatter]="botJobInputFormatter"
        [searchFilter]="botJobSearchFilter"
        id="botJobList"
      ></app-text-typeahead>
      <div class="p-2"></div>
      <ng-container *ngIf="selectedBotJob.value">
        <small class="form-text text-muted">
          Branch deployed: <strong *ngIf="!deploying" class="text-brand-dark-blue">{{ deployedBranchName }}</strong
          ><strong *ngIf="deploying" class="text-brand-dark-blue">Deploying...</strong>
        </small>
        <div class="p-1"></div>
        <small class="form-text text-muted">
          botType: <strong class="text-brand-dark-blue">getClaimStatus</strong>
        </small>
        <div class="p-1"></div>
        <small class="form-text text-muted">
          Claim ID: <strong class="text-brand-dark-blue">{{ runBotOpts?.claimId }}</strong>
        </small>
        <div class="p-1"></div>
        <small class="form-text text-muted">
          Org ID: <strong class="text-brand-dark-blue">{{ runBotOpts?.orgId }}</strong>
        </small>
        <div class="p-1"></div>
        <small class="form-text text-muted">
          Portal: <strong class="text-brand-dark-blue">{{ runBotOpts?.portal }}</strong>
        </small>
        <div class="p-1"></div>

        <small class="form-text text-muted" *ngIf="isLegacyPayerPlan">
          Payer: <strong class="text-brand-dark-blue">{{ runBotOpts?.payer }}</strong>
        </small>
        <small class="form-text text-muted border-top border-bottom" *ngIf="!isLegacyPayerPlan">
          <ng-container>
            <div *ngIf="!payerPlan" class="text-bold pt-2 pb-2" style="color: red">Payer/Plan not found.</div>
            <div *ngIf="payerPlan">
              <div class="text-bold">Payer/Plan</div>
              <div class="pl-4">
                <div>
                  Name: <strong class="text-brand-dark-blue">{{ payerPlan?.payerName }}</strong>
                </div>
                <div>
                  Code: <strong class="text-brand-dark-blue">{{ payerPlan?.payerCode }}</strong>
                </div>
                <div>
                  Plan: <strong class="text-brand-dark-blue">{{ payerPlan?.planName }}</strong>
                </div>
              </div>
            </div>
          </ng-container>
        </small>
        <div class="p-1"></div>
        <small class="form-text text-muted">
          credentialType: <strong class="text-brand-dark-blue">{{ runBotOpts?.credentialType }}</strong>
        </small>
        <div class="p-1"></div>
        <div class="p-1"></div>
        <button
          [disabled]="startButtonDisabled()"
          (click)="startBot()"
          class="btn btn-brand-green px-5"
          data-t="change-org-button"
        >
          Run
        </button>
      </ng-container>
      <small class="form-text" style="color: red">
        <strong class="text-red">{{ warningMessage }}</strong>
      </small>
      <div class="my-5">
        <div ngbDropdown class="mr-4">
          <button
            [disabled]="!selectedBranch.value"
            type="button"
            class="btn border border-alt-light-gray"
            id="date-presets"
            ngbDropdownToggle
          >
            {{ output.name }}
          </button>
          <div id="presets-dropdown-container" ngbDropdownMenu aria-labelledby="BotQaOutputDropdown">
            <button ngbDropdownItem (click)="onSelectOutput({ name: 'logs' })">logs</button>
            <button ngbDropdownItem (click)="onSelectOutput({ name: 'result' })">result</button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-4"></div>
    <div class="h-100 w-100" style="overflow: scroll; font-family: monospace; font-size: 13px">
      <div *ngIf="output.name === 'logs'">
        <p class="pv-1" *ngFor="let log of logs">
          {{ log }}
        </p>
      </div>
      <div *ngIf="output.name === 'result'">
        <pre>{{ translate | json }}</pre>
      </div>
    </div>
  </div>
</div>
