<div ngbDropdown>
  <a role="button" class="nav-link p-0" id="userDropdown" ngbDropdownToggle>
    <app-user-avatar [user]="authenticationService.getUser()" [invertColor]="true"></app-user-avatar>
  </a>
  <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu dropdown-menu-right border-0 shadow-sm mt-0">
    <a routerLink="/policies/privacy-policy" ngbDropdownItem>Privacy Policy</a>
    <a routerLink="/policies/terms-of-use" ngbDropdownItem>Terms of Use</a>
    <button type="button" ngbDropdownItem (click)="openOrgChangeModal()">Change Org</button>
    <button type="button" ngbDropdownItem (click)="authenticationService.logout()">Logout</button>
  </div>
</div>
