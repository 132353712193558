import { AfterViewInit, Component, HostListener, OnDestroy } from '@angular/core'
import { SubscriptionService } from 'app/shared/services/subscription.service'
import { OrgService } from './admin/org/org.service'
import { AuthenticationService } from './auth/authentication.service'
import { ToastService } from './shared/services/toast.service'

declare let ClipboardJS: any

/**
 * Main application component
 *
 * @export
 * @class AppComponent
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'janus-ui'
  loading: boolean = true
  clipboard: any

  constructor(
    private subs: SubscriptionService,
    private authenticationService: AuthenticationService,
    private org: OrgService,
    private toast: ToastService,
  ) {
    subs.init() // set up app wide websocket subscriptions
  }

  ngAfterViewInit(): void {
    this.clipboard = new ClipboardJS('.copy-btn')
    this.clipboard.on('success', (e) => {
      this.toast.success('Copied to clipboard')
    })
  }

  ngOnDestroy(): void {
    this.clipboard?.destroy()
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      const $activeItems = document.querySelectorAll('ngb-typeahead-window.show > .dropdown-item.active')
      $activeItems.forEach(($item) => $item.scrollIntoView())
    }
  }
}
