<div class="w-100 bg-white" data-id="filter-dropdown" ngbDropdown container="body">
  <button
    class="btn d-flex justify-content-between rotate-on-open align-items-center border-alt-light-gray text-alt-medium-gray w-100"
    [ngClass]="{ 'btn-md btn-light rounded border-1 no-dropdown-arrow': iconButton }"
    ngbDropdownToggle
    aria-label="Filter Options"
  >
    <div class="overflow-hidden" *ngIf="!iconButton; else icon_button">{{ placeholder }}</div>
    <ng-template #icon_button>
      <i class="fa fa-filter" *ngIf="iconButton"></i><span class="ml-1">{{ selectedFilterCount }}</span>
    </ng-template>
  </button>
  <div ngbDropdownMenu class="checkbox-filter-container minw-20vw maxw-100vw text-center">
    <div class="d-flex justify-content-between px-3">
      <button data-id="filter-dropdown-select-all" type="button" class="btn btn-link p-0" (click)="setAllToValue(true)">
        <span class="fs-14">Select all</span>
      </button>
      <button data-id="filter-dropdown-clear-all" type="button" class="btn btn-link p-0" (click)="setAllToValue(false)">
        <span class="fs-14">Select none</span>
      </button>
    </div>
    <div
      class="pt-0 pr-3 pl-3 pb-3"
      *ngFor="let filterFormGroup of filterFormGroups; let i = index"
      [formGroup]="filterFormGroup"
    >
      <div class="dropdown-divider"></div>
      <div class="custom-control custom-checkbox d-flex justify-content-start" *ngFor="let filter of filters[i]">
        <input
          [attr.data-t]="'filter-' + filter.filterName"
          type="checkbox"
          class="custom-control-input"
          [attr.id]="filter.filterName"
          [formControlName]="filter.filterName"
        />
        <label class="custom-control-label" [for]="filter.filterName">{{ filter.title }}</label>
      </div>
    </div>
  </div>
</div>
