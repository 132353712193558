const CAP = 10000 // 10 seconds
const BASE = 500 // half second

/**
 * Calculate intermittent delay for retrying network calls
 *
 * @param {number} lastJitter
 * @param {number} attempt
 * @return {*} {number}
 */
export const jitter = (lastJitter: number, attempt: number): number => {
  return Math.floor(Math.min(CAP, Math.random() * (lastJitter * attempt - BASE) + BASE))
}
