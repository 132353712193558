import { Pipe, PipeTransform } from '@angular/core'

/**
 * Angular pipe to format JSON data
 *
 * @export
 * @class PrettyPrintPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'prettyprint',
})
export class PrettyPrintPipe implements PipeTransform {
  transform(val: string): string {
    return JSON.stringify(val, undefined, 4).replace(/ /g, '&nbsp;').replace(/\n/g, '<br/>')
  }
}
