import { Component, Input, TemplateRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FeatureFlags, FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { getPropDetails } from 'app/shared/types/claim'
import { Claim } from 'generated/graphql'
import { BotModal } from '../bot-modal/bot-modal.component'

/**
 * Component to display a claim's key details
 *
 * @export
 * @class ClaimKeyDetailsComponent
 */
@Component({
  selector: 'app-claim-key-details',
  templateUrl: './claim-key-details.component.html',
  styleUrls: ['./claim-key-details.component.scss'],
})
export class ClaimKeyDetailsComponent {
  @Input() claim: Claim = null
  public isSuper: boolean = false
  public nodeBotAllowed: boolean = false
  public isQediEnabled: boolean = false

  getPropDetails = getPropDetails

  constructor(
    public org: OrgService,
    private modal: NgbModal,
    public authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.isSuper = authenticationService.isSuper()
  }

  async ngOnInit(): Promise<void> {
    this.isQediEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.qedi.launchDemonstration)
    this.nodeBotAllowed = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.bot.nodeBot)
  }

  openBotModal(): void {
    const ref = this.modal.open(BotModal, { centered: true, size: 'xl' })
    ref.componentInstance.claim = this.claim
  }

  /**
   * Launch modal to show images related to a task
   * FOR DEMO ONLY
   *
   * @param {*} modal
   * @memberof ClaimKeyDetailsComponent
   */
  showImages(modal: TemplateRef<NgbModal>): void {
    this.modal.open(modal, { centered: true, size: 'xl' })
  }

  showOpNote(modal: TemplateRef<NgbModal>): void {
    this.modal.open(modal, {
      centered: true,
      size: 'xl',
    })
  }
}
