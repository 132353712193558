import { Injectable, OnDestroy } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetWorkflow, WorkflowChanges } from 'app/admin/workflows/workflows.gql'
import { debug } from 'app/shared/utils/debug'
import { QueryWorkflowArgs, SubscriptionNotification, Workflow } from 'generated/graphql'
import { Subject } from 'rxjs'
import { delay, takeUntil } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class WorkflowSubscriptionService implements OnDestroy {
  destroy$ = new Subject<void>()

  constructor(private apollo: Apollo) {}

  subscribe(): void {
    this.apollo
      .subscribe<{ workflowChanges: SubscriptionNotification }>({
        query: WorkflowChanges,
      })
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(
        async (event) => {
          debug('subscriptions', 'workflowChanges', event)
          let payload = event?.data?.workflowChanges
          let workflowId = payload?.entityId
          try {
            await this.apollo
              .query<{ workflow: Workflow }, QueryWorkflowArgs>({
                query: GetWorkflow,
                variables: {
                  id: workflowId,
                },
                fetchPolicy: 'network-only',
              })
              .toPromise()
          } catch (e) {
            debug('subscriptions', 'workflow websocket went sideways', JSON.stringify(payload), JSON.stringify(e))
          }
        },
        (error) => {
          debug('subscriptions', 'workflow sub got error')
        },
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
