import { Component, Input } from '@angular/core'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { DateRange } from 'app/shared/components/date-range-picker/date-range-picker.component'
import { QueryCenterService } from 'app/shared/services/query-center.service'
import { debug } from 'app/shared/utils/debug'
import { saveAs } from 'file-saver'
import { Organization, QueryArg, QueryArgs, QueryCenterQuery, QueryResult } from 'generated/graphql'
import moment from 'moment'
import { BehaviorSubject, Observable, Subject, combineLatest, concat, of } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators'

@Component({
  selector: 'app-query-center',
  templateUrl: './query-center.component.html',
  styleUrls: ['./query-center.component.scss'],
  host: {
    class: 'm-n4',
  },
})
export class QueryCenterComponent {
  /**
   * query product flexibility is maintained even though we only have one use-case for it right now
   */
  @Input() queryProduct: 'AUTOMATIONS'
  @Input() rowDisplayLimit: number = null

  currentRowsDisplayed: number = this.rowDisplayLimit

  // make enum available in template
  QueryArg = QueryArg

  // BehaviorSubject for value of each possible QueryArg
  args: Map<QueryArg, BehaviorSubject<string>> = new Map([
    [QueryArg.OrgId, new BehaviorSubject<string>('')],
    [QueryArg.Date, new BehaviorSubject<string>('')],
    [QueryArg.StartDate, new BehaviorSubject<string>('')],
    [QueryArg.EndDate, new BehaviorSubject<string>('')],
  ])

  // BehaviorSubject to force a query to re-run
  repeatQueryClickEvents$ = new BehaviorSubject<MouseEvent>(null)

  // functions used by org typeahead
  searchOrgs = (term: string): Observable<Organization[]> => {
    return this.org.getOrgs(term, 10, 0).pipe(
      take(1),
      map((result) => result.data.organizations.entities),
    )
  }
  formatOrgSearchResult = (org: Organization): string => org.name

  // user's current org...
  userOrg = this.authenticationService.getUserOrg()

  // ...and the currently selected org (tracked separately from args above so we have whole entity)
  selectedOrg$ = new BehaviorSubject<Organization>(null)

  // list of available queries & currently selected query
  queries$: Observable<QueryCenterQuery[]>
  selectedQuery$ = new BehaviorSubject<QueryCenterQuery>(null)

  // state of query execution
  isMissingArg$ = new BehaviorSubject<boolean>(false)
  isExecutingQuery$ = new BehaviorSubject<boolean>(false)
  queryError$ = new BehaviorSubject<string>(null)

  // A subject that emits whenever the user interacts with the UI on a separate
  // stream so that we can cancel any in-progress queries and save resources.
  cancelOnNewInteraction$ = new Subject<void>()

  // results of executing query, plus derived values
  userInteraction$ = combineLatest([
    this.selectedQuery$,
    combineLatest([...this.args.values()]),
    this.repeatQueryClickEvents$,
  ]).pipe(
    // This will fire whenever the user interacts with the UI, but on a separate
    // stream, so that we don't cancel the very query they just started.
    tap(() => {
      debug('query-center', 'User interaction detected. Canceling any in-progress queries.')
      this.cancelOnNewInteraction$.next()
    }),
  )

  queryResult$ = this.userInteraction$.pipe(
    debounceTime(1500),
    tap(() => this.queryError$.next(null)),
    switchMap(([query]) =>
      concat(
        // immediately clear queryResults from previous query
        of(<QueryResult>null),
        of(query).pipe(
          // ==================
          // PREP WORK
          // ==================

          // This cancels the query if the user interacts with the UI again.
          takeUntil(this.cancelOnNewInteraction$),

          // only proceed if there's a query selected
          filter((query) => !!query),

          // and make sure all args have values
          filter((query) => {
            for (let arg of query.args) {
              if (!this.args.get(arg).value) {
                this.isMissingArg$.next(true)
                return false
              }
            }
            this.isMissingArg$.next(false)
            return true
          }),

          // ==================
          // EXECUTE THE QUERY
          // ==================

          // set the loading state
          tap(() => this.isExecutingQuery$.next(true)),
          // reset the max displayed rows to the initial value on new query
          tap(() => (this.currentRowsDisplayed = this.rowDisplayLimit)),
          // switchMap will cancel the inner observable when a new outer
          // observable emits, effectively cancelling the http request for the
          // query if the user interacts with the UI again.
          switchMap(() =>
            this.queryCenterService
              .executeQuery(
                query.name,
                this.queryProduct,
                query.args.reduce<QueryArgs>((acc, arg) => {
                  acc[arg] = this.args.get(arg).value
                  return acc
                }, {}),
              )
              .pipe(
                first(),
                catchError((e) => {
                  this.queryError$.next(e)
                  return of(null)
                }),
              ),
          ),
          // format the result
          map((result) => result?.data?.query),
          // clear the loading state if the query either completes or is cancelled
          finalize(() => this.isExecutingQuery$.next(false)),
        ),
      ),
    ),
    // share the result so that multiple subscribers don't trigger multiple queries
    shareReplay(),
  )
  latestResultData: any
  resultData$ = this.queryResult$.pipe(
    map((result) => (result ? JSON.parse(result.jsonResult) : null)),
    tap((data) => (this.latestResultData = data)),
    shareReplay(),
  )
  resultDataColumns$ = this.resultData$.pipe(map((data) => (data?.length ? Object.keys(data[0]) : [])))
  resultRowCount$ = this.queryResult$.pipe(map((result) => (result ? result.rowCount : null)))
  resultExecutionMs$ = this.queryResult$.pipe(map((result) => (result ? result.executionMs : null)))

  constructor(
    private queryCenterService: QueryCenterService,
    public authenticationService: AuthenticationService,
    private org: OrgService,
  ) {}

  ngOnInit(): void {
    this.queries$ = this.queryCenterService.fetchQueries(this.queryProduct).pipe(map((result) => result.data.queries))
    this.currentRowsDisplayed = this.rowDisplayLimit
  }

  expandDisplayedResults(): void {
    if (!this.rowDisplayLimit) {
      // this makes no sense. How would you even call this function if the rows were unlimited?
      return
    }
    this.currentRowsDisplayed += this.rowDisplayLimit
  }

  selectQuery(query: QueryCenterQuery): void {
    this.selectedQuery$.next(query)

    // set defaults on required args (unless values are already populated)
    if (query.args.includes(QueryArg.OrgId) && !this.args.get(QueryArg.OrgId).value) {
      this.userOrg.then((org) => this.selectOrg(org))
    }
    if (query.args.includes(QueryArg.Date) && !this.args.get(QueryArg.Date).value) {
      this.args.get(QueryArg.Date).next(moment().format('YYYY-MM-DD'))
    }
    if (query.args.includes(QueryArg.StartDate) && !this.args.get(QueryArg.StartDate).value) {
      this.args.get(QueryArg.StartDate).next(moment().subtract(6, 'days').format('YYYY-MM-DD'))
    }
    if (query.args.includes(QueryArg.EndDate) && !this.args.get(QueryArg.EndDate).value) {
      this.args.get(QueryArg.EndDate).next(moment().format('YYYY-MM-DD'))
    }
  }

  selectOrg(org: Organization): void {
    this.args.get(QueryArg.OrgId).next(org?.id)
    this.selectedOrg$.next(org)
  }

  selectDateRange(dateRange: DateRange): void {
    if (this.args.get(QueryArg.StartDate).value !== dateRange.startDate) {
      this.args.get(QueryArg.StartDate).next(dateRange.startDate)
    }
    if (this.args.get(QueryArg.EndDate).value !== dateRange.endDate) {
      this.args.get(QueryArg.EndDate).next(dateRange.endDate)
    }
  }

  async exportCsv(): Promise<void> {
    let columns = Object.keys(this.latestResultData?.[0])
    let csv = [
      columns.map((column) => JSON.stringify(column)).join(','),
      ...this.latestResultData.map((row: any) => {
        return columns
          .map((column) => {
            let cleanColumn = row[column]
            if (Array.isArray(row[column])) {
              cleanColumn = row[column]?.join(' ')
            }
            return JSON.stringify(cleanColumn)
          })
          .join(',')
      }),
    ].join('\r\n')
    let blob = new Blob([csv], { type: 'text/csv' })
    let filenamePieces = [this.selectedQuery$.value?.name]
    if (this.selectedQuery$.value?.args?.includes(QueryArg.OrgId)) {
      filenamePieces.push(this.selectedOrg$.value?.name.replace(' ', '-'))
    }
    if (this.selectedQuery$.value?.args?.includes(QueryArg.Date)) {
      filenamePieces.push(this.args.get(QueryArg.Date).value)
    }
    if (
      this.selectedQuery$.value?.args?.includes(QueryArg.StartDate) ||
      this.selectedQuery$.value?.args?.includes(QueryArg.EndDate)
    ) {
      filenamePieces.push(`${this.args.get(QueryArg.StartDate).value}-to-${this.args.get(QueryArg.EndDate).value}`)
    }
    saveAs(blob, `${filenamePieces.join('_')}.csv`)
  }
}
