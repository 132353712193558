<div class="modal-header d-flex justify-content-between">
  <h5 class="modal-title mx-auto">Export Claims by ID</h5>
  <button type="button" class="close mx-2" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body d-flex flex-column">
  <textarea [formControl]="claimIds" class="form-control flex-grow-1" cols="20" rows="6"></textarea>
  <small class="form-text text-muted">Enter Claim IDs separated by commas, tabs, or one per line</small>
  <hr class="w-75 mx-auto" />
  <h4 class="text-center">Parsed {{ parsedClaimIds?.length | number }} Claims</h4>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="modal.dismiss()" data-t="export-claims-modal-cancel-button">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-success ml-2"
    (click)="modal.close(parsedClaimIds)"
    data-t="export-claims-modal-yes-button"
  >
    Export {{ parsedClaimIds?.length | number }} Claims
  </button>
</div>
