import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { LabelType, Options } from 'ngx-slider-v2'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators'

/**
 * Component to display a range slider facet for claim properties
 *
 * @export
 * @class RangeSliderFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet
  @ViewChild('input') input: ElementRef

  isCollapsed = false

  form = new UntypedFormGroup({
    range: new UntypedFormControl([]),
  })

  range$: Subscription
  facets$: Subscription

  options: Options

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.options = {
      floor: 0,
      ceil: this.data.max || 10000,
      translate: (value: number, label: LabelType): string => {
        return `${this.data?.labelPrepend || ''}${value}`
      },
    }
    this.form.get('range').setValue([0, this.data.max], { emitEvent: false })

    this.range$ = this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((form) => form.range[0] !== 0 || form.range[1] !== this.data.max),
        tap((form) => {
          let [low, high] = form.range
          let display = `${this.data.labelPrepend}${low} - ${this.data.labelPrepend}${high}`
          this.facetService.addToFacet(this.data.id, { value: form.range, display: display })
        }),
      )
      .subscribe()

    this.facets$ = this.facetService.facets$
      .pipe(
        filter((facets) => facets.find((f) => f.id === this.data.id) !== undefined),
        tap((facets: Facet[]) => {
          let selected = facets.find((f) => f.id === this.data.id).selected
          if (selected.length === 0) {
            this.form.get('range').setValue([0, this.data.max], { emitEvent: false })
          }
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.range$.unsubscribe()
    this.facets$.unsubscribe()
  }

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.input.nativeElement.focus()
    }
  }

  /**
   * Remove this facet
   *
   * @memberof RangeSliderFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
