import { Component, OnInit } from '@angular/core'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ObserveSummary, Organization, ObserveUrlGroup, ObserveMissConditions } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { ConfigViewModes } from '../service/observe-config.service'

@Component({
  selector: 'app-observe-config-page',
  templateUrl: './observe-config.page.html'
})
export class ObserveConfigPage implements OnInit {
  viewModes = ConfigViewModes
  currentOrg: Organization = null
  activeTab: ConfigViewModes = ConfigViewModes.CONFIG
  dateRangeString: string = null
  selectedUrlGroup: ObserveUrlGroup
  showAddConfigurations: boolean = false
  selectedObserveSummary: ObserveSummary
  selectedMissReason: ObserveMissConditions

  constructor(private orgService: OrgService, private authenticationService: AuthenticationService) {}

  async ngOnInit(): Promise<void> {
    this.onOrgChange()
  }

  public async onOrgChange($event?: Organization): Promise<void> {
    let orgId = $event ? $event.id : this.authenticationService.getUser()?.orgId

    const orgResult = await this.orgService.getOrg(orgId).pipe(first()).toPromise()
    this.currentOrg = orgResult?.data?.organization
    this.activeTab = ConfigViewModes.CONFIG

  }

  public openUrlGrouping(event: ObserveSummary): void {
    this.selectedObserveSummary = event
    this.activeTab = ConfigViewModes.URL_GROUPS
  }

  public openAddConfigurations(urlGroup: ObserveUrlGroup): void {
    this.selectedUrlGroup = urlGroup
    this.showAddConfigurations = true
  }

  public setQueryDate(event: string): void {
    this.dateRangeString = event
  }

  public goToBackButton(): void {
    if (this.activeTab == ConfigViewModes.URL_GROUPS) {
      if (this.showAddConfigurations) {
        this.showAddConfigurations = false
        this.selectedMissReason = null
      } else {
        this.selectedObserveSummary = null
        this.activeTab = ConfigViewModes.CONFIG
      }
    }
  }

  public missReasonSelected($event: ObserveMissConditions | null): void {
    this.selectedMissReason = $event
  }
}

