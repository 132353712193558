<div class="container d-flex flex-column min-vh-100" *ngIf="!isLoading;else loadingBlock">
  <div class="row justify-content-around my-5">
    <div class="col-md-4 col-8">
      <img src="/assets/img/Janus_Health_horizontal_logo_white.svg" alt="Janus Health Logo" class="img-fluid" />
    </div>
  </div>
  <div class="row justify-content-around">
    <div class="bg-brand-gray text-alt-dark-gray rounded px-5 col-10 pb-4">
      <div>
        <h1 class="font-weight-bold text-primary my-4">OOPS!</h1>

        <!-- General Error -->
        <div *ngIf="errorType === 'General'" data-testid="general-error">
          <h5 class="font-weight-bold my-2">AUTOMATION FAILED</h5>
          <p class="my-4">
            Teleport has experienced an error. Please consider restarting your browser, and attempt to launch Teleport
            again. We apologize for the inconvenience.
          </p>
        </div>

        <!-- UnsupportedPayer error - displays when Teleport times out (2 minutes) -->
        <div *ngIf="errorType === 'UnsupportedPayer'" data-testid="unsupported-payer-error">
          <h5 class="font-weight-bold my-2">UNSUPPORTED PAYER</h5>
          <p class="my-4">
            Unfortunately that payer is not supported by Teleport at this time. We apologize for the inconvenience.
          </p>
          <p class="my-4">
            Please visit <a routerLink="/admin/payers">janus-ai.com/admin/payers</a> for a comprehensive list of current
            payers.
          </p>
        </div>

        <!-- DuplicateAutomation - displays when user attempts to open an automation when one is already running -->
        <div *ngIf="errorType === 'DuplicateAutomation'" data-testid="duplicate-automation-error">
          <h5 class="font-weight-bold my-2">AUTOMATION IS ALREADY RUNNING</h5>
          <p class="my-4">A Teleport automation is already running in another tab. Please close this tab.</p>
          <p class="my-4"></p>
        </div>

        <!-- WebsocketDisconnected - displays when the websocket server is disconnected -->
        <div *ngIf="errorType === 'WebsocketDisconnected'" data-testid="websocket-disconnected-error">
          <h5 class="font-weight-bold my-2">TELEPORT IS UNAVAILABLE</h5>
          <p class="my-4">
            Teleport is temporarily unavailable. Please reach out to your customer success representative if this
            persists.
          </p>
          <p class="my-4"></p>
        </div>

        <!-- TeleportExtensionUnresponsive - displays when extension does not respond -->
        <div *ngIf="errorType === 'TeleportExtensionUnresponsive'" data-testid="teleport-extension-unresponsive-error">
          <h5 class="font-weight-bold my-2">TELEPORT IS UNAVAILABLE</h5>
          <p class="my-4">
            Teleport is temporarily unavailable, please make sure
            <a
              href="https://chrome.google.com/webstore/detail/janus-teleport/fogbgogfedkknbbdiepecoacdoilemof/related?hl=en&authuser=1"
              >the extension is installed</a
            >
            and enabled.
          </p>
          <p class="my-4">Please reach out to your customer success representative if this persists.</p>
          <p class="my-4"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-around my-5">
    <div class="col text-center text-muted">
      For information or to report an issue, please submit a ticket at
      <a target="_blank" [href]="zendesk.createTicketUrl">{{ zendesk.createTicketDisplayText }}</a>
      <br />
      <app-copyright></app-copyright>
    </div>
  </div>
</div>
<ng-template #loadingBlock>
  <app-loading
    innerClass="fa-3x"
    outerClass="text-brand-light-blue h-100 d-flex align-items-center justify-content-center"
  ></app-loading>
</ng-template>
