import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Subject } from 'rxjs'
import { ProcedureSetsService } from 'app/procedure-sets/procedure-sets.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ContactPayerModalComponent } from '../contact-payer-modal/contact-payer-modal.component'
import { debug } from 'app/shared/utils/debug'
import { Procedure, ProcedureSet } from 'generated/graphql'
import { takeUntil, tap } from 'rxjs/operators'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ProcedureSetRowConfig } from 'app/teleport/teleport.service'

@Component({
  selector: 'app-contact-payer-link-table-row',
  templateUrl: './contact-payer-link-table-row.component.html',
  styleUrls: ['./contact-payer-link-table-row.component.scss'],
  host: {
    class: 'no-wrapper-box',
  },
})
export class ContactPayerLinkTableRowComponent implements OnInit {
  @Input()
  config: ProcedureSetRowConfig

  @Output()
  statusUpdated: EventEmitter<ProcedureSet> = new EventEmitter<ProcedureSet>()

  tooltip: string = ''
  private destroy$ = new Subject<void>()

  constructor(
    private procedureSetsService: ProcedureSetsService,
    private modal: NgbModal,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.tooltip = 'Click on the Procedure to set the authorization requirement.'
  }

  changeStatus(procedures: Procedure[], newStatus: string): Promise<ProcedureSet> {
    const trimedProcedures = procedures.map((procedure) => {
      return { id: procedure.id, status: procedure.status }
    })
    try {
      this.procedureSetsService
        .updateProcedureSetandProcedureStatus(this.config.typeDef.id, newStatus, trimedProcedures)
        .pipe(
          tap((result) => {
            debug('prior-Auth', 'change status result', result)
            this.statusUpdated.emit(result)
          }),
          takeUntil(this.destroy$),
        )
        .subscribe({
          error: (error) => {
            debug('prior-Auth', 'change status error', error)
            this.toast.error(
              parseGraphQLError(error, 'Could not update the status or that procedure' + JSON.stringify(error)),
            )
          },
        })
    } catch (e) {
      debug('prior-Auth', 'change status error', e)
      return
    }
  }

  contactPayerModalAppear(): void {
    const modalRef = this.modal.open(ContactPayerModalComponent, {
      centered: true,
      windowClass: 'modal-extra-padding',
      size: 'lg',
      backdrop: true,
      keyboard: false,
    })

    modalRef.componentInstance.procedureSetId = this.config.typeDef.id
    modalRef.componentInstance.procedures = this.config.typeDef.procedures

    const modalSubscription = modalRef.closed.subscribe(async (procedures: Procedure[]) => {
      modalSubscription.unsubscribe()
      if (!procedures) return
      let newStatus = 'NA'

      for (let i: number = 0; i < procedures.length; i++) {
        let procedureStatus = procedures[i].status
        if (procedureStatus === 'CT') {
          newStatus = 'CT'
          break
        } else if (procedureStatus === 'ARC') {
          newStatus = 'ARC'
        }
      }

      this.changeStatus(procedures, newStatus)

      modalRef.close()
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
