<h5 class="mb-3">Claim Responsibility</h5>
<div class="bg-light card mb-5">
  <app-collapsing-card [isCollapsed]="true">
    <div slot="title">
      <div class="d-flex justify-content-between">
        <span>Payer 1: {{ claim?.payer?.name }}</span>
        <app-teleport-button [claim]="claim"></app-teleport-button>
      </div>
    </div>
    <div slot="content" class="pt-2">
      <div class="d-flex w-100 py-1">
        <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('memberId')?.label }}</dt>
        <dd class="flex-grow-1 text-right">
          <app-copy-value [value]="claim?.memberId"></app-copy-value>
        </dd>
      </div>
      <div class="d-flex w-100 py-1">
        <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('subscriberId')?.label }}</dt>
        <dd class="flex-grow-1 text-right">
          <app-copy-value [value]="claim?.subscriberId"></app-copy-value>
        </dd>
      </div>
      <div class="d-flex w-100 py-1">
        <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('plan.planId')?.label }}</dt>
        <dd class="flex-grow-1 text-right">
          <app-copy-value [value]="claim?.plan?.planId" facet="plan.planId"></app-copy-value>
        </dd>
      </div>
      <div class="d-flex w-100 py-1">
        <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('plan.name')?.label }}</dt>
        <dd class="flex-grow-1 text-right">
          <app-copy-value
            [value]="claim?.plan?.name"
            facet="plan.name"
            data-t="detail-page-plan-payer-name"
          ></app-copy-value>
        </dd>
      </div>
      <div class="d-flex w-100 py-1">
        <dt class="flex-shrink-1 font-weight-normal">{{ getPropDetails('groupNumber')?.label }}</dt>
        <dd class="flex-grow-1 text-right">
          <app-copy-value
            [value]="claim?.plan?.groupNumber"
            facet="plan.groupNumber"
            data-t="claim-responsibility-group-number"
          ></app-copy-value>
        </dd>
      </div>
    </div>
  </app-collapsing-card>
</div>

<div *ngIf="secondaryPayer?.name" class="bg-light card mb-5">
  <app-collapsing-card [allowCollapse]="false">
    <div slot="title">
      <div class="d-flex justify-content-between">
        <span>Payer 2: {{ secondaryPayer.name }}</span>
      </div>
    </div>
  </app-collapsing-card>
</div>

<div *ngIf="tertiaryPayer?.name" class="bg-light card mb-5">
  <app-collapsing-card [allowCollapse]="false">
    <div slot="title">
      <div class="d-flex justify-content-between">
        <span>Payer 3: {{ tertiaryPayer.name }}</span>
      </div>
    </div>
  </app-collapsing-card>
</div>
