import { DaterangeFacetComponent } from 'app/shared/components/faceted-search/daterange/daterange.component'
import { NumberRangeFacetComponent } from 'app/shared/components/faceted-search/number-range/number-range.component'
import { RadioFacetComponent } from 'app/shared/components/faceted-search/radio/radio.component'
import { TextFacetComponent } from 'app/shared/components/faceted-search/text/text.component'
import { TypeaheadFacetComponent } from 'app/shared/components/faceted-search/typeahead/typeahead.component'
import { getPropDetails } from 'app/shared/types/claim'
import { Facet, FacetType } from 'app/shared/types/facet'
import { ElasticsearchScriptType } from 'generated/graphql'
import { ComparisonFacetComponent } from '../components/faceted-search/comparison/comparison.component'

export const SEARCHFIELD: Facet = {
  id: 'wildcard',
  title: 'Search',
  type: FacetType.TEXT,
  component: TextFacetComponent,
  selected: [],
  onlySingle: true,
}

/* Example predefined facet:

  {
    id: 'outstandingAndTasks',
    title: 'Open Tasks with Outstanding Amount',
    type: FacetType.PREDEFINED,
    component: PreDefinedFacetComponent,
    selected: [
      {
        key: 'tasks.completed',
        value: false,
        display: '',
      },
      {
        key: 'outstandingAmount',
        value: '1 TO *',
        display: '',
      },
    ],
  },
*/

export const FACETS: Facet[] = [
  {
    id: 'providerClaimId',
    title: getPropDetails('providerClaimId')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
  },
  {
    id: 'icn',
    title: getPropDetails('icn')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerBilling.npi',
    title: getPropDetails('providerBilling.npi')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerAttending.npi',
    title: getPropDetails('providerAttending.npi')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerReferring.npi',
    title: getPropDetails('providerReferring.npi')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerRendering.npi',
    title: getPropDetails('providerRendering.npi')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'plan.planId',
    title: getPropDetails('plan.planId')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'plan.name',
    title: getPropDetails('plan.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'plan.groupNumber',
    title: getPropDetails('plan.groupNumber')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'payer.name',
    title: getPropDetails('payer.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'secondaryPayer.name',
    title: getPropDetails('secondaryPayer.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'tertiaryPayer.name',
    title: getPropDetails('tertiaryPayer.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'authNum',
    title: getPropDetails('authNum')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'patientMrn',
    title: getPropDetails('patientMrn')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'patientName',
    title: getPropDetails('patientName')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'submissionDate',
    title: getPropDetails('submissionDate')?.label,
    type: FacetType.DATERANGE,
    component: DaterangeFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'serviceDate',
    title: getPropDetails('serviceDate')?.label,
    type: FacetType.DATERANGE,
    component: DaterangeFacetComponent,
    selected: [],
    onlySingle: true,
  },
  // {
  //   id: 'denialDate',
  //   title: 'Denial Date',
  //   type: FacetType.DATERANGE,
  //   component: DaterangeFacetComponent,
  //   selected: [],
  //   onlySingle: true,
  // },
  {
    id: 'categoryCode',
    otherIds: ['servicelines.categoryCodes.code'],
    title: getPropDetails('servicelines.categoryCodes.code')?.heading,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'remitCode',
    otherIds: ['servicelines.remitAdviceRemarkCodes.code'],
    title: getPropDetails('servicelines.remitAdviceRemarkCodes.code')?.heading,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'servicelines.claimAdjustmentReasonCodes.code',
    title: getPropDetails('servicelines.claimAdjustmentReasonCodes.code')?.heading,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'ehrPayments.postDate',
    otherIds: ['payerPayments.postDate'],
    title: `Payment ${getPropDetails('ehrPayments.postDate')?.heading}`,
    type: FacetType.DATERANGE,
    component: DaterangeFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'claimAmount',
    title: getPropDetails('claimAmount')?.label,
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'outstandingAmount',
    title: getPropDetails('outstandingAmount')?.label,
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'harId',
    title: getPropDetails('harId')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
  },
  // {
  //   id: 'providerOperating.name',
  //   title: 'Operating Provider',
  //   type: FacetType.TYPEAHEAD,
  //   component: TypeaheadFacetComponent,
  //   selected: [],
  //   onlySingle: true,
  // },
  {
    id: 'taxId',
    title: getPropDetails('taxId')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerAttending.name',
    title: getPropDetails('providerAttending.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerReferring.name',
    title: getPropDetails('providerReferring.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerBilling.name',
    title: getPropDetails('providerBilling.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerBilling.taxId',
    title: getPropDetails('providerBilling.taxId')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'providerRendering.name',
    title: getPropDetails('providerRendering.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  // {
  //   id: 'placeOfService',
  //   title: 'Place of Service',
  //   type: FacetType.TYPEAHEAD,
  //   component: TypeaheadFacetComponent,
  //   selected: [],
  //   onlySingle: true,
  // },
  // {
  //   id: 'serviceFacility',
  //   title: 'Service Facility',
  //   type: FacetType.TYPEAHEAD,
  //   component: TypeaheadFacetComponent,
  //   selected: [],
  //   onlySingle: true,
  // },
  {
    id: 'tasks.taskType.name',
    title: getPropDetails('tasks.taskType.name')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: false,
    search: null,
    valueProp: 'name',
    displayProp: 'name',
    inputFormatter: (x: { name: string }): string => x.name,
    resultFormatter: (x: { name: string }): string => x.name,
  },
  {
    id: 'tasks.completedBy',
    title: getPropDetails('tasks.completedBy')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
    search: null,
  },
  {
    id: 'tasks.completedDate',
    title: getPropDetails('tasks.completedDate')?.label,
    type: FacetType.DATERANGE,
    component: DaterangeFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'tasks.taskGroup.name',
    title: 'Task Group',
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
    search: null,
    valueProp: 'name',
    displayProp: 'name',
    inputFormatter: (x: { name: string }): string => x.name,
    resultFormatter: (x: { name: string }): string => x.name,
  },
  {
    id: 'ehrStatusCode',
    otherIds: ['servicelines.ehrStatusCodes.code'],
    title: getPropDetails('servicelines.ehrStatusCodes.code')?.heading,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'payerStatusCode',
    otherIds: ['servicelines.payerStatusCodes.code'],
    title: getPropDetails('servicelines.payerStatusCodes.code')?.heading,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
  },
  // {
  //   id: 'servicelines.serviceUnit',
  //   title: 'Service Unit / Department',
  //   type: FacetType.TYPEAHEAD,
  //   component: TypeaheadFacetComponent,
  //   selected: [],
  //   onlySingle: true,
  // },
  {
    id: 'servicelines.modifier',
    title: getPropDetails('servicelines.modifier')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'servicelines.diagnosis',
    title: getPropDetails('servicelines.diagnosis')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'servicelines.cpt',
    title: getPropDetails('servicelines.cpt')?.label,
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'servicelines.chargeAmount',
    title: getPropDetails('servicelines.chargeAmount')?.label,
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.coveredAmount',
    otherIds: ['payerPayments.coveredAmount', 'servicelines.coveredAmount'],
    title: 'Covered Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.nonCoveredAmount',
    otherIds: ['payerPayments.nonCoveredAmount', 'servicelines.nonCoveredAmount'],
    title: 'Non-Covered Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.deductibleAmount',
    otherIds: ['payerPayments.deductibleAmount'],
    title: 'Deductible Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.copayAmount',
    otherIds: ['payerPayments.copayAmount'],
    title: 'Copay Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.coinsAmount',
    otherIds: ['payerPayments.coinsAmount'],
    title: 'Co-Insurance Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.cobAmount',
    otherIds: ['payerPayments.cobAmount'],
    title: 'COB Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.paidAmount',
    otherIds: ['payerPayments.paidAmount'],
    title: 'Paid Amount',
    type: FacetType.NUMBERRANGE,
    component: NumberRangeFacetComponent,
    selected: [],
    labelPrepend: '$',
    onlySingle: true,
  },
  {
    id: 'ehrPayments.checkNumber',
    otherIds: ['payerPayments.checkNumber'],
    title: 'Check Number',
    type: FacetType.TEXT,
    component: TextFacetComponent,
    selected: [],
  },
  {
    id: 'checkNumber',
    otherIds: ['payerPayments.checkNumber', 'ehrPayments.checkNumber'],
    title: 'Check Number Exists?',
    type: FacetType.RADIO,
    component: RadioFacetComponent,
    selected: [],
    existsQuery: true,
    options: [
      { value: true, display: 'Yes' },
      { value: false, display: 'No' },
    ],
    onlySingle: true,
  },
  {
    id: 'ehrPayments',
    otherIds: ['payerPayments'],
    title: 'Payments Exist?',
    type: FacetType.RADIO,
    component: RadioFacetComponent,
    selected: [],
    existsQuery: true,
    options: [
      { value: true, display: 'Yes' },
      { value: false, display: 'No' },
    ],
    onlySingle: true,
  },
  {
    id: 'tasks.completionType',
    title: 'Task Status',
    type: FacetType.RADIO,
    component: RadioFacetComponent,
    selected: [],
    options: [
      { value: null, display: 'Not Completed' },
      { value: 1, display: 'Completed' },
      { value: 2, display: 'Rejected' },
    ],
    onlySingle: true,
  },
  {
    id: 'patientSex',
    title: 'Patient Sex',
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
    onlySingle: true,
  },
  {
    id: 'subscriberId',
    title: getPropDetails('subscriberId')?.label,
    type: FacetType.TYPEAHEAD,
    component: TypeaheadFacetComponent,
    selected: [],
  },
  {
    id: 'claimAmount-vs-outstandingAmount',
    title: `${getPropDetails('claimAmount')?.label} vs ${getPropDetails('outstandingAmount')?.label}`,
    type: FacetType.COMPARISON,
    component: ComparisonFacetComponent,
    comparisonProps: [getPropDetails('claimAmount')?.label, getPropDetails('outstandingAmount')?.label],
    selected: [],
    options: [
      {
        value: ElasticsearchScriptType.OutstandingAmountExceedsClaimAmount,
        display: '<',
        displayPill: `${getPropDetails('claimAmount')?.label} < ${getPropDetails('outstandingAmount')?.label}`,
      },
      {
        value: ElasticsearchScriptType.OutstandingAndClaimAmountsEqual,
        display: '=',
        displayPill: `${getPropDetails('claimAmount')?.label} = ${getPropDetails('outstandingAmount')?.label}`,
      },
      {
        value: ElasticsearchScriptType.ClaimAmountExceedsOutstandingAmount,
        display: '>',
        displayPill: `${getPropDetails('claimAmount')?.label} > ${getPropDetails('outstandingAmount')?.label}`,
      },
    ],
  },
]
