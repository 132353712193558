import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrgService } from 'app/admin/org/org.service'
import { FeatureFlags } from 'app/shared/services/feature-flag.service'
import { Organization } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { QuicksightDashboardType } from 'app/shared/components/quicksight-dashboard/quicksight-dashboard'

@Component({
  selector: 'app-edit-org-dashboard-page',
  templateUrl: './edit-org-dashboard.page.component.html',
  styleUrls: ['./edit-org-dashboard.page.component.scss'],
})
export class EditOrgDashboardPage implements OnInit {
  public organization: Organization

  public quickSightDashBoardSettings: DashboardSettingLabels[] = [
    {
      label: 'Workforce Management',
      lookUpKey: `quicksightDashboard:${QuicksightDashboardType.Illuminate}`,
      featureFlagName: FeatureFlags.ui.illuminate.workforceManagement,
    },
    {
      label: 'Usage Insights',
      lookUpKey: `quicksightDashboard:${QuicksightDashboardType.UsageInsights}`,
      featureFlagName: FeatureFlags.ui.illuminate.usageInsights,
    },
    {
      label: 'Denials Insights',
      lookUpKey: `quicksightDashboard:${QuicksightDashboardType.Standard}`,
      featureFlagName: FeatureFlags.ui.illuminate.denialsDashboard,
    },
    {
      label: 'Throughput Report',
      lookUpKey: `quicksightDashboard:${QuicksightDashboardType.Throughput}`,
      featureFlagName: FeatureFlags.ui.throughputReport,
    },
  ]

  constructor(private route: ActivatedRoute, private orgService: OrgService) {}

  async ngOnInit(): Promise<void> {
    const orgId = (await this.route.params.pipe(first()).toPromise())?.orgId
    this.organization = (await this.orgService.getOrg(orgId).pipe(first()).toPromise()).data?.organization
  }
}

export type DashboardSettingLabels = {
  label: string
  lookUpKey: string
  featureFlagName: string
}
