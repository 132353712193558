import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input() selected: boolean
  @Input() disabled: boolean
  @Input() highlighted: boolean
  @Input() label?: string
  @Input() href: string

  constructor() {}

  ngOnInit(): void {}
}
