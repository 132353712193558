import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { BotRunsService } from 'app/revbot/bot-runs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { BotJob, ListResponseMetaData } from 'generated/graphql'
import * as moment from 'moment'
import { first } from 'rxjs/operators'

export type BotJobWithDetails = {
  botJob: BotJob
  details: {
    successRate: number
    avgRunTime: number
    lastRun: string
    fetched: boolean
  }
}

/**
 * Page to display a list of bots
 *
 * @export
 * @class BotsListPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-bots-list',
  templateUrl: './bots-list.page.html',
  styleUrls: ['./bots-list.page.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class BotsListPage implements OnInit {
  loading: boolean = false
  botsSearch = new UntypedFormControl('')
  botJobs: BotJobWithDetails[]
  totalBotJobs: number
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 10

  constructor(
    private toast: ToastService,
    private botRunsService: BotRunsService,
    private botJobsService: BotJobsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.searchBotJobs()
    void this.fetchBotDetails()
  }

  /**
   * Get bot jobs to display based on page and search variables
   *
   * @param {number} [page]
   * @return {*}  {Promise<void>}
   * @memberof BotsListPage
   */
  async searchBotJobs(page?: number): Promise<void> {
    this.loading = true
    try {
      let offset
      if (page) {
        offset = (page - 1) * this.pageSize
      }
      let fetchedBotJobs = await this.botJobsService
        .getBotJobs(offset, undefined, undefined, this.botsSearch?.value, true, null, false, this.pageSize)
        .pipe(first())
        .toPromise()
      this.botJobs = fetchedBotJobs?.data?.botJobsV2?.entities?.map((botJob) => {
        return { botJob, details: { successRate: null, avgRunTime: null, lastRun: null, fetched: false } }
      })
      this.meta = this.botJobsService.getMeta()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find bot job'), JSON.stringify(e))
    }
    this.loading = false
  }

  async fetchBotDetails(): Promise<void> {
    this.botJobs.map(async (botJob, botJobIdx) => {
      try {
        let botRuns = await this.botRunsService.filterBotRuns(botJob.botJob.id, 0).pipe(first()).toPromise()
        let ranBots = botRuns?.data?.botRuns?.entities?.filter((botRun) => botRun.runStartAt && botRun.runEndAt)
        let successRate
        let avgRunTime
        let lastRun
        if (ranBots.length) {
          successRate = this.botRunsService.getAverageSuccessRate(ranBots)
          let botRunTimes = ranBots.map((botRun) => moment(botRun.runEndAt).diff(moment(botRun.runStartAt), 'seconds'))
          let total = 0
          for (let runTime of botRunTimes) {
            if (runTime && runTime > 0) {
              total += Number(runTime)
            }
          }
          avgRunTime = Math.ceil(total / ranBots.length)
          lastRun = new Date(
            Math.max.apply(
              null,
              ranBots.map(function (bot) {
                return new Date(bot.runEndAt)
              }),
            ),
          )
        }
        this.botJobs[botJobIdx] = {
          botJob: botJob.botJob,
          details: { successRate, avgRunTime, lastRun, fetched: true },
        }
      } catch (error) {
        this.toast.error(
          parseGraphQLError(error, 'Could not fetch details for bot job ' + botJob.botJob.id + ':'),
          JSON.stringify(error),
        )
      }
    })
  }
}
