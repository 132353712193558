<div class="d-flex picsum-img-wrapper justify-content-center mx-5 position-relative">
  <div
    class="badge mt-2 mr-2 position-absolute right-0 top-0"
    [ngClass]="{
      'badge-grey-300': !record?.isConfirmed && record?.hasBeenViewed,
      'badge-primary': record?.isConfirmed,
      'badge-white': !record?.hasBeenViewed && !record?.hasBeenViewed
    }"
    data-t="status-badge"
  >
    {{ badgeText }}
  </div>

  <img [src]="observeFile?.signedS3Link" class="w-75" />
</div>
