import { Component, Input } from '@angular/core'
import { VxStatusType } from 'generated/graphql'

@Component({
  selector: 'vx-document-status-badge',
  templateUrl: './vx-document-status-badge.component.html',
})
export class VxDocumentStatusBadgeComponent {
  @Input() status: VxStatusType
  @Input() tooltipPlacement: 'top' | 'bottom' | 'start' | 'end' = 'top'
  tooltips: { [k in VxStatusType]: string } = {
    NEEDS_VX_DOCUMENT_GROUPING: 'Processing In Progress',
    NEEDS_PROCESSING: 'Processing In Progress',
    NEEDS_CATEGORIZATION: 'Assign Page Type',
    NEEDS_EXTRACTION_RULES: 'Create Extraction Rules',
    NEEDS_EXTRACTION: 'Complete Extraction',
    NEEDS_VX_DOCUMENT_TYPE: 'Document Type Selection Required',
    NEEDS_EXTRACTION_REVIEW: 'Complete Extraction',
    NEEDS_IDENTIFIER: 'Identifier Lookup In Progress',
    NEEDS_IDENTIFIER_REVIEW: 'Identifier Selection Required',
    READY_FOR_AUTOMATION: 'Ready For Automation',
    AUTOMATION_ERRORS: 'Errors - Contact Janus Support',
    REJECTED_INVALID: 'Rejected',
    COMPLETED_EXTERNALLY: 'Disregarded',
    DONE: 'Done',
  }

  /**
   * Return the status with underscores replaced with spaces.
   */
  get humanReadableStatus(): string {
    return this.status.replace(/_/g, ' ')
  }

  /**
   * Return the tooltip string for the active status.
   */
  get statusTooltip(): string | null {
    if (!this.status) {
      return null
    }

    return this.tooltips[this.status]
  }
}
