import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ClaimsForTeleport } from 'app/claims/claims-list/claims-list.gql'
import { ClaimsService } from 'app/claims/claims.service'
import { ProcedureSetsService } from 'app/procedure-sets/procedure-sets.service'
import { PortalMapService } from 'app/shared/services/portal-map.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import {
  ClaimRowConfig,
  ProcedureSetBotType,
  ProcedureSetRowConfig,
  TeleportRowMetaData,
  TeleportService,
} from 'app/teleport/teleport.service'
import { Claim, ProcedureSet, ProcedureSetTest } from 'generated/graphql'
import { groupBy, isNil, sortBy } from 'lodash'
import { Observable, Subject, Subscription, forkJoin, of, zip } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import { BotJobsService } from '../../../admin/bot-jobs/bot-jobs.service'
import { TeleportLinkTableRowComponent } from '../teleport-link-table-row/teleport-link-table-row.component'

type LinkTableType = 'harId' | 'patientMrn'
export type BillingModule = 'pb' | 'hb'

type BotType = { procedureSet: ProcedureSet | ProcedureSetTest }
type RowConfigData = BotType & {
  required: boolean
  submitTeleportEnabled?: boolean
  authRequestTeleportEnabled?: boolean
}

@Component({
  selector: 'app-teleport-link-table',
  templateUrl: './teleport-link-table.component.html',
  styleUrls: ['./teleport-link-table.component.scss'],
  host: {
    class: 'no-wrapper-box no-gutters',
  },
})
export class TeleportLinkTableComponent implements OnInit {
  @Input() type: LinkTableType
  @Input() listId: string
  @Input() billingModules: BillingModule[] | null = null
  // Input added for usePriorAuthFollowUpTeleport strictly for tests. Value to be passed in as component param for tests
  @Input() usePriorAuthFollowUpTeleport: boolean
  rowConfigs: TeleportRowMetaData[]
  testRowConfigs: TeleportRowMetaData[]
  authStatusRowConfigs: ProcedureSetRowConfig[]
  testAuthStatusRowConfigs: ProcedureSetRowConfig[]
  contactRowConfigs: ProcedureSetRowConfig[]
  completeReferralRowConfigs: ProcedureSetRowConfig[]
  @ViewChildren(TeleportLinkTableRowComponent)
  links: QueryList<TeleportLinkTableRowComponent>

  rowOrdering: string[]
  isLoading: boolean = true
  orgId: string

  private destroy$ = new Subject<void>()
  private currentSubscription: Subscription
  private initialized = false

  constructor(
    private authenticationService: AuthenticationService,
    private portalMapService: PortalMapService,
    private procedureSetsService: ProcedureSetsService,
    private claimsService: ClaimsService,
    private toast: ToastService,
    private teleportService: TeleportService,
    private botjobsService: BotJobsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initializeTable()
    this.orgId = this.authenticationService.getUser().orgId
    this.initialized = true
  }

  ngOnChanges(): void {
    // Wait for initialization
    if (this.initialized) {
      this.initializeTable()
    }
  }

  private initializeTable() {
    this.setRowOrdering()
    // In the event of back and forth on same page
    this.currentSubscription?.unsubscribe()

    switch (this.type) {
      case 'patientMrn':
        this.currentSubscription = this.makePatientMrnTable().subscribe()
        break

      case 'harId':
        this.currentSubscription = this.makeHarIdTable().subscribe()
        break
    }
  }

  onLinkLoaded(event: { canTeleport: boolean; listId: number }): void {
    const rowConfig = this.rowConfigs.find((rc) => rc.listId === event.listId)
    rowConfig.canTeleport = event.canTeleport
    this.updateRowDisplay()
    // Auto-click the sole record if this is the only enabled teleport
    if (
      this.rowConfigs.length === 1 &&
      this.rowConfigs[0].canTeleport &&
      !(this.contactRowConfigs && this.contactRowConfigs.length !== 0) &&
      !this.authStatusRowConfigs?.length
    ) {
      this.links.first.launchTeleport()
    }
  }

  onStatusUpdated(obj: ProcedureSet): void {
    this.contactRowConfigs = this.contactRowConfigs.map((row) => {
      if (row.typeDef.id === obj.id) {
        row.typeDef.procedures = obj.procedures
      }
      return row
    })

    this.contactRowConfigs = this.contactRowConfigs.filter((row) => {
      if (row.typeDef.id === obj.id) {
        if (obj.status === 'ARC') {
          this.rowConfigs.push(row)
          return false
        } else if (obj.status === 'NA') {
          return false
        }
      }

      return true
    })
  }

  private makePatientMrnTable(): Observable<
    {
      procedureSet: ProcedureSet | ProcedureSetTest
      required: boolean
      submitTeleportEnabled?: boolean
      authRequestTeleportEnabled?: boolean
    }[]
  > {
    this.rowConfigs = []
    this.contactRowConfigs = []
    this.completeReferralRowConfigs = []
    this.authStatusRowConfigs = []

    return zip(
      this.procedureSetsService.getProcedureSetTests(this.listId),
      this.procedureSetsService.getProcedureSets(this.listId)
    ).pipe(
      filter(([procedureSetTests, procedureSets]) => procedureSetTests.length > 0 || procedureSets.length > 0),
      switchMap(([procedureSetTests, procedureSets]) => {
        const allProcedureSets = [...procedureSetTests, ...procedureSets];
        return this.getUploadRequired(allProcedureSets);
      }),
      switchMap((results) => this.getTeleportEnabled(results, 'submitAuthRequest')),
      switchMap((results) => this.getTeleportEnabled(results, 'priorAuthFollowUp')),
      tap((results) => this.configureProcedureSetRows(results)),
      tap(() => (this.isLoading = false)),
      takeUntil(this.destroy$)
    );
  }

  private getTeleportEnabled(
    results: {
      procedureSet: ProcedureSet | ProcedureSetTest
      required: boolean
      submitTeleportEnabled?: boolean
      authRequestTeleportEnabled?: boolean
    }[],
    botType: ProcedureSetBotType
  ): Observable<
    {
      procedureSet: ProcedureSet | ProcedureSetTest
      submitTeleportEnabled?: boolean
      authRequestTeleportEnabled?: boolean
      required: boolean
    }[]
  > {
    return forkJoin(
      results.map(({ procedureSet, required, authRequestTeleportEnabled, submitTeleportEnabled }) => {
        const codes = procedureSet.procedures.filter((i) => !isNil(i.setIndex)).sort((a, b) => a.setIndex - b.setIndex)
        const primaryProcedureCode = codes?.[0].code

        return this.portalMapService
          .getPortalMap(procedureSet.payerPlanId, this.orgId, primaryProcedureCode, botType)
          .pipe(
            map((result) => {
              return {
                procedureSet,
                submitTeleportEnabled: botType === 'submitAuthRequest' ? result.teleportIsEnabled : submitTeleportEnabled,
                authRequestTeleportEnabled: botType === 'priorAuthFollowUp' ? result.teleportIsEnabled : authRequestTeleportEnabled,
                required,
              }
            }),
            catchError((error) => {
              return of({ procedureSet,
                submitTeleportEnabled: botType === 'submitAuthRequest' ? false : submitTeleportEnabled,
                authRequestTeleportEnabled: botType === 'priorAuthFollowUp' ? false : authRequestTeleportEnabled,
                required })
            }),
          )
      }),
    )
  }

  private getUploadRequired(
    procedureSets: (ProcedureSet | ProcedureSetTest)[],
  ): Observable<{ procedureSet: ProcedureSet | ProcedureSetTest; required: boolean }[]> {
    return forkJoin(
      procedureSets.map((procedureSet) =>
        this.teleportService.docUploadRequired(procedureSet).pipe(map((required) => ({ procedureSet, required }))),
      ),
    )
  }

  /**
   * filters row data so that we list only the most recent record for each unique epicReferralId
   * 
   * @param rowConfigs row data to filter
   * @returns filtered rowConfigs
   */
  private filterProcedureSetRows(rowConfigs: RowConfigData[]): RowConfigData[] {
    const filteredConfigs: RowConfigData[] = []
    const configsGroupedByReferralId = groupBy(rowConfigs, 'procedureSet.epicReferralId')
    for(const referralId of Object.keys(configsGroupedByReferralId)) {
      configsGroupedByReferralId[referralId].sort((a, b) => {
        const aDate = parseInt(a.procedureSet.createdAt)
        const bDate = parseInt(b.procedureSet.createdAt)
        return bDate - aDate
      })

      filteredConfigs.push(configsGroupedByReferralId[referralId][0])
    }
    return filteredConfigs
  }

  private configureProcedureSetRows(results: RowConfigData[]): void {

    const submitRowData: RowConfigData[] = []
    const contactRowData: RowConfigData[] = []
    const completeReferralRowData: RowConfigData[] = []
    const authStatusRowData: RowConfigData[] = []
    const testSubmitRowData: RowConfigData[] = []
    const testAuthStatusRowData: RowConfigData[] = []

    //sort and filter rowData into its appropriate table category
    results.forEach((rowData) => {
      const procedureSet = rowData.procedureSet

      let has278R = false
      let has278I = false
      if (!procedureSet.isTest) { // Allow test records with no related edi message to display
        has278R = !!procedureSet.ediTransactions.find((e) => e.type === '278R')
        has278I = !!procedureSet.ediTransactions.find((e) => e.type === '278I')
      }

      if (procedureSet.isTest) { // Set up test record rows
        if (procedureSet.requiresPriorAuth && rowData.submitTeleportEnabled) {
          testSubmitRowData.push(rowData)
        }
        if(rowData.authRequestTeleportEnabled){
          testAuthStatusRowData.push(rowData)
        }
      } else { // Set up non-test rows
        if(has278R) { 
          if (procedureSet.status === 'CT' && !procedureSet.isTest) {
            contactRowData.push(rowData)
          } else if (procedureSet.status === '51' && !procedureSet.isTest) {
            completeReferralRowData.push(rowData)
          } else if (procedureSet.requiresPriorAuth && rowData.submitTeleportEnabled) {
            submitRowData.push(rowData)
          } else if (procedureSet.status === 'A4' && procedureSet.priorAuthCaseEvents.some(caseEvents => caseEvents.pendingReasonCode === '0U') && !procedureSet.isTest) { // This is the status and pending reason for intellipath doc needed
            submitRowData.push(rowData)
          }
        }
        
        if(has278I && rowData.authRequestTeleportEnabled){
          authStatusRowData.push(rowData)
        }
      }
    })

    // create configs from the data in the table rows
    this.contactRowConfigs = this.convertRowDataToProcedureSetConfigs(this.filterProcedureSetRows(contactRowData), 'submitAuthRequest')
    this.authStatusRowConfigs = this.convertRowDataToProcedureSetConfigs(this.filterProcedureSetRows(authStatusRowData), 'priorAuthFollowUp')
    this.completeReferralRowConfigs = this.convertRowDataToProcedureSetConfigs(completeReferralRowData, 'submitAuthRequest')
    this.rowConfigs = this.convertRowDataToProcedureSetConfigs(this.filterProcedureSetRows(submitRowData), 'submitAuthRequest')
    this.testAuthStatusRowConfigs = this.convertRowDataToProcedureSetConfigs(this.filterProcedureSetRows(testAuthStatusRowData), 'priorAuthFollowUp')
    this.testRowConfigs = this.convertRowDataToProcedureSetConfigs(testSubmitRowData, 'submitAuthRequest')
  }

  private convertRowDataToProcedureSetConfigs(rowData: RowConfigData[], botType: ProcedureSetBotType): ProcedureSetRowConfig[] {
    return rowData.map(({ procedureSet, required }, listId) => {
      return {
        portal: procedureSet.priorAuthPortal,
        serviceDates: {
          startDate: procedureSet.serviceDateStart as Date,
          endDate: procedureSet.serviceDateEnd as Date,
        },
        payer: procedureSet.payer,
        rowType: 'procedureSet',
        listId,
        docUploadRequired: required,
        typeDef: {
          id: procedureSet.id,
          procedures: procedureSet.procedures.map((p) => ({
            id: p.id,
            code: p.code,
            description: p.description,
            status: p.status,
            setIndex: p.setIndex
          })),
          payerPlan: procedureSet.payerPlan,
        },
        isTest: procedureSet.isTest,
        botType,
        epicReferralId: parseInt(procedureSet.epicReferralId),
      }
    })
  }

  private makeHarIdTable(): Observable<ClaimRowConfig[]> {
    let search = `harId: (${this.listId})`
    // If we have a single billing module filter, add it to the search
    // If both billing modules are included (pb and hb), no need to filter
    if (this.billingModules && this.billingModules.length === 1) {
      search += ` AND billingModule:(${this.billingModules[0]})`
    }
    return this.claimsService.getClaimsObservable(ClaimsForTeleport, { search }, false).pipe(
      catchError((e) => {
        this.toast.error(parseGraphQLError(e, 'Could not fetch claims from that account'), JSON.stringify(e))
        return of([] as Claim[])
      }),
      mergeMap((claims) => {
        if (!claims.length) return of([])
        return forkJoin(
          claims.map((claim) =>
            this.botjobsService.botJobFromClaim({ claim }).pipe(
              take(1),
              map((botjob) => ({ claim, botjob })),
            ),
          ),
        ).pipe(
          catchError((e) => {
            this.toast.error(parseGraphQLError(e, 'Could not fetch bot jobs'), JSON.stringify(e))
            return of([])
          }),
        )
      }),
      map((data) => {
        return data.map(
          ({ claim, botjob }, id): ClaimRowConfig => ({
            portal: botjob?.portal ?? 'unknown',
            botPayer: botjob?.payer?.toLowerCase(),
            rowType: 'claim',
            listId: id,
            payer: claim.payer,
            serviceDates: this.claimsService.getClaimServiceDates(claim),
            docUploadRequired: false,
            typeDef: {
              id: claim.id,
              outstandingAmount: claim.outstandingAmount,
              claimAmount: claim.claimAmount,
              providerClaimId: claim.providerClaimId,
            },
            botType: 'getClaimStatus', // TODO: make this a configurable
            reuseTab: botjob?.reuseTab,
          }),
        )
      }),
      tap((rowConfigs) => {
        this.rowConfigs = rowConfigs
        this.updateRowDisplay()
        this.isLoading = false
      }),
      takeUntil(this.destroy$),
    )
  }

  private updateRowDisplay(): void {
    let rowsLoading = this.rowConfigs.filter((row) => row.canTeleport === undefined)
    let rowsActive = this.rowConfigs.filter((row) => row.canTeleport)
    let rowsInactive = this.rowConfigs.filter((row) => row.canTeleport === false)

    this.rowConfigs = [
      ...sortBy(rowsLoading, this.rowOrdering, ['asc', 'desc']),
      ...sortBy(rowsActive, this.rowOrdering, ['asc', 'desc']),
      ...sortBy(rowsInactive, this.rowOrdering, ['asc', 'desc']),
    ]
  }

  private setRowOrdering() {
    switch (this.type) {
      case 'patientMrn':
        this.rowOrdering = ['payer.name', 'typeDef.providerClaimId']
        break
      case 'harId':
        this.rowOrdering = ['payer.name', 'serviceDates.start']
        break
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
