<div class="d-flex flex-column py-4">
  <div class="d-flex flex-row flex-wrap justify-content-between">
    <div class="d-flex col-md-3 flex-column my-3">
      <span>PATIENT NAME</span>
      <span data-testid="patient-name"> {{ getFirstName() }} {{ getLastName() }}</span>
    </div>
    <div class="d-flex col-md-3 flex-column my-3">
      <span>MRN</span>
      <span data-testid="patient-mrn">{{ procedureSet.patientMrn }}</span>
    </div>
    <div class="d-flex col-md-3 flex-column my-3">
      <span>CASE</span>
      <span data-testid="case-ids">{{ formatCaseNumbers(procedureSet.procedures) }}</span>
    </div>
    <div class="d-flex col-md-3 flex-column my-3">
      <span>SERVICE DATE</span>
      <span data-testid="service-date">{{ formatServiceDate(procedureSet.serviceDateStart) }}</span>
    </div>
  </div>

  <div class="d-flex flex-row flex-wrap justify-content-between">
    <div class="d-flex col-md-6 flex-column my-3">
      <span>PROCEDURE</span>
      <ul>
        <li *ngFor="let procedure of procedureSet.procedures" data-testid="procedure">
          {{ formatProcedure(procedure) }}
        </li>
      </ul>
    </div>
    <div class="d-flex col-md-6 flex-column my-3">
      <span>PAYER</span>
      <span data-testid="payer-name">{{ procedureSet.payerPlan?.payerName ?? procedureSet.payer?.name }}</span>
    </div>
  </div>
</div>
