import { Component } from '@angular/core'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'

/**
 * Wrapper for every page requiring a user to be authorized
 *
 * @export
 * @class LoggedInPage
 */
@Component({
  selector: 'app-logged-in-page',
  templateUrl: './logged-in.page.html',
  styleUrls: ['./logged-in.page.scss'],
  host: {
    class: 'd-flex flex-column h-100vh',
  },
})
export class LoggedInPage {
  constructor(public breadcrumbService: BreadcrumbsService) {}
}
