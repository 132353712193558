import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetTaskStats } from 'app/reporting/stats.gql'
import { debug } from 'app/shared/utils/debug'
import { QueryTaskStatsArgs, TaskStats } from 'generated/graphql'

/**
 * Handle retrieving an organization's task statistics
 *
 * @export
 * @class TaskStatsService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskStatsService {
  constructor(private apollo: Apollo) {}

  /**
   * Retrieve task stats based on time period and user
   *
   * @param {string} start
   * @param {string} end
   * @param {string} [user]
   * @return {*}  {Promise<TaskStats[]>}
   * @memberof TaskStatsService
   */
  async getTaskStats(start: string, end: string, user?: string): Promise<TaskStats[]> {
    debug('task-stats', 'get task stats', start, end, user)
    let result = await this.apollo
      .query<{ taskStats: TaskStats[] }, QueryTaskStatsArgs>({
        query: GetTaskStats,
        variables: {
          startDate: new Date(start).toISOString(),
          endDate: new Date(end).toISOString(),
          userId: user,
          tzOffset: new Date().getTimezoneOffset() / 60,
        },
        fetchPolicy: 'network-only',
      })
      .toPromise()

    return result.data.taskStats
  }
}
