import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { TaskGroupFilterComponent } from 'app/pathfinder/task-groups/components/task-group-filter/task-group-filter.component'
import { TaskGroupsService, TASK_GROUPS_PAGE_LIMIT } from 'app/pathfinder/task-groups/task-groups.service'
import { TaskGroupStatsService } from 'app/reporting/task-group-stats.service'
import { TaskStatsService } from 'app/reporting/task-stats.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ChartDataset, ChartOptions } from 'chart.js'
import { ListResponseMetaData, TaskGroup, TaskGroupStats, User } from 'generated/graphql'
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, first, skip, startWith, takeUntil } from 'rxjs/operators'

type MomentPoint = {
  x: string
  y: number
}

/**
 * Page to display a list of task groups
 *
 * @export
 * @class TaskGroupsPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-task-groups-page',
  templateUrl: './task-groups.page.html',
  styleUrls: ['./task-groups.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class TaskGroupsPage implements OnInit, OnDestroy {
  isOverviewCollapsed: boolean = true

  taskGroups: TaskGroup[] = []
  taskGroupStats: { [key: string]: TaskGroupStats } = {} // taskGroupStats[taskGroup.id] === TaskGroupStats for that TG

  searchField: UntypedFormControl = new UntypedFormControl('')
  destroy$ = new Subject<void>()

  searching: boolean = false
  loadingMore: boolean = false
  taskGroupsMeta: ListResponseMetaData
  isShowingAllResults: boolean = false

  sort: BehaviorSubject<string> = new BehaviorSubject<string>('score:asc')
  user: BehaviorSubject<User> = new BehaviorSubject<User>(null)
  includeCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  searchOffset: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  weeklyAverage = 0
  weeklyCompleted: ChartDataset<'line', MomentPoint[]>[] = []
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {
        type: 'timeseries',
        time: {
          unit: 'week',
        },
        grid: {
          offset: false
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Tasks Completed',
        },
        type: 'linear',
        suggestedMin: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
  }
  chartColors= {
    borderColor: '#638eb9',
    backgroundColor: '#638eb9',
    pointBackgroundColor: '#638eb9',
    pointBorderColor: 'white',
    pointBorderWidth: 3,
    pointStyle: 'rectRot',
    pointRadius: 10,
  }
  chartLegend = false

  /**
   * Helper to get current week's completed tasks
   *
   * @readonly
   * @type {number}
   * @memberof TaskGroupsPage
   */
  get lastWeeklyValue(): number {
    let dumb = this.weeklyCompleted[0]?.data[3]
    return dumb?.y
  }

  constructor(
    private taskGroupService: TaskGroupsService,
    private taskGroupStatsService: TaskGroupStatsService,
    private taskStatsService: TaskStatsService,
    public authenticationService: AuthenticationService,
    private userService: UsersService,
    private modal: NgbModal,
    private toast: ToastService,
    public org: OrgService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.pipe(first()).subscribe(async (params) => {
      let { sort, completed, search, offset, user } = params
      this.sort.next(sort || 'score:asc')
      this.includeCompleted.next(!!completed)
      this.searchField.setValue(search || '')
      this.searchOffset.next(parseInt(offset || '0'))
      this.user.next(
        user
          ? await this.userService
              .getUser(user)
              .pipe(first())
              .toPromise()
              .catch((e) => null)
          : null,
      )
    })
    this.route.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe(async (paramMap) => {
      let sort = paramMap.get('sort') || 'score:asc'
      let completed = paramMap.get('completed')
      let search = paramMap.get('search')
      let userId = paramMap.get('user')
      let offset = parseInt(paramMap.get('offset') || '0')

      if (!offset) {
        this.taskGroups = []
      }

      let adjustedOffset = offset || TASK_GROUPS_PAGE_LIMIT
      let pagedCalls = new Array(this.taskGroups.length ? 1 : adjustedOffset / TASK_GROUPS_PAGE_LIMIT).fill(
        offset || this.taskGroups.length,
      )

      this.searching = true
      if (pagedCalls.length > 1) {
        this.loadingMore = true
      }
      await Promise.all(
        pagedCalls.map((value, i) =>
          this.search(search, userId, sort, TASK_GROUPS_PAGE_LIMIT * i + value, !!completed).then(
            (taskGroups) => (this.taskGroups = this.taskGroups.concat(taskGroups)),
          ),
        ),
      )
      this.searching = false
      this.loadingMore = false

      this.isShowingAllResults = this.taskGroupsMeta ? this.taskGroupsMeta.total <= this.taskGroups?.length : true
    })

    combineLatest([
      this.searchField.valueChanges.pipe(debounceTime(500), distinctUntilChanged(), startWith('')),
      this.sort,
      this.user,
      this.includeCompleted,
      this.searchOffset,
    ])
      .pipe(skip(1), debounceTime(100), takeUntil(this.destroy$))
      .subscribe(async () => {
        let queryParams = {
          search: this.searchField.value || null,
          user: this.user.value?.id || null,
          completed: this.includeCompleted.value || null,
          offset: this.searchOffset.value || null,
        }

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge',
        })
      })

    let stats = await this.taskStatsService.getTaskStats(
      moment().subtract(3, 'weeks').format('L'),
      moment().add(1, 'd').format('L'),
      this.authenticationService.getUser().id,
    )

    let data: MomentPoint[] = [
      { x: moment().subtract(3, 'w').format('LL'), y: 0 },
      { x: moment().subtract(2, 'w').format('LL'), y: 0 },
      { x: moment().subtract(1, 'w').format('LL'), y: 0 },
      { x: moment().format('LL'), y: 0 },
    ]

    stats.forEach((stat) => {
      let index = -1

      data.some((d, i) => {
        let isSame = moment(stat.day).isSame(d.x, 'week')
        if (isSame) {
          index = i
        }
        return isSame
      })

      if (index > -1) {
        data[index].y += stat.completed
      }
    })

    this.weeklyCompleted = [{ label: '', type: 'line', data, ...this.chartColors }]
    this.weeklyAverage = Math.round(
      data.reduce((acc, cur) => {
        return acc + cur.y
      }, 0) / data.length,
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Search task groups
   *
   * @param {string} search
   * @param {string} userId
   * @param {string} sort
   * @param {number} offset
   * @param {boolean} completed
   * @return {*}  {Promise<TaskGroup[]>}
   * @memberof TaskGroupsPage
   */
  async search(search: string, userId: string, sort: string, offset: number, completed: boolean): Promise<TaskGroup[]> {
    try {
      let taskGroups = await this.taskGroupService.searchTaskGroups(search, userId, sort, offset, completed)
      this.taskGroupsMeta = await this.taskGroupService.getMeta()
      this.setTaskGroupStats(taskGroups)
      return taskGroups
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load task groups'), JSON.stringify(e))
      return []
    }
  }

  /**
   * Load stats for all displayed task groups
   *
   * @private
   * @param {TaskGroup[]} taskGroups
   * @memberof TaskGroupsPage
   */
  private setTaskGroupStats(taskGroups: TaskGroup[]): void {
    taskGroups.forEach(async (tg) => {
      try {
        this.taskGroupStats[tg.id] = await this.taskGroupStatsService.getTaskGroupStats(tg.id)
      } catch (e) {
        debug('taskGroups', 'failed to load task group stats', JSON.stringify(e))
      }
    })
  }

  /**
   * Load more task groups
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskGroupsPage
   */
  async loadMore(): Promise<void> {
    this.searchOffset.next(this.searchOffset.value + TASK_GROUPS_PAGE_LIMIT)
  }

  /**
   * Launch modal to allow filtering the list of task groups
   *
   * @memberof TaskGroupsPage
   */
  toggleFilters(): void {
    const modalRef = this.modal.open(TaskGroupFilterComponent, {
      centered: true,
      backdrop: true,
      windowClass: 'modal-extra-padding',
    })

    modalRef.componentInstance.selectedUser = this.user.value || null
    modalRef.componentInstance.includeCompleted = this.includeCompleted.value

    debug('taskGroups', 'toggle modal starting as', this.includeCompleted.value)

    modalRef.result.then(
      (closed) => {
        this.searchOffset.next(0)
        this.user.next(closed?.user)
        this.includeCompleted.next(closed?.includeCompleted)
      },
      (dismissed) => {},
    )
  }
}
