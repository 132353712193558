import { HttpRequest } from '@angular/common/http'
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'

export abstract class AnalyticsBaseService {
  router: Router

  /**
   * The named attribute used to hide data from being sent to anayltics.
   * When set, you can use the `private` directive on any element.
   *
   * Example Directive Usage: `<div private> My sensitive data< /div>`
   *
   * Using an attribute value will result in  - `<div data-private />`
   *
   * @example "data-private"
   */
  privateAttribute = ''

  /**
   * Sends any metric to be tracked to analytics platform.
   * @param key Reference key to track.
   * @param obj An object used to send relevant data associated with the key to the analytics platform.
   */
  event?<T = any>(key: string, obj?: T): void
  /**
   * Sends errors to be tracked to analytics platform.
   * @param key Reference key to track.
   * @param obj An object used to send relevant Error data to the analytics platform.
   */
  error?(err: Error, obj?: unknown): void

  /**
   * Fired when the app starts.
   * Where you are initializing 3rd party analytics SDKs to which we're sending events to.
   */
  abstract initialize(): Promise<void>
  /**
   * Fired when a new route has begun to load but not yet finished.
   */
  protected onNavigationStarted?(event: NavigationStart): void
  /**
   * Fired when a new route has loaded.
   */
  protected onNavigationEnded?(event: NavigationEnd): void
  /**
   * Fired when there is an error loading a route.
   */
  protected onNavigationError?(event: NavigationError): void
  /**
   * Fired from the track api interceptor on each API request.
   */
  protected onApiRequest?(req: HttpRequest<any>): void
  /**
   * Fired from the track api interceptor on each GraphQL request.
   */
  protected onGqlRequest?(req: HttpRequest<any>): void

  constructor(router: Router) {
    this.router = router
    this._startRouteWatcher()
  }

  /**
   * Subscribes to router events to fire off methods defined in derived tracking services.
   */
  private _startRouteWatcher() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.onNavigationStarted?.(event)
      }

      if (event instanceof NavigationEnd) {
        this.onNavigationEnded?.(event)
      }

      if (event instanceof NavigationError) {
        this.onNavigationError?.(event)
      }
    })
  }
}
