<app-auth-layout-component>
  <div class="d-flex justify-content-center" *ngIf="error">
    <div class="d-flex alert alert-danger mb-4" role="alert" [innerHtml]="error"></div>
  </div>
  <ng-container [ngSwitch]="status">
    <div *ngSwitchCase="'EXPIRED'">
      <app-auth-error-card title="Reactivate Your Account">
        <p>This link has expired.</p>
        <p>Request a new confirmation link to get started.</p>
        <div class="pt-3">
          <button type="button" (click)="requestConfirmation()" class="btn bg-primary text-light">
            <i class="fa fa-send fa-fw text-light"></i> Send Confirmation Email
          </button>
        </div>
      </app-auth-error-card>
    </div>

    <div *ngSwitchCase="'EXPIRED_SENT'">
      <app-auth-error-card title="Confirmation Email Sent">
        <p>Please check your e-mail.</p>
        <p>A new confirmation link has been sent to you.</p>
      </app-auth-error-card>
    </div>

    <div *ngSwitchCase="'INVALID'">
      <app-auth-error-card title="Invalid Link">
        <p>The link you are trying to access requires authorization.</p>
        <div class="pt-3">
          <a [routerLink]="['/login']">Go To Login</a>
        </div>
      </app-auth-error-card>
    </div>

    <div *ngSwitchCase="'SUCCESS'">
      <app-auth-error-card title="Password Created">
        <p>Your password has been successfully created!</p>
        <div class="pt-3">
          <a [routerLink]="['/login']">Go To Login</a>
        </div>
      </app-auth-error-card>
    </div>

    <div *ngSwitchCase="'PASSWORD'">
      <div class="w-75 mb-4 mx-auto">
        <h3>Create Password</h3>
      </div>
      <app-password buttonText="Submit" [disable]="submitting" [showForgot]="false" [validateOnServer]="true" [hasConfirm]="true" [loading]="submitting"
        (onSubmit)="submit($event)">
      </app-password>
    </div>
    <div *ngSwitchCase="'LOADING'">
      <app-loading innerClass="fa-3x" class="d-flex justify-content-center text-brand-light-blue"></app-loading>
    </div>
  </ng-container>
</app-auth-layout-component>