<div class="d-flex flex-column flex-basis-0 flex-grow-1">
  <div class="row">
    <div class="col d-flex align-items-center">
      <h1 class="h3">{{(illuminate.currentWorkflowGroup | async)?.name}}</h1>
      <button
        class="btn btn-sm mx-4"
        [ngClass]="isAnimating ? 'btn-danger' : 'btn-success'"
        (click)="isAnimating = !isAnimating"
      >
        <i class="fa fa-fw" [ngClass]="isAnimating ? 'fa-stop' : 'fa-play'"></i>
      </button>
    </div>
  </div>
  <div class="row flex-grow-1">
    <app-illuminate-graph-panel [isAnimating]="isAnimating" class="col"></app-illuminate-graph-panel>
  </div>
</div>
