import { Component, Input, OnChanges } from '@angular/core'
import { Router } from '@angular/router'
import { ChartDataset, ChartOptions } from 'chart.js'
import { BotRun } from 'generated/graphql'
import * as moment from 'moment'

/**
 * Component to display a graph of bot runs
 *
 * @export
 * @class BotRunsGraphComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-bot-runs-graph',
  templateUrl: './bot-runs-graph.component.html',
  styleUrls: ['./bot-runs-graph.component.scss'],
  host: {
    '[class.d-flex]': 'true',
    '[class.flex-column]': 'true',
    '[class.flex-grow-1]': 'true',
  },
})
export class BotRunsGraphComponent implements OnChanges {
  @Input() botRuns: BotRun[]
  @Input() selectedBotRunId: string
  @Input() botId: string
  chartLabels: string[]
  chartData: ChartDataset[]
  chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x:{
          ticks: {
            display: true,
            autoSkip: true,
            maxTicksLimit: 2,
            maxRotation: 20,
            minRotation: 0,
          },
        },
      y: {
          title: {
            display: true,
            text: `Runtime (seconds)`,
          }
        },
    },
    plugins: {
      tooltip: {
        callbacks: {},
      }
    }
  }
  chartLegend = false

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.setChartData()
  }

  /**
   * Set chart data based on bot runs
   *
   * @memberof BotRunsGraphComponent
   */
  setChartData(): void {
    let ranBots = this.botRuns
      ?.filter((botRun) => botRun.runStartAt && botRun.runEndAt)
      .sort((a, b) => a.createdAt?.localeCompare(b.createdAt))
    if (ranBots) {
      this.chartLabels = ranBots.map((botRun) => moment(botRun.runEndAt).format('MM/DD/YYYY hh:mm a'))
      let botRunTimes = ranBots
        .map((botRun) => moment(botRun.runEndAt).diff(moment(botRun.runStartAt), 'seconds'))
        .filter((botRunTime) => botRunTime > 0)
      let chartColors = ranBots.map((botRun) => {
        if (botRun.id === this.selectedBotRunId) {
          return 'rgba(54, 162, 235, 0.4)'
        } else if (botRun.status === 'SUCCEEDED') {
          return 'rgba(75, 192, 192, 0.4)'
        } else if (botRun.status === 'FAILED') {
          return 'rgba(255, 99, 132, 0.4)'
        } else if (
          botRun.status === 'UNSTABLE' ||
          botRun.status === 'MISSING_DATA' ||
          botRun.status === 'CLAIM_NOT_FOUND'
        ) {
          return 'rgba(255, 205, 86, 0.4)'
        } else {
          return 'rgba(201, 203, 207, 0.4)'
        }
      })
      let borderColors = ranBots.map((botRun) => {
        if (botRun.id === this.selectedBotRunId) {
          return 'rgb(54, 162, 235)'
        } else if (botRun.status === 'SUCCEEDED') {
          return 'rgb(75, 192, 192)'
        } else if (botRun.status === 'FAILED') {
          return 'rgb(255, 99, 132)'
        } else if (
          botRun.status === 'UNSTABLE' ||
          botRun.status === 'MISSING_DATA' ||
          botRun.status === 'CLAIM_NOT_FOUND'
        ) {
          return 'rgb(255, 205, 86)'
        } else {
          return 'rgb(201, 203, 207)'
        }
      })
      this.chartData = [
        {
          data: botRunTimes,
          label: 'Run time (sec)',
          backgroundColor: chartColors,
          borderColor: borderColors,
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(54, 162, 235)',
          hoverBorderWidth: 0,
        },
      ]
      this.chartOptions.onClick = (event, activeElement) => {
        let clickedRun: string = ranBots[activeElement[0]['_index']]?.id?.toString()
        this.router.navigate(['revbots', this.botId, clickedRun])
      }
      this.chartOptions.plugins.tooltip.callbacks.footer = (item: any[]) =>
        'Bot ID: ' + (ranBots[item[0]['index']]?.id?.toString() || '')
    }
  }
}
