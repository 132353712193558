import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FeatureFlags, FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'
import { TaskCardActionsService } from './task-types/task-card-actions.service'

/**
 * Page to display available admin actions
 *
 * @export
 * @class AdminPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-page',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
  host: { class: 'h-100' },
})
export class AdminPage implements OnInit, OnDestroy {
  routerSub$: Subscription
  isWorkflowsEnabled = false
  isObserveAclEnabled = false
  isQediEnabled = false
  triggerRoute$ = new Subject<boolean>()
  constructor(
    private router: Router,
    private actionService: TaskCardActionsService,
    private featureFlagService: FeatureFlagService,
    public authenticationService: AuthenticationService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(filter(([event, trigger]) => event instanceof NavigationEnd || trigger))
      .subscribe((event) => {
        actionService.hidden = []
      })
  }

  async ngOnInit(): Promise<void> {
    this.triggerRoute$.next(true)
    this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.qedi.root).then((isQediEnabled) => {
      this.isQediEnabled = isQediEnabled
    })

    this.isWorkflowsEnabled = await this.featureFlagService.getFeatureFlagValue(FeatureFlags.ui.workflows)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }
}
