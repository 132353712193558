import { Injectable } from '@angular/core'
import { TaskType } from 'generated/graphql'
import { Subject } from 'rxjs'

/**
 * Handle task type card interactions
 *
 * @export
 * @class TaskCardActionsService
 */
@Injectable({
  providedIn: 'root',
})
export class TaskCardActionsService {
  /*
    This is used b/c the task-type-card component is used in several ways,
    at varying depths of relationship to the actual handler of the @Output events

    Since @Output events necessitate a close coupling of components, it would be
    dirty to use @Output in this case.

    Instead, subscribe to the *Clicked$ observable wherever you need to handle
    the task-type-card actions
  */

  private pencilSource = new Subject<TaskType>()
  private rightSource = new Subject<TaskType>()
  private removeSource = new Subject<TaskType>()
  private twoRightSource = new Subject<TaskType>()
  private hideCardSource = new Subject<{ id: string; hide: boolean }>()

  pencilClicked$ = this.pencilSource.asObservable()
  rightClicked$ = this.rightSource.asObservable()
  removeClicked$ = this.removeSource.asObservable()
  twoRightClicked = this.twoRightSource.asObservable()
  hideCard$ = this.hideCardSource.asObservable()

  hidden: string[] = []

  constructor() {}

  /**
   * User clicked edit icon
   *
   * @param {TaskType} taskType
   * @memberof TaskCardActionsService
   */
  clickPencil(taskType: TaskType): void {
    this.pencilSource.next(taskType)
  }

  /**
   * User clicked single arrow icon
   *
   * @param {TaskType} taskType
   * @memberof TaskCardActionsService
   */
  clickRight(taskType: TaskType): void {
    this.rightSource.next(taskType)
  }

  /**
   * User clicked remove icon
   *
   * @param {TaskType} taskType
   * @memberof TaskCardActionsService
   */
  clickRemove(taskType: TaskType): void {
    this.removeSource.next(taskType)
  }

  /**
   * User clicked double arrow icon
   *
   * @param {TaskType} taskType
   * @memberof TaskCardActionsService
   */
  clickTwoRight(taskType: TaskType): void {
    this.twoRightSource.next(taskType)
  }

  /**
   * User closed the card
   *
   * @param {TaskType} taskType
   * @param {boolean} hide
   * @memberof TaskCardActionsService
   */
  hideCard(taskType: TaskType, hide: boolean): void {
    this.hideCardSource.next({ id: taskType.id, hide: hide })
  }
}
