import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationStart, Router } from '@angular/router'
import { PayersService } from 'app/shared/services/payers.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Payer } from 'generated/graphql'
import { of, Subscription } from 'rxjs'
import { catchError, debounceTime, filter, tap } from 'rxjs/operators'
import { PayerCardActionsService } from '../../payer-card-actions.service'

/**
 * Component to display and allow searching list of payers
 *
 * @export
 * @class PayerSearchComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-payer-search',
  templateUrl: './payer-search.component.html',
})
export class PayerSearchComponent implements OnInit {
  @Input() showRight = ''
  @Input() showRemove = ''
  @Input() showTwoRight = ''

  payers: Payer[]
  payerSearch = new UntypedFormControl('')
  isSearching: boolean = false
  totalPayers: number
  payerSearchSub$: Subscription

  hideSub$: Subscription

  routerSub$: Subscription

  constructor(
    private router: Router,
    private payerService: PayersService,
    private toast: ToastService,
    public actions: PayerCardActionsService,
  ) {
    this.routerSub$ = router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      this.onRouteChange()
    })
    this.hideSub$ = actions.hideCard$.subscribe(({ id, hide }) => {
      let payer = this.payers?.find((payer) => payer.id === id)
      if (payer) {
        if (hide) {
          this.actions.hidden.push(payer.id)
        } else {
          let id = this.actions.hidden?.findIndex((id) => id === payer.id)
          if (id > -1) {
            this.actions.hidden.splice(id, 1)
          }
        }
      }
    })
  }

  async ngOnInit(): Promise<void> {
    debug('payer-search', 'ngOnInit')
    this.payerSearchSub$ = this.payerSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap(async (term) => await this.searchPayers(term)),
        catchError((e) => {
          return of([])
        }),
      )
      .subscribe()

    await this.searchPayers('')
  }

  /**
   * Possibly reload the current search
   *
   * @return {*}  {Promise<void>}
   * @memberof PayerSearchComponent
   */
  async onRouteChange(): Promise<void> {
    let reloadSearch = this.router.getCurrentNavigation().extras?.state?.data?.reloadSearch
    if (reloadSearch) {
      this.payerSearch.updateValueAndValidity({ onlySelf: true, emitEvent: true })
    }
  }

  /**
   * Search payers
   *
   * @param {string} term
   * @return {*}  {Promise<void>}
   * @memberof PayerSearchComponent
   */
  async searchPayers(term: string): Promise<void> {
    this.isSearching = true
    try {
      this.payers = await this.payerService.searchPayers({ search: term })
      this.totalPayers = this.payerService.getMeta()?.total
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find payers'), JSON.stringify(e))
    }

    this.isSearching = false
  }
}
