<div class="container align-items-start p-5 bg-alt-white">
  <h4>
    <b class="text-primary">JANUS HEALTH TECHNOLOGIES, INC. </b>
  </h4>
  <h4><b class="text-primary">PRODUCT COOKIE POLICY</b></h4>
  <p><br /></p>
  <p>
    This “Product Cookie Policy” describes the different types of Cookies we use in connection with our Services. Please
    read this Product Cookie Policy for more information on how Janus uses Cookies on our Services and how to manage
    Cookies.
  </p>
  <p><br /></p>
  <p>
    If you would like to learn more about our data privacy practices, please refer to our
    <u><a routerLink="/policies/cookie-policy">Product Privacy Policy</a></u>
    and our Website Privacy Policy, or you can contact us at
    <a href="mailto:privacy@janus-ai.com"><u>privacy&commat;janus-ai.com</u></a
    >.
  </p>
  <p><br /></p>
  <p>
    Terms that are capitalized but not defined in this Product Cookie Policy will have the meanings given to them in our
    <u><a routerLink="/policies/cookie-policy">Product Privacy Policy</a></u
    >.
  </p>
  <p><br /></p>
  <ol>
    <li>
      <p><b class="h5 text-primary">What Are Cookies?</b></p>
    </li>
  </ol>
  <p>
    Cookies are small data files placed on your computer or mobile device that are set by the website provider or by
    third parties when you visit a website or other online service. The Cookies Janus uses provide content or features
    on our Services, help us remember you and your preferences, and improve your user experience by making our Services
    more efficient and relevant to you. You can find more information about cookies at:
    <a href="http://www.allaboutcookies.org/"> <u>www.allaboutcookies.org</u></a
    >.
  </p>
  <p><br /></p>
  <p>
    In addition to Cookies, we use other technologies that are similar in function, such as pixel tags, also called web
    beacons or single-pixel tags/gifs, and local or web storage.
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <b>Web Beacons:</b> Web beacons, or “clear gifs,” and single-pixel tags/gifs, or web tags, are tiny graphics
        with a unique identifier placed on a website or in an email that gather information about your interaction with
        that website or email. Because of their small size, they are not visible.
      </p>
    </li>

    <li>
      <p>
        <b>Flash Cookies: </b>Flash cookies, also known as local shared objects, are commonly used for videos and
        collect and store information about your use of a website and its video features.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <p>We use the term “Cookie” throughout this Product Cookie Policy to cover all these technologies.</p>
  <p><br /></p>
  <ol start="2">
    <li>
      <p><b class="h5 text-primary">Types of Cookies</b></p>
    </li>
  </ol>
  <p>
    Cookies are often described by who created and placed them and how long they last. Types of Cookies generally
    include the following:
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <i><b class="text-primary">Persistent Cookies and Session Cookies</b></i>
      </p>
      <ul>
        <li>
          <p>
            <b>Persistent Cookies:</b> A persistent Cookie stays in your browser and will be read by us when you return.
            A persistent Cookie helps us recognize you as an existing user, so it is easier for you to return and
            interact with our Services.
          </p>
        </li>

        <li>
          <p>
            <b>Session Cookies:</b> A session Cookie is temporary and enables you to move from page to page on our
            Services and allows information that you enter to be remembered. A session Cookie is deleted when you close
            your browser or after a short time.
          </p>
        </li>
      </ul>
    </li>
  </ul>

  <p><br /></p>
  <ul>
    <li>
      <p>
        <i><b class="text-primary">First-Party Cookies and Third-Party Cookies</b></i>
      </p>
      <ul>
        <li>
          <p>
            <b>First-party Cookies:</b> These are Cookies set by the publisher of the website or online service you are
            visiting. First-party Cookies may be set either by us or by a service provider at our request.
          </p>
        </li>

        <li>
          <p>
            <b>Third-party Cookies: </b>These are Cookies that are set by a party other than the publisher of the
            website you are visiting.
          </p>
        </li>
      </ul>
    </li>
  </ul>

  <p><br /></p>
  <ol start="3">
    <li>
      <p>
        <b class="h5 text-primary">What Types of Information Do Cookies Collect?</b>
      </p>
    </li>
  </ol>
  <p>
    Cookies collect different types of information depending on the type of Cookie and its purpose. Some examples of
    information that may be collected by Cookies when you use our Services include:
  </p>
  <ul>
    <li><p>The pages you visit;</p></li>
    <li><p>The buttons you click;</p></li>
    <li><p>The date and time you visit;</p></li>
    <li><p>The amount of time you spend on our Services;</p></li>

    <li>
      <p>The Internet Protocol (“IP”) address used to connect your computer/mobile phone to the internet; and</p>
    </li>

    <li>
      <p>
        Your device (such as computer, mobile phone) and connection information such as your browser type and version
        and operating system.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <ol start="4">
    <li>
      <p>
        <b class="h5 text-primary">How Do We Use Cookies?</b>
      </p>
    </li>
  </ol>
  <p>
    We, our service providers, or agents acting on our behalf, use the following persistent and session Cookies (which
    may be first-party or third-party Cookies) on our Services:
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <b>Strictly Necessary Cookies: </b>These Cookies are essential because they enable our Services to work
        properly, and they cannot be disabled. Without these Cookies, some of our Services cannot be provided. These
        Cookies do not gather information about you for marketing purposes. For example, these Cookies are used to:
      </p>
      <ul>
        <li>
          <p>Remember the information that you fill in when performing certain activities on your Services;</p>
        </li>

        <li>
          <p>Pass information from one page to the next, for example when filling out forms;</p>
        </li>

        <li><p>Provide our Services according to your preferences;</p></li>

        <li>
          <p>Read your browser and device settings to optimize the display of our Services;</p>
        </li>

        <li><p>Locate and identify misuse of our Services;</p></li>

        <li><p>Load our Services uniformly to maintain accessibility; and</p></li>

        <li><p>Authenticate users and prevent fraud.</p></li>
      </ul>
    </li>

    <li>
      <p>
        <b>Functional Cookies:</b> We use functional Cookies to remember your choices so we can tailor our Services to
        provide you with enhanced features and personalized content. For example, these Cookies can be used to remember
        your name or preferences on our Services. We do not use functional Cookies for online marketing. While these
        Cookies can be disabled, this may result in less functionality during your use of our Services. For example, we
        use these Cookies to:
      </p>
      <ul>
        <li>
          <p>Provide you a personalized experience, such as remembering how you have customized our Services;</p>
        </li>

        <li>
          <p>Remember the information that you fill in when performing certain activities on our Services; and</p>
        </li>

        <li><p>Store your preferences such as language and location.</p></li>
      </ul>
    </li>

    <li>
      <p>
        <b>Performance or Analytics Cookies:</b> These Cookies help us understand how our Services are being accessed,
        used, or are performing. These performance or analytics Cookies may collect information about the content you
        view, what websites you visit immediately before and after visiting our Services, and your system and geographic
        information. The information generated by these Cookies will be transmitted to and stored by the applicable
        analytics services. For example, we may use performance or analytics Cookies to:
      </p>
      <ul>
        <li><p>Maintain and continually improve our Services;</p></li>

        <li>
          <p>Keep track of the number of visitors to the pages within our Services;</p>
        </li>

        <li>
          <p>Keep track of the length of time that each visitor spends on the pages within our Services;</p>
        </li>

        <li>
          <p>Determine the order in which a visitor visits the various pages or features within our Services;</p>
        </li>

        <li><p>Identify performance issues with our Services; and</p></li>

        <li><p>Assess which parts of our Services need improvement.</p></li>
      </ul>
    </li>
  </ul>

  <p><br /></p>
  <ol start="5">
    <li>
      <p><b class="h5 text-primary">How to Manage or Delete Cookies </b></p>
    </li>
  </ol>
  <p>You can exercise your preferences concerning Cookies served on our Services by taking the steps outlined below.</p>
  <p><br /></p>
  <p>
    To learn more about Cookies, including how to manage Cookies and opt-out of Cookies being placed on your devices,
    please visit
    <a href="http://www.allaboutcookies.org/"><u> www.allaboutcookies.org</u></a
    >.
  </p>
  <p><br /></p>
  <p>
    <i><b>Browser Settings</b></i>
  </p>
  <p><br /></p>
  <p>
    You may alter the Cookie settings in your browser settings at any time. You may accept all or only certain Cookies.
    If you disable Cookies in your browser settings, however, you may find that certain sections of our Services will
    not work.
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <b>First-Party Cookies:</b> If you do not want Cookies placed on your device, you can adjust the setting of your
        Internet browser to reject some or all Cookies and to alert you when a Cookie is placed on your device. To do
        this, follow the instructions provided by your browser (usually located within the “Help,” “Tools,” or “Edit”
        settings). While adjusting your browser settings can prevent the future placement of Cookies, it does not remove
        existing persistent Cookies.
      </p>
    </li>

    <li>
      <p>
        <b>Third-Party Cookies:</b> Modern browsers also allow you to block third-party Cookies using the steps
        described above for first-party Cookies.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <p>
    <i><b>Preferences Tools</b></i>
  </p>
  <p><br /></p>
  <p>
    There are several services available that can assist you with managing your Cookie preferences, including industry
    groups, standards associations, and tools provided by the Cookie providers and social media sites. Please note that
    Janus has no affiliation with, and is not responsible for, the third party websites.
  </p>
  <p><br /></p>
  <ul>
    <li>
      <p>
        <b>Google Analytics:</b> You may download the Google Analytics opt-out browser add-on at
        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
      </p>
    </li>

    <li>
      <p>
        <b>Do Not Track: </b>You may turn on the Do Not Track (“DNT”) setting in your browser, which will enable your
        browser to send a special signal to websites, analytics companies, plug-in providers, and other web services you
        encounter while browsing to stop tracking your activity. To turn on your DNT signal, please visit
        <u
          ><a href="https://allaboutdnt.com/#adjust-settings">AII</a
          ><a href="https://allaboutdnt.com/#adjust-settings"> About </a
          ><a href="https://allaboutdnt.com/#adjust-settings">DNT</a></u
        >. Please note that not all companies will respond to or honor DNT signals. Our Services do not respond to DNT
        signals.
      </p>
    </li>

    <li>
      <p>
        <b>Web Beacons and Pixels: </b>You may avoid web beacons and pixels by disabling the functionality that allows
        remote images to load in your email account and refraining from clicking on any links in email messages.
      </p>
    </li>
  </ul>

  <p><br /></p>
  <ol start="6">
    <li>
      <p>
        <b class="h5 text-primary">Changes to This Product Cookie Policy</b>
      </p>
    </li>
  </ol>
  <p>
    Janus reserves the right to amend this Product Cookie Policy at any time. If we update this Product Cookie Policy,
    we will revise the “Effective Date” of the Product Privacy Policy that includes this Product Cookie Policy and post
    a link to the new Product Cookie Policy on our Services. Changes to this Product Cookie Policy are effective when
    they are posted. If we make any material changes, we will provide you with notice as required under applicable law.
    You are advised to review this Product Cookie Policy periodically for any changes.
  </p>
  <p><br /></p>
  <ol start="7">
    <li>
      <p>
        <b class="h5 text-primary">Contact Us</b>
      </p>
    </li>
  </ol>
  <p>
    If you have any questions about this Product Cookie Policy, please contact us using the contact information provided
    in the Contact Us section of our
    <u><a routerLink="/policies/cookie-policy">Product Privacy Policy</a></u
    >.
  </p>
</div>
