import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ClaimsService } from 'app/claims/claims.service'
import { DecryptService } from 'app/shared/services/decrypt.service'
import { ToastService } from 'app/shared/services/toast.service'
import { OrgSettingService } from 'app/shared/services/org-setting.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { of, Subject } from 'rxjs'
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { BillingModule } from 'app/teleport/components/teleport-link-table/teleport-link-table.component'

@Component({
  selector: 'app-har-list',
  templateUrl: './har-list.page.html',
  styleUrls: ['./har-list.page.scss'],
})
export class HarListPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()
  isLoading: boolean = true
  harId: string
  patientName: string
  acceptedBillingModules: BillingModule[] = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private claimsService: ClaimsService,
    private orgSettingService: OrgSettingService,
    private toast: ToastService,
    private decryptService: DecryptService,
    protected zendesk: ZendeskService,
  ) {
    let lazyHarId: string
    let lazyBillingModules: BillingModule[]
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot.paramMap.get('harId')),
        switchMap((maybeEncrypted) => {
          return this.decryptService.getEpicDecrypted(maybeEncrypted)
        }),
        switchMap((harId) => {
          lazyHarId = harId
          return this.claimsService.getPatientNameForHarId(harId)
        }),
        switchMap((patientName) => {
          return this.orgSettingService.getOrgSettingValue('har-page-supported-billing-modules').pipe(
            map((billingModules) => {
              lazyBillingModules = billingModules
              return patientName
            }),
          )
        }),
        catchError((e) => {
          this.toast.error(parseGraphQLError(e, 'Could not fetch claims from that account'), JSON.stringify(e))
          return of('')
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((patientName) => {
        this.patientName = patientName
        this.harId = lazyHarId
        this.acceptedBillingModules = lazyBillingModules
        this.isLoading = false
      })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
