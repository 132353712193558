import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http'
import { tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { InactivityService } from './inactivity.service'

@Injectable()
export class InactivityHttpInterceptor implements HttpInterceptor {
  // Ignore any paths that could be associated with refreshing the session since
  // if those paths trigger a session refresh it could cause an infinite loop.
  readonly IGNORED_PATHS = [`/api/organizations/timeout`, `/api/session/keepalive`]

  constructor(private inactivity: InactivityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (!(event instanceof HttpResponse)) {
          return
        }

        const shouldIgnorePath = this.IGNORED_PATHS.some((path) => event.url.includes(path))
        if (shouldIgnorePath) {
          return
        }

        this.inactivity.resetTimeoutInAllTabs()
      }),
    )
  }
}
