import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { FileQuery, FilesQuery } from 'app/file/file.gql'
import { File, FileList, QueryFileArgs, QueryFilesArgs } from 'generated/graphql'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  /**
   * Fetches a single file record
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ file: File }>>}
   * @memberof FileService
   */
  fetchFile(id: string): Observable<ApolloQueryResult<{ file: File }>> {
    return this.apollo.watchQuery<{ file: File }, QueryFileArgs>({
      query: FileQuery,
      variables: {
        id,
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    }).valueChanges
  }

  /**
   * Fetches a list of file records
   *
   * @param {QueryFilesArgs} variables
   * @return {*}  {Observable<ApolloQueryResult<{ files: FileList }>>}
   * @memberof FileService
   */
  fetchFiles(variables: QueryFilesArgs): Observable<ApolloQueryResult<{ files: FileList }>> {
    return this.apollo.watchQuery<{ files: FileList }, QueryFilesArgs>({
      query: FilesQuery,
      variables: {
        ...variables,
      },
    }).valueChanges
  }

  /**
   * Fetch a file and return it's content as a string
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof FileService
   */
  async loadFileContents(id: string): Promise<ArrayBuffer> {
    let contents = await this.http
      .get(`/api/file/${id}`, { withCredentials: true, responseType: 'arraybuffer' })
      .toPromise()
    return contents
  }

  /**
   * Fetch a file pertaining to VisionX and return it's content as a string
   *
   * @param {string} id
   * @return {*}  {Promise<string>}
   * @memberof FileService
   */
  async loadVxFileContents(id: string): Promise<ArrayBuffer> {
    let contents = await this.http
      .get(`/api/vx-file/${id}`, { withCredentials: true, responseType: 'arraybuffer' })
      .toPromise()
    return contents
  }
}
