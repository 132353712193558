import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SavedSearch } from 'generated/graphql'

/**
 * Page to show list of saved searches
 * Why is this named ClaimSearchEDITPage?!?!  EDIT?
 *
 * @export
 * @class ClaimSearchEditPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-admin-claim-searches',
  templateUrl: './claim-searches.page.html',
  styleUrls: ['./claim-searches.page.scss'],
})
export class ClaimSearchEditPage implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  /**
   * Navigate to selected search
   *
   * @param {SavedSearch} search
   * @memberof ClaimSearchEditPage
   */
  selectSearch(search: SavedSearch): void {
    this.router.navigate(['admin/searches/edit', search.id])
  }
}
