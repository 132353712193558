<div class="d-flex justify-content-end">
  <div class="mr-3 my-auto" #paginationText>
    <ng-content select="[slot=paginationText]"></ng-content>
  </div>
  <small *ngIf="!paginationText.children.length" class="mr-3 my-auto">
    Showing {{ (currentPageNumber - 1) * metaData.limit + 1 | number }} -
    {{
      metaData?.total < currentPageNumber * metaData.limit
        ? metaData?.total
        : (currentPageNumber * metaData.limit | number)
    }}
    of
    {{ metaData?.total | approximation: isTotalApproximate }}
  </small>

  <div class="btn-group" role="group" aria-label="Pagination">
    <button
      class="border-alt-light-gray btn btn-light btn-md text-alt-medium-gray"
      ngbTooltip="Previous"
      container="body"
      type="button"
      [disabled]="currentPageNumber <= 1"
      (click)="previousPage()"
      data-t="previous-button"
    >
      <i class="fa fa-angle-left px-2"></i>
    </button>

    <button
      class="border-alt-light-gray btn btn-light btn-md text-alt-medium-gray"
      ngbTooltip="Next"
      container="body"
      type="button"
      [disabled]="currentPageNumber >= maximumNumberOfPages"
      (click)="nextPage()"
      data-t="next-button"
    >
      <i class="fa fa-angle-right px-2"></i>
    </button>
  </div>
</div>
