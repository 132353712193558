<div class="navbar navbar-light bg-light p-0 my-3 justify-content-between">
  <ul class="navbar-nav flex-row ml-5 bg-white">
    <li class="nav-item px-2" *ngFor="let task of tasks; let i = index">
      <button
        type="button"
        class="btn btn-lg btn-alt-baby-blue"
        [ngClass]="{ 'opacity-45': task?.id !== selectedTaskId }"
        (click)="goToTask(task?.id)"
        [attr.data-t]="'task-number-' + i"
      >
        {{ i + 1 }}
      </button>
    </li>
  </ul>
  <div>
    <span class="pl-2 bg-white" *ngIf="isDemoEnabled">
      <button type="button" class="btn btn-primary">Complete Workflow</button>
    </span>

    <span class="mr-5 px-2 bg-white" *ngIf="workflow.completed !== true">
      <button
        class="btn btn-outline-secondary"
        ngbTooltip="Reject workflow"
        container="body"
        (click)="reject.emit({ id: workflow.id, reject: true })"
        data-t="reject-workflow"
      >
        <span class="sr-only">Reject workflow</span>
        <i class="fa fa-fw fa-ban"></i>
      </button>
    </span>
    <span class="mr-5 px-2 bg-white" *ngIf="isRejected">
      <button
        class="btn btn-outline-secondary"
        ngbTooltip="Re-open workflow"
        container="body"
        (click)="reject.emit({ id: workflow.id, reject: false })"
        data-t="reopen-workflow"
      >
        <span class="sr-only">Re-Open workflow</span>
        <i class="fa fa-fw fa-folder-open-o"></i>
      </button>
    </span>
  </div>
</div>
<div class="w-100">
  <app-collapsing-card
    *ngFor="let task of tasks"
    [isCollapsed]="task?.id !== selectedTaskId"
    (onToggled)="handleTaskToggle(task?.id, $event)"
    [ngClass]="{ 'opacity-45': task?.id !== selectedTaskId }"
    [formGroup]="outcomes"
    class="bg-alt-baby-blue mb-3"
    [attr.data-t]="'workflow-task-' + task?.taskType?.name"
  >
    <div slot="title">
      <h5 class="text-alt-dark-blue">
        {{ task?.taskType?.name }}
      </h5>
    </div>
    <div slot="content">
      <div class="p-4 my-3 rounded-lgr bg-alt-white">
        <p [innerHTML]="task?.taskType?.description"></p>
      </div>
      <div class="p-4 my-3 rounded-lgr bg-alt-white">
        <label [attr.for]="'outcome-' + task.id">Outcome</label>
        <div class="d-flex justify-content-end">
          <select
            *ngIf="workflow?.workflowType?.tasks[task.workflowTypeTaskIndex]?.outcomes?.length"
            [attr.id]="'outcome-' + task.id"
            class="custom-select mr-3 flex-grow-1"
            [formControlName]="task?.id"
            [appFcBsValidation]="outcomes.get(task?.id)"
            data-t="task-outcome"
          >
            <option [ngValue]="null" disabled>Select Outcome</option>
            <option
              *ngFor="
                let outcome of workflow?.workflowType?.tasks[task.workflowTypeTaskIndex]?.outcomes;
                index as outcomeIndex
              "
              [ngValue]="outcomeIndex"
            >
              {{ outcome?.name }}
            </option>
          </select>
          <div class="flex-shrink-0 d-flex align-items-center">
            <button
              *ngIf="!completing"
              type="button"
              class="complete-button btn btn-primary"
              (click)="finishTask(task)"
              [attr.data-t]="'complete-' + task?.taskType?.name"
            >
              Complete Task
            </button>
            <button *ngIf="completing" type="button" class="complete-button btn btn-primary">
              <i class="fa fa-spin fa-spinner fa-fw" data-t="loading-wheel"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-link text-alt-dark-blue"
          (click)="showComments = !showComments"
          ngbTooltip="Toggle task comments"
          container="body"
        >
          <span class="sr-only">Show comments</span>
          <i class="fa fa-lg fa-fw fa-comments"></i>
        </button>
      </div>
      <app-task-comments
        *ngIf="showComments"
        [comments]="comments"
        [taskId]="task?.id"
        [loading]="false"
        class="d-block mt-1"
      ></app-task-comments>
    </div>
  </app-collapsing-card>
</div>
