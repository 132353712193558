import { Injectable, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Observable, Subject, debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs'

/**
 * Service that keeps track of User filters and syncs them with query params.
 * This service should be used for reading and writing User filters that need to
 * be tracked across multiple pages or components.
 *
 * You can listen for values with `search$` and write values with `setSearch()`.
 *
 * ```typescript
 * import { Component, OnInit, inject } from '@angular/core'
 * import { UsersFilterService } from 'app/admin/users/users-list/users-filter.service'
 *
 * export class MyComponent implements OnInit {
 *   usersFilter = inject(UsersFilterService)
 *
 *   ngOnInit(): void {
 *     this.usersFilter.search$.subscribe((value) => {
 *       console.log('Search value changed to', value)
 *     })
 *   }
 *
 *   onInput(event: Event): void {
 *     this.usersFilter.setSearch(event.target.value)
 *   }
 * }
 *
 * ```
 */
@Injectable()
export class UsersFilterService {
  private route = inject(ActivatedRoute)
  private router = inject(Router)

  search$: Observable<string> = this.route.queryParams.pipe(
    map((params: Params) => params['search'] ?? ''),

    // This will prevent writes from re-emitting.
    distinctUntilChanged(),

    takeUntilDestroyed(),
    shareReplay(),
  )

  /**
   * Used to emit new values to query params. We subscribe to this and add a
   * debounce to prevent rapid updates.
   */
  private incomingSearch$ = new Subject<string>()

  constructor() {
    this.incomingSearch$.pipe(debounceTime(150), takeUntilDestroyed()).subscribe((value) => {
      const queryParams: Params = {
        // If the value is empty, remove it from the params.
        search: value || undefined,
      }

      this.router.navigate([], { queryParams, queryParamsHandling: 'merge', relativeTo: this.route })
    })
  }

  /**
   * Set a new search value. New values won't take effect immediately; we have
   * placed query param updates behind a small debounce to prevent rapid
   * updates.
   */
  setSearch(value: string): void {
    this.incomingSearch$.next(value)
  }
}
