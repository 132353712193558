<label class="sr-only" for="organizationSearch">Search for an organization</label>
<div class="input-group">
  <input
    id="organizationSearch"
    type="text"
    class="form-control"
    [ngClass]="{ 'is-invalid': showError }"
    [placeholder]="placeholder"
    container="body"
    [ngbTypeahead]="search"
    popupClass="above-modal"
    [resultFormatter]="resultFormatter"
    [inputFormatter]="inputFormatter"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectOrg($event)"
    #input="ngbTypeahead"
    #element
  />
  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="element.value.length && !searching"
      type="button"
      (click)="clearOrg($event)"
      class="btn bg-transparent"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
    <app-loading *ngIf="searching"></app-loading>
  </span>
</div>
