<div class="px-4">
    <h3 class="mb-5">Please click the buttons in numerical order</h3>
    <div class="d-flex flex-row justify-content-between">
        <button class="btn btn-primary btn-sm">1</button>
        <button class="btn btn-primary btn-sm">2</button>
        <button class="btn btn-primary btn-sm">3</button>
    </div>
    <div class="d-flex flex-row justify-content-around mt-5">
        <button class="btn btn-primary btn-sm" style="margin-top: 5rem">4</button>
        <button class="btn btn-primary btn-sm" style="margin-top: 5rem">5</button>
        <button class="btn btn-primary btn-sm" style="margin-top: 5rem">6</button>
    </div>
    <div class="d-flex flex-row justify-content-start">
        <button class="btn btn-primary btn-sm mr-5" style="margin-top: 5rem">7</button>
        <button class="btn btn-primary btn-sm mr-5" style="margin-top: 5rem">8</button>
        <button class="btn btn-primary btn-sm" style="margin-top: 5rem">9</button>
    </div>
</div>