import { Component, OnInit } from '@angular/core'

/**
 * Page to display illuminate
 * currently TABS
 *
 * @export
 * @class IlluminatePage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-illuminate',
  templateUrl: './illuminate.page.html',
  styleUrls: ['./illuminate.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class IlluminatePage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
