import { Injectable } from '@angular/core'
import { ApolloQueryResult, FetchPolicy } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateFeatureFlag,
  DeleteFeatureFlag,
  GetAllFeatureFlags,
  GetFeatureFlag,
  GetFeatureFlagNames,
  GetFeatureFlagValue,
  GetFeatureFlags,
  UpdateFeatureFlag,
} from 'app/shared/services/feature-flag.gql'
import { debug } from 'app/shared/utils/debug'
import {
  CreateFeatureFlagInput,
  FeatureFlag,
  FeatureFlagList,
  FeatureFlagNamesList,
  ListResponseMetaData,
  MutationCreateFeatureFlagArgs,
  MutationDeleteFeatureFlagArgs,
  MutationUpdateFeatureFlagArgs,
  Organization,
  QueryAllFeatureFlagsArgs,
  QueryFeatureFlagArgs,
  QueryFeatureFlagNamesArgs,
  QueryFeatureFlagsArgs,
  UpdateFeatureFlagInput,
  User,
} from 'generated/graphql'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { parseGraphQLError } from '../utils/parse-gql-error'
import { ToastService } from './toast.service'

/**
 * Feature flag constants
 */
export const FeatureFlags = {
  admin: {
    organization: {
      /**
       * Permission to soft-delete organizations.
       */
      delete: 'admin.organization.delete',
    },
  },
  auth: {
    authorization: {
      v2023: 'auth.authorization.v2023',
    },
  },
  bot: {
    teleportUseSessions: 'bot.teleport-use-sessions',
    nodeBot: 'bot.qa-node-bot',
  },
  edi: {
    onlyShowAuthRequiredProcedureSet: 'edi.278i.filtering.procedures',
  },
  payerPlan: {
    botjob: 'ui.botjob-payerplan',
    api: 'api.payerplan',
  },
  api: {
    portalMap: 'api.portalMap',
    priorAuth: {
      zync: 'api.priorauth.zync',
    },
  },
  ui: {
    uploadDocs: 'ui.upload-docs',
    teleportUiObservability: 'ui.teleport-ui-observability',
    annotations: 'ui.annotations',
    artemis: {
      documentCreator: 'ui.artemis.documentCreator',
      identifierReview: 'ui.artemis.identifierReview',
      quickReview: 'ui.artemis.quickreview',
      processVxPagesToday: 'ui.artemis.processVxPagesToday',
    },
    authStatusTeleport: 'ui.auth-status-teleport',
    teleportIsEnabledForPAFU: 'ui.teleport_is_enabled_for_pafu',
    dashboard: 'ui.dashboard',
    demo: 'ui.demo',
    favorites: 'ui.favorites',
    illuminate: {
      root: 'ui.illuminate',
      workforceManagement: 'ui.illuminate.workforceManagement',
      usageInsights: 'ui.illuminate.usageInsights',
      denialsDashboard: 'ui.illuminate.denialsDashboard',
    },
    observeDashboard: 'ui.observeDashboard',
    processMaps: 'ui.ProcessMaps',
    qedi: {
      root: 'ui.qedi',
      launchDemonstration: 'ui.qedi.launchDemonstration',
    },
    queryCenter: {
      automations: 'ui.queryCenter.automations',
    },
    quicksightDashboard: {
      root: 'ui.quicksightDashboard',
      bookmarks: 'ui.quicksightDashboard.bookmarks',
      visionx: 'ui.quicksightDashboard.visionx',
    },
    sessionInactivityWarning: 'ui.sessionInactivityWarning',
    tableauDashboard: 'ui.tableauDashboard',
    throughputReport: 'ui.throughputReport',
    visionx: {
      ruleTemplates: 'ui.visionx.ruleTemplates',
    },
    workflows: 'ui.workflows',
    zendeskIcon: 'ui.zendeskIcon',
    slideshows: 'ui.slideshows',
    disableTeleportGuard: 'ui.disableTeleportGuard', // temp, this will be removed after testing with customer
  },
  vx: {
    formExtraction: 'vx.form-extraction',
    identifierIndexing: 'vx.identifier-indexing',
    missingFieldDropup: 'vx.missing-field-dropup',
  },
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private meta: ListResponseMetaData

  constructor(private apollo: Apollo, private toastService: ToastService) {}

  flags: Record<string, boolean> = {}

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof FeatureFlagService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve the boolean result of whether a feature should be enabled
   *
   * @param {string} feature
   * @return {*}  {Promise<boolean>}
   * @memberof FeatureFlagService
   */
  async getFeatureFlagValue(feature: string): Promise<boolean> {
    debug('feature-flag', 'feature-flag service get feature flag value', feature, this.flags[feature])
    if (this.flags[feature] != null) {
      return this.flags[feature]
    }
    try {
      let result = await this.apollo
        .query<{ featureFlagValue: boolean }, QueryFeatureFlagArgs>({
          query: GetFeatureFlagValue,
          variables: {
            feature: feature,
          },
        })
        .toPromise()

      this.flags[feature] = result.data.featureFlagValue

      return this.flags[feature]
    } catch (error) {
      debug('featureFlags', 'error loading feature flag', feature, error)
      this.toastService.error(parseGraphQLError(error, 'Could not fetch feature flag'), JSON.stringify(error))
      return false
    }
  }

  /**
   * Retrieve a single feature flag
   *
   * @param {string} feature
   * @return {*}  {Observable<ApolloQueryResult<{ featureFlag: FeatureFlag }>>}
   * @memberof FeatureFlagService
   */
  getFeatureFlag(feature: string): Observable<ApolloQueryResult<{ featureFlag: FeatureFlag }>> {
    return this.apollo.watchQuery<{ featureFlag: FeatureFlag }, QueryFeatureFlagArgs>({
      query: GetFeatureFlag,
      variables: {
        feature: feature,
      },
    }).valueChanges
  }

  /**
   * Retrieve up to 100 feature flags with matching feature flag names
   *
   * @param {string[]} features
   * @return {*}  {Observable<ApolloQueryResult<{ featureFlags: FeatureFlag[] }>>}
   * @memberof FeatureFlagService
   */
  getFeatureFlags(features: string[]): Observable<ApolloQueryResult<{ featureFlags: FeatureFlag[] }>> {
    return this.apollo.watchQuery<{ featureFlags: FeatureFlag[] }, QueryFeatureFlagsArgs>({
      query: GetFeatureFlags,
      variables: {
        features,
      },
    }).valueChanges
  }

  /**
   * Retrieve a list of all feature flags.
   *
   * @param {QueryAllFeatureFlagsArgs} variables
   * @return {*}  {Observable<ApolloQueryResult<{ allFeatureFlags: FeatureFlagList }>>}
   * @memberof FeatureFlagService
   */
  getAllFeatureFlags(
    variables: QueryAllFeatureFlagsArgs,
  ): Observable<ApolloQueryResult<{ allFeatureFlags: FeatureFlagList }>> {
    return this.apollo.watchQuery<{ allFeatureFlags: FeatureFlagList }, QueryAllFeatureFlagsArgs>({
      query: GetAllFeatureFlags,
      variables,
    }).valueChanges
  }

  /**
   * Retrieve a list of feature flags consolidated by name
   *
   * @param {QueryFeatureFlagNamesArgs} variables
   * @return {*}  {Observable<ApolloQueryResult<{ featureFlagNames: FeatureFlagNames }>>}
   * @memberof FeatureFlagService
   */
  getFeatureFlagNames(
    variables: QueryFeatureFlagNamesArgs,
    options?: { fetchPolicy?: FetchPolicy },
  ): Observable<ApolloQueryResult<{ featureFlagNames: FeatureFlagNamesList }>> {
    return this.apollo
      .watchQuery<{ featureFlagNames: FeatureFlagNamesList }>({
        query: GetFeatureFlagNames,
        variables,
        fetchPolicy: options?.fetchPolicy || 'cache-first',
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.featureFlagNames?.meta
        }),
      )
  }

  /**
   * Create a new feature flag
   *
   * @param {CreateFeatureFlagInput} data
   * @return {*}  {Promise<FeatureFlag>}
   * @memberof FeatureFlagService
   */
  async createFeatureFlag(data: CreateFeatureFlagInput): Promise<FeatureFlag> {
    let result = await this.apollo
      .mutate<{ createFeatureFlag: FeatureFlag }, MutationCreateFeatureFlagArgs>({
        mutation: CreateFeatureFlag,
        variables: { data },
        update: (cache, { data }) => {
          const existingFlags: { featureFlagNames: FeatureFlagList } = cache.readQuery({
            query: GetFeatureFlagNames,
            variables: {
              limit: 25,
            },
          })
          if (existingFlags) {
            const newFlag: FeatureFlag = data.createFeatureFlag
            cache.writeQuery({
              query: GetFeatureFlagNames,
              variables: {
                limit: 25,
              },
              data: { featureFlagNames: [newFlag, ...existingFlags.featureFlagNames.entities] },
            })
          }
        },
        refetchQueries: [
          {
            query: GetFeatureFlags,
            variables: {
              features: [data.name],
            },
          },
        ],
      })
      .toPromise()

    return result.data?.createFeatureFlag
  }

  /**
   * Update an existing feature flag
   *
   * @param {UpdateFeatureFlagInput} data
   * @return {*}  {Promise<FeatureFlag>}
   * @memberof FeatureFlagService
   */
  async updateFeatureFlag(data: UpdateFeatureFlagInput): Promise<FeatureFlag> {
    let result = await this.apollo
      .mutate<{ updateFeatureFlag: FeatureFlag }, MutationUpdateFeatureFlagArgs>({
        mutation: UpdateFeatureFlag,
        variables: {
          data,
        },
        refetchQueries: [
          {
            query: GetFeatureFlags,
            variables: {
              features: [data.name],
            },
          },
        ],
      })
      .toPromise()

    return result.data?.updateFeatureFlag
  }

  /**
   * Delete an existing feature flag
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof FeatureFlagService
   */
  async deleteFeatureFlag(featureFlag: FeatureFlag): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ deleteFeatureFlag: boolean }, MutationDeleteFeatureFlagArgs>({
        mutation: DeleteFeatureFlag,
        variables: { id: featureFlag.id },
        update: (cache) => {
          cache.evict({ fieldName: 'featureFlagNames' })
          cache.gc()
        },
        refetchQueries: [
          {
            query: GetFeatureFlags,
            variables: {
              features: [featureFlag.name],
            },
          },
        ],
      })
      .toPromise()

    return result.data?.deleteFeatureFlag
  }

  /**
   * Saturates a feature flag with the human readable value for the typeId
   *
   * @param {FeatureFlag} flag
   * @param {User[]} users
   * @param {Organization[]} orgs
   * @return {*}  {{ featureFlag: FeatureFlag; type: 'string' }}
   * @memberof FeatureFlagService
   */
  saturateFeatureFlagType(
    flag: FeatureFlag,
    users: User[],
    orgs: Organization[],
  ): { featureFlag: FeatureFlag; type: 'string' } {
    let type
    if (flag.type === 'User') {
      let user = users.find((user) => user.id === flag.typeId)
      type = this.getUserDisplayName(user)
    } else if (flag.type === 'Organization') {
      type = orgs.find((org) => org.id === flag.typeId)?.name
    } else {
      type = ''
    }
    return { featureFlag: flag, type: type }
  }

  /**
   * Get a user display name for the feature flag page.
   * If they have a name display the email in parens otherwise only display email
   */
  getUserDisplayName(user: User): string {
    const name = user?.name
    const email = user?.email

    if (name) {
      return `${name} (${email})`
    }

    return email || ''
  }
}
