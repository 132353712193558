import { Component } from '@angular/core'

/**
 * Page that displays the Cookie Policy
 *
 * @export
 * @class CookiePolicyPage
 */
@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.page.html',
  styleUrls: ['./cookie-policy.page.scss'],
})
export class CookiePolicyPage {
  constructor() {}
}
