import { Injectable } from '@angular/core'
import { EdgeConfig, Graph, GraphData, NodeConfig } from '@antv/g6'
import {
  BLUE_EDGE,
  BLUE_NODE,
  GREEN_EDGE,
  GREEN_NODE,
  ORANGE_EDGE,
  ORANGE_NODE,
  RED_EDGE,
  RED_NODE,
  START_NODE,
  END_NODE,
} from 'app/illuminate-v2/node-config'
import { debug } from 'app/shared/utils/debug'
import { IlTask, IlWorkflow } from 'generated/graphql'
import { isEqual, uniqBy, uniqWith } from 'lodash'
import { IlluminateService } from './illuminate.service'

@Injectable({
  providedIn: 'root',
})
export class IlluminateGraphFunctionsService {
  graph: Graph
  graphData: GraphData

  constructor(private illuminate: IlluminateService) {}

  /**
   * Add nodes / edges for all workflows
   *
   * @param {IlWorkflow[]} workflows
   * @return {*}  {Promise<void>}
   * @memberof IlluminateGraphFunctionsService
   */
  async drawGraph(workflows: IlWorkflow[]): Promise<void> {
    let nodes: NodeConfig[] = []
    let edges: EdgeConfig[] = []

    nodes.push({
      id: 'start',
      ...START_NODE,
    })

    for (const workflow of workflows) {
      let tmpIlUserTasks = [...workflow.ilUserTasks]
      tmpIlUserTasks.reverse()
      for (const [index, tsk] of tmpIlUserTasks.entries()) {
        let edgeConfig = {
          source: tmpIlUserTasks[index + 1]?.ilTask?.id,
          target: tsk?.ilTask.id,
        }
        let impact = this.illuminate.taskImpact(edgeConfig)

        nodes.push(this.addNode(tsk?.ilTask, impact))

        if (edgeConfig.source) {
          edges.push(this.addEdge(edgeConfig.source, edgeConfig.target, impact))
        } else if (edgeConfig.target) {
          edges.push(this.addEdge('start', edgeConfig.target, impact))
        }
        if (index === 0) {
          edges.push(this.addEdge(edgeConfig.target, 'end', impact))
        }
      }
    }

    nodes.push({
      id: 'end',
      ...END_NODE,
    })

    nodes = uniqBy(nodes, 'id')
    edges = uniqWith(edges, isEqual)

    debug('illuminate', 'added nodes / edges', { nodes, edges })

    setTimeout(() => (this.graphData = { nodes, edges }))
  }

  /**
   * Add node based on impact
   *
   * @param {IlTask} task
   * @param {number} [impact]
   * @return {*}  {NodeConfig}
   * @memberof IlluminateGraphFunctionsService
   */
  addNode(task: IlTask, impact?: number): NodeConfig {
    // Do things w/ colors
    if (impact > 0.8) {
      return {
        id: task.id,
        label: task?.name,
        ...RED_NODE,
      }
    } else if (impact > 0.5) {
      return {
        id: task.id,
        label: task?.name,
        ...GREEN_NODE,
      }
    } else if (impact > 0.25) {
      return {
        id: task.id,
        label: task?.name,
        ...ORANGE_NODE,
      }
    } else {
      return {
        id: task.id,
        label: task?.name,
        ...BLUE_NODE,
      }
    }
  }

  /**
   * Add edge based on impact of target node
   *
   * @param {string} source
   * @param {string} target
   * @param {number} [targetImpact]
   * @return {*}  {EdgeConfig}
   * @memberof IlluminateGraphFunctionsService
   */
  addEdge(source: string, target: string, targetImpact?: number): EdgeConfig {
    if (targetImpact > 0.8) {
      return {
        source: source,
        target: target,
        ...RED_EDGE,
      }
    } else if (targetImpact > 0.5) {
      return {
        source: source,
        target: target,
        ...GREEN_EDGE,
      }
    } else if (targetImpact > 0.25) {
      return {
        source: source,
        target: target,
        ...ORANGE_EDGE,
      }
    } else {
      return {
        source: source,
        target: target,
        ...BLUE_EDGE,
      }
    }
  }

  /**
   * Add disable state to a node's siblings
   * Remove disabled state from a node's direct children
   *
   * @param {string} source
   * @param {string} target
   * @param {*} selectedTaskIds
   * @memberof IlluminateGraphFunctionsService
   */
  updateNodeStates(source: string, target: string, selectedTaskIds: string[]): void {
    let nodes = this.graph.getNodes()

    // disable direct siblings of target,
    // aka source's children NOT target
    nodes
      .find((n) => n.getID() === source)
      ?.getOutEdges()
      .map((e) => e.getTarget())
      .forEach((n) => {
        if (n.getID() !== target) {
          n.setState('disabled', true)
        }
      })

    // un-disable direct children of target that are part of a path
    nodes.forEach((node) => {
      let nodeParents = node.getInEdges().map((edge) => edge.getSource())
      if (nodeParents.map((n) => n.getID()).includes(target)) {
        if (this.illuminate.isTaskSequenceInPath([...selectedTaskIds, node.getID()])) {
          node.setState('disabled', false)
        }
      }
    })
  }

  /**
   * Update all edge states based on selected tasks
   *
   * @param {string} source
   * @param {string} target
   * @param {*} selectedTaskIds
   * @memberof IlluminateGraphFunctionsService
   */
  updateEdgeStates(source: string, target: string, selectedTaskIds: string[]): void {
    let edges = this.graph.getEdges()

    edges.forEach((edge) => {
      if (edge.getSource().getID() === source && edge.getTarget().getID() === target) {
        edge.setState('selected', true)
      } else if (edge.getSource().getID() === source) {
        edge.setState('disabled', true)
      } else if (edge.getSource().getID() === target) {
        if (this.illuminate.isTaskSequenceInPath([...selectedTaskIds, edge.getTarget().getID()])) {
          edge.setState('disabled', false)
        }
      }
    })
  }

  /**
   * Helper to determine where a dot should be
   *
   * @param {number[]} probabilities
   * @return {*}  {number}
   * @memberof IlluminateGraphFunctionsService
   */
  probabilisticChoice(probabilities: number[]): number {
    let random = Math.random()
    let cumulativeProbability = 0
    for (let i = 0; i < probabilities.length; i++) {
      cumulativeProbability += probabilities[i]
      if (cumulativeProbability > random) {
        return i
      }
    }
    return 0
  }
}
