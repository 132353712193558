<div class="d-flex flex-row flex-grow-1">
  <button
    id="toggle-pan"
    type="button"
    class="btn btn-md btn-light px-4 text-alt-medium-gray"
    (click)="togglePanning.emit()"
    [ngClass]="{ active: isPanning }"
  >
    <label class="sr-only" for="toggle-pan">Move Document</label>
    <i class="fa fa-lg fa-hand-rock-o"></i>
  </button>
  <button
    *ngIf="showResetZoom"
    id="reset-zoom"
    type="button"
    class="btn btn-md btn-light px-4 text-alt-medium-gray"
    (click)="resetZoom.emit()"
  >
    <label class="sr-only" for="reset-zoom">Reset Zoom</label>
    <i class="fa fa-lg fa-expand"></i>
  </button>
  <button id="zoom-out" type="button" class="btn btn-md btn-light px-4 text-alt-medium-gray" (click)="zoomOut.emit()">
    <label class="sr-only" for="zoom-out">Zoom Out</label>
    <i class="fa fa-lg fa-search-minus"></i>
  </button>
  <button id="zoom-in" type="button" class="btn btn-md btn-light px-4 text-alt-medium-gray" (click)="zoomIn.emit()">
    <label class="sr-only" for="zoom-in">Zoom In</label>
    <i class="fa fa-lg fa-search-plus"></i>
  </button>
  <div ngbDropdown class="border-bottom-1 border-alt-medium-gray ml-2">
    <button
      id="toggle-zoom-dropdown"
      class="btn btn-md px-3 fs-16 text-alt-medium-gray text-left font-weight-light"
      id="zoomLevelDropdown"
      ngbDropdownToggle
    >
      <label class="sr-only" for="toggle-zoom-dropdown">Zoom Level Dropdown</label>
      {{ zoomLevel | percent }}
      <i class="fa fa-angle-down fa-lg ml-1"></i>
    </button>
    <div ngbDropdownMenu="zoomLevelDropdown">
      <div *ngFor="let zoom of zoomLevels">
        <button
          id="zoom-{{ zoom }}"
          ngbDropdownItem
          (click)="setZoom.emit(zoom)"
          class="text-alt-dark-gray fs-16 font-weight-light dropdown"
          [ngClass]="{ 'dropdown-active': zoom === zoomLevel }"
        >
          <label class="sr-only" for="zoom-{{ zoom }}">Zoom to {{ zoom | percent }}</label>
          {{ zoom | percent }}
        </button>
      </div>
    </div>
  </div>
</div>
