import { Component, OnInit } from '@angular/core'
import { TaskStatsService } from 'app/reporting/task-stats.service'
import * as moment from 'moment'

const FORMAT = 'MM/DD/YYYY'

/**
 * Component to display an overview of task stats
 *
 * @export
 * @class TeamQuickStatsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-team-quick-stats',
  templateUrl: './team-quick-stats.component.html',
  styleUrls: ['./team-quick-stats.component.scss'],
})
export class TeamQuickStatsComponent implements OnInit {
  completed = {
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  }
  created = {
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  }
  constructor(private statService: TaskStatsService) {}

  async ngOnInit(): Promise<void> {
    let today = {
      start: moment().format(FORMAT),
      end: moment().format(FORMAT),
    }
    let week = {
      start: moment().startOf('week').format(FORMAT),
      end: moment().endOf('week').format(FORMAT),
    }
    let month = {
      start: moment().startOf('month').format(FORMAT),
      end: moment().endOf('month').format(FORMAT),
    }
    let year = {
      start: moment().startOf('year').format(FORMAT),
      end: moment().endOf('year').subtract(1, 'd').format(FORMAT),
    }

    let results = await Promise.allSettled(
      [today, week, month, year].map((p) => this.statService.getTaskStats(p.start, p.end)),
    )

    const rCompleted = (acc, curr) => acc + curr.completed
    const rCreated = (acc, curr) => acc + curr.created
    const pValue = (promise) => (promise as PromiseFulfilledResult<any>)?.value

    this.completed.day = pValue(results[0])[0]?.completed || 0
    this.created.day = pValue(results[0])[0]?.created || 0

    this.completed.week = pValue(results[1])?.reduce(rCompleted, 0)
    this.created.week = pValue(results[1])?.reduce(rCreated, 0)

    this.completed.month = pValue(results[2])?.reduce(rCompleted, 0)
    this.created.month = pValue(results[2])?.reduce(rCreated, 0)

    this.completed.year = pValue(results[3])?.reduce(rCompleted, 0)
    this.created.year = pValue(results[3])?.reduce(rCreated, 0)
  }
}
