import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ProgressModalComponent } from 'app/shared/components/progress-modal/progress-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { jitter } from 'app/shared/utils/retry-jitter'
import Bottleneck from 'bottleneck'
import { TaskGroup, WorkflowType } from 'generated/graphql'
import { flatten, uniq, without } from 'lodash'
import { Subject } from 'rxjs'
import { takeUntil, tap } from 'rxjs/operators'
import { WorkflowService } from '../workflow.service'

/**
 * Page to allow assigning workflows to claims and task groups
 *
 * @export
 * @class WorkflowAssignPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-workflow-assign',
  templateUrl: './assign.page.html',
  styleUrls: ['./assign.page.scss'],
})
export class WorkflowAssignPage implements OnInit, OnDestroy {
  workflows: WorkflowType[] = []
  taskGroupId: string = null
  claimIds = new UntypedFormControl('')
  claims: string[] = []
  saving = false
  destroyed$ = new Subject<void>()

  constructor(
    private router: Router,
    private workflowService: WorkflowService,
    private toast: ToastService,
    private modal: NgbModal,
  ) {
    let providerClaimIDs = this.router.getCurrentNavigation()?.extras?.state?.data?.providerClaimIDs
    if (providerClaimIDs) {
      this.claimIds.setValue(providerClaimIDs.join())
    }
  }

  ngOnInit(): void {
    this.claimIds.valueChanges
      .pipe(
        tap((ids: string) => {
          if (ids?.length > 0) {
            let claims = uniq(ids.split(/\t|\n|,/))
            this.claims = claims.filter((c) => c?.length)
          } else {
            this.claims = []
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe()
    this.claimIds.updateValueAndValidity()
  }

  /**
   * Add workflow type to list of workflows to assign
   *
   * @param {WorkflowType} workflow
   * @memberof WorkflowAssignPage
   */
  addSelectedWorkflow(workflow: WorkflowType): void {
    debug('workflow', 'addSelectedWorkflow', workflow)
    this.workflows.push(workflow)
  }

  /**
   * Remove workflow type from list of workflows to assign
   *
   * @param {WorkflowType} workflow
   * @memberof WorkflowAssignPage
   */
  removeSelectedWorkflow(workflow: WorkflowType): void {
    debug('workflow', 'removeSelectedWorkflow', workflow)
    this.workflows = without(this.workflows, workflow)
  }

  /**
   * Set task group to which to assign workflows
   *
   * @param {TaskGroup} taskGroup
   * @memberof WorkflowAssignPage
   */
  selectedTaskGroup(taskGroup: TaskGroup): void {
    debug('workflow', 'selected taskGroup', taskGroup)
    this.taskGroupId = taskGroup?.id
  }

  /**
   * Cancel current assignment and clear inputs
   *
   * @memberof WorkflowAssignPage
   */
  cancel(): void {
    this.workflows = []
    this.claims = []
    this.claimIds.reset()
  }

  /**
   * Assign list of workflow types to claims & task group
   *
   * @return {*}  {Promise<void>}
   * @memberof WorkflowAssignPage
   */
  async save(): Promise<void> {
    this.saving = true

    let promises = flatten(
      this.workflows.map((workflow) => {
        return this.claims.map((claim) => {
          return {
            providerClaimId: claim,
            workflowTypeId: workflow.id,
            taskGroupId: this.taskGroupId,
          }
        })
      }),
    )
    debug('workflow', 'save', promises)

    let limiter = new Bottleneck({
      maxConcurrent: 6,
      minTime: 333,
      trackDoneStatus: true,
    })

    let jitters = {}

    limiter.on('failed', (error, info) => {
      if (info.retryCount < 10) {
        let previous = info.retryCount === 0 ? 0 : jitters[info.options.id]
        let jit = jitter(previous, info.retryCount + 1)
        jitters[info.options.id] = jit
        return jit
      }
    })

    let call = (p: any) => {
      return this.workflowService.createWorkflow({
        providerClaimId: p.providerClaimId,
        workflowTypeId: p.workflowTypeId,
        taskGroupId: p.taskGroupId,
      })
    }
    let wrapped = limiter.wrap(call)

    let modalRef = this.modal.open(ProgressModalComponent, {
      size: 'lg',
      centered: true,
    })
    modalRef.componentInstance.title = 'Assigning workflows to selected claims...'
    modalRef.componentInstance.total = promises.length
    modalRef.componentInstance.current = 0

    let interval = setInterval(() => {
      let count = limiter.counts()
      modalRef.componentInstance.current = count.DONE
    }, 1000)

    modalRef.result.then(
      () => {},
      () => {
        clearInterval(interval)
        limiter.stop({ dropWaitingJobs: true })
      },
    )
    try {
      await Promise.all(promises.map((p) => wrapped(p)))
      modalRef.componentInstance.current = promises.length
      modalRef.dismiss()
      let workflowCount = this.workflows.length === 1 ? '1 workflow' : `${this.workflows.length} workflows`
      let claimCount = this.claims.length === 1 ? '1 claim' : `${this.claims.length} claims`
      this.toast.success(`Assigned ${workflowCount} to ${claimCount}`)
    } catch (e) {
      modalRef.dismiss()
      this.toast.error(parseGraphQLError(e, 'Could not assign workflows'), JSON.stringify(e))
      // TODO: add modal with report showing rundown of successes / errors
      // but only if this screen is actually going to get used, which doesn't seem to be the case
    }
    this.saving = false
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }
}
