import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { PaginationUpdateEvent } from 'app/shared/components/pagination/pagination.component'
import { NgbdSortableHeader, SortDirection, SortEvent } from 'app/shared/directives/sortable.directive'
import { ListResponseMetaData, ObserveSummary, Organization, QueryDates } from 'generated/graphql'
import { first } from 'rxjs/operators'
import { ObserveConfigService } from '../../service/observe-config.service'
@Component({
  selector: 'app-configuration-pipeline',
  templateUrl: './configuration-pipeline.component.html',
  styleUrls: ['./configuration-pipeline.component.scss'],
})
export class ConfigurationPipelineComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>
  @Input()
  selectedOrganization: Organization
  @Output()
  selectedSummary: EventEmitter<ObserveSummary> = new EventEmitter<ObserveSummary>()
  @Output()
  queryDateRange: EventEmitter<string> = new EventEmitter<string>()

  readonly COLUMN_TYPES = {
    applicationURL: 'applicationURL',
    count: 'count',
    totalCoveragePercent: 'totalCoveragePercent',
  }
  configsMetaData: ListResponseMetaData = {
    offset: 0,
    limit: 50,
    total: 0,
  }
  configData: ObserveSummary[] = []
  displayedRows: ObserveSummary[] = []
  loading = false
  readonly PAGE_SIZE = 50
  currentPage = 1
  totalScreenCoverage = 0
  constructor(private observeConfigService: ObserveConfigService) {}

  ngOnInit(): void {}

  async ngOnChanges(): Promise<void> {
    await this.getConfigData()
    this.onSort({ column: this.COLUMN_TYPES.count, direction: 'asc' })
  }

  onPageUpdate($event: PaginationUpdateEvent): void {
    this.currentPage = $event.newPageNumber
    this.getDisplayRows()
  }

  onSort($event: SortEvent): void {
    const sortColumn = $event.column
    const sortDirection: SortDirection = $event.direction
    if (sortColumn == this.COLUMN_TYPES.applicationURL) {
      this.configData.sort((a, b) => {
        return sortDirection == 'desc'
          ? b.applicationURL.localeCompare(a.applicationURL)
          : a.applicationURL.localeCompare(b.applicationURL)
      })
    } else if (sortColumn == this.COLUMN_TYPES.count) {
      this.configData.sort((a, b) => {
        return sortDirection == 'desc' ? a.count - b.count : b.count - a.count
      })
    } else if (sortColumn == this.COLUMN_TYPES.totalCoveragePercent) {
      this.configData.sort((a, b) => {
        return sortDirection == 'desc'
          ? a.totalCoveragePercent - b.totalCoveragePercent
          : b.totalCoveragePercent - a.totalCoveragePercent
      })
    }
    this.headers?.forEach((header) => {
      if (header.sortable === sortColumn) {
        header.direction = sortDirection
      } else {
        header.direction = ''
      }
    })

    // reset to page 1 onSort
    this.onPageUpdate({ newPageNumber: 1 })
    this.getDisplayRows()
  }

  public getDisplayRows(): void {
    const start = this.currentPage == 1 ? 0 : this.PAGE_SIZE * (this.currentPage - 1)
    const end = start + this.PAGE_SIZE
    this.displayedRows = this.configData?.slice(start, end) ?? []

    this.configsMetaData = {
      ...this.configsMetaData,
      offset: start,
    }
  }

  async getConfigData(): Promise<void> {
    if (this.selectedOrganization) {
      let data = null
      this.loading = true
      try {
        data = (
          await this.observeConfigService
            .getObserveConfigurations(this.selectedOrganization.id)
            .pipe(first())
            .toPromise()
        ).data
      } catch (error) {
        this.loading = false
      }
      this.setDateRange(data?.observeSummaryList.queryDateRange)
      this.configData = data.observeSummaryList?.entities ? [...data.observeSummaryList.entities] : []
      this.totalScreenCoverage = data?.observeSummaryList?.totalScreenCoverage || 0
      this.configsMetaData.total = this.configData?.length || 0

      this.getDisplayRows()
    }
    this.loading = false
  }

  public setDateRange(queryDate: QueryDates): void {
    if (queryDate) {
      const queryDateString = `${queryDate.startDate} - ${queryDate.endDate}`
      this.queryDateRange.emit(queryDateString)
    }
  }

  public onSelectedSummary(appURL: string): void {
    const summary = this.configData.find((summary) => summary.applicationURL == appURL)
    this.selectedSummary.emit(summary)
  }
}
