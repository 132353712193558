import { Injectable } from '@angular/core'
import { FeatureFlagService, FeatureFlags } from './feature-flag.service'
import { Apollo } from 'apollo-angular'
import { debug } from '../utils/debug'
import {
  PayerPlan,
  PayerPlanList,
  PayersByPayerNameDocument,
  PlansByPayerNameDocument,
  QueryPayersByPayerNameArgs,
  QueryPlansByPayerNameArgs,
  FilterablePayerPlanDto,
  QueryPlansByFilterArgs,
  PlansByFilterDocument,
  ListResponseMetaData,
  BulkCreatePlanMappingsDocument,
  BulkDestroyPlanMappingsDocument,
  BulkUpdatePlanMappingsDocument,
  PayerPlanGroupBridge,
  PlanMappingAttributes,
  QueryBulkCreatePlanMappingsArgs,
  QueryBulkDestroyPlanMappingsArgs,
  QueryBulkUpdatePlanMappingsArgs,
} from 'generated/graphql'
import { from, Observable } from 'rxjs'
import { concatMap, first, map, tap } from 'rxjs/operators'

/**
 * Handle payer plan operations
 *
 * @export
 * @class PayerPlanService
 */
@Injectable({
  providedIn: 'root',
})
export class PayerPlanService {
  private meta: ListResponseMetaData

  constructor(private featureFlagService: FeatureFlagService, private apollo: Apollo) {}

  /**
   * @description Will check if `api.payerplan` is enabled or not. You can either pass in an orgId or lookup using the current user's context on the api.
   */
  async getLegacyEnabled(orgId: string = null): Promise<boolean> {
    if (orgId) {
      const flags = await this.featureFlagService
        .getFeatureFlags([FeatureFlags.payerPlan.api])
        .pipe(first())
        .toPromise()

      return !flags.data.featureFlags.find((f) => f.typeId === orgId)?.value
    }
    return !(await this.featureFlagService.getFeatureFlagValue(FeatureFlags.payerPlan.api))
  }

  async searchPayers(variables?: QueryPayersByPayerNameArgs): Promise<PayerPlan[]> {
    debug('payer-plan-service', 'search for payer', variables?.payerName)
    let result = await this.apollo
      .query<{ payersByPayerName: PayerPlanList }, QueryPayersByPayerNameArgs>({
        query: PayersByPayerNameDocument,
        variables,
      })
      .toPromise()

    return result.data.payersByPayerName.entities
  }
  async getPlansByPayer(variables?: QueryPlansByPayerNameArgs): Promise<PayerPlan[]> {
    debug('payer-plan-service', 'search for plans by payer name', variables?.payerName)
    let result = await this.apollo
      .query<{ plansByPayerName: PayerPlanList }, QueryPlansByPayerNameArgs>({
        query: PlansByPayerNameDocument,
        variables,
      })
      .toPromise()

    return result.data.plansByPayerName.entities
  }

  getPlansByFilter(limit: number, offset: number, payerPlanDTO: FilterablePayerPlanDto): Observable<PayerPlanList> {
    return this.apollo
      .query<{ plansByFilter: PayerPlanList }, QueryPlansByFilterArgs>({
        query: PlansByFilterDocument,
        variables: {
          offset,
          limit,
          payerPlanDTO,
        },
      })
      .pipe(
        tap((result) => {
          this.meta = result?.data?.plansByFilter?.meta
        }),
        map((result) => {
          return result.data?.plansByFilter
        }),
      )
  }

  bulkCreatePlanMappings(
    payerPlanGroupBridges: PlanMappingAttributes[],
    username: string,
  ): Observable<PayerPlanGroupBridge[]> {
    return this.apollo
      .mutate<{ bulkCreatePlanMappings: PayerPlanGroupBridge[] }, QueryBulkCreatePlanMappingsArgs>({
        mutation: BulkCreatePlanMappingsDocument,
        variables: {
          payerPlanGroupBridges,
          username,
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.bulkCreatePlanMappings),
      )
  }

  bulkUpdatePlanMappings(
    payerPlanGroupBridges: PlanMappingAttributes[],
    username: string,
  ): Observable<PayerPlanGroupBridge[]> {
    return this.apollo
      .mutate<{ bulkUpdatePlanMappings: PayerPlanGroupBridge[] }, QueryBulkUpdatePlanMappingsArgs>({
        mutation: BulkUpdatePlanMappingsDocument,
        variables: {
          payerPlanGroupBridges,
          username,
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.bulkUpdatePlanMappings),
      )
  }

  bulkDestroyPlanMappings(payerPlanGroupBridges: PlanMappingAttributes[], username: string): Observable<number[]> {
    return this.apollo
      .mutate<{ bulkDestroyPlanMappings: number[] }, QueryBulkDestroyPlanMappingsArgs>({
        mutation: BulkDestroyPlanMappingsDocument,
        variables: {
          payerPlanGroupBridges,
          username,
        },
      })
      .pipe(
        concatMap((result) => {
          // This block allows us to refresh the table immediately after the mutation is completed
          // so we don't have to worry about refreshing the page before continuing to make selections
          return from(this.apollo.client.resetStore()).pipe(
            map(() => {
              return result;
            })
          );
        }),
        map((result) => result.data?.bulkDestroyPlanMappings),
      )
  }

  /**
   * Helper to get the current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof PayerPlanGroupService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }
}
