import { Component, Input, OnInit } from '@angular/core'
import { ProcedureSetRowConfig } from 'app/teleport/teleport.service'

@Component({
  selector: 'app-completed-teleport-link-table-row',
  templateUrl: './completed-teleport-link-table-row.component.html',
  styleUrls: ['./completed-teleport-link-table-row.component.scss'],
  host: {
    class: 'no-wrapper-box',
  },
})
export class CompletedTeleportLinkTableRowComponent implements OnInit {
  @Input()
  config: ProcedureSetRowConfig

  tooltip: string = ''
  constructor() {}

  ngOnInit(): void {
    this.tooltip = 'This case has been submitted.'
  }
}
