import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { debug } from 'app/shared/utils/debug'

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private PING_URL = `/api/ping`
  private HEALTH_URL = `/api/health`

  constructor(private http: HttpClient) {}

  async pingApi(): Promise<{ pong: boolean }> {
    try {
      const response = (await this.http.get(this.PING_URL).toPromise()) as {
        pong: boolean
      }
      return response
    } catch (e) {
      debug('status', 'ping API went sideways', e)
      throw new Error('Could not ping API')
    }
  }

  async checkApiHealth(): Promise<{ healthy: boolean; time?: number }> {
    try {
      const start = performance.now()
      const response = await this.http.get(this.HEALTH_URL, { observe: 'response', responseType: 'text' }).toPromise()
      const end = performance.now()
      const healthy = response.status === 200
      const time = start - end
      return { healthy, time }
    } catch (e) {
      debug('status', 'check API health went sideways', e)
      throw new Error('Could not check API health')
    }
  }
}
