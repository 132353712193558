<jui-theme>
  <ng-template #linkTemplate let-label="label" let-path="path">
    <a [routerLink]="path">{{ label }}</a>
  </ng-template>

  <jui-page-header class="mb-2" [links]="pageHeaderLinks" title="Users" [linkTemplate]="linkTemplate"></jui-page-header>

  <jui-data-table-view
    [bulkSelectBarInputs]="bulkSelectBarInputs"
    [config]="dataConfig"
    [dataSource]="dataSource"
    [search$]="usersFilterService.search$"
    (searchChange)="onSearchChange($event)"
  >
    <ng-container ngProjectAs="no-rows">
      <div class="empty-message text-center">Your search returned no results.</div>
    </ng-container>

    <ng-template juiTableTemplateName="email" let-row="row" let-value="value">
      <a routerLink="/admin/users/{{ row.id }}">{{ value }}</a>
    </ng-template>

    <ng-template juiTableTemplateName="is-active" let-value="value">
      <jui-tag [variant]="value ? 'success' : 'error'">{{ value ? 'Enabled' : 'Disabled' }}</jui-tag>
    </ng-template>

    <ng-container ngProjectAs="toolbar-top">
      <jui-button
        [disabled]="!canCreate()"
        [title]="canCreate() ? 'Create User' : 'You do not have permission to create users.'"
        (click)="navigateToUserCreation()"
      >
        <fa-icon [icon]="createUserIcon"></fa-icon>
      </jui-button>
    </ng-container>
  </jui-data-table-view>
</jui-theme>
