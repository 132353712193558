import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { defer } from 'lodash'
import { merge, Observable, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'

/**
 * Component to allow searching for and selecting from a list of items
 *
 * @export
 * @class TextTypeahead
 * @implements {OnInit}
 */
@Component({
  selector: 'app-text-typeahead',
  templateUrl: './text-typeahead.component.html',
  styleUrls: ['./text-typeahead.component.scss'],
})
export class TextTypeaheadComponent<T> {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') typeaheadInput: ElementRef

  @Output() onSelect = new EventEmitter<T>()
  @Input() placeholder: string = ''
  @Input() clearInput: boolean = true
  @Input() items: T[] = []
  @Input() readonly: boolean = false
  @Input() searchFilter: (item: T, target: string) => boolean
  @Input() inputFormatter: (item: T) => string
  @Input() resultFormatter: (item: T) => string

  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      const $activeItems = document.querySelectorAll('ng-typeahead-window.show > .dropdown-item.active')
      $activeItems.forEach(($item) => $item.scrollIntoView())
    }
  }

  /**
   * Get items based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof TextTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<T[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((search) => this.items.filter((b) => this.searchFilter(b, search))),
    ) 
  }

  /**
   * Notify consumers that an item has been selected
   *
   * @param {*} event
   * @memberof TextTypeaheadComponent
   */
  onSelectItem(event: { item: T}): void {
    let item = event.item
    this.onSelect.emit(item)
    if (this.clearInput) {
      defer(() => (this.typeaheadInput.nativeElement.value = ''))
    } else {
      this.typeaheadInput.nativeElement.value = item
    }
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof TextTypeaheadComponent
   */
  clearText(event: Event): void {
    event.preventDefault()
    this.typeaheadInput.nativeElement.value = ''
    this.onSelect.emit()
  }
}
