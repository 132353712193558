<h5 class="mb-3">Payments</h5>
<div class="card bg-light mb-5">
  <div class="card-body">
    <div class="table-responsive height-restrict">
      <table class="table table-sm table-details small text-left">
        <thead>
          <tr>
            <th scope="col">{{ getPropDetails('ehrPayments.coveredAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.nonCoveredAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.deductibleAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.copayAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.coinsAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.cobAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.paidAmount')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.checkNumber')?.heading }}</th>
            <th scope="col">{{ getPropDetails('ehrPayments.postDate')?.heading }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of payments.ehr">
            <td>{{ p?.coveredAmount | currency }}</td>
            <td>{{ p?.nonCoveredAmount | currency }}</td>
            <td>{{ p?.deductibleAmount | currency }}</td>
            <td>{{ p?.copayAmount | currency }}</td>
            <td>{{ p?.coinsAmount | currency }}</td>
            <td>{{ p?.cobAmount | currency }}</td>
            <td>{{ p?.paidAmount | currency }}</td>
            <td>{{ p?.checkNumber }}</td>
            <td>{{ p?.postDate ? (p.postDate | date: 'MM/dd/yyyy') : '' }}</td>
          </tr>
          <tr *ngFor="let p of payments.payer">
            <td>{{ p?.coveredAmount | currency }}</td>
            <td>{{ p?.nonCoveredAmount | currency }}</td>
            <td>{{ p?.deductibleAmount | currency }}</td>
            <td>{{ p?.copayAmount | currency }}</td>
            <td>{{ p?.coinsAmount | currency }}</td>
            <td>{{ p?.cobAmount | currency }}</td>
            <td>{{ p?.paidAmount | currency }}</td>
            <td>{{ p?.checkNumber }}</td>
            <td>{{ p?.postDate ? (p.postDate | date: 'MM/dd/yyyy') : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
