import { Injectable, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ColumnSorting, DataSource, GetDataParams, Row } from '@janushealthinc/janus-ui-kit'
import { UsersService } from 'app/shared/services/users.service'
import { User } from 'generated/graphql'
import { Observable, map } from 'rxjs'

@Injectable()
export class UserDataSource implements DataSource<User> {
  private usersService = inject(UsersService)

  private data$ = this.usersService.getUsersQueryData$.pipe(takeUntilDestroyed())

  /**
   * Observable that emits the total number of records.
   */
  private total$ = this.data$.pipe(map((users) => users.length))

  getData(params: GetDataParams<User>): Observable<Row<User>[]> {
    return this.data$.pipe(
      // TODO: WA-2133 Rethink the Row<T> type, because RowValue is
      // typed too strictly.
      map((users) => this.sortData([...(users as unknown as Row<User>[])], params.sorting)),
      map((users) => this.paginateData(users, params.page, params.pageSize)),
    )
  }

  getTotal(): Observable<number> {
    return this.total$
  }

  private paginateData(data: Row<User>[], page: number, pageSize: number): Row<User>[] {
    const startIndex = page * pageSize

    return data.slice(startIndex, startIndex + pageSize)
  }

  private sortData(data: Row<User>[], sorting: ColumnSorting<User>): Row<User>[] {
    const sortKeys = Object.keys(sorting) as (keyof User)[]

    if (sortKeys.length === 0) return data // No sorting needed

    return data.sort((a, b) => {
      for (const key of sortKeys) {
        let valueA = a[key]
        let valueB = b[key]
        const direction = sorting[key]?.direction === 'ASC' ? 1 : -1

        // Compare values
        if (valueA == null) valueA = ''
        if (valueB == null) valueB = ''

        if (valueA < valueB) return -1 * direction
        if (valueA > valueB) return 1 * direction
      }
      return 0 // If equal or no sort criteria met
    })
  }
}
