<label class="sr-only" for="vxPageTypeSearch">Search for a Page Type</label>
<div class="input-group">
  <input
    appTrimmedInput
    id="vxPageTypeSearch"
    type="text"
    class="form-control"
    [ngClass]="inputClass"
    placeholder="Select Page Type"
    [formControl]="inputFormControl"
    [container]="container"
    [ngbTypeahead]="search"
    [resultFormatter]="vxPageTypeFormatter"
    [inputFormatter]="vxPageTypeFormatter"
    (change)="onChange.emit($any($event).target.value)"
    (input)="onInput.emit($any($event).target.value)"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectVxPageType($event)"
    #input="ngbTypeahead"
    #element
  />
  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="element.value.length && !searching"
      type="button"
      (click)="clearVxPageType($event)"
      class="btn bg-transparent"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
    <app-loading *ngIf="searching"></app-loading>
  </span>
</div>
