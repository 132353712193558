<div class="row h-100">
  <div class="col-lg-6 scrolling-sidebar left-column bg-white" *ngIf="isShowTable">
    <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
      <table class="table table-janus-new table-striped" data-t="feature-flag-table">
        <thead>
          <tr>
            <th scope="col" class="width-1">Enabled</th>
            <th scope="col" class="text-left">Feature</th>
            <th scope="col" class="text-left">Type</th>
            <th scope="col" class="text-left">For</th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoading && featureFlags?.length">
          <tr *ngFor="let flag of featureFlags" data-t="feature-flag-row">
            <td class="text-center">
              <i
                [ngClass]="flag?.featureFlag?.value ? 'fa fa-circle text-success' : 'fa fa-circle-o text-secondary'"
                [attr.aria-label]="flag?.featureFlag?.value ? 'Enabled' : 'Disabled'"
              ></i>
            </td>
            <td>
              <a routerLink="/admin/feature-flags/{{ flag?.featureFlag?.name }}/{{ flag?.featureFlag?.id }}">
                {{ flag?.featureFlag?.name }}
              </a>
            </td>
            <td>{{ flag?.featureFlag?.type }}</td>
            <td>{{ flag?.type }}</td>
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="isLoading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      ></app-loading>
      <div
        *ngIf="!featureFlags?.length && !isLoading"
        class="d-flex justify-content-center alert alert-danger"
        role="alert"
      >
        Your search returned no results.
      </div>
    </div>
  </div>
  <div class="col-lg-6 scrolling-main">
    <router-outlet></router-outlet>
  </div>
</div>
