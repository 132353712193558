import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-multi-input',
  templateUrl: './multi-input.component.html',
  styleUrls: ['./multi-input.component.scss'],
})
export class MultiInputComponent {
  /**
   * The text to display as the HTML label
   */
  @Input() label: string = ''

  @Input() inputDescribedBy: string = ''

  /**
   * Required to in case there is more than one multi input component on a single
   * page to prevent duplicate ids.
   */
  inputBoxId: string = `app-multi-input-${Math.random()}`

  /**
   * Form control to add entries to
   */
  @Input({ required: true }) arrayFormControl: FormControl<any[]> = null

  /**
   * The form control for the input element.
   */
  inputFormControl: FormControl<string | null> = new FormControl(null)

  /**
   * The input element.
   */
  @ViewChild('inputBox') inputBox: ElementRef

  /**
   * Force the input box to be focused if you click anywhere inside of the parent div.
   */
  @HostListener('mousedown', ['$event'])
  focusInput(event: Event): void {
    if (event.target !== this.inputBox?.nativeElement) {
      this.inputBox?.nativeElement?.focus()
    }
  }

  /**
   * Add an entry to to this list of entries unless the inputFormControl has errors or the input value is empty
   */
  onPushEntry() {
    const value = this.inputFormControl.value?.trim() || ''
    const currentValues = this.arrayFormControl.value ?? []
    this.inputFormControl.markAsTouched()
    this.arrayFormControl.markAsTouched()

    if (!value) {
      return
    }

    this.arrayFormControl.setValue([...currentValues, value])
    this.inputFormControl.reset()
  }

  onEnter(event: Event) {
    event.preventDefault()
    event.stopPropagation()

    this.onPushEntry()
  }

  /**
   * Delete the entry at the passed index.
   * @param {number} index
   */
  onDeleteEntry(event: Event, index: number): void {
    event.preventDefault()
    event.stopPropagation()

    this.removeChipAtIndex(index)
  }

  /**
   * On backspace of input element remove the chip and set the input text to the value
   * of that chip
   */
  onBackspace(event: Event): void {
    if (!this.inputFormControl.value) {
      event.preventDefault()
      event.stopPropagation()

      const index = this.arrayFormControl.value.length - 1
      const chipValue = this.removeChipAtIndex(index)

      this.inputFormControl.setValue(chipValue)
    }
  }

  /**
   * Remove the chip at the current index from the form control
   */
  private removeChipAtIndex(index: number): string | null {
    const value = [...this.arrayFormControl.value]

    if (value.length === 0 || index < 0) {
      return null
    }

    const [chipValue] = value.splice(index, 1)

    this.arrayFormControl.setValue(value)

    return chipValue
  }
}
