import { formatDate, formatNumber } from '@angular/common'
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core'
import G6, { GraphOptions, INode } from '@antv/g6'
import { GRAPH_OPTIONS } from 'app/illuminate-v2/graph-config'
import { IlluminateGraphFunctionsService } from 'app/illuminate-v2/illuminate-graph-functions.service'
import {
  IlluminateDateFilter,
  IlluminatePathFilter,
  IlluminateService,
  IlluminateUserFilter,
} from 'app/illuminate-v2/illuminate.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { IlPathType, IlTaskStats, IlWorkflow, IlWorkflowGrouping, IlWorkflowStats, User } from 'generated/graphql'
import { capitalize, difference, isEqual, sum, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import { Subject } from 'rxjs'
import { filter, first, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-illuminate-graph-panel',
  templateUrl: './illuminate-graph-panel.component.html',
  styleUrls: ['./illuminate-graph-panel.component.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class IlluminateGraphPanelComponent implements OnChanges, OnDestroy {
  workflowGroup: IlWorkflowGrouping = null

  @Input() isAnimating: boolean = false

  animationInterval: number = null

  graphOptions: Partial<GraphOptions> = {
    plugins: [
      new G6.Tooltip({
        itemTypes: ['node'],
        getContent: (e) => {
          let id = e?.item?.getID()
          if (id !== 'start' && id !== 'end' && !this.isSelectingPath) {
            let label = this.illuminate.extractUserTasks().find((ut) => ut.ilTask.id === id)?.ilTask?.name
            let stats = this.taskStats[id]
            const outDiv = document.createElement('div')
            outDiv.style.width = '276px'
            outDiv.innerHTML = `
            <div class="popover show" role="tooltip">
              <h3 class="popover-header pre-wrap">${capitalize(label)}</h3>
              <div class="popover-body bg-alt-white">
                <table class="table">
                    <tr class="border-top-0">
                      <td class="border-top-0">Avg time to reach node</td>
                      <td class="border-top-0 text-left text-primary font-weight-bold">${formatDate(
                        (stats?.averageTimeToReachTask || 0) * 1000,
                        'm:ss',
                        'en',
                      )}</td>
                    </tr>
                    <tr>
                      <td>Percent reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${
                        formatNumber((stats?.percentageReachingTask || 0) * 100, 'en', '1.0-0') + '%'
                      }</td>
                    </tr>
                    <tr>
                      <td>Total reaching node</td>
                      <td class="text-left text-primary font-weight-bold">${stats?.totalReachingTask || ''}</td>
                    </tr>
                </table>
              </div>
            </div>
            `
            return outDiv
          } else {
            return ''
          }
        },
      }),
    ],
    ...GRAPH_OPTIONS,
  }
  graphMode = 'default'

  panelCollapsed: boolean = true

  activePath: { id: string; name: string } = { id: null, name: 'Baseline' }
  // activeUsers: User[] = []
  // activeDates: { from: string; to: string } = null

  workflowStats: IlWorkflowStats = {}
  pathStats: IlWorkflowStats = {}
  taskStats: { [id: string]: IlTaskStats } = {}

  isSelectingPath: boolean = false
  selectedTaskIds: string[] = []

  destroy$ = new Subject<void>()

  /**
   * Respond to change in filters
   *
   * @param {IlluminatePathFilters} filters
   * @return {*}  {Promise<void>}
   * @memberof IlluminateGraphPanelComponent
   */
  async changeFilters(
    path: IlluminatePathFilter,
    users: IlluminateUserFilter,
    dates: IlluminateDateFilter,
  ): Promise<void> {
    let selectedPath: string = null

    let tasks = this.illuminate.extractUserTasks()
    if (tasks?.length) {
      debug('illuminate', 'changing filters', path, users, dates)

      const userIds = users?.selected?.map((user) => user.id)
      const startDate = moment(dates?.selected?.startAt).startOf('d')
      const endDate = moment(dates?.selected?.endAt).endOf('d')

      tasks = tasks.filter((ilUserTask) => {
        const userFilter = userIds.includes(ilUserTask.userId)
        const dateFilter =
          moment(ilUserTask.startAt).isSameOrAfter(startDate) && moment(ilUserTask.endAt).isSameOrBefore(endDate)
        return userFilter && dateFilter
      })

      let filteredWorkflows: IlWorkflow[] = this.workflowGroup?.ilWorkflows.map((w) => {
        let newW = Object.assign({}, w)
        newW.ilUserTasks = newW.ilUserTasks.filter((ut) => {
          return tasks.map((t) => t.id).includes(ut.id)
        })
        return newW
      })

      if (path?.name === 'Ideal') {
        try {
          let ranking = await this.illuminate
            .getRankedPaths(
              IlPathType.Ideal,
              filteredWorkflows.map((w) => w.pathId),
            )
            .pipe(first())
            .toPromise()
          selectedPath = [...ranking?.data?.ilPath].sort((a, b) => a.rank - b.rank)[0]?.pathId

          filteredWorkflows = filteredWorkflows.filter((w) => w.pathId === selectedPath)
        } catch (e) {
          selectedPath = null
        }
      } else if (path?.id) {
        selectedPath = path.id
        filteredWorkflows = filteredWorkflows.filter((w) => w.pathId === selectedPath)
      }

      await this.graph.drawGraph(filteredWorkflows)

      this.activePath = path

      try {
        await this.illuminate
          .getWorkflowStats(
            this.workflowGroup?.id,
            selectedPath,
            userIds,
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD'),
          )
          .pipe(first())
          .subscribe((data) => {
            if (selectedPath) {
              this.pathStats = data?.data?.ilWorkflowStats
            } else {
              this.workflowStats = data?.data?.ilWorkflowStats
            }
          })

        let taskIds = tasks.map((t) => t.ilTask.id)

        let promises = await Promise.all(
          taskIds.map((id) =>
            this.illuminate
              .getTaskStats(id, userIds, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
              .pipe(first())
              .toPromise(),
          ),
        )

        promises.forEach((p, i) => {
          this.taskStats[taskIds[i]] = p?.data?.ilTaskStats
        })
      } catch (e) {
        debug('illuminate', 'failed setting stats', e)
      }
    }
  }

  selectPathMode(isStarting: boolean): void {
    this.selectedTaskIds = []
    if (isStarting) {
      this.graphMode = 'selectMultiple'

      this.graph.graph.getNodes().forEach((node) => {
        const isTerminalNode = ['start', 'end'].includes(node.getID())
        const isFirstNode = node.getEdges().some((edge) => edge.getSource()?.getID() === 'start')
        if (!isTerminalNode && !isFirstNode) {
          node.setState('disabled', true)
        }
      })
      this.graph.graph.getEdges().forEach((edge) => {
        if (edge.getSource()?.getID() !== 'start') {
          edge.setState('disabled', true)
        }
      })
    } else {
      this.graph.graph.getNodes().forEach((node) => {
        node.setState('disabled', false)
        node.setState('selected', false)
      })
      this.graph.graph.getEdges().forEach((edge) => {
        edge.setState('disabled', false)
        edge.setState('selected', false)
      })
    }
    this.isSelectingPath = isStarting
  }

  /**
   * Conditionally select a node that is part of the current path
   *
   * RETURN if selection allowed
   * If not allowed, reset node state
   *
   * @param {INode} node
   * @memberof IlluminateGraphPanelComponent
   */
  maybeSelectPathNode(nodes: INode[]): void {
    if (this.isSelectingPath) {
      if (nodes?.length) {
        let newlySelectedNodeId = difference(
          nodes.map((n) => n.getID()),
          this.selectedTaskIds,
        )[0]
        let node = this.graph.graph.findById(newlySelectedNodeId) as INode
        // G6 yeets the selected back at you w/o any order that makes sense to me
        nodes = [node, ...nodes.filter((n) => n.getID() !== newlySelectedNodeId)].reverse()
        // selection is legit IFF
        // 1. Node is direct child of already selected node ( or first in a path )
        // 2. No other direct child of node's parent is selected ( or no other first in path is selected )
        let taskId = node.getID()
        let nodeParents = node.getInEdges().map((edge) => edge.getSource())

        // check if first node in path & no other selected
        let startIsParent = nodeParents.find((node) => node.getID() === 'start')
        if (
          this.selectedTaskIds?.length === 0 &&
          startIsParent &&
          !startIsParent
            ?.getOutEdges()
            .map((e) => e.getSource().hasState('selected'))
            .includes(true)
        ) {
          // is first in path w/ no other first in paths selected
          this.selectedTaskIds = nodes.map((n) => n.getID())
          this.graph.updateEdgeStates('start', taskId, this.selectedTaskIds)
          this.graph.updateNodeStates('start', taskId, this.selectedTaskIds)

          return
        }

        // check if node ID proceeds previously selected node in a path
        let selectedParent = nodeParents.find((node) => node.hasState('selected'))

        if (selectedParent && this.illuminate.isTaskSequenceInPath([...this.selectedTaskIds, taskId])) {
          this.selectedTaskIds = nodes.map((n) => n.getID())
          this.graph.updateEdgeStates(selectedParent.getID(), taskId, this.selectedTaskIds)
          this.graph.updateNodeStates(selectedParent.getID(), taskId, this.selectedTaskIds)

          let activePath = this.illuminate.allPaths.find(
            (path) =>
              path.taskIds.every((id) => this.selectedTaskIds.includes(id)) &&
              path.taskIds?.length === this.selectedTaskIds?.length,
          )
          if (activePath) {
            this.graph.updateEdgeStates(taskId, 'end', this.selectedTaskIds)
            this.graph.updateNodeStates(taskId, 'end', this.selectedTaskIds)
            this.activePath = { name: null, id: activePath.pathId }
          }
          return
        }

        node.setState('disabled', true)
        node.setState('selected', false)
        this.selectedTaskIds = nodes?.map((n) => n.getID()).filter((id) => id !== taskId)
      } else {
        this.selectPathMode(true)
      }
    }
  }

  /**
   * Start / stop main workflow graph animation
   *
   * @memberof IlluminateGraphPanelComponent
   */
  toggleAnimation(): void {
    if (this.animationInterval) {
      window.clearInterval(this.animationInterval)
      this.animationInterval = null
      let { nodes, edges } = this.graph.graphData

      // edges.forEach((edge, index) => {
      //   edges[index].style.lineWidth = 2 + (edge.count / this.opportunity.processVolume) * 12
      // })

      this.graph.graphData = { nodes: nodes, edges: edges }
    } else {
      let rootNode = this.graph.graph.getNodes().find((node) => node.getID() === '1')
      this.animationInterval = window.setInterval(() => this.animateDot(rootNode), 200) // TODO: switch to rxjs interval
    }
  }

  /**
   * Animate dots in the left / main graph
   *
   * @param {INode} startingNode
   * @memberof IlluminateGraphPanelComponent
   */
  animateDot(startingNode: INode): void {
    let possibleEdges = this.graph.graph.getEdges().filter((edge) => edge.getSource().getID() === startingNode.getID())
    if (possibleEdges.length) {
      let probabilities = possibleEdges.map((edge) => {
        let edgeData = this.graph.graphData.edges.find((data) => data.id === edge.getID())
        let percent = (edgeData as any).percent || 0
        return parseInt(percent) / 100
      })
      let totalProbability = sum(probabilities)
      if (totalProbability !== 1) {
        // console.warn('Probabilities of edges leading away from node do not total 100%', startingNode)
      }
      let chosenIndex = this.graph.probabilisticChoice(probabilities)
      let chosenEdge = possibleEdges[chosenIndex]
      if (!chosenEdge) {
        // console.error('Error choosing edge', { possibleEdges, probabilities, chosenIndex })
        return
      }
      let chosenEdgeShape = chosenEdge.getKeyShape()
      let startingPoint = (chosenEdgeShape as any).getPoint(0)
      const circle = chosenEdge.getContainer().addShape('circle', {
        attrs: {
          x: startingPoint.x,
          y: startingPoint.y,
          fill: '#1890ff',
          r: 6,
        },
      })
      circle.animate(
        (ratio) => {
          const intermediatePoint = (chosenEdgeShape as any).getPoint(ratio)
          return {
            x: intermediatePoint.x,
            y: intermediatePoint.y,
          }
        },
        {
          duration: 2000,
          // easing: 'linearEasing', // TODO: figure this out
          callback: () => {
            chosenEdge.getContainer().removeChild(circle)
            this.animateDot(chosenEdge.getTarget())
          },
        },
      )
    }
  }

  /**
   * Set default active user filter based on all userTasks
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof IlluminateGraphPanelComponent
   */
  private async setActiveUsers(pathId?: string): Promise<User[]> {
    try {
      let tasks = this.illuminate.extractUserTasks(pathId)
      if (tasks?.length) {
        let uniqIds = uniq(tasks?.map((ilUserTask) => ilUserTask.userId))
        let users = await Promise.all(uniqIds?.map((id) => this.users.getUser(id).pipe(first()).toPromise()))

        return users.map((promise) => promise.data.user)
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load users'), JSON.stringify(e))
      return []
    }
  }

  /**
   * Set default start / end filter based on all userTasks
   *
   * @private
   * @memberof IlluminateGraphPanelComponent
   */
  private setActiveDates(pathId?: string): { startAt: string; endAt: string } {
    let tasks = this.illuminate.extractUserTasks(pathId)
    if (tasks?.length) {
      let allStarts = tasks?.map((ilUserTask) => moment(ilUserTask.startAt).startOf('d'))
      let allEnds = tasks?.map((ilUserTask) => moment(ilUserTask.endAt).endOf('d'))

      return {
        startAt: moment.min(allStarts).subtract(1, 'd').format('MM/DD/YYYY'),
        endAt: moment.max(allEnds).add(1, 'd').format('MM/DD/YYYY'),
      }
    }
  }

  constructor(
    private illuminate: IlluminateService,
    private users: UsersService,
    private toast: ToastService,
    public graph: IlluminateGraphFunctionsService,
  ) {
    this.illuminate.currentWorkflowGroup
      .pipe(
        filter((workflowGroup) => workflowGroup != null),
        takeUntil(this.destroy$),
      )
      .subscribe(async (workflowGroup) => {
        this.workflowGroup = workflowGroup

        let users = await this.setActiveUsers()
        let dates = this.setActiveDates()

        this.illuminate.filters.next({
          path: { id: null, name: 'Baseline' },
          users: { possible: users, selected: users },
          dates: { possible: dates, selected: dates },
        })

        this.illuminate.allPaths = uniqBy(
          this.workflowGroup.ilWorkflows.map((w) => ({
            pathId: w.pathId,
            taskIds: w.ilUserTasks.map((ut) => ut.ilTask.id),
          })),
          'pathId',
        )
      })

    this.illuminate.filters
      .pipe(
        filter((filters) => filters !== null),
        takeUntil(this.destroy$),
      )
      .subscribe(async (filters) => {
        const { path, users, dates } = filters
        // When changing path, reset possible users / dates
        if (!isEqual(path, this.activePath)) {
          this.activePath = path
          let users = await this.setActiveUsers(path.id)
          let dates = this.setActiveDates(path.id)

          this.illuminate.filters.next({
            path: path,
            users: { possible: users, selected: users },
            dates: { possible: dates, selected: dates },
          })
          return
        }

        this.changeFilters(path, users, dates)
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isAnimating && !changes.isAnimating.isFirstChange()) {
      this.toggleAnimation()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
