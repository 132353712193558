import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskCardActionsService } from 'app/admin/task-types/task-card-actions.service'
import { ClaimsService } from 'app/claims/claims.service'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskType } from 'generated/graphql'
import { Subscription } from 'rxjs'

/**
 * Page to allow merging list of task types into one source task type
 *
 * @export
 * @class TaskTypeMergePage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-task-type-merge-page',
  templateUrl: './merge.page.html',
  styleUrls: ['./merge.page.scss'],
})
export class TaskTypeMergePage implements OnInit, OnDestroy {
  rightSub$: Subscription
  twoRightSub$: Subscription
  removeSub$: Subscription
  pencilSub$: Subscription

  keep: TaskType = null
  keepCollapsed = true
  merge: TaskType[] = []
  mergeCollapsed = []

  saving: boolean = false

  constructor(
    private router: Router,
    private actionService: TaskCardActionsService,
    private toast: ToastService,
    private modal: NgbModal,
    private claimService: ClaimsService,
    private tasktypeService: TaskTypesService,
  ) {
    this.rightSub$ = actionService.rightClicked$.subscribe((taskType: TaskType) => {
      if (this.keep) {
        this.actionService.hideCard(this.keep, false)
      }
      this.keep = taskType
      let exists = this.merge.findIndex((tt) => tt.id === taskType.id)
      if (exists > -1) {
        this.merge.splice(exists, 1)
        this.mergeCollapsed.splice(exists, 1)
      }
      this.actionService.hideCard(taskType, true)
    })
    this.twoRightSub$ = actionService.twoRightClicked.subscribe((taskType: TaskType) => {
      let exists = this.merge.findIndex((tt) => tt.id === taskType.id)
      if (exists === -1) {
        this.merge.push(taskType)
        this.mergeCollapsed.push(true)
      }
      if (this.keep?.id === taskType.id) {
        this.keep = null
      }
      this.actionService.hideCard(taskType, true)
    })
    this.removeSub$ = actionService.removeClicked$.subscribe((taskType: TaskType) => {
      if (this.keep?.id === taskType.id) {
        this.keep = null
      } else {
        let exists = this.merge.findIndex((tt) => tt.id === taskType.id)
        this.merge.splice(exists, 1)
        this.mergeCollapsed.splice(exists, 1)
      }
      this.actionService.hideCard(taskType, false)
    })
    this.pencilSub$ = actionService.pencilClicked$.subscribe((taskType: TaskType) => {
      router.navigate(['admin/task-types/edit', taskType.id])
    })
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.rightSub$.unsubscribe()
    this.twoRightSub$.unsubscribe()
    this.removeSub$.unsubscribe()
    this.pencilSub$.unsubscribe()
  }

  /**
   * Cancel current merge and clear inputs
   *
   * @memberof TaskTypeMergePage
   */
  cancel(): void {
    this.keep = null
    this.merge = []
    this.actionService.hidden = []
  }

  /**
   * Launch modal to confirm merge
   *
   * @memberof TaskTypeMergePage
   */
  async confirmSave(): Promise<void> {
    let calls = await Promise.all(this.merge.map((tt) => this.claimService.getTotalClaimsByTaskType(tt.id)))
    let totalClaims = calls.reduce((a, b) => a + b, 0)

    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Merge task types?'
    modalRef.componentInstance.body = `This will update tasks on about ${totalClaims} claim(s)`
    modalRef.componentInstance.yes = 'Merge task types'
    modalRef.componentInstance.yesClass = 'btn-brand-green'

    modalRef.result.then(
      (closed) => {
        this.save()
      },
      (dismissed) => {},
    )
  }

  /**
   * Merge 'merge' list of task types into 'keep' task type
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeMergePage
   */
  async save(): Promise<void> {
    try {
      let merged = await this.tasktypeService.mergeTaskTypes(
        this.merge.map((tt) => tt.id),
        this.keep.id,
      )
      this.toast.success(`Merged ${merged} tasks`)
      this.cancel()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not merge task types'), JSON.stringify(e))
    }
  }
}
