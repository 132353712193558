import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskComment, User } from 'generated/graphql'
import * as moment from 'moment'

/**
 * Component to display a task's comments
 *
 * @export
 * @class TaskCommentsComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-task-comments',
  templateUrl: './task-comments.component.html',
  styleUrls: ['./task-comments.component.scss'],
})
export class TaskCommentsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() comments: Array<{ comment: TaskComment; user: User }>
  @Input() taskId: string
  @Input() loading: boolean

  @ViewChild('taskList') taskList: ElementRef

  newComment = new UntypedFormControl('')
  saving: boolean = false

  constructor(private taskService: TasksService, private toast: ToastService, private modal: NgbModal) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.sortAndScroll()
  }

  ngAfterViewInit(): void {
    this.sortAndScroll()
  }

  /**
   * Launch modal to create a new comment
   *
   * @param {*} content
   * @memberof TaskCommentsComponent
   */
  openModal(content: TemplateRef<NgbModal>): void {
    this.modal.open(content, {
      centered: true,
    })
  }

  /**
   * Helper to sort comments and scroll to the last one
   *
   * @private
   * @memberof TaskCommentsComponent
   */
  private sortAndScroll() {
    if (this.comments?.length) {
      this.comments = this.comments.sort((a, b) => moment(a?.comment?.createdAt).diff(moment(b?.comment?.createdAt)))
      setTimeout(() => {
        let taskList = this.taskList?.nativeElement
        if (taskList) {
          let listItems = taskList?.children
          taskList.children[listItems.length - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
      }, 500)
    }
  }

  /**
   * Create a task comment
   *
   * @return {*} {Promise<void>}
   * @memberof TaskCommentsComponent
   */
  async addComment(): Promise<void> {
    if (this.newComment.invalid) {
      return
    }
    this.saving = true
    try {
      await this.taskService.createTaskComment(this.newComment.value, this.taskId)
      this.toast.success('Comment saved')
      this.newComment.setValue('')
      this.modal.dismissAll()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not save comment'), JSON.stringify(e))
    }
    this.saving = false
  }
}
