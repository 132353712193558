<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col-3 pt-2">
      <app-organization-typeahead
        (onSelect)="onOrgChange($event)"
        [placeholder]="currentOrg?.name || 'Search for organization'"
        [autoFocus]="false"
        id="org"
      ></app-organization-typeahead>
    </div>
  </div>

  @if (currentOrg) {
    <div class="card detail-card mt-3">
      <div class="card-header">Configure Build - {{ currentOrg.name }}</div>
      <div class="card-body">
        <div class="form-row p-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="shouldObfuscateToggle"
              [formControl]="shouldObfuscate"
            />
            <label class="custom-control-label" for="shouldObfuscateToggle">Should Obfuscate?</label>
          </div>
        </div>

        <div class="form-row p-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="shouldUpdateToggle"
              [formControl]="shouldUpdate"
            />
            <label class="custom-control-label" for="shouldUpdateToggle">Should Update?</label>
          </div>
        </div>

        <div class="form-row p-3">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="installPrerequisitesToggle"
              [formControl]="installPrerequisites"
            />
            <label class="custom-control-label" for="installPrerequisitesToggle">Install Prerequisites?</label>
          </div>
        </div>

        <div class="form-row p-3">
          <app-tag-search #tagSearch [availableTags]="availableTags" [formControl]="tagToBuild" [autoFocus]="false" (onSelect)="onTagChange($event)"></app-tag-search>
        </div>
        <div class="form-row p-3">
          <button (click)="onCancel($event)" class="btn btn-cancel btn-link pl-3 pr-3 mr-3">Cancel</button>
          <button (click)="onSubmit()" class="btn btn-primary pl-3 pr-3">
            <i class="fa fa-play mr-2"></i>
            Start Build
          </button>
        </div>
      </div> 
    </div>
  }
</div>
