import { Injectable, inject } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { ToastService } from 'app/shared/services/toast.service'
import {
  CreateUser,
  DeleteUser,
  GetUser,
  GetUserWithIsSamlEmail,
  SearchUsers,
  UpdateUser,
} from 'app/shared/services/users.gql'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import {
  CreateUserInput,
  MutationCreateUserArgs,
  MutationDeleteUserArgs,
  MutationUpdateUserArgs,
  QueryUserArgs,
  QueryUsersArgs,
  User,
  UserRole,
  UserStatus,
} from 'generated/graphql'
import { Observable, catchError, map, of, shareReplay } from 'rxjs'

/**
 * Handle user operations
 *
 * @export
 * @class UsersService
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apollo = inject(Apollo)
  private toastService = inject(ToastService)

  /**
   * Observable to the query that fetches users. In most cases, this should not
   * be directly subscribed to; however, you can use use this query to trigger
   * data fetches.
   *
   * Defaults to setting `activeOnly: false`.
   */
  getUsersQuery$ = this.apollo.watchQuery<{ users: User[] }, QueryUsersArgs>({
    query: SearchUsers,
    variables: {
      activeOnly: false,
      limit: 100,
    },
  })

  /**
   * Observable that emits data records emitted from the query.
   *
   * On error, a toast message is displayed and an empty array is emitted.
   */
  getUsersQueryData$ = this.getUsersQuery$.valueChanges.pipe(
    catchError((error) => {
      const errorMessage = parseGraphQLError(error)
      this.toastService.error(errorMessage)

      return of({ data: { users: [] } })
    }),
    map((result) => result.data.users as User[]),
    shareReplay(),
  )

  /**
   * Retrieve a single user
   *
   * @param {string} id
   * @return {*}  {Promise<User>}
   * @memberof UsersService
   */

  getUser(id: string, options?: { includeIsSamlEmail?: boolean }): Observable<ApolloQueryResult<{ user: User }>> {
    debug('users', 'user service get user', id)

    let query = GetUser

    if (options?.includeIsSamlEmail) {
      query = GetUserWithIsSamlEmail
    }

    return this.apollo.watchQuery<{ user: User }, QueryUserArgs>({
      query,
      variables: { id },
    }).valueChanges
  }

  /**
   * Retrieve a group of users
   *
   * @param {string} search
   * @param {string[]} [ids]
   * @param {boolean} [activeOnly]
   * @return {*}  {Promise<User[]>}
   * @memberof UsersService
   */
  async getUsers(search: string, ids?: string[], activeOnly?: boolean): Promise<User[]> {
    debug('users', 'user service get users', search, ids)
    let result = await this.apollo
      .query<{ users: User[] }, QueryUsersArgs>({
        query: SearchUsers,
        variables: {
          search: search,
          ids: ids,
          activeOnly: activeOnly,
        },
        fetchPolicy: 'network-only',
      })
      .toPromise()

    return result.data?.users
  }

  /**
   * Create a new user
   *
   * @param {CreateUserInput} data
   * @return {*}  {Promise<User>}
   * @memberof UsersService
   */
  async createUser(data: CreateUserInput): Promise<User> {
    debug('users', 'user service create user')
    let result = await this.apollo
      .mutate<{ createUser: User }, MutationCreateUserArgs>({
        mutation: CreateUser,
        variables: { data },
      })
      .toPromise()

    return result.data?.createUser
  }

  /**
   * Update an existing user
   *
   * @param {string} id
   * @param {string} name
   * @param {string} email
   * @param {UserRole} role
   * @param {boolean} isStaff
   * @param {boolean} isSuperuser
   * @param {boolean} isActive
   * @return {*}  {Promise<User>}
   * @memberof UsersService
   */
  async updateUser(
    id: string,
    name: string,
    email: string,
    role: UserRole,
    isStaff: boolean,
    isSuperuser: boolean,
    isActive: boolean,
    allowedOrgIds?: string[],
    roleIds?: string[],
  ): Promise<User> {
    debug('users', 'user service update user', id)
    let variables = {
      id,
      data: {
        name,
        email,
        role,
        isStaff,
        isSuperuser,
        status: isActive ? UserStatus.Active : UserStatus.Inactive,
        allowedOrgIds,
        roleIds,
      },
    }
    let result = await this.apollo
      .mutate<{ updateUser: User }, MutationUpdateUserArgs>({
        mutation: UpdateUser,
        variables: variables,
      })
      .toPromise()

    return result.data?.updateUser
  }

  /**
   * Delete an existing user
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof UsersService
   */
  async deleteUser(id: string): Promise<boolean> {
    debug('users', 'user service delete user', id)
    let result = await this.apollo
      .mutate<{ deleteUser: boolean }, MutationDeleteUserArgs>({
        mutation: DeleteUser,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data?.deleteUser
  }
}
