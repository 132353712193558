<div class="d-flex justify-content-between align-items-center">
  <h5 class="mb-0">Comments</h5>
  <button class="btn btn-link flex-shrink-1" (click)="openModal(commentModal)" data-t="add-comment-button">
    <i class="fa fa-fw fa-plus-circle"></i>
    Add Comment
  </button>
</div>
<app-loading *ngIf="loading" innerClass="fa-lg" class="d-flex justify-content-center my-5">
  Loading comments...
</app-loading>
<ul class="list-unstyled height-restrict mb-5" data-t="task-comments-list" id="task-comments-list" #taskList>
  <li
    class="card bg-light"
    *ngFor="let comment of comments; first as first; last as last"
    [ngClass]="first ? 'mb-3' : last ? 'mt-3' : 'my-3'"
  >
    <span class="card-body">
      <strong class="d-block text-dark">
        {{ comment?.user?.name }}
        <small>{{ comment?.comment?.createdAt ? (comment.comment.createdAt | date: 'medium') : '' }}</small>
      </strong>
      <p [innerHTML]="comment?.comment?.comment"></p>
    </span>
  </li>
  <li class="card bg-light my-3" *ngIf="!comments?.length">
    <span class="card-body text-center">No comments yet</span>
  </li>
</ul>
<ng-template #commentModal let-modal>
  <div class="modal-body">
    <textarea
      class="form-control"
      cols="30"
      rows="2"
      [formControl]="newComment"
      placeholder="Add a comment..."
      data-t="task-comments-text-area"
    ></textarea>
  </div>
  <div class="modal-footer">
    <div class="d-flex justify-content-end mt-3">
      <button type="button" class="btn btn-cancel" (click)="modal.close()">Cancel</button>
      <button
        type="button"
        class="btn btn-brand-green px-5"
        (click)="addComment()"
        [disabled]="newComment.value === ''"
        data-t="task-comment-save-button"
      >
        Save
        <app-loading *ngIf="saving" class="pl-2" outerClass="align-self-center"></app-loading>
      </button>
    </div>
  </div>
</ng-template>
