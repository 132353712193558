<div class="row flex-grow-1">
  <div class="col-lg-3 mt-2">
    <app-faceted-search></app-faceted-search>
  </div>
  <div class="col-lg-9 d-flex flex-column flex-grow-1">
    <div class="d-flex justify-content-between">
      <app-selected-facets class="my-2"></app-selected-facets>
      <div class="flex-shrink-0 d-flex pb-2 align-items-end">
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light ml-2"
          (click)="saveSearch()"
          [disabled]="saveSearchDisabled || loading"
          ngbTooltip="Save current search"
          container="body"
          data-t="save-search-button"
        >
          <i class="fa fa-save"></i>
        </button>
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light ml-2"
          (click)="loadSearch()"
          [disabled]="loading"
          ngbTooltip="Load a saved search"
          container="body"
          data-t="load-saved-search-button"
        >
          <i class="fa fa-folder-o"></i>
        </button>
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light ml-2"
          *ngIf="authenticationService.canAccessManagerViews()"
          (click)="prepareClaimExport()"
          [disabled]="loading"
          ngbTooltip="Export claims using visible columns"
          container="body"
          data-t="claims-list-download-button"
        >
          <i class="fa fa-download"></i>
        </button>
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light mx-1"
          (click)="assignTasksToClaims()"
          data-t="assign-tasks-button"
          [disabled]="(selected?.length === 0 && !selectedAll) || loading"
          ngbTooltip="Assign tasks to selected claims"
          container="body"
        >
          Assign Tasks
        </button>
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light mx-1"
          *ngIf="authenticationService.canAccessAdmin()"
          (click)="deleteTasksOnClaims()"
          data-t="delete-tasks-button"
          [disabled]="selected?.length === 0 || loading"
          ngbTooltip="Delete tasks on selected claims"
          container="body"
        >
          Delete Tasks
        </button>
        <button
          *ngIf="isWorkflowsEnabled"
          type="button"
          class="btn btn-alt-dark-blue text-light mx-1"
          (click)="assignWorkflowsToClaims()"
          data-t="assign-workflows-button"
          [disabled]="(selected?.length === 0 && !selectedAll) || loading"
          ngbTooltip="Assign workflows to selected claims"
        >
          Assign Workflows
        </button>
        <button
          type="button"
          class="btn btn-alt-dark-blue text-light mx-1"
          (click)="changeColumns()"
          ngbTooltip="Change columns to display in table"
          container="body"
          data-t="select-columns"
        >
          <i class="fa fa-fw fa-gear"></i>
        </button>
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
      <table class="table table-janus table-striped" data-t="claims-table">
        <thead>
          <tr *ngIf="hasColumnGroups" class="parent-headings">
            <th colspan="1" class="no-heading"></th>
            <ng-template ngFor let-col [ngForOf]="columnGroups">
              <th [colSpan]="col.span" [ngClass]="{ 'no-heading': !col.heading?.length }">{{col.heading}}</th>
            </ng-template>
            <th class="no-heading" *ngIf="isFavoritesEnabled"></th>
          </tr>
          <tr [ngClass]="{ 'border-top': hasColumnGroups }">
            <th scope="col" class="text-center">
              <div class="custom-control custom-checkbox">
                <input
                  id="selectAll"
                  class="custom-control-input"
                  (change)="selectAllClaims($event)"
                  type="checkbox"
                  [indeterminate]="selected?.length && selected?.length !== meta?.total && !selectedAll"
                  [checked]="selectedAll || selected?.length === meta?.total"
                />
                <label for="selectAll" class="custom-control-label">
                  <span class="sr-only">Select All Claims </span></label
                >
              </div>
            </th>
            <ng-template ngFor let-col [ngForOf]="columns">
              <th
                scope="col"
                *ngIf="col.sortable"
                [sortable]="col.property"
                (sort)="onSort($event)"
                [ngbTooltip]="col.tooltip"
                container="body"
              >
                {{col.heading || col.label}}
              </th>
              <th scope="col" *ngIf="!col.sortable" [ngbTooltip]="col.tooltip" container="body">
                {{col.heading || col.label}}
              </th>
            </ng-template>
            <th *ngIf="isFavoritesEnabled" class="width-1"><span class="sr-only">Favorite</span></th>
          </tr>
        </thead>
        <tbody *ngIf="!loading && claims?.length">
          <tr
            *ngFor="let claim of claims; index as i"
            (click)="selectClaim(claim.providerClaimId)"
            class="clickable"
            data-t="claim-card"
          >
            <td scope="row" class="text-center">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [checked]="selected.includes(claim.providerClaimId)"
                  data-t="claim-checkbox"
                />
              </div>
            </td>
            <ng-template ngFor let-col [ngForOf]="columns">
              <td
                scope="row"
                [ngClass]="{'text-center': !col.type, 'text-right': col.type}"
                [attr.data-t]="col.property"
              >
                <a
                  *ngIf="col.property === 'providerClaimId'"
                  routerLink="/claims/{{ claim.providerClaimId }}"
                  (click)="$event.stopPropagation()"
                  data-t="claim-id"
                >
                  {{ claim.providerClaimId }}
                </a>
                <ng-template [ngIf]="isArray(claim[col.property.split('.')[0]])" [ngIfElse]="singleValue">
                  <ng-template [ngIf]="claim[col.property.split('.')[0]].length > 4" [ngIfElse]="lessThanFive">
                    {{claim[col.property.split('.')[0]].length}}
                    <i
                      class="fa fa-question-circle ml-3"
                      [ngbTooltip]="lessThanFive"
                      triggers="mouseenter:click"
                      container="body"
                    ></i>
                  </ng-template>
                  <ng-template #lessThanFive>
                    <ng-template ngFor let-prop let-last="last" [ngForOf]="claim[col.property.split('.')[0]]">
                      <ng-template [ngIf]="col.type === PROPTYPE.DATE">
                        {{prop | get: col.property.split('.').slice(1):'' | date: 'MM/dd/yyyy'}}
                      </ng-template>
                      <ng-template [ngIf]="col.type === PROPTYPE.CURRENCY">
                        {{prop | get: col.property.split('.').slice(1):'0' | currency}}
                      </ng-template>
                      <ng-template [ngIf]="!col.hasOwnProperty('type') && col.property !== 'providerClaimId'">
                        {{prop | get: col.property.split('.').slice(1):''}}
                      </ng-template>
                      <br *ngIf="(prop | get: col.property.split('.').slice(1):'')?.length > 0 && !last" />
                    </ng-template>
                  </ng-template>
                </ng-template>
                <ng-template #singleValue>
                  <ng-template [ngIf]="col.type === PROPTYPE.DATE">
                    {{claim | get: col.property:'' | date: 'MM/dd/yyyy'}}
                  </ng-template>
                  <ng-template [ngIf]="col.type === PROPTYPE.CURRENCY">
                    {{claim | get: col.property:'0' | currency}}
                  </ng-template>
                  <ng-template [ngIf]="!col.hasOwnProperty('type') && col.property !== 'providerClaimId'">
                    {{claim | get: col.property:''}}
                  </ng-template>
                </ng-template>
              </td>
            </ng-template>
            <td *ngIf="isFavoritesEnabled" class="width-1">
              <button
                class="btn btn-link"
                (click)="toggleFav($event, claim.id)"
                [attr.data-t]="'favorite-'+claim.providerClaimId"
              >
                <i
                  class="fa fa-fw fa-lg text-alt-light-blue"
                  [ngClass]="favorited.includes(claim.id) ? 'fa-star' : 'fa-star-o'"
                  [attr.data-t]="'favorite-star-'+claim.providerClaimId"
                ></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="loading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      ></app-loading>
      <div *ngIf="!claims?.length && !loading" class="d-flex justify-content-center alert alert-danger" role="alert">
        Your search returned no results.
      </div>
    </div>
    <div class="d-flex p-2 justify-content-between">
      <div class="d-flex">
        <div *ngIf="selected?.length || selectedAll">
          Selected
          <span *ngIf="selectedAll">
            {{meta?.total > maxSelectAll ? '' : 'all'}} {{meta?.total > maxSelectAll ? (maxSelectAll | number) :
            (meta?.total | number)}}
          </span>
          <span *ngIf="!selectedAll"> {{selected?.length | number}} </span>
          claim(s).
          <button
            class="btn btn-link"
            (click)="selectAllAllClaims()"
            *ngIf="meta?.total > meta?.limit && selected?.length < maxSelectAll && selected?.length < meta?.total && !selectedAll"
          >
            <ng-template [ngIf]="meta?.total > maxSelectAll">Select first {{maxSelectAll | number}} claims</ng-template>
            <ng-template [ngIf]="meta?.total <= maxSelectAll">Select all {{meta?.total | number}} claim(s)</ng-template>
          </button>
        </div>
      </div>
      <div class="d-flex align-items-baseline" *ngIf="claims?.length && !loading">
        <small class="mr-3">
          {{page * pageSize - pageSize + 1 | number}} - {{claims?.length < pageSize ? meta?.total : page * pageSize |
          number}} of {{meta?.total | number}}
        </small>
        <ngb-pagination
          [collectionSize]="meta?.total"
          (pageChange)="onPageChange($event)"
          [pageSize]="25"
          [(page)]="page"
          [maxSize]="5"
          *ngIf="meta?.total >= pageSize"
          [boundaryLinks]="false"
          data-t="claims-list-pagination"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
