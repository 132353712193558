@if(!breadcrumbService.disabledBreadcrumbDisplay) {
<nav aria-label="breadcrumb" *ngIf="breadcrumbService.breadcrumbs.length" class="bg-alt-barely-blue">
  <ol class="breadcrumb mb-0 px-4">
    <li
      class="breadcrumb-item"
      *ngFor="let crumb of breadcrumbService.breadcrumbs; last as isLast; let i = index"
      [class.active]="isLast"
    >
      <i
        *ngIf="i > 0 && i < breadcrumbService.breadcrumbs.length"
        class="fa fa-angle-right m-auto pr-2 text-alt-dark-gray"
      ></i>
      <a
        *ngIf="!isLast"
        [routerLink]="crumb.link"
        [queryParams]="{ refreshFiltersFromState: crumb.preserveFilters }"
        class="text-alt-light-blue"
        >{{ crumb.label }}</a
      >
      <span *ngIf="isLast" class="text-alt-dark-gray">{{ crumb.label }}</span>
    </li>
  </ol>
</nav>
}
