<a class="card detail-card-new my-4 bg-light text-reset" [routerLink]="['/pathfinder', taskGroup?.id]">
  <div class="card-header border-0 py-1"></div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-9 mr-lg-0 mr-6">
        <h5 class="card-title text-alt-steel-blue" data-t="task-group-name">{{ taskGroup?.name }}</h5>
        <p class="card-text" [innerHTML]="taskGroup?.description"></p>
        <div class="d-lg-none d-flex justify-content-end">
          <small>
            Remaining Outstanding Amount: <strong>{{ stats?.remainingOutstandingAmount | currency }}</strong>
          </small>
          <small class="ml-3">
            Remaining Predicted Amount: <strong>{{ stats?.remainingPredictedAmount | currency }}</strong>
          </small>
        </div>
        <div class="d-flex align-items-center">
          <app-task-group-assign-avatar
            [users]="users"
            (editUsers)="onEditUsers($event)"
          ></app-task-group-assign-avatar>
          <div class="d-lg-none d-flex flex-grow-1">
            <span class="flex-grow-1 align-self-center mr-3">
              <ngb-progressbar
                type="success"
                class="progress"
                [max]="stats?.totalTasks"
                [value]="stats?.completedTasks"
                [striped]="true"
              ></ngb-progressbar>
            </span>
            <small class="flex-shrink-1">
              {{ stats?.completedTasks | number }} / {{ stats?.totalTasks | number }}
            </small>
          </div>
        </div>
      </div>
      <div class="col-lg-3 d-none d-lg-flex flex-column align-items-end">
        <app-task-group-score [taskGroup]="taskGroup" [stats]="stats"></app-task-group-score>
        <small>
          Remaining Outstanding Amount: <strong>{{ stats?.remainingOutstandingAmount | currency }}</strong>
        </small>
        <small>
          Remaining Predicted Amount: <strong>{{ stats?.remainingPredictedAmount | currency }}</strong>
        </small>
        <div class="d-flex w-100">
          <span class="flex-grow-1 align-self-center mr-3">
            <ngb-progressbar
              type="success"
              class="progress"
              [max]="stats?.totalTasks"
              [value]="stats?.completedTasks"
              [striped]="true"
            ></ngb-progressbar>
          </span>
          <small class="flex-shrink-1"> {{ stats?.completedTasks | number }} / {{ stats?.totalTasks | number }} </small>
        </div>
      </div>
      <app-task-group-score
        [taskGroup]="taskGroup"
        [stats]="stats"
        class="d-lg-none d-md-block right-icon mt-4 mx-4 position-absolute"
      ></app-task-group-score>
    </div>
  </div>
</a>
