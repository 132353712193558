<button
  class="btn btn-block btn-outline-primary copy-btn mt-3"
  (click)="toggleNote(note)"
  [attr.data-clipboard-text]="noteText"
  [attr.aria-expanded]="!noteCollapsed"
>
  Generate Note
</button>
<div class="card bg-alt-dark-blue-light mb-5" #note="ngbCollapse" [(ngbCollapse)]="noteCollapsed">
  <div class="card-body">
    <div class="d-flex flex-column w-100">
      <p [innerHTML]="noteText" class="pre-wrap"></p>
      <button type="button" class="btn btn-primary btn-block copy-btn" [attr.data-clipboard-text]="noteText">
        Copy Note Text<i class="fa fw fa-clone ml-3"></i>
      </button>
    </div>
  </div>
</div>
