<form>
  <div class="row mb-3">
    <label class="col-sm-2 d-flex align-items-center task-input-label">Start</label>
    <div class="col-sm-10">
      <div class="row">
        <div class="col d-flex align-items-center">
          <input
            type="text"
            name="inputFromDate"
            #inputFromDate
            class="form-control"
            placeholder="mm/dd/yyyy"
            [value]="ngbToDate(fromDate)"
            (blur)="setDate('from', inputFromDate.value)"
          />
        </div>
        <div class="col" *ngIf="selectTime">
          <ngb-timepicker
            #timepicker
            [(ngModel)]="fromTime"
            (ngModelChange)="onTimeSelection()"
            [meridian]="true"
            name="fromTime"
          ></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <label class="col-sm-2 d-flex align-items-center task-input-label">End</label>
    <div class="col-sm-10">
      <div class="row">
        <div class="col d-flex align-items-center">
          <input
            type="text"
            name="inputToDate"
            #inputToDate
            class="form-control"
            placeholder="mm/dd/yyyy"
            [value]="ngbToDate(toDate)"
            (blur)="setDate('to', inputToDate.value)"
          />
        </div>
        <div class="col" *ngIf="selectTime">
          <ngb-timepicker
            #timepicker
            [(ngModel)]="toTime"
            (ngModelChange)="onTimeSelection()"
            [meridian]="true"
            name="toTime"
          ></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
</form>
<ngb-datepicker
  #dp
  (dateSelect)="onDateSelection($event)"
  [displayMonths]="1"
  [dayTemplate]="t"
  outsideDays="hidden"
  class="w-100"
>
</ngb-datepicker>

<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
