<div class="card detail-card-new bg-white h-100">
  <div class="card-header d-flex align-items-start justify-content-between">
    <h5>
      <i
        class="fa fa-fw fa-times clickable d-md-inline-block d-lg-none"
        (click)="isSidebarCollapsedChange.emit(true)"
      ></i>
      Claim {{ claim?.providerClaimId }}
    </h5>
    <div>
      <button
        class="text-white btn btn-circle btn-primary mr-2"
        [ngClass]="{ disabled: !isPreviousEnabled }"
        (click)="taskNavButtonClick.emit('prev')"
        data-t="previous-task"
      >
        <i class="fa fa-lg fa-fw fa-angle-left pr-1"></i>
      </button>
      <button
        class="text-white btn btn-circle btn-primary ml-2"
        [ngClass]="{ disabled: !isNextEnabled }"
        (click)="taskNavButtonClick.emit('next')"
        data-t="next-task"
      >
        <i class="fa fa-lg fa-fw fa-angle-right pl-1"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <app-claim-status [claim]="claim"></app-claim-status>
    <app-claim-details [claim]="claim"></app-claim-details>
    <app-claim-responsibility [claim]="claim"></app-claim-responsibility>

    <h5 class="mb-3">Other Tasks on Claim</h5>
    <app-task-panel class="d-flex flex-column taller-height-restrict mb-5" [tasks]="claim?.tasks"></app-task-panel>

    <app-claim-servicelines
      *ngIf="claim?.servicelines?.length"
      [servicelines]="claim?.servicelines"
    ></app-claim-servicelines>

    <app-claim-payments
      *ngIf="claim?.ehrPayments?.length || claim?.payerPayments?.length"
      [payments]="{ ehr: claim?.ehrPayments, payer: claim?.payerPayments }"
    ></app-claim-payments>
  </div>
</div>
