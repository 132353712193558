import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment'
import { Observable } from 'rxjs'

export type ObserveBuildConfigEnv = 'dev' | 'app';
export type ObserveBuildConfig = {
  id: string;
  orgId: string;
  orgKey: string;
  env: ObserveBuildConfigEnv;
  shouldObfuscate: boolean;
  shouldUpdate: boolean;
  installPrerequisites: boolean;
  uploadData?: boolean;
}

export type ObserveBuildConfigResponse = {
  buildConfig: ObserveBuildConfig;
}

export type StartObserveBuildRequest = {
  orgId: string;
  refName: string;
}

@Injectable({
  providedIn: 'root'
})
export class ObserveBuildService {

  private readonly http = inject(HttpClient)
  private readonly baseUrl = `https://intelligence-platform-api.${environment.name}.janus-ai.com/api`
  
  public getObserveBuildConfig(orgId: string): Observable<ObserveBuildConfigResponse> {
    return this.http.get<ObserveBuildConfigResponse>(`${this.baseUrl}/observe-build/v1/${orgId}/config`)
  }

  public getObserveTags(): Observable<string[]> {
    return this.http.get<any>(`${this.baseUrl}/observe-build/v1/tags`)
  }

  public saveObserveBuildConfig(config: ObserveBuildConfig): Observable<ObserveBuildConfig> {
    return this.http.post<ObserveBuildConfig>(`${this.baseUrl}/observe-build/v1/${config.orgId}/config/${config.id}`, config)
  }

  public startObserveBuild(orgId: string, tagId: string, configId: string): Observable<string> {
    return this.http.post(`${this.baseUrl}/observe-build/v1/${orgId}/deploy/${configId}`, {
      orgId: orgId,
      refName: `refs/tags/${tagId}`
    }, {
      responseType: 'text'
    })
  }
}