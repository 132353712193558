<div class="h-100 d-flex flex-column justify-content-center align-items-stretch" *ngIf="src">
  <ng-container *ngIf="isLoading">
    <span class="h1 text-center"><i class="fa fa-file"></i></span>
    <p class="text-center"><app-loading></app-loading> Loading PDF</p>
  </ng-container>
  <ng-container *ngIf="hasError">
    <span class="h1 text-center"><i class="fa fa-exclamation-circle text-danger"></i></span>
    <p class="text-center">Could not load PDF</p>
  </ng-container>
  <div class="flex-grow-1 position-relative" *ngIf="pages?.length">
    <div
      class="position-absolute overflow-auto d-flex flex-column align-items-center py-3 top-0 right-0 bottom-0 left-0"
      id="pdf-container"
      #pdfContainer
    >
      <canvas *ngFor="let page of pages" class="shadow m-3" [ngClass]="{ 'd-none': !hasRendered }"></canvas>
    </div>
  </div>
</div>
