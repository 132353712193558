import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { VxDocumentTypeService } from 'app/vision-x/services/vx-document-type.service'
import { allVxDocumentTypesOption } from 'app/vx/vx-page-type-list/modals/lib'
import { VxDocumentType } from 'generated/graphql'
import { Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'

type VxDocumentTypeSearch = (term: string) => Observable<{ id: string; name: string }[]>

@Component({
  selector: 'vx-document-type-typeahead',
  templateUrl: './vx-document-type-typeahead.component.html',
})
export class VxDocumentTypeTypeaheadComponent {
  @Input() selectedVxDocumentType: VxDocumentType
  @Input() includeAllOption: boolean = false
  @Output() onVxDocumentTypeSelected: EventEmitter<VxDocumentType> = new EventEmitter<VxDocumentType | null>()

  constructor(public vxDocumentTypeService: VxDocumentTypeService, private toast: ToastService) {}

  buildSearchVxDocumentTypes = this.searchVxDocumentTypes(this.vxDocumentTypeService)

  searchVxDocumentTypes(vxDocumentTypeService: VxDocumentTypeService): VxDocumentTypeSearch {
    return (term: string): Observable<{ id: string; name: string }[]> => {
      return vxDocumentTypeService.fetchVxDocumentTypes({ search: term }).pipe(
        take(1),
        map((result) => {
          let docTypes = result?.data?.vxDocumentTypes?.entities ?? []
          if (this.includeAllOption) {
            docTypes = docTypes.concat(allVxDocumentTypesOption)
          }
          return docTypes
        }),
        catchError((error) => {
          this.toast.error(parseGraphQLError(error, 'Could not fetch list of DocumentTypes'), JSON.parse(error))
          return of([])
        }),
      )
    }
  }

  formatVxDocumentTypeName = (vxDocumentType: VxDocumentType): string => vxDocumentType.name
}
