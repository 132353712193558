import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/**
 * Modal to allow filtering tasks
 *
 * @export
 * @class TaskFilterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
})
export class TaskFilterComponent implements OnInit {
  @Input() completedValue: string

  filters = new UntypedFormGroup({
    //userId: new FormControl(''),
    completed: new UntypedFormControl('all'),
  })

  //userId = this.filters.get('userId') as FormControl
  completed = this.filters.get('completed') as UntypedFormControl

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.completed.setValue(this.completedValue)
  }

  /**
   * Close modal with selected filters
   *
   * @memberof TaskFilterComponent
   */
  applyFilters(): void {
    this.modal.close({ completed: this.completed.value })
  }
}
