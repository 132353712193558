import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { debug } from 'app/shared/utils/debug'
import { Organization } from 'generated/graphql'
import { defer } from 'lodash'
import { merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators'

/**
 * Component to allow searching for and selecting an organization
 * Should be in admin/org/components
 *
 * @export
 * @class OrganizationTypeaheadComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-organization-typeahead',
  templateUrl: './organization-typeahead.component.html',
  styleUrls: ['./organization-typeahead.component.scss'],
})
export class OrganizationTypeaheadComponent implements OnChanges {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Output() onSelect = new EventEmitter<Organization>()
  @Input() selectedOrg: string = ''
  @Input() placeholder: string = 'Search for an organization'
  @Input() clearInput: boolean = true
  @Input() autoFocus: boolean = true
  @Input() showError = false

  searching: boolean = false

  constructor(private orgsService: OrgService) {}

  ngOnChanges(): void {
    if (this.selectedOrg?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedOrg
      })
    }
    setTimeout(() => {
      if (this.autoFocus) {
        this.element.nativeElement.focus()
      }
    })
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      const $activeItems = document.querySelectorAll('ngb-typeahead-window.show > .dropdown-item.active')
      $activeItems.forEach(($item) => $item.scrollIntoView())
    }
  }

  /**
   * Get orgs based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof OrganizationTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<Organization[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((search) =>
        this.orgsService.getOrgs(search).pipe(
          catchError(() => {
            return of({ data: { organizations: { entities: [] } } })
          }),
          first(),
        ),
      ),
      map((result) => result?.data?.organizations?.entities),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {{ name: string }} x
   * @memberof OrganizationTypeaheadComponent
   */
  inputFormatter = (x: { name: string }): string => x.name

  /**
   * Format typeahead results list
   *
   * @param {{ name: string }} x
   * @memberof OrganizationTypeaheadComponent
   */
  resultFormatter = (x: { name: string }): string => x.name

  /**
   * Notify consumers that an org has been selected
   *
   * @param {*} event
   * @memberof OrganizationTypeaheadComponent
   */
  onSelectOrg(event: { item: Organization }): void {
    let org = event.item
    debug('organizations', 'selected an organization', org)
    this.onSelect.emit(org)
    if (this.clearInput) {
      defer(() => (this.element.nativeElement.value = ''))
    } else {
      this.element.nativeElement.value = org?.name
    }
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof OrganizationTypeaheadComponent
   */
  clearOrg(event: Event): void {
    event.preventDefault()
    this.element.nativeElement.value = ''
    this.onSelect.emit()
  }
}
