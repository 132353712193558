import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

/**
 * Modal that displays a progress bar
 * If modal promise is rejected, terminate process
 *
 * @export
 * @class ProgressModalComponent
 */
@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
})
export class ProgressModalComponent {
  @Input() title: string
  @Input() total: number // progress is 100%
  @Input() current: number // current value of progress between 0 and total

  constructor(public modal: NgbActiveModal) {}
}
