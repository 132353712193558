import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { IlluminateService } from 'app/illuminate-v2/illuminate.service'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { combineLatest, of, Subject } from 'rxjs'
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators'

@Component({
  selector: 'app-illuminate-workflow-group',
  templateUrl: './illuminate-workflow-group.page.html',
  styleUrls: ['./illuminate-workflow-group.page.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class IlluminateWorkflowGroupPage implements OnDestroy {
  destroy$ = new Subject<void>()
  triggerRoute$ = new Subject<boolean>()
  workflow$ = new Subject<void>()

  isAnimating: boolean = false

  constructor(
    public illuminate: IlluminateService,
    router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private crumbs: BreadcrumbsService,
  ) {
    combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        tap(() => this.workflow$.next()),
        map(() => this.route.snapshot?.paramMap?.get('workflowGroupId')),
        takeUntil(this.destroy$),
      )
      .subscribe((id) => {
        this.illuminate
          .getWorkflowGroup(id)
          .pipe(
            catchError((e) => {
              this.toast.error(parseGraphQLError(e, 'Could not load workflow group'), JSON.stringify(e))
              return of(null)
            }),
            takeUntil(this.workflow$),
          )
          .subscribe((event) => {
            if (event?.data) {
              this.illuminate.currentWorkflowGroup.next(event?.data?.ilWorkflowGrouping)
              this.crumbs.setPageTitle('Illuminate - ' + event?.data?.ilWorkflowGrouping?.name)
            }
          })

        this.illuminate
          .getTaskImpacts(id)
          .pipe(
            catchError((e) => {
              this.toast.error(parseGraphQLError(e, 'Could not load task impacts'), JSON.stringify(e))
              return of(null)
            }),
            takeUntil(this.workflow$),
          )
          .subscribe((event) => {
            if (event?.data) {
              this.illuminate.currentTaskImpacts.next(event?.data?.ilTaskImpacts)
            }
          })
      })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
    this.workflow$.next()
    this.workflow$.complete()
  }
}
