import { Directive, ElementRef, OnInit } from '@angular/core'
import { AnalyticsService } from '../services/analytics.service'

/**
 * A directive to exclude elements from web analytics session recordings for increased privacy.
 *
 * The `private` attribute is renamed to the value of `TrackService.privateAttribute`.
 *
 * @example <input type="text" private />
 * @example <div private> My sensitive data. </div>
 *
 * @see AnalyticsSrvice.privateAttribute
 * @see https://docs.logrocket.com/reference/dom
 */
@Directive({
  selector: '[private]',
})
export class PrivateDirective implements OnInit {
  constructor(private analytics: AnalyticsService, private el: ElementRef) {}
  ngOnInit(): void {
    if (this.analytics.privateAttribute) this.setAttribute()
  }

  setAttribute(): void {
    if (this.el.nativeElement) this.el.nativeElement.setAttribute(this.analytics.privateAttribute, '')
  }
}
