import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet, Selected } from 'app/shared/types/facet'
import { Subscription } from 'rxjs'
import { filter, tap } from 'rxjs/operators'

/**
 * Component to display a checkbox facet for claim properties
 *
 * @export
 * @class CheckboxFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet

  @ViewChildren('input') inputs: QueryList<ElementRef>

  isCollapsed = false

  form = new UntypedFormGroup({
    checks: new UntypedFormArray([]),
    negate: new UntypedFormControl(false),
  })

  checks$: Subscription
  facets$: Subscription

  constructor(private facetService: FacetedSearchService) {}

  /**
   * Helper to get form fields as FormArray
   *
   * @readonly
   * @type {FormArray}
   * @memberof CheckboxFacetComponent
   */
  get checksFromArray(): UntypedFormArray {
    return this.form.get('checks') as UntypedFormArray
  }

  ngOnInit(): void {
    this.data?.options?.forEach((option: Selected) => {
      let checked = this.data.selected?.find((s) => s.display === option.display) ? true : false
      this.checksFromArray.push(new UntypedFormControl(checked))
    })

    this.checks$ = this.form.valueChanges.subscribe((form) => {
      let negate = form.negate
      form.checks.forEach((check, i) => {
        if (check) {
          let selected = this.data.options[i]
          if (negate) {
            selected.value = `!(${selected.value})`
            selected.display = `NOT ${selected.display}`
          }
          this.facetService.addToFacet(this.data.id, selected)
        } else {
          this.facetService.removeFromFacet(this.data.id, this.data.options[i])
        }
      })
    })

    this.facets$ = this.facetService.facets$
      .pipe(
        filter((facets) => facets.find((f) => f.id === this.data.id) !== undefined),
        tap((facets: Facet[]) => {
          let selected = facets.find((f) => f.id === this.data.id).selected
          this.data.options.forEach((option: Selected, i) => {
            if (!selected.find((s) => s.display === option.display)) {
              this.checksFromArray.controls[i].setValue(false, { emitEvent: false })
            }
          })
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.checks$.unsubscribe()
    this.facets$.unsubscribe()
  }

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.inputs.first?.nativeElement?.focus()
    }
  }

  /**
   * Remove this facet
   *
   * @memberof CheckboxFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
