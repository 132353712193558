// https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters
const ELASTIC_RESERVED_CHARS = /\s|\+|-|=|&&|\|\||!|\(|\)|{|}|\[|\]|\^|"|~|\*|\?|:|\\|\//g
const ELASTIC_RESERVED_CHARS_IGNORE_SPACES_DOUBLEQUOTES = /\+|-|=|&&|\|\||!|\(|\)|{|}|\[|\]|\^|~|\*|\?|:|\\|\//g

/**
 * Helper to escape a string used in an Elasticsearch query. Note: forbidden characters "<" and ">" will not be addressed.
 *
 * @param {string} input
 * @return {*}  {string}
 */
export const escapeElasticQuery = (input: string, preserveSpacesAndDoubleQuotes = false): string => {
  if (!input) return null
  return input.replace(
    preserveSpacesAndDoubleQuotes ? ELASTIC_RESERVED_CHARS_IGNORE_SPACES_DOUBLEQUOTES : ELASTIC_RESERVED_CHARS,
    (match) => `\\${match}`,
  )
}
