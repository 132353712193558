<label class="sr-only" for="planSearch">Search for a plan</label>
<div class="input-group">
  <input
    id="planSearch"
    type="text"
    class="form-control"
    placeholder="Search for a plan"
    container="body"
    [ngbTypeahead]="query"
    [resultFormatter]="resultFormatter"
    [inputFormatter]="inputFormatter"
    [disabled]="disabled"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectPlan($event)"
    #input="ngbTypeahead"
    #element
  />

  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="selectedPlan && !searching"
      type="button"
      (click)="onAddPlan($event)"
      class="btn bg-transparent"
      ngbTooltip="Add Plan"
      container="body"
    >
      <i class="fa fa-plus fa-fw text-brand-dark-gray"></i>
    </button>
    <app-loading *ngIf="searching"></app-loading>
  </span>
</div>
