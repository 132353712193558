<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar h-100">
    <div class="row no-gutters h-100">
      <div class="col">
        <div
          class="d-flex row no-gutters bg-breadcrumb justify-content-between align-items-center rounded-lg mx-1 pt-2 pl-3 pr-1"
        >
          <div class="h5 text-uppercase col"><a routerLink="/admin">Admin</a> / Teams</div>
          <div class="pt-1 col-md-4 col-sm-6">
            <div class="row no-gutters flex-nowrap">
              <fieldset class="col">
                <div class="input-group form-group">
                  <input
                    [formControl]="teamsSearch"
                    (keyup.enter)="searchTeams()"
                    type="text"
                    class="form-control"
                    placeholder="Search Teams"
                  />
                  <div class="input-group-append">
                    <span class="in-input-icon d-flex align-items-center">
                      <button type="button" class="btn bg-transparent" (click)="searchTeams()">
                        <i class="fa fa-search fa-fw"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </fieldset>
              <button
                type="button"
                class="btn btn-link mid-blue mr-1 mb-1"
                ngbTooltip="Create team"
                placement="bottom-right"
                container="body"
                (click)="createTeam()"
              >
                <i class="fa fa-2x fa-plus-square"></i>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="isLoading" class="h-100 d-flex align-items-center justify-content-center">
          <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
        </div>
        <div *ngIf="!isLoading">
          <div class="row no-gutters">
            <div class="col-lg-3 col-sm-6" *ngFor="let teamPod of teamPods">
              <app-team-card [teamPod]="teamPod"></app-team-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
