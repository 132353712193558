import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { debug } from 'app/shared/utils/debug'
import { WorkflowType } from 'generated/graphql'
import { WorkflowService } from './workflow.service'

export const WorkflowTypeResolverService: ResolveFn<WorkflowType> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const workflowService = inject(WorkflowService)
  let workflowTypeId = route.paramMap.get('workflowTypeId')
  debug('workflow', 'resolving workflow', workflowTypeId)
  if (workflowTypeId === 'new') {
    return Promise.resolve({
      tasks: [
        {
          taskType: {
            name: 'Task',
          },
        },
      ],
    } as WorkflowType)
  }
  try {
    let workflowType = workflowService.getWorkflowType(workflowTypeId)
    return workflowType
  } catch (e) {
    debug('workflow', 'Error getting workflow type', e)
  }
}
