import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AnalyticsService } from '../services/analytics.service'
import { AnalyticsInterceptorType } from '../enums/analytics-interceptor-type.enum'

export abstract class AnalyticsBaseInterceptor implements HttpInterceptor {
  analytics: AnalyticsService
  interceptorType: AnalyticsInterceptorType
  urlContains: string[]
  abstract action(req: HttpRequest<any>): void

  constructor(analytics: AnalyticsService, interceptorType: AnalyticsInterceptorType, urlContains: string[]) {
    this.analytics = analytics
    this.interceptorType = interceptorType
    this.urlContains = urlContains
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isUrlIncluded = this.urlContains.some((url) => req.url.toLowerCase().includes(url.toLowerCase()))
    if (isUrlIncluded && this.action) this.action(req)
    return next.handle(req)
  }
}
