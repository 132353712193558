<div class="cumulative-opp-stat">
  <div class="text-center p-5 h-100 bg-brand-gray d-flex flex-column">
    <i *ngIf="icon" class="h1 fa" [class]="icon + ' ' + iconColor"></i>
    <div *ngIf="janusFontIconName" class="h1 janus-font" [class]="iconColor">{{ janusFontIconName }}</div>
    {{ title }}
    <div class="d-flex justify-content-center" [class]="iconColor">
      <sup class="h3 dollar-sign" *ngIf="dollars">$</sup>
      <div class="cumulative-opp-stat-value">{{ formattedAmount.amount | number: '1.0-1' }}</div>
      <div class="cumulative-opp-stat-value" *ngIf="formattedAmount.million">m</div>
      <div class="cumulative-opp-stat-value" *ngIf="formattedAmount.thousand">k</div>
    </div>
  </div>
</div>
