import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GenericModalComponent } from 'app/shared/components/generic-modal/generic-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import {
  ObserveImageLink,
  ListResponseMetaData,
  ObserveSummary,
  ObserveUrlGroup,
  ObserveMissConditions,
  ObserveRecordFields,
  ObserveConfigTypes,
} from 'generated/graphql'
import { BehaviorSubject, Subject } from 'rxjs'
import { first, takeUntil, tap } from 'rxjs/operators'
import { ObserveConfigService, ScreenLabelParams } from '../../service/observe-config.service'

export const ZOOM_DELTA = 1.1
const DEFAULT_ZOOM = 1.0

@Component({
  selector: 'app-add-configurations',
  templateUrl: './add-configurations.component.html',
  styleUrls: ['./add-configurations.component.scss'],
  providers: [NgbActiveModal],
})
export class AddConfigurationsComponent implements OnInit, OnDestroy  {
  private destroyed$ = new Subject<void>()
  @Input()
  selectedUrlGroup: ObserveUrlGroup
  @Input()
  selectedObserveSummary: ObserveSummary
  @Input()
  missReason: ObserveMissConditions
  imagesLoading = false
  observeFieldsLoading = false
  zoomLevel$ = new BehaviorSubject<number>(DEFAULT_ZOOM)
  observeMissConditions = ObserveMissConditions
  typeOfConfgs = ObserveConfigTypes
  imageWidthPercent = 100
  isPanningEnabled = false

  readonly regexRefUrl = 'https://regex101.com/'
  imagesOffset = 0
  imagesMetaData: ListResponseMetaData = {
    offset: 0,
    limit: 1,
    total: 0,
  }
  @ViewChild('confirmCreateConfigModal') confirmCreateConfigModal: TemplateRef<GenericModalComponent>
  dialogConfirm$ = new BehaviorSubject<boolean>(false)
  screenLabelForm = new UntypedFormGroup({
    typeOfConfig: new UntypedFormControl(''),
    metaDataField: new UntypedFormControl(''),
    regexValue: new UntypedFormControl('', Validators.required),
    labelName: new UntypedFormControl('', Validators.required),
  })
  imageLinks: ObserveImageLink[] = []
  imageIndex = 0
  recordFields: ObserveRecordFields
  imageError = false

  constructor(
    public modal: NgbModal,
    private observeConfigService: ObserveConfigService,
    private toast: ToastService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.getImages()

    this.dialogConfirm$
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (confirm) => {
        if (confirm && this.screenLabelForm.valid) {
          const values: ScreenLabelParams = {
            regexValue: this.screenLabelForm.value.regexValue,
            screenLabel: this.screenLabelForm.value.labelName,
            url: this.selectedObserveSummary.applicationURL,
            fieldName: this.screenLabelForm.value.metaDataField,
            // Hardcoded type for now, the only configType the UI currently supports.
            // In the future this value with come from the form i.e. ~ this.screenLabelForm.value.typeOfConfig,
            configType: ObserveConfigTypes.Locate,
          }
          const createdConfig = await this.observeConfigService.createObserveConfig(values)
          if (createdConfig) {
            this.toast.success(
              `New screen label created for: ${createdConfig.screenLabel}, with config id: ${createdConfig.id}`,
            )
          } else {
            this.toast.error(`Error creating screen Label: ${values.screenLabel}`)
          }
        }
      })

    this.zoomLevel$.pipe(takeUntil(this.destroyed$)).subscribe((zoomValue) => {
      this.imageWidthPercent = zoomValue * 100
    })

    if (this.missReason === ObserveMissConditions.UrlMissingScreenName) {
      this.screenLabelForm.patchValue({
        typeOfConfig: ObserveConfigTypes.Locate,
        metaDataField: 'rawUrl',
      })
    } else if (this.missReason === ObserveMissConditions.ClickEventMissingActionName) {
      this.screenLabelForm.patchValue({
        typeOfConfig: ObserveConfigTypes.Identify,
      })
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  async getImages(): Promise<void> {
    this.imagesLoading = true

    this.observeConfigService
      .getImageUrls(this.selectedObserveSummary?.orgId, this.selectedUrlGroup?.groupRawUrl, this.imagesOffset)
      .pipe(
        tap((data) => {
          const imageId = data.data.observeImageUrls[this.imageIndex]?.imageFileId
          this.getObserveRecordFields(imageId)
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.imageLinks = res.data.observeImageUrls
        this.imagesMetaData = {
          ...this.imagesMetaData,
          total: this.imageLinks?.length || 0,
        }
        this.imagesLoading = false
        this.imageError = false
      }, 
      (err) => { 
        this.imageError = true 
        this.imagesLoading = false
      })
  }

  public openConfirmModal(): void {
    this.modal.open(this.confirmCreateConfigModal)
  }

  public onImageUpdate($event: { newPageNumber: number }): void {
    this.imageIndex = $event.newPageNumber - 1
    const imageId = this.imageLinks[this.imageIndex]?.imageFileId
    this.getObserveRecordFields(imageId)
  }

  public async getObserveRecordFields(imageId: string): Promise<void> {
    this.observeFieldsLoading = true
    const rawUrl = this.selectedUrlGroup?.groupRawUrl
    const orgId = this.selectedObserveSummary?.orgId

    const result = await this.observeConfigService.getObserveRecord(rawUrl, orgId, imageId).pipe(first()).toPromise()
    this.recordFields = { ...result.data.observeRecordFields }
    this.observeFieldsLoading = false
  }

  public setZoom(zoomLevel: number): void {
    this.zoomLevel$.next(zoomLevel)
  }

  public zoomIn(): void {
    const current = this.zoomLevel$.value
    this.zoomLevel$.next(current * ZOOM_DELTA)
  }

  public zoomOut(): void {
    this.zoomLevel$.next(this.zoomLevel$.value / ZOOM_DELTA)
  }

  public resetZoom(): void {
    this.zoomLevel$.next(DEFAULT_ZOOM)
  }

  public refreshImages(): void {
    // If there are at least 10 images,
    // Increase offset number, to query for 10 more images,
    // Max image offset = 100
    if (this.imageLinks.length == 10 && this.imagesOffset < 100) {
      this.imagesOffset = this.imagesOffset + 10
      this.getImages()
    } else {
      this.imagesOffset = 0
    }
  }

  public togglePanning(): void {
    this.isPanningEnabled = !this.isPanningEnabled
  }

  public errorLoadingImg(): void {
    this.imageError = true
  }
}
