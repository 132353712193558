import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TeamsService } from 'app/admin/teams/teams.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Team, User } from 'generated/graphql'

/**
 * Component to display a single team in a list of teams
 *
 * @export
 * @class TeamCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
})
export class TeamCardComponent implements OnInit {
  @Input() teamPod: {
    team: Team
    supervisor: User
  }
  @Output() teamRemoved = new EventEmitter<string>()

  constructor(private modal: NgbModal, private toast: ToastService, public teamsService: TeamsService) {}

  ngOnInit(): void {}

  /**
   * Launch modal to confirm deleting this team
   *
   * @memberof TeamCardComponent
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete team?'
    modalRef.componentInstance.body = `Are you sure you want to delete this team?`
    modalRef.componentInstance.yes = 'Delete'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTeam()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete this team
   *
   * @return {*}  {Promise<void>}
   * @memberof TeamCardComponent
   */
  async deleteTeam(): Promise<void> {
    try {
      await this.teamsService.deleteTeam(this.teamPod.team.id)
      this.teamRemoved.emit(this.teamPod.team.id)
      this.toast.success('Team successfully deleted')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete team'), JSON.stringify(e))
    }
  }
}
