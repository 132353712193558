<div *ngIf="organization">
  <div class="flex-row p-2 banner">
    <h5 class="text-white">Manage Dashboard Settings For Organization: {{ organization?.name }}</h5>
  </div>

  <div *ngFor="let setting of quickSightDashBoardSettings" class="container border col">
    <div class="flex-row p-2">
      <app-organization-management [settingType]="setting" [orgId]="organization?.id"></app-organization-management>
    </div>
  </div>
</div>
