import { Component, OnInit } from '@angular/core'

/**
 * Page to display various reports and statistics
 *
 * @export
 * @class ReportingPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting.page.html',
  styleUrls: ['./reporting.page.scss'],
  host: {
    class: 'h-100',
  },
})
export class ReportingPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
