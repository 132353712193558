import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { TaskStatsService } from 'app/reporting/task-stats.service'
import { ChartDataset, ChartOptions, ChartType } from 'chart.js'
import { TaskStats, User } from 'generated/graphql'
import { sortBy } from 'lodash'
import * as moment from 'moment'
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs'
import { startWith } from 'rxjs/operators'

const FORMAT = 'MM/DD/YYYY'

/**
 * Component to allow querying and displaying of task stats
 * based on user, time frame, and action
 *
 * @export
 * @class EmployeeProductivityComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-employee-productivity',
  templateUrl: './employee-productivity.component.html',
  styleUrls: ['./employee-productivity.component.scss'],
})
export class EmployeeProductivityComponent implements OnInit, OnDestroy {
  tasksMetricForm = new UntypedFormGroup({
    model: new UntypedFormControl('completed'),
  })
  selectedUsers: User[] = []

  selectedUserIds = new BehaviorSubject([])
  dateRange$ = new BehaviorSubject({ from: null, to: null })
  search$: Subscription

  data: ChartDataset[] = []
  //labels: Label[] = []
  chartOptions: ChartOptions<'bar'>;
  chartType: ChartType = 'bar'
  chartLegend = false

  constructor(private statService: TaskStatsService) {}

  ngOnInit(): void {
    this.search$ = combineLatest([
      this.dateRange$,
      this.tasksMetricForm.valueChanges.pipe(startWith(this.tasksMetricForm.value)),
      this.selectedUserIds,
    ]).subscribe(async ([dateRange, taskStatus, userIds]) => {
      // First, filter out incomplete pops ( need all 3 observable values to search )
      if (!dateRange.to || !dateRange.from) {
        this.data = []
        this.chartLegend = false
        return
      }
      if (!userIds.length) {
        this.data = []
        this.chartLegend = false
        return
      }
      if (!taskStatus.model) {
        this.data = []
        this.chartLegend = false
        return
      }

      // Rely on Apollo to handle duplicate calls
      let queries = []
      userIds.forEach((user) => {
        queries.push({ userId: user, startDate: dateRange.from, endDate: dateRange.to })
      })

      let results = await Promise.allSettled(
        queries.map((q) => this.statService.getTaskStats(q.startDate, q.endDate, q.userId)),
      )
      const pValue = (promise) => (promise as PromiseFulfilledResult<any>)?.value
      let r = results.map((r) => pValue(r))

      this.chartOptions = {
        responsive: true,
        scales: {
          x: {
              type: 'time',
              time: {
                minUnit: 'day',
              },
              min: moment(dateRange.from).subtract(1, 'd').toLocaleString(),
              max: moment(dateRange.to).add(1, 'd').toLocaleString(),
            },
          y: {
              title: {
                display: true,
                text: `Tasks ${taskStatus.model}`,
              },
              type: 'linear',
              min: 0,
              ticks: {
                stepSize: 1
              },
            }
        },
      }
      this.data = []

      r.forEach((taskStats: TaskStats[]) => {
        if (taskStats?.length) {
          let userObj = this.selectedUsers.find((u) => u.id === taskStats[0]?.userId)
          let user = userObj?.name || userObj?.email

          let stats = sortBy(taskStats, (a, b) => moment.utc(a.day, FORMAT))
          let data = stats.map((ts) => ({ x: moment(ts.day, FORMAT), y: ts[taskStatus.model] || 0 } as any))

          this.data.push({
            label: `${user} - ${taskStatus.model}`,
            data,
            borderColor: 'black',
            backgroundColor: 'rgba(255,0,0,0.3)'
          })
        }
      })

      this.chartLegend = true
    })
  }

  ngOnDestroy(): void {
    this.search$.unsubscribe()
  }

  /**
   * Add selected user to list of users
   *
   * @param {User} user
   * @memberof EmployeeProductivityComponent
   */
  addUser(user: User): void {
    if (user?.id) {
      this.selectedUsers.push(user)
      this.selectedUserIds.next(this.selectedUsers.map((u) => u.id))
    }
  }

  /**
   * Remove selected user from list of users
   *
   * @param {User} user
   * @memberof EmployeeProductivityComponent
   */
  removeUser(user: User): void {
    let i = this.selectedUsers.findIndex((u) => u.id === user.id)
    if (i >= 0) {
      this.selectedUsers.splice(i, 1)
      this.selectedUserIds.next(this.selectedUsers.map((u) => u.id))
    }
  }

  /**
   * Indicate that a date range has been selected
   *
   * @param {{ from: string; to: string }} event
   * @memberof EmployeeProductivityComponent
   */
  rangeSelected(event: { from: string; to: string }): void {
    this.dateRange$.next(event)
  }
}
