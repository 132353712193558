import { Component, OnDestroy, OnInit } from '@angular/core'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { FacetType, Selected } from 'app/shared/types/facet'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export interface Pill {
  facet: string
  title: string
  selected: Selected
}

/**
 * Component to display all values of all selected facets as pills
 *
 * @export
 * @class SelectedFacetsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-selected-facets',
  templateUrl: './selected-facets.component.html',
  styleUrls: ['./selected-facets.component.scss'],
})
export class SelectedFacetsComponent implements OnInit, OnDestroy {
  pills: Pill[] = []

  destroy$ = new Subject<void>()

  constructor(public facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.facetService.facets$.pipe(takeUntil(this.destroy$)).subscribe((facets) => {
      let pills = []
      facets.forEach((facet) => {
        if (facet.type === FacetType.PREDEFINED) {
          pills.push({ facet: facet.id, title: facet.title, selected: { value: '', display: '' } })
        } else if (facet.selected?.length === 1 && facet.selected[0].displayPill) {
          pills.push({ facet: facet.id, title: facet.selected[0].displayPill, selected: { value: '', display: '' } })
        } else {
          facet?.selected?.forEach((s) => {
            pills.push({ facet: facet.id, title: facet.title, selected: s })
          })
        }
      })
      this.pills = pills
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  /**
   * Remove a value from a facet and the associated pill
   *
   * @param {Pill} pill
   * @memberof SelectedFacetsComponent
   */
  removePill(pill: Pill): void {
    this.facetService.removeFromFacet(pill?.facet, pill?.selected)
  }

  /**
   * Helper for performant ngFor loop
   *
   * @param {*} index
   * @param {Pill} item
   * @return {*} {string}
   * @memberof SelectedFacetsComponent
   */
  trackById(index: number, item: Pill): string {
    return item.facet
  }
}
