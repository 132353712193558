<div class="navbar navbar-light bg-alt-light-gray-low p-2 mb-3 d-flex justify-content-between align-items-center">
  <div>
    <span ngbDropdown>
      <button
        class="btn btn-sm btn-link text-uppercase"
        ngbDropdownToggle
        ngbTooltip="Change displayed path"
        container="body"
        [disabled]="isSelectingPath"
      >
        {{ selectedPath?.name }} Path
      </button>
      <div ngbDropdownMenu>
        <h6 class="dropdown-header text-primary">Select path to display</h6>
        <button
          ngbDropdownItem
          [ngClass]="{ active: !selectedPath?.name }"
          (click)="setPath({ name: 'Baseline', id: null })"
        >
          Baseline
        </button>
        <div class="dropdown-divider"></div>
        <button
          ngbDropdownItem
          [ngClass]="{ active: selectedPath?.name === 'Ideal' }"
          (click)="setPath({ name: 'Ideal', id: null })"
        >
          Ideal
        </button>
        <button
          ngbDropdownItem
          [ngClass]="{ active: selectedPath?.name === 'Non-ideal' }"
          (click)="setPath({ name: 'Non-Ideal', id: null })"
        >
          Non-ideal
        </button>
        <div class="dropdown-divider"></div>
        <button
          *ngFor="let path of selectedPaths"
          ngbDropdownItem
          [ngClass]="{ active: selectedPath?.name === path?.name }"
          class="d-flex justify-content-between align-items-center"
          (click)="setPath({ name: path.name, id: path.pathId })"
        >
          {{ path?.name }}
          <button class="btn pr-0" (click)="confirmDeletePath(path.id)">
            <i class="fa fa-close fa-fw"></i>
            <span class="sr-only">Delete selected path</span>
          </button>
        </button>
        <button ngbDropdownItem (click)="selectPathMode(true)">
          <i class="fa fa-plus fa-fw mr-3"></i>
          Create New Path
        </button>
      </div>
    </span>
    <span ngbDropdown>
      <button
        class="btn btn-sm btn-link text-uppercase"
        ngbDropdownToggle
        ngbTooltip="Filter path by User"
        container="body"
        [disabled]="isSelectingPath"
      >
        <i class="fa fa-user mr-2"></i>
        {{ selectedUsersLabel }} Users
      </button>
      <div ngbDropdownMenu class="p-3">
        <h6 class="dropdown-header text-primary">Filter path by User</h6>
        <app-user-search
          (onSelect)="addUser($event)"
          [container]="true"
          [allowedUserIds]="allowedUserIds"
          [disabledUserIds]="selectedUserIds"
        ></app-user-search>
        <div class="dropdown-divider"></div>
        <ul class="list-unstyled my-4 height-restrict">
          <li *ngFor="let user of selectedUsers" class="d-flex align-items-center justify-content-between">
            {{ user?.name }}
            <button class="btn btn-sm" (click)="removeUser(user)">
              <i class="fa fa-fw fa-times-circle-o text-danger"></i>
            </button>
          </li>
        </ul>
      </div>
    </span>
    <span ngbDropdown>
      <button
        class="btn btn-sm btn-link text-uppercase"
        ngbDropdownToggle
        ngbTooltip="Filter path by Dates"
        container="body"
        [disabled]="isSelectingPath"
      >
        <i class="fa fa-calendar mr-2"></i>
        {{ selectedDatesLabel }}
      </button>
      <div ngbDropdownMenu class="p-3">
        <h6 class="dropdown-header text-primary">Filter path by Dates</h6>
        <app-datepicker-range [selectedDates]="selectedDates" (rangeSelected)="setDates($event)"></app-datepicker-range>
      </div>
    </span>
    <button
      class="btn btn-sm btn-link text-uppercase"
      ngbTooltip="Reset Filters"
      container="body"
      (click)="resetFilters()"
      [disabled]="isSelectingPath"
    >
      <i class="fa fa-refresh mr-2"></i>
      Reset Filters
    </button>
    <button
      *ngIf="isSelectingPath"
      class="btn btn-sm btn-primary ml-3"
      ngbTooltip="Save path"
      container="body"
      (click)="saveSelectedPath(savePathModal)"
      [disabled]="!selectedPath?.id?.length"
    >
      Save Path
    </button>
    <button
      *ngIf="isSelectingPath"
      class="btn btn-sm btn-primary ml-3"
      ngbTooltip="Save path"
      container="body"
      (click)="selectPathMode(false)"
    >
      Cancel Path
    </button>
  </div>
  <button class="btn btn-sm" (click)="onPanelOpen.emit()" [disabled]="isSelectingPath">
    <i class="fa fa-plus fa-fw"></i>
    <span class="sr-only">View Statistics</span>
  </button>
</div>

<ng-template #savePathModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Save Selected Path</h4>
  </div>
  <form [formGroup]="form" (ngSubmit)="modal.close(form.get('name')?.value)">
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="name">Path Name</label>
            <input type="text" formControlName="name" class="form-control" [appFcBsValidation]="form.get('name')" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-cancel"
        (click)="modal.dismiss('Close click')"
        data-t="save-path-cancel-button"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-brand-green px-5">Save</button>
    </div>
  </form>
</ng-template>
