import { Injectable, OnDestroy } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { GetWorkflow } from 'app/admin/workflows/workflows.gql'
import { GetTaskCommentsQuery } from 'app/pathfinder/tasks/task-comments.gql'
import { GetTaskQuery } from 'app/pathfinder/tasks/task-queries.gql'
import { TaskSub } from 'app/pathfinder/tasks/task-sub.gql'
import { debug } from 'app/shared/utils/debug'
import {
  QueryTaskArgs,
  QueryTaskCommentsArgs,
  QueryWorkflowArgs,
  SubscriptionNotification,
  Task,
  TaskComment,
  Workflow,
} from 'generated/graphql'
import { Subject } from 'rxjs'
import { delay, takeUntil } from 'rxjs/operators'

export enum TaskTopics {
  taskUpdated = 'taskUpdated',
  taskCreated = 'taskCreated',
  taskPicked = 'taskPicked',
  taskCompleted = 'taskCompleted',
  taskDeleted = 'taskDeleted',
  taskUncompleted = 'taskUncompleted',
  taskCommented = 'taskCommented',
}

@Injectable({
  providedIn: 'root',
})
export class TaskSubscriptionService implements OnDestroy {
  destroy$ = new Subject<void>()

  constructor(private apollo: Apollo) {}

  subscribe(): void {
    this.apollo
      .subscribe<{ taskChanges: SubscriptionNotification }>({
        query: TaskSub,
      })
      .pipe(delay(500), takeUntil(this.destroy$))
      .subscribe(
        async (event) => {
          debug('subscriptions', 'taskChanges', event)
          let payload = event?.data?.taskChanges
          let taskId = payload?.entityId
          let workflowId = payload?.relatedEntities?.[0]?.entityId

          try {
            switch (payload?.type) {
              case TaskTopics.taskCommented: {
                await this.apollo
                  .query<{ taskComments: TaskComment[] }, QueryTaskCommentsArgs>({
                    query: GetTaskCommentsQuery,
                    variables: {
                      taskId: taskId,
                    },
                    fetchPolicy: 'network-only',
                  })
                  .toPromise()
                break
              }
              case TaskTopics.taskDeleted: {
                let cache = this.apollo.client.cache
                cache.evict({ id: taskId })
                if (workflowId) {
                  cache.modify({
                    id: cache.identify({ id: workflowId, __typename: 'Workflow' }),
                    fields: {
                      tasks(existing, { readField }) {
                        return existing.filter((task) => readField('id', task) !== taskId)
                      },
                    },
                  })
                }
                cache.gc()
                break
              }
              default: {
                if (workflowId) {
                  await this.apollo
                    .query<{ workflow: Workflow }, QueryWorkflowArgs>({
                      query: GetWorkflow,
                      variables: {
                        id: workflowId,
                      },
                      fetchPolicy: 'network-only',
                    })
                    .toPromise()
                } else {
                  await this.apollo
                    .query<{ task: Task }, QueryTaskArgs>({
                      query: GetTaskQuery,
                      variables: {
                        id: taskId,
                      },
                      fetchPolicy: 'network-only',
                    })
                    .toPromise()
                }
              }
            }
          } catch (e) {
            debug('subscriptions', 'task websocket went sideways', JSON.stringify(payload), JSON.stringify(e))
          }
        },
        (error) => {
          debug('subscriptions', 'task sub got error')
        },
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
