import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import { escapeElasticQuery } from 'app/shared/utils/escapeElasticQuery'
import { from, merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators'

/**
 * Component to display a typeahead facet for claim properties
 *
 * @export
 * @class TypeaheadFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet

  @ViewChild('input') input: ElementRef
  @ViewChild('instance', { static: true }) instance: NgbTypeahead

  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  negate = new UntypedFormControl(false)

  exact = new UntypedFormControl(false)

  isCollapsed = false

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.input.nativeElement.focus()
      this.input.nativeElement.click()
    }
  }

  /**
   * Get possible values based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @return {*} {Observable<string[]>}
   * @memberof TypeaheadFacetComponent
   */
  search = (text$: Observable<string>): Observable<string[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) =>
        from(this.data.search(escapeElasticQuery(term), !this.exact.value) as Observable<any[]>).pipe(
          catchError(() => {
            return of([])
          }),
        ),
      ),
    )
  }

  /**
   * Format typeahead input and results list
   *
   * @param {string} x
   * @return {*} {string}
   * @memberof TypeaheadFacetComponent
   */
  defaultFormatter = (x: string): string => x

  /**
   * Add value to facet when possible value is selected
   *
   * @param {*} event
   * @param {*} input
   * @memberof TypeaheadFacetComponent
   */
  onSelect(event: NgbTypeaheadSelectItemEvent, input: { value: string }): void {
    event.preventDefault()

    let value = this.data?.valueProp ? event.item[this.data.valueProp] : event.item
    let display = this.data?.displayProp ? event.item[this.data.displayProp] : event.item
    if (!this.exact.value) {
      value = `*${escapeElasticQuery(value)}*`
      display = `*${display}*`
    }
    if (this.negate.value) {
      value = `!(${value})`
      display = `NOT ${display}`
    }
    this.facetService.addToFacet(this.data.id, {
      value: value,
      display: display,
    })

    input.value = ''
  }

  /**
   * Remove this facet
   *
   * @memberof TypeaheadFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
