<label class="sr-only" for="tagSearch">Search for a tag</label>
<div class="input-group">
  <input
    id="tagSearch"
    type="text"
    class="form-control"
    placeholder="Search for a tag"
    [ngbTypeahead]="search"
    [resultFormatter]="resultFormatter"
    [inputFormatter]="inputFormatter"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="onSelectTag($event)"
    #input="ngbTypeahead"
    #element
  />
  <span class="in-input-icon d-flex align-items-center">
    <button
      *ngIf="element.value.length && !searching"
      type="button"
      (click)="clearTag($event)"
      class="btn bg-transparent"
    >
      <i class="fa fa-times fa-fw"></i>
    </button>
    <app-loading *ngIf="searching"></app-loading>
  </span>
</div>
