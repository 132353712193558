<div class="card detail-card-new my-4 bg-light nostyle" [attr.id]="task?.id">
  <a
    *ngIf="!isSelected"
    class="card-header d-flex align-items-center"
    [ngClass]="{ 'border-bottom rounded-bottom-lg': !isSelected }"
    [routerLink]="['/pathfinder', task?.taskGroupId || 'my', task?.id]"
    [queryParams]="{ type: task?.__typename === 'Task' ? 'task' : 'workflow' }"
    queryParamsHandling="merge"
    data-t="app-task-card"
  >
    <ng-container *ngTemplateOutlet="header"></ng-container>
  </a>
  <a
    *ngIf="isSelected"
    class="card-header d-flex align-items-center"
    [ngClass]="{ 'border-bottom rounded-bottom-lg': !isSelected }"
    [routerLink]="['/pathfinder', task.taskGroupId || 'my']"
  >
    <ng-container *ngTemplateOutlet="header"></ng-container>
  </a>

  <div class="card-body" [ngbCollapse]="!isSelected" data-t="task-card-body">
    <button
      class="btn btn-sm btn-primary float-sm-right d-md-inline-block d-lg-none ml-2"
      (click)="isSidebarCollapsedChange.emit(false)"
    >
      More Details
    </button>
    <app-claim-key-details [claim]="claim"></app-claim-key-details>

    <ng-template [ngIf]="task?.__typename === 'Task'" [ngIfElse]="newWorkflowUI">
      <h5 class="mb-3">Task Instructions</h5>
      <div class="card bg-alt-dark-blue-light mb-1">
        <div class="card-body">
          <div class="d-flex w-100">
            <p [innerHTML]="asTask(task)?.taskType?.description"></p>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #newWorkflowUI>
      <div class="row" *ngIf="isSelected && asWorkflow(task)?.tasks?.length > 0">
        <div class="col-sm-3 d-flex flex-column align-items-center">
          <div class="w-75 my-3">
            <canvas
              baseChart
              #baseChart="base-chart"
              [datasets]="workflowProgress"
              [labels]="null"
              [options]="chartOptions"
              [legend]="false"
              [plugins]="chartPlugins"
            >
            </canvas>
          </div>
          <div class="w-100 bg-alt-medium-gray-low p-3 rounded position-relative">
            <h6 class="text-secondary">History</h6>
            <span class="position-absolute mt-3 mr-3 right-icon see-thru d-flex">
              <i class="fa fa-fw fa-clock-o text-muted"></i>
            </span>
            <ul class="list-unstyled" *ngIf="asWorkflow(task)?.tasks?.length >= 0">
              <li *ngFor="let wt of asWorkflow(task)?.tasks" class="text-muted text-small my-2">
                {{
                  wt?.completionType !== null &&
                  asWorkflow(task).workflowType?.tasks[wt?.workflowTypeTaskIndex]?.outcomes?.length
                    ? asWorkflow(task).workflowType?.tasks[wt?.workflowTypeTaskIndex]?.outcomes[
                        wt?.workflowTypeTaskOutcomeIndex
                      ]?.name
                    : ''
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col">
          <app-workflow-tasks
            [workflow]="asWorkflow(task)"
            [comments]="comments"
            [isSelected]="isSelected"
            (completeTask)="onWorkflowTaskComplete($event)"
            (navigate)="onWorkflowNavigate($event)"
            (reject)="onWorkflowReject($event)"
          ></app-workflow-tasks>
        </div>
      </div>
    </ng-template>

    <app-claim-generate-note [claim]="claim"></app-claim-generate-note>

    <app-task-comments
      *ngIf="task?.__typename === 'Task'"
      [comments]="comments"
      [taskId]="task?.id"
      [loading]="loadingComments"
      class="d-block mt-5"
    ></app-task-comments>

    <app-task-actions
      *ngIf="task?.__typename === 'Task'"
      [task]="asTask(task)"
      (taskEvent)="taskResolved.emit()"
    ></app-task-actions>
  </div>
</div>

<ng-template #header>
  <app-task-status-icon [completed]="completed" [rejected]="rejected"></app-task-status-icon>
  <h5
    [ngClass]="{
      'text-success': completed,
      'text-danger': rejected,
      'text-dark': !completed && !rejected
    }"
    class="flex-grow-1 d-flex flex-column align-self-start"
  >
    <span>
      <i class="fa fa-fw fa-cogs circle" *ngIf="task?.__typename === 'Task' && asTask(task)?.taskType?.isBotTask"></i>
      {{ task?.__typename === 'Workflow' ? asWorkflow(task)?.workflowType?.name : asTask(task)?.taskType?.name }}
    </span>
    <small class="d-flex w-100" [ngClass]="{ 'text-dark': !completed && !rejected }">
      <span class="mr-3" *ngIf="completed || rejected">
        <i class="fa fa-fw fa-user"></i>
        {{
          task?.__typename === 'Task'
            ? asTask(task)?.completedBy
            : asWorkflow(task)?.completedBy || asWorkflow(task)?.tasks[asWorkflow(task)?.tasks?.length - 1]?.completedBy
        }}
      </span>
      <span *ngIf="completed || rejected">
        <i class="fa fa-fw fa-clock-o"></i>
        <ng-template [ngIf]="task?.__typename === 'Task'" [ngIfElse]="workflowCompletedBy">
          {{ asTask(task)?.completedDate ? (asTask(task).completedDate | date: 'MM/dd/yyyy') : '' }}
        </ng-template>
        <ng-template #workflowCompletedBy>
          {{ asWorkflow(task)?.completedDate ? (asWorkflow(task)?.completedDate | date: 'MM/dd/yyyy') : '' }}
        </ng-template>
      </span>

      <span
        [ngClass]="{
          invisible: completed || rejected || !outstandingAmount
        }"
      >
        <strong>
          {{ outstandingAmount | currency }}
        </strong>
        can be recovered
      </span>
    </small>
  </h5>
  <div class="d-flex flex-column align-items-end">
    <ng-template [ngIf]="task?.__typename === 'Task'" [ngIfElse]="workflowLinkAndPicked">
      <a [routerLink]="['/claims', asTask(task)?.providerClaimId]">{{ asTask(task)?.providerClaimId }}</a>
      <small class="text-dark">{{ isSelected ? claim?.patientName : asTask(task)?.claim?.patientName }} </small>
      <app-task-picked-by
        [userIds]="activeUserIds"
        [ngClass]="{
          invisible: !activeUserIds?.length
        }"
      ></app-task-picked-by>
    </ng-template>
    <ng-template #workflowLinkAndPicked>
      <a
        [routerLink]="['/claims', asWorkflow(task)?.claim?.providerClaimId]"
        [queryParams]="{ billing_module: asWorkflow(task)?.claim?.billingModule }"
      >
        {{ asWorkflow(task)?.claim?.providerClaimId }}
      </a>
      <small class="text-dark">{{ asWorkflow(task)?.claim?.patientName || '' }}</small>
      <app-task-picked-by
        [userIds]="activeUserIds"
        [ngClass]="{
          invisible: !activeUserIds?.length
        }"
      ></app-task-picked-by>
    </ng-template>
  </div>
</ng-template>
