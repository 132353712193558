<div class="container-fluid">

  <div *ngIf="isLoading" class="p-4 row bg-alt-lighter-gray text-center">
    <div class="col align-center">
      <p class="text-center text-alt-dark-blue">
        Loading Filter Options ....
        <app-loading
          innerClass="fa-2x"
          outerClass=" text-brand-light-blue"
          class="justify-content-center"
        ></app-loading>
      </p>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <div class="px-4 pt-4 row bg-alt-lighter-gray" [formGroup]="filterFormControls">
      <ng-template #filterLabel let-item="item">
        <span>{{ item.data.name }}</span>
      </ng-template>
      <div class="col-2">
        <label for="functionalAreaFilter">FunctionalAreas</label>
          <app-multiselect-typeahead
          #functionalAreaFilter
          [filterLabel]="filterLabel"
          [fetchOnSearch]="getSearchTypeFunction(ObserveUserFilterTypes.FunctionalAreas)"
          placeholderText="{{filterFormControls.controls.selectedFunctionalAreaIds.value.length ? 
            'Functional Areas Selected ' + filterFormControls.controls.selectedRoleIds.value.length : 'Functional Areas' }}"
          [selectedIds]="filterFormControls.controls.selectedFunctionalAreaIds.value"
          (onSelect)="filterSelection($event, ObserveUserFilterTypes.FunctionalAreas)"
          ></app-multiselect-typeahead>
      </div>

      <div class="col-2">
        <label for="functionalTeamFilter">Teams</label>
          <app-multiselect-typeahead
          #functionalTeamFilter
          [filterLabel]="filterLabel"
          [fetchOnSearch]="getSearchTypeFunction(ObserveUserFilterTypes.Teams)"
          placeholderText="{{filterFormControls.controls.selectedTeamIds.value.length ? 
            'Teams Selected ' + filterFormControls.controls.selectedTeamIds.value.length : 'Teams' }}"
          [selectedIds]="filterFormControls.controls.selectedTeamIds.value"
          (onSelect)="filterSelection($event, ObserveUserFilterTypes.Teams)"
          ></app-multiselect-typeahead>
      </div>
      
      <div class="col-2">
        <label for="roleFilter">Roles</label>
          <app-multiselect-typeahead
          #roleFilter
          [filterLabel]="filterLabel"
          [fetchOnSearch]="getSearchTypeFunction(ObserveUserFilterTypes.Roles)"
          placeholderText="{{filterFormControls.controls.selectedRoleIds.value.length ? 
            'Roles Selected ' + filterFormControls.controls.selectedRoleIds.value.length : 'Roles' }}"
          [selectedIds]="filterFormControls.controls.selectedRoleIds.value"
          (onSelect)="filterSelection($event, ObserveUserFilterTypes.Roles)"
          ></app-multiselect-typeahead>
      </div>
    
      <div class="col-2">
        <label for="managerFilter">Managers</label>
          <app-multiselect-typeahead
          #managerFilter
          [filterLabel]="filterLabel"
          [fetchOnSearch]="getSearchTypeFunction(ObserveUserFilterTypes.Manager)"
          placeholderText="{{filterFormControls.controls.selectedManagerIds.value.length ? 
            'Managers Selected' + filterFormControls.controls.selectedManagerIds.value.length : 'Manager' }}"
          [selectedIds]="filterFormControls.controls.selectedManagerIds.value"
          (onSelect)="filterSelection($event, ObserveUserFilterTypes.Manager)"
          ></app-multiselect-typeahead>
      </div>

      <div class="col-2">
        <label for="supervisorFilter">Supervisor</label>
          <app-multiselect-typeahead
          #supervisorFilter
          [filterLabel]="filterLabel"
          [fetchOnSearch]="getSearchTypeFunction(ObserveUserFilterTypes.Supervisor)"
          placeholderText="{{filterFormControls.controls.selectedSupervisorIds.value.length ? 
            'Supervisors Selected ' + filterFormControls.controls.selectedSupervisorIds.value.length : 'Supervisor' }}"
          [selectedIds]="filterFormControls.controls.selectedSupervisorIds.value"
          (onSelect)="filterSelection($event, ObserveUserFilterTypes.Supervisor)"
          ></app-multiselect-typeahead>
      </div>

      <div class="col-2 mt-4 pl-4">
        <button type="button" class="btn btn-primary m-4" (click)="resetFilters()">Reset Filters</button>
      </div>
    </div>
    <div class="px-4 row bg-alt-lighter-gray">
      <label class="col-2">
        <input #showDeactivatedUsers class="mr-2" type="checkbox" [formControl]="includeDeactivatedUsers" />
        Show Deactivated
      </label>
    </div>
    <div class="px-4 pb-4 row bg-alt-lighter-gray">
      <label class="col-2">
        <input #showDeletedUsers class="mr-2" type="checkbox" [formControl]="includeDeletedUsers"/>
        Show Deleted
      </label>
    </div>
  </div>

  <div class="p-4 row mx-2">
    <div class="col-2">
      <div class="input-group form-group">
        <input
          type="text"
          class="form-control rounded-lg"
          placeholder="Search For ..."
          [formControl]="userSearchText"
        />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <button type="button" class="btn bg-transparent border-left">
              <i class="fa fa-search fa-fw text-brand-dark-gray"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-3 pt-2">
      <a routerLink="/admin/observe/users/activityLog">Activity Log</a>
    </div>
    <div class="col-2 ml-auto">
      <select
        *ngIf="!fileProcessing"
        class="form-control shadow-sm custom-select"
        id="documentTypeSelection"
        [formControl]="documentTypeSelection"
      >
        <option value="null" disabled class="text-alt-medium-gray">Actions</option>
        <option value="template" class="text-alt-medium-gray">Download Template</option>
        <option value="import" class="text-alt-medium-gray">Import Users</option>
        <option value="export" class="text-alt-medium-gray" [disabled]="!observeUsersList?.length">Export Users</option>
      </select>

       <!-- Input type element used for file uploads, not displayed in UI -->
       <input #fileInput hidden type="file" (change)="uploadUsersEvent($event)" accept=".csv"/> 

      <ng-container *ngIf="fileProcessing">
        <p class="text-center text-alt-dark-blue">
          {{ documentTypeSelection.value === 'export' ? 'Creating' : 'Uploading' }} File....
          <app-loading
            innerClass="fa-2x"
            outerClass=" text-brand-light-blue"
            class="justify-content-center"
          ></app-loading>
        </p>
      </ng-container>
    </div>
    <div class="col-3">
      <app-pagination
        *ngIf="!isLoading && observeUsersList.length"
        [metaData]="userlistMetaData"
        (fetchPage)="fetchPage($event)"
      >
      </app-pagination>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col m-1 overflow-auto">
      <app-loading
        *ngIf="isLoading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center py-4 my-4"
      ></app-loading>

      <table class="table table-striped" data-t="observe-users-list-table" *ngIf="!isLoading">
        <thead>
          <tr>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.fullName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.fullName }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.userName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.userName }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.functionalArea"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.functionalArea }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.teamName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.teamName }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.role"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.role }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.manager"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.manager }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.supervisor"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.supervisor }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.director"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.director }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray">
              {{ COLUMN_NAMES.groups }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.epicUserName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.epicUserName }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.resqUserName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.resqUserName }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.enforcerUserName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.enforcerUserName }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.athenaIdxUserName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.athenaIdxUserName }}
            </th>
            <th scope="col" class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_NAMES.status"
              (sort)="onSort($event)"
            >
              {{ COLUMN_NAMES.status }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of displayRows" data-t="observe-user-list-table-row" (click)="onClickEvent(row)">
            <td class="text-left">{{ row.name }}</td>
            <td class="text-left">{{ row.userName }}</td>
            <td class="text-left">{{ row.team.functionalArea.name }}</td>
            <td class="text-left">{{ row.team.name }}</td>
            <td class="text-left">{{ row.role.name }}</td>
            <td class="text-left">{{ row.manager.name }}</td>
            <td class="text-left">{{ row.supervisor.name }}</td>
            <td class="text-left">{{ row.director.name }}</td>
            <td class="text-left">{{ row.groupNames.join(',') }}</td>
            <td class="text-left">{{ row.epicUserName }}</td>
            <td class="text-left">{{ row.resqUserName }}</td>
            <td class="text-left">{{ row.enforcerUserName }}</td>
            <td class="text-left">{{ row.athenaIdxUsername }}</td>
            <td class="text-left">{{ getUserStatus(row) }}</td>
          </tr>
        </tbody>
      </table>

      <div
        *ngIf="!observeUsersList?.length && !isLoading"
        class="d-flex justify-content-center alert alert-danger"
        role="alert"
      >
        No Observe Users Found For Organization
      </div>

      <div
      *ngIf="!isLoading && !displayRows.length && isFilterPresent()"
      class="d-flex justify-content-center alert alert-danger"
      role="alert"
    >
        No matching users found
    </div>
  </div>

    <div class="row">
      <div class="col float-right">
        <app-pagination
          *ngIf="!isLoading && observeUsersList.length"
          [metaData]="userlistMetaData"
          (fetchPage)="fetchPage($event)"
        ></app-pagination>
      </div>
    </div>
  </div>

  <ng-template #uploadErrorModal let-modal>
    <div class="modal-header p-4">
      <h5 class="modal-title justify-content-center">Import Error</h5>
    </div>
    <div class="modal-body text-start mx-5 p-4">
      <p>
        File is not formatted correctly and cannot be fully imported.
        <br>
        Update your file and try uploading again.
       </p>
       <ul>Errors:
         <li *ngFor="let error of errorMessages"> {{ error }} </li>
       </ul>
    </div>
    <div class="modal-footer justify-content-start">
      <button type="button" class="btn btn-primary" aria-label="Close" (click)="closeModal()">
        Close
      </button>
    </div>
  </ng-template>


  <ng-template #editUserModal let-modal>
    <app-observe-user-edit-modal
      [observeUser]="selectedUser"
      [orgId]="orgId"
      [updatedById]="updatedById"
      (observeUserSave)="saveEvent($event)">
    </app-observe-user-edit-modal>
  </ng-template>
</div>
