<div class="d-flex">
  <div class="flex-grow-1">
    <app-search-field
      [label]="'Search Saved Searches'"
      [search]="searchSearch"
      [searching]="searching"
    ></app-search-field>
  </div>
</div>
<small class="d-block text-center" *ngIf="searches?.length">
  Showing {{ searches.length }} of about {{ total }} Saved Searches
</small>
<div class="card detail-card my-4 border-0" *ngFor="let search of searches; let i = index">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed[i]"
    (click)="isCollapsed[i] = !isCollapsed[i]"
    [attr.data-t]="'saved-search-' + search.name"
  >
    <span class="flex-grow-1">{{ search.name }}</span>
    <span class="flex-shrink-0 pr-3">
      <button
        type="button"
        class="btn btn-sm btn-link"
        (click)="selectSearch.emit(search)"
        placement="bottom"
        ngbTooltip="Edit Saved Search"
        container="body"
        data-t="edit-saved-search-button"
      >
        <i class="fa fa-lg fa-pencil text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed[i]">
    <span [innerHTML]="search?.description"></span>
  </div>
</div>
