<div class="bg-alt-lighter-gray container-fluid h-100 overflow-hidden">
  <ng-container class="h-100">
    <div class="row py-4 mx-1">
      <div ngbDropdown class="col-lg-2 text-center">
          <button type="button" class="btn bg-alt-white text-brand-dark-gray" id="missReasonDropdown" ngbDropdownToggle>
            <span>{{ missReason.value?.label || 'Select Issue Scenario' }}</span>
          </button>
          <div ngbDropdownMenu aria-labelledby="missReasonDropdown">
            <button
              type="button"
              class="btn btn-link text-right border-bottom border-dark"
              (click)="clearMissReason()"
              ngbDropdownItem
            >
              <span>Clear</span>
            </button>
            <button
              *ngFor="let reason of missReasons"
              (click)="onReasonSelection(reason)"
              class="justify-content-between"
              ngbDropdownItem
            >
              <span>{{ reason.label }}</span>
            </button>
          </div>
      </div>

      <div class="col-lg-2 pt-2">
        <p>App/URL Coverage: {{ selectedObserveSummary?.totalCoveragePercent | percent }}</p>
      </div>

      <div class="col float-right">
        <app-pagination *ngIf="urlGroupList?.length" [metaData]="listMetaData" (fetchPage)="onPageUpdate($event)"> </app-pagination>
      </div>
    </div>

    <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md col m-1">
      <table *ngIf="missReason.value" class="table table-janus-new table-striped" data-t="url-groups-table">
        <thead *ngIf="displayedRows?.length" class="bg-alt-lighter-gray">
          <tr>
            <th
              scope="col"
              class="text-left text-brand-dark-gray raw-url-col"
              [sortable]="COLUMN_TYPES.rawUrl"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.rawUrl }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.actionName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.actionName }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.screenLabel"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.screenLabel }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.targetName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.targetName }}
            </th>
            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.rawTargetName"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.rawTargetName }}
            </th>

            <th
              scope="col"
              class="text-left text-brand-dark-gray"
              [sortable]="COLUMN_TYPES.volume"
              (sort)="onSort($event)"
            >
              {{ COLUMN_TYPES.volume }}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="!loading" class="bg-alt-white">
          <tr *ngFor="let row of displayedRows" data-t="observe-url-groups-table-row">
            <td class="text-left url-col">
              <button type="button" class="btn btn-link" (click)="showAddConfigurations(row)">
                {{ row.groupRawUrl }}
              </button>
            </td>
            <td class="text-left">{{ row.actionName }}</td>
            <td class="text-left">{{ row.screenLabel }}</td>
            <td class="text-left">{{ row.targetName }}</td>
            <td class="text-left">{{ row.rawTargetName }}</td>
            <td class="text-left">{{ row.volume }}</td>
          </tr>
        </tbody>
      </table>
      <app-loading
        *ngIf="loading"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center py-4 my-4"
      ></app-loading>
      <div
        *ngIf="missReason.value && !urlGroupList.length && !loading"
        class="d-flex justify-content-center alert alert-secondary"
        role="alert"
      >
        No results for Url Group:
        {{ selectedObserveSummary?.applicationURL }}
      </div>

      <div *ngIf="!missReason.value" class="align-self-center text-center">
        <div>
          <h4>No results found</h4>
        </div>
        <div>
          <p>Select a issue scenerio to display results.</p>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row p-2">
      <div class="col float-right">
        <app-pagination *ngIf="urlGroupList?.length" [metaData]="listMetaData" (fetchPage)="onPageUpdate($event)">
        </app-pagination>
      </div>
    </div>
  </ng-container>
</div>
