<div class="d-flex flex-column h-100">
  <div class="row">
    <div class="col-lg-3 col-sm-9">
      <div class="input-group form-group">
        <input
          [formControl]="payersSearch"
          (keyup.enter)="searchPayers()"
          type="text"
          class="form-control"
          placeholder="Search Payers"
        />
        <div class="input-group-append">
          <span class="in-input-icon d-flex align-items-center">
            <button type="button" (click)="searchPayers()" class="btn bg-transparent">
              <i class="fa fa-search fa-fw"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-lg-1">
      <a routerLink="/admin/payers/new" class="btn btn-primary mb-3"> Add Payer </a>
    </div>
  </div>

  <div class="d-flex flex-column flex-grow-1 flex-shrink-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="payers-table">
      <thead>
        <tr>
          <th scope="col" class="width-1"><span class="sr-only"> Payer Site URL</span></th>
          <th scope="col" class="text-left">Name</th>
          <th scope="col" class="text-left">Address</th>
          <th scope="col" class="text-left">National ID</th>
        </tr>
      </thead>
      <tbody *ngIf="!loading && payers?.length">
        <tr *ngFor="let payer of payers" data-t="payer-row">
          <td>
            <a href="{{payer?.siteUrl}}" target="_blank" class="text-nowrap" *ngIf="payer?.siteUrl">
              <i class="fa fa-globe"></i>
            </a>
          </td>
          <td><a routerLink="/admin/payers/{{ payer?.id }}" class="text-nowrap"> {{ payer?.name }} </a></td>
          <td>{{payer?.address}}</td>
          <td>{{payer?.payerId}}</td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="loading"
      innerClass="fa-4x"
      outerClass="align-self-center text-brand-light-blue"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div *ngIf="!payers?.length && !loading" class="d-flex justify-content-center alert alert-danger" role="alert">
      Your search returned no results.
    </div>
    <div class="d-flex align-items-baseline justify-content-end mb-4" *ngIf="!loading && payers?.length">
      <small class="mr-3">
        {{page * pageSize - pageSize + 1 | number}} - {{payers?.length < pageSize ? meta?.total : page * pageSize |
        number}} of {{meta?.total | number}}
      </small>
      <ngb-pagination
        [collectionSize]="meta?.total"
        (pageChange)="searchPayers($event)"
        [pageSize]="pageSize"
        [(page)]="page"
        [maxSize]="5"
        *ngIf="meta?.total >= pageSize"
        [boundaryLinks]="false"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
