<div class="d-flex flex-column h-100">
  <app-loading
    *ngIf="isLoading"
    innerClass="fa-4x"
    outerClass="align-self-center text-primary"
    class="d-flex flex-column flex-grow-1 justify-content-center"
  ></app-loading>
  <div *ngIf="!isLoading" class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar h-100">
    <div class="row no-gutters h-100">
      <div class="card col my-1 p-0 border-0 bg-transparent">
        <div class="card-header border-0 bg-primary text-white">{{user?.id ? 'Edit User Details' : 'Add User'}}</div>
        <div class="card-body mt-2 px-5 bg-white shadow">
          <form [formGroup]="userForm" (ngSubmit)="saveUser()" class="d-flex flex-column px-4">
            <div class="row">
              <div class="col">
                <app-user-avatar size="lg" [user]="user" *ngIf="user?.id && user?.name"></app-user-avatar>
              </div>
            </div>
            <i *ngIf="!user?.name" class="text-primary fa fa-user-circle fa-5x"></i>
            <div class="row my-3">
              <div class="col-md-6 col-xl-4">
                <div class="form-group">
                  <label for="name" class="required">Full Name</label>
                  <input
                    type="text"
                    class="form-control shadow-sm"
                    formControlName="name"
                    id="name"
                    [appFcBsValidation]="userForm.get('name')"
                  />
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-6 col-xl-4">
                <div class="form-group">
                  <label for="email" class="required">Email</label>
                  <input
                    type="email"
                    class="form-control shadow-sm"
                    formControlName="email"
                    id="email"
                    [appFcBsValidation]="userForm.get('email')"
                    [readonly]="user?.isSamlEmail ?? false"
                    aria-describedby="samlEmailHelp"
                  />
                  <small *ngIf="user?.isSamlEmail ?? false" id="samlEmailHelp" class="form-text text-muted">
                    SAML emails can not be edited
                  </small>
                </div>
              </div>
            </div>
            <div *ngIf="!authServiceEnabled" class="row my-3">
              <div class="col-md-6 col-xl-4">
                <div class="form-group">
                  <label for="role" class="required">Role</label>
                  <select
                    id="role"
                    class="custom-select"
                    formControlName="role"
                    [appFcBsValidation]="userForm.get('role')"
                  >
                    <option [ngValue]="null" disabled>Select Role</option>
                    <option *ngFor="let role of legacyUserRoles" [ngValue]="role">{{ role }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div *ngIf="!authServiceEnabled" class="row my-3">
              <div
                class="col-md-6 col-xl-4"
                *ngIf="!userForm.get('isSuperuser').value && userForm.get('role').value !== legacyUserRole.Admin; else adminOrgMessage"
              >
                <div class="form-group">
                  <label for="org">Allowed Organizations</label>
                  <app-organization-typeahead (onSelect)="pickedOrg($event)" id="org"></app-organization-typeahead>
                </div>
                <ul class="list-unstyled my-4 height-restrict">
                  <li *ngFor="let org of allowedOrgs" class="d-flex align-items-center">
                    <button class="btn" (click)="removeOrg(org)">
                      <i class="fa fa-times-circle-o text-danger mr-4"></i>
                    </button>
                    {{ org.name }}
                  </li>
                </ul>
              </div>
              <ng-template #adminOrgMessage>
                <strong class="col-md-6 col-xl-4">
                  Admin will have access to ALL organizations in {{primaryOrg?.name}}'s Set!
                </strong>
              </ng-template>
            </div>
            <div *ngIf="authServiceEnabled && !isLoading" class="row my-3">
              <div class="col-md-6 col-xl-4">
                <div class="form-group">
                  <label class="required">Role(s)</label>
                  <app-user-org-assignments
                    [primaryOrganization]="primaryOrg"
                    [user]="user"
                    (allowedOrganizationsChange)="onAllowedOrganizationsChange($event)"
                    (userRolesChange)="onUserRolesChange($event)"
                  ></app-user-org-assignments>
                </div>
              </div>
            </div>
            <ng-template [ngIf]="!user?.id">
              <div class="row my-3">
                <div class="col-md-6 col-xl-4">
                  <div class="form-group">
                    <label for="password" class="required">Password</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control shadow-sm"
                        formControlName="password"
                        id="password"
                        [appFcBsValidation]="userForm.get('password')"
                      />
                      <div class="input-group-append">
                        <span class="in-input-icon d-flex align-items-center">
                          <button
                            type="button"
                            class="btn btn-link bg-transparent"
                            ngbTooltip="Generate password"
                            container="body"
                            (click)="generatePassword()"
                          >
                            <i class="fa fw fa-lg fa-unlock-alt"></i>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <app-password-validator
                    [password]="userForm.get('password')"
                    [email]="userForm.get('email')"
                  ></app-password-validator>
                </div>
              </div>
            </ng-template>
            <div class="row my-4">
              <div class="col-md-6 col-xl-4">
                <div class="custom-control custom-switch text-nowrap mb-2 pl-5" *ngIf="authenticationService.isSuper()">
                  <input class="custom-control-input" type="checkbox" id="isSuperuser" formControlName="isSuperuser" />
                  <label class="custom-control-label ml-4" for="isSuperuser">Superuser</label>
                </div>
                <div class="custom-control custom-switch text-nowrap mb-2 pl-5">
                  <input class="custom-control-input" type="checkbox" id="isActive" formControlName="isActive" />
                  <label class="custom-control-label ml-4" for="isActive">Active</label>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-md-6 col-xl-4 d-flex justify-content-end">
                <button
                  *ngIf="canDelete()"
                  type="button"
                  class="btn btn-secondary mr-1"
                  data-t="delete-user-button"
                  (click)="confirmDelete()"
                >
                  <i class="fa fa-trash mr-3"></i>
                  Delete
                </button>
                <a class="btn btn-cancel mx-1" routerLink="/admin/users">Cancel</a>
                <button type="submit" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-user-button">
                  <i class="fa fa-floppy-o mr-3"></i>
                  Save
                  <app-loading *ngIf="saving" class="pl-2"></app-loading>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
