import { Component, Input } from '@angular/core'
import { ProcedureSet } from 'generated/graphql'
import { format } from 'date-fns'
import { Procedure } from 'generated/graphql'

@Component({
  selector: 'app-procedure-set-details',
  styleUrls: ['./procedure-set-details.component.scss'],
  templateUrl: './procedure-set-details.component.html',
})
export class ProcedureSetDetailsComponent {
  @Input() procedureSet: ProcedureSet

  formatServiceDate(date: string): string {
    return format(new Date(date), 'MM/dd/yyyy')
  }

  /**
   * Use ProcedureSet.integrationMetadata.evicoreCaseId if available,
   * fallback to procedure ref nums if not
   */
  formatCaseNumbers(procedures: Procedure[]): string {
    return (
      this.procedureSet.integrationMetadata?.evicoreCaseId ??
      procedures
        .map((p) => p.authNumber ?? p.referenceNumber)
        .filter((value) => !!value)
        .join(', ')
    )
  }

  formatProcedure(procedure: Procedure): string {
    if (procedure.description) {
      return `${procedure.code} - ${procedure.description}`
    }

    return procedure.code
  }

  getFirstName(): string {
    return this.procedureSet.dependentFirstName ?? this.procedureSet.patientFirstName
    
  }

  getLastName(): string {
    return this.procedureSet.dependentLastName ?? this.procedureSet.patientLastName
  }
}
