import { Pipe, PipeTransform } from '@angular/core'
import { PayerPlan } from 'generated/graphql'

/**
 * Payer plan formatting.
 * @param payerPlan The PayerPlan object to determine output.
 */
@Pipe({
  name: 'payerPlan',
  pure: true,
})
export class PayerPlanPipe implements PipeTransform {
  transform(payerPlan: PayerPlan, isPlanOnly = false): string {
    const planName = payerPlan.planName || '-'
    const payerCode = payerPlan.payerCode || '-'
    return !isPlanOnly ? `${payerPlan.payerName}: ${planName} (${payerCode})`
      : `${planName} (${payerCode})`
  }
}
