<div class="d-flex align-items-center justify-content-center">
  <form (ngSubmit)="submit()" class="w-75 d-flex flex-column">
    <div class="form-group mb-4">
      <input
        #input
        [formControl]="username"
        id="id_username"
        type="text"
        autofocus=""
        autocapitalize="none"
        autocomplete="username"
        maxlength="254"
        required=""
        class="form-control py-4"
        [ngClass]="{ 'is-invalid': invalidMessage }"
        placeholder="&#xf007; EMAIL ADDRESS"
        data-t="username"
        (blur)="warnErrors()"
      />
      <div *ngIf="invalidMessage" class="invalid-feedback">
        {{ invalidMessage }}
      </div>
    </div>
    <button class="btn btn-primary py-4 btn-gradient btn-block border-0 text-light text-uppercase mt-4" type="submit">
      Submit
    </button>
  </form>
</div>
