import { Directive, EventEmitter, Input, Output } from '@angular/core'

export type SortColumn = string
export type SortDirection = 'asc' | 'desc' | ''
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' }

export interface SortEvent {
  column: SortColumn
  direction: SortDirection
}

/**
 * Angular directive to apply classes to sortable table column headers
 *
 * @export
 * @class NgbdSortableHeader
 */
@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.sortable]': 'true',
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = ''
  @Input() direction: SortDirection = ''
  @Output() sort = new EventEmitter<SortEvent>()

  rotate(): void {
    this.direction = rotate[this.direction]
    this.sort.emit({ column: this.sortable, direction: this.direction })
  }
}
