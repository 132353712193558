import { Component, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { FeatureFlagService } from 'app/shared/services/feature-flag.service'
import { ToastService } from 'app/shared/services/toast.service'
import { UsersService } from 'app/shared/services/users.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import {
  CreateFeatureFlagInput,
  FeatureFlag,
  FeatureFlagType,
  Organization,
  UpdateFeatureFlagInput,
  User,
} from 'generated/graphql'
import { startCase } from 'lodash'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter, first, map, switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'app-feature-flag-edit',
  templateUrl: './feature-flag-edit.page.html',
  styleUrls: ['./feature-flag-edit.page.scss'],
})
export class FeatureFlagEditPage implements OnDestroy {
  routerSub$: Subscription
  isLoading: boolean
  isSaving: boolean
  featureFlag: FeatureFlag
  flagTypes = Object.values(FeatureFlagType)
  forType: string
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    value: new UntypedFormControl(false),
    type: new UntypedFormControl(null, Validators.required),
    typeId: new UntypedFormControl(null),
  })
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private toastService: ToastService,
    private featureFlagService: FeatureFlagService,
    private orgService: OrgService,
    private usersService: UsersService,
    private crumbs: BreadcrumbsService,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger], index) => event instanceof NavigationEnd || trigger),
        map(() => this.route.snapshot.paramMap.get('featureFlagId')),
        tap((featureFlagId) => {
          if (featureFlagId === 'new') {
            this.featureFlag = null
            this.form.reset({ name: null, value: false, type: null, typeId: null })
            this.crumbs.setPageTitle('Admin - Feature Flags - New')
          }
        }),
        filter((featureFlagId) => featureFlagId != 'new'),
        switchMap((featureFlagId) => {
          return this.featureFlagService.getFeatureFlag(featureFlagId)
        }),
        tap(async (flag) => {
          this.isLoading = flag?.loading
          this.featureFlag = flag?.data?.featureFlag

          this.crumbs.setPageTitle(
            `Admin - Feature Flags - ${startCase(this.featureFlag.name)} - ${this.featureFlag.type}`,
          )
          this.form.setValue({
            name: this.featureFlag?.name.trim(),
            value: this.featureFlag?.value || false,
            type: this.featureFlag?.type,
            typeId: this.featureFlag?.typeId,
          })
          if (this.featureFlag?.type === 'Organization') {
            let result = await this.orgService
              .getOrg(this.featureFlag?.typeId)
              .pipe(first())
              .toPromise()
              .catch((e) => null)
            if (result?.data?.organization) {
              this.forType = result?.data?.organization?.name
            }
          }
          if (this.featureFlag?.type === 'User') {
            let result = await this.usersService
              .getUser(this.featureFlag?.typeId)
              .pipe(first())
              .toPromise()
              .catch((e) => null)
            if (result?.data?.user?.id) {
              this.forType = result.data.user.name || result.data.user.email
            }
          }
        }),
      )
      .subscribe()
  }

  ngOnInit() {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }

  /**
   * Sets the selected organization id on the form
   *
   * @param {Organization} organization
   * @memberof FeatureFlagEditPage
   */
  updateOrganization(organization: Organization): void {
    if (organization?.id) {
      this.form.get('typeId').setValue(organization.id)
    }
  }

  /**
   * Sets the selected user id on the form
   *
   * @param {User} user
   * @memberof FeatureFlagEditPage
   */
  updateUser(user: User): void {
    if (user?.id) {
      this.form.get('typeId').setValue(user.id)
    }
  }

  /**
   * Unsets the typeId and forType when the type is changed
   *
   * @memberof FeatureFlagEditPage
   */
  clearTypeId(): void {
    this.form.get('typeId').setValue(null)
    this.forType = null
  }

  /**
   * Save existing or create new feature flag
   *
   * @return {*}  {Promise<void>}
   * @memberof FeatureFlagEditPage
   */
  async saveFeatureFlag(): Promise<void> {
    this.isSaving = true
    if (this.form.invalid || !this.isTypeIdValid()) {
      this.toastService.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.form)),
      )
      this.form.markAllAsTouched()
    } else {
      try {
        if (this.featureFlag?.id) {
          let form = this.form.value as UpdateFeatureFlagInput
          form.id = this.featureFlag.id
          form.name = form.name.trim()
          this.featureFlag = await this.featureFlagService.updateFeatureFlag(form)
        } else {
          let form = this.form.value as CreateFeatureFlagInput
          form.name = form.name.trim()
          this.featureFlag = await this.featureFlagService.createFeatureFlag(form)
        }
        this.form.setValue({
          name: this.featureFlag?.name.trim(),
          value: this.featureFlag?.value || false,
          type: this.featureFlag?.type,
          typeId: this.featureFlag?.typeId,
        })
        this.toastService.success('Feature Flag successfully saved')
        this.router.navigate(['admin', 'feature-flags']).then(() => {
          window.location.reload() // This updates each feature flag list without having to watch multiple caches
        })
      } catch (e) {
        this.toastService.error(parseGraphQLError(e, 'Could not save feature flag'), JSON.stringify(e))
      }
    }
    this.isSaving = false
  }

  /**
   * Validate that if the type is not global a typeId value exists
   */
  isTypeIdValid(): boolean {
    if (this.form.value.type === FeatureFlagType.Global || this.form.value.typeId) {
      return true
    }

    return false
  }

  /**
   * Launch modal to confirm deleting current feature flag
   *
   * @memberof FeatureFlagEditPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Feature Flag?'
    modalRef.componentInstance.body = 'Once this feature flag is deleted its data cannot be recovered'
    modalRef.componentInstance.yes = 'Delete feature flag'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteFeatureFlag()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete current feature flag
   *
   * @return {*}  {Promise<void>}
   * @memberof FeatureFlagEditPage
   */
  async deleteFeatureFlag(): Promise<void> {
    try {
      if (this.featureFlag?.id) {
        await this.featureFlagService.deleteFeatureFlag(this.featureFlag)
      }
      this.toastService.success('Feature flag successfully deleted')
      this.router.navigate(['admin', 'feature-flags'])
    } catch (e) {
      this.toastService.error(parseGraphQLError(e, 'Could not delete feature flag'), JSON.stringify(e))
    }
  }
}
