<div class="position-relative" id="typeahead-select-wrapper">
  <input
    data-id="typeahead-select"
    type="text"
    class="form-control border-alt-light-gray text-alt-medium-gray"
    [container]="container"
    [placeholder]="placeholder"
    [ngbTypeahead]="search"
    [(ngModel)]="selectedItem"
    [inputFormatter]="resultFormatter || defaultFormatter"
    [resultFormatter]="resultFormatter || defaultFormatter"
    (focus)="focus$.next($any($event).target.value)"
    (click)="click$.next($any($event).target.value)"
    (selectItem)="selectValue.emit($event.item)"
    #input
    #instance="ngbTypeahead"
  />
  <i class="fa fa-search position-absolute text-alt-medium-gray"></i>
</div>

