import { Component, Input, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { CreateTaskInput, TaskType } from 'generated/graphql'
import { from, Observable, of, Subscription } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators'

/**
 * Modal to add a task to a claim
 *
 * @export
 * @class CreateTaskComponent
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-create-task',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.scss'],
  host: {
    'data-t': 'create-task-modal',
  },
})
export class CreateTaskComponent implements OnDestroy {
  @Input() providerClaimId: string

  taskForm = new UntypedFormGroup({
    taskType: new UntypedFormControl(null, Validators.required),
    instructions: new UntypedFormControl('', Validators.required),
    aveMinutes: new UntypedFormControl(0, Validators.required),
  })

  private taskType = null
  private shouldSaveAsNew = false
  taskForm$: Subscription

  saving: boolean = false

  constructor(
    public activeModal: NgbActiveModal,
    private tasksService: TasksService,
    private tasktypeService: TaskTypesService,
    private toast: ToastService,
  ) {
    this.taskForm$ = this.taskForm.valueChanges.subscribe((form) => {
      if (this.taskType !== null && form.taskType.id) {
        let noP = form.instructions.replace('<p>', '').replace('</p>', '').trim()
        if (
          this.providerClaimId &&
          (form.taskType.name.localeCompare(this.taskType?.name) !== 0 ||
            (form.instructions.localeCompare(this.taskType?.instructions) !== 0 &&
              noP.localeCompare(this.taskType?.instructions) !== 0))
        ) {
          this.shouldSaveAsNew = true
        } else if (!this.providerClaimId) {
          this.shouldSaveAsNew = true
        } else {
          this.shouldSaveAsNew = false
        }
      } else {
        this.shouldSaveAsNew = true
      }
    })
  }

  ngOnDestroy(): void {
    this.taskForm$?.unsubscribe()
  }

  /**
   * Respond to typeahead task type search
   *
   * @param {Observable<string>} text$
   * @return {*} {Observable<TaskType[]>}
   * @memberof CreateTaskComponent
   */
  search = (text$: Observable<string>): Observable<TaskType[]> =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        from(this.tasktypeService.searchTaskTypes(term)).pipe(
          catchError(() => {
            return of([])
          }),
          tap((types) => {
            if (!types?.length) {
              this.taskType = null
            }
          }),
        ),
      ),
    )

  /**
   * Format typeahead input
   *
   * @param {{ name: string }} x
   * @return {*} {string}
   * @memberof CreateTaskComponent
   */
  inputFormatter = (x: { name: string }): string => x.name

  /**
   * Format typeahead results
   *
   * @param {{ name: string }} x
   * @return {*} {string}
   * @memberof CreateTaskComponent
   */
  resultFormatter = (x: { name: string }): string => x.name

  /**
   * Fill out form if an existing task type is selected
   *
   * @param {{ item: TaskType }} event
   * @memberof CreateTaskComponent
   */
  onSelectItem(event: { item: TaskType }): void {
    let taskType = event.item
    debug('tasks', 'selected a task type', taskType)
    this.taskForm.patchValue({
      instructions: taskType?.description || '',
      aveMinutes: taskType?.averageMinutes || 0,
    })
    this.taskType = {
      name: taskType?.name,
      instructions: taskType?.description,
      averageMinutes: taskType?.averageMinutes,
    }
  }

  /**
   * Update existing task type or create new one
   *
   * @return {*}  {Promise<void>}
   * @memberof CreateTaskComponent
   */
  async saveNewTask(): Promise<void> {
    if (!this.taskForm.valid) {
      return
    }
    this.saving = true
    let task = this.taskForm.get('taskType').value
    debug('tasks', 'saving new task type', task)

    if (!task?.id || this.shouldSaveAsNew) {
      let name = task?.id ? task.name : task
      task = {
        name: name,
        description: this.taskForm.get('instructions').value,
        averageMinutes: this.taskForm.get('aveMinutes').value,
      }
      try {
        task = await this.tasktypeService.createTaskType(task)
        this.toast.success('Task Type created')
        if (!this.providerClaimId) {
          this.activeModal.close()
          return
        }
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not create Task Type'), JSON.stringify(e))
        return
      }
    }

    let createTaskInput: CreateTaskInput = {
      providerClaimId: this.providerClaimId,
      taskTypeId: task.id,
    }
    try {
      await this.tasksService.createTask(createTaskInput)
      this.saving = false
      this.activeModal.close()
      this.toast.success('Task created')
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not create Task'), JSON.stringify(e))
    }
  }
}
