import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * Handle various organizational decryptions for the ui
 *
 * @export
 * @class DecryptService
 */
@Injectable({
  providedIn: 'root',
})
export class DecryptService {
  constructor(private http: HttpClient) {}

  /**
   * Assuming something was encrypted via an epic
   * url encryption, we want to decrypt it
   */
  getEpicDecrypted(toDecrypt: string): Observable<string> {
    return this.http
      .get<{
        decrypted: string
      }>(`api/decrypt/epic/${encodeURIComponent(toDecrypt)}`)
      .pipe(map((result) => result?.decrypted))
  }
}
