import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'

@Component({
  selector: 'app-tableau-dashboard',
  styleUrls: ['./tableau-dashboard.component.scss'],
  templateUrl: './tableau-dashboard.component.html',
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class TableauDashboardComponent implements OnInit {
  @Input() type: string = 'standard'

  env: string
  dashboard: string
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private authenticationService: AuthenticationService,
    private toast: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const dashboardEmbedInfo = await this.authenticationService.getTableauDashboardEmbedInfo(this.type)
      this.env = dashboardEmbedInfo.env
      this.dashboard = dashboardEmbedInfo.dashboard
      let script = this.renderer.createElement('script')
      script.src = `https://tableau.${this.env}.janus-ai.com/javascripts/api/viz_v1.js`
      script.type = 'text/javascript'
      this.renderer.appendChild(this._document.body, script)
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not fetch Dashboard Info'), JSON.stringify(e))
    }
  }
}
