<div class="border rounded shadow d-flex flex-column flex-basis-0 flex-grow-1 position-relative">
  <app-illuminate-graph-panel-toolbar
    (onPanelOpen)="panelCollapsed = false"
    (onSelectPathMode)="selectPathMode($event)"
  ></app-illuminate-graph-panel-toolbar>
  <div class="d-flex flex-column flex-basis-0 flex-grow-1">
    <app-dag
      (graphChange)="graph.graph = $event"
      [(graphData)]="graph.graphData"
      [graphOptions]="graphOptions"
      [setMode]="graphMode"
      (onSelection)="maybeSelectPathNode($event)"
      class="flex-grow-1"
    ></app-dag>
  </div>
  <div
    class="offcanvas-collapse offcanvas-collapse-fit d-flex flex-column flex-grow-1 h-100"
    [(ngbCollapse)]="panelCollapsed"
  >
    <div class="card bg-white flex-grow-1 collapse-content">
      <div class="card-header bg-alt-white d-flex justify-content-start align-items-center">
        <button class="btn btn-lg btn-link mr-4" (click)="panelCollapsed = true">
          <i class="fa fa-times"></i>
          <span class="sr-only">Cancel</span>
        </button>
        <h1 class="h4 mb-0 text-primary text-nowrap text-truncate">Path Statistics</h1>
      </div>
      <div class="card-body">
        <div class="h-100 bg-transparent">
          <div class="card bg-alt-baby-blue text-brand-dark-blue">
            <div class="card-header">Path Variant Details</div>
            <div class="card-body bg-alt-baby-blue">
              <table class="table rounded bg-alt-barely-blue">
                <tr class="">
                  <td class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase"></td>
                  <td class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase">Baseline</td>
                  <td
                    *ngIf="activePath?.id"
                    class="bg-brand-dark-gray-high p-1 small text-center text-alt-white text-uppercase"
                  >
                    {{ activePath?.name }}
                  </td>
                </tr>
                <tr>
                  <td class="border-top-0">Count</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold border-top-0">
                    {{ workflowStats?.workflowFrequency }}
                  </td>
                  <td
                    *ngIf="activePath?.id"
                    class="bg-alt-baby-blue-med text-primary text-right font-weight-bold border-top-0"
                  >
                    {{ pathStats?.workflowFrequency }}
                  </td>
                </tr>
                <tr>
                  <td>Volume</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.cashVolume || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.cashVolume || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Avg Outstanding</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.averageOutstandingAmount || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.averageOutstandingAmount || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Avg Payment</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.averagePayment || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.averagePayment || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Median Payment</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.medianPayment || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.medianPayment || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Payment Frequency</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.percentagePaid || 0 * 100 | number: '1.0-0' }}%
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.percentagePaid || 0 * 100 | number: '1.0-0' }}%
                  </td>
                </tr>
                <tr>
                  <td>Avg Completion Time</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ (workflowStats?.averageCompletionTime || 0) * 1000 | date: 'm:ss' }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ (pathStats?.averageCompletionTime || 0) * 1000 | date: 'm:ss' }}
                  </td>
                </tr>
                <tr>
                  <td>Median Completion Time</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ (workflowStats?.medianCompletionTime || 0) * 1000 | date: 'm:ss' }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ (pathStats?.medianCompletionTime || 0) * 1000 | date: 'm:ss' }}
                  </td>
                </tr>
                <tr>
                  <td>Avg Collection Cost</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.averageCollectionCost || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.averageCollectionCost || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Mean Collection Cost</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.medianCollectionCost || 0 | currency }}
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.medianCollectionCost || 0 | currency }}
                  </td>
                </tr>
                <tr>
                  <td>Avg Payment Lag</td>
                  <td class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ workflowStats?.averagePaymentLag / (3600 * 24) }} days
                  </td>
                  <td *ngIf="activePath?.id" class="bg-alt-baby-blue-med text-primary text-right font-weight-bold">
                    {{ pathStats?.averagePaymentLag / (3600 * 24) }} days
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
