<div class="full-view-height d-flex flex-column align-items-center">
  <div class="brand flex-shrink-0"></div>
  <main class="flex-grow-1 d-flex overflow-hidden">
    <div class="bg-alt-white rounded p-4 h-100 overflow-scroll">
      <h1 class="text-center">Support and Maintenance Services</h1>
      <ol>
        <li>
          <p class="text-decoration-underline">Support and Maintenance Services</p>
          <p>
            Support and Maintenance Services are included in the Annual SaaS Service Fee in Schedule A and entitles
            Customer to the following:
          </p>
          <ol type="a">
            <li>
              Telephone or electronic support in order to help Customer locate and correct problems with the Software.
            </li>
            <li>
              Bug fixes and code corrections to correct Software malfunctions in order to bring such Software into
              substantial conformity with the operating specifications.
            </li>
            <li>
              All extensions, enhancements and other changes that Janus, at its sole discretion, makes or adds to the
              Software and which Janus furnishes, without charge, to all other Subscribers of the SaaS Service.
            </li>
            <li>
              Up to five (5) dedicated contacts designated by Customer in writing that will have access to support
              services.
            </li>
          </ol>
        </li>
        <li>
          <p class="text-decoration-underline">Response and Resolution Goals</p>
          <ul>
            <li>&ldquo;business hours&rdquo; 9am-5pm CST, Monday thru Friday, except holidays.</li>
            <li>&ldquo;Fix&rdquo; means the repair or replacement of Software component to remedy Problem.</li>
            <li>
              &ldquo;Problem&rdquo; means a defect in Software as defined in Janus’s standard Software specification
              that significantly degrades such Software.
            </li>
            <li>
              &ldquo;Respond&rdquo; means acknowledgement of Problem received containing assigned support engineer name,
              date and time assigned, and severity assignment.
            </li>
            <li>
              &ldquo;Workaround&rdquo; means a change in the procedures followed or data supplied by Customer to avoid a
              Problem without substantially impairing Customer’s use of the Software.
            </li>
          </ul>
        </li>
        <table class="mt-4">
          <thead>
            <tr>
              <th width="35%">Problem Severity</th>
              <th width="20%">Response Goals</th>
              <th width="45%">Resolution Goals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                1. The production system is creating a significant impact to the Customer’s business function preventing
                that function from being executed.
              </td>
              <td>Janus will Respond within 4 business hours</td>
              <td>
                Upon confirmation of receipt, a Janus support personnel begins continuous work on the Problem, and a
                customer resource must be available at any time to assist with problem determination. Customer Support
                will provide reasonable effort for Workaround or Fix within 24 hours, once the Problem is reproducible
                or once we have identified the Software defect. Janus may incorporate Fix in future release of software.
              </td>
            </tr>
            <tr>
              <td>
                2. The production system or application is moderately affected. There is no workaround currently
                available or the workaround is cumbersome to use.
              </td>
              <td>Janus will Respond within 4 business hours.</td>
              <td>
                Customer Support will provide reasonable effort for Workaround or Fix within 7 business days, once the
                Problem is reproducible. Janus may incorporate fix in future release of software.
              </td>
            </tr>
            <tr>
              <td>
                3. The production system or application issue is not critical: no data has been lost, and the system has
                not failed. The issue has been identified and does not hinder normal operation, or the situation may be
                temporarily circumvented using an available workaround.
              </td>
              <td>Janus will Respond within 8 business hours.</td>
              <td>
                Customer Support will provide reasonable effort for Workaround or Fix within 10 business days, once the
                Problem is reproducible. Janus may incorporate Fix in future release of software.
              </td>
            </tr>
            <tr>
              <td>
                4. Non-critical issues, general questions, enhancement requests, or the functionality does not match
                documented specifications.
              </td>
              <td>Janus will Respond within 24 business hours.</td>
              <td>Resolution of Problem may appear in future release of software.</td>
            </tr>
          </tbody>
        </table>
      </ol>
    </div>
  </main>
</div>
