<div class="d-flex align-items-center justify-content-center">
  <form (ngSubmit)="submit()" class="w-75 d-flex flex-column">
    <div class="input-group form-group mb-4">
      <input
        #input
        id="id_password"
        [type]="passType"
        [formControl]="password"
        autofocus=""
        autocapitalize="none"
        autocomplete="{{ hasConfirm ? '' : 'current-password' }}"
        required=""
        class="form-control py-4"
        placeholder="&#xf023; PASSWORD"
        data-t="password"
      />      
      <div class="input-group-append">
        <span class="in-input-icon d-flex align-items-center">
          <button
            type="button"
            *ngIf="password?.value?.length"
            class="btn btn-link bg-transparent"
            (click)="togglePassType()"
          >
            <i
              class="fa fw fa-lg text-reset"
              [class.fa-eye]="passType === 'password'"
              [class.fa-eye-slash]="passType === 'text'"
            ></i>
          </button>
        </span>
      </div>
    </div>

    <div class="input-group form-group mb-4" *ngIf="hasConfirm">
      <input
        id="id_password_confirm"
        [type]="passType"
        [formControl]="confirm"
        autofocus=""
        autocapitalize="none"
        autocomplete=""
        required=""
        class="form-control py-4"
        placeholder="CONFIRM"
      />      
    </div>

    <div *ngIf="validateOnServer" class="mt-2">
      <app-password-validator [password]="password"></app-password-validator>
    </div>
    

    <button class="btn btn-primary py-4 btn-gradient btn-block border-0 text-light text-uppercase mt-4" [disabled]="disable" type="submit">
      {{ buttonText }}
      <app-loading *ngIf="loading" class="pl-2"></app-loading>
    </button>
    <a *ngIf="showForgot" class="btn btn-link text-brand-light-blue btn-block" routerLink="/login/reset">
      Forgot password? <i class="fa fa-lock fa-fw pl-3"></i>
    </a>
  </form>
</div>
