import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { WorkflowService } from 'app/admin/workflows/workflow.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { WorkflowType } from 'generated/graphql'
import { differenceBy } from 'lodash'
import { of, Subject } from 'rxjs'
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators'

/**
 * Component to display and search a list of workflow types
 *
 * @export
 * @class WorkflowSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-workflow-search',
  templateUrl: './workflow-search.component.html',
  styleUrls: ['./workflow-search.component.scss'],
})
export class WorkflowSearchComponent implements OnInit, OnDestroy {
  @Input() publishedOnly = true
  @Input() hiddenWorkflows: WorkflowType[] = []
  @Output() selectWorkflow = new EventEmitter<WorkflowType>()

  workflows: WorkflowType[]
  workflowSearch = new UntypedFormControl('')
  searching: boolean = false
  totalWorkflows: number
  destroyed$ = new Subject<void>()

  constructor(private workflowService: WorkflowService, private toast: ToastService) {}

  async ngOnInit(): Promise<void> {
    debug('workflow-search', 'ngOnInit')
    this.workflowSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap(async (term) => await this.searchWorkflows(term)),
        catchError((e) => {
          return of([])
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe()

    await this.searchWorkflows('')
  }

  ngOnDestroy(): void {
    debug('workflow-search', 'ngOnDestroy')
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  /**
   * Load a list of workflow types
   *
   * @param {string} term
   * @return {*}  {Promise<void>}
   * @memberof WorkflowSearchComponent
   */
  async searchWorkflows(term: string): Promise<void> {
    this.searching = true
    try {
      this.workflows = await this.workflowService.getWorkflowTypes({
        search: term,
        published: this.publishedOnly || null,
      })
      this.totalWorkflows = this.workflowService.getMeta()?.total
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find workflows'), JSON.stringify(e))
    }
    this.searching = false
  }

  /**
   * Helper for displaying a list of workflow types
   *
   * @readonly
   * @type {WorkflowType[]}
   * @memberof WorkflowSearchComponent
   */
  get workflowsToShow(): WorkflowType[] {
    return differenceBy(this.workflows, this.hiddenWorkflows, 'id') || []
  }
}
