import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ObserveActivityLogModels } from 'generated/graphql'

@Component({
  selector: 'app-observe-users-activity-log',
  templateUrl: './observe-users-activity-log.component.html',
})

export class ObserveUsersActivityLogComponent implements OnInit  {
  orgId: string
  modelType: ObserveActivityLogModels = ObserveActivityLogModels.ObserveUser
  constructor(private authenticationService: AuthenticationService ) {}

  async ngOnInit(): Promise<void> {
    this.orgId = this.authenticationService.getUser().orgId
  }

}
