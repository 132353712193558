<div class="card p-0 border-0 bg-transparent h-100">
  <div class="card-header border-0 bg-primary text-white">
    <strong>{{ featureFlag?.id ? 'Edit Feature Flag Details' : 'Add Feature Flag'}}</strong>
  </div>
  <div class="row h-100">
    <div class="col">
      <div class="card-body bg-white shadow d-flex flex-column flex-basis-0 flex-grow-1 h-100">
        <form
          [formGroup]="form"
          (ngSubmit)="saveFeatureFlag()"
          class="d-flex flex-column flex-basis-0 flex-grow-1 px-4"
        >
          <div class="row my-3">
            <div class="col">
              <div class="form-group">
                <label for="name" class="required">Feature</label>
                <input
                  type="text"
                  class="form-control shadow-sm"
                  formControlName="name"
                  id="name"
                  [appFcBsValidation]="form.get('name')"
                />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col">
              <div class="form-group">
                <label for="type" class="required">Type</label>
                <select
                  id="type"
                  class="custom-select custom-select shadow-sm"
                  formControlName="type"
                  (change)="clearTypeId()"
                  [appFcBsValidation]="form.get('type')"
                >
                  <option [ngValue]="null" disabled>Select Type</option>
                  <option *ngFor="let type of flagTypes" [ngValue]="type">{{ type }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col">
              <div class="form-group" *ngIf="form.get('type').value === 'Organization'">
                <label class="required" for="typeId">Organization</label>
                <app-organization-typeahead
                  (onSelect)="updateOrganization($event)"
                  [selectedOrg]="forType"
                  [clearInput]="false"
                  id="typeId"
                ></app-organization-typeahead>
              </div>
              <div class="form-group" *ngIf="form.get('type').value === 'User'">
                <label class="required" for="typeId">User</label>
                <app-user-search
                  (onSelect)="updateUser($event)"
                  [selectedUser]="forType"
                  [clearInput]="false"
                  id="typeId"
                >
                </app-user-search>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col">
              <div class="custom-control custom-switch text-nowrap mb-2 pl-5">
                <input class="custom-control-input" type="checkbox" id="value" formControlName="value" />
                <label class="custom-control-label ml-4" for="value">Enabled</label>
              </div>
            </div>
          </div>
          <div class="row mt-auto mb-3 card-footer border-0 bg-transparent">
            <div class="col d-flex flex-nowrap justify-content-end">
              <a class="btn btn-cancel mr-2" routerLink="/admin/feature-flags"> Cancel </a>
              <button
                type="button"
                class="btn btn-danger mr-2"
                ngbTooltip="Delete Feature Flag"
                (click)="confirmDelete()"
                container="body"
              >
                <i class="fa fa-trash mr-2"></i> Delete
              </button>
              <button type="submit" class="btn btn-alt-light-blue mr-4" ngbTooltip="Save Feature Flag" container="body">
                <i class="fa fa-floppy-o mr-2"></i>
                Save
                <app-loading *ngIf="isSaving" class="pl-2"></app-loading>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
