import { capitalize } from 'lodash'

/**
 * Split a string into words based on camelCase, PascalCase, snake_case, and kebab-case. (Accepts any combination of the four.)
 * Also works with SCREAMING_SNAKE_CASE and SCREAMING-KEBAB-CASE.
 *
 * NOTE: Lodash does have a _words function that is supposed to do the same thing, but it ALSO strips out special characters. 😞
 *
 * @param input String with any combination of camelCase, PascalCase, snake_case, and kebab-case
 * @returns
 */
export function splitWords(input: string): string[] {
  // Handle transitions between lowercase/uppercase and numbers, and numbers and lowercase/uppercase
  const digitsRegex = /([a-zA-Z])([0-9])|([0-9])([a-zA-Z])/g
  // camelCase
  const camelCaseRegex = /([a-z0-9])([A-Z])/g
  // PascalCase - (you'd think these could be handled by one regex, but alas, no. 🥔)
  const pascalCaseRegex = /([A-Z]+)([A-Z][a-z])/g

  const words = input
    .replace(digitsRegex, (match, p1, p2, p3, p4) => (p1 || p3) + ' ' + (p2 || p4))
    .replace(camelCaseRegex, '$1 $2')
    .replace(pascalCaseRegex, '$1 $2')
    .split(' ') // Splits prior additions as well as any spaces that existed in the original string
    .flatMap((word) => word.split('_')) // snake_case
    .flatMap((word) => word.split('-')) // kebab-case

  return words
}

/**
 * Capitalize the first letter of each word in a string.
 *
 * NOTE: This is possibly a naive implementation. That's why I wanted to use lodash's startCase function, but alas, we cannot.
 *
 * @param value
 * @returns
 */
export const startCase = (value: string): string =>
  splitWords(value.trim())
    .map((word) => (word !== word.toUpperCase() ? capitalize(word) : word))
    .join(' ')

export default startCase
