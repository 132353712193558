<select
  class="custom-select"
  (change)="this.onSelection.emit(outcomeInput.value)"
  [formControl]="outcomeInput"
  [appFcBsValidation]="outcomeInput"
>
  <option [ngValue]="null" disabled>Select task…</option>
  <optgroup label="Tasks">
    <option *ngFor="let option of options" [ngValue]="option.taskIndex" [disabled]="option.disabled">
      {{ option.taskName }}
    </option>
  </optgroup>
  <optgroup label="Other">
    <option ngValue="new_task">New Task</option>
    <option ngValue="terminus">Complete Workflow</option>
  </optgroup>
</select>
