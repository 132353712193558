import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ClaimsAndTasksQuery } from 'app/admin/tasks/delete/claims-by-id.gql'
import { ClaimsService } from 'app/claims/claims.service'
import { TasksService } from 'app/pathfinder/tasks/tasks.service'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { ToastService } from 'app/shared/services/toast.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { Claim, Task, TaskType } from 'generated/graphql'
import { chunk, compact, flatten, pull, uniq, uniqBy } from 'lodash'
import { Subject, Subscription, combineLatest } from 'rxjs'
import { filter } from 'rxjs/operators'

/**
 * Page to allow deleting tasks from claims
 *
 * @export
 * @class TasksDeletePage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-admin-tasks-delete-page',
  templateUrl: './delete.page.html',
  styleUrls: ['./delete.page.scss'],
})
export class TasksDeletePage implements OnInit, OnDestroy {
  claimIds = new UntypedFormControl('')
  claimIds$: Subscription
  claims: string[]
  fetchedClaims: Claim[] = []

  tasks: Task[] = []
  taskTypes: TaskType[] = []
  selectedTaskTypes: TaskType[] = []
  fetchingTasks: boolean = false

  deleting: boolean = false

  routerSub$: Subscription
  triggerRoute$ = new Subject<boolean>()

  constructor(
    private router: Router,
    private claimsService: ClaimsService,
    private tasksService: TasksService,
    private toast: ToastService,
    private modal: NgbModal,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
    .pipe(filter(([event, trigger]) => event instanceof NavigationEnd || trigger))
    .subscribe(() => {
      this.onRouteChange()
    })
    this.claimIds$ = this.claimIds.valueChanges.subscribe(async (ids: string) => {
      this.fetchingTasks = true
      if (ids?.length > 0) {
        this.claims = uniq(ids.split(/\t|\n|,/))
        try {
          this.fetchedClaims = await this.claimsService.getClaims(
            ClaimsAndTasksQuery,
            {
              sort: 'providerClaimId',
              offset: 0,
              limit: 100,
              search: `providerClaimId: (${this.claims.join(' OR ')})`,
            },
            false,
          )
        } catch (e) {
          return
        }
        this.tasks = compact(flatten(this.fetchedClaims.map((claim) => claim?.tasks)))
        this.taskTypes = uniqBy(this.tasks, 'taskType.id').map((task) => task?.taskType)
      }
      this.fetchingTasks = false
    })
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.claimIds$.unsubscribe()
    this.routerSub$.unsubscribe()
  }

  /**
   * Set list of claims from which to delete tasks
   *
   * @return {*}  {Promise<void>}
   * @memberof TasksDeletePage
   */
  async onRouteChange(): Promise<void> {
    let providerClaimIDs = this.router.getCurrentNavigation().extras?.state?.data?.providerClaimIDs
    if (providerClaimIDs) {
      this.claimIds.setValue(providerClaimIDs.join())
    }
  }

  /**
   * Add task to list of selected tasks for deletion
   *
   * @param {TaskType} TaskType
   * @param {Event} $event
   * @memberof TasksDeletePage
   */
  selectTaskType(TaskType: TaskType, $event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedTaskTypes.push(TaskType)
    } else {
      pull(this.selectedTaskTypes, TaskType)
    }
  }

  /**
   * Add all visible tasks to list of selected tasks for deletion
   *
   * @param {Event} $event
   * @memberof TasksDeletePage
   */
  selectAllTaskTypes($event: Event): void {
    if (($event.target as HTMLInputElement).checked) {
      this.selectedTaskTypes = this.taskTypes
    } else {
      this.selectedTaskTypes = []
    }
  }

  /**
   * Launch modal to confirm deleting tasks
   *
   * @memberof TasksDeletePage
   */
  confirmTaskDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    let tasksToDelete = compact(
      this.tasks.map((task) => {
        if (this.selectedTaskTypes.find((type) => type.id === task.taskType.id)) {
          return task.id
        }
      }),
    )
    modalRef.componentInstance.title = 'Delete Tasks?'
    modalRef.componentInstance.body = `This will delete ${tasksToDelete?.length} tasks from up to ${this.claims?.length} claims`
    modalRef.componentInstance.yes = 'Delete Tasks'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteTasks(tasksToDelete)
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete selected task(s) on selected claim(s)
   *
   * @param {string[]} tasksToDelete
   * @return {*}  {Promise<void>}
   * @memberof TasksDeletePage
   */
  async deleteTasks(tasksToDelete: string[]): Promise<void> {
    this.deleting = true
    try {
      let groupTasksToDelete: string[][] = chunk(tasksToDelete, 100)
      let results = flatten(
        await Promise.all(
          groupTasksToDelete.map(async (tasksToDelete) => this.tasksService.deleteTasks(tasksToDelete)),
        ),
      )
      let successfulResults: number = results.filter((result) => {
        return result.succeeded === true
      })?.length
      if (successfulResults === 0) {
        throw new Error('API returned all successes as false')
      } else {
        this.toast.success(`Successfully deleted ${successfulResults} tasks on claims`)
        this.cancel()
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete tasks on claims'), JSON.stringify(e))
    }
    this.deleting = false
  }

  /**
   * Cancel current deletion and clear inputs
   *
   * @memberof TasksDeletePage
   */
  cancel(): void {
    this.claimIds.reset()
    this.tasks = []
    this.taskTypes = []
    this.fetchedClaims = []
    this.selectedTaskTypes = []
  }
}
