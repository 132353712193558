import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'

export interface OutcomeOption {
  taskIndex: number
  taskName: string
  disabled: boolean
}

export type OutcomeSelection = number | 'new_task' | 'terminus'

/**
 * Component to allow user to select a task's outcome
 * Should be moved to admin/workflows/components
 *
 * @export
 * @class OutcomeSelectorComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-outcome-selector',
  templateUrl: './outcome-selector.component.html',
  styleUrls: ['./outcome-selector.component.scss'],
})
export class OutcomeSelectorComponent implements OnInit {
  @Input() selection: OutcomeSelection
  @Input() options: OutcomeOption[] = []
  @Input() required: boolean = false
  @Output() onSelection = new EventEmitter<OutcomeSelection>()

  outcomeInput = new UntypedFormControl(null)

  constructor() {}

  ngOnInit(): void {
    if (this.selection) {
      this.outcomeInput.setValue(this.selection)
    }
    if (this.required) {
      this.outcomeInput.setValidators([Validators.required])
    }
  }
}
