import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { Facet } from 'app/shared/types/facet'
import { Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators'

/**
 * Component to display a number range facet for claim properties
 *
 * @export
 * @class NumberRangeFacetComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'app-faceted-search-number-range',
  templateUrl: './number-range.component.html',
  styleUrls: ['./number-range.component.scss'],
})
export class NumberRangeFacetComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() data: Facet
  @ViewChild('input') input: ElementRef

  isCollapsed = false

  form = new UntypedFormGroup({
    min: new UntypedFormControl(null),
    max: new UntypedFormControl(null),
  })

  range$: Subscription
  facets$: Subscription

  constructor(private facetService: FacetedSearchService) {}

  ngOnInit(): void {
    this.range$ = this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((form) => form.min || form.max),
        tap((form) => {
          let { min, max } = form
          let display

          if (max && !min) {
            display = `< ${this.data.labelPrepend}${max}`
          } else if (min && !max) {
            display = `> ${this.data.labelPrepend}${min}`
          } else {
            if (min > max) {
              this.form.get('min').setValue(max, { emitEvent: false })
              this.form.get('max').setValue(min, { emitEvent: false })
              ;[min, max] = [max, min]
            }
            display = `${this.data.labelPrepend}${min} - ${this.data.labelPrepend}${max}`
          }
          this.facetService.addToFacet(this.data.id, { value: [min, max], display: display })
        }),
      )
      .subscribe()

    this.facets$ = this.facetService.facets$
      .pipe(
        filter((facets) => facets.find((f) => f.id === this.data.id) !== undefined),
        tap((facets: Facet[]) => {
          let selected = facets.find((f) => f.id === this.data.id).selected
          if (selected.length === 0) {
            this.form.get('min').setValue(null, { emitEvent: false })
            this.form.get('max').setValue(null, { emitEvent: false })
          }
        }),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    this.range$.unsubscribe()
    this.facets$.unsubscribe()
  }

  ngAfterViewInit(): void {
    if (!this.data?.skipFocus) {
      this.input.nativeElement.focus()
    }
  }

  /**
   * Remove this facet
   *
   * @memberof NumberRangeFacetComponent
   */
  removeFacet = (): void => this.facetService.removeFacet(this.data)
}
