<div class="d-flex">
  <div
    *ngIf="allowCollapse"
    role="button"
    (click)="collapse.toggle()"
    [attr.aria-label]="isCollapsed ? 'Show details' : 'Hide details'"
    [ngClass]="isCollapsed ? 'toggle toggle-collapsed' : 'toggle'"
  ></div>
  <div class="d-flex align-items-center w-100">
    <div (click)="collapse.toggle()" class="clickable flex-grow-1">
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <div class="flex-shrink-1">
      <ng-content select="[slot=actions]"></ng-content>
    </div>
  </div>
</div>
<div
  #collapse="ngbCollapse"
  [(ngbCollapse)]="isCollapsed"
  (ngbCollapseChange)="onToggled.emit(isCollapsed)"
  class="px-4 pb-2"
>
  <ng-content select="[slot=content]"></ng-content>
</div>
