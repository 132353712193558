<div class="row h-100">
  <div class="col-lg-8 d-flex flex-column flex-grow-1">
    <span class="d-flex mb-4">
      <h2 class="flex-grow-1">Task Groups</h2>
      <button class="btn btn-outline-primary d-md-inline-block d-lg-none mr-3" (click)="isOverviewCollapsed = false">
        <i class="fa fa-fw fa-bar-chart"></i>
        Overview
      </button>
      <button class="btn btn-primary" [routerLink]="['/pathfinder/', taskGroups && taskGroups[0]?.id || 'tasks']">
        <i class="fa fa-fw fa-play-circle"></i>
        Start
        <span class="d-none d-md-inline-block">with Highest Value Group</span>
      </button>
    </span>
    <app-search-field
      [label]="'Search task groups'"
      [search]="searchField"
      [searching]="searching"
      class="d-block"
    ></app-search-field>
    <div class="d-flex justify-content-between">
      <div class="flex-grow-1">
        <button
          type="button"
          class="btn btn-sm py-2 btn-outline-alt-steel-blue mr-3"
          (click)="toggleFilters()"
          data-t="filter-button"
        >
          <i class="fa fa-sliders"></i>
          <small class="px-3">Filters</small>
          <span *ngIf="user" class="badge badge-primary d-none d-sm-inline-block d-md-none">1</span>
        </button>
        <span class="badge badge-light p-3 d-none d-md-inline-block text-reset mr-3" *ngIf="user.value">
          User: <span class="font-weight-normal">{{user.value?.name || user.value?.email}}</span>
          <i class="fa fa-fw fa-times ml-3 mr-2 clickable" (click)="user.next(null)"></i>
        </span>
        <span class="badge badge-light p-3 d-none d-md-inline-block text-reset" *ngIf="includeCompleted.value">
          Status: <span class="font-weight-normal">All</span>
          <i class="fa fa-fw fa-times ml-3 mr-2 clickable" (click)="includeCompleted.next(false)"></i>
        </span>
      </div>
      <div ngbDropdown container="body" class="d-flex align-items-center">
        Sort by <button class="btn btn-link" ngbDropdownToggle>Score</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem>Score</button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto">
      <ng-template ngFor let-tg [ngForOf]="taskGroups" let-i="index">
        <app-task-group-overview-card
          [taskGroup]="tg"
          [stats]="taskGroupStats[tg.id]"
          data-t="pathfinder-task-group"
        ></app-task-group-overview-card>
      </ng-template>
      <app-loading
        *ngIf="searching"
        innerClass="fa-4x"
        outerClass="align-self-center text-brand-light-blue"
        class="d-flex flex-column flex-grow-1 justify-content-center"
      ></app-loading>
    </div>
    <div class="d-flex justify-content-center my-5" *ngIf="!isShowingAllResults">
      <button type="button" class="btn btn-primary" (click)="loadMore()" [disabled]="loadingMore">
        Load More
        <app-loading *ngIf="loadingMore" class="pl-2"></app-loading>
      </button>
    </div>
  </div>
  <div class="col-lg-4 d-none d-lg-flex flex-column">
    <h2>Overview</h2>
    <ng-container *ngTemplateOutlet="overview"></ng-container>
  </div>
  <div class="offcanvas-collapse-lg d-md-flex flex-column d-lg-none" [(ngbCollapse)]="isOverviewCollapsed">
    <div class="modal-backdrop fade" [ngClass]="{'show': !isOverviewCollapsed}"></div>
    <div class="bg-white flex-grow-1 p-4 collapse-content">
      <h2>
        <i class="fa fa-fw fa-times clickable" (click)="isOverviewCollapsed = true"></i>
        Overview
      </h2>
      <ng-container *ngTemplateOutlet="overview"></ng-container>
    </div>
  </div>
</div>
<ng-template #overview>
  <div class="card detail-card-new bg-light my-4 border-left-8 border-success">
    <div class="card-header border-0">
      <h4>Completed Tasks <small class="text-muted"> - This Week</small></h4>
    </div>
    <div class="card-body">
      <div class="w-75 mx-auto d-flex justify-content-center">
        <span class="d-flex flex-fill flex-column align-items-center">
          <h5>Mine</h5>
          <span class="h1 d-flex align-items-center">
            {{lastWeeklyValue}}
            <small class="pl-1">
              <i
                class="fa fa-arrow-up text-success"
                [ngClass]="{
               'fa-arrow-up text-success': lastWeeklyValue >= weeklyAverage,
               'fa-arrow-down text-danger': lastWeeklyValue < weeklyAverage
              }"
                [ngbTooltip]="'You average ' + weeklyAverage + ' tasks per week'"
                container="body"
              ></i>
            </small>
          </span>
        </span>
        <span class="d-flex flex-fill flex-column align-items-center border-left" *ngIf="org.isDemo()">
          <h5>My Team</h5>
          <span class="h1 d-flex align-items-center">
            67
            <small class="pl-1">
              <i
                class="fa fa-arrow-up text-success"
                ngbTooltip="Your team averages 63 tasks per week"
                container="body"
              ></i>
            </small>
          </span>
        </span>
      </div>
    </div>
    <span class="position-absolute right-icon see-thru d-flex align-items-center">
      <i class="fa fa-fw fa-4x fa-star-o text-muted"></i>
    </span>
  </div>
  <div class="card detail-card-new bg-light my-4 border-left-8 border-alt-steel-blue">
    <div class="card-header border-0">
      <h4>My Completed Task Trends</h4>
    </div>
    <div class="card-body">
      <canvas
        baseChart
        [datasets]="weeklyCompleted"
        [options]="chartOptions"
        [legend]="chartLegend"
      >
      </canvas>
    </div>
  </div>
</ng-template>
