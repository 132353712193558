import { CdkDropList } from '@angular/cdk/drag-drop'
import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CreateVxPageTypeMutation,
  DeleteVxPageType,
  UpdateVxPageTypeMutation,
  VxPageTypeListQuery,
  VxPageTypeQuery,
} from 'app/vision-x/vision-x.gql'
import {
  MutationDeleteVxPageTypeArgs,
  QueryVxPageTypeArgs,
  QueryVxPageTypesArgs,
  VxPageType,
  VxPageTypeCreate,
  VxPageTypeList,
  VxPageTypeUpdate,
} from 'generated/graphql'
import { Observable } from 'rxjs'

export type FetchVxPageTypeFilters = {
  needsCategorizationTemplates?: boolean
  needsRuleCreation?: boolean
  includeVxDocumentTypeNames?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class VxPageTypeService {
  vxPageTypeDragList: CdkDropList

  constructor(private apollo: Apollo) {}

  fetchVxPageType(id: string): Observable<ApolloQueryResult<{ vxPageType: VxPageType }>> {
    return this.apollo.watchQuery<{ vxPageType: VxPageType }, QueryVxPageTypeArgs>({
      query: VxPageTypeQuery,
      variables: {
        id,
      },
    }).valueChanges
  }

  fetchVxPageTypes(
    search?: string,
    source?: string,
    limit: number = 300,
    offset: number = 0,
    sort: string = 'name:asc',
    includeFieldGroups: boolean = false,
    filters?: FetchVxPageTypeFilters,
  ): Observable<ApolloQueryResult<{ vxPageTypes: VxPageTypeList }>> {
    return this.apollo.watchQuery<
      { vxPageTypes: VxPageTypeList },
      QueryVxPageTypesArgs & {
        includeFieldGroups: boolean
      }
    >({
      fetchPolicy: 'network-only',
      query: VxPageTypeListQuery,
      variables: {
        search,
        source,
        limit,
        offset,
        sort,
        includeFieldGroups,
        ...filters,
      },
    }).valueChanges
  }

  /**
   * Hack-y way of making sure that the file/vxPageType drag/drop sets have references to each other
   */
  setVxPageTypeDragList(val: CdkDropList): void {
    this.vxPageTypeDragList = val
  }

  async createVxPageType(data: VxPageTypeCreate): Promise<VxPageType> {
    const result = await this.apollo
      .mutate<{ createVxPageType: VxPageType }, { data: VxPageTypeCreate }>({
        mutation: CreateVxPageTypeMutation,
        variables: { data },
      })
      .toPromise()
    return result.data?.createVxPageType
  }

  async updateVxPageType(id: string, data: VxPageTypeUpdate): Promise<VxPageType> {
    const result = await this.apollo
      .mutate<{ updateVxPageType: VxPageType }, { id: string; data: VxPageTypeUpdate }>({
        mutation: UpdateVxPageTypeMutation,
        variables: { id, data },
        update(cache, { data: { updateVxPageType: vxPageType } }) {
          cache.modify({
            id: cache.identify({ id: vxPageType?.id, __typename: 'VxPageType' }),
            fields: {
              vxDocumentTypeId() {
                return vxPageType.vxDocumentTypeId
              },
            },
          })
        },
      })
      .toPromise()
    return result.data?.updateVxPageType
  }

  async deleteVxPageType(id: string): Promise<boolean> {
    const result = await this.apollo
      .mutate<{ deleteResult: boolean }, MutationDeleteVxPageTypeArgs>({
        mutation: DeleteVxPageType,
        variables: {
          id,
        },
      })
      .toPromise()
    return result.data?.deleteResult
  }
}
