import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap'
import { debug } from 'app/shared/utils/debug'
import { VxPageType } from 'generated/graphql'
import { defer } from 'lodash'
import { merge, Observable, of, Subject } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators'
import { VxPageTypeService } from 'app/vision-x/services/vx-page-type.service'

@Component({
  selector: 'app-vx-page-type-typeahead',
  templateUrl: './vx-page-type-typeahead.component.html',
  styleUrls: ['./vx-page-type-typeahead.component.scss'],
})
export class VxPageTypeTypeaheadComponent implements OnChanges {
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  @ViewChild('input', { static: true }) input: NgbTypeahead
  @ViewChild('element') element: ElementRef

  @Output() onChange = new EventEmitter<string>()
  @Output() onInput = new EventEmitter<string>()
  @Output() onSelect = new EventEmitter<VxPageType>()
  @Input() container: string = 'body'
  @Input() inputClass: string | string[] | Set<string> | { [klass: string]: any }
  @Input() inputFormControl: UntypedFormControl = new UntypedFormControl()
  @Input() selectedPageType: string = ''
  @Input() clearInputOnSelect: boolean = true

  searching: boolean = false

  constructor(private vxPageTypeService: VxPageTypeService) {}

  ngOnChanges(): void {
    if (this.selectedPageType?.length) {
      setTimeout(() => {
        this.element.nativeElement.value = this.selectedPageType
      })
    }
  }

  /**
   * Get vxPageTypes based on search term in typeahead
   *
   * @param {Observable<string>} text$
   * @memberof VxPageTypeTypeaheadComponent
   */
  search = (text$: Observable<string>): Observable<VxPageType[]> => {
    const debouncedText$ = text$.pipe(debounceTime(500), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.input.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap((search) =>
        this.vxPageTypeService.fetchVxPageTypes(search, undefined, 100).pipe(
          catchError(() => {
            return of({ data: { vxPageTypes: { entities: [] } } })
          }),
          first(),
        ),
      ),
      map((result) => [
        {
          id: null,
          name: 'Uncategorized',
        },
        ...result?.data?.vxPageTypes?.entities,
      ]),
      tap(() => (this.searching = false)),
    )
  }

  /**
   * Format typeahead value in input
   *
   * @param {{ name: string }} x
   * @memberof VxPageTypeTypeaheadComponent
   */
  vxPageTypeFormatter = (x: VxPageType): string => x.name

  /**
   * Notify consumers that a vxPageType has been selected
   *
   * @param {*} event
   * @memberof VxPageTypeTypeaheadComponent
   */
  onSelectVxPageType(event: { item: VxPageType }): void {
    let vxPageType = event.item
    debug('vxPageType', 'selected a vxPageType', vxPageType)
    this.onSelect.emit(vxPageType)
    if (this.clearInputOnSelect) {
      defer(() => this.inputFormControl.setValue(''))
    } else {
      this.inputFormControl.setValue(vxPageType?.name)
    }
  }

  /**
   * Clear typeahead input
   *
   * @param {*} event
   * @memberof VxPageTypeTypeaheadComponent
   */
  clearVxPageType(event: Event): void {
    event.preventDefault()
    this.inputFormControl.setValue('')
    this.onSelect.emit()
  }
}
