<div class="modal-header d-flex justify-content-end">
  <button type="button" class="close mx-2" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="embed-responsive embed-responsive-16by9">
    <video controls autoplay>
      <source [src]="safeUrl" type="video/mp4" />

      Sorry, your browser doesn't support embedded videos.
    </video>
  </div>
</div>
