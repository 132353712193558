import { Component, Input, OnInit } from '@angular/core'
import { PayersService } from 'app/shared/services/payers.service'
import { getPropDetails } from 'app/shared/types/claim'
import { Claim, Payer } from 'generated/graphql'

/**
 * Component to display a claim's payer information
 *
 * @export
 * @class ClaimResponsibilityComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-claim-responsibility',
  templateUrl: './claim-responsibility.component.html',
  styleUrls: ['./claim-responsibility.component.scss'],
})
export class ClaimResponsibilityComponent implements OnInit {
  @Input() claim: Claim

  secondaryPayer: Payer
  tertiaryPayer: Payer

  getPropDetails = getPropDetails

  constructor(private payersService: PayersService) {}

  async ngOnInit(): Promise<void> {
    if (this.claim?.secondaryPayerId) {
      this.secondaryPayer = await this.payersService.getPayer(this.claim.secondaryPayerId)
    }
    if (this.claim?.tertiaryPayerId) {
      this.tertiaryPayer = await this.payersService.getPayer(this.claim.tertiaryPayerId)
    }
  }
}
