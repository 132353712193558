import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { OrgService } from 'app/admin/org/org.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { ProcedureSetsService } from 'app/procedure-sets/procedure-sets.service'
import { OrgSwitcherModalComponent } from 'app/shared/components/org-switcher-modal/org-switcher-modal.component'
import { DecryptService } from 'app/shared/services/decrypt.service'
import { ToastService } from 'app/shared/services/toast.service'
import { ZendeskService } from 'app/shared/services/zendesk.service'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { environment } from 'environments/environment'
import { ProcedureSet } from 'generated/graphql'
import { Subject, of } from 'rxjs'
import { catchError, filter, first, map, switchMap, takeUntil } from 'rxjs/operators'

type Row = {
  serviceDates: { start: Date; end: Date }
  payer: string
  procedures: { code: string; description: string }[]
  procedureSet: ProcedureSet
  teleportUrl: string
  isLoading: boolean
  tooltip: string
  requiresPriorAuth: boolean
}

@Component({
  selector: 'app-prior-auth-page',
  templateUrl: './prior-auth.page.html',
  styleUrls: ['./prior-auth.page.scss'],
})
export class PriorAuthPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()
  isLoading: boolean = true
  rows: Row[] = []
  mrn: string = ''
  patientName: string = ''
  private modalRef?: NgbModalRef
  currentOrg: string = null
  shouldShowOrgName: boolean

  constructor(
    private orgService: OrgService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private procedureSetsService: ProcedureSetsService,
    private decryptService: DecryptService,
    private modal: NgbModal,
    public authenticationService: AuthenticationService,
    protected zendesk: ZendeskService,
  ) {
    let lazyMrn: string
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd
        }),
        map(() => this.route.snapshot.paramMap.get('patientMrn')),
        switchMap((maybeEncrypted) => {
          return this.decryptService.getEpicDecrypted(maybeEncrypted)
        }),
        switchMap((patientMrn) => {
          lazyMrn = patientMrn
          return this.procedureSetsService.getPatientForProcedureSet(patientMrn).pipe(
            catchError((e) => {
              this.toast.error(parseGraphQLError(e, 'Could not fetch patient'), JSON.stringify(e))
              return of('')
            }),
            takeUntil(this.destroy$),
          )
        }),
        catchError((e) => {
          this.toast.error(parseGraphQLError(e, 'Could not fetch procedures'), JSON.stringify(e))
          return of('')
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(async (patientName) => {
        this.mrn = lazyMrn
        this.patientName = patientName
        this.isLoading = false
      })
  }

  changeOrgModalAppears(): void {
    this.modalRef = this.modal.open(OrgSwitcherModalComponent, {
      centered: true,
      windowClass: 'modal-extra-padding',
      size: 'lg',
      backdrop: true,
      keyboard: false,
    })

    this.modalRef.componentInstance.redirect = false
  }

  async ngOnInit(): Promise<void> {
    try {
      this.shouldShowOrgName = !environment.production

      if (this.shouldShowOrgName) {
        const orgResult = await this.orgService
          .getOrg(this.authenticationService.getUser()?.orgId)
          .pipe(first())
          .toPromise()
        this.currentOrg = orgResult?.data?.organization.name
      }
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find the org'), JSON.stringify(e))
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
