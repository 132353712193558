<div class="modal-header">
  <h4 class="modal-title">Session Expiring</h4>
</div>

<div class="modal-body">
  <p>For security purposes your session will soon time out due to inactivity.</p>
  <p>
    Session ending in <strong>{{ countdown$ | async }} seconds</strong>.
  </p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-brand-light-blue" (click)="keepalive()" [disabled]="!(isCountingDown$ | async)">
    Resume
  </button>
  <button type="submit" class="btn btn-outline-secondary" (click)="logout()" [disabled]="!(isCountingDown$ | async)">
    Log out
  </button>
</div>
