import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import { DeleteReport, GetReport, GetReports } from 'app/reports/report.gql'
import {
  ListResponseMetaData,
  MutationDeleteReportArgs,
  QueryReportArgs,
  QueryReportsArgs,
  Report,
  ReportList,
} from 'generated/graphql'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private meta: ListResponseMetaData

  constructor(private http: HttpClient, private apollo: Apollo) {}

  getMeta(): ListResponseMetaData {
    return this.meta
  }

  private get API_URL() {
    return '/api'
  }

  getReport(id: string): Observable<ApolloQueryResult<{ report: Report }>> {
    return this.apollo.watchQuery<{ report: Report }, QueryReportArgs>({
      query: GetReport,
      variables: {
        id,
      },
    }).valueChanges
  }

  getReports(variables?: QueryReportsArgs): Observable<ApolloQueryResult<{ reports: ReportList }>> {
    return this.apollo
      .watchQuery<{ reports: ReportList }, QueryReportsArgs>({
        query: GetReports,
        variables,
      })
      .valueChanges.pipe(
        tap((res) => {
          this.meta = res?.data?.reports?.meta
        }),
      )
  }

  async setReport(file: File, name: string, category: string): Promise<any> {
    let data = new FormData()
    data.append('file', file, file.name)
    data.append('name', name)
    data.append('category', category)
    return this.http
      .post(`${this.API_URL}/reports`, data, {
        withCredentials: true,
      })
      .toPromise()
      .then((data: Report) => {
        let cache = this.apollo.client.cache
        let existingReports = cache.readQuery<{ reports: ReportList }, QueryReportsArgs>({
          query: GetReports,
          variables: {
            search: '',
            limit: 25,
          },
        })
        let newReport: Report = {
          __typename: 'Report',
          id: data.id,
          name: data.name,
          link: data.link,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        }
        cache.writeQuery({
          query: GetReports,
          variables: {
            search: '',
            limit: 25,
          },
          data: { reports: [newReport, ...existingReports?.reports?.entities] },
        })
        return data
      })
  }

  async deleteReport(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ deleteReport: boolean }, MutationDeleteReportArgs>({
        mutation: DeleteReport,
        variables: {
          id: id,
        },
        update: (store) => {
          let cache = this.apollo.client.cache
          cache.evict({ id: cache.identify({ id: id, __typename: 'Report' }) })
          cache.gc()
        },
      })
      .toPromise()

    return result.data.deleteReport
  }
}
