<ng-template ngFor let-user [ngForOf]="users" let-i="index">
  <button
    class="btn btn-link text-dark p-0"
    (click)="remove($event, user)"
    (mouseenter)="isHoveredOver[i] = true"
    (mouseleave)="isHoveredOver[i] = false"
    ngbTooltip="Unassign User"
    container="body"
    *ngIf="canEdit; else avatar"
  >
    <app-user-avatar
      class="flex-shrink-1 mr-3"
      [ngClass]="isHoveredOver[i] ? 'd-none' : ''"
      [user]="user"
      size="md"
      [randomColor]="true"
      [showTooltip]="false"
    ></app-user-avatar>
    <span class="h-100 w-100 p-4 mr-3 rounded-circle bg-danger" *ngIf="isHoveredOver[i]">
      <i class="fa fa-lg fa-close px-1"></i>
    </span>
  </button>
  <ng-template #avatar>
    <app-user-avatar
      class="flex-shrink-1 mr-3"
      [user]="user"
      size="sm"
      [randomColor]="true"
      [showTooltip]="false"
    ></app-user-avatar>
  </ng-template>
</ng-template>
<button
  *ngIf="canEdit"
  (click)="add($event)"
  class="btn btn-outline-primary p-3 rounded-circle"
  ngbTooltip="Assign User"
  container="body"
>
  <span class="sr-only">Assign a User</span>
  <i class="fa fa-plus fa-fw fa-lg"></i>
</button>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Assign User to Task Group</h5>
  </div>
  <div class="modal-body">
    <app-user-search (onSelect)="addUser($event)" [disabledUserIds]="userIds" [clearInput]="true"></app-user-search>
  </div>
</ng-template>
