import { UntypedFormGroup } from '@angular/forms'

/**
 * Helper function to produce an object detailing
 * a FormGroup's controls that are in an error state
 *
 * TODO: does NOT deal with nested FormGroups!
 *
 * @export
 * @param {FormGroup} formGroup
 * @return {*}  {{ [field: string]: string }}
 */
export function readableFormGroupErrors(formGroup: UntypedFormGroup): { [field: string]: string } {
  return Object.keys(formGroup.controls)
    .filter((k) => formGroup.get(k).invalid)
    .reduce((prev, cur) => {
      let errors = Object.keys(formGroup.get(cur).errors ?? {}).join(', ')
      prev[cur] = errors
      return prev
    }, {})
}
