import { Component, OnInit } from '@angular/core'
import { Opportunity } from 'app/illuminate/opportunities'
import { OpportunitiesService } from 'app/illuminate/opportunities.service'
import { CumulativeOpportunityData } from 'app/illuminate/opportunities/cumulativeOpportunityData'

/**
 * Page to display a list of opportunities
 *
 * @export
 * @class OpportunitiesPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.page.html',
  styleUrls: ['./opportunities.page.scss'],
  host: {
    class: 'd-flex flex-column h-100',
  },
})
export class OpportunitiesPage implements OnInit {
  opportunities: Opportunity[] = []
  cumulativeData: CumulativeOpportunityData = {}
  selectedOpportunity: string

  expandedView: boolean = false

  constructor(private opportunitiesService: OpportunitiesService) {}

  ngOnInit(): void {
    this.opportunities = this.opportunitiesService.getOpportunities()
    this.cumulativeData = this.opportunitiesService.getCumulativeOpportunityData(this.opportunities)
  }

  /**
   * Respond to user selecting an opportunity in the chart
   *
   * @param {string} id
   * @memberof OpportunitiesPage
   */
  setSelectedOpportunity(id: string): void {
    this.selectedOpportunity = id
  }
}
