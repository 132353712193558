/**
 * Sleep for a given number of milliseconds
 *
 * @param   {number<void>}   ms  [ms description]
 *
 * @return  {Promise<void>}      [return description]
 */
export const sleep = async function (ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms))
}
