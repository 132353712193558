import { Injectable, TemplateRef } from '@angular/core'
import { NgbToast } from '@ng-bootstrap/ng-bootstrap'
import { debug } from '../utils/debug'

/**
 * Display and manage toast notifications
 *
 * @export
 * @class ToastService
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private window: Window) {
    this.window.onmessage = (event) => {
      if (event.data === 'desktop-notification') {
        this.port = event.ports[0]
      }
    }
  }

  toasts: any[] = []
  private class = {
    success: 'bg-success text-light',
    error: 'bg-danger text-light',
    info: 'bg-info text-light',
    warning: 'bg-warning text-light',
  }
  port: MessagePort = null

  /**
   * Display a success toast
   *
   * @param {(string | TemplateRef<any>)} textOrTpl
   * @param {string} [header]
   * @memberof ToastService
   */
  success(textOrTpl: string | TemplateRef<any>, header?: string): void {
    this.show(textOrTpl, header || null, {
      classname: this.class.success,
      delay: 2000,
      type: 'success',
      autohide: true,
    })
  }

  /**
   * Display an error toast
   *
   * @param {(string | TemplateRef<any>)} textOrTpl
   * @param {*} [error]
   * @memberof ToastService
   */
  error(textOrTpl: string | TemplateRef<any>, error?: string): void {
    this.show(textOrTpl, null, { classname: this.class.error, type: 'error', autohide: true, error: error })
  }

  /**
   * Display an information toast
   *
   * @param {(string | TemplateRef<any>)} textOrTpl
   * @param {string} [header]
   * @memberof ToastService
   */
  info(textOrTpl: string | TemplateRef<any>, header?: string, options?: { autohide?: boolean }): void {
    const { autohide } = options || {}

    this.show(textOrTpl, header || null, { classname: this.class.info, autohide, type: 'info' })
  }

  /**
   * Remove a toast from display
   *
   * @param {*} toast
   * @memberof ToastService
   */
  remove(toast: NgbToast): void {
    this.toasts = this.toasts.filter((t) => t !== toast)
  }

  /**
   * Add a toast for display;
   * Or, in the desktop app,
   * post a message that the app picks up to use in a system notification
   *
   * @private
   * @param {(string | TemplateRef<any>)} textOrTpl
   * @param {(string | null)} header
   * @param {*} [options={}]
   * @memberof ToastService
   */
  private show(textOrTpl: string | TemplateRef<any>, header: string | null, options: any = {}): void {
    if (this.port) {
      this.port.postMessage(JSON.stringify({ title: textOrTpl, options }))
    } else {
      let toastOptions = { textOrTpl, header, ...options }
      debug('toast', 'adding toast', toastOptions)
      this.toasts.push(toastOptions)
    }
  }
}
