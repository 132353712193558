import { Component, Input, OnInit } from '@angular/core'
import { Procedure } from 'generated/graphql'

@Component({
  selector: 'app-contact-payer-modal-dropdown',
  templateUrl: './contact-payer-modal-dropdown.component.html',
  styleUrls: ['./contact-payer-modal-dropdown.component.scss'],
})
export class ContactPayerModalDropdownComponent implements OnInit {
  @Input() procedure: Procedure

  options: { display: string; value: string }[]
  changeable: boolean = false

  constructor() {
    this.options = [
      { display: 'Auth Required', value: 'ARC' },
      { display: 'Auth Not Required', value: 'NA' },
      { display: 'Contact Payer', value: 'CT' },
    ]
  }

  ngOnInit(): void {
    this.changeable = this.procedure.status === 'CT'
  }
}
