import { Component, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { WorkflowService } from 'app/admin/workflows/workflow.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ListResponseMetaData, WorkflowType } from 'generated/graphql'

/**
 * Page to display and search a list of workflow types
 *
 * @export
 * @class WorkflowListPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-workflow-list',
  templateUrl: './workflow-list.page.html',
  host: { class: 'h-100' },
})
export class WorkflowListPage implements OnInit {
  workflowTypes: WorkflowType[]
  query = new UntypedFormControl('')
  lastSearch: string
  isLoading = false
  meta: ListResponseMetaData
  page: number = 1
  pageSize: number = 25
  workflowCopyName = new UntypedFormControl()
  workflowIdToCopy: string
  isSavingWorkflowCopy = false

  constructor(private workflowService: WorkflowService, private toast: ToastService, private router: Router) {}

  ngOnInit(): void {
    this.getWorkflowTypes(null, 1)
  }

  /**
   * Load a list of workflow types
   *
   * @param {string} query
   * @param {number} page
   * @return {*}  {Promise<void>}
   * @memberof WorkflowListPage
   */
  async getWorkflowTypes(query: string, page: number): Promise<void> {
    this.isLoading = true
    this.workflowTypes = []
    this.meta = null
    this.lastSearch = query
    let variables = {
      search: query,
      offset: (page - 1) * this.pageSize,
      limit: this.pageSize,
    }
    debug('workflow', 'loading workflow types', variables)
    try {
      this.workflowTypes = await this.workflowService.getWorkflowTypes(variables)
      this.meta = this.workflowService.getMeta()
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not load workflows'), JSON.stringify(e))
    }
    this.isLoading = false
  }

  /**
   * Launch popover for copying a workflow type
   *
   * @param {WorkflowType} workflowType
   * @memberof WorkflowListPage
   */
  openedCopyWorkflow(workflowType: WorkflowType): void {
    this.workflowCopyName.setValue(`${workflowType.name} copy`)
    this.workflowIdToCopy = workflowType.id
  }

  /**
   * Copy an existing workflow type and navigate to newly created type
   *
   * @return {*}  {Promise<void>}
   * @memberof WorkflowListPage
   */
  async copyWorkflow(): Promise<void> {
    this.isSavingWorkflowCopy = true
    try {
      let newWorkflow = await this.workflowService.copyWorkflowType(this.workflowIdToCopy, this.workflowCopyName.value)
      this.toast.success('Successfully copied workflow')
      this.router.navigate(['admin', 'workflows', newWorkflow.id])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not copy workflow'), JSON.stringify(e))
    }
    this.isSavingWorkflowCopy = false
  }
}
