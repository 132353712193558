export enum PROPTYPE {
  DATE,
  CURRENCY,
}

export type PropertyDef = {
  property: string
  label: string // full label
  order?: number
  sortable?: boolean
  heading?: string // if column heading display is different from full label
  parentHeading?: string // if present, multi tier column header
  type?: PROPTYPE // for angular pipe in table
  facet?: string // if facet title display is different from full label
  tooltip?: string // tooltip on column heading / facet title
}

/**
 * Helper to get claim property details based on property name
 *
 * @param {string} id
 * @return {*}  {PropertyDef}
 */
export const getPropDetails = (id: string): PropertyDef => {
  return ClaimProperties.find((cp) => cp.property === id)
}

export const ClaimProperties: PropertyDef[] = [
  {
    order: 0, //*
    property: 'providerClaimId',
    label: 'Claim ID',
    sortable: true,
  },
  {
    order: 5, //*
    property: 'ehrStatusCode',
    label: 'EHR Status',
    sortable: true,
  },
  {
    order: 10, //*
    property: 'icn',
    label: 'ICN',
  },
  {
    order: 20,
    property: 'patientMrn',
    label: 'Patient MRN',
    heading: 'MRN',
    parentHeading: 'Patient',
  },
  {
    order: 21, //*
    property: 'patientName',
    label: 'Patient Name',
    heading: 'Name',
    parentHeading: 'Patient',
  },
  {
    order: 22, //*
    property: 'patientDob',
    label: 'Patient DOB',
    heading: 'DOB',
    parentHeading: 'Patient',
    type: PROPTYPE.DATE,
  },
  {
    order: 23, //*
    property: 'patientSex',
    label: 'Patient Sex',
    heading: 'Sex',
    parentHeading: 'Patient',
  },
  {
    order: 30, //*
    property: 'subscriberId',
    label: 'Subscriber ID',
  },
  {
    order: 31,
    property: 'memberId',
    label: 'Member ID',
  },
  {
    order: 40, //*
    property: 'serviceDate',
    label: 'Service Date',
    type: PROPTYPE.DATE,
    sortable: true,
  },
  {
    order: 50,
    property: 'submissionDate',
    label: 'Submission Date',
    type: PROPTYPE.DATE,
  },
  {
    order: 60, //*
    property: 'claimAmount',
    label: 'Original Amount',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 70, //*
    property: 'outstandingAmount',
    label: 'Outstanding Amount',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 70, //*
    property: 'predictedRemainingAmount',
    label: 'Predicted Remaining Amount',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 80, //*
    property: 'payer.name',
    label: 'Primary Payer',
  },
  {
    order: 81,
    property: 'secondaryPayer.name',
    label: 'Secondary Payer',
  },
  {
    order: 82,
    property: 'tertiaryPayer.name',
    label: 'Tertiary Payer',
  },
  {
    order: 83,
    property: 'plan.planId',
    label: 'Plan ID',
  },
  {
    order: 84,
    property: 'plan.name',
    label: 'Plan Name',
  },
  {
    order: 85,
    property: 'plan.groupNumber',
    label: 'Group Number',
  },
  {
    order: 100,
    property: 'taxId',
    label: 'Provider Tax ID',
  },
  {
    order: 110, // Need attention to this in ES
    property: 'claimSet.claim.providerClaimId',
    label: 'Related Claims',
  },
  {
    order: 120,
    property: 'authNum',
    label: 'Authorization Number',
  },
  {
    order: 140,
    property: 'providerBilling.name',
    label: 'Billing Provider Name',
    heading: 'Name',
    parentHeading: 'Billing Provider',
  },
  {
    order: 141,
    property: 'providerBilling.npi',
    label: 'Billing Provider NPI',
    heading: 'NPI',
    parentHeading: 'Billing Provider',
  },
  {
    order: 142,
    property: 'providerBilling.taxId',
    label: 'Billing Tax ID',
  },
  {
    order: 150,
    property: 'providerAttending.name',
    label: 'Attending Provider Name',
    heading: 'Name',
    parentHeading: 'Attending Provider',
  },
  {
    order: 151,
    property: 'providerAttending.npi',
    label: 'Attending Provider NPI',
    heading: 'NPI',
    parentHeading: 'Attending Provider',
  },
  {
    order: 170,
    property: 'providerReferring.name',
    label: 'Referring Provider Name',
    heading: 'Name',
    parentHeading: 'Referring Provider',
  },
  {
    order: 171,
    property: 'providerReferring.npi',
    label: 'Referring Provider NPI',
    heading: 'NPI',
    parentHeading: 'Referring Provider',
  },
  {
    order: 180,
    property: 'providerRendering.name',
    label: 'Rendering Provider Name',
    heading: 'Name',
    parentHeading: 'Rendering Provider',
  },
  {
    order: 181,
    property: 'providerRendering.npi',
    label: 'Rendering Provider NPI',
    heading: 'NPI',
    parentHeading: 'Rendering Provider',
  },
  {
    order: 190,
    property: 'servicelines.categoryCodes.code', // also merge claim level
    label: 'Claim Status Category Codes',
    heading: 'Category Codes', // use in claim deets view
    parentHeading: 'Claim Status',
  },
  {
    order: 191,
    property: 'servicelines.categoryCodes.description',
    label: 'Claim Status Category Text',
    heading: 'Category Text',
    parentHeading: 'Claim Status',
  },
  {
    order: 192,
    property: 'servicelines.claimStatusCodes.code',
    label: 'Claim Status Codes',
    heading: 'Codes',
    parentHeading: 'Claim Status',
  },
  {
    order: 193,
    property: 'servicelines.claimStatusCodes.description',
    label: 'Claim Status Text',
    heading: 'Text',
    parentHeading: 'Claim Status',
  },
  {
    order: 194,
    property: 'servicelines.payerStatusCodes.code', // also merge claim level payerStatusCodes
    label: 'Claim Status Payer Non-standard Codes',
    heading: 'Payer Codes',
    parentHeading: 'Claim Status',
    tooltip: 'Non-standard',
  },
  {
    order: 195,
    property: 'servicelines.payerStatusCodes.description',
    label: 'Claim Status Payer Non-standard Text',
    heading: 'Payer Text',
    parentHeading: 'Claim Status',
    tooltip: 'Non-standard',
  },
  {
    order: 196,
    property: 'servicelines.ehrStatusCodes.code', // also merge claim level
    label: 'EHR Status Codes',
    heading: 'EHR Codes',
    tooltip: 'Internal to EHR Status',
  },
  {
    order: 197,
    property: 'servicelines.ehrStatusCodes.description',
    label: 'EHR Status Text',
    tooltip: 'Internal to EHR Status',
  },
  {
    order: 198,
    property: 'servicelines.claimAdjustmentReasonCodes.code',
    label: 'EOB Reason Codes',
    heading: 'Reason Codes',
    parentHeading: 'EOB Codes',
    tooltip: 'Claim Adjustment Reason Codes',
  },
  {
    order: 199,
    property: 'servicelines.claimAdjustmentReasonCodes.description',
    label: 'EOB Reason Text',
    heading: 'Reason Text',
    parentHeading: 'EOB Codes',
    tooltip: 'Claim Adjustment Reason Codes Text',
  },
  {
    order: 200,
    property: 'servicelines.remitAdviceRemarkCodes.code', // also merge claim level
    label: 'EOB Remark Codes',
    heading: 'Remark Codes',
    parentHeading: 'EOB Codes',
    tooltip: 'Remit Advice Remark Codes',
  },
  {
    order: 201,
    property: 'servicelines.remitAdviceRemarkCodes.description',
    label: 'EOB Remark Text',
    heading: 'Remark Text',
    parentHeading: 'EOB Codes',
    tooltip: 'Remit Advice Remark Codes Text',
  },
  {
    order: 210,
    property: 'department',
    label: 'Department',
  },
  {
    order: 220,
    property: 'servicelines.dateOfServiceStart',
    label: 'Service Start Date',
    type: PROPTYPE.DATE,
  },
  {
    order: 230,
    property: 'servicelines.dateOfServiceEnd',
    label: 'Service End Date',
    type: PROPTYPE.DATE,
  },
  {
    order: 240,
    property: 'servicelines.modifier',
    label: 'Modifier',
  },
  {
    order: 250,
    property: 'servicelines.cpt',
    label: 'Procedure ID',
    tooltip: 'CPT or HCPCS Codes',
  },
  {
    order: 260,
    property: 'servicelines.diagnosis',
    label: 'Diagnosis',
  },
  {
    order: 270,
    property: 'servicelines.chargeAmount',
    label: 'Charge Amount',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 279,
    property: 'ehrPayments.paidAmount',
    label: 'Payments from EHR Paid Amount',
    heading: 'Paid',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 280,
    property: 'ehrPayments.postDate',
    label: 'Payments from EHR Posted Date',
    heading: 'Posted Date',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.DATE,
  },
  {
    order: 281,
    property: 'ehrPayments.coveredAmount',
    label: 'Payments from EHR Covered Amount',
    heading: 'Covered',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 282,
    property: 'ehrPayments.nonCoveredAmount',
    label: 'Payments from EHR Non-Covered Amount',
    heading: 'Non-Covered',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 283,
    property: 'ehrPayments.copayAmount',
    label: 'Payments from EHR Co-Pay Amount',
    heading: 'Co-Pay',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 283,
    property: 'ehrPayments.coinsAmount',
    label: 'Payments from EHR Co-Insurance Amount',
    heading: 'Co-Insurance',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 283,
    property: 'ehrPayments.deductibleAmount',
    label: 'Payments from EHR Deductible Amount',
    heading: 'Deductible',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 284,
    property: 'ehrPayments.cobAmount',
    label: 'Payments from EHR COB Amount',
    heading: 'COB',
    parentHeading: 'Payments from EHR',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 285,
    property: 'ehrPayments.checkNumber',
    label: 'Payments from EHR Check Number',
    heading: 'Check Number',
    parentHeading: 'Payments from EHR',
  },
  {
    order: 290,
    property: 'payerPayments.paidAmount',
    label: 'Payments from Payer Paid Amount',
    heading: 'Paid',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 291,
    property: 'payerPayments.postDate',
    label: 'Payments from Payer Posted Date',
    heading: 'Posted Date',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.DATE,
  },
  {
    order: 292,
    property: 'payerPayments.coveredAmount',
    label: 'Payments from Payer Covered Amount',
    heading: 'Covered',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 293,
    property: 'payerPayments.nonCoveredAmount',
    label: 'Payments from Payer Non-Covered Amount',
    heading: 'Non-Covered',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 294,
    property: 'payerPayments.copayAmount',
    label: 'Payments from Payer Co-Pay Amount',
    heading: 'Co-Pay',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 295,
    property: 'payerPayments.coinsAmount',
    label: 'Payments from Payer Co-Insurance Amount',
    heading: 'Co-Insurance',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 296,
    property: 'payerPayments.deductibleAmount',
    label: 'Payments from Payer Deductible Amount',
    heading: 'Deductible',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 297,
    property: 'payerPayments.cobAmount',
    label: 'Payments from Payer COB Amount',
    heading: 'COB',
    parentHeading: 'Payments from Payer',
    type: PROPTYPE.CURRENCY,
  },
  {
    order: 298,
    property: 'payerPayments.checkNumber',
    label: 'Payments from Payer Check Number',
    heading: 'Check Number',
    parentHeading: 'Payments from Payer',
  },
  {
    order: 300,
    property: 'tasks.taskType.name',
    label: 'Task Name',
    heading: 'Tasks',
    parentHeading: 'Tasks',
  },
  {
    order: 301,
    property: 'tasks.completionType',
    label: 'Task Completion Type',
    heading: 'Completion Type',
    parentHeading: 'Tasks',
  },
  {
    order: 302,
    property: 'tasks.completedBy',
    label: 'Tasks Completed By User',
    heading: 'Completed By',
    parentHeading: 'Tasks',
  },
  {
    order: 303,
    property: 'tasks.completedDate',
    label: 'Task Completion Date',
    heading: 'Completion Date',
    parentHeading: 'Tasks',
    type: PROPTYPE.DATE,
  },
  {
    order: 304,
    property: 'harId',
    label: 'HAR ID',
  },
]

/* The below is notes on fields we cannot show / access right now
  'Supervising Provider Name': 'providerSupervising.name',
  'Supervising Provider NPI': 'providerSupervising.npi',
  'Resource Provider Name': 'providerResource.name', // not populated
  'Resource Provider NPI': 'providerResource.npi', // not populated
  'Appointment Provider Name': 'providerAppointment.name',
  'Appointment Provider NPI': 'providerAppointment.npi',
  'Primary Insurance': 'primaryInsurance', // Insurance = Payer in Epic
  'Secondary Insurance': 'secondaryInsurance', // not accessible
  'Check/EFT #': 'checkNumber', // both have meaning, this one has most data but will be DED - how do we handle this?  If serviceline, show that data & if no servicelines, show claim data?
  'Check Date': 'checkDate', // same as above
  'Total Paid amount': 'paidAmount', // will be DED
  'Check amount': 'checkAmount', // same answer as above
  'Plan Name': 'plan.name', // not info data from dump to have plan info
  'Denial Date': 'denialDate', // DED - is same as SOME data on servicelines?
*/

/* other data...
  current / primary / secondary
  plan ( type )
  denialDate = paymentDate ( $0 payment ) ... most recent denial date in UI!!! ... possible payment flag of denial | payment | recoupment
*/
