<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-grow-1">
    <span class="d-flex mb-4">
      <ng-container *ngIf="!isEditingGroupName; else editMode">
        <h2 class="flex-grow-1 cursor-pointer" (click)="startEditingGroupName()">{{ payerPlanGroupName }}</h2>
      </ng-container>
      <ng-template #editMode>
        <div class="col-3">
        <input
          type="text"
          class="form-control"
          container="body"
          [(ngModel)]="editedGroupNameValue"
          (blur)="saveGroupNameChanges()"
          (keyup.enter)="saveGroupNameChanges()"
        />
      </div>
      </ng-template>
    </span>
    <div class="d-flex justify-content-between row">
      <div class="col-sm">
        <div class="input-group form-group">
          <div class="col-3">
            <p>Payer Name</p>
            <input type="text" [formControl]="payerNameSearch" class="form-control" container="body" placeholder="Search payers" />
          </div>
          <div class="col-3">
            <p>Plan Name</p>
            <input type="text" [formControl]="planNameSearch" class="form-control" container="body" placeholder="Search plans" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between row">
      <div class="col">
        <div class="input-group form-group">
          <div class="col-3">
            <p>Payer Code</p>
            <input type="text" [formControl]="payerCodeSearch" class="form-control" container="body" placeholder="Search payer codes" />
          </div>
          <div class="col-3 d-flex flex-column">
            <div class="d-flex flex-grow-1"></div>
            <div class="btn-group text-nowrap">
              <button
                type="button"
                [ngClass]="{'bg-alt-light-blue': planMode === 'remove', 'bg-alt-light-gray': planMode !== 'remove'}"
                class="btn text-alt-white"
                id="currentPlansBtn"
                (click)="setPlanMode('remove')"
              >
                Current Plans
              </button>
              <button
                type="button"
                [ngClass]="{'bg-alt-light-blue': planMode === 'add', 'bg-alt-light-gray': planMode !== 'add'}"
                class="btn text-alt-white"
                id="availablePlansBtn"
                (click)="setPlanMode('add')"
              >
                Available Plans
              </button>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <div class="d-flex flex-grow-1"></div>
            <div class="d-flex mt-auto">
              <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table">
      <thead class="bg-alt-light-blue text-alt-white">
        <tr>
          <th sortable="payerName" (sort)="onSort($event)" direction="asc" scope="col" class="width-1 text-nowrap">Payer Name</th>
          <th sortable="planName" (sort)="onSort($event)" direction="asc" scope="col" class="width-1 text-nowrap">Plan Name</th>
          <th sortable="payerCode" (sort)="onSort($event)" scope="col" class="width-1 text-nowrap">Payer Code</th>
          <th scope="col" class="width-1 text-nowrap">Current Group</th>
          <th scope="col" class="width-1 text-nowrap text-center">
            {{ planMode === 'remove' ? 'Remove Plan' : 'Add Plan' }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payerPlan of payerPlans">
          <td class="width-1 text-nowrap small">{{ payerPlan.payerName }}</td>
          <td class="width-1 text-nowrap small">{{ payerPlan.planName }}</td>
          <td class="width-1 text-nowrap small">{{ payerPlan.payerCode }}</td>
          <td class="width-1 text-nowrap small">{{ payerPlan.payerPlanGroupBridges[0]?.payerPlanGroup?.name }}</td>
          <td class="width-1 text-nowrap text-center">
            <div class="d-flex justify-content-center">
              <label class="checkmark-container">
                <input
                  class="checkbox"
                  type="checkbox"
                  [checked]="isChecked(payerPlan.id)"
                  (change)="onCheckboxChange($event, payerPlan)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <app-loading
      *ngIf="isLoading"
      innerClass="fa-4x"
      outerClass="align-self-center text-primary"
      class="d-flex flex-column flex-grow-1 justify-content-center"
    ></app-loading>
    <div class="d-flex justify-content-end row">
      <div class="col-3 d-flex justify-content-end">
        <app-pagination *ngIf="meta" [metaData]="meta" (fetchPage)="changePage($event)"></app-pagination>
      </div>
    </div>
    <!-- here -->
    <div class="d-flex justify-content-start row">
      <div class="input-group form-group button-container">
        <div class="button-fixed">
          <button class="btn bg-alt-light-blue text-alt-white text-nowrap" [disabled]="!addList.length && !removeList.length && !updateList.length" (click)="openConfirmSaveModal()">Save</button>
        </div>
        <div class="button-fixed">
          <button class="btn bg-alt-light-gray text-alt-white text-nowrap" [disabled]="!addList.length && !removeList.length && !updateList.length" (click)="openConfirmClearModal()">Clear</button>
        </div>
        <div class="button-fixed">
          <button class="btn bg-alt-red text-alt-white text-nowrap" (click)="openConfirmDeleteModal()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>
