import { Pipe, PipeTransform } from '@angular/core'
import startCase from '../utils/startCase'

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    const titleCase = startCase(value)
    return titleCase
      .split(' ')
      .map((word, index) => {
        if (index === 0 || word.charAt(word.length - 1).match(/[A-Z]/)) {
          return word
        }
        return word.toLowerCase()
      })
      .join(' ')
  }
}
