<div class="d-flex mb-5 overflow-auto">
  <app-cumulative-opportunity-stat
    *ngIf="cumulativeData.forcastedValue"
    title="Forecasted Value"
    [amount]="82300000"
    icon="fa-calendar"
    [dollars]="true"
    class="ml-auto"
    iconColor="text-brand-green"
  ></app-cumulative-opportunity-stat>
  <app-cumulative-opportunity-stat
    *ngIf="cumulativeData.actualValue"
    title="Actual Value"
    [amount]="cumulativeData.actualValue"
    icon="fa-money"
    [dollars]="true"
    iconColor="text-alt-steel-blue"
  ></app-cumulative-opportunity-stat>
  <app-cumulative-opportunity-stat
    title="Events Reviewed"
    [amount]="45200000"
    icon="fa-file-text"
    iconColor="text-alt-steel-blue"
  ></app-cumulative-opportunity-stat>
  <app-cumulative-opportunity-stat
    title="Workflows Mapped"
    [amount]="102"
    icon="fa-search"
    iconColor="text-alt-steel-blue"
  ></app-cumulative-opportunity-stat>
  <app-cumulative-opportunity-stat
    title="Interventions Live"
    [amount]="44"
    iconColor="text-brand-green"
    icon="fa-clock-o"
  ></app-cumulative-opportunity-stat>
  <app-cumulative-opportunity-stat
    title="Candidates for Automation"
    [amount]="78"
    janusFontIconName="d"
    iconColor="text-alt-steel-blue"
    class="mr-auto"
  ></app-cumulative-opportunity-stat>
</div>

<div class="mx-0 d-flex flex-column flex-grow-1 flex-basis-0">
  <div class="d-flex flex-column flex-grow-1 flex-basis-0 overflow-auto table-responsive-md">
    <table class="table table-janus table-striped" data-t="opportunities-table">
      <thead>
        <tr class="parent-headings">
          <th [colSpan]="5" class="text-left border-right bg-alt-medium-gray text-brand-gray align-middle">
            <div class="d-flex">
              <label class="mr-2">Condensed</label>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input toggle-color"
                  id="expandedViewSwitch"
                  [(ngModel)]="expandedView"
                />
                <label class="custom-control-label" for="expandedViewSwitch">Expanded</label>
              </div>
            </div>
          </th>
          <th class="border-right bg-brand-dark-gray align-middle text-brand-gray" [colSpan]="2">
            ANNUAL WORKFLOW IMPROVEMENT
          </th>
          <th *ngIf="expandedView" class="border-right bg-alt-medium-gray align-middle text-brand-gray" [colSpan]="3">
            ANNUAL CASH YIELD
          </th>
          <th *ngIf="expandedView" class="border-right bg-alt-medium-gray align-middle text-brand-gray" [colSpan]="3">
            ANNUAL COST TO COLLECT
          </th>
          <th *ngIf="expandedView" class="bg-alt-medium-gray align-middle text-brand-gray" [colSpan]="3">
            AVERAGE PAYMENT DAYS
          </th>
        </tr>
        <tr class="border-top text-alt-dark-gray">
          <th scope="col" class="text-center text-alt-medium-gray bg-brand-gray"><i class="fa fa-star"></i></th>
          <th scope="col" class="bg-brand-gray" [class]="expandedView ? 'w-200' : 'w-400'">Name</th>
          <th scope="col" class="bg-brand-gray description">Description</th>

          <th scope="col" class="text-center bg-brand-gray">Deployment Status</th>
          <th scope="col" class="text-center bg-brand-gray">Complexity</th>

          <th scope="col" class="text-center bg-brand-gray font-weight-bold w-200">Forecasted Value</th>
          <th scope="col" class="text-center bg-brand-gray font-weight-bold w-200">Actual Value</th>

          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Baseline</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Current Phase</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Full Implementation</th>

          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Baseline</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Current Phase</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Full Implementation</th>

          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Baseline</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Current Phase</th>
          <th *ngIf="expandedView" scope="col" class="text-center bg-brand-gray w-200">Full Implementation</th>
        </tr>
      </thead>
      <tbody *ngIf="opportunities?.length > 0">
        <tr
          *ngFor="let opportunity of opportunities; index as i"
          data-t="opportunity-row"
          [ngClass]="{'selectedRow': selectedOpportunity === opportunity.id}"
          (click)="setSelectedOpportunity(null)"
        >
          <td scope="row" class="text-center align-middle">
            <i
              class="fa px-5"
              [ngClass]="{
              'fa-star text-alt-light-blue': opportunity.favorite,
              'fa-star-o text-alt-medium-gray': !opportunity?.favorite
            }"
            ></i>
          </td>
          <td scope="row" class="align-middle">
            <a routerLink="/illuminate/opportunities/{{ opportunity.id }}">{{ opportunity.processName }}</a>
          </td>
          <td scope="row" class="align-middle">{{ opportunity.description }}</td>

          <td scope="row" class="text-center align-middle">
            <app-deployment-status [completedSteps]="opportunity?.completedPhaseSteps || 0"></app-deployment-status>
          </td>
          <td scope="row" class="text-center align-middle">
            <app-level-badge [level]="opportunity?.complexityLevel || 'null'"></app-level-badge>
          </td>

          <td scope="row" class="align-middle text-center">
            <app-profit-improvement
              [baseLine]="opportunity?.monthlyNetProfitabilityOfCollection?.baseLine"
              [compareValue]="opportunity?.monthlyNetProfitabilityOfCollection?.fullAutomation"
              color="text-alt-light-green"
            ></app-profit-improvement>
          </td>
          <td scope="row" class="align-middle text-center">
            <app-profit-improvement
              [baseLine]="opportunity?.monthlyNetProfitabilityOfCollection?.baseLine"
              [compareValue]="opportunity?.monthlyNetProfitabilityOfCollection?.currentPhase"
              color="text-alt-light-green"
            ></app-profit-improvement>
          </td>

          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyWorkflowYield?.baseLine"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyWorkflowYield?.baseLine"
              [currentPhase]="opportunity?.monthlyWorkflowYield?.currentPhase"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyWorkflowYield?.baseLine"
              [fullAutomation]="opportunity?.monthlyWorkflowYield?.fullAutomation"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>

          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyCostToCollect?.baseLine"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyCostToCollect?.baseLine"
              [currentPhase]="opportunity?.monthlyCostToCollect?.currentPhase"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.monthlyCostToCollect?.baseLine"
              [fullAutomation]="opportunity?.monthlyCostToCollect?.fullAutomation"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>

          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.averagePaymentLag?.baseLine"
              units="days"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.averagePaymentLag?.baseLine"
              [currentPhase]="opportunity?.averagePaymentLag?.currentPhase"
              units="days"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
          <td *ngIf="expandedView" scope="row" class="text-center align-middle">
            <app-table-item
              [baseLine]="opportunity?.averagePaymentLag?.baseLine"
              [fullAutomation]="opportunity?.averagePaymentLag?.fullAutomation"
              units="days"
              [hasSecondChild]="false"
            ></app-table-item>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
