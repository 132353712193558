<div class="toast-wrapper d-flex flex-column align-items-center">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [class]="toast.classname"
    [class.p-3]="true"
    [autohide]="toast.autohide"
    (mouseenter)="toast.autohide = false"
    (mouseleave)="toast.autohide = toast.header ? false : true"
    [delay]="toast.header ? null : toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
    [header]="toast.header || null"
    [attr.data-t]="'toast-' + toast.textOrTpl"
  >
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
      <div class="d-flex justify-content-between">
        <span class="d-flex border-right pl-2 pr-4 align-items-center">
          <i class="fa fa-2x fa-check-circle-o" *ngIf="toast.type === 'success'"></i>
          <i class="fa fa-2x fa-exclamation-circle" *ngIf="toast.type === 'error'"></i>
          <i class="fa fa-2x fa-info-circle" *ngIf="toast.type === 'info'"></i>
        </span>
        <b class="flex-grow-1 mx-4 my-3" [innerHtml]="toast.textOrTpl"></b>
        <div class="d-flex flex-column align-items-center justify-content-between">
          <button
            type="button"
            *ngIf="!toast.header"
            class="btn btn-link text-white py-0"
            (click)="toastService.remove(toast)"
            data-t="remove-toast"
          >
            <i class="fa fa-times-circle"></i>
          </button>
          <button
            type="button"
            class="btn btn-link copy-btn text-white py-0"
            *ngIf="toast.type === 'error' && toast.error?.length > 2"
            [attr.data-clipboard-text]="toast.error"
          >
            <i class="fa fa-fw fa-clone"></i>
          </button>
        </div>
      </div>
    </ng-template>
  </ngb-toast>
</div>
