import { Component, Input, OnInit } from '@angular/core'
import { TaskCardActionsService } from 'app/admin/task-types/task-card-actions.service'
import { TaskType } from 'generated/graphql'

/**
 * Component to display a task type in a card
 *
 * @export
 * @class TaskTypeCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-task-type-card',
  templateUrl: './task-type-card.component.html',
  styleUrls: ['./task-type-card.component.scss'],
})
export class TaskTypeCardComponent implements OnInit {
  @Input() taskType: TaskType
  @Input() isCollapsed: boolean

  @Input() showPencil: string = null
  @Input() showRight: string = null
  @Input() showRemove: string = null
  @Input() showTwoRight: string = null

  constructor(private actionService: TaskCardActionsService) {}

  ngOnInit(): void {}

  /**
   * User clicked edit icon
   *
   * @param {TaskType} taskType
   * @param {*} $event
   * @memberof TaskTypeCardComponent
   */
  clickPencil(taskType: TaskType, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickPencil(taskType)
  }

  /**
   * User clicked single arrow icon
   *
   * @param {TaskType} taskType
   * @param {*} $event
   * @memberof TaskTypeCardComponent
   */
  clickRight(taskType: TaskType, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRight(taskType)
  }

  /**
   * User clicked remove icon
   *
   * @param {TaskType} taskType
   * @param {*} $event
   * @memberof TaskTypeCardComponent
   */
  clickRemove(taskType: TaskType, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickRemove(taskType)
  }

  /**
   * User clicked double arrow icon
   *
   * @param {TaskType} taskType
   * @param {*} $event
   * @memberof TaskTypeCardComponent
   */
  clickTwoRight(taskType: TaskType, $event: Event): void {
    $event.stopPropagation()
    this.actionService.clickTwoRight(taskType)
  }
}
