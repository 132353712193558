<img [src]="gravatar" class="rounded-circle" (error)="showDefault = true" *ngIf="!showDefault" />
<span
  *ngIf="showDefault && initials?.length"
  class="rounded-circle"
  [className]="color"
  [ngClass]="{
    'display-1 p-5 d-inline-block': size === 'lg',
    'p-4': size === 'md',
    'p-3': size === 'sm',
    'text-alt-white bg-primary': !invertColor && !randomColor,
    'text-primary bg-alt-white': invertColor
  }"
  [ngbTooltip]="toolTipText"
  container="body"
>
  {{ initials }}
</span>
