import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { defer } from 'lodash'
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/display/api'
import { fromEvent, Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'

const MAX_PAGE_WIDTH = 1000

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnChanges {
  @Input() src: string
  @ViewChild('pdfContainer') pdfContainer: ElementRef

  isLoading: boolean
  hasError: boolean
  hasRendered: boolean
  pdf: PDFDocumentProxy
  pages: PDFPageProxy[]
  renderTasks: { cancel: () => void; promise: Promise<void> }[] = [] // pdfjs-dist does not export RenderTask class
  destroy$ = new Subject<void>()

  constructor() {
    GlobalWorkerOptions.workerSrc = pdfjsWorker
    fromEvent(window, 'resize')
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe(() => this.renderPages())
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.src && changes.src.currentValue) {
      this.isLoading = true
      this.hasError = false
      this.hasRendered = false
      delete this.pdf
      delete this.pages
      try {
        this.pdf = await getDocument(changes.src.currentValue).promise
        this.pages = []
        for (let i = 1; i <= this.pdf.numPages; i++) {
          this.pages.push(await this.pdf.getPage(i))
        }
        defer(() => this.renderPages()) // wait for canvases to be in view
      } catch (e) {
        this.hasError = true
      }
      this.isLoading = false
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private renderPages(): void {
    this.renderTasks.map((rt) => rt.cancel())
    this.pages.forEach((page) => {
      const pageCanvas = this.pdfContainer.nativeElement.querySelector(`canvas:nth-of-type(${page.pageNumber})`)
      const scale =
        Math.min(this.pdfContainer.nativeElement.clientWidth - 40, MAX_PAGE_WIDTH) /
        page.getViewport({ scale: 1 }).width
      const viewport = page.getViewport({ scale })
      pageCanvas.width = viewport.width
      pageCanvas.height = viewport.height
      const canvasContext = pageCanvas.getContext('2d')
      let renderTask = page.render({ canvasContext, viewport })
      renderTask.promise.finally(() => (this.renderTasks = this.renderTasks.filter((rt) => rt !== renderTask)))
      this.renderTasks.push(renderTask)
    })
    this.hasRendered = true
  }
}
