<div class="card detail-card mb-4 border-0">
  <div
    class="card-header d-flex justify-content-between align-items-center with-arrow"
    [class.rotated]="!isCollapsed"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="d-flex justify-content-between flex-grow-1">
      {{ data.title }}
      <button type="button" class="btn btn-sm btn-link mr-3" (click)="removeFacet()" *ngIf="data.id !== 'wildcard'">
        <i class="fa fa-lg fa-times-circle text-light"></i>
      </button>
    </span>
  </div>
  <div class="card-body" [ngbCollapse]="isCollapsed">
    <fieldset>
      <div class="form-group">
        <label class="sr-only">{{ data.title }}:</label>
        <div class="input-group">
          <input
            #input
            [formControl]="search"
            (keyup.enter)="addTerm()"
            type="text"
            class="form-control"
            [attr.placeholder]="data.title"
            data-t="termSearch"
          />
          <div class="input-group-append">
            <span class="in-input-icon d-flex align-items-center">
              <button type="button" (click)="addTerm()" class="btn bg-transparent">
                <i class="fa fa-search fa-fw"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" [formControl]="exact" [attr.id]="data.id + 'exact'" />
        <label class="form-check-label" [attr.for]="data.id + 'exact'">Search exact term only</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" [formControl]="negate" [attr.id]="data.id + 'negate'" />
        <label
          class="form-check-label"
          [attr.for]="data.id + 'negate'"
          ngbTooltip="Search for all terms NOT matching this one"
          container="body"
        >
          NOT
        </label>
      </div>
    </fieldset>
  </div>
</div>
