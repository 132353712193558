import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnChanges {
  @Input() src: string
  isLoading: boolean
  hasError: boolean

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src && changes.src.currentValue) {
      this.isLoading = true
      this.hasError = false
    }
  }
}
