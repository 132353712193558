<div class="d-flex">
  <div class="flex-grow-1">
    <app-search-field [label]="'Search Payers'" [search]="payerSearch" [searching]="isSearching"></app-search-field>
  </div>
</div>
<small class="d-block text-center" *ngIf="payers?.length">
  Showing {{ payers.length - actions.hidden.length }} of about {{ totalPayers }} Payers
</small>

<ng-template ngFor let-payer [ngForOf]="payers" let-i="index">
  <app-payer-card
    [class.d-none]="actions.hidden.includes(payer.id)"
    [payer]="payer"
    [showRight]="showRight"
    [showTwoRight]="showTwoRight"
    [showRemove]="showRemove"
    [attr.data-t]="payer.name + '-payer'"
  ></app-payer-card>
</ng-template>
