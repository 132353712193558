import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { NavigationStart, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TaskCardActionsService } from 'app/admin/task-types/task-card-actions.service'
import { CreateTaskComponent } from 'app/pathfinder/tasks/components/task-create/task-create.component'
import { TaskTypesService } from 'app/pathfinder/tasks/task-types.service'
import { ToastService } from 'app/shared/services/toast.service'
import { debug } from 'app/shared/utils/debug'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { TaskType } from 'generated/graphql'
import { of, Subscription } from 'rxjs'
import { catchError, debounceTime, filter, tap } from 'rxjs/operators'

/**
 * Component to display and allow searching a list of task types
 *
 * @export
 * @class TaskTypeSearchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-task-type-search',
  templateUrl: './task-type-search.component.html',
  styleUrls: ['./task-type-search.component.scss'],
})
export class TaskTypeSearchComponent implements OnInit, OnDestroy {
  @Input() showPencil = ''
  @Input() showRight = ''
  @Input() showRemove = ''
  @Input() showTwoRight = ''

  taskTypes: TaskType[]
  taskTypeSearch = new UntypedFormControl('')
  searching: boolean = false
  totalTaskTypes: number
  taskTypeSearchSub$: Subscription

  hideSub$: Subscription

  isCollapsed: boolean[] = []

  routerSub$: Subscription

  constructor(
    private router: Router,
    private tasktypeService: TaskTypesService,
    private toast: ToastService,
    public actions: TaskCardActionsService,
    private modal: NgbModal,
  ) {
    this.routerSub$ = router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      this.onRouteChange()
    })
    this.hideSub$ = actions.hideCard$.subscribe(({ id, hide }) => {
      let taskType = this.taskTypes?.find((tt) => tt.id === id)
      if (taskType) {
        if (hide) {
          this.actions.hidden.push(taskType.id)
        } else {
          let id = this.actions.hidden?.findIndex((id) => id === taskType.id)
          if (id > -1) {
            this.actions.hidden.splice(id, 1)
          }
        }
      }
    })
  }

  /**
   * Possibly reload the current search
   *
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeSearchComponent
   */
  async onRouteChange(): Promise<void> {
    let reloadSearch = this.router.getCurrentNavigation().extras?.state?.data?.reloadSearch
    if (reloadSearch) {
      this.taskTypeSearch.updateValueAndValidity({ onlySelf: true, emitEvent: true })
    }
  }

  async ngOnInit(): Promise<void> {
    debug('task-type-search', 'ngOnInit')
    this.taskTypeSearchSub$ = this.taskTypeSearch.valueChanges
      .pipe(
        debounceTime(500),
        tap(async (term) => await this.searchTaskTypes(term)),
        catchError((e) => {
          return of([])
        }),
      )
      .subscribe()

    await this.searchTaskTypes('')
  }

  ngOnDestroy(): void {
    debug('task-type-search', 'ngOnDestroy')
    this.taskTypeSearchSub$.unsubscribe()
    this.routerSub$.unsubscribe()
  }

  /**
   * Load a list of task types
   *
   * @param {string} term
   * @return {*}  {Promise<void>}
   * @memberof TaskTypeSearchComponent
   */
  async searchTaskTypes(term: string): Promise<void> {
    this.searching = true
    try {
      this.taskTypes = await this.tasktypeService.searchTaskTypes(term)
      this.totalTaskTypes = this.tasktypeService.getMeta()?.total
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not find task types'), JSON.stringify(e))
    }

    this.searching = false
    this.isCollapsed = new Array(this.taskTypes.length).fill(true)
  }

  /**
   * Launch modal to create a new task type
   *
   * @memberof TaskTypeSearchComponent
   */
  createTask(): void {
    this.modal.open(CreateTaskComponent, {
      size: 'lg',
      centered: true,
    })
  }
}
