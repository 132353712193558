import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo, gql } from 'apollo-angular'
import { BotJobsService } from 'app/admin/bot-jobs/bot-jobs.service'
import { AuthenticationService } from 'app/auth/authentication.service'
import { FullClaimQuery } from 'app/claims/claim-detail/claim-detail.gql'
import { MarkClaimAsOpenedMutation } from 'app/claims/claim-event-mutations.gql'
import { ClaimsForClaimList, ClaimsForTeleport } from 'app/claims/claims-list/claims-list.gql'
import { GetTaskQuery } from 'app/pathfinder/tasks/task-queries.gql'
import { FacetedSearchService } from 'app/shared/services/faceted-search.service'
import { PropertyDef } from 'app/shared/types/claim'
import { Facet } from 'app/shared/types/facet'
import { debug } from 'app/shared/utils/debug'
import { facetsToClaimQueryParams } from 'app/shared/utils/facets-to-params'
import {
  Claim,
  ClaimList,
  ElasticsearchScriptInput,
  ListResponseMetaData,
  MutationMarkClaimAsOpenedArgs,
  QueryClaimArgs,
  QueryClaimsArgs,
  QueryClaimsByTaskTypeIdArgs,
} from 'generated/graphql'
import { DocumentNode } from 'graphql'
import { compact, get, pickBy, uniq } from 'lodash'
import moment, { utc } from 'moment'
import { Observable, of } from 'rxjs'
import { catchError, first, map, tap } from 'rxjs/operators'

export interface GetClaimOptions {
  markAsOpened?: boolean
  billingModule?: 'pb' | 'hb'
}

/**
 * Handle claim(s) operations
 *
 * @export
 * @class ClaimsService
 */
@Injectable({
  providedIn: 'root',
})
export class ClaimsService {
  private meta: ListResponseMetaData = {
    total: 0,
    sort: 'serviceDate:desc',
    offset: 0,
    limit: 25,
  }

  constructor(
    private apollo: Apollo,
    private facetService: FacetedSearchService,
    private botJobsService: BotJobsService,
    private authenticationService: AuthenticationService,
  ) {}

  /**
   * Helper to retrieve pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof ClaimsService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Helper to set pagination and search data
   *
   * @param {ListResponseMetaData} meta
   * @memberof ClaimsService
   */
  setMeta(meta: ListResponseMetaData): void {
    this.meta = meta
  }

  /**
   * Retrieve an individual claim
   */
  getClaim(providerClaimId: string, options: GetClaimOptions = {}): Observable<ApolloQueryResult<{ claim: Claim }>> {
    const markAsOpened = options.markAsOpened ?? false
    const billingModule = options.billingModule
    return this.getClaimByFieldQuery({ providerClaimId, billingModule }, markAsOpened)
  }

  /**
   * Retrieve an individual claim by it's DB id
   *
   * @param {string} id
   * @param {boolean} [markAsOpened=false]
   * @return {*}  {Observable<ApolloQueryResult<{ claim: Claim }>>}
   * @memberof ClaimsService
   */
  getClaimById(id: string, markAsOpened = false): Observable<ApolloQueryResult<{ claim: Claim }>> {
    return this.getClaimByFieldQuery({ id }, markAsOpened)
  }

  private getClaimByFieldQuery(queryArgs: Partial<QueryClaimArgs>, markAsOpened = false) {
    const claimObservable = this.apollo.watchQuery<{ claim: Claim }, QueryClaimArgs>({
      query: FullClaimQuery,
      variables: queryArgs,
      fetchPolicy: 'cache-first',
    }).valueChanges
    claimObservable
      .pipe(
        first(),
        catchError(() => of({ data: null })),
        tap((res) => {
          if (res?.data?.claim?.id) {
            const claim = res.data.claim
            claim.tasks?.map((task) => {
              this.apollo.client.writeQuery({
                query: GetTaskQuery,
                variables: {
                  id: task.id,
                },
                data: {
                  task,
                },
              })
            })
            if (markAsOpened) {
              void this.markClaimAsOpened(res.data.claim.id)
            }
          }
        }),
      )
      .subscribe()
    return claimObservable
  }

  /**
   * Retrieve claims for export
   *
   * @param {(Facet[] | string)} [search]
   * @param {number} [offset]
   * @param {number} [limit]
   * @return {*}  {Promise<Claim[]>}
   * @memberof ClaimsService
   */
  async getClaimsForExport(search?: Facet[] | string, offset?: number, limit?: number): Promise<Claim[]> {
    let scripts: ElasticsearchScriptInput[]
    if (Array.isArray(search) && search[0]?.selected?.length) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ({ search, scripts } = facetsToClaimQueryParams(search))
    } else {
      search = ''
    }
    let result = await this.apollo
      .query<{ claims: ClaimList }, QueryClaimsArgs>({
        query: ClaimsForClaimList,
        variables: {
          sort: 'serviceDate',
          offset: offset || 0,
          limit: limit || 100,
          search,
          scripts,
        },
      })
      .toPromise()

    return result.data.claims.entities
  }

  /**
   * Preferred over getClaims due to the use of Observables
   * @param query
   */
  getClaimsObservable(query: DocumentNode, vars: QueryClaimsArgs, setMeta = true): Observable<Claim[]> {
    debug('claims-service', 'get claims search', vars)
    let { sort, offset, limit, search, scripts } = vars

    return this.apollo
      .query<{ claims: ClaimList }, QueryClaimsArgs>({
        query: query,
        variables: {
          sort: sort || '',
          offset: offset || 0,
          limit: limit || 25,
          search,
          scripts,
        },
        fetchPolicy: 'cache-first',
      })
      .pipe(
        map((result) => {
          if (setMeta) this.meta = result?.data?.claims?.meta

          return result?.data?.claims?.entities
        }),
      )
  }

  /**
   * Retrieve claims for display
   *
   * @deprecated - use getClaimsObservable instead
   * @param {DocumentNode} query
   * @param {QueryClaimsArgs} vars
   * @param {boolean} [setMeta=true]
   * @return {*}  {Promise<Claim[]>}
   * @memberof ClaimsService
   */
  async getClaims(query: DocumentNode, vars: QueryClaimsArgs, setMeta = true): Promise<Claim[]> {
    return this.getClaimsObservable(query, vars, setMeta).toPromise()
  }

  /**
   * Retrieve claims that have an assigned task type
   *
   * @param {string} [taskTypeId]
   * @return {*}  {Promise<Claim[]>}
   * @memberof ClaimsService
   */
  async getClaimsByTaskType(taskTypeId?: string): Promise<Claim[]> {
    let result = await this.apollo
      .query<{ claimsByTaskTypeId: ClaimList }, QueryClaimsByTaskTypeIdArgs>({
        query: gql`
          query ClaimsByTaskType($sort: String, $offset: Float, $limit: Float, $taskTypeId: String!) {
            claimsByTaskTypeId(sort: $sort, offset: $offset, limit: $limit, taskTypeId: $taskTypeId) {
              entities {
                id
                providerClaimId
              }
              meta {
                total
              }
            }
          }
        `,
        variables: {
          sort: 'providerClaimId',
          offset: 0,
          limit: 25,
          taskTypeId: taskTypeId || null,
        },
        fetchPolicy: 'network-only',
      })
      .toPromise()

    this.meta = result.data.claimsByTaskTypeId?.meta

    return result.data.claimsByTaskTypeId?.entities
  }

  /**
   * Retrieve the count of claims that have an assigned task type
   *
   * @param {string} [taskTypeId]
   * @return {*}  {Promise<number>}
   * @memberof ClaimsService
   */
  async getTotalClaimsByTaskType(taskTypeId?: string): Promise<number> {
    let result = await this.apollo
      .query<{ claimsByTaskTypeId: ClaimList }, QueryClaimsByTaskTypeIdArgs>({
        query: gql`
          query TotalClaimsByTaskType($sort: String, $offset: Float, $limit: Float, $taskTypeId: String!) {
            claimsByTaskTypeId(sort: $sort, offset: $offset, limit: $limit, taskTypeId: $taskTypeId) {
              entities {
                id
              }
              meta {
                total
              }
            }
          }
        `,
        variables: {
          sort: 'providerClaimId',
          offset: 0,
          limit: 1,
          taskTypeId: taskTypeId || null,
        },
        fetchPolicy: 'network-only',
      })
      .toPromise()

    return result.data.claimsByTaskTypeId.meta.total
  }

  /**
   * Mark a claim as having been viewed
   *
   * @private
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof ClaimsService
   */
  private async markClaimAsOpened(id: string): Promise<boolean> {
    debug('claims-service', 'marking claim as opened', id)
    let vars: MutationMarkClaimAsOpenedArgs = {
      id: id,
    }
    let result = await this.apollo
      .mutate<{ markClaimAsOpened: boolean }, MutationMarkClaimAsOpenedArgs>({
        mutation: MarkClaimAsOpenedMutation,
        variables: vars,
      })
      .toPromise()

    return result.data?.markClaimAsOpened
  }

  /**
   * Helper method for formatting list of claims for export as csv
   *
   * @param {Claim[]} claims
   * @param {PropertyDef[]} columns
   * @return {*}  {string}
   * @memberof ClaimsService
   */
  claimsToCsv(claims: Claim[], columns: PropertyDef[]): string {
    let csv = claims.map((row) => {
      return (row.servicelines?.length > 0 ? row.servicelines : new Array({}))
        .map(() => columns.map((label) => this.getValueFromProperty(row, label.property)).join(','))
        .join('\r\n')
    })

    csv.unshift(columns.map((c) => c.label).join(','))
    let csvArray = csv.join('\r\n')

    return csvArray
  }

  /**
   * Helper for getting the value of a claim's property
   * for csv export
   *
   * @private
   * @param {Claim} claim
   * @param {string} path
   * @return {*}  {string}
   * @memberof ClaimsService
   */
  private getValueFromProperty(claim: Claim, path: string): string {
    const replacer = (key, value) => (value === null || value === undefined ? 'N/A' : value)
    let parts = path.split('.')
    let first = parts[0]

    if (!Array.isArray(claim[first])) {
      return JSON.stringify(get(claim, path, 'N/A'), replacer)
    }

    return JSON.stringify(
      claim[first]
        .map((parent) => replacer(null, get(parent, parts.slice(1), null)))
        .filter((v) => v?.length)
        .join('|'),
      replacer,
    )
  }

  /**
   * Helper to save currently applied facets and pagination to session storage
   *
   * @return {*}  {boolean}
   * @memberof ClaimsService
   */
  saveFacetsToSessionStorage(): boolean {
    let applied = this.facetService.facets$.value.filter((f) => f.selected.length)
    window.sessionStorage.setItem(
      'claimsSearch',
      JSON.stringify(applied.map((f) => ({ id: f.id, selected: f.selected }))),
    )
    window.sessionStorage.setItem('claimsMeta', JSON.stringify(this.getMeta()))
    return true
  }

  /**
   *
   * @deprecated - Please use the teleport Service getTeleportServiceForEntity
   * @param claim
   * @param botType
   * @returns
   */
  async getTeleportUrlForClaim(claim: Claim, botType?: string): Promise<string> {
    // Keep needed claim fields sync with ClaimsForTeleport in 'app/claims/claims-list/claims-list.gql'
    if (!claim?.payer?.id) {
      return
    }
    let user = this.authenticationService.getUser()
    let payerId = claim?.payer?.parentId || claim?.payer?.id
    let botsList = await this.botJobsService
      .getBotJobs(0, payerId, null, null, true, user.orgId, true)
      .pipe(first())
      .toPromise()
    let theBot = botsList.data.botJobsV2.entities.find(
      (bot) => bot.payerIds.includes(payerId) && bot.teleportEnabled === true,
    )
    if (theBot) {
      let name = claim.patientName?.split(',') || []
      let patientLastName = claim?.patientNameLast?.trim()
      let patientFirstName = claim?.patientNameFirst?.trim()
      let alternateFirstName = name[name.length - 1]?.trim()
      let alternateLastName = name[0]?.trim()
      let url = 'https://app.janus-ai.com/teleport?'
      let serviceDates = this.getClaimServiceDates(claim)
      // NOTE - legacy, this is completely unused
      if (name.length > 2) {
        name = name.filter((n) => !['JR', 'SR'].includes(n.toUpperCase().replace('.', '')))
      }
      let params = new URLSearchParams(
        pickBy(
          {
            userId: user.id,
            claimId: claim.id,
            attendingNpi: claim.providerAttending?.npi,
            availityOrgId: claim?.availityOrgId,
            billingNpi: claim.providerBilling?.npi,
            billingTaxId: claim.taxId?.replace('-', '') || claim.providerBilling?.taxId?.replace('-', ''),
            botType,
            claimAmount: claim?.claimAmount?.toString(),
            credentialTypeModifier: theBot?.credentialTypeModifier,
            credPool: theBot.credentialType,
            operatingNpi: claim.providerOperating?.npi,
            patientAccountNumber: claim?.providerClaimId,
            patientDob: utc(claim?.patientDob)?.format('MM/DD/YYYY'),
            patientFirstName: patientFirstName ?? alternateFirstName,
            patientLastName: patientLastName ?? alternateLastName,
            patientMemberId: claim.memberId,
            payer: theBot.payer?.toLowerCase(),
            payerName: claim.payer?.name,
            subscriberName: claim.subscriberName,
            patientRelationship: claim.patientRelationship,
            payerAddress: claim.payer?.address,
            portal: theBot.portal?.toLowerCase(),
            providerGroupNpi: claim?.providerOperating?.groupNpi,
            providerNpi: claim.providerOperating?.npi,
            providerRenderingName: claim?.providerRendering?.name,
            providerTaxId: claim?.taxId?.replace('-', ''),
            referringNpi: claim.providerReferring?.npi,
            renderingNpi: claim.providerRendering?.npi,
            billingAddress: claim.providerReferring?.address,
            referringAddress: claim.providerBilling?.address,
            renderingAddress: claim.providerReferring?.address,
            serviceDatesEnd: serviceDates.endDate ? moment(serviceDates.endDate).format('MM/DD/YYYY') : null,
            serviceDatesStart: serviceDates.startDate ? moment(serviceDates.startDate).format('MM/DD/YYYY') : null,
            serviceDate: claim.serviceDate ? moment(claim.serviceDate).format('MM/DD/YYYY') : null,
            submissionDate: claim.submissionDate ? moment(claim.submissionDate).format('MM/DD/YYYY') : null,
            subscriberId: claim?.subscriberId,
            patientSex: claim.patientSex,
            orgId: user.orgId,
            groupNumber: claim.groupNumber,
            formType: claim.formType,
            renderingProviderTaxonomyCode: claim.renderingProviderTaxonomyCode,
            billingProviderTaxonomyCode: claim.billingProviderTaxonomyCode
          },
          (value) => value != null,
        ),
      )
      return url + params
    }
    return null
  }

  getClaimServiceDates(claim: Claim): { startDate: Date; endDate: Date } {
    let startDate: Date
    let endDate: Date
    let processDate = function (dateStr: string): void {
      // determine min/max service dates
      let date = moment(dateStr).toDate()
      if (!startDate || date < startDate) startDate = date
      if (!endDate || date > endDate) endDate = date
    }
    if (claim.serviceDate) processDate(claim.serviceDate)
    if (claim.dateOfServiceStart) processDate(claim.dateOfServiceStart)
    if (claim.dateOfServiceEnd) processDate(claim.dateOfServiceEnd)

    claim.servicelines?.forEach((sl) => {
      if (sl.dateOfServiceStart) {
        processDate(sl.dateOfServiceStart)
      }
      if (sl.dateOfServiceEnd) {
        processDate(sl.dateOfServiceEnd)
      }
    })

    return { startDate, endDate }
  }

  getPatientNameForHarId(harId: string): Observable<string> {
    return this.getClaimsObservable(
      ClaimsForTeleport,
      {
        search: `harId: (${harId})`,
      },
      false,
    ).pipe(
      map((claims) => {
        if (!claims) return ''
        let patientNames = uniq(compact(claims.map((claim) => claim.patientName)))
        if (patientNames.length > 1) {
          debug('harList', 'claims for this HAR contain multiple patientNames', patientNames)
        }
        return patientNames[0]
      }),
    )
  }
}
