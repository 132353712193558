<div class="row mb-5">
  <div class="col">
    <div class="input-group flex-grow-1">
      <label class="sr-only">Select Workflow Group</label>
      <input
        #workflowGroupingTypeahead="ngbTypeahead"
        type="text"
        class="form-control text-alt-medium-gray rounded-left border-alt-light-gray border-right-0"
        [formControl]="workflowGroupingFormControl"
        placeholder="Select Workflow to Annotate"
        [ngbTypeahead]="workflowGroupingSearch"
        [resultFormatter]="workflowGroupsFormatter"
        [inputFormatter]="workflowGroupsFormatter"
        (focus)="workflowGroupingFocus$.next($any($event).target.value)"
        (click)="workflowGroupingClick$.next($any($event).target.value)"
        (selectItem)="selectWorkflowGrouping($event)"
        data-t="annot-workflow-grouping"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-alt-light-gray input-group-text"
          [disabled]="!(annotationsStateService.currentWorkflowGroup$ | async)"
          type="button"
          (click)="selectWorkflowGrouping()"
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-8 py-3">
    <app-loading
      *ngIf="isLoading"
      innerClass="fa-4x"
      outerClass="d-flex align-items-center justify-content-center h-100"
    ></app-loading>

    <div
      *ngIf="!isLoading && (annotationsStateService.currentWorkflowGroup$ | async) && !annotatedObserveRecords.length"
    >
      No observe records found for
      <strong
        >{{ (annotationsStateService.currentWorkflowGroup$ | async)?.name || 'selected workflow grouping' }}</strong
      >.
    </div>

    <div *ngIf="!isLoading && annotatedObserveRecords.length">
      <ngb-carousel
        #recordCarousel="ngbCarousel"
        [interval]="null"
        [showNavigationIndicators]="false"
        [showNavigationArrows]="false"
        [keyboard]="false"
        [wrap]="false"
        (slide)="changeRecord($event)"
        data-t="annot-carousel"
      >
        <ng-template ngbSlide [id]="record?.id" *ngFor="let record of annotatedObserveRecords">
          <app-annotated-record-slide [record]="record"></app-annotated-record-slide>
        </ng-template>
      </ngb-carousel>

      <div class="mt-5 text-center w-100">
        <input
          class="w-90"
          [formControl]="activeRecordFormControl"
          type="range"
          min="1"
          [max]="annotatedObserveRecords.length"
          step="1"
        />
        <div class="mt-3">
          On page <strong>{{ activeRecordFormControl.value?.toLocaleString() }}</strong> of {{
          annotatedObserveRecords.length.toLocaleString() }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <form
      (ngSubmit)="updateObserveRecordAnnotated()"
      [formGroup]="workflowForm"
      class="d-flex flex-column"
      data-t="annot-form"
    >
      <fieldset class="border p-3 my-3">
        <legend class="w-auto h3">Workflow</legend>
        <div class="custom-control custom-switch ml-2 my-2" (click)="startInput.focus()">
          <input
            #startInput
            type="checkbox"
            class="custom-control-input"
            id="workflowForm-isStart"
            value="true"
            formControlName="isStart"
            data-t="annot-start"
          />
          <label class="custom-control-label" for="workflowForm-isStart">Start</label>
        </div>
        <div class="custom-control custom-switch ml-2 my-2" (click)="endInput.focus()">
          <input
            #endInput
            type="checkbox"
            class="custom-control-input"
            id="workflowForm-isEnd"
            value="true"
            formControlName="isEnd"
            data-t="annot-end"
          />
          <label class="custom-control-label" for="workflowForm-isEnd">End</label>
        </div>
      </fieldset>
      <fieldset class="border p-3 my-3">
        <legend class="w-auto h3">Task</legend>
        <div class="form-group d-flex">
          <div class="input-group flex-grow-1">
            <label class="sr-only">Task</label>
            <input
              type="text"
              class="form-control"
              placeholder="Task"
              formControlName="taskId"
              [ngbTypeahead]="taskSearch"
              [resultFormatter]="taskFormatter"
              [inputFormatter]="taskFormatter"
              (focus)="taskFocus$.next($any($event).target.value)"
              (click)="taskClick$.next($any($event).target.value)"
              #taskTypeahead="ngbTypeahead"
              data-t="annot-task"
            />
            <span class="in-input-icon d-flex align-items-center">
              <button type="button" class="btn bg-transparent">
                <i class="fa fa-times fa-fw"></i>
              </button>
            </span>
          </div>
          <div class="flex-shrink-1">
            <button type="button" class="btn btn-lime-green font-weight-bold ml-2">
              <i class="fa fa-fw fa-plus-circle" data-t="create-task-button"></i>
            </button>
          </div>
        </div>
      </fieldset>

      <button
        class="btn btn-primary align-self-end"
        [disabled]="!(workflowFormModified$ | async)"
        type="submit"
        data-t="annot-save-button"
      >
        Save <i class="fa fa-fw fa-save ml-2"></i>
      </button>
    </form>
  </div>
</div>
