import { NgModule } from '@angular/core'
import { Data, Route, RouterModule } from '@angular/router'
import { AdminPage } from 'app/admin/admin.page'
import { ClaimSearchEditPage } from 'app/admin/claim-searches/claim-searches.page'
import { ClaimSearchDetailPage } from 'app/admin/claim-searches/edit/edit.page'
import { ClientCredentialsListPage } from 'app/admin/client-credentials/client-credentials-list/client-credentials-list.page'
import { ClientCredentialEditPage } from 'app/admin/client-credentials/edit/client-credential-edit.page'
import { FeatureFlagEditPage } from 'app/admin/feature-flags/edit/feature-flag-edit.page'
import { FeatureFlagNamesListPage } from 'app/admin/feature-flags/feature-flag-names-list/feature-flag-names-list.page'
import { FeatureFlagPage } from 'app/admin/feature-flags/feature-flag/feature-flag.page'
import { ObserveAccessControlAuditTrailPage } from 'app/admin/observe-access-controls/audit-trail/observe-access-control-audit-trail.page'
import { ObserveAccessControlPage } from 'app/admin/observe-access-controls/observe-access-control-list/observe-access-control.page'
import { OrgLogoPage } from 'app/admin/org/logo/logo.page'
import { OrgListPage } from 'app/admin/org/org-list/org-list.page'
import { SamlConfigurationPage } from 'app/admin/org/saml/saml-configuration.page'
import { SamlOrgListPage } from 'app/admin/org/saml/saml-org-list.page'
import { PayerPlanGroupEditPage } from 'app/admin/payer-plan-groups/edit/payer-plan-group-edit.page'
import { PayerPlanGroupPage } from 'app/admin/payer-plan-groups/payer-plan-groups/payer-plan-groups.page'
import { CombinePayersPage } from 'app/admin/payers/combine/combine-payers.page'
import { PayerEditPage } from 'app/admin/payers/edit/payer-edit.page'
import { PayerResolverService } from 'app/admin/payers/payer-resolver.service'
import { PayersListPage } from 'app/admin/payers/payers-list/payers-list.page'
import { TaskGroupAssignPage } from 'app/admin/task-groups/assign/assign.page'
import { TaskGroupEditPage } from 'app/admin/task-groups/edit/edit.page'
import { TaskGroupDetailsPage } from 'app/admin/task-groups/edit/task-group-details/task-group-details.page'
import { TaskTypeAssignPage } from 'app/admin/task-types/assign/assign.page'
import { TaskTypeEditPage } from 'app/admin/task-types/edit/edit.page'
import { TaskTypeDetailsPage } from 'app/admin/task-types/edit/right/task-type-details/task-type-details.page'
import { TaskTypeMergePage } from 'app/admin/task-types/merge/merge.page'
import { TasksDeletePage } from 'app/admin/tasks/delete/delete.page'
import { TeamEditPage } from 'app/admin/teams/edit/team-edit.page'
import { TeamsPage } from 'app/admin/teams/teams.page'
import { EditUserPage } from 'app/admin/users/edit/edit-user.page'
import { UsersListPage } from 'app/admin/users/users-list/users-list.page'
import { WorkflowAssignPage } from 'app/admin/workflows/assign/assign.page'
import { DeleteWorkflowsPage } from 'app/admin/workflows/delete/delete-workflows.page'
import { WorkflowListPage } from 'app/admin/workflows/workflow-list/workflow-list.page'
import { WorkflowTypeResolverService } from 'app/admin/workflows/workflow-type-resolver.service'
import { WorkflowPage } from 'app/admin/workflows/workflow/workflow.page'
import { AnnotationsPage } from 'app/annotations/annotations.page'
import { LoggedInPage } from 'app/auth/logged-in/logged-in.page'
import { ActivatePage } from 'app/auth/login/activate/activate.page'
import { LoginPage } from 'app/auth/login/login.page'
import { NewPasswordPage } from 'app/auth/login/reset/new-password/new-password.page'
import { ResetPage } from 'app/auth/login/reset/reset.page'
import { ClaimDetailPage } from 'app/claims/claim-detail/claim-detail.page'
import { ClaimsListPage } from 'app/claims/claims-list/claims-list.page'
import { DashboardPage } from 'app/dashboard/dashboard.page'
import { PriorAuthDashboardPage } from 'app/demo/prior-auth/prior-auth.page'
import { FilePage } from 'app/file/file.page'
import { HomePage } from 'app/home/home.page'
import { IlluminateWorkflowGroupPage } from 'app/illuminate-v2/illuminate-workflow-group/illuminate-workflow-group.page'
import { IlluminatePage } from 'app/illuminate/illuminate.page'
import { OpportunitiesPage } from 'app/illuminate/opportunities/opportunities.page'
import { OpportunityDetailsPage } from 'app/illuminate/opportunity-details/opportunity-details.page'
import { TaskGroupsPage } from 'app/pathfinder/task-groups/task-groups.page'
import { TasksPage } from 'app/pathfinder/tasks/tasks.page'
import { CookiePolicyPage } from 'app/policies/cookie-policy/cookie-policy.page'
import { PrivacyPolicyPage } from 'app/policies/privacy-policy/privacy-policy.page'
import { SupportAndMaintenancePage } from 'app/policies/support-and-maintenance.page'
import { TermsOfUsePage } from 'app/policies/terms-of-use/terms-of-use.page'
import { ReportingPage } from 'app/reporting/reporting.page'
import { ReportListPage } from 'app/reports/report-list/report-list.page'
import { ReportUploadPage } from 'app/reports/report-upload/report-upload.page'
import { RequestPage } from 'app/request/request.page'
import { BotDetailPage } from 'app/revbot/bot-detail/bot-detail.page'
import { BotOverviewPage } from 'app/revbot/bot-detail/bot-overview/bot-overview.page'
import { BotRunOverviewPage } from 'app/revbot/bot-detail/bot-run-overview/bot-run-overview.page'
import { BotsListPage } from 'app/revbot/bots-list/bots-list.page'
import { SecurityAndCompliancePage } from 'app/security-and-compliance/security-and-compliance.page'
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard'
import { ClaimsListGuard } from 'app/shared/guards/claims-list.guard'
import { FeatureFlagGuard, FeatureLogicCanActivate } from 'app/shared/guards/feature-flag.guard'
import { PermissionGuard } from 'app/shared/guards/permission.guard'
import { TeleportGuard } from 'app/shared/guards/teleport.guard'
import { StatusPage } from 'app/status/status.page'
import { TeleportFeedbackPage } from 'app/teleport-feedback/teleport-feedback.page'
import { HarListPage } from 'app/teleport/har-list/har-list.page'
import { PriorAuthPage } from 'app/teleport/prior-auth/prior-auth.page'
import { TeleportErrorPage } from 'app/teleport/teleport-error/teleport-error.page'
import { TeleportInvoicePage } from 'app/teleport/teleport-invoice/teleport-invoice.page'
import { TeleportPage } from 'app/teleport/teleport.page'
import { UserRole } from 'generated/graphql'
import { DashboardOrgListComponent } from './admin/dashboard-management/dashboard-org-list/dashboard-org-list.component'
import { EditOrgDashboardPage } from './admin/dashboard-management/edit/edit-org-dashboard.page.component'
import { EdiReader } from './admin/edi-reader/edi-reader.page'
import { ObserveBuildConfigPageComponent } from './admin/observe-builds/observe-build-config-page/observe-build-config-page.component'
import { ObserveConfigPage } from './admin/observe-configurations/observe-config-page/observe-config.page'
import { ObserveTestingPage } from './admin/observe-testing/observe-testing.page'
import { ObserveUsersActivityLogComponent } from './admin/observe-users/observe-users-activity-log/observe-users-activity-log.component'
import { ObserveUsersListComponent } from './admin/observe-users/observe-users-list/observe-users-list.component'
import { ObserveOrgListComponent } from './admin/observe/org-settings/observe-org-list/observe-org-list.component'
import { ObserveOrgSettingsComponent } from './admin/observe/org-settings/observe-org-settings/observe-org-settings.component'
import { AutomationsQueryCenterPage } from './admin/query-center/automations-query-center/automations-query-center.page'
import { DEFAULT_HOME_PATH } from './app-routing.routes'
import { ConfirmPage } from './auth/login/confirm/confirm.page'
import { SelectOrgPage } from './auth/login/select-org/select-org.page'
import { Permission, PermissionConditionType, Permissions } from './auth/types'
import { DenialsDashboardPage } from './illuminate/denials-dashboard/denials-dashboard.page'
import { IlluminateDashboardPage } from './illuminate/illuminate-dashboard/illuminate-dashboard.page'
import { UsageInsightsDashboardPage } from './illuminate/usage-insights-dashboard/usage-insights-dashboard.page'
import { ObserveDashboardPage } from './observe-dashboard/observe-dashboard.page'
import { ThroughputReportDashboardPage } from './revbot/throughput-report-dashboard/throughput-report-dashboard.page'
import { OrgIdResolver } from './shared/resolvers/org-id.resolver'
import { FeatureFlags } from './shared/services/feature-flag.service'
import { PriorAuthDocsUploadPage } from './teleport/docs-upload/docs-upload.page'
export interface AppRoute extends Route {
  data?: Data & {
    title?: string
    breadcrumb?: string
    reuse?: boolean
    feature?: string | FeatureLogicCanActivate
    roles?: (UserRole | 'Super')[] // Remove after migration to permissions.
    permissions?: Permission[]
    permissionType?: PermissionConditionType
    unauthenticatedMessage?: string
  }
  children?: AppRoute[]
}

const routes: AppRoute[] = [
  {
    path: 'login',
    data: { title: null },
    children: [
      {
        path: '',
        component: LoginPage,
      },
      {
        path: 'reset',
        component: ResetPage,
      },
      {
        path: 'reset/:token',
        component: NewPasswordPage,
      },
      {
        path: 'activate/:token',
        component: ActivatePage,
      },
      {
        path: 'confirm/:token',
        component: ConfirmPage,
      },
      {
        path: 'select-org',
        component: SelectOrgPage,
        canActivate: [AuthenticationGuard],
      },
      {
        path: ':error',
        component: LoginPage,
      },
    ],
  },
  {
    path: 'teleport',
    canActivate: [TeleportGuard],
    data: { title: 'Teleport', unauthenticatedMessage: 'Please login to continue' },
    resolve: { orgId: OrgIdResolver },
    children: [
      {
        path: '',
        component: TeleportPage,
      },
      {
        path: 'init',
        component: TeleportPage,
      },
      {
        path: 'feedback',
        component: TeleportFeedbackPage,
        data: { title: 'Teleport Feedback' },
      },
      {
        path: 'warning',
        component: TeleportErrorPage,
        data: { title: 'Teleport Warning' },
      },
      {
        path: 'launch',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('app/launch-teleport/launch-teleport.module').then((m) => m.LaunchTeleportModule),
      },
      {
        path: 'har',
        canActivate: [AuthenticationGuard],
        data: { title: 'Teleport Claim List' },
        children: [
          {
            path: ':harId',
            component: HarListPage,
          },
        ],
      },
      {
        path: 'prior-auth',
        canActivate: [AuthenticationGuard],
        data: { title: 'Teleport Prior Auth' },
        children: [
          {
            path: ':patientMrn',
            component: PriorAuthPage,
          },
        ],
      },
      {
        path: 'prior-auth-docs-upload',
        canActivate: [AuthenticationGuard, FeatureFlagGuard],
        data: {
          title: 'Prior Auth Doc Upload',
          feature: FeatureFlags.ui.uploadDocs,
        },
        children: [
          {
            path: '',
            component: PriorAuthDocsUploadPage,
          },
        ],
      },
      {
        path: 'invoice',
        canActivate: [AuthenticationGuard],
        children: [
          {
            path: ':providerClaimId',
            component: TeleportInvoicePage,
          },
        ],
      },
      {
        path: 'inv/:providerClaimId',
        pathMatch: 'full',
        redirectTo: 'invoice/:providerClaimId',
      },
    ],
  },
  {
    path: 'request',
    component: RequestPage,
    data: { title: null },
  },
  {
    path: 'policies',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: DEFAULT_HOME_PATH,
      },
      {
        path: 'support-and-maintenance',
        component: SupportAndMaintenancePage,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyPage,
      },
      {
        path: 'cookie-policy',
        component: CookiePolicyPage,
      },
      {
        path: 'terms-of-use',
        component: TermsOfUsePage,
      },
    ],
  },
  {
    path: 'security-and-compliance',
    component: SecurityAndCompliancePage,
  },
  {
    path: 'status',
    component: StatusPage,
  },
  {
    path: '',
    component: LoggedInPage,
    canActivate: [AuthenticationGuard],
    resolve: { orgId: OrgIdResolver },
    children: [
      {
        path: '',
        children: [
          {
            path: 'dashboard/prior-auth',
            component: PriorAuthDashboardPage,
            data: {
              feature: FeatureFlags.ui.demo,
              roles: [UserRole.Executive], // Remove after migration to permissions.
              permissions: [Permissions.priorAuth.dashboard.view],
            },
            canActivate: [FeatureFlagGuard, PermissionGuard],
          },
          {
            path: 'dashboard',
            component: DashboardPage,
            data: {
              feature: {
                features: [FeatureFlags.ui.dashboard, FeatureFlags.ui.illuminate.denialsDashboard],
                isAndOperator: false,
              } as FeatureLogicCanActivate,
              roles: [UserRole.Executive], // Remove after migration to permissions.
              title: 'Dashboard',
              permissions: [Permissions.wmf.dashboard.view],
            },
            canActivate: [FeatureFlagGuard, PermissionGuard],
          },
          {
            path: 'home',
            component: HomePage,
          },
          {
            path: 'illuminate',
            component: IlluminatePage,
            data: { title: 'Illuminate' },
            children: [
              {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
              },
              {
                path: 'dashboard',
                component: ObserveDashboardPage,
                data: {
                  feature: FeatureFlags.ui.observeDashboard,
                },
                canActivate: [FeatureFlagGuard],
              },
              {
                path: 'denials',
                component: DenialsDashboardPage,
                data: {
                  roles: [UserRole.Executive, UserRole.Admin],
                  feature: FeatureFlags.ui.illuminate.denialsDashboard,
                },
                canActivate: [FeatureFlagGuard, PermissionGuard],
              },
              {
                path: 'workforce-management',
                component: IlluminateDashboardPage,
                data: {
                  feature: FeatureFlags.ui.illuminate.workforceManagement,
                  roles: [UserRole.Executive, UserRole.Admin],
                },
                canActivate: [FeatureFlagGuard, PermissionGuard],
              },
              {
                path: 'usage-insights',
                component: UsageInsightsDashboardPage,
                data: {
                  feature: FeatureFlags.ui.illuminate.usageInsights,
                  roles: [UserRole.Executive, UserRole.Admin],
                },
                canActivate: [FeatureFlagGuard, PermissionGuard],
              },
              {
                path: 'opportunities',
                component: OpportunitiesPage,
              },
              {
                path: 'opportunities/:opportunityId',
                data: {
                  breadcrumb: ':opportunityId',
                },
                component: OpportunityDetailsPage,
              },
            ],
          },
          {
            path: 'illuminate-v2',
            canActivate: [PermissionGuard, FeatureFlagGuard],
            data: {
              roles: [UserRole.Admin, UserRole.Manager], // Remove after migration to permissions.
              feature: FeatureFlags.ui.illuminate.root,
              permissions: [Permissions.illuminate.all.all],
            },
            children: [
              {
                path: ':workflowGroupId',
                component: IlluminateWorkflowGroupPage,
              },
            ],
          },
          {
            path: 'admin',
            data: {
              breadcrumb: 'Admin',
              roles: [UserRole.Admin], // Remove after migration to permissions.
              title: 'Admin',
              permissions: [Permissions.system.admin.all],
            },
            canActivate: [PermissionGuard],
            children: [
              {
                path: '',
                component: AdminPage,
              },
              {
                path: 'org',
                data: {
                  breadcrumb: 'Organization',
                  roles: ['Super'], // Remove after migration to permissions.
                  permissions: [Permissions.system.org.all],
                },
                canActivate: [PermissionGuard],
                children: [
                  {
                    path: 'logo',
                    component: OrgLogoPage,
                    data: {
                      breadcrumb: 'Change Logo',
                      title: 'Admin - Org Logo',
                    },
                  },
                  {
                    path: 'edit',
                    component: OrgListPage,
                    data: {
                      breadcrumb: 'Edit Orgs',
                      title: 'Admin - Org List',
                    },
                  },
                  {
                    path: 'saml',
                    data: { title: 'SAML Configuration', breadcrumb: 'SAML' },
                    children: [
                      {
                        path: '',
                        component: SamlOrgListPage,
                        data: {
                          title: 'Org List',
                        },
                      },
                      {
                        path: 'edit/:orgId',
                        component: SamlConfigurationPage,
                        data: {
                          title: 'Edit SAML Configuration',
                          breadcrumb: 'Edit',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'qedi',
                data: {
                  title: 'QEDI',
                  breadcrumb: 'QEDI',
                  feature: FeatureFlags.ui.qedi.root,
                  roles: ['Super'], // Remove after migration to permissions.
                  permissions: [Permissions.qedi.all.all],
                },
                canActivate: [FeatureFlagGuard, PermissionGuard],
                loadChildren: () => import('app/qedi/qedi.module').then((m) => m.QediModule),
              },
              {
                path: 'searches',
                children: [
                  {
                    path: 'edit',
                    component: ClaimSearchEditPage,
                    data: {
                      breadcrumb: 'Edit Claim Search',
                      title: 'Admin - Claim Searches',
                    },
                    children: [
                      {
                        path: ':searchId',
                        component: ClaimSearchDetailPage,
                        data: {
                          breadcrumb: ':searchId',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'task-types',
                data: {
                  title: 'Admin - Task Types',
                },
                children: [
                  {
                    path: 'edit',
                    component: TaskTypeEditPage,
                    data: {
                      breadcrumb: 'Edit Task Type',
                    },
                    children: [
                      {
                        path: ':taskTypeId',
                        component: TaskTypeDetailsPage,
                        data: {
                          breadcrumb: ':taskTypeId',
                        },
                      },
                    ],
                  },
                  {
                    path: 'merge',
                    component: TaskTypeMergePage,
                    data: {
                      breadcrumb: 'Merge Task Types',
                      title: 'Admin - Merge Task Types',
                    },
                  },
                  {
                    path: 'assign',
                    component: TaskTypeAssignPage,
                    data: {
                      breadcrumb: 'Assign Tasks',
                      title: 'Admin - Create Tasks',
                    },
                  },
                ],
              },
              {
                path: 'task-groups',
                children: [
                  {
                    path: 'edit',
                    component: TaskGroupEditPage,
                    data: {
                      breadcrumb: 'Edit Task Group',
                      title: 'Admin - Task Groups',
                    },
                    children: [
                      {
                        path: ':taskGroupId',
                        component: TaskGroupDetailsPage,
                        data: {
                          breadcrumb: ':taskGroupId',
                        },
                      },
                    ],
                  },
                  {
                    path: 'assign',
                    component: TaskGroupAssignPage,
                    data: {
                      breadcrumb: 'Assign Task Groups',
                      title: 'Admin - Task Groups - Assign Tasks / Claims',
                    },
                  },
                ],
              },
              {
                path: 'tasks',
                children: [
                  {
                    path: 'delete',
                    component: TasksDeletePage,
                    data: {
                      breadcrumb: 'Delete Tasks',
                      title: 'Admin - Tasks - Delete',
                    },
                  },
                ],
              },
              {
                path: 'users',
                data: {
                  breadcrumb: 'Users',
                },
                children: [
                  {
                    path: '',
                    component: UsersListPage,
                    data: {
                      disableBreadcrumbs: true,
                      title: 'Admin - Users',
                    },
                  },
                  {
                    path: 'new',
                    component: EditUserPage,
                    canActivate: [PermissionGuard],
                    data: {
                      roles: ['Super'],
                    },
                  },
                  {
                    path: ':userId',
                    component: EditUserPage,
                  },
                ],
              },
              {
                path: 'feature-flags',
                data: {
                  breadcrumb: 'Feature Flags',
                  roles: ['Super'], // Remove after migration to permissions.
                  permissions: [Permissions.system.featureFlag.all],
                },
                canActivate: [PermissionGuard],
                children: [
                  {
                    path: '',
                    component: FeatureFlagNamesListPage,
                    data: { title: 'Admin - Feature Flags' },
                  },
                  {
                    path: ':featureFlagName',
                    component: FeatureFlagPage,
                    data: {
                      breadcrumb: ':featureFlagName',
                    },
                    children: [
                      {
                        path: ':featureFlagId',
                        component: FeatureFlagEditPage,
                        data: {
                          breadcrumb: ':featureFlagId',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'payer-plan-groups',
                data: {
                  breadcrumb: 'Payer Plan Groups',
                },
                canActivate: [PermissionGuard],
                children: [
                  {
                    path: '',
                    component: PayerPlanGroupPage,
                    data: { title: 'Admin - Payer Plan Groups' },
                  },
                  {
                    path: ':payerPlanGroupName',
                    component: PayerPlanGroupEditPage,
                    data: {
                      breadcrumb: ':payerPlanGroupName',
                    },
                    children: [
                      {
                        path: ':payerPlanGroupName',
                        component: PayerPlanGroupEditPage,
                        data: {
                          breadcrumb: ':payerPlanGroupName',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'edi-reader',
                component: EdiReader,
                data: {
                  breadcrumb: 'Edi Reader',
                },
              },
              {
                path: 'teams',
                data: {
                  breadcrumb: 'Teams',
                },
                children: [
                  {
                    path: '',
                    component: TeamsPage,
                    data: { title: 'Admin - Teams' },
                  },
                  {
                    path: ':teamId',
                    component: TeamEditPage,
                    data: {
                      breadcrumb: ':teamId',
                    },
                  },
                ],
              },
              {
                path: 'observe',
                data: {
                  breadcrumb: 'Observe',
                },
                children: [
                  {
                    path: '',
                    redirectTo: '/admin',
                    pathMatch: 'full',
                  },
                  {
                    path: 'access-controls',
                    data: {
                      breadcrumb: 'Access Control List',
                    },
                    children: [
                      {
                        path: '',
                        data: {
                          title: 'Admin - Observe ACL',
                          roles: [UserRole.Admin],
                        },
                        canActivate: [PermissionGuard],
                        component: ObserveAccessControlPage,
                      },
                      {
                        path: 'audit-trail',
                        data: {
                          title: 'Observe Access Control Audit Trail',
                          breadcrumb: 'Audit',
                          roles: [UserRole.Admin],
                        },
                        canActivate: [PermissionGuard],
                        component: ObserveAccessControlAuditTrailPage,
                      },
                    ],
                  },
                  {
                    path: 'configurations',
                    data: {
                      breadcrumb: 'Configurations',
                      roles: ['Super'], // Remove after migration to permissions.
                      permissions: [Permissions.observe.config.all],
                    },
                    canActivate: [PermissionGuard],
                    component: ObserveConfigPage,
                  },
                  {
                    path: 'settings',
                    data: {
                      breadcrumb: 'Settings',
                    },
                    children: [
                      {
                        path: '',
                        component: ObserveOrgListComponent,
                      },
                      {
                        path: ':orgId',
                        component: ObserveOrgSettingsComponent,
                        data: {
                          breadcrumb: 'Manage',
                        },
                      },
                    ],
                  },
                  {
                    path: 'users',
                    data: {
                      breadcrumb: 'Users',
                    },
                    children: [
                      {
                        path: '',
                        component: ObserveUsersListComponent,
                      },
                      {
                        path: 'activityLog',
                        data: {
                          breadcrumb: 'Activity Log',
                        },
                        component: ObserveUsersActivityLogComponent,
                      },
                    ],
                  },
                  {
                    path: 'builds',
                    data: {
                      breadcrumb: 'Builds',
                      roles: ['Super'],
                      canActivate: [PermissionGuard],
                    },
                    component: ObserveBuildConfigPageComponent,
                  },
                  {
                    path: 'slideshows',
                    data: {
                      disableBreadcrumbs: true,
                      roles: ['Super'], // Remove after migration to permissions.
                      feature: FeatureFlags.ui.slideshows,
                      permissions: [Permissions.observe.config.all],
                    },
                    canActivate: [FeatureFlagGuard, PermissionGuard],
                    loadComponent: () =>
                      import('./admin/observe/slideshows/slideshow-page/slideshow-page.component').then(
                        (c) => c.SlideshowPageComponent,
                      ),
                  },
                  {
                    path: 'slideshows/:orgId',
                    data: {
                      disableBreadcrumbs: true,
                      roles: ['Super'], // Remove after migration to permissions.
                      permissions: [Permissions.observe.config.all],
                    },
                    canActivate: [PermissionGuard],
                    loadComponent: () =>
                      import('./admin/observe/slideshows/slideshow-page/slideshow-page.component').then(
                        (c) => c.SlideshowPageComponent,
                      ),
                  },
                ],
              },
              {
                path: 'testing',
                data: {
                  breadcrumb: 'Observe Testing',
                  roles: ['Super'], // Remove after migration to permissions.
                },
                canActivate: [PermissionGuard],
                component: ObserveTestingPage,
              },
              {
                path: 'dashboard-publishing',
                data: {
                  breadcrumb: 'Dashboard Publishing',
                  roles: ['Super'],
                },
                canActivate: [PermissionGuard],
                children: [
                  {
                    path: '',
                    canActivate: [PermissionGuard],
                    component: DashboardOrgListComponent,
                    data: {
                      roles: ['Super'],
                    },
                  },
                  {
                    path: ':orgId',
                    canActivate: [PermissionGuard],
                    component: EditOrgDashboardPage,
                    data: {
                      breadcrumb: 'Manage Dashboard Settings',
                      roles: ['Super'],
                    },
                  },
                ],
              },
              {
                path: 'bot-jobs',
                data: {
                  breadcrumb: 'Bot Jobs',
                },
                loadChildren: () => import('./admin/bot-jobs/bot-jobs.module').then((m) => m.BotJobsAdminModule),
              },
              {
                path: 'payers',
                data: {
                  breadcrumb: 'Payers',
                  roles: ['Super'], // Remove after migration to permissions.
                  permissions: [Permissions.system.payers.all],
                },
                canActivate: [PermissionGuard],
                children: [
                  {
                    path: '',
                    component: PayersListPage,
                    data: { title: 'Admin - Payers' },
                  },
                  {
                    path: 'combine',
                    component: CombinePayersPage,
                    data: {
                      breadcrumb: 'Combine Payers',
                      title: 'Admin - Payers - Combine',
                    },
                  },
                  {
                    path: ':payerId',
                    component: PayerEditPage,
                    data: {
                      breadcrumb: ':payerId',
                    },
                    resolve: {
                      resolved: PayerResolverService,
                    },
                    runGuardsAndResolvers: 'always',
                  },
                ],
              },
              {
                path: 'client-credentials',
                data: {
                  breadcrumb: 'Client Credentials',
                },
                children: [
                  {
                    path: '',
                    component: ClientCredentialsListPage,
                    data: { title: 'Admin - Client Credentials' },
                  },
                  {
                    path: ':clientCredentialId',
                    component: ClientCredentialEditPage,
                    data: {
                      breadcrumb: ':clientCredentialId',
                      title: 'Admin - Client Credentials - New',
                    },
                  },
                ],
              },
              {
                path: 'workflows/assign',
                component: WorkflowAssignPage,
                data: {
                  breadcrumb: 'Assign Workflows',
                  feature: FeatureFlags.ui.workflows,
                  title: 'Admin - Workflows - Assign',
                },
                canActivate: [FeatureFlagGuard],
              },
              {
                path: 'workflows/delete',
                component: DeleteWorkflowsPage,
                data: {
                  breadcrumb: 'Delete Workflows',
                  title: 'Admin - Workflows - Delete',
                },
              },
              {
                path: 'workflows',
                data: {
                  breadcrumb: 'Workflows',
                  feature: FeatureFlags.ui.workflows,
                  title: 'Admin - Workflows',
                },
                canActivate: [FeatureFlagGuard],
                children: [
                  {
                    path: '',
                    component: WorkflowListPage,
                  },
                  {
                    path: ':workflowTypeId',
                    component: WorkflowPage,
                    data: {
                      breadcrumb: ':workflowTypeId',
                    },
                    resolve: {
                      workflowType: WorkflowTypeResolverService,
                    },
                    runGuardsAndResolvers: 'always',
                  },
                ],
              },
              {
                path: 'automations-query-center',
                data: {
                  roles: [UserRole.Admin, UserRole.Manager], // Remove after migration to permissions.
                  breadcrumb: 'Automations Query Center',
                  title: 'Admin - Automations Query Center',
                  feature: FeatureFlags.ui.queryCenter.automations,
                  permissions: [Permissions.queryCenter.automations.all],
                },
                canActivate: [PermissionGuard, FeatureFlagGuard],
                component: AutomationsQueryCenterPage,
              },
            ],
          },
          {
            path: 'annotations',
            data: {
              breadcrumb: 'Annotations',
              feature: FeatureFlags.ui.annotations,
            },
            component: AnnotationsPage,
            canActivate: [FeatureFlagGuard],
          },
          {
            path: 'claims',
            data: {
              breadcrumb: 'Claims',
              title: 'Claims',
              permissions: [Permissions.system.admin.all],
              roles: [UserRole.Admin, UserRole.Executive],
            },
            canActivate: [PermissionGuard],
            children: [
              {
                path: '',
                canDeactivate: [ClaimsListGuard],
                component: ClaimsListPage,
              },
              {
                path: ':claimId',
                canDeactivate: [ClaimsListGuard],
                component: ClaimDetailPage,
                data: {
                  breadcrumb: ':claimId',
                },
              },
            ],
          },
          {
            path: 'reporting',
            data: {
              breadcrumb: 'Reporting',
              roles: [UserRole.Admin], // Remove after migration to permissions.
              title: 'Reporting',
              permissions: [Permissions.system.reporting.all],
            },
            canActivate: [PermissionGuard],
            children: [
              {
                path: '',
                component: ReportingPage,
              },
            ],
          },
          {
            path: 'reports',
            data: {
              breadcrumb: 'Reports',
              roles: [UserRole.Admin, UserRole.Manager], // Remove after migration to permissions.
              title: 'Reports',
              permissions: [Permissions.system.report.all],
            },
            canActivate: [PermissionGuard],
            children: [
              {
                path: 'upload',
                component: ReportUploadPage,
                data: {
                  breadcrumb: 'Reports Upload',
                  roles: [UserRole.Admin, UserRole.Manager], // Remove after migration to permissions.
                  title: 'Reports - Upload',
                  permissions: [Permissions.system.report.all],
                },
                canActivate: [PermissionGuard],
              },
              {
                path: '',
                component: ReportListPage,
              },
              {
                path: ':search',
                component: ReportListPage,
              },
            ],
          },
          {
            path: 'pathfinder',
            data: {
              breadcrumb: 'Pathfinder',
              title: 'Pathfinder',
              permissions: [Permissions.pathfinder.all.all],
            },
            children: [
              {
                path: '',
                component: TaskGroupsPage,
              },
              {
                path: ':taskGroupId', // task group id OR 'my'
                data: {
                  breadcrumb: ':taskGroupId',
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'tasks', // :taskId = tasks
                  },
                  {
                    path: ':taskId',
                    component: TasksPage,
                    data: {
                      breadcrumb: ':taskId',
                    },
                    canDeactivate: [ClaimsListGuard],
                    runGuardsAndResolvers: 'always',
                  },
                ],
              },
            ],
          },
          {
            path: 'revbots',
            canActivate: [PermissionGuard],
            data: {
              breadcrumb: 'RevBots',
              roles: [UserRole.Admin, UserRole.Executive], // Remove after migration to permissions.
              title: 'RevBots',
              permissions: [Permissions.automation.revbot.all],
            },
            children: [
              {
                path: '',
                component: BotsListPage,
                canActivate: [PermissionGuard],
                data: {
                  roles: [UserRole.Admin],
                },
              },
              {
                path: 'throughput',
                component: ThroughputReportDashboardPage,
                data: {
                  breadcrumb: 'Throughput',
                  feature: FeatureFlags.ui.throughputReport,
                  roles: [UserRole.Executive, UserRole.Admin],
                },
                canActivate: [FeatureFlagGuard, PermissionGuard],
              },
              {
                path: ':botJobId',
                component: BotDetailPage,
                canActivate: [PermissionGuard],
                data: {
                  breadcrumb: ':botJobId',
                  roles: [UserRole.Admin],
                },
                children: [
                  {
                    path: '',
                    component: BotOverviewPage,
                  },
                  {
                    path: ':botRunId',
                    component: BotRunOverviewPage,
                    data: {
                      breadcrumb: ':botRunId',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'visionx/document-types',
            children: [
              {
                path: ':vxDocumentTypeId/mapping',
                redirectTo: '/vx/document-types/:vxDocumentTypeId',
              },
            ],
          },
          {
            path: 'file/:fileId',
            component: FilePage,
            data: { title: 'File Viewer' },
          },
          {
            path: 'visionx-rule-editor',
            data: {
              permissions: [Permissions.visionx.ruleEditor.view],
            },
            canActivate: [PermissionGuard],
            loadChildren: () => import('app/vx-rule-editor/vx-rule-editor.module').then((m) => m.VxRuleEditorModule),
          },
          {
            path: 'vx',
            data: {
              breadcrumb: 'VisionX',
              title: 'VisionX',
            },
            canActivate: [PermissionGuard],
            loadChildren: () => import('app/vx/vx.module').then((m) => m.VXModule),
          },
          // LEGACY HANDLING OF DIRECT LINK TO A VXDOCUMENT, NEEDED BECAUSE WE PROVIDED BOONE WITH MANY LINKS WITH THIS STRUCTURE
          {
            path: 'visionx-document/:vxDocumentId',
            redirectTo: 'vx/manager/vx-document/:vxDocumentId',
          },
          {
            path: 'process-maps',
            data: {
              breadcrumb: 'Process Maps',
              title: 'Process Maps',
              feature: FeatureFlags.ui.processMaps,
            },
            loadChildren: () => import('./process-maps/process-maps.module').then((module) => module.ProcessMapsModule),
          },
          { path: '', redirectTo: DEFAULT_HOME_PATH, pathMatch: 'full' },
          { path: '**', redirectTo: DEFAULT_HOME_PATH },
        ],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
