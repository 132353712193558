import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-prior-auth-dashboard',
  templateUrl: './prior-auth.page.html',
  host: {
    class: 'd-flex flex-column flex-grow-1',
  },
})
export class PriorAuthDashboardPage implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
